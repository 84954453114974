/** @jsxImportSource @emotion/react */

import internet from "Assets/black-internet.svg";
import paperclip from "Assets/paperclip.svg";
import wintheme from "Assets/wintheme-assistant.svg";
import Popover from "components/atoms/popover/Popover";
import Tooltip from "components/atoms/tooltip/Tooltip";
import ContentDrivePopoverContent from "components/molecules/content-drive-popover-content";
import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { useCallback, useMemo, useRef, useState } from "react";
import { getProjectAttachments } from "store/reducers/projectAttachmentsReducer";
import {
    insertAssistantFiles,
    removeAllAssistantFiles,
    removeAssistantFiles,
    setEnableInternet,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { BaseFile, WorkspaceFile } from "types/FileStorage";
import AttachmentsPopover from "./AttachmentsPopover";
import WinthemesPopover from "./WinthemesPopover";
import { SOURCE_TO_META, Source } from "./constants";
import { transformToFileMention } from "./utils";

const WritingAssistantInputActions = () => {
    const [activeSource, setActiveSource] = useState<Source>();
    const [sourceOpen, setSourceOpen] = useState<boolean>(false);
    const [winThemesOpen, setWinThemesOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const ref = useRef(null);
    const { activeProject } = useAppSelector((root) => root.project);
    const dispatch = useAppDispatch();
    const searchable = activeSource === Source.ProjectAttachments || activeSource === Source.ContentDrive;
    const { selectedFiles } = useAppSelector((root) => root.writingAssistant);
    const selectedFileIds = useMemo(() => selectedFiles.map(({ id }) => id), [selectedFiles]);

    const handleSourceClick = (val: Source | undefined) => {
        setActiveSource(val);
        if (val === Source.Internet) {
            dispatch(removeAllAssistantFiles());
            dispatch(setEnableInternet(true));
            return;
        }
    };

    const initialOptions = useMemo(
        () =>
            (
                Object.values(Source).filter(
                    (s) => Number.isInteger(s) && s !== Source.Search && s !== Source.Internet
                ) as Source[]
            ).map(
                (source, idx) =>
                    ({
                        key: idx,
                        label: SOURCE_TO_META[source].copy,
                        value: source,
                        onSelect: (val, e) => {
                            setActiveSource(val);
                            e?.preventDefault();
                        },
                    } as MenuItem<Source>)
            ),
        []
    );

    const handleSelectedFiles = useCallback(
        (checked: boolean, files: Pick<WorkspaceFile, "id" | "name" | "file_extension_type">[]) => {
            if (checked) {
                dispatch(insertAssistantFiles(transformToFileMention(files)));
            } else dispatch(removeAssistantFiles(files.map(({ id }) => id)));
            dispatch(setEnableInternet(false));
        },
        [dispatch]
    );

    const handleSelectedDriveFiles = useCallback(
        (checked: boolean, files: BaseFile[]) => {
            if (checked) {
                dispatch(insertAssistantFiles(transformToFileMention(files)));
            } else dispatch(removeAssistantFiles(files.map(({ id }) => id)));
            dispatch(setEnableInternet(false));
        },
        [dispatch]
    );

    const handleClickWinThemes = useCallback(() => {
        if (winThemesOpen) {
            setWinThemesOpen(false);
        } else {
            setWinThemesOpen(true);
            setSourceOpen(false);
        }
    }, [winThemesOpen]);

    return (
        <div
            ref={ref}
            className="flex flex-row gap-1 items-center w-full  bg-transparent absolute left-0 right-0 -top-10 justify-between"
        >
            <div className="flex items-center">
                <Popover
                    content={
                        <>
                            {!activeSource && (
                                <div
                                    className="text-stone-800 text-sm min-w-[150px] max-w-[350px] max-h-[375px] overflow-y-auto flex flex-col p-1"
                                    css={[searchable && tw`min-w-[300px]`]}
                                >
                                    {initialOptions.map(({ label, value }) => (
                                        <Tooltip
                                            key={value}
                                            disabled={value !== Source.Internet}
                                            content={value === Source.Internet ? SOURCE_TO_META[value].tooltip : ""}
                                            contentProps={{ side: "right", style: { marginTop: -19 } }}
                                        >
                                            <button
                                                key={value}
                                                onClick={() => handleSourceClick(value)}
                                                className="py-2 pr-3 pl-2 outline-none flex items-center justify-between gap-2 word-break-[break-word] hover:bg-slate-100"
                                            >
                                                {label}
                                            </button>
                                        </Tooltip>
                                    ))}
                                </div>
                            )}
                            {activeSource === Source.ProjectAttachments && (
                                <AttachmentsPopover query={query} handleSelectedFiles={handleSelectedFiles} />
                            )}
                            {activeSource === Source.ContentDrive && (
                                <ContentDrivePopoverContent
                                    query={query}
                                    handleSelectedFiles={handleSelectedDriveFiles}
                                    onClear={(files) => dispatch(removeAssistantFiles(files.map(({ id }) => id)))}
                                    selectedFiles={selectedFileIds}
                                />
                            )}
                        </>
                    }
                    open={sourceOpen}
                    onOpenChange={(o) => {
                        if (!o) {
                            setActiveSource(undefined);
                            setWinThemesOpen(false);
                        }
                        if (o) dispatch(getProjectAttachments(activeProject?.internal_contract.id));
                        setSourceOpen(o);
                    }}
                    searchProps={{
                        value: query,
                        onChange: (e) => setQuery(e.target.value),
                        placeholder: activeSource ? SOURCE_TO_META[activeSource].searchPlaceholder : undefined,
                    }}
                    searchable={searchable}
                    contentProps={{
                        align: "start",
                        side: "top",
                        css: [tw`backdrop-blur-sm ml-0 bg-[rgba(255,255,255,0.95)]`, searchable && tw`min-w-[400px]`],
                    }}
                    portalProps={{ container: ref.current }}
                >
                    <button
                        className="flex px-2.5 py-1.5 mr-5 h-full text-xs bg-neutral-50 rounded-md text-black duration-100 hover:bg-[#E9F0FF]"
                        style={{
                            marginRight: "4px",
                            border: "1px solid #E3EAEE",
                        }}
                    >
                        <span>
                            <img src={paperclip} alt="" className="w-[13px] h-full" />
                        </span>
                        <span className="ml-1">Documents</span>
                    </button>
                </Popover>
                <Tooltip content={SOURCE_TO_META[Source.Internet].tooltip}>
                    <button
                        className="flex px-2.5 py-1.5 ml-1 h-full text-xs bg-neutral-50 rounded-md text-black duration-100 hover:bg-[#E9F0FF]"
                        style={{
                            border: "1px solid #E3EAEE",
                        }}
                        key={Source.Internet}
                        onClick={() => handleSourceClick(Source.Internet)}
                    >
                        <span>
                            <img src={internet} alt="" className="w-[14px] h-full" />
                        </span>
                        <span className="ml-1">Internet</span>
                    </button>
                </Tooltip>
            </div>

            {winThemesOpen && <WinthemesPopover setWinThemesOpen={setWinThemesOpen} />}

            <button
                className="flex px-2.5 py-1.5 h-full text-xs bg-neutral-50 rounded-md text-black duration-100 hover:bg-[#E9F0FF]"
                style={{
                    border: "1px solid #E3EAEE",
                }}
                onClick={handleClickWinThemes}
            >
                <span>
                    <img src={wintheme} alt="" className="w-[13px] h-full" />
                </span>
                <span className="ml-1">Win themes</span>
            </button>
        </div>
    );
};

export default WritingAssistantInputActions;
