/** @jsxImportSource @emotion/react */

import { Sheet, Storage, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import EditableContent from "components/molecules/editable-content";
import tw from "twin.macro";
import { useFrameworkOperations } from "components/copilot/hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import SheetsDropdown from "components/molecules/sheets-dropdown";
import { BsArrowsCollapse, BsArrowsExpand, BsPlus } from "react-icons/bs";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { useCreateAndAssignRequirement } from "components/copilot/Framework/hooks";
import Tooltip from "components/atoms/tooltip";
import { useStorage, useUpdateMyPresence } from "liveblocks.config";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import { ControlItemSlug, useRouteRequirement, useSseSectionProposal } from "./hooks";
import { useCallback, useEffect, useMemo } from "react";
import { useObserveSseController } from "hook/useObserveSseController";
import BottomBar from "./BottomBar";
import { PREVIEW_LAUNCH_DATE, WHITELIST_PROJECT_IDS } from "const-values/Preview";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";

const DraftSection = () => {
    const { sectionId, volumeId } = useParams();
    const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
    const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
    const collapseAllText = useAppSelector((store) => store.sectionState.requirementsState.collapseAllText);
    const { volume } = useOutletContext<{ volume?: Volume }>();
    const createAndAddToSection = useCreateAndAssignRequirement();
    const { updateSectionTitle } = useFrameworkOperations();
    const { abortConnection } = useSseSectionProposal();
    const updateMyPresence = useUpdateMyPresence();
    const dispatch = useAppDispatch();
    const activeProject = useAppSelector((state) => state.project.activeProject);
    useRouteRequirement();
    const { pathname } = useLocation();
    const isProposal = useMemo(() => pathname?.split("/").includes(ControlItemSlug.proposal), [pathname]);

    useEffect(() => {
        abortConnection();
        dispatch(setRequirementsState({ expandedRequirementId: null, collapseAllText: false }));
        return () => {
            dispatch(setCheckedState({}));
        };
    }, [sectionId, dispatch]);

    const section = useStorage((storage) => {
        if (volume) return volume?.sections?.find((section) => section.id === sectionId);

        return (storage.framework as Storage["framework"]).volumes
            ?.find((volume) => volume.id === volumeId)
            ?.sections?.find((section) => section.id === sectionId);
    });

    useObserveSseController(abortConnection, () => {
        updateMyPresence({
            selectedId: null,
        });
    });

    const onCreateRequirement = useCallback(
        (sheet: Sheet) => {
            if (section) {
                const createdRequirement = createComplianceMatrixRowRequirement({
                    extraction_id: sheet?.id,
                });

                createAndAddToSection(section, undefined, { requirement: createdRequirement });
                dispatch(setRequirementsState({ autoScroll: true }));
            }
        },
        [createAndAddToSection, dispatch, section]
    );

    const shouldShowPreview = useMemo(
        () =>
            WHITELIST_PROJECT_IDS.includes(activeProject?.internal_contract.id) ||
            new Date(activeProject?.internal_contract?.created_at || PREVIEW_LAUNCH_DATE).getTime() >=
                new Date(PREVIEW_LAUNCH_DATE).getTime(),
        [activeProject?.internal_contract?.created_at, activeProject?.internal_contract.id]
    );

    return (
        <div className="flex flex-col gap-3 h-full pt-3">
            <div className="pl-3.5 py-1 pr-5 flex items-start gap-4">
                <EditableContent
                    submitOnEnter
                    content={section?.title || ""}
                    onContentUpdate={(val) => volumeId && section?.id && updateSectionTitle(volumeId, section.id, val)}
                    css={[tw`font-medium flex-1`]}
                    heightProps={tw`line-clamp-2 max-h-12 overflow-hidden`}
                    textareaProps={{ forceResetProps: [sidebarSize, actionsPanelOpen], forceResetDelay: 150 }}
                />
                <div className="flex items-center gap-2">
                    <SheetsDropdown disabled={isProposal} onSelect={onCreateRequirement}>
                        <div
                            className="bg-slate-200 text-slate-700 font-medium shadow-sharp-thin flex items-center rounded-md pr-3 pl-2 py-1.5 text-sm duration-100 hover:bg-slate-300 hover:text-slate-900"
                            css={[
                                isProposal && tw`group-disabled:bg-gray-200 group-disabled:text-slate-400 shadow-none`,
                            ]}
                        >
                            <BsPlus className="text-[20px] mr-1" /> Requirement
                        </div>
                    </SheetsDropdown>
                    <Tooltip disabled={isProposal} content={collapseAllText ? "Expand all text" : "Collapse all text"}>
                        <button
                            disabled={isProposal}
                            onClick={() => dispatch(setRequirementsState({ collapseAllText: !collapseAllText }))}
                            className="text-base bg-slate-200 text-slate-700 h-8 w-8 flex items-center rounded-md justify-center shadow-sharp-thin duration-100 hover:bg-slate-300 hover:text-slate-900 disabled:bg-gray-200 disabled:text-slate-400 disabled:shadow-none"
                        >
                            {collapseAllText ? <BsArrowsExpand /> : <BsArrowsCollapse />}
                        </button>
                    </Tooltip>
                </div>
            </div>
            <div className="relative flex flex-col h-full">
                <Outlet context={{ section, volume, abortConnection }} />
                {!shouldShowPreview && <BottomBar section={section} abortConnection={abortConnection} />}
            </div>
        </div>
    );
};

export default DraftSection;
