/** @jsxImportSource @emotion/react */

import { HTMLProps, ReactNode } from "react";
import { ReactComponent as Document } from "Assets/svgs/document.svg";
import { ReactComponent as SearchNotFound } from "Assets/svgs/search.svg";
import { ReactComponent as DocumentSearch } from "Assets/svgs/document-search.svg";
import { ReactComponent as List } from "Assets/svgs/list.svg";
import { ReactComponent as EmptyRequirements } from "Assets/svgs/emptyRequirements.svg";
import { ReactComponent as EmptyList } from "Assets/svgs/listItems.svg";
import { ReactComponent as EmptySearch } from "Assets/svgs/search.svg";
import { ReactComponent as WritingAssistantEmpty } from "Assets/svgs/writing-assistant-empty.svg";
import { ReactComponent as DocumentEmpty } from "Assets/svgs/empty-document.svg";
import { ReactComponent as IdeatorEmpty } from "Assets/svgs/empty-ideator-state.svg";

import "twin.macro";

enum IconType {
    Document,
    SearchNotFound,
    DocumentSearch,
    List,
    EmptyRequirements,
    EmptyList,
    EmptySearch,
    WritingAssistantEmpty,
    DocumentEmpty,
    IdeatorEmpty,
}

const SVGS: Record<keyof typeof IconType, React.FC> = {
    Document,
    SearchNotFound,
    DocumentSearch,
    List,
    EmptyRequirements,
    EmptyList,
    EmptySearch,
    WritingAssistantEmpty,
    DocumentEmpty,
    IdeatorEmpty,
};

interface Props extends Omit<HTMLProps<SVGElement>, "title"> {
    name: keyof typeof IconType;
    title?: ReactNode;
    containerProps?: HTMLProps<HTMLDivElement>;
}

export const Empty = ({ name, title, containerProps, ...props }: Props) => {
    const Component = SVGS[name];
    return (
        <div className="flex flex-col justify-center items-center h-[90%] w-full" {...containerProps}>
            <Component {...props} />
            {title}
        </div>
    );
};
