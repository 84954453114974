/** @jsxImportSource @emotion/react */

import * as Collapsible from "@radix-ui/react-collapsible";
import Icon from "components/atoms/icons/Icon";
import { MAX_ATTACHMENT_SIZE } from "const-values/Project";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { formatBytes } from "utils/helpers";
import upload from "Assets/upload-folder 1.svg";
import { removeAttachment, setAttachments } from "store/reducers/newProjectReducer";
import { getFileIcon } from "utils/getFileIcon";
import { LuTrash2 } from "react-icons/lu";
import { BiSolidInfoCircle } from "react-icons/bi";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useNotification } from "context/notificationContext";

const ProjectAttachments = () => {
    const { setToast } = useNotification();
    const dispatch = useAppDispatch();
    const { attachments } = useAppSelector((root) => root.newProject);
    const [open, setOpen] = useState(false);

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (acceptedFiles && acceptedFiles?.length > 0) {
                await dispatch(setAttachments(acceptedFiles));
            }
        },
        [dispatch]
    );

    const dropValidator = (file: File) => {
        if (file?.size > MAX_ATTACHMENT_SIZE) {
            setToast.error({
                title: "Document size not supported",
                msg:
                    file?.name +
                    `size is too large. We do not support document sizes larger than ${formatBytes(
                        MAX_ATTACHMENT_SIZE
                    )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
            });
            return {
                code: "file-too-large",
                message: `Document is larger than ${formatBytes(MAX_ATTACHMENT_SIZE)}`,
            };
        }

        return null;
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: MAX_ATTACHMENT_SIZE,
        validator: dropValidator,
        accept: {
            "text/plain": [".txt"],
            "text/pdf": [".pdf"],
            "text/doc": [".docx"],
            "text/excel": [".xlsx"],
            "text/powerpoint": [".pptx"],
        },
        noClick: false,
        maxFiles: 30,
        multiple: true,
    });

    return (
        <div className="pl-6">
            <div className="flex items-center gap-2 w-full font-bold">
                <div className="flex text-sm font-semibold items-center gap-2">
                    Relevant Documents{" "}
                    <div className="font-normal text-gray-lightest text-xs ml-0.5 mt-0.5">(Optional)</div>
                    <Tooltip content="Vultron will use the uploaded documents as context for the project.">
                        <div className="relative flex items-center justify-center">
                            <BiSolidInfoCircle className="text-action rounded-full z-[1]" />
                            <div
                                className="pulse-animation absolute rounded-full left-0.5 right-0.5 top-0.5 bottom-0.5"
                                // @ts-ignore
                                style={{ "--pulse-bg": "rgba(42, 71, 171, 0.7)" }}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className="flex flex-col gap-1 p-4 pl-0 pt-5 pb-2.5">
                <div
                    {...getRootProps()}
                    className="p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 justify-center flex flex-col items-center rounded-lg bg-[#fafafa] h-[162px]"
                >
                    <>
                        <input multiple {...getInputProps()} />
                        {isDragActive && (
                            <div className="absolute bg-gray-300 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-50" />
                        )}
                        <img src={upload} alt="" className="h-20 w-20" />
                        <span className="font-semibold pointer-events-none">Drop or Select File</span>
                        <span className="text-gray-text pointer-events-none text-sm">
                            We accept pdf, docx, txt, xlsx, and pptx files
                        </span>
                    </>
                </div>
                <div className="flex flex-col">
                    {attachments?.map((fileItem, i) => (
                        <div key={i} className="py-3 flex gap-5 justify-between items-center w-full">
                            <div className="flex gap-3 px-1 items-center">
                                <img src={getFileIcon(fileItem?.type)} alt="" className="w-[33px] h-[41px]" />
                                <div className="flex flex-col gap-0.5">
                                    <span className="text-sm font-medium break-all line-clamp-2">{fileItem?.name}</span>
                                    <span className="text-xs">{formatBytes(fileItem?.size)}</span>
                                </div>
                            </div>
                            <button
                                className="px-2 min-w-[33px] w-[33px] border-0 b-white rounded-full p-2 duration-150 text-slate-400 hover:bg-gray-200 hover:text-slate-600"
                                onClick={() => dispatch(removeAttachment(fileItem))}
                            >
                                <LuTrash2 />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectAttachments;
