/** @jsxImportSource @emotion/react */

import { Section } from "components/copilot/CopilotSchemaImmutableTypes";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { memo, useMemo, useRef } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { MdFormatBold, MdFormatItalic, MdOutlineFormatUnderlined } from "react-icons/md";
import { DisplayOption, setPreviewProposalState } from "store/reducers/draft/previewProposalReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { DISPLAY_OPTIONS_TO_META } from "./constants";
import { usePreviewProposalConfig } from "./hooks";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useBuildDraftLinks } from "hook/draft/useBuildDraftLinks";
import Tooltip from "components/atoms/tooltip";

type Props = {
    section: Section | FormattedSection;
};

const SectionRow = ({ section }: Props) => {
    // const sectionRequirement = useStorage(
    //     (storage) =>
    //         (storage.compliance_matrix as Storage["compliance_matrix"]).find(
    //             (row) => row.proposal_reference.section_id === section.id
    //         ),
    //     shallow
    // );
    const { buildSectionLink } = useBuildDraftLinks();
    const ref = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const { updateSectionFormat, previewProposalConfig } = usePreviewProposalConfig();
    const { activeSectionId, activeDraftId } = useAppSelector((store) => store.previewProposalState);
    const { id, title, parent_id } = section;
    const isSubsection = !!parent_id;
    const isActive = activeSectionId === id;
    const configFormat = previewProposalConfig?.sectionFormats.find((sectionFormat) => sectionFormat.id === section.id);

    const menuItems = useMemo(
        () => [
            {
                key: 1,
                label: DISPLAY_OPTIONS_TO_META[DisplayOption.DontInclude].label,
                selected: DisplayOption.DontInclude === configFormat?.displayOption,
                onSelect: () => updateSectionFormat({ id, displayOption: DisplayOption.DontInclude }),
            },
            {
                key: 2,
                label: DISPLAY_OPTIONS_TO_META[DisplayOption.Heading].label,
                selected: DisplayOption.Heading === configFormat?.displayOption,
                onSelect: () => updateSectionFormat({ id, displayOption: DisplayOption.Heading }),
            },
            {
                key: 3,
                label: DISPLAY_OPTIONS_TO_META[DisplayOption.FullRequirement].label,
                selected: DisplayOption.FullRequirement === configFormat?.displayOption,
                onSelect: () => updateSectionFormat({ id, displayOption: DisplayOption.FullRequirement }),
            },
        ],
        [configFormat?.displayOption, id, updateSectionFormat]
    );

    // TODO: The page count needs to account for titles (heading, title, no title) dynamically
    // const wordCount = useMemo(
    //     () => getWordCount(sectionRequirement?.written_content || ""),
    //     [sectionRequirement?.written_content]
    // );
    // const pageCount = Math.round((wordCount / 400 + Number.EPSILON) * 100) / 100;

    return (
        <div
            ref={ref}
            className="group flex flex-col gap-1 pl-7 pr-3 duration-100 hover:bg-midnight-700"
            css={[isSubsection && tw`pl-12`, isActive && tw`bg-midnight-700`]}
        >
            <button
                className="flex justify-between items-center font-normal py-2.5 gap-2"
                onClick={() => dispatch(setPreviewProposalState({ activeSectionId: id }))}
            >
                <div className="text-base text-white truncate">{title}</div>
                <Tooltip delayDuration={600} content="Open section">
                    <div
                        onClick={(e) => {
                            const sectionLink = buildSectionLink(activeDraftId || "", id).link;
                            window.open(sectionLink, "_blank");
                            e.stopPropagation();
                        }}
                        className="text-slate-100 opacity-0 flex justify-center items-center w-6 h-6 rounded duration-100 hover:bg-[#606074] group-hover:opacity-100"
                        css={[isActive && tw`opacity-100`]}
                    >
                        <HiOutlineExternalLink />
                    </div>
                </Tooltip>
                {/* <span className="text-xs text-slate-400 whitespace-nowrap">{pageCount} pgs.</span> */}
            </button>
            {isActive && (
                <div className="flex flex-row items-center gap-4 pb-3">
                    <span className="text-xs text-slate-400">Requirement:</span>
                    <div className="flex flex-row gap-0.5 flex-wrap">
                        <DropdownMenu modal portalProps={{ container: ref.current }} items={menuItems}>
                            <div className="whitespace-nowrap flex flex-row pl-2 pr-1 py-1 justify-between gap-4 rounded items-center bg-[#606074] text-slate-100 duration-100 hover:brightness-125">
                                <div className="text-xs">
                                    {
                                        DISPLAY_OPTIONS_TO_META[configFormat?.displayOption || DisplayOption.Heading]
                                            .label
                                    }
                                </div>
                                <IoChevronDownOutline className="text-sm" />
                            </div>
                        </DropdownMenu>
                        <div className="flex flex-row gap-0.5 flex-wrap">
                            <button
                                onClick={() => {
                                    if (!configFormat) return;

                                    updateSectionFormat({
                                        id,
                                        headingStyle: {
                                            ...configFormat.headingStyle,
                                            bold: !configFormat.headingStyle.bold,
                                        },
                                    });
                                }}
                                className="text-slate-100 flex justify-center items-center w-6 h-6 rounded duration-100 hover:bg-[#606074]"
                                css={[!!configFormat?.headingStyle.bold && tw`bg-[#606074] hover:brightness-125`]}
                            >
                                <MdFormatBold />
                            </button>
                            <button
                                onClick={() => {
                                    if (!configFormat) return;

                                    updateSectionFormat({
                                        id,
                                        headingStyle: {
                                            ...configFormat.headingStyle,
                                            italic: !configFormat.headingStyle.italic,
                                        },
                                    });
                                }}
                                className="text-slate-100 flex justify-center items-center w-6 h-6 rounded duration-100 hover:bg-[#606074]"
                                css={[!!configFormat?.headingStyle.italic && tw`bg-[#606074] hover:brightness-125`]}
                            >
                                <MdFormatItalic />
                            </button>
                            <button
                                onClick={() => {
                                    if (!configFormat) return;

                                    updateSectionFormat({
                                        id,
                                        headingStyle: {
                                            ...configFormat.headingStyle,
                                            underline: !configFormat.headingStyle.underline,
                                        },
                                    });
                                }}
                                className="text-slate-100 flex justify-center items-center w-6 h-6 rounded duration-100 hover:bg-[#606074]"
                                css={[!!configFormat?.headingStyle.underline && tw`bg-[#606074] hover:brightness-125`]}
                            >
                                <MdOutlineFormatUnderlined />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(SectionRow);
