import VultronExtractionToggle from "./VultronExtractionToggle";
import ExtractionKeywordsDropdown from "./ExtractionKeywordsDropdown";
import SplitTypeOptions from "./SplitTypeOptions";
import ExtractToSheetDropdown from "./ExtractToSheetDropdown";

export const RequirementExtractionSettings = () => {
    return (
        <div className="max-w-xl flex flex-col gap-4">
            <ExtractionKeywordsDropdown />
            <VultronExtractionToggle />
            <SplitTypeOptions />
            <ExtractToSheetDropdown />
        </div>
    );
};
