import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import add from "../../Assets/Addsvg.svg";
import group from "../../Assets/flashsvg.svg";
import lod from "../../Assets/loader.svg";
import selectedTab from "../../Assets/selectedaddsvg.svg";
import flashIcon from "../../Assets/flashicsvg.svg";
import NotFoundItems from "../NotFoundItems";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import { addToContract } from "api/api";
import { SortingActiveBottomIcon, SortingActiveIcon, SortingActiveTopIcon, Spinner } from "utils/icons";
import { useSelector, useDispatch } from "react-redux";
import { useSearch } from "hook/useSearch";
import { getExactDate } from "utils/helpers";
import { deadlineOptions, publishedDateOptions } from "./SearchPageSearchBar";
import moment from "moment";
import { setContractManager } from "store/reducers/contractManagerReducerSlice";
import { useNotification } from "context/notificationContext";
import { useFlags } from "launchdarkly-react-client-sdk";

const SearchTable = ({
    searchVal,
    allChips,
    offset,
    setOffset,
    setLimitQuery,
    limitQuery,
    infiniteScroll,
    setInfiniteScroll,
    searchBarRef,
    isContractDetails,
}) => {
    const dispatch = useDispatch();
    const { data: contractData } = useSelector((store) => store.contracts);
    const observer = useRef(null);
    const [addingToContract, setAddingToContract] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
    const { setToast } = useNotification();
    const { optionList } = useSelector((store) => store.utils);
    const [hasMore, setHasMore] = useState(true);
    const [size, setSize] = useState(116);
    const flags = useFlags();

    //
    let departments =
        allChips?.departments?.map((v) => optionList?.department?.find((s) => s?.name === v)?.value) || [];
    let naicsCodes = allChips?.naics_codes?.map((v) => optionList?.naics_code?.find((s) => s?.name === v)?.value) || [];
    let productCodes =
        allChips?.product_codes?.map((v) => optionList?.product_code?.find((s) => s?.name === v)?.value) || [];
    let stages = allChips?.notice_type?.map((v) => optionList?.notice_type?.find((s) => s?.name === v)?.value) || [];
    let states = allChips?.state?.map((v) => optionList?.state?.find((s) => s?.name === v)?.value) || [];
    let queryPublishDate = () => {
        let end = "";
        let start = getExactDate(allChips?.publishedDate, publishedDateOptions, "past");
        if (start?.length > 0) {
            end = moment().format("YYYY-MM-DD");
        }
        return { start, end };
    };
    let queryDeadlineDate = () => {
        let start = "";
        let end = getExactDate(allChips?.response_deadline, deadlineOptions);
        if (end?.length > 0) {
            start = moment().format("YYYY-MM-DD");
        }
        return { start, end };
    };

    const searchListDelimiter = "||";
    const { result, loadingResult, setResult } = useSearch(
        `/contracts/search?search_queries=${searchVal}&departments=${departments?.join(
            searchListDelimiter
        )}&notice_types=${stages?.join(searchListDelimiter)}&naics_codes=${naicsCodes?.join(
            searchListDelimiter
        )}&product_codes=${productCodes?.join(searchListDelimiter)}&performance_place_states=${states.join(
            searchListDelimiter
        )}&publish_date_start=${queryPublishDate()?.start}&publish_date_end=${
            queryPublishDate()?.end
        }&response_deadline_start=${queryDeadlineDate()?.start}&response_deadline_end=${
            queryDeadlineDate()?.end
        }&order_by=${sortConfig.key ? (sortConfig.direction === "descending" ? "-" : "") : ""}${
            sortConfig.key
        }&${limitQuery}`,
        false,
        "result",
        infiniteScroll,
        setHasMore,
        offset
    );

    const addItemToContract = (item) => {
        if (addingToContract) return;
        setAddingToContract(item?.id);

        addToContract({
            contract_id: item?.id,
        })
            .then((res) => {
                // update search table data
                let prevList1 = [...result];
                let index = result?.findIndex((v) => v?.id === item?.id);
                if (index >= 0) {
                    prevList1[index] = {
                        ...prevList1[index],
                        added_to_contracts: true,
                    };
                }
                // update contract tab data, add new contract locally in contracts
                let respObj = res.data;
                let obj = {
                    starred: false,
                    status: 0,
                    added_to_contracts: true,
                    contract: {
                        contact_information: {
                            office_address: "",
                            primary_point_of_contact: { name: "", email: "" },
                            secondary_point_of_contact: { name: "", email: "" },
                        },
                        naics_codes: [""],
                        title: item?.title,
                        department: item?.department,
                        publish_date: item?.publish_date,
                        response_date: item?.response_date,
                        opportunity_stage: item?.opportunity_stage,
                        id: respObj?.id,
                    },
                    id: respObj?.id,
                };
                let updatedContracts = [...contractData];
                updatedContracts.push(obj);
                dispatch(setContractManager({ data: updatedContracts, loading: false }));
                setResult(prevList1);
                setAddingToContract(null);
            })
            .catch((err) => {
                setToast.error({
                    title: "Oops!",
                    msg: "Something went wrong!",
                });
                console.log("Error while adding to contract manager from main tab. ", err);
                setAddingToContract(null);
            });
    };

    // update sort config key and order in state
    const handleSorting = (key, direction) => {
        setSortConfig({ key, direction: direction });
    };

    // render sort icon
    const renderSortIcon = (key) => {
        if (sortConfig?.key !== key) {
            return (
                <SortingActiveIcon
                    onclick1={() => handleSorting(key, "ascending")}
                    onclick2={() => handleSorting(key, "descending")}
                />
            );
        }
        switch (sortConfig?.direction) {
            case "ascending":
                return (
                    <SortingActiveTopIcon
                        onclick1={() => handleSorting(key, "ascending")}
                        onclick2={() => handleSorting(key, "descending")}
                    />
                );
            case "descending":
                return (
                    <SortingActiveBottomIcon
                        onclick1={() => handleSorting(key, "ascending")}
                        onclick2={() => handleSorting(key, "descending")}
                    />
                );
            default:
                return (
                    <SortingActiveIcon
                        onclick1={() => handleSorting(key, "ascending")}
                        onclick2={() => handleSorting(key, "descending")}
                    />
                );
        }
    };

    // handle sort click event on table headers
    const handleSortEvent = (key) => {
        if (sortConfig?.key === key) {
            if (sortConfig?.direction === "ascending") {
                setInfiniteScroll(false);
                setLimitQuery(`limit=${result?.length || 50}&offset=0`);
                handleSorting(key, "descending");
            } else {
                setInfiniteScroll(true);
                let v = result.length;
                setOffset(0);
                handleSorting("", "");
                setTimeout(() => {
                    setLimitQuery(`limit=${v || 50}&offset=0`);
                }, 120);
            }
        } else {
            setInfiniteScroll(false);
            handleSorting(key, "ascending");
            setLimitQuery(`limit=${result?.length || 50}&offset=0`);
        }
    };

    //
    const lastEle = useCallback(
        (node) => {
            if (loadingResult) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0]?.isIntersecting && hasMore) {
                    setInfiniteScroll(true);
                    let newOffset = result?.length > 0 ? offset + 50 : 0;
                    setOffset(newOffset);
                    setLimitQuery(`limit=50&offset=${newOffset}`);
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasMore, loadingResult, offset, result?.length, setInfiniteScroll, setLimitQuery, setOffset]
    );

    useLayoutEffect(() => {
        if (!searchBarRef?.current) return;
        const updateSize = () => {
            let searhcbarHeight = searchBarRef?.current?.clientHeight || 72;
            const newSize = searhcbarHeight + 41;
            setSize(newSize);
        };
        setTimeout(() => updateSize(), 0);
        window.addEventListener("resize", updateSize);
        return () => {
            window.removeEventListener("resize", updateSize);
        };
    }, [searchBarRef, flags.topBanner]);

    return (
        <>
            <div
                className="max-w-full overflow-x-visible  border bg-white rounded-lg m-4 scrollbar-0"
                style={{
                    height: `calc(100% - ${size}px)`,
                    display: isContractDetails ? "none" : "",
                }}
            >
                <table className="table-ato w-full h-full overflow-y-auto block">
                    <thead className="text-sm sticky top-0 left-0 bg-white z-[22]">
                        <tr className="text-left rounded-t-lg">
                            <th className="pl-6 pr-4 py-5 font-[550] w-[50%] rounded-tl-lg">
                                <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                    <span onClick={() => handleSortEvent("title")} className="block pr-1">
                                        Title
                                    </span>
                                    <span className="p-[2px] block">{renderSortIcon("title")}</span>
                                </p>
                            </th>
                            <th className="px-2 py-5 font-[550] min-[1500px]:min-w-[290px] min-w-[200px]">
                                <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                    <span onClick={() => handleSortEvent("department")} className="block pr-1">
                                        Department
                                    </span>
                                    <span className="p-[2px] block">{renderSortIcon("department")}</span>
                                </p>
                            </th>
                            <th className="px-4 py-5 font-[550] min-w-[180px] w-[180px] max-w-[180px]">
                                <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                    <span onClick={() => handleSortEvent("opportunity_stage")} className="block pr-1">
                                        Stage
                                    </span>
                                    <span className="p-[2px] block">{renderSortIcon("opportunity_stage")}</span>
                                </p>
                            </th>
                            <th className="px-4 py-5 font-[550] min-w-[140px] w-[140px] max-w-[140px]">
                                <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                    <span onClick={() => handleSortEvent("response_date")} className="block pr-1">
                                        Deadline
                                    </span>
                                    <span className="p-[2px] block">{renderSortIcon("response_date")}</span>
                                </p>
                            </th>
                            <th className="px-2 py-5 font-[550] w-[140px]">
                                <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                    <span onClick={() => handleSortEvent("publish_date")} className="block pr-1">
                                        Published Date
                                    </span>
                                    <span className="p-[2px] block">{renderSortIcon("publish_date")}</span>
                                </p>
                            </th>
                            <th className="px-4 py-5 font-[550] w-[120px] ">
                                <p className="flex w-max max-w-full items-center">Viewed by</p>
                            </th>
                            <th className="pr-4 pl-1 py-5 font-[550] min-w-[80px] rounded-tr-lg">
                                <p className="flex gap-3 items-center">
                                    <span className="h-7 bg-gray-200 w-[1px]"></span>
                                    <span className="mx-auto block">Add</span>
                                </p>
                            </th>
                        </tr>
                        <tr>
                            <th className="h-[1px] bg-gray-200" colSpan="7"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingResult && result?.length === 0 ? (
                            <tr>
                                <td colSpan="7">
                                    <div className="min-h-[64vh] bg-white rounded-b-lg w-full flex justify-center items-center flex-col py-7 px-2">
                                        <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
                                    </div>
                                </td>
                            </tr>
                        ) : result?.length === 0 ? (
                            <tr>
                                <td colSpan="7">
                                    <NotFoundItems
                                        title="No Search Result"
                                        subTitle="There are no contracts that match your search"
                                        className={"min-h-[64vh]"}
                                    />
                                </td>
                            </tr>
                        ) : (
                            <>
                                {result?.map((searchItem, i) => {
                                    return (
                                        <tr
                                            ref={result?.length === i + 1 ? lastEle : null}
                                            className={`group/row ${result?.length === i + 1 ? "" : "border-b"}`}
                                            key={i}
                                        >
                                            <td className="rounded-bl-lg pl-2 w-[50%] group-hover/row:bg-[#f5f5f5] max-w-[270px]">
                                                <div className="flex items-center gap-2">
                                                    <Link
                                                        to={`/dashboard/search/contracts-details/?id=${searchItem?.id}`}
                                                        className="w-[100%] block p-4 pr-2 text-ellipsis line-clamp-2 text-sm"
                                                    >
                                                        {searchItem?.title}
                                                    </Link>
                                                    <div className="group relative py-3 mr-3">
                                                        <img src={group} alt="" className="inline-block" />
                                                        <div className="absolute font-inter font-semibold w-max max-w-[270px] top-[12px] bottom-auto left-[39px] hidden rounded border-[2px] border-[#6CD2DA] bg-white shadow-xl  group-hover:block z-20">
                                                            <div className="menu-arrow !left-[0] !top-[10px] !bg-[#6CD2DA]"></div>
                                                            <div className="bg-white p-2">
                                                                <div className="flex items-center">
                                                                    <img
                                                                        src={flashIcon}
                                                                        alt=""
                                                                        className="inline-block"
                                                                    />
                                                                    <span className="text-[#6CD2DA] text-[14px] ml-[3px]">
                                                                        AI Overview
                                                                    </span>
                                                                </div>
                                                                <p className="text-xs text-gray-primary pt-1 font-normal">
                                                                    {searchItem?.summarized_description &&
                                                                    searchItem?.summarized_description?.length > 0
                                                                        ? searchItem?.summarized_description
                                                                        : "AI overview could not be generated"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-[1500px]:min-w-[290px] min-w-[200px] group-hover/row:bg-[#f5f5f5]">
                                                <Link
                                                    to={`/dashboard/search/contracts-details/?id=${searchItem?.id}`}
                                                    className="w-[100%] block py-4 px-2 text-ellipsis line-clamp-2 text-sm"
                                                >
                                                    {searchItem?.department || "-"}
                                                </Link>
                                            </td>
                                            <td className=" min-w-[140px] w-[140px] max-w-[140px] group-hover/row:bg-[#f5f5f5]">
                                                <Link
                                                    to={`/dashboard/search/contracts-details/?id=${searchItem?.id}`}
                                                    className="w-[100%] block p-4 text-ellipsis line-clamp-2 text-sm"
                                                >
                                                    {searchItem?.opportunity_stage || "-"}
                                                </Link>
                                            </td>
                                            <td className="w-[140px] group-hover/row:bg-[#f5f5f5]">
                                                <Link
                                                    to={`/dashboard/search/contracts-details/?id=${searchItem?.id}`}
                                                    className="w-[100%] block p-4 px1 text-sm"
                                                >
                                                    {searchItem?.response_date || "-"}
                                                </Link>
                                            </td>
                                            <td className="w-[140px] group-hover/row:bg-[#f5f5f5]">
                                                <Link
                                                    to={`/dashboard/search/contracts-details/?id=${searchItem?.id}`}
                                                    className="w-[100%] block p-2 text-sm"
                                                >
                                                    {searchItem?.publish_date || "-"}
                                                </Link>
                                            </td>
                                            <td className="px-4 py-2 w-[120px]  group-hover/row:bg-[#f5f5f5]">
                                                <div className="flex scrollbar-none items-center relative group py-3">
                                                    <div className="flex items-center -space-x-[11px] mr-[2px]">
                                                        {searchItem?.viewed_by?.length > 0
                                                            ? searchItem?.viewed_by
                                                                  ?.slice(0, 3)
                                                                  ?.map((user, i) => (
                                                                      <Avatar
                                                                          id={user?.toString() || ""}
                                                                          key={i}
                                                                          src=""
                                                                          alt={user?.toString() || ""}
                                                                          width={33}
                                                                          height={33}
                                                                      />
                                                                  ))
                                                            : "-"}
                                                    </div>
                                                    {searchItem?.viewed_by?.length > 2 && (
                                                        <span className="text-sm text-[#CF5800]">+</span>
                                                    )}
                                                    {searchItem?.viewed_by?.length > 0 && (
                                                        <div className="absolute w-max top-[100%] hidden cursor-pointer rounded-[6px] left-[50%] translate-x-[-50%] bg-white shadow-[0_0_15px_rgba(0,0,0,0.1)] group-hover:block z-20">
                                                            <div className="px-3 py-[0.67rem] max-h-[300px] overflow-y-auto">
                                                                {searchItem?.viewed_by?.map((user, i) => (
                                                                    <div
                                                                        key={i}
                                                                        className={`flex items-center min-w-[120px] max-w-[170px] ${
                                                                            i + 1 === searchItem?.viewed_by?.length
                                                                                ? ""
                                                                                : "mb-2"
                                                                        }`}
                                                                    >
                                                                        <Avatar
                                                                            id={user?.toString()}
                                                                            src=""
                                                                            alt={user?.toString()}
                                                                            key={i}
                                                                            width={24}
                                                                            height={24}
                                                                            className="mr-2 !text-[13px]"
                                                                        />
                                                                        <p className="font-inter text-xs text-gray-primary line-clamp-1 text-ellipsis w-full">
                                                                            {user?.toString()}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="px-4 py-2 min-w-[80px] group-hover/row:bg-[#f5f5f5] ">
                                                <div className="flex pl-1 w-full justify-center">
                                                    {searchItem?.added_to_contracts ? (
                                                        <div className="group relative py-3">
                                                            <img src={selectedTab} alt="" className="" />
                                                            {/* <CheckMarkIcon fill="true" /> */}
                                                            <div className="absolute w-max max-w-[177px] top-[100%] hidden rounded border-[2px] border-[#dbe0e5] right-[-22px] bg-white shadow-xl  group-hover:block z-20">
                                                                <div className="menu-arrow !right-[26px]"></div>
                                                                <div className="bg-white p-2">
                                                                    <p className="font-inter text-xs font-medium text-gray-primary">
                                                                        Already in projects
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : searchItem?.id && searchItem?.id === addingToContract ? (
                                                        <div className="group relative py-[1.04rem] items-center w-[30px] text-center">
                                                            <Spinner width={13} height={13} classes="!text-black" />
                                                        </div>
                                                    ) : (
                                                        <div className="group relative py-3">
                                                            <img
                                                                onClick={() => {
                                                                    addItemToContract(searchItem);
                                                                }}
                                                                src={add}
                                                                alt=""
                                                                className={`${
                                                                    addingToContract && addingToContract?.length > 0
                                                                        ? "pointer-events-none opacity-40"
                                                                        : "cursor-pointer"
                                                                }`}
                                                            />
                                                            {addingToContract && addingToContract?.length > 0 ? null : (
                                                                <div className="absolute w-max max-w-[177px] top-[100%] hidden rounded border-[2px] border-[#dbe0e5] right-[-22px] bg-white shadow-xl group-hover:block z-20">
                                                                    <div className="menu-arrow !right-[26px]"></div>
                                                                    <div className="bg-white p-2">
                                                                        <p className="font-inter text-xs font-medium text-gray-primary">
                                                                            Add to projects
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {/* {result?.map((s, i) => {
                                    if (result?.length === i + 1) {
                                        return <tr key={i} ref={lastEle}>
                                            <td colSpan="7"><span ></span></td>
                                        </tr>
                                    } else {
                                        return null
                                    }
                                })} */}

                                {loadingResult || hasMore ? (
                                    <tr>
                                        <td colSpan="7">
                                            <div className="p-6 flex justify-center items-center">
                                                <Spinner classes="!text-black" />
                                            </div>
                                        </td>
                                    </tr>
                                ) : null}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SearchTable;
