import { ReactComponent as ArrowRightNav } from "Assets/svgs/arrow-right-nav.svg";
import Tabs from "components/organisms/tabs";
import { useMemo } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FolderNode, NavHistory, setDocumentNavHistory, setMediaNavHistory } from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { usePollDrive, useTabs } from "./hooks";
import { TabSlug } from "./types";

const Drive = () => {
    const navigate = useNavigate();
    const {
        activeTabState: [activeDriveTab, setActiveDriveTab],
        driveTabs,
    } = useTabs();
    usePollDrive();

    const dispatch = useAppDispatch();
    const { navHistory: docNavHistory, allFolderNodes: docAllFolderNodes } = useAppSelector(
        (root) => root.drive.fileStorage
    );
    const { navHistory: mediaNavHistory, allFolderNodes: mediaAllFolderNodes } = useAppSelector(
        (root) => root.drive.media
    );

    const getNavFolders = (nav: NavHistory, folderNodes: { [id: string]: FolderNode }) => {
        const result: FolderNode[] = [];
        let cur: string | undefined = nav.id;
        while (cur && cur in folderNodes) {
            result.unshift(folderNodes[cur]);
            if (result.length >= 10) {
                break; // we don't want to render more than 10 folders
            }
            cur = folderNodes[cur].parentId;
        }
        return result;
    };

    const mediaNavFolders = useMemo(() => {
        return getNavFolders(mediaNavHistory[mediaNavHistory.length - 1] ?? {}, mediaAllFolderNodes);
    }, [mediaNavHistory, mediaAllFolderNodes]);

    const docNavFolders = useMemo(() => {
        return getNavFolders(docNavHistory[docNavHistory.length - 1] ?? {}, docAllFolderNodes);
    }, [docNavHistory, docAllFolderNodes]);

    return (
        <div className="overflow-hidden flex flex-col">
            <div className="z-[1] mt-3 shadow-sm bg-white flex gap-3 justify-between">
                <Tabs<TabSlug>
                    className="mx-6 !border-b-0 !gap-6"
                    tabs={driveTabs}
                    activeTab={activeDriveTab}
                    onTabSelect={(tab) => {
                        if (activeDriveTab.slug === TabSlug.Media) {
                            dispatch(setMediaNavHistory([]));
                        } else if (activeDriveTab.slug === TabSlug.Documents) {
                            dispatch(setDocumentNavHistory([]));
                        }
                        setActiveDriveTab(tab);
                        navigate(`${tab.slug}`);
                    }}
                />
                {activeDriveTab.slug === TabSlug.Media && (
                    <div className="mx-6 flex items-center gap-2 justify-center">
                        {mediaNavHistory.length > 0 && (
                            <Link
                                className="text-gray-text hover:underline"
                                to={`/dashboard/library/media`}
                                onClick={() => dispatch(setMediaNavHistory([]))}
                            >
                                Drive
                            </Link>
                        )}
                        {mediaNavFolders.map((item, index) => (
                            <div className="flex items-center gap-2 justify-center" key={index}>
                                <ArrowRightNav />
                                <Link
                                    className="text-gray-text hover:underline"
                                    to={`/dashboard/library/media/folder/${item.id}`}
                                    onClick={() =>
                                        dispatch(
                                            setMediaNavHistory([
                                                {
                                                    name: item.name,
                                                    id: item.id,
                                                },
                                            ])
                                        )
                                    }
                                >
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                )}

                {activeDriveTab.slug === TabSlug.Documents && (
                    <div className="mx-6 flex items-center gap-2 justify-center">
                        {docNavHistory.length > 0 && (
                            <Link
                                className="text-gray-text hover:underline"
                                to={`/dashboard/library/documents`}
                                onClick={() => dispatch(setDocumentNavHistory([]))}
                            >
                                Drive
                            </Link>
                        )}
                        {docNavFolders.map((item, index) => (
                            <div className="flex items-center gap-2 justify-center" key={index}>
                                <ArrowRightNav />
                                <Link
                                    className="text-gray-text hover:underline"
                                    to={`/dashboard/library/documents/folder/${item.id}`}
                                    onClick={() =>
                                        dispatch(
                                            setDocumentNavHistory([
                                                {
                                                    name: item.name,
                                                    id: item.id,
                                                },
                                            ])
                                        )
                                    }
                                >
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="overflow-y-auto">
                <Outlet />
            </div>
        </div>
    );
};

export default Drive;
