import Popover from "components/atoms/popover";
import { Props as PopoverProps } from "components/atoms/popover/Popover";
import { PropsWithChildren, useState } from "react";
import { BaseFile } from "types/FileStorage";
import ContentDrivePopoverContent from "./ContentDrivePopoverContent";

interface Props extends Omit<PopoverProps, "content"> {
    handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
    selectedFiles: string[];
    onClear: (files: BaseFile[]) => void;
}

const ContentDrivePopover = ({ handleSelectedFiles, onClear, selectedFiles, ...props }: PropsWithChildren<Props>) => {
    const [query, setQuery] = useState("");

    return (
        <Popover
            content={
                <ContentDrivePopoverContent
                    query={query}
                    handleSelectedFiles={handleSelectedFiles}
                    onClear={onClear}
                    selectedFiles={selectedFiles}
                />
            }
            searchable
            searchProps={{
                value: query,
                onChange: (e) => setQuery(e.target.value),
                placeholder: "Search content library documents...",
            }}
            {...props}
        />
    );
};

export default ContentDrivePopover;
