import { Link } from "react-router-dom";
import open from "../../../Assets/open.svg";

const ContractOpportunity = ({ contractDetails }) => {
    return (
        <div className="border border-[#D8DDE2] shadow-sm p-4 rounded-lg mb-4 bg-white">
            <h3 className="text-base font-medium pb-3">Contract Opportunity</h3>
            <div className="flex flex-col gap-4">
                {contractDetails?.public && (
                    <div className="flex flex-col gap-3 pt-2">
                        <span className="text-[#5B6B79]">Source ID</span>
                        <span className="flex items-center break-all gap-2 text-sm">
                            <span>{contractDetails?.notice_id}</span>
                            {contractDetails?.notice_id && (
                                <Link
                                    to={`https://sam.gov/opp/${contractDetails?.notice_id}/view`}
                                    target="_blank"
                                    className="min-w-[14px] text-center"
                                >
                                    <img className="cursor-pointer select-none" src={open} alt="open" />
                                </Link>
                            )}
                        </span>
                    </div>
                )}
                <div className="flex flex-col gap-3 pt-2">
                    <span className="text-[#5B6B79]">Department</span>
                    <span className="text-sm">{contractDetails?.department || "-"}</span>
                </div>
                {contractDetails?.public && (
                    <div className="flex flex-col gap-3 pt-2">
                        <span className="text-[#5B6B79]">Subtier</span>
                        <span className="text-sm">{contractDetails?.subtier || "-"}</span>
                    </div>
                )}
                {contractDetails?.public && (
                    <div className="flex flex-col gap-3 pt-2">
                        <span className="text-[#5B6B79]">Office</span>
                        <span className="text-sm">{contractDetails?.office || "-"}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContractOpportunity;
