import { LiveList, LiveObject } from "@liveblocks/client";
import { SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { Section, Volume } from "components/copilot/CopilotSchemaTypes";
import { nanoid } from "nanoid";

export const createVolume = (properties?: Partial<Volume>): LiveObject<Volume> => {
    const newID = nanoid();
    return new LiveObject({
        id: newID,
        title: "",
        sections: new LiveList([]),
        ...properties,
    });
};

export const createSection = (properties?: Partial<Section>): LiveObject<Section> => {
    const newID = nanoid();
    return new LiveObject({
        id: newID,
        title: "",
        proposal: "",
        proposal_sources: new LiveList([]),
        assignees: [],
        status: SectionStatus.Todo,
        ...properties,
    });
};
