import { LiveObject } from "@liveblocks/client";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { nanoid } from "nanoid";

export const createSheet = (properties: Partial<Sheet> & Pick<Sheet, "name">): LiveObject<Sheet> => {
    const newID = nanoid();
    return new LiveObject({
        id: newID,
        ...properties,
    });
};
