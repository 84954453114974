import { EnsureJson, LiveList, LiveObject } from "@liveblocks/client";
import { Step } from "types/ProjectProgress";
import {
    AssistantBlockType,
    BlockSource,
    BaseSource,
    Sheet,
    SectionStatus,
    WritingPrompt,
    UserInstruction,
    ResponseSource,
    RequirementStatus,
    RequirementCompliance,
} from "./CopilotSchemaImmutableTypes";

// Compliance Matrix
export type Requirement = {
    id: string;
    content: string;
    summarized_content?: string; // (legacy)
    section: string; // RFP Subsection
    header: string; // RFP Section
    disregarded?: boolean;
    skipped?: boolean;
    section_order?: number;
    page_number?: string;
    extraction_number?: string;
    extraction_id?: string;
    matched_keywords?: string[];
    generated_heading?: string;
};

export type ProposalReference = {
    volume_id?: string; // Maps to the volume id in framework (Proposal Volume)
    section_id?: string; // Maps to section id (Proposal Section)
    subsection_id?: string; // Maps to section id of section with parent_id (Proposal Section)
    paragraph?: number;
};

export type Document = {
    id: string;
    name: string;
};

export type Bolt = {
    id: string;
    bolt_class?: number;
    title: string;
    full_content: string;
};

export type File = {
    id: string;
    name: string;
    content?: string;
    file_extension_type?: string;
    created_at?: string;
};

export enum SourceType {
    AI = "ai_generated",
    ContentDrive = "content_drive",
    Custom = "custom_response",
}

export type ComplianceMatrixRowContentBody = {
    content: string;
    reference_id?: string | null;
    source_name: string;
    source_type: SourceType;
    source_extension_type?: string;
    source_content: string;
};

export type ComplianceMatrixRow = {
    requirement: LiveObject<Requirement>;
    proposal_reference: LiveObject<ProposalReference>;
    document?: LiveObject<Document>;
    compliance_status?: RequirementCompliance | "" | null;
    risk_status?: "Low" | "Medium" | "High" | "";
    requirement_status?: RequirementStatus | "" | null;
    notes: string;
    bolts: LiveList<Bolt>;
    written_content: string;
    generated_content_ideas_v2?: LiveList<ComplianceMatrixRowContentBody>;
    selected_content_v2?: LiveList<ComplianceMatrixRowContentBody>;
    assigned_user_ids?: string[];
    response_generated?: boolean;
    is_response_generating?: boolean;
    is_response_in_queue?: boolean;
    auto_response_actor?: string;
    writing_prompts?: LiveList<LiveObject<WritingPrompt>>;
    user_instructions?: LiveList<LiveObject<UserInstruction>>;
    response_sources?: LiveList<LiveObject<ResponseSource>>;
    locked?: boolean;
    locked_actor?: string;
};

// Framework
export type Section = {
    id: string;
    title: string;
    parent_id?: string;
    annotate_proposal?: boolean;
    theme?: string;
    theme_active?: boolean;
    proposal?: string;
    proposal_generated?: boolean;
    locked?: boolean;
    proposal_sources?: LiveList<LiveObject<BaseSource | ResponseSource>>;
    assignees?: string[];
    status?: SectionStatus;
};

export type Volume = {
    id: string;
    title: string;
    sections: LiveList<LiveObject<Section>>;
    updated_at?: string;
    assignees?: string[];
};

export type Template = {
    id?: string; // (legacy or imported if empty)
    isDirty?: boolean;
};

export type Framework = {
    volumes: LiveList<LiveObject<Volume>>;
    template?: LiveObject<Template>;
};

export type WinTheme = {
    id: string;
    content: string;
};

export type Progress = { steps: LiveList<LiveObject<EnsureJson<Step>>>; completed: boolean };

export type BaseBlock = {
    id: string;
    updated_at: string;
    body: string;
    sources: LiveList<LiveObject<BlockSource>>;
};

export type CoreBlock = { type: AssistantBlockType.CoreBlock } & BaseBlock;

export type VultronBlock = {
    type: AssistantBlockType.VultronBlock;
    prompt?: string;
    promptSources?: string[];
    error?: boolean;
    enableInternet?: boolean;
} & BaseBlock;

export type WritingAssistantBlock = CoreBlock | VultronBlock;

export type WritingAssistant = {
    user_id: string;
    conversation: LiveList<LiveObject<WritingAssistantBlock>>;
};

export type Storage = {
    compliance_matrix: LiveList<LiveObject<ComplianceMatrixRow>>;
    sheets?: LiveList<LiveObject<Sheet>>;
    framework: LiveObject<Framework>;
    win_themes?: LiveList<LiveObject<WinTheme>>;
    progress?: LiveObject<Progress>;
    writing_assistant?: LiveObject<{ [key: WritingAssistant["user_id"]]: LiveObject<WritingAssistant> }>;
};
