import { Interpolation, Theme } from "@emotion/react";
import { HTMLAttributes, ReactNode, useCallback } from "react";
import { Mention, MentionItem, MentionsInput, SuggestionDataItem } from "react-mentions";
import { theme } from "twin.macro";
import { getFileIcon } from "utils/getFileIcon";

export type DocumentInputContent = {
    raw: string;
    plainText: string;
    mentions: MentionItem[];
};

interface DocumentItem extends SuggestionDataItem {
    file_extension_type: string;
}

interface Props {
    documents: DocumentItem[];
    content: DocumentInputContent;
    setContent: (content: DocumentInputContent) => void;
    onEnter: () => void;
    submitDisabled?: boolean;
    css?: Interpolation<Theme>;
    height?: number;
}

const DocumentsMentionInput = ({
    documents,
    content,
    height,
    setContent,
    onEnter,
    submitDisabled,
    ...props
}: Props) => {
    const renderDocumentSuggestion = useCallback(
        (suggestion: DocumentItem, search: string, highlightedDisplay: ReactNode, index: number, focused: boolean) => {
            if (suggestion.id === "EMPTY")
                return (
                    <div
                        className="text-center py-8 px-5 text-sm text-gray-400 pointer-events-none"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {suggestion.display}
                    </div>
                );

            return (
                <div className="flex items-center gap-2 py-2 px-1 mentionItem">
                    <div className="w-4">
                        <img src={getFileIcon(suggestion.file_extension_type)} alt="icon" />
                    </div>
                    <div className="truncate max-w-[250px] text-sm">{suggestion.display}</div>
                </div>
            );
        },
        []
    );

    const handleInput = useCallback(
        (
            event: { target: { value: string } },
            newValue: string,
            newPlainTextValue: string,
            mentions: MentionItem[]
        ) => {
            if (!!mentions.find(({ id }) => id === "EMPTY")) return;

            setContent({
                raw: event.target.value,
                plainText: newPlainTextValue,
                mentions,
            });
        },
        [setContent]
    );

    return (
        <MentionsInput
            value={content.raw}
            onChange={handleInput}
            onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey && !submitDisabled) {
                    onEnter();
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            className="documentMentionInput w-full border border-gray-light rounded-md bg-gray-100"
            placeholder="What do you want to write about?"
            allowSuggestionsAboveCursor
            style={{
                "&multiLine": {
                    control: {
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        fontSize: 14,
                        border: "0px solid transparent",
                    },
                    highlighter: {
                        padding: 8,
                        border: "0px solid transparent",
                        background: theme`colors.gray.100`,
                        borderRadius: 5,
                        height: height || 83,
                        overflow: "hidden",
                        pointerEvents: "none",
                    },
                    input: {
                        padding: 8,
                        outline: "0px",
                        color: theme`colors.stone.800`,
                        background: "transparent",
                        height: height || 83,
                        overflowY: "auto",
                    },
                },
                suggestions: {
                    list: {
                        borderRadius: 6,
                        maxHeight: 270,
                        overflowY: "auto",
                        boxShadow: "0px 1px 4px rgba(0,0,0,0.2)",
                    },
                    item: {
                        cursor: documents.length ? "default" : undefined,
                        borderBottom: "1px solid rgba(0,0,0,0.1)",
                    },
                },
            }}
            {...props}
        >
            <Mention
                trigger="@"
                data={
                    documents.length
                        ? documents
                        : [
                              {
                                  id: "EMPTY",
                                  display: "No Documents",
                              },
                          ]
                }
                appendSpaceOnAdd
                style={{
                    backgroundColor: "#dae1f4",
                    borderRadius: 4,
                    zIndex: 1,
                    position: "relative",
                    color: theme`colors.action.DEFAULT`,
                }}
                // @ts-ignore
                renderSuggestion={renderDocumentSuggestion}
            />
        </MentionsInput>
    );
};

export default DocumentsMentionInput;
