import { useCallback, useMemo, useState } from "react";
import { ComplianceMatrixRow as ImmutableComplianceMatrixRow } from "../CopilotSchemaImmutableTypes";
import { ComplianceMatrixRow } from "../CopilotSchemaTypes";
import { UpdatedRequirement } from "./TableHeader";
import { Storage } from "../CopilotSchemaTypes";
import { useMutation } from "liveblocks.config";
import { createComplianceMatrixRow } from "utils/complianceMatrix";

export const useCopilotSearch = (filteredComplianceMatrix: ImmutableComplianceMatrixRow[]) => {
    const [query, setQuery] = useState("");

    const filteredResults = useMemo(
        () =>
            filteredComplianceMatrix.filter((row) =>
                (
                    row.requirement?.content?.toLowerCase() ||
                    row.requirement?.summarized_content?.toLowerCase() ||
                    ""
                ).includes(query.toLowerCase())
            ),
        [filteredComplianceMatrix, query]
    );

    return { query, setQuery, filteredResults };
};

export enum SortKey {
    RequirementText = "requirementText",
    RequirementStatus = "requirement_status",
    ComplianceStatus = "compliance_status",
    ProposalVolume = "proposalVolume",
    ProposalSection = "proposalSection",
}

export enum Direction {
    Descending = "descending",
    Ascending = "ascending",
}

type SortState = { key: SortKey; direction: Direction };

const sortMap: Record<SortKey, (a: UpdatedRequirement, b: UpdatedRequirement) => number> = {
    [SortKey.ComplianceStatus]: (a, b) =>
        a[SortKey.ComplianceStatus]?.toLowerCase()?.localeCompare(b[SortKey.ComplianceStatus]?.toLowerCase() || "") ||
        0,
    [SortKey.RequirementStatus]: (a, b) =>
        a[SortKey.RequirementStatus]?.toLowerCase()?.localeCompare(b[SortKey.RequirementStatus]?.toLowerCase() || "") ||
        0,
    [SortKey.ProposalSection]: (a, b) => a[SortKey.ProposalSection] - b[SortKey.ProposalSection],
    [SortKey.ProposalVolume]: (a, b) => a[SortKey.ProposalVolume] - b[SortKey.ProposalVolume],
    [SortKey.RequirementText]: (a, b) =>
        a[SortKey.RequirementText]?.toLowerCase().localeCompare(b[SortKey.RequirementText]?.toLowerCase()),
};

export const useSortable = (items: UpdatedRequirement[], sortActive: boolean) => {
    const [sortConfig, setSortConfig] = useState<SortState>({
        key: SortKey.RequirementText,
        direction: Direction.Ascending,
    });

    const sortedItems = useMemo(() => {
        if (!sortActive) return items;
        let sortableItems = [...items];
        if (sortConfig !== null) {
            if (sortConfig.direction === Direction.Ascending) {
                sortableItems.sort(sortMap[sortConfig.key]);
            } else sortableItems.sort(sortMap[sortConfig.key]).reverse();
        }
        return sortableItems;
    }, [items, sortActive, sortConfig]);

    const handleSorting = useCallback((sortState: Partial<SortState>) => {
        return setSortConfig((prev) => ({ ...prev, ...sortState }));
    }, []);

    return {
        items: sortedItems,
        sortConfig,
        handleSorting,
    };
};

export const useInsertRequirementRow = () => {
    const insertRequirementRow = useMutation(
        ({ storage }, index: number, partialProperties?: Partial<ComplianceMatrixRow> | undefined) => {
            const newRequirement = createComplianceMatrixRow(partialProperties);
            (storage.get("compliance_matrix") as Storage["compliance_matrix"]).insert(newRequirement, index);
        },
        [createComplianceMatrixRow]
    );

    return insertRequirementRow;
};
