import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AutopilotHealthCheck } from "types/Autopilot/HealthCheck";

type State = AutopilotHealthCheck;

const initialState: State = {
    requirement_document_tasks: [],
    requirement_text_tasks: [],
    template_document_tasks: [],
    template_text_tasks: [],
};

export const getAutopilotHealthCheck = createAsyncThunk<AutopilotHealthCheck, string>(
    "autopilotHealthCheck/getAutopilotHealthCheck",
    (internalContractId) => {
        return axios.get(`autopilot/${internalContractId}/task_statuses`).then((res) => {
            return res.data;
        });
    }
);

const autopilotHealthCheckReducer = createSlice({
    name: "autopilotHealthCheckReducer",
    initialState,
    reducers: {
        setAutopilotHealthCheck: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAutopilotHealthCheck.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const { setAutopilotHealthCheck } = autopilotHealthCheckReducer.actions;

export default autopilotHealthCheckReducer.reducer;
