import { useDispatch } from "react-redux";
import { useMutation, useStorage } from "liveblocks.config";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { Section, Storage, Volume } from "./CopilotSchemaTypes";
import {
    Storage as ImmutableStorage,
    SectionStatus,
    WritingPrompt,
    ResponseSource,
    RequirementStatus,
} from "./CopilotSchemaImmutableTypes";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { clearQueue, dequeue } from "store/reducers/requirementsSmartResponseReducer";
import { ResponseTolerance } from "types/Requirement";
import { LiveList, LiveObject, shallow } from "@liveblocks/client";
import { useNotification } from "context/notificationContext";
import axios, { AxiosResponse } from "axios";
import { setFrameworkState } from "store/reducers/templates/frameworkReducer";
import { DroppableId } from "./Framework/types";
import { useUpdateTemplate } from "./Framework/hooks";
import { addRequirementToSection } from "./Framework/utils";

import { move } from "utils/array";
import { DropResult } from "react-beautiful-dnd";
import { DRAG_TYPES } from "./contants";
import {
    GENERATED_CONTENT_DROPPABLE,
    SELECTED_CONTENT_DROPPABLE,
} from "./Framework/section-content-ideation/constants";
import {
    moveGenratedContentToSelected,
    moveSelectedContentToGenerated,
    reorderSectionIdeationGeneratedContent,
    reorderSectionIdeationSelectedContent,
} from "store/reducers/copilot/SectionIdeationReducer";
import usePageVisibility from "hook/usePageVisibility";
import { getAutopilotHealthCheck } from "store/reducers/copilot/AutopilotHealthReducer";
import { setBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { createSection, createVolume } from "utils/framework";
import { DELIMITER, HEARTBEAT } from "const-values/Stream";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

export type RequestVariables = {
    project_id: string;
    requirement: string;
    response_tolerance: ResponseTolerance;
    writing_prompts: Pick<WritingPrompt, "content" | "file_id_filters">[];
    user_instructions: string[];
};

export const useSyncResponses = () => {
    const winThemes = useStorage(
        (root) => (root.win_themes as ImmutableStorage["win_themes"])?.filter(({ content }) => !!content),
        shallow
    );
    const { generateResponseQueue, autoResponseActive } = useAppSelector((root) => root.requirementsSmartResponse);
    const { currentUser } = useAppSelector((root) => root.auth);
    const dispatch = useDispatch();
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id")?.toLocaleLowerCase();
    const generateResponseQueueRef = useRef(generateResponseQueue);
    generateResponseQueueRef.current = generateResponseQueue;
    const controller = useRef(new AbortController());
    const trackUserEvent = useTrackUserMetric();

    const { setToast } = useNotification();

    const [activelyGenerating, setActivelyGenerating] = useState<Set<string>>(new Set());

    const setIsGenerating = useMutation(({ storage }, reqId, isGenerating) => {
        if (!isGenerating) {
            setActivelyGenerating((prev) => {
                const updated = new Set(prev);
                updated.delete(reqId);
                return updated;
            });
        }

        const foundRow = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.find(
            (row) => row.get("requirement")?.get("id") === reqId
        );

        foundRow?.set("is_response_generating", isGenerating);
    }, []);

    const removeFromQueue = useMutation(
        ({ storage }, reqId) => {
            const foundRow = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.find(
                (row) => row.get("requirement")?.get("id") === reqId
            );

            foundRow?.update({
                is_response_in_queue: false,
                is_response_generating: false,
                auto_response_actor: "",
            });
            dispatch(dequeue(reqId));
            currentQueueReqId.current = "";
        },
        [dispatch, dequeue]
    );

    const setResponseGenerated = useMutation(({ storage }, reqId: string, responseGenerated: boolean) => {
        const row = storage
            .get("compliance_matrix") // @ts-ignore
            ?.find((row) => row.get("requirement")?.get("id") === reqId);

        row?.set("response_generated", responseGenerated);
    }, []);

    const initResponseStream = useMutation(
        ({ storage }, body: RequestVariables, reqId: string) => {
            controller.current = new AbortController();
            const foundRow = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.find(
                (row) => row.get("requirement")?.get("id") === reqId
            );
            if (!foundRow) return;

            foundRow?.set("written_content", "");
            foundRow?.set("response_sources", new LiveList([]));
            const isStatusTodo =
                (foundRow?.get("requirement_status") || RequirementStatus.Todo) === RequirementStatus.Todo;

            fetchEventSource(`${process.env.REACT_APP_BASE_URL}/requirements/generate/sources/response/stream`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Workspace: `Workspace ${workspace_id}`,
                    Authorization: `Bearer ${localValue}`,
                    Accept: "application/json",
                },
                body: JSON.stringify(body),
                signal: controller.current?.signal,
                onmessage(msg) {
                    if (msg.event === "FatalError") {
                        removeFromQueue(reqId);
                    }

                    if (!!msg.data?.length) {
                        if (msg.data === "*") return;

                        try {
                            const parsed: Record<"sources", ResponseSource[]> = JSON.parse(msg.data);
                            if (typeof parsed !== "object" || !parsed.sources) throw new Error("error");
                            const liveSources = parsed.sources?.map((source) => new LiveObject(source));
                            foundRow.set("response_sources", new LiveList(liveSources));
                        } catch {
                            if (msg.data === HEARTBEAT) {
                                return;
                            } else if (msg.data !== DELIMITER) {
                                foundRow?.set("written_content", `${foundRow?.get("written_content")}${msg.data}`);

                                if (isStatusTodo) foundRow?.set("requirement_status", RequirementStatus.InProgress);
                            }
                        }
                    } else if (typeof msg.data === "string") {
                        foundRow?.set("written_content", `${foundRow?.get("written_content")}\n`);
                    }
                },
                async onopen(response) {
                    if (response.status === 204) {
                        dispatch(setRequirementsState({ responseErrorRequirementId: reqId }));
                    } else if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                        foundRow?.set("written_content", "");
                        foundRow?.set("response_sources", new LiveList([]));
                        return; // everything's good
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                        setToast.error({
                            title: "Unable to generate content",
                            msg: "We were unable to generate content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                        });
                        removeFromQueue(reqId);
                        // client-side errors are usually non-retriable:
                        // throw new FatalError();
                    } else {
                        // throw new RetriableError();
                    }
                },
                onclose() {
                    trackUserEvent("Drafts: Requirement Response Generated", {
                        requirement_id: String(reqId),
                        word_count: getWordCount(foundRow?.get("written_content")),
                        sensitivity: String(body.response_tolerance),
                        number_sources: foundRow?.get("response_sources")?.length || 0,
                        file_ids: foundRow?.get("response_sources")?.map((source) => source.get("file_id")) || [],
                    });

                    setTimeout(() => removeFromQueue(reqId), 500);
                    setResponseGenerated(reqId, true);
                    // if the server closes the connection unexpectedly, retry:
                    // throw new RetriableError();
                },
                onerror(err) {
                    setToast.error({
                        title: "Unable to generate response",
                        msg: "We were unable to generate a response due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                    removeFromQueue(reqId);
                    if (err instanceof Error) {
                        throw err; // rethrow to stop the operation
                    } else {
                        // do nothing to automatically retry. You can also
                        // return a specific retry interval here.
                    }
                },
            });
        },
        [localValue, removeFromQueue, setResponseGenerated, setToast, workspace_id]
    );

    let currentQueueReqId = useRef("");

    const isNewQueueReq = currentQueueReqId.current !== generateResponseQueue[0]?.requirement?.id;

    useEffect(() => {
        if (!autoResponseActive) {
            controller.current?.abort();
            abortLocalAutoResponse();
            dispatch(clearQueue());
        }
        dispatch(setBannerState({ smartResponse: { open: autoResponseActive, forceClose: false } }));
    }, [autoResponseActive, dispatch]);

    const abortLocalAutoResponse = useMutation(
        ({ storage }) => {
            const rows = storage.get("compliance_matrix") || [];
            const queuedRowsByCurrentUser = (rows as Storage["compliance_matrix"])?.filter(
                (row) => row.get("auto_response_actor") === currentUser?.id
            );

            if (!queuedRowsByCurrentUser?.length) return;

            queuedRowsByCurrentUser.forEach((row) => {
                row.update({ is_response_in_queue: false, is_response_generating: false, auto_response_actor: "" });
            });
            currentQueueReqId.current = "";

            setToast.success({
                msg: "Response generations stopped",
            });
        },
        [currentUser, dispatch]
    );

    const abortAllAutoResponse = useMutation(
        ({ storage }) => {
            const rows = storage.get("compliance_matrix") || [];
            (rows as Storage["compliance_matrix"])?.forEach((row) => {
                row.update({ is_response_in_queue: false, is_response_generating: false, auto_response_actor: "" });
            });
            controller.current?.abort();
            currentQueueReqId.current = "";
            dispatch(clearQueue());
            setToast.success({
                msg: "All response generations stopped",
            });
        },
        [dispatch]
    );

    useEffect(() => {
        const row = generateResponseQueue[0];

        if (!!projectId && isNewQueueReq && !!row && !row.is_response_generating) {
            currentQueueReqId.current = row.requirement?.id;
            setIsGenerating(row.requirement?.id, true);
            setActivelyGenerating((prev) => new Set(prev.add(row.requirement?.id)));
            initResponseStream(
                {
                    project_id: projectId,
                    requirement: row.requirement.content || row.requirement.summarized_content || "",
                    response_tolerance: row.response_tolerance,
                    writing_prompts: (row.writing_prompts || [])
                        .filter((prompt) => !!prompt.content.trim())
                        .map(({ id, ...rest }) => rest),
                    user_instructions: (row.user_instructions || [])
                        .filter((prompt) => !!prompt.content.trim())
                        .map(({ content }) => content),
                },
                row.requirement.id
            );
        }
    }, [
        activelyGenerating,
        initResponseStream,
        projectId,
        generateResponseQueue,
        setIsGenerating,
        dispatch,
        isNewQueueReq,
        removeFromQueue,
        winThemes,
    ]);

    useEffect(() => {
        const beforeUnloadCallback = (event: any) => {
            if (generateResponseQueueRef.current?.length) {
                event.preventDefault();
                event.returnValue = ""; // * required for browser compatibility
                return ""; // * required for browser compatibility
            }
            return "";
        };
        const unloadCallback = () => {
            controller.current?.abort();
            abortLocalAutoResponse();
            dispatch(clearQueue());
        };
        const onVisibileChange = () => {
            if (document.visibilityState === "hidden") {
                controller.current?.abort();
                abortLocalAutoResponse();
                dispatch(clearQueue());
            }
        };

        window.addEventListener("visibilitychange", onVisibileChange);
        window.addEventListener("beforeunload", beforeUnloadCallback);
        window.addEventListener("unload", unloadCallback);

        // return cleanup function to cancel listener and call function
        // is simply unmounting
        return () => {
            window.removeEventListener("visibilitychange", onVisibileChange);
            window.removeEventListener("unload", unloadCallback);
            window.removeEventListener("beforeunload", beforeUnloadCallback);
            controller.current?.abort();
            abortLocalAutoResponse();
            dispatch(clearQueue());
        };
    }, []);

    return { abortAllAutoResponse, dispatch };
};

type ReviseVariables = {
    project_id: string;
    requirement?: string;
    previous_response: string;
    user_feedback: string;
    win_themes: string[];
};

export const useAIRevise = (onSuccess: (text: string) => void) => {
    const { setToast } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [revisedText, setRevisedText] = useState("");

    const reviseText = (data: ReviseVariables) => {
        setIsLoading(true);
        axios
            .post<any, AxiosResponse<{ response: string }, any>, ReviseVariables>(
                `requirements/generate/feedback/response`,
                data
            )
            .then(({ data }) => {
                if (!data.response) setRevisedText(data.response);
                onSuccess(data.response);
            })
            .catch(() => {
                setToast.error({
                    title: "Unable to revise content",
                    msg: "We were unable to revise the content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        isLoading,
        reviseText,
        revisedText,
    };
};

export const useDrag = () => {
    const { activeTab: activeRequirementsDrawerTab } = useAppSelector((root) => root.requirementsDrawer);
    const updateTemplate = useUpdateTemplate();
    const dispatch = useAppDispatch();

    const frameworkDragEnd = useMutation(
        ({ storage }, results: DropResult) => {
            const { source, destination, type, draggableId } = results;
            if (!destination) return;

            const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
            const complianceMatrixRows = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            dispatch(
                setFrameworkState({
                    selectedSection: undefined,
                })
            );
            if (type === "TEMPLATE_VOLUMES") {
                const sourceIndex = source.index;
                const destinationIndex = destination?.index;
                volumeList.move(sourceIndex, destinationIndex);
            } else if (type === "TEMPLATE_REQUIREMENTS") {
                const sourceDroppableId = source?.droppableId; // source section id or drawer
                const destinationDroppableId = destination?.droppableId; // destination section id or drawer
                const isReordingWithinSection =
                    destinationDroppableId !== DroppableId.RequirementDrawer &&
                    destinationDroppableId === sourceDroppableId;
                // const isDroppingIntoDrawerFromOutside =
                //     sourceDroppableId !== DroppableId.RequirementDrawer &&
                //     destinationDroppableId === DroppableId.RequirementDrawer;
                // const isReordingWithinDrawer =
                //     destination?.droppableId === DroppableId.RequirementDrawer &&
                //     source.droppableId === DroppableId.RequirementDrawer;
                const activeDraggableTabPrefix = draggableId.match(
                    /drawer:unassigned:|drawer:assigned:|drawer:disregarded:/
                )?.[0];
                const activeDraggableRequirementId = activeDraggableTabPrefix
                    ? draggableId.replace(activeDraggableTabPrefix, "")
                    : draggableId; // requirement id
                const complianceMatrixActiveDraggableRowIndex = complianceMatrixRows.findIndex(
                    (row) => row?.get("requirement")?.get("id") === activeDraggableRequirementId
                );
                const activeDraggableComplianceMatrixRow = complianceMatrixRows.get(
                    complianceMatrixActiveDraggableRowIndex
                );
                if (!activeDraggableComplianceMatrixRow) return;
                // if (isDroppingIntoDrawerFromOutside) {
                //     if (activeRequirementsDrawerTab === TabSlug.Assigned) return;
                //     if (activeRequirementsDrawerTab === TabSlug.Disregarded) {
                //         disregardRequirement(activeDraggableComplianceMatrixRow.get("requirement")?.get("id"));
                //     }
                //     if (activeRequirementsDrawerTab === TabSlug.Unassigned) {
                //         activeDraggableComplianceMatrixRow.get("proposal_reference")?.update({
                //             volume_id: "",
                //             section_id: "",
                //             subsection_id: "",
                //         });
                //         activeDraggableComplianceMatrixRow.get("requirement")?.set("disregarded", false);
                //     }
                //     const destinationRow = groupedRequirements[activeRequirementsDrawerTab][destination?.index];
                //     if (!destinationRow) return;
                //     const destinationComplianceMatrixRowIndex = complianceMatrixRows.findIndex(
                //         (row) => row?.get("requirement")?.get("id") === destinationRow.requirement?.id
                //     );
                //     complianceMatrixRows.move(
                //         complianceMatrixActiveDraggableRowIndex,
                //         destinationComplianceMatrixRowIndex
                //     );
                //     resetSourceRequirementsOrder(complianceMatrixRows, activeDraggableComplianceMatrixRow);
                //     return;
                // }
                if (isReordingWithinSection) {
                    const rowsInSection = complianceMatrixRows.filter(
                        (row) => row?.get("proposal_reference").get("section_id") === destinationDroppableId
                    );
                    const doesNotHaveFullOrdering = rowsInSection.some(
                        (row) => typeof row.get("requirement")?.get("section_order") !== "number"
                    );
                    if (doesNotHaveFullOrdering) {
                        rowsInSection.forEach((row, idx) => {
                            const requirement = row?.get("requirement");
                            requirement?.set("section_order", idx);
                        });
                    }
                    rowsInSection.sort(
                        (a, b) =>
                            (a.get("requirement")?.get("section_order") || 0) -
                            (b.get("requirement")?.get("section_order") || 0)
                    );
                    const sortedRows = move([...rowsInSection], source?.index, destination?.index);
                    sortedRows.forEach((row, idx) => {
                        row.get("requirement")?.set("section_order", idx);
                    });
                    return;
                }
                // if (isReordingWithinDrawer) {
                //     const rowsInTab = getComplianceMatrixRowsFromTab(
                //         DRAGGABLE_PREFIX_TO_TAB[activeDraggableTabPrefix as PrefixType],
                //         groupedRequirements
                //     );
                //     const rowToMove = rowsInTab[destination?.index];
                //     const rowToMoveIndex = complianceMatrixRows.findIndex(
                //         (row) => row?.get("requirement")?.get("id") === rowToMove?.requirement?.id
                //     );
                //     complianceMatrixRows?.move(complianceMatrixActiveDraggableRowIndex, rowToMoveIndex);
                //     return;
                // }
                // prevent dragging req to section if it already exists
                const requirementExistsInSection =
                    activeDraggableComplianceMatrixRow.get("requirement")?.get("id") === activeDraggableRequirementId &&
                    activeDraggableComplianceMatrixRow.get("proposal_reference")?.get("section_id") ===
                        destinationDroppableId;
                if (requirementExistsInSection) return;
                // dragging req to section from outside
                addRequirementToSection({
                    complianceMatrix: complianceMatrixRows,
                    activeRow: activeDraggableComplianceMatrixRow,
                    volumeList,
                    destinationSectionId: destinationDroppableId,

                    setSelectedSection: (section) => dispatch(setFrameworkState({ selectedSection: section })),
                });
            } else if (type === "TEMPLATE_SECTIONS") {
                const targetSectionId = draggableId;
                const volumeSourceId = source?.droppableId;
                const volumeDestinationId = destination?.droppableId;
                const volumeSourceIndex = volumeList.findIndex((b) => b.get("id") === volumeSourceId);
                const volumeDestinationIndex = volumeList.findIndex((b) => b.get("id") === volumeDestinationId);
                const newSubItems = volumeList.get(volumeSourceIndex)?.get("sections");
                if (!newSubItems) return;
                const newDestinationSubItems =
                    volumeSourceId !== volumeDestinationId
                        ? volumeList.get(volumeDestinationIndex)?.get("sections")
                        : newSubItems;
                const destinationItemToReplace = newDestinationSubItems?.get(
                    destination.index > source.index && volumeDestinationId === volumeSourceId
                        ? destination.index + 1
                        : destination.index
                );
                // Remove item from source list
                const originalItem = newSubItems?.get(source.index);
                if (!originalItem) return;
                const deletedItem = new LiveObject({
                    id: originalItem.get("id"),
                    title: originalItem.get("title"),
                    locked: originalItem.get("locked"),
                    ...(!!destination.index && {
                        parent_id:
                            volumeDestinationId === volumeSourceId &&
                            destination.index > source.index &&
                            newDestinationSubItems?.get(destination.index - 1)?.get("parent_id") ===
                                newDestinationSubItems?.get(source.index)?.get("parent_id")
                                ? originalItem.get("parent_id")
                                : destinationItemToReplace?.get("parent_id"),
                    }),
                    proposal: originalItem.get("proposal"),
                });
                // Update compliance matrix
                complianceMatrixRows.forEach((row) => {
                    if (row?.get("proposal_reference")?.get("section_id") === targetSectionId) {
                        row?.get("proposal_reference")?.update({
                            volume_id: volumeDestinationId,
                            subsection_id: deletedItem.get("parent_id") ? deletedItem.get("id") : "",
                        });
                    }
                });
                newSubItems?.delete(source.index);
                // Add item to destination list
                newDestinationSubItems?.insert(deletedItem, destination?.index);
            }
            updateTemplate({ isDirty: true });
        },
        [activeRequirementsDrawerTab]
    );

    const sectionIdeationDragEnd = useCallback(
        (results: DropResult) => {
            const { source, destination } = results;

            if (!destination || !source) return;

            if (
                source?.droppableId === GENERATED_CONTENT_DROPPABLE &&
                destination?.droppableId === GENERATED_CONTENT_DROPPABLE
            ) {
                dispatch(reorderSectionIdeationGeneratedContent({ from: source.index, to: destination.index }));
            }
            if (
                source?.droppableId === SELECTED_CONTENT_DROPPABLE &&
                destination?.droppableId === SELECTED_CONTENT_DROPPABLE
            ) {
                dispatch(reorderSectionIdeationSelectedContent({ from: source.index, to: destination.index }));
            }
            if (
                source?.droppableId === GENERATED_CONTENT_DROPPABLE &&
                destination?.droppableId === SELECTED_CONTENT_DROPPABLE
            ) {
                dispatch(moveGenratedContentToSelected({ from: source.index, to: destination.index }));
            }
            if (
                source?.droppableId === SELECTED_CONTENT_DROPPABLE &&
                destination?.droppableId === GENERATED_CONTENT_DROPPABLE
            ) {
                dispatch(moveSelectedContentToGenerated({ from: source.index, to: destination.index }));
            }
        },
        [dispatch]
    );

    const dragEnd = useCallback(
        (results: DropResult) => {
            const { source, destination, type } = results;
            if (!destination) return;
            if (source?.index === destination?.index && source?.droppableId === destination?.droppableId) return;

            if (DRAG_TYPES.sectionContentIdeation.includes(type)) {
                sectionIdeationDragEnd(results);
            } else if (DRAG_TYPES.framework.includes(type)) {
                frameworkDragEnd(results);
            }
        },
        [frameworkDragEnd, sectionIdeationDragEnd]
    );

    return dragEnd;
};

export const usePollAutopilotStatus = () => {
    const isPageVisible = usePageVisibility();
    const timerIdRef = useRef<NodeJS.Timeout>();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id")?.toLocaleLowerCase();
    const dispatch = useAppDispatch();
    const [isPollingEnabled, setIsPollingEnabled] = useState(true);

    useEffect(() => {
        if (!projectId) return;
        const pollingCallback = async () => {
            dispatch(getAutopilotHealthCheck(projectId));
        };

        const startPolling = () => {
            pollingCallback();
            // Polling every 3 seconds
            timerIdRef.current = setInterval(pollingCallback, 3000);
        };

        const stopPolling = () => {
            clearInterval(timerIdRef.current);
        };

        if (isPageVisible && isPollingEnabled) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [dispatch, isPageVisible, isPollingEnabled, projectId]);
};

export const useBannerState = () => {
    const { requirement_document_tasks, requirement_text_tasks, template_document_tasks, template_text_tasks } =
        useAppSelector((state) => state.autopilotHealthCheck);
    const { requirementExtraction, templateExtraction } = useAppSelector((state) => state.bannerState);
    const dispatch = useAppDispatch();

    const requirementExtractionInProgress = useMemo(
        () =>
            !![...requirement_document_tasks, ...requirement_text_tasks]?.filter(({ is_started }) => is_started)
                ?.length,
        [requirement_document_tasks, requirement_text_tasks]
    );

    const templateExtractionInProgress = useMemo(
        () => !![...template_document_tasks, ...template_text_tasks]?.filter(({ is_started }) => is_started)?.length,
        [template_document_tasks, template_text_tasks]
    );

    useEffect(() => {
        if (requirementExtractionInProgress) {
            if (requirementExtraction.forceClose) return;
            dispatch(
                setBannerState({
                    requirementExtraction: {
                        open: requirementExtractionInProgress,
                        forceClose: false,
                    },
                })
            );
        } else {
            dispatch(
                setBannerState({
                    requirementExtraction: {
                        open: false,
                        forceClose: false,
                    },
                })
            );
        }
    }, [dispatch, requirementExtraction.forceClose, requirementExtractionInProgress]);

    useEffect(() => {
        if (templateExtractionInProgress) {
            if (templateExtraction.forceClose) return;
            dispatch(
                setBannerState({
                    templateExtraction: {
                        open: templateExtractionInProgress,
                        forceClose: false,
                    },
                })
            );
        } else {
            dispatch(
                setBannerState({
                    templateExtraction: {
                        open: false,
                        forceClose: false,
                    },
                })
            );
        }
    }, [dispatch, templateExtraction.forceClose, templateExtractionInProgress]);
};

export const useFrameworkOperations = (frameworkState?: ImmutableStorage["framework"]) => {
    const updateTemplate = useUpdateTemplate();

    const getFrameworkVolumeTitle = useMutation(({ storage }, volumeId: string) => {
        const liveVolumes = (storage.get("framework") as Storage["framework"])?.get("volumes");
        let index = liveVolumes?.findIndex((v) => v.get("id") === volumeId);
        if (index === undefined || index === -1) return "";
        return liveVolumes.get(index)?.get("title") || "";
    }, []);

    const getFrameworkVolumeSectionTitle = useMutation(({ storage }, volumeId: string, sectionId: string) => {
        const liveVolumes = (storage.get("framework") as Storage["framework"])?.get("volumes");

        const volumeIndex = liveVolumes?.findIndex((v) => v.get("id") === volumeId);
        if (volumeIndex === -1 || volumeIndex === undefined) return "";

        const liveSections = liveVolumes.get(volumeIndex)?.get("sections");

        const sectionIndex = liveSections?.findIndex((s) => s.get("id") === sectionId);
        if (sectionIndex === -1 || sectionIndex === undefined) return "";

        return liveSections?.get(sectionIndex)?.get("title");
    }, []);

    const addNewVolume = useMutation(({ storage }, volumeProperties?: Partial<Volume>) => {
        const newVolume = createVolume(volumeProperties);
        (storage.get("framework") as Storage["framework"]).get("volumes").push(newVolume);

        updateTemplate({ isDirty: true });

        return newVolume.toImmutable();
    }, []);

    const addNewSection = useMutation(({ storage }, volumeId: string, sectionProperties?: Partial<Section>) => {
        const newSubsection = createSection(sectionProperties);
        const foundVolume = (storage.get("framework") as Storage["framework"])
            .get("volumes")
            ?.find((vol) => vol.get("id") === volumeId);

        foundVolume?.get("sections")?.push(newSubsection);
        updateTemplate({ isDirty: true });

        return newSubsection.toImmutable();
    }, []);

    const updateVolumeTitle = useMutation(({ storage }, id: string, newTitle: string) => {
        const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
        const foundVolume = volumeList?.find((volume) => volume.get("id") === id);
        foundVolume?.set("title", newTitle);

        updateTemplate({ isDirty: true });
    }, []);

    const updateSectionTitle = useMutation(({ storage }, volumeId: string, sectionId: string, newTitle: string) => {
        const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
        const foundVolume = volumeList?.find((volume) => volume.get("id") === volumeId);
        const foundSection = foundVolume?.get("sections")?.find((section) => section.get("id") === sectionId);
        foundSection?.set("title", newTitle);

        updateTemplate({ isDirty: true });
    }, []);

    const updateSectionStatus = useMutation(
        ({ storage }, volumeId: string, sectionId: string, status: SectionStatus) => {
            const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
            const foundVolume = volumeList?.find((volume) => volume.get("id") === volumeId);
            const foundSection = foundVolume?.get("sections")?.find((section) => section.get("id") === sectionId);
            foundSection?.set("status", status);

            updateTemplate({ isDirty: true });
        },
        []
    );

    const assignToVolume = useMutation(({ storage }, volumeId: string, assignees: Section["assignees"]) => {
        const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
        const foundVolume = volumeList?.find((volume) => volume.get("id") === volumeId);
        foundVolume?.set("assignees", assignees);

        updateTemplate({ isDirty: true });
    }, []);

    const assignToSection = useMutation(
        ({ storage }, volumeId: string, sectionId: string, assignees: Section["assignees"]) => {
            const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
            const foundVolume = volumeList?.find((volume) => volume.get("id") === volumeId);
            const foundSection = foundVolume?.get("sections")?.find((section) => section.get("id") === sectionId);
            foundSection?.set("assignees", assignees);

            updateTemplate({ isDirty: true });
        },
        []
    );

    const setSectionProposal = useMutation(
        ({ storage }, volumeId: string, sectionId: string, proposal: Section["proposal"]) => {
            const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes");
            const foundVolume = volumeList?.find((volume) => volume.get("id") === volumeId);
            const foundSection = foundVolume?.get("sections")?.find((section) => section.get("id") === sectionId);
            foundSection?.set("proposal", proposal);

            updateTemplate({ isDirty: true });
        },
        []
    );

    const deleteVolume = useMutation(({ storage }, volumeId: string) => {
        const liveReqs = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const volumeList = (storage.get("framework") as Storage["framework"])?.get("volumes");
        const index = volumeList?.findIndex((v) => v.get("id") === volumeId);

        if (index === -1) return;
        liveReqs?.forEach((row) => {
            const assignedVolumeId = row.get("proposal_reference")?.get("volume_id");
            if (assignedVolumeId === volumeId) {
                row.get("proposal_reference").update({
                    volume_id: "",
                    section_id: "",
                    subsection_id: "",
                });
            }
        });
        volumeList?.delete(index);

        updateTemplate({ isDirty: true });
    }, []);

    const deleteSection = useMutation(({ storage }, volumeId: string, sectionId: string) => {
        const liveReqs = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const volumeList = (storage.get("framework") as Storage["framework"])?.get("volumes");
        const volumeIndex = volumeList?.findIndex((vol) => vol.get("id") === volumeId);
        if (volumeIndex === -1) return;

        const sectionList = volumeList?.get(volumeIndex)?.get("sections");

        sectionList?.forEach((sec) => {
            const id = sec.get("id");
            const parentId = sec.get("parent_id");
            const foundSectionOrSubsection = id === sectionId || parentId === sectionId;

            if (foundSectionOrSubsection) {
                liveReqs?.forEach((row) => {
                    const assignedSectionId = row.get("proposal_reference")?.get("section_id");
                    if (assignedSectionId === sectionId) {
                        row.get("proposal_reference").update({
                            volume_id: "",
                            section_id: "",
                            subsection_id: "",
                        });
                    }
                });

                const sectionIndex = sectionList?.findIndex((section) => section.get("id") === id);
                if (sectionIndex === -1) return;

                sectionList?.delete(sectionIndex);
            }
        });
        updateTemplate({ isDirty: true });

        return sectionList?.toImmutable();
    }, []);

    return {
        getFrameworkVolumeTitle,
        getFrameworkVolumeSectionTitle,
        addNewVolume,
        addNewSection,
        updateVolumeTitle,
        updateSectionTitle,
        assignToVolume,
        assignToSection,
        updateSectionStatus,
        setSectionProposal,
        deleteVolume,
        deleteSection,
        frameworkState,
    };
};
