/** @jsxImportSource @emotion/react */

import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { shallow } from "@liveblocks/client";
import { Section, Storage as ImmutableStorage, Volume, Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { useStorage } from "liveblocks.config";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import SortableItem from "./SortableItem";
import SectionRequirement from "./SectionRequirement";
import { useCreateAndAssignRequirement } from "components/copilot/Framework/hooks";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import SheetsDropdown from "components/molecules/sheets-dropdown";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { EMPTY_SHEET } from "const-values/Sheets";
import { useRequirementHighlight } from "./hooks";
import RequirementsBulkUpdate from "components/organisms/requirements-bulk-update/RequirementsBulkUpdate";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";

const DraftSectionRequirements = () => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const { section, volume } = useOutletContext<{
        section?: Section;
        volume?: Volume;
        abortConnection?: () => void;
    }>();
    const requirements = useStorage(
        (storage) =>
            (storage.compliance_matrix as ImmutableStorage["compliance_matrix"]).filter(
                (row) => row.proposal_reference.section_id === section?.id && !row.requirement.skipped
            ),
        shallow
    );
    useRequirementHighlight();

    const createAndAddToSection = useCreateAndAssignRequirement();

    const autoScroll = useAppSelector((root) => root.sectionState.requirementsState.autoScroll);
    const activeDragRequirementId = useAppSelector(
        (root) => root.sectionState.requirementsState.activeDragRequirementId
    );

    const sortedRequirements = useMemo(
        () =>
            [...requirements]
                .sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0))
                .map((row) => ({ id: row.requirement.id, ...row })),
        [requirements]
    );

    const activeRow = useMemo(
        () => requirements.find((row) => row?.requirement?.id === activeDragRequirementId),
        [activeDragRequirementId, requirements]
    );

    const sortedSections = useMemo(() => {
        return (
            volume?.sections?.reduce<FormattedSection[]>((acc, section) => {
                if (!section.parent_id) {
                    const subsections = volume?.sections?.filter(({ parent_id }) => parent_id === section.id);
                    return [...acc, { ...section, subsections }];
                }

                return acc;
            }, []) || []
        );
    }, [volume?.sections]);

    const onCreateRequirement = useCallback(
        (sheet: Sheet) => {
            if (section) {
                const createdRequirement = createComplianceMatrixRowRequirement({
                    ...(sheet?.id !== EMPTY_SHEET.id && { extraction_id: sheet?.id }),
                });

                createAndAddToSection(section, undefined, { requirement: createdRequirement });
            }
        },
        [createAndAddToSection, section]
    );

    useLayoutEffect(() => {
        if (autoScroll) {
            scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 20);
            dispatch(setRequirementsState({ autoScroll: false }));
        }
    }, [autoScroll, dispatch]);

    if (!section) return;

    if (!sortedRequirements?.length)
        return (
            <div className="relative h-full flex flex-col-reverse mt-4">
                {new Array(8).fill(0).map((_, i) => (
                    <div
                        key={i}
                        className="absolute px-8 bg-slate-50 left-3.5 right-3.5 gap-3 h-1/3 min-h-[200px] rounded-md shadow flex flex-col items-center justify-center"
                        css={[
                            {
                                opacity: (-i + 9) / 9,
                                top: 12 * i,
                                transform: `scaleX(${(-i + (9 + i * 0.8)) / 9})`,
                                zIndex: 9 - i,
                            },
                        ]}
                    >
                        {!i && (
                            <>
                                <div className="text-slate-900 font-semibold text-base">Add Requirement</div>
                                <div className="text-slate-500 text-sm text-center">
                                    Add requirement from the{" "}
                                    <button
                                        onClick={() => dispatch(toggleRequirements(true))}
                                        className="text-action font-medium duration-100 hover:text-action-hover"
                                    >
                                        requirements drawer
                                    </button>{" "}
                                    or{" "}
                                    <SheetsDropdown onSelect={onCreateRequirement}>
                                        <div className="text-action font-medium duration-100 hover:text-action-hover">
                                            create a new one
                                        </div>
                                    </SheetsDropdown>
                                    .
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        );

    return (
        <>
            <RequirementsBulkUpdate />
            <div className="flex flex-col h-full overflow-hidden">
                <div className="relative flex-1 h-full w-full">
                    <div
                        ref={scrollRef}
                        className="overflow-y-auto flex-1 pl-3 pr-5 absolute top-0 right-0 bottom-0 left-0"
                    >
                        <SortableContext
                            id="TEMPLATE_REQUIREMENTS"
                            items={sortedRequirements || []}
                            strategy={verticalListSortingStrategy}
                        >
                            <div className="flex flex-col gap-4 pt-2 pb-5">
                                {sortedRequirements?.map((row) => (
                                    <SortableItem
                                        key={row.requirement.id}
                                        id={row.requirement.id}
                                        row={row}
                                        sortedSections={sortedSections}
                                    />
                                ))}
                            </div>
                            <DragOverlay style={{ transformOrigin: "0 0 " }}>
                                {!!activeRow?.requirement && <SectionRequirement row={activeRow} isDragging />}
                            </DragOverlay>
                        </SortableContext>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DraftSectionRequirements;
