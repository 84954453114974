import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "./store";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
// css
import "./index.css";
import "./styles/text-editor.css";
import "./styles/prose.css";
import "./styles/global.css";
import "./styles/themes/dark-theme.css";
import "./styles/themes/light-theme.css";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { asyncWithLDProvider, basicLogger } from "launchdarkly-react-client-sdk";
import Hotjar from "@hotjar/browser";
import * as Sentry from "@sentry/browser";

const HOTJAR_SITE_ID = 3693930;

TimeAgo.addDefaultLocale(en);

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    ignoreErrors: [/ResizeObserver loop (limit exceeded|completed with undelivered notifications)/i],
    tracePropagationTargets: [],
    tracesSampleRate: 0.05, // only used for performance monitoring so reduce it to 5% to reduce costs
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur
});

const root = ReactDOM.createRoot(document.getElementById("root"));
(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
        context: {
            kind: "user",
            key: "anonymous_user",
            anonymous: true,
        },
        options: {
            logger: basicLogger({ level: "error" }),
        },
    });
    Hotjar.init(HOTJAR_SITE_ID, 6);
    root.render(
        <LDProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <TooltipProvider>
                        <App />
                    </TooltipProvider>
                </BrowserRouter>
            </Provider>
        </LDProvider>
    );
})();
