import { useMutation, useStorage } from "liveblocks.config";
import { Storage } from "../CopilotSchemaTypes";
import { Storage as ImmutablelStorage } from "../CopilotSchemaImmutableTypes";
import { useEffect } from "react";
import { LiveObject } from "@liveblocks/client";
import { buildDefaultSteps } from "./utils";
import { Step, StepStatus, StepType } from "types/ProjectProgress";
import { setProgressCompleted } from "store/reducers/project-progress/projectProgressReducer";
import { useAppDispatch } from "store/storeTypes";

export const useSyncProgress = () => {
    // const { progressOpen: open } = useAppSelector((root) => root.copilotDrawer);
    const open = false;
    const dispatch = useAppDispatch();
    const progress = useStorage((root) => root.progress as ImmutablelStorage["progress"]);

    const initializeProgress = useMutation(({ storage }) => {
        const progress = storage.get("progress") as Storage["progress"];
        if (progress) return;
        storage.set("progress", new LiveObject({ steps: buildDefaultSteps(), completed: false }));
    }, []);

    useEffect(() => {
        initializeProgress();
    }, [initializeProgress]);

    useEffect(() => {
        if (progress?.steps && open) {
            setTimeout(
                () =>
                    dispatch(
                        setProgressCompleted(
                            (progress.steps.filter(
                                ({ status }) => status === StepStatus.Completed || status === StepStatus.Skipped
                            ).length /
                                progress.steps.length) *
                                100
                        )
                    ),
                250
            );
        }

        if (progress?.steps && !open) {
            dispatch(
                setProgressCompleted(
                    (progress.steps.filter(
                        ({ status }) => status === StepStatus.Completed || status === StepStatus.Skipped
                    ).length /
                        progress.steps.length) *
                        100
                )
            );
        }
    }, [dispatch, open, progress?.steps]);
};

export const useSetStatus = (step: Step) => {
    const setStatus = useMutation(
        ({ storage }, status) => {
            const progress = storage.get("progress") as Storage["progress"];
            const liveSteps = progress?.get("steps");
            if (!liveSteps?.length || progress?.get("completed")) return;

            if (step.type === StepType.Default) {
                const foundStepIndex = liveSteps.findIndex((liveStep) => {
                    if (liveStep.get("type") === StepType.Default) {
                        // @ts-ignore
                        return liveStep.get("step") === step.step;
                    }
                    return false;
                });

                if (foundStepIndex === -1) return;
                const foundStep = liveSteps.get(foundStepIndex);

                const nextCompletedOrSkippedStepIndex = liveSteps.findIndex((liveStep, idx) => {
                    if (liveStep.get("type") === StepType.Default && idx - 1 > foundStepIndex) {
                        const stepStatus = liveStep.get("status");
                        return stepStatus === StepStatus.Completed || stepStatus === StepStatus.Skipped;
                    }
                    return false;
                });
                const previousCompletedOrSkippedStepIndex = liveSteps.findIndex((liveStep, idx) => {
                    if (liveStep.get("type") === StepType.Default && idx + 1 < foundStepIndex) {
                        const stepStatus = liveStep.get("status");
                        return stepStatus === StepStatus.Completed || stepStatus === StepStatus.Skipped;
                    }
                    return false;
                });

                if (!status) {
                    const stepBeforeStatus = liveSteps.get(foundStepIndex - 1)?.get("status");
                    const stepAfterStatus = liveSteps.get(foundStepIndex + 1)?.get("status");
                    const isBoundBy = (!!stepBeforeStatus && !!stepAfterStatus) || foundStepIndex === 0;
                    let closestCompletedOrSkippedStepIdxBefore = -1;
                    let closestCompletedOrSkippedStepIdxAfter = Infinity;

                    liveSteps.forEach((step, idx) => {
                        const stepStatus = step.get("status");
                        const completedOrSkipped =
                            stepStatus === StepStatus.Completed || stepStatus === StepStatus.Skipped;
                        if (idx < foundStepIndex && completedOrSkipped)
                            closestCompletedOrSkippedStepIdxBefore = Math.max(
                                idx,
                                closestCompletedOrSkippedStepIdxBefore
                            );
                        if (idx > foundStepIndex && completedOrSkipped)
                            closestCompletedOrSkippedStepIdxAfter = Math.min(
                                idx,
                                closestCompletedOrSkippedStepIdxAfter
                            );
                    });

                    foundStep?.set("status", isBoundBy ? StepStatus.Ready : status);

                    liveSteps?.forEach((step, idx) => {
                        if (
                            closestCompletedOrSkippedStepIdxBefore === -1 &&
                            closestCompletedOrSkippedStepIdxAfter === Infinity
                        ) {
                            step.set("status", idx === 0 ? StepStatus.Ready : null);
                        } else if (
                            closestCompletedOrSkippedStepIdxBefore === -1 &&
                            idx < closestCompletedOrSkippedStepIdxAfter
                        ) {
                            step.set("status", idx === 0 ? StepStatus.Ready : null);
                        } else if (
                            closestCompletedOrSkippedStepIdxAfter === Infinity &&
                            idx - 1 > closestCompletedOrSkippedStepIdxBefore
                        ) {
                            step.set("status", idx === 0 ? StepStatus.Ready : null);
                        }
                    });
                } else foundStep?.set("status", status);

                if (status === StepStatus.Completed || status === StepStatus.Skipped) {
                    if (foundStepIndex < liveSteps.length - 1 && !liveSteps.get(foundStepIndex + 1)?.get("status")) {
                        liveSteps.get(foundStepIndex + 1)?.set("status", StepStatus.Ready);
                    }

                    liveSteps?.forEach((step, idx) => {
                        if (
                            nextCompletedOrSkippedStepIndex !== -1 &&
                            idx > foundStepIndex &&
                            idx < nextCompletedOrSkippedStepIndex &&
                            !step.get("status")
                        ) {
                            step.set("status", StepStatus.Ready);
                        }
                        if (
                            previousCompletedOrSkippedStepIndex !== -1 &&
                            idx < foundStepIndex &&
                            idx > previousCompletedOrSkippedStepIndex &&
                            !step.get("status")
                        ) {
                            step.set("status", StepStatus.Ready);
                        }
                    });
                }
            }
        },
        [step]
    );

    return setStatus;
};
