import { useState } from "react";
import axios from "axios";
import { VOICE_ASSIST_READY, VOICE_ASSIST_PROCESSING, VOICE_ASSIST_RECORDING, VOICE_TRANSLATE_API } from "./constants";
import { useNotification } from "context/notificationContext";

import { ButtonHTMLAttributes } from "react";
import "twin.macro";
import { IoMdMicrophone } from "react-icons/io";
import { StopIcon } from "@radix-ui/react-icons";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    onComplete: (message: string) => void;
    height?: string;
    width?: string;
    bg?: string;
    text?: string;
    hoverText?: string;
    hoverBg?: string;
    rounded?: string;
}

interface State {
    mediaRecorder: MediaRecorder | undefined;
    voiceAssistState: string;
}

const VoiceTranslateItem = ({ onComplete, height, width, bg, text, hoverText, hoverBg, rounded, disabled }: Props) => {
    const { setToast } = useNotification();
    const [state, setState] = useState<State>({
        mediaRecorder: undefined,
        voiceAssistState: VOICE_ASSIST_READY,
    });

    const handlePermissionDenied = () => {
        setToast.error({
            title: "Microphone Access Denied",
            msg: "Enable microphone access to use this feature. Please check and update your browser settings.",
        });
    };

    const createMediaRecorder = async () => {
        try {
            if (disabled) {
                return;
            }
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            return mediaRecorder;
        } catch (error) {
            handlePermissionDenied();
        }
    };

    const onRecordingComplete = async (audioBlob: any) => {
        try {
            if (disabled) {
                return;
            }
            const formData = new FormData();
            formData.append("audio", audioBlob, "memo.webm");
            const resp = await axios.postForm(VOICE_TRANSLATE_API, formData);
            const message = resp.data.message;
            onComplete(message);
            setState({ ...state, voiceAssistState: VOICE_ASSIST_READY });
        } catch (e) {
            setToast.error({
                title: "Unable to translate request",
                msg: "We were unable to translate the request due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
        }
        setState({ ...state, voiceAssistState: VOICE_ASSIST_READY });
    };

    const handleStartRecording = async () => {
        try {
            if (disabled) {
                return;
            }
            const mediaRecorder = await createMediaRecorder();
            if (!mediaRecorder) {
                return;
            }
            mediaRecorder.ondataavailable = (e) => {
                onRecordingComplete(e.data);
            };
            setState((prevState) => ({ ...prevState, mediaRecorder, voiceAssistState: VOICE_ASSIST_RECORDING }));
            mediaRecorder.start();
        } catch (error) {
            setToast.error({
                title: "Unable to start microphone",
                msg: "We were unable to start the microphone due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
            setState({ ...state, voiceAssistState: VOICE_ASSIST_READY });
        }
    };

    const handleStopRecording = () => {
        if (state.mediaRecorder) {
            state.mediaRecorder.stop();
            setState((prevState) => ({ ...prevState, voiceAssistState: VOICE_ASSIST_PROCESSING }));
        }
    };

    const renderHeight = height || "6";
    const renderWidth = width || "6";
    const renderBackground = bg || "black";
    const renderText = text || "white";
    const renderHoverText = hoverText || "[#4f4e4e]";
    const renderHoverBackground = hoverBg || "[#4f4e4e]";
    const roundedCorners = rounded || "rounded-full";
    const renderDisable = `opacity-50 hover:${renderText} hover:${renderBackground} cursor-normal`;
    const renderNormal = `hover:text-${renderHoverText} hover:bg-${renderHoverBackground} cursor-pointer`;
    const disabledStyling = disabled ? renderDisable : renderNormal;

    return (
        <div
            className={`bg-${renderBackground} p-[2px] relative text-sm flex items-center justify-center text-${renderText} w-${renderWidth} h-${renderHeight} min-w-[20px] min-h-[20px] duration-150 ${roundedCorners} disabled:bg-gray-200 disabled:text-slate-400 ${disabledStyling}`}
        >
            {state.voiceAssistState === VOICE_ASSIST_READY && (
                <IoMdMicrophone onClick={handleStartRecording} className="h-[70%] w-[70%]" />
            )}
            {state.voiceAssistState === VOICE_ASSIST_RECORDING && (
                <StopIcon onClick={handleStopRecording} className="h-[70%] w-[70%]" />
            )}
            {state.voiceAssistState === VOICE_ASSIST_PROCESSING && <div className={`loader-circle h-[70%] w-[70%]`} />}
        </div>
    );
};

export default VoiceTranslateItem;
