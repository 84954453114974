const MEDIA_BASE = "image_search";

export const EDITOR_ROUTES = {
    media: {
        search: `${MEDIA_BASE}/search`,
        file: {
            upload: `${MEDIA_BASE}/file/create`,
            get: (id: string) => `${MEDIA_BASE}/file/${id}`,
        },
    },
} as const;
