/** @jsxImportSource @emotion/react */

import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import useRequirementOperations from "hook/useRequirementOperations";
import { useMemo, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import UserInstructionRow from "./UserInstructionRow";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import { LuPlus } from "react-icons/lu";
import Tooltip from "components/atoms/tooltip";
import Icon from "components/atoms/icons/Icon";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import tw from "twin.macro";
import { useTrackUserMetric } from "utils/metrics";

type Props = {
    row: ComplianceMatrixRow;
};

const UserInstructions = ({ row }: Props) => {
    const { user_instructions, requirement } = row;
    const instructions = user_instructions;
    const { addNewUserInstruction } = useRequirementOperations();
    const [editableInstruction, setEditableInstruction] = useState("");
    const { sensors, handleDragEnd, handleDragCancel, handleDragStart, activeDragId } = useDrag();
    const trackUserEvent = useTrackUserMetric();

    const activeInstruction = useMemo(
        () => instructions?.find(({ id }) => id === activeDragId),
        [activeDragId, instructions]
    );

    const handleTranslation = (text: string) => {
        addNewUserInstruction(requirement.id, text);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={verticalSortableListCollisionDetection}
            onDragEnd={(event) => handleDragEnd(event, requirement.id)}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
        >
            <div className="flex flex-col gap-1.5">
                <div className="flex items-center justify-between">
                    <div className="text-xs font-semibold py-1 text-gray-darkest flex items-center gap-1">
                        Guidelines
                        <Tooltip
                            disableHoverableContent
                            content="Guidelines provides control over the generated content’s structure and style, from paragraph and word count to tone and language style used. Experiment with more complex instructions for tailored outputs."
                        >
                            <Icon name="InfoCircle" className="w-3 h-3" />
                        </Tooltip>
                    </div>
                    <div className="flex gap-1.5">
                        <VoiceTranslateItem
                            onComplete={handleTranslation}
                            bg="slate-200"
                            text="slate-600"
                            hoverBg="slate-300"
                            hoverText="slate-900"
                            rounded="rounded"
                            disabled={row.locked}
                        />
                        <button
                            onClick={() => {
                                const createdInstruction = addNewUserInstruction(requirement.id, "");
                                setEditableInstruction(createdInstruction?.id || "");

                                trackUserEvent("Drafts: New Guideline Added", {
                                    requirement_id: String(requirement.id),
                                });
                            }}
                            disabled={row.locked}
                            className="bg-slate-200 text-slate-600 rounded shadow-sm text-base w-6 h-6 flex items-center justify-center duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>
                <div
                    className="flex flex-col rounded max-h-[260px] overflow-auto"
                    css={[row.locked && tw`pointer-events-none`]}
                >
                    {!!instructions?.length ? (
                        <SortableContext
                            id="REQUIREMENT_PROMPTS"
                            items={instructions || []}
                            strategy={verticalListSortingStrategy}
                            disabled={row.locked}
                        >
                            <div className="flex flex-col gap-1">
                                {instructions?.map((instruction) => (
                                    <SortableItem
                                        key={instruction.id}
                                        reqId={requirement.id}
                                        editableInstruction={editableInstruction}
                                        setEditableInstruction={setEditableInstruction}
                                        instruction={instruction}
                                        disabled={row.locked}
                                    />
                                ))}
                            </div>
                            <DragOverlay style={{ transformOrigin: "0 0 " }}>
                                {!!activeInstruction && (
                                    <UserInstructionRow
                                        reqId={requirement.id}
                                        editableInstruction={editableInstruction}
                                        setEditableInstruction={setEditableInstruction}
                                        instruction={activeInstruction}
                                        isDragging
                                    />
                                )}
                            </DragOverlay>
                        </SortableContext>
                    ) : (
                        <button
                            onClick={() => {
                                const createdInstruction = addNewUserInstruction(requirement.id, "");
                                setEditableInstruction(createdInstruction?.id || "");

                                trackUserEvent("Drafts: New Guideline Added", {
                                    requirement_id: String(requirement.id),
                                });
                            }}
                            disabled={row.locked}
                            className="bg-slate-200 h-11 text-start flex items-center justify-between gap-2 text-slate-600 rounded text-xs px-2 py-1.5 disabled:opacity-50"
                        >
                            <div className="flex items-center gap-2">
                                <BsPlusCircleFill /> Add guideline
                            </div>
                        </button>
                    )}
                </div>
            </div>
        </DndContext>
    );
};

export default UserInstructions;
