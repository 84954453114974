import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplatesModalProps } from "components/copilot/Framework/TemplatesModal";
import { CreateProjectModalProps } from "components/modals/create-project-modal/CreateProjectModal";
import { PreviewProposalModalProps } from "components/modals/preview-proposal-modal/PreviewProposalModal";
import { StrictnessModalProps } from "components/modals/strictness-modal/StrictnessModal";
import { TemplateExtractionFromRequirementsModalProps } from "components/modals/template-extraction/template-extraction-from-requirements-modal/TemplateExtractionFromRequirementsModal";
import { TemplateExtractionModalProps } from "components/modals/template-extraction/template-extraction-modal/TemplateExtractionModal";
import { WritingPromptIdeationModalProps } from "components/modals/writing-prompt-ideation-modal/WritingPromptIdeationModal";
import { SourceModalProps } from "components/organisms/global-modals/SourceModal";
import { RequirementExtractionModalProps } from "components/organisms/requirement-extraction-modal/RequirementExtractionModal";

enum Modals {
    SourceModal = "sourceModal",
    TemplateExtractionModal = "templateExtractionModal",
    TemplateExtractionFromRequirementsModal = "templateExtractionFromRequirementsModal",
    TemplatesModal = "templatesModal",
    CreateProjectModal = "createProjectModal",
    WritingPromptIdeationModal = "writingPromptIdeationModal",
    RequirementExtractionModal = "requirementExtractionModal",
    StrictnessModal = "strictnessModal",
    PreviewProposalModal = "previewProposalModal",
}

type InitialState = {
    [Modals.SourceModal]: SourceModalProps;
    [Modals.TemplateExtractionModal]: TemplateExtractionModalProps;
    [Modals.TemplateExtractionFromRequirementsModal]: TemplateExtractionFromRequirementsModalProps;
    [Modals.RequirementExtractionModal]: RequirementExtractionModalProps;
    [Modals.TemplatesModal]: TemplatesModalProps;
    [Modals.CreateProjectModal]: CreateProjectModalProps;
    [Modals.WritingPromptIdeationModal]: WritingPromptIdeationModalProps;
    [Modals.StrictnessModal]: StrictnessModalProps;
    [Modals.PreviewProposalModal]: PreviewProposalModalProps;
};

const initialState: InitialState = {
    sourceModal: {
        open: false,
        initialProps: {},
    },
    templateExtractionModal: {
        open: false,
    },
    templateExtractionFromRequirementsModal: {
        open: false,
    },
    templatesModal: {
        open: false,
    },
    createProjectModal: {
        open: false,
    },
    writingPromptIdeationModal: {
        open: false,
    },
    requirementExtractionModal: {
        open: false,
    },
    strictnessModal: {
        open: false,
        initialProps: {},
    },
    previewProposalModal: {
        open: false,
    },
};

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        toggleSourceModal: (state: InitialState, action: PayloadAction<SourceModalProps>) => {
            return { ...initialState, sourceModal: { ...state.sourceModal, ...action.payload } };
        },
        toggleTemplateExtractionModal: (state, action: PayloadAction<TemplateExtractionModalProps>) => {
            return { ...state, templateExtractionModal: action.payload };
        },
        toggleTemplatesModal: (state, action: PayloadAction<TemplatesModalProps>) => {
            return { ...state, templatesModal: action.payload };
        },
        toggleTemplateExtractionFromRequirementsModal: (
            state,
            action: PayloadAction<TemplateExtractionFromRequirementsModalProps>
        ) => {
            return { ...state, templateExtractionFromRequirementsModal: action.payload };
        },
        toggleCreateProjectModal: (state, action: PayloadAction<CreateProjectModalProps>) => {
            return { ...state, createProjectModal: action.payload };
        },
        toggleWritingPromptsIdeationModal: (state, action: PayloadAction<WritingPromptIdeationModalProps>) => {
            return { ...state, writingPromptIdeationModal: action.payload };
        },
        toggleRequirementExtractionModal: (state, action: PayloadAction<RequirementExtractionModalProps>) => {
            return { ...state, requirementExtractionModal: action.payload };
        },
        toggleStrictnessModal: (state, action: PayloadAction<StrictnessModalProps>) => {
            return { ...state, strictnessModal: action.payload };
        },
        togglePreviewProposalModal: (state, action: PayloadAction<PreviewProposalModalProps>) => {
            return { ...state, previewProposalModal: action.payload };
        },
    },
});

export default modalsSlice.reducer;
export const {
    toggleTemplateExtractionModal,
    toggleTemplateExtractionFromRequirementsModal,
    toggleSourceModal,
    toggleTemplatesModal,
    toggleCreateProjectModal,
    toggleWritingPromptsIdeationModal,
    toggleRequirementExtractionModal,
    toggleStrictnessModal,
    togglePreviewProposalModal,
} = modalsSlice.actions;
