import useAutosizeTextArea from "../../primitives/hooks/useAutosizeTextArea";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tiptap/react";
import styles from "../Toolbar.module.css";
import SlateStyles from "../../../../components/editor/components/Toolbar.module.css";
import { HiOutlineMenuAlt2, HiOutlineSparkles } from "react-icons/hi";
import sendArrow from "../../../../Assets/sendArrow.svg";
import { useState, useRef, useEffect } from "react";
import { GiMicrophone } from "react-icons/gi";
import { HiArrowUturnLeft } from "react-icons/hi2";
import { ImMagicWand } from "react-icons/im";
import { MdSpellcheck, MdShortText, MdKeyboardArrowRight } from "react-icons/md";
import {
    askAiAction,
    changeToneToggleAction,
    resetEditorResponse,
    resetTryAgainAction,
    sendingUserResponse,
    tryAgainAction,
} from "store/reducers/aiReducerSlice";
import AskAiPortal from "./AskAiPortal";
import Typewriter from "components/TypeWriter";
import { AiOutlineCheck } from "react-icons/ai";
import { RiMenuAddFill, RiDeleteBin6Line } from "react-icons/ri";
import { TbArrowBackUp } from "react-icons/tb";
import {
    isTextAreaHaveValueAction,
    toggleAskAiInputWthDrpDownAction,
    typewrtierDivFinishesTypingAction,
} from "store/reducers/yjs-editor-reducer";
import TypingEffectAiWriting from "components/editor/components/TypingEffectAiWriting";
import { Fragment } from "@tiptap/pm/model";

type AskAiProps = {
    editor: Editor;
    scrollDiv: HTMLElement | null;
    proseElement: HTMLElement | null;
    fullscreen?: boolean;
};

const AskAiSearchBar = ({ editor, proseElement, scrollDiv, fullscreen }: AskAiProps) => {
    const loader = useSelector((state: any) => state.aiReducer.loading);
    const editorRespons = useSelector((state: any) => state.aiReducer.editorResponse);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loader) {
            dispatch(typewrtierDivFinishesTypingAction(false));
        }
    }, [loader, dispatch]);

    return (
        <AskAiPortal
            editor={editor}
            top={60}
            left={fullscreen ? 100 : 310}
            scrollDiv={scrollDiv}
            proseElement={proseElement}
        >
            {loader ? (
                <div className="flex items-center px-2 py-1 h-[52px] bg-[#FFFFFFFF]  gap-2 items-center font-medium">
                    <div>
                        <HiOutlineSparkles size={18} color="#A782C3" />
                    </div>
                    <span className="text-sm  text-[#A782C3]">AI is writing...</span>
                    <div className="p-2" style={{ minWidth: `${proseElement && proseElement.offsetWidth - 330}px` }}>
                        <TypingEffectAiWriting />
                    </div>
                    <div className="">
                        <img
                            src={sendArrow}
                            alt=""
                            className=""
                            style={{ userSelect: "none", height: "25px", width: "25px" }}
                        />
                    </div>
                </div>
            ) : (
                <div id="typewriterDiv" className={styles.typeWriterDiv}>
                    {editorRespons?.response !== "" && editorRespons?.response !== undefined && (
                        <div
                            className={`${styles.typeWriter} textarea`}
                            style={{ maxWidth: `${proseElement && proseElement.offsetWidth - 157}px` }}
                        >
                            <Typewriter editor={editor} text={editorRespons?.response} />
                        </div>
                    )}
                    <AskAiPopoverTextArea editor={editor} />
                </div>
            )}
        </AskAiPortal>
    );
};

export default AskAiSearchBar;

function AskAiPopoverTextArea({ editor }: { editor: Editor }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const proseElement = document.getElementById("slateEditor");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { selectedText, editorPrevSelection } = useSelector((state: any) => state.yjsEditor);
    useAutosizeTextArea(textAreaRef.current, value);
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
            dispatch(isTextAreaHaveValueAction(false));
        }
    };
    const handleClick = () => {
        handleSubmit();
        // dispatch(isTextAreaHasValueAction(false));
    };

    const handleSubmit = () => {
        if (textAreaRef.current) {
            const userInput = textAreaRef.current.value.trim();
            if (userInput) {
                const obj = {
                    actionType: "user_request",
                    text: selectedText, // Replace this with the actual selectedText
                    user_request: userInput,
                };
                // @ts-ignore
                dispatch(sendingUserResponse(obj));
                dispatch(tryAgainAction(obj));
            }
        }
    };

    useEffect(() => {
        const clearInterval = setTimeout(() => {
            const targetDiv = document.getElementById("movefocuse");
            targetDiv && targetDiv.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 100);
        return () => {
            clearTimeout(clearInterval);
        };
    }, []);
    return (
        <>
            <form
                id="movefocuse"
                className={styles.formToolbarPopOver}
                onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(isTextAreaHaveValueAction(false));
                    // onSubmit(value);
                }}
            >
                <div
                    className={`${styles.toolbarAskAiPopoverBar} relative`}
                    style={{ minWidth: `${proseElement && proseElement.offsetWidth - 157}px` }}
                >
                    <div className="absolute  left-0 top-[1.09rem] flex items-center pl-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <HiOutlineSparkles size={18} color="#A782C3" />
                        </svg>
                    </div>
                    <textarea
                        id="textAreaCmp"
                        className={`${styles.toolbarAskAiPopoverInput} text-base  textarea`}
                        rows={1}
                        ref={textAreaRef}
                        value={value}
                        placeholder="Ask AI to edit or generate…"
                        onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                                dispatch(isTextAreaHaveValueAction(true));
                            } else {
                                dispatch(isTextAreaHaveValueAction(false));
                            }
                            setValue(e.target.value);
                        }}
                        style={{ userSelect: "none" }}
                        onFocus={() => {
                            if (editorPrevSelection) {
                                const startPos = editorPrevSelection.from;
                                const endPos = editorPrevSelection.to;
                                const highlightMarkType = editor.schema.marks.highlight; // Replace 'highlight' with your actual mark name

                                // Create a new Mark instance using the MarkType
                                const highlightMark = highlightMarkType.create();
                                const tr = editor.state.tr.addMark(startPos, endPos, highlightMark);
                                editor.view.dispatch(tr);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="absolute right-0 bottom-[0.5rem] flex items-center pr-2">
                        <img
                            src={sendArrow}
                            alt=""
                            className=""
                            style={{ userSelect: "none", height: "25px", width: "25px" }}
                            onClick={handleClick}
                        />
                    </div>
                </div>
            </form>
        </>
    );
}

export const DropDownMenu = ({ editor, proseElement, scrollDiv, fullscreen }: AskAiProps) => {
    const { selectedText, isTextAreaHaveValue } = useSelector((state: any) => state.yjsEditor);
    const loader = useSelector((state: any) => state.aiReducer.loading);
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(0);
    const menuItems = [
        { id: "item1", icon: <ImMagicWand color="#A782C3" />, title: "Improve writing" },
        { id: "item2", icon: <MdSpellcheck color="#A782C3" />, title: "Fix spelling & grammar" },
        { id: "item3", icon: <MdShortText color="#A782C3" />, title: "Make shorter" },
        { id: "item4", icon: <HiOutlineMenuAlt2 color="#A782C3" />, title: "Make longer" },
        { id: "changetone", icon: <GiMicrophone color="#A782C3" />, title: "Change tone" },
        { id: "item5", icon: <HiOutlineSparkles color="#A782C3" />, title: "Explain" },
    ];
    const handleMenuItemClick = (id: string) => {
        let obj;

        switch (id) {
            case "item1":
                obj = { actionType: "action", text: selectedText, action: "improve_writing" };
                break;
            case "item2":
                obj = { actionType: "action", text: selectedText, action: "fix" };
                break;
            case "item3":
                obj = { actionType: "action", text: selectedText, action: "shorten" };
                break;
            case "item4":
                obj = { actionType: "action", text: selectedText, action: "expand" };
                break;
            case "item5":
                obj = { actionType: "explain", text: selectedText };
                break;
            default:
                return;
        }

        if (obj) {
            // @ts-ignore
            dispatch(sendingUserResponse(obj));
            dispatch(tryAgainAction(obj));
        }
    };
    useEffect(() => {
        if (activeItem === 4) {
            dispatch(changeToneToggleAction(true));
        } else {
            dispatch(changeToneToggleAction(false));
        }
    }, [activeItem, dispatch]);
    return (
        <AskAiPortal
            editor={editor}
            top={1}
            left={fullscreen ? 100 : 310}
            scrollDiv={scrollDiv}
            proseElement={proseElement}
        >
            {!isTextAreaHaveValue && !loader && (
                <div className={`${styles.mainDropDown} min-w-[300px] py-1`}>
                    <span className="text-xs p-2 text-[#37352FA6]">Edit or review selection</span>
                    <div>
                        {menuItems.map((menuItem, index) => (
                            <div
                                key={menuItem.id}
                                id={menuItem.id}
                                onMouseEnter={() => setActiveItem(index)}
                                className={`flex px-2 my-1 gap-2 items-center justify-between leading-120 w-full select-none min-h-[28px] text-base ${
                                    index === activeItem ? SlateStyles.activeClass : "inactiveClass"
                                }`}
                                onClick={() => {
                                    handleMenuItemClick(menuItem.id);
                                }}
                            >
                                <div className="flex my-1 gap-2 items-center">
                                    <span> {menuItem.icon}</span>
                                    <h5 style={{ fontSize: "0.875em" }}>{menuItem.title}</h5>
                                </div>
                                <div
                                    style={{
                                        display: "inline-block",
                                        transform: "rotate(180deg) scaleX(-1) scaleY(1)",
                                    }}
                                >
                                    {activeItem === index && activeItem !== 4 && (
                                        <HiArrowUturnLeft color="#37352F80" size={12} />
                                    )}
                                    {activeItem === index && activeItem === 4 && (
                                        <MdKeyboardArrowRight color="#37352F80" size={12} />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </AskAiPortal>
    );
};
export const ToneDropDown = ({ editor, proseElement, scrollDiv, fullscreen }: AskAiProps) => {
    const { selectedText, isTextAreaHaveValue } = useSelector((state: any) => state.yjsEditor);
    const changeTonDropDown = useSelector((store: any) => store.aiReducer.changeToneToggle);
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(0);
    const changeToneDropDown = [
        "Professional",
        "Concise",
        "Official",
        "Confident",
        "Persuasive",
        "Solution-Oriented",
        "Customer-Centric",
        "Active Voice",
    ];
    const handleMenuItemClick = (item: string) => {
        let obj;

        switch (item) {
            case "Professional":
                obj = { actionType: "tone", text: selectedText, tone: "professional" };
                break;
            case "Concise":
                obj = { actionType: "tone", text: selectedText, tone: "concise" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Official":
                obj = { actionType: "tone", text: selectedText, tone: "official" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Confident":
                obj = { actionType: "tone", text: selectedText, tone: "confident" };
                break;
            case "Persuasive":
                obj = { actionType: "tone", text: selectedText, tone: "persuasive" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Solution-Oriented":
                obj = { actionType: "tone", text: selectedText, tone: "solution" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Customer-Centric":
                obj = { actionType: "tone", text: selectedText, tone: "customer" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Active Voice":
                obj = { actionType: "tone", text: selectedText, tone: "active_voice" };
                dispatch(changeToneToggleAction(false));
                break;
            default:
                dispatch(changeToneToggleAction(false));
                break;
        }

        if (obj) {
            // @ts-ignore
            dispatch(sendingUserResponse(obj));
            dispatch(tryAgainAction(obj));
        }
    };

    useEffect(() => {
        const handleMouseEnter = () => {
            dispatch(changeToneToggleAction(true));
        };
        const handleMouseLeave = () => {
            dispatch(changeToneToggleAction(false));
        };
        const element = document.getElementById("changetoneClose");
        const element2 = document.getElementById("changetoneClose");
        element && element.addEventListener("mouseenter", handleMouseEnter);
        element2 && element2.addEventListener("mouseleave", handleMouseLeave);
        return () => {
            element && element.removeEventListener("mouseenter", handleMouseEnter);
            element2 && element2.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [dispatch]);

    return (
        <AskAiPortal
            editor={editor}
            top={-1}
            left={fullscreen ? -204 : 20}
            scrollDiv={scrollDiv}
            proseElement={proseElement}
        >
            {!isTextAreaHaveValue && changeTonDropDown && (
                <div id="changetoneClose" className={`${styles.mainDropDown} min-w-[220px]`}>
                    <div>
                        {changeToneDropDown.map((item, index) => (
                            <div
                                key={item}
                                onMouseEnter={() => setActiveItem(index)}
                                className={`flex px-2 mt-[0.2rem] gap-2 items-center justify-between leading-120 w-full select-none min-h-[28px] text-base ${
                                    index === activeItem ? SlateStyles.activeClass : "inactiveClass"
                                }`}
                                onClick={() => {
                                    handleMenuItemClick(item);
                                }}
                            >
                                <div className="flex my-1 gap-1 items-center">
                                    <h5 style={{ fontSize: "0.875em" }}>{item}</h5>
                                </div>
                                <div
                                    style={{
                                        display: "inline-block",
                                        transform: "rotate(180deg) scaleX(-1) scaleY(1)",
                                    }}
                                >
                                    {activeItem === index && <HiArrowUturnLeft color="#37352F80" size={12} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </AskAiPortal>
    );
};

export const ResponseDropDown = ({ editor, proseElement, scrollDiv, fullscreen }: AskAiProps) => {
    const typewriterDiv = document.getElementById("typewriterDiv");
    const { editorPrevSelection, isTextAreaHaveValue, typingFinished } = useSelector((state: any) => state.yjsEditor);
    const loader = useSelector((state: any) => state.aiReducer.loading);
    const dispatch = useDispatch();
    const lastActionState = useSelector((state: any) => state.aiReducer.lastActionState);
    const editorRespons = useSelector((state: any) => state.aiReducer.editorResponse);
    const [activeItem, setActiveItem] = useState(0);
    const menuItems = [
        {
            id: "replace-selection",
            title: "Replace Selection",
            icon: <AiOutlineCheck color="#A782C3" />,
        },
        {
            id: "insert-below",
            title: "Insert Below",
            icon: <RiMenuAddFill color="#A782C3" />,
        },
        {
            id: "try-again",
            title: "Try Again",
            icon: <TbArrowBackUp color="#A782C3" />,
        },
        {
            id: "discard",
            title: "Discard",
            icon: <RiDeleteBin6Line color="#A782C3" />,
        },
    ];
    const handleInsertBelow = () => {
        const { state, dispatch } = editor.view;
        const { $to } = editorPrevSelection;

        // Create a new paragraph node with the desired text
        const newParagraphNode = state.schema.nodes.paragraph.create(null, [
            state.schema.text(editorRespons?.response),
        ]);

        // Create a fragment with the new paragraph node
        const fragment = Fragment.fromArray([newParagraphNode]);

        // Create a transaction to insert the fragment below the selected node
        const transaction = state.tr.insert($to.after(), fragment);

        // Dispatch the transaction to update the editor's content
        dispatch(transaction);
    };
    return (
        <AskAiPortal
            editor={editor}
            top={-1}
            left={fullscreen ? 100 : 310}
            scrollDiv={scrollDiv}
            proseElement={proseElement}
            typewriterDiv={typewriterDiv}
        >
            {typingFinished && editorRespons?.response && !loader && !isTextAreaHaveValue && (
                <div className={`${styles.mainDropDown} min-w-[220px]`}>
                    <div>
                        {menuItems.map((menuItem, index) => (
                            <div
                                key={menuItem.id}
                                id={menuItem.id}
                                onMouseEnter={() => setActiveItem(index)}
                                className={`flex px-2 my-1 gap-2 items-center justify-between leading-120 w-full select-none min-h-[28px] text-base ${
                                    index === activeItem ? SlateStyles.activeClass : "inactiveClass"
                                }`}
                                onClick={() => {
                                    switch (menuItem.id) {
                                        case "replace-selection": {
                                            if (editor) {
                                                const { state, dispatch } = editor.view;
                                                const { from, to } = editorPrevSelection;

                                                // Check if there is a valid selection
                                                if (from !== to) {
                                                    // Create a transaction to replace the selected text
                                                    const transaction = state.tr
                                                        .delete(from, to) // Delete the selected text
                                                        .insertText(editorRespons?.response, from); // Insert the new text at the selection start

                                                    // Dispatch the transaction to update the editor's content
                                                    dispatch(transaction);
                                                }
                                            }
                                            dispatch(resetTryAgainAction());
                                            break;
                                        }
                                        case "insert-below": {
                                            handleInsertBelow();
                                            dispatch(toggleAskAiInputWthDrpDownAction(false));
                                            dispatch(changeToneToggleAction(false));
                                            dispatch(askAiAction(false));
                                            dispatch(resetEditorResponse());
                                            dispatch(resetTryAgainAction());
                                            const startPos = editorPrevSelection.from;
                                            const endPos = editorPrevSelection.to;
                                            const tr = editor.state.tr.removeMark(
                                                startPos,
                                                endPos,
                                                editor.schema.marks.highlight
                                            );
                                            editor.view.dispatch(tr);
                                            break;
                                        }
                                        case "try-again": {
                                            if (lastActionState) {
                                                const tryAgainObj = { payloadAction: "try-again", ...lastActionState };
                                                // @ts-ignore
                                                dispatch(sendingUserResponse(tryAgainObj));
                                            }
                                            break;
                                        }
                                        case "discard": {
                                            dispatch(toggleAskAiInputWthDrpDownAction(false));
                                            dispatch(changeToneToggleAction(false));
                                            dispatch(askAiAction(false));
                                            dispatch(resetEditorResponse());
                                            dispatch(resetTryAgainAction());
                                            const startPos = editorPrevSelection.from;
                                            const endPos = editorPrevSelection.to;
                                            const tr = editor.state.tr.removeMark(
                                                startPos,
                                                endPos,
                                                editor.schema.marks.highlight
                                            );
                                            editor.view.dispatch(tr);
                                            break;
                                        }
                                    }
                                }}
                            >
                                <div className="flex my-1 gap-2 items-center">
                                    <span> {menuItem.icon}</span>
                                    <h5 style={{ fontSize: "0.875em" }}>{menuItem.title}</h5>
                                </div>
                                <div
                                    style={{
                                        display: "inline-block",
                                        transform: "rotate(180deg) scaleX(-1) scaleY(1)",
                                    }}
                                >
                                    {activeItem === index && <HiArrowUturnLeft color="#37352F80" size={12} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </AskAiPortal>
    );
};
