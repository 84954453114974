/** @jsxImportSource @emotion/react */

import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { ComponentProps, ReactNode, useMemo, useState } from "react";
import { styles } from "./styles";
import tw from "twin.macro";
import classNames from "classnames";
import Icon from "components/atoms/icons/Icon";
import { Checkbox } from "components/atoms/checkbox";
import { CSSObject, Interpolation, Theme } from "@emotion/react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { LuSearch } from "react-icons/lu";

export type MenuItem<T> = {
    key: number | string;
    label: ReactNode;
    value?: T;
    searchValue?: string;
    selected?: boolean;
    onSelect: (value?: T, e?: Event) => void;
    preventAutoClose?: boolean;
    disabled?: boolean;
    style?: CSSObject;
    tooltipProps?: ComponentProps<typeof Tooltip>;
    subs?: ReactNode;
};

export type MultiselectMenuItem<T> = {
    key: number | string;
    label: ReactNode;
    value: T;
    searchValue?: string;
    checked?: boolean;
    onCheck: (checked: boolean, value?: T) => void;
};

type DropdownMenuProps<T> =
    | {
          items: MultiselectMenuItem<T>[];
          multiselect: true;
          subs?: never;
      }
    | {
          items: MenuItem<T>[];
          multiselect?: false;
      };

export type Props<T> = {
    label?: ReactNode;
    contentProps?: RadixDropdownMenu.DropdownMenuContentProps & { css?: Interpolation<Theme> };
    triggerProps?: RadixDropdownMenu.DropdownMenuTriggerProps & { css?: Interpolation<Theme> };
    portalProps?: RadixDropdownMenu.DropdownMenuPortalProps;
    disabled?: boolean;
    searchable?: boolean;
    searchPlaceholder?: string;
} & RadixDropdownMenu.DropdownMenuProps &
    DropdownMenuProps<T>;

const DropdownMenu = <T,>({
    children,
    label,
    items,
    contentProps,
    triggerProps,
    portalProps,
    disabled,
    multiselect,
    searchable,
    searchPlaceholder,
    ...props
}: Props<T>) => {
    const [query, setQuery] = useState("");

    const filteredItems = useMemo(() => {
        if (!searchable || !query.trim().length) return items;

        // @ts-ignore
        return items.filter(
            ({ searchValue }: { searchValue?: string }) =>
                !!searchValue?.trim()?.toLowerCase()?.startsWith(query?.trim()?.toLowerCase())
        );
    }, [items, query, searchable]);

    return (
        <RadixDropdownMenu.Root
            modal={false}
            {...props}
            onOpenChange={(o) => {
                if (!o) setQuery("");
                props.onOpenChange?.(o);
            }}
        >
            <RadixDropdownMenu.Trigger
                {...triggerProps}
                disabled={disabled}
                className={classNames([styles.trigger, triggerProps?.className])}
            >
                {children}
            </RadixDropdownMenu.Trigger>
            {!disabled && (
                <RadixDropdownMenu.Portal {...portalProps}>
                    <RadixDropdownMenu.Content className={styles.content} align="end" sideOffset={4} {...contentProps}>
                        {label && (
                            <RadixDropdownMenu.Label className="text-slate-500 text-xxs font-medium py-0.5 px-1">
                                {label}
                            </RadixDropdownMenu.Label>
                        )}
                        {searchable && (
                            <div
                                className="px-2 -mx-1 gap-1.5 flex flex-row items-center -mt-1 border-b border-b-gray-light"
                                css={[label && tw`border-t border-t-gray-light mt-0`]}
                            >
                                <LuSearch className="text-gray-400" />
                                <input
                                    autoFocus
                                    className="flex-1 outline-none py-3 text-sm text-gray-600"
                                    placeholder={searchPlaceholder || "Search..."}
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                            </div>
                        )}
                        {!filteredItems.length && (
                            <div className="min-h-[150px] text-xs text-gray-400 w-full flex justify-center items-center">
                                Nothing found
                            </div>
                        )}
                        {multiselect &&
                            (filteredItems as MultiselectMenuItem<T>[]).map(
                                ({ key, label, checked, value, onCheck }) => (
                                    <RadixDropdownMenu.CheckboxItem
                                        key={key}
                                        className={styles.checkboxItem}
                                        css={{ wordBreak: "break-word" }}
                                        onSelect={(e) => {
                                            e.preventDefault();
                                        }}
                                        checked={checked}
                                        onCheckedChange={(checkedState) => {
                                            onCheck(checkedState, value);
                                        }}
                                        onPointerLeave={(event) => event.preventDefault()}
                                        onPointerMove={(event) => event.preventDefault()}
                                    >
                                        <Checkbox checked={checked} onCheck={() => {}} /> {label}
                                    </RadixDropdownMenu.CheckboxItem>
                                )
                            )}
                        {!multiselect &&
                            (filteredItems as MenuItem<T>[]).map(
                                ({
                                    key,
                                    label,
                                    selected,
                                    value,
                                    style,
                                    disabled,
                                    preventAutoClose,
                                    onSelect,
                                    tooltipProps,
                                    subs,
                                }) =>
                                    !!tooltipProps
                                        ? subs || (
                                              <Tooltip key={key} {...tooltipProps}>
                                                  <RadixDropdownMenu.Item
                                                      className={styles.item}
                                                      css={[
                                                          { wordBreak: "break-word" },
                                                          style,
                                                          disabled && tw`cursor-default opacity-40 !bg-transparent`,
                                                      ]}
                                                      onSelect={(e) => {
                                                          if (disabled) {
                                                              e.preventDefault();
                                                              return;
                                                          }
                                                          if (preventAutoClose) e.preventDefault();
                                                          onSelect(value, e);
                                                      }}
                                                      onPointerLeave={(event) => event.preventDefault()}
                                                      onPointerMove={(event) => event.preventDefault()}
                                                  >
                                                      {label}{" "}
                                                      {selected && (
                                                          <Icon name="Check" className="w-5 h-5 min-w-[20px]" />
                                                      )}
                                                  </RadixDropdownMenu.Item>
                                              </Tooltip>
                                          )
                                        : subs || (
                                              <RadixDropdownMenu.Item
                                                  key={key}
                                                  className={styles.item}
                                                  css={[
                                                      { wordBreak: "break-word" },
                                                      disabled && tw`pointer-events-none opacity-40`,
                                                      style,
                                                  ]}
                                                  onSelect={(e) => {
                                                      if (disabled) return;
                                                      if (preventAutoClose) e.preventDefault();
                                                      onSelect(value, e);
                                                  }}
                                                  onPointerLeave={(event) => event.preventDefault()}
                                                  onPointerMove={(event) => event.preventDefault()}
                                              >
                                                  {label}{" "}
                                                  {selected && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                                              </RadixDropdownMenu.Item>
                                          )
                            )}
                    </RadixDropdownMenu.Content>
                </RadixDropdownMenu.Portal>
            )}
        </RadixDropdownMenu.Root>
    );
};

DropdownMenu.Sub = RadixDropdownMenu.Sub;
DropdownMenu.SubTrigger = RadixDropdownMenu.SubTrigger;
DropdownMenu.Portal = RadixDropdownMenu.Portal;
DropdownMenu.SubContent = RadixDropdownMenu.SubContent;
DropdownMenu.Item = RadixDropdownMenu.Item;

export default DropdownMenu;
