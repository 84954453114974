import { useSelector } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocalStorage } from "hook/useLocalStorage";

// Initialize Amplitude with your project's API key
amplitude.init(process.env.REACT_APP_AMPLITUDE);

export const useTrackUserMetric = () => {
    const { localValue: workspaceId } = useLocalStorage("vultron_workspace_id", "");
    const { myWorkSpaces } = useSelector((store) => store.auth);

    const trackUserEvent = (eventInput, properties = {}, userProperties = {}) => {
        if (myWorkSpaces?.email.endsWith("@synthetics.dtdg.co")) {
            return;
        }

        amplitude.setGroup("workspace_ids", workspaceId);

        // Set user properties if provided
        if (Object.keys(userProperties).length > 0) {
            const identify = new amplitude.Identify();
            for (const [key, value] of Object.entries(userProperties)) {
                identify.set(key, value);
            }
            amplitude.identify(identify);
        }

        const eventProperties = {
            ...properties,
        };
        amplitude.track(eventInput, eventProperties);
    };

    return trackUserEvent;
};
