/** @jsxImportSource @emotion/react */

import { TwStyle } from "twin.macro";

const TypingEffectAiWriting = ({ color }: { color?: TwStyle[] }) => {
    return (
        <div className="typing-container-for-writing-ai flex items-center pb-2">
            <div className="Aidot" css={color}></div>
            <div className="Aidot" css={color}></div>
            <div className="Aidot" css={color}></div>
        </div>
    );
};

export default TypingEffectAiWriting;
