/** @jsxImportSource @emotion/react */

import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import Icon from "components/atoms/icons/Icon";
import { ComplianceMatrixRow, RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { HTMLAttributes, forwardRef, useCallback, useEffect, useState } from "react";
import tw from "twin.macro";
import WritingPrompts from "./writing-prompts";
import UserInstructions from "./user-instructions";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import * as Collapsible from "@radix-ui/react-collapsible";
import { VscCollapseAll, VscExpandAll } from "react-icons/vsc";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import RequirementOptionsDropdown from "components/molecules/requirement-options-dropdown/RequirementOptionsDropdown";
import { BsArrowsCollapse, BsArrowsExpand, BsThreeDotsVertical } from "react-icons/bs";
import Popover from "components/atoms/popover";
import AssigneesPopoverContent from "components/organisms/assignees-popover-content";
import { xor } from "lodash";
import useRequirementOperations from "hook/useRequirementOperations";
import { AvatarGroup } from "components/molecules/avatar-group";
import useGetAvatarGroup from "hook/draft/useAvatarGroup";
import { Avatar } from "components/molecules/avatar";
import RequirementStatusPopoverContent from "components/organisms/requirement-status-popover-content/RequirementStatusPopoverContent";
import { REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import RequirementResponse from "components/organisms/requirement-response";
import Tooltip from "components/atoms/tooltip";
import EditableContent from "components/molecules/editable-content";
import { IoLockOpenOutline } from "react-icons/io5";
import { IoLockClosed } from "react-icons/io5";
import { updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { Checkbox } from "components/atoms/checkbox";

interface Props extends HTMLAttributes<HTMLDivElement> {
    row: ComplianceMatrixRow;
    withOpacity?: boolean;
    isDragging?: boolean;
    dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
    sortedSections?: FormattedSection[];
}

const SectionRequirement = forwardRef<HTMLDivElement, Props>(
    ({ row, style, withOpacity, isDragging, dragProps, sortedSections }, ref) => {
        const { currentUser } = useAppSelector((store) => store.auth);
        const { workspaceMembers } = useAppSelector((store) => store.auth);
        const [textCollapsed, setTextCollapse] = useState(false);
        const { requirement, assigned_user_ids, requirement_status, locked, locked_actor } = row;
        const {
            requirementEditable,
            expandedRequirementId,
            activeDragRequirementId,
            collapseAllText,
            requirementHighlighted,
        } = useAppSelector((store) => store.sectionState.requirementsState);
        const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
        const checkedState = useAppSelector((root) => root.requirements.checkedState);
        const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
        const { setAssignees, setRequirementStatus, setRequirementContent, setRequirementLock } =
            useRequirementOperations();
        const avatars = useGetAvatarGroup(assigned_user_ids, { size: 18, className: "!text-xs" });
        const dispatch = useAppDispatch();

        const open = expandedRequirementId === requirement.id;
        const content = requirement?.content || requirement?.summarized_content || "";
        const isEditable = requirement.id === requirementEditable;
        const checked = checkedState[requirement.id];

        const lockedByUsername = workspaceMembers?.find((user) => user.id === locked_actor)?.username || "Removed User";

        useEffect(() => {
            setTextCollapse(collapseAllText);
        }, [collapseAllText]);

        const changeLock = (locked: boolean, locked_actor: string, current_locker?: string) => {
            if (!locked_actor) {
                // TODO: how to handle case here where id is not present
                return;
            }
            setRequirementLock(requirement.id, locked, locked_actor);
        };

        const handleToggleRowSelection = useCallback(
            (checked: boolean) => {
                const reqId = requirement?.id;
                dispatch(updateCheckedState({ [reqId]: checked }));
            },
            [dispatch, requirement?.id]
        );

        return (
            <div
                id={`section-requirement-${requirement.id}`}
                className="flex flex-row items-baseline gap-1.5 cursor-default"
                ref={ref}
                css={[
                    {
                        zIndex: isDragging ? "2" : "auto",
                        opacity: withOpacity ? "0.3" : "1",
                        pointerEvents: isEditable ? "none" : "auto",
                        ...style,
                    },
                ]}
                {...dragProps?.attributes}
            >
                <div className="flex flex-col items-center gap-2">
                    <div
                        className="flex z-[1] bg-transparent rounded p-1 text-slate-500 hover:text-slate-900 hover:bg-slate-200 group-hover:opacity-100"
                        css={[{ cursor: isDragging ? "grabbing" : "grab" }]}
                        {...dragProps?.listeners}
                    >
                        <Icon name="Draggable" className="w-3.5 h-3.5" />
                    </div>
                    <Checkbox
                        size="sm"
                        id={requirement?.id}
                        checked={checked || false}
                        onCheck={(checked) => handleToggleRowSelection(checked)}
                    />
                </div>
                <Collapsible.Root
                    open={open && !activeDragRequirementId}
                    onOpenChange={(o) =>
                        dispatch(setRequirementsState({ expandedRequirementId: o ? requirement.id : null }))
                    }
                    className="flex-1 max-w-[calc(100%-24px)]"
                >
                    <div
                        className="flex shadow-sharp-thin flex-col gap-2 p-2 pb-3 bg-white border border-gray-light rounded-md flex-1"
                        css={[
                            isDragging && tw`shadow-expanded`,
                            open && !activeDragRequirementId && tw`border-slate-500`,
                            requirementHighlighted === requirement.id && tw`bg-slate-100 border-action`,
                        ]}
                    >
                        <div className="flex items-center justify-between">
                            <div className="flex gap-1 items-center justify-between">
                                <div className="text-xs pl-1 font-medium text-slate-600 text-start">Requirement</div>
                                <Tooltip content={textCollapsed ? "Expand text" : "Collapse text"}>
                                    <button
                                        onClick={() => setTextCollapse((prev) => !prev)}
                                        className="text-xs px-0.5 text-slate-600 hover:text-slate-900"
                                    >
                                        {textCollapsed ? <BsArrowsExpand /> : <BsArrowsCollapse />}
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    content={
                                        locked ? `Unlock Response. Locked by ${lockedByUsername}` : "Lock Response"
                                    }
                                >
                                    <button
                                        onClick={() => changeLock(!locked, currentUser?.id || "", locked_actor)}
                                        className="text-xs px-0.5 text-slate-600 hover:text-slate-900"
                                    >
                                        {locked ? <IoLockClosed /> : <IoLockOpenOutline />}
                                    </button>
                                </Tooltip>
                            </div>
                            <div className="flex items-center gap-1.5">
                                <Popover
                                    contentProps={{ align: "end", css: tw`mx-0` }}
                                    content={
                                        <RequirementStatusPopoverContent
                                            selectedStatus={requirement_status || RequirementStatus.Todo}
                                            onStatusSelect={(newStatus) => {
                                                setRequirementStatus(requirement.id, newStatus);
                                            }}
                                            tw="p-1"
                                        />
                                    }
                                >
                                    <button className="text-slate-600 p-1 flex items-center justify-center min-w-[28px] min-h-[28px] rounded-md bg-transparent duration-150 hover:bg-slate-100">
                                        <div className="flex text-sm items-center gap-1.5 text-gray-500 truncate">
                                            {
                                                REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo]
                                                    .icon
                                            }
                                        </div>
                                    </button>
                                </Popover>
                                <Popover
                                    contentProps={{ align: "end", css: tw`mx-0` }}
                                    content={
                                        <AssigneesPopoverContent
                                            selectedUsers={assigned_user_ids || []}
                                            onUserSelect={(userId) => {
                                                const newAssignees = xor(assigned_user_ids || [], [userId]);
                                                setAssignees(requirement.id, newAssignees);
                                            }}
                                            onClearAll={() => setAssignees(requirement.id, [])}
                                            tw="p-1"
                                        />
                                    }
                                >
                                    <button className="text-slate-600 p-1 flex items-center justify-center min-w-[28px] min-h-[28px] rounded-md bg-transparent duration-150 hover:bg-slate-100">
                                        {!!avatars.length ? (
                                            <div className="flex text-xs items-center gap-2 text-gray-500">
                                                <AvatarGroup maxCount={6} size={20} avatars={avatars} />
                                            </div>
                                        ) : (
                                            <span className="text-xs text-slate-600">
                                                <Avatar size={20} empty />
                                            </span>
                                        )}
                                    </button>
                                </Popover>

                                <Collapsible.Trigger>
                                    <div
                                        className="text-xl rounded bg-transparent p-1 font-medium text-slate-600 text-start hover:bg-slate-100"
                                        css={[open && tw`!bg-slate-200 shadow-sharp-thin`]}
                                    >
                                        {open ? <VscCollapseAll /> : <VscExpandAll />}
                                    </div>
                                </Collapsible.Trigger>
                                <RequirementOptionsDropdown complianceMatrixRow={row} sections={sortedSections || []}>
                                    <div className="p-1 pl-0 text-base text-slate-500 duration-100 z-[1] hover:text-black">
                                        <BsThreeDotsVertical />
                                    </div>
                                </RequirementOptionsDropdown>
                            </div>
                        </div>
                        <EditableContent
                            content={content || ""}
                            onMouseDown={() =>
                                dispatch(setRequirementsState({ expandedRequirementId: requirement.id }))
                            }
                            onContentUpdate={(val) => setRequirementContent(requirement?.id, val)}
                            css={tw`text-sm p-1.5 flex-1`}
                            heightProps={[textCollapsed && tw`line-clamp-3 max-h-[60px] overflow-hidden`]}
                            textareaProps={{
                                placeholder: "Add requirement content...",
                                ...(sidebarSize && {
                                    forceResetProps: [sidebarSize, actionsPanelOpen],
                                    forceResetDelay: 150,
                                }),
                            }}
                        />
                        <Collapsible.Content className="collapsibleContent">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-3 border border-light rounded-md bg-slate-50 p-4 mx-1.5">
                                    <WritingPrompts row={row} />
                                    <UserInstructions row={row} />
                                </div>
                                <RequirementResponse complianceMatrixRow={row} />
                            </div>
                        </Collapsible.Content>
                    </div>
                </Collapsible.Root>
            </div>
        );
    }
);

export default SectionRequirement;
