import { ResponseSource } from "components/copilot/CopilotSchemaImmutableTypes";
import moment from "moment";
import { toggleSourceModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { getFileIcon } from "utils/getFileIcon";

type Props = {
    responseSources?: ResponseSource[];
};

const ResponseSources = ({ responseSources }: Props) => {
    const dispatch = useAppDispatch();

    return (
        <div className="flex flex-col py-2 gap-3 px-4 bg-gray-50 border border-gray-75 rounded-b-md">
            <div className="flex w-full">
                <div className="text-gray-500 text-xs font-normal">Sources</div>
            </div>
            {!!responseSources?.length ? (
                <span className="flex gap-2 overflow-x-auto">
                    {responseSources?.map((source, idx) => (
                        <button
                            key={`${source.file_id}_${idx}`}
                            onClick={() => {
                                dispatch(
                                    toggleSourceModal({
                                        open: true,
                                        initialProps: {
                                            id: source.file_id,
                                            extension: source.extension_type,
                                            name: source.name,
                                            sourceContent: source.used_file_contents
                                                .map((uc) => `${uc.requirement_source_citations}\n\n${uc.content}\n`)
                                                .join("\n"),
                                        },
                                    })
                                );
                            }}
                            className="flex gap-2 items-center rounded border-[1.5px] border-gray-mid pl-2 py-1.5 pr-3 mb-1 h-[50px] max-w-[220px] duration-100 bg-white hover:bg-gray-100"
                        >
                            <img src={getFileIcon(source.extension_type)} alt="" className="h-[24px]" />
                            <div className="flex flex-col items-start min-w-[150px]">
                                <div className="text-xs text-gray-darkest max-w-full truncate">{source.name}</div>
                                <div className="text-xxs text-gray-mid whitespace-nowrap">
                                    {moment(source?.date).format("MMMM DD, YYYY")}
                                </div>
                            </div>
                        </button>
                    ))}
                </span>
            ) : (
                <div className="text-gray-400 mb-1 text-xs font-normal">No sources used during generation.</div>
            )}
        </div>
    );
};

export default ResponseSources;
