import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import Chip from "components/atoms/chip/Chip";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setExtractState } from "store/reducers/extract/ExtractReducer";
import { theme } from "twin.macro";
import tw from "twin.macro";
import { TemplateDocumentType } from "./Extract";
import ExtractToSheetDropdown from "./ExtractToSheetDropdown";
import Icon from "components/atoms/icons/Icon";
import SwitchBtn from "components/SwitchBtn";
import { DropdownMenu } from "components/molecules/dropdown-menu";

export const TemplateExtractionSettings = () => {
    const { templateDocumentType, shouldGenerateTemplateWithRequirements } = useAppSelector(
        (state) => state.extractReducer
    );
    const dispatch = useAppDispatch();
    const [isOther, setIsOther] = useState(false);
    const [isSBIR, setIsSBIR] = useState(false);

    const [showSbirDropdown, setShowSbirDropdown] = useState(false);

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            dispatch(setExtractState({ templateDocumentType: event.target.value }));
        },
        [dispatch]
    );

    const documentTypes = useMemo(() => Object.values(TemplateDocumentType), []);

    const handleButtonClick = useCallback(
        (option: string) => {
            if (option.includes("SBIR") && option !== TemplateDocumentType.SBIR) {
                setIsSBIR(true);
                dispatch(
                    setExtractState({
                        shouldGenerateTemplateWithRequirements: true,
                        templateDocumentType: option,
                    })
                );
            } else if (option === TemplateDocumentType.SBIR) {
                setShowSbirDropdown(true);
                setIsOther(false);
                dispatch(
                    setExtractState({
                        shouldGenerateTemplateWithRequirements: true,
                        templateDocumentType: option,
                    })
                );
            } else if (option === TemplateDocumentType.Outline) {
                setShowSbirDropdown(false);
                setIsOther(false);
                setIsSBIR(false);
                dispatch(
                    setExtractState({
                        shouldGenerateTemplateWithRequirements: false,
                        templateDocumentType: option,
                    })
                );
            } else {
                setShowSbirDropdown(false);
                setIsOther(false);
                setIsSBIR(false);
                dispatch(
                    setExtractState({
                        shouldGenerateTemplateWithRequirements: true,
                        templateDocumentType: option,
                    })
                );
            }
        },
        [dispatch]
    );

    const items = [
        {
            key: 1,
            label: "Phase 1",
            selected: templateDocumentType === "SBIR Phase 1",
            value: "Phase 1",
            onSelect: () => handleButtonClick("SBIR Phase 1"),
        },
        {
            key: 2,
            selected: templateDocumentType === "SBIR Phase 2",
            label: "Phase 2",
            value: "Phase 2",
            onSelect: () => handleButtonClick("SBIR Phase 2"),
        },
        {
            key: 3,
            selected: templateDocumentType === "SBIR Phase 3",
            label: "Phase 3",
            value: "Phase 3",
            onSelect: () => handleButtonClick("SBIR Phase 3"),
        },
    ];
    const selectedLabel = items.find((item) => templateDocumentType.includes(item.value))?.label || "Select Phase";

    return (
        <div className="max-w-750 flex flex-col gap-3">
            <div className="text-sm font-normal text-gray-500">Select Type</div>
            <div className="flex flex-wrap gap-2">
                {documentTypes.map((option: string) => (
                    <Chip
                        clickable
                        key={option}
                        onClick={() => handleButtonClick(option)}
                        colors={{
                            primaryColor:
                                option === templateDocumentType ||
                                // (isOther && option === TemplateDocumentType.Other) ||
                                (isSBIR && option === TemplateDocumentType.SBIR)
                                    ? theme`colors.white`
                                    : "#F1F1F1",
                            secondaryColor:
                                option === templateDocumentType ||
                                // (isOther && option === TemplateDocumentType.Other) ||
                                (isSBIR && option === TemplateDocumentType.SBIR)
                                    ? theme`colors.action.DEFAULT`
                                    : "#5B6B79",
                        }}
                        className="h-[28px]"
                        variant={
                            option === templateDocumentType ||
                            // (isOther && option === TemplateDocumentType.Other) ||
                            (isSBIR && option === TemplateDocumentType.SBIR)
                                ? "primary"
                                : "secondary"
                        }
                    >
                        {option}
                    </Chip>
                ))}
            </div>
            {showSbirDropdown && (
                <DropdownMenu items={items} contentProps={{ css: tw`min-w-[180px]`, align: "start" }}>
                    <div className="w-40 rounded-md justify-between flex items-center gap-4 border border-gray-light mt-2 px-2 py-1.5 capitalize text-sm text-gray-darkest duration-100 hover:border-gray-600">
                        {selectedLabel}
                        <Icon name="CarrotDown" />
                    </div>
                </DropdownMenu>
            )}
            <div className="text-sm font-normal text-gray-500">
                {isOther && (
                    <input
                        placeholder="Please specify type"
                        className="text-[14px] w-full placeholder:text-gray-400 outline-0 text-[#1e1e1e] focus:border-action mt-2 px-3 py-2 rounded-lg border"
                        onChange={handleInputChange}
                        value={templateDocumentType}
                    />
                )}
            </div>
            <div className="gap-2 w-full">
                {templateDocumentType && templateDocumentType !== "Outline" && (
                    <div className="text-gray-text text-sm pb-2 flex items-center gap-[0.2rem]">
                        <span className="text-sm font-normal text-gray-500 mr-2">Full Template</span>
                        <SwitchBtn
                            value={shouldGenerateTemplateWithRequirements}
                            handleChange={() =>
                                dispatch(
                                    setExtractState({
                                        shouldGenerateTemplateWithRequirements: !shouldGenerateTemplateWithRequirements,
                                    })
                                )
                            }
                        />
                    </div>
                )}
                {templateDocumentType === "RFP" && (
                    <div>
                        {shouldGenerateTemplateWithRequirements ? (
                            <div className="text-sm font-normal text-gray-500">
                                Vultron will build a full template including the outline and instruction requirements
                                for the proposal.
                            </div>
                        ) : (
                            <div className="text-sm font-normal text-gray-500">
                                Vultron will extract a compliant outline including volumes, section, and subsections for
                                the proposal.
                            </div>
                        )}
                    </div>
                )}
                {templateDocumentType === "RFI" && (
                    <div>
                        {shouldGenerateTemplateWithRequirements ? (
                            <div className="text-sm font-normal text-gray-500">
                                Vultron will build a full template including the outline, questions, and key topics for
                                the response.
                            </div>
                        ) : (
                            <div className="text-sm font-normal text-gray-500">
                                Vultron will build an outline including sections and subsections for the response.
                            </div>
                        )}
                    </div>
                )}
                {templateDocumentType.includes("SBIR") && (
                    <div>
                        {shouldGenerateTemplateWithRequirements ? (
                            <div className="text-sm font-normal text-gray-500">
                                Vultron will build a full template including the outline and all of the instruction
                                requirements for the proposal.
                            </div>
                        ) : (
                            <div className="text-sm font-normal text-gray-500">
                                Vultron will extract an outline including volumes, sections, and subsections for the
                                proposal.
                            </div>
                        )}
                    </div>
                )}
                {templateDocumentType === "Outline" && (
                    <div className="text-sm font-normal text-gray-500">
                        Vultron will import an outline including sections and subsections for the proposal.
                    </div>
                )}
            </div>
            {shouldGenerateTemplateWithRequirements && <ExtractToSheetDropdown />}
        </div>
    );
};
