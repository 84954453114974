/** @jsxImportSource @emotion/react */

import { useParams } from "react-router-dom";
import Volumes from "./volumes";
import DraftVolume from "pages/draft-volume";
import tw from "twin.macro";

const Draft = () => {
    const { volumeId } = useParams();

    return (
        <div className="flex-1" css={[!!volumeId && tw`-mt-5 overflow-hidden`]}>
            {volumeId ? <DraftVolume /> : <Volumes />}
        </div>
    );
};

export default Draft;
