// ------------------ Loading component for Auth screens ------------------
const Loading = () => {
    return (
        <div className="p-28 flex items-center justify-center">
            <svg className="flex items-center flex-col" width={100} height={100}>
                <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#2a47ab"
                    strokeWidth="3"
                    r="35"
                    strokeDasharray="164.93361431346415 56.97787143782138"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                    ></animateTransform>
                </circle>
            </svg>
        </div>
    );
};

export default Loading;
