import { LiveObject } from "@liveblocks/client";
import {
    ComplianceMatrixRow,
    RequirementStatus,
    RequirementCompliance,
    Section as ImmutableSection,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { ProposalReference, Storage } from "components/copilot/CopilotSchemaTypes";
import { addRequirementToSection } from "components/copilot/Framework/utils";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import useRequirementOperations from "hook/useRequirementOperations";
import { useMutation } from "liveblocks.config";
import { useCallback, useMemo } from "react";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { createComplianceMatrixRow, createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { useTrackUserMetric } from "utils/metrics";

export const useBulkUpdateOperation = () => {
    const { checkedState, activeSheet } = useAppSelector((root) => root.requirements);
    const { deleteRequirementRow } = useRequirementOperations();
    const { generateRequirementHeading } = useGenerateRequirementHeading();
    const dispatch = useAppDispatch();
    const trackUserEvent = useTrackUserMetric();
    const checkedReqIds = useMemo(
        () => Object.keys(checkedState).filter((reqId) => checkedState[reqId]),
        [checkedState]
    );

    const removeRows = useCallback(() => {
        checkedReqIds.forEach((id) => {
            deleteRequirementRow(id);
        });
        dispatch(setCheckedState({}));
    }, [checkedReqIds, deleteRequirementRow, dispatch]);

    const assignToSection = useMutation(
        ({ storage }, section: ImmutableSection) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const volumes = (storage.get("framework") as Storage["framework"])?.get("volumes");
            const liveRequirements = complianceMatrix?.filter((row) =>
                checkedReqIds.includes(row.get("requirement").get("id"))
            );
            const requirementsIds = liveRequirements
                ?.filter(
                    (row) =>
                        !!(
                            row.get("written_content") ||
                            row.get("requirement").get("content") ||
                            row.get("requirement").get("summarized_content")
                        )
                )
                ?.map((row) => row.get("requirement").get("id"));

            generateRequirementHeading({ requirement_ids: requirementsIds });

            liveRequirements.forEach((complianceMatrixRow) => {
                addRequirementToSection({
                    complianceMatrix,
                    activeRow: complianceMatrixRow,
                    volumeList: volumes,
                    destinationSectionId: section.id,
                });
            });
            trackUserEvent("Requirements: Requirement Bulk Added to Section", {
                number_requirements: liveRequirements.length,
                section_id: String(section.id),
            });
        },
        [checkedReqIds]
    );

    const setRequirementStatus = useMutation(
        ({ storage }, status: ComplianceMatrixRow["requirement_status"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirements = complianceMatrix?.filter((row) =>
                checkedReqIds.includes(row.get("requirement").get("id"))
            );

            liveRequirements.forEach((complianceMatrixRow) => {
                complianceMatrixRow.set("requirement_status", status);
            });

            trackUserEvent("Requirements: Requirement Status Bulk Updated", {
                number_requirements: liveRequirements.length,
                new_requirement_status: status
                    ? REQUIREMENT_STATUS_TO_META[status]?.label
                    : REQUIREMENT_STATUS_TO_META[RequirementStatus.Todo].label,
            });
        },
        [checkedReqIds]
    );

    const setComplianceStatus = useMutation(
        ({ storage }, status: ComplianceMatrixRow["compliance_status"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirements = complianceMatrix?.filter((row) =>
                checkedReqIds.includes(row.get("requirement").get("id"))
            );

            liveRequirements.forEach((complianceMatrixRow) => {
                complianceMatrixRow.set("compliance_status", status);
            });

            trackUserEvent("Requirements: Compliance Status Bulk Updated", {
                number_requirements: liveRequirements.length,
                new_compliance_status: status
                    ? COMPLIANCE_TO_META[status]?.label
                    : COMPLIANCE_TO_META[RequirementCompliance.Empty].label,
            });
        },
        [checkedReqIds]
    );

    const setSkipped = useMutation(
        ({ storage }, skipped: ComplianceMatrixRow["requirement"]["skipped"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirements = complianceMatrix?.filter((row) =>
                checkedReqIds.includes(row.get("requirement").get("id"))
            );

            liveRequirements.forEach((complianceMatrixRow) => {
                complianceMatrixRow.get("requirement").set("skipped", skipped);
            });
        },
        [checkedReqIds]
    );

    const setAssignees = useMutation(
        ({ storage }, assignees: ComplianceMatrixRow["assigned_user_ids"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirements = complianceMatrix?.filter((row) =>
                checkedReqIds.includes(row.get("requirement").get("id"))
            );

            liveRequirements.forEach((complianceMatrixRow) => {
                complianceMatrixRow.set("assigned_user_ids", assignees);
            });

            trackUserEvent("Requirements: Assignees Bulk Updated", {
                number_requirements: liveRequirements.length,
                new_assignees_count: assignees?.length ?? 0,
            });
        },
        [checkedReqIds]
    );

    const mergeRequirements = useMutation(
        ({ storage }, retainSection?: boolean) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirements = complianceMatrix?.filter((row) =>
                checkedReqIds.includes(row.get("requirement").get("id"))
            );
            let minIndex = complianceMatrix.length;
            const mergedContent = liveRequirements.map((row) => row.get("requirement")?.get("content")).join("\n\n");
            const createdRequirement = createComplianceMatrixRowRequirement({
                extraction_id: activeSheet?.id,
                content: mergedContent,
            });
            const newRow = createComplianceMatrixRow({
                requirement: createdRequirement,
                ...(retainSection && {
                    proposal_reference: new LiveObject(liveRequirements[0]?.toImmutable().proposal_reference),
                }),
            });

            liveRequirements.forEach((complianceMatrixRow) => {
                const idx = complianceMatrix.findIndex(
                    (row) => row.get("requirement")?.get("id") === complianceMatrixRow.get("requirement")?.get("id")
                );

                if (idx >= 0) minIndex = Math.min(minIndex, idx);
                complianceMatrix.delete(idx);
            });

            complianceMatrix.insert(newRow, minIndex);
            dispatch(setCheckedState({}));
        },
        [checkedReqIds, dispatch, activeSheet?.id]
    );

    return {
        checkedReqIds,
        removeRows,
        setRequirementStatus,
        setComplianceStatus,
        setSkipped,
        assignToSection,
        setAssignees,
        mergeRequirements,
    };
};
