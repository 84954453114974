/** @jsxImportSource @emotion/react */

import { PopoverClose } from "@radix-ui/react-popover";
import Icon from "components/atoms/icons/Icon";
import { useMemo } from "react";
import { RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { REQUIREMENT_STATUS_TO_META } from "const-values/Draft";

type Props = {
    isolated?: boolean;
    selectedStatus?: RequirementStatus;
    onStatusSelect: (id: RequirementStatus) => void;
};

const RequirementStatusPopoverContent = ({ isolated, selectedStatus, onStatusSelect, ...props }: Props) => {
    const statusOptions = useMemo(() => {
        return Object.values(RequirementStatus).map((status) => ({
            status,
            ...REQUIREMENT_STATUS_TO_META[status],
        }));
    }, []);

    return (
        <div className="w-max max-w-[425px] overflow-hidden bg-white" {...props}>
            <div className="flex flex-col max-h-[180px] overflow-y-auto">
                {statusOptions?.map(({ label, status }) =>
                    isolated ? (
                        <button
                            onClick={() => onStatusSelect(status)}
                            className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
                        >
                            <div className="flex items-center gap-2 line-clamp-1">
                                {REQUIREMENT_STATUS_TO_META[status || RequirementStatus.Todo].icon}
                                {label}
                            </div>
                            {selectedStatus === status && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                        </button>
                    ) : (
                        <PopoverClose key={status}>
                            <button
                                onClick={() => onStatusSelect(status)}
                                className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
                            >
                                <div className="flex items-center gap-2 line-clamp-1">
                                    {REQUIREMENT_STATUS_TO_META[status || RequirementStatus.Todo].icon}
                                    {label}
                                </div>
                                {selectedStatus === status && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                            </button>
                        </PopoverClose>
                    )
                )}
            </div>
        </div>
    );
};

export default RequirementStatusPopoverContent;
