import { DefaultStepType } from "types/ProjectProgress";
import { Arcade } from "utils/arcade";

export const DEFAULT_STEP_TO_META: Record<DefaultStepType, { name: string; description: string; arcade?: Arcade }> = {
    [DefaultStepType.ProjectDescription]: {
        name: "Add Project Description",
        description: "Include a detailed project description for optimal results",
        arcade: Arcade.Project,
    },
    [DefaultStepType.ProjectAttachments]: {
        name: "Add Project Attachments",
        description: "Upload attachments to provide context on the project",
    },
    [DefaultStepType.WinThemes]: {
        name: "Provide Win Themes",
        description: "Provide organization win themes for the proposal",
        arcade: Arcade.WinThemes,
    },
    [DefaultStepType.Requirements]: {
        name: "Extract Requirements",
        description: "Extract all project requirements",
        arcade: Arcade.Requirements,
    },
    [DefaultStepType.RequirementResponses]: {
        name: "Respond to Requirements (Optional)",
        description: "Provide a response to each individual requirements",
    },
    [DefaultStepType.TemplateSelection]: {
        name: "Select Template",
        description: "Import, extract, or generate a proposal template",
        arcade: Arcade.Template,
    },
    [DefaultStepType.RequirementAssignment]: {
        name: "Assign Requirements to Draft Sections",
        description: "Assign and add additional requirements to each draft section",
    },
    [DefaultStepType.ProposalSectionWriting]: {
        name: "Draft Proposal Sections",
        description: "Generate all proposal draft sections based on requirements",
    },
    [DefaultStepType.ProposalGeneration]: {
        name: "Import Draft",
        description: "Import the draft to generate the proposal document",
        arcade: Arcade.Editor,
    },
    [DefaultStepType.Export]: { name: "Export Proposal", description: "Export the final, revised proposal document" },
};
