import dots from "Assets/3dots.svg";
import dltLoader from "Assets/delete-loader.svg";
import properties from "Assets/properties-icon.svg";
import TagWithColor from "components/Drive/TagWithColor";
import { useMemo } from "react";
import { setDraggingDocId, setDraggingFolderId } from "store/reducers/driveReducerSlice";
import { useAppDispatch } from "store/storeTypes";
import { WorkspaceMedia } from "types/FileStorage";
import { MoveIcon } from "utils/icons";
import { useMediaMutateOperations } from "./hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import tw from "twin.macro";
import { HiOutlineDownload } from "react-icons/hi";
import { LuTrash2 } from "react-icons/lu";
interface Props {
    media: WorkspaceMedia;
    setSelectedMedia: (media: WorkspaceMedia) => void;
    onMediaFileDeleted: (id: string) => void;
    setIsPreviweModalOpen: (isOpen: boolean) => void;
    setIsUpdateMediaFileModalOpen: (isOpen: boolean) => void;
    setIsMoveMediaFileModalOpen: (isOpen: boolean) => void;
}

export default function Media({
    media,
    onMediaFileDeleted,
    setSelectedMedia,
    setIsPreviweModalOpen,
    setIsUpdateMediaFileModalOpen,
    setIsMoveMediaFileModalOpen,
}: Props) {
    const { id, file_name, formatted_updated_date, user_tags, download_url } = media;
    const { isDeletingFile, deleteFile } = useMediaMutateOperations();
    const dispatch = useAppDispatch();

    const handleDrag = (e: any) => {
        e.preventDefault();
        dispatch(setDraggingDocId(id));
        dispatch(setDraggingFolderId(""));
    };

    const menuItems = useMemo(
        () => [
            {
                key: 1,
                label: (
                    <div className="flex items-center gap-2">
                        <HiOutlineDownload /> Download
                    </div>
                ),
                onSelect: () => {
                    const a = document.createElement("a");
                    a.href = download_url;
                    a.download = file_name;
                    a.click();
                },
            },
            {
                key: 2,
                label: (
                    <div className="flex items-center gap-2">
                        <img src={properties} alt="" /> Properties
                    </div>
                ),
                onSelect: () => {
                    setIsUpdateMediaFileModalOpen(true);
                    setSelectedMedia(media);
                },
            },
            {
                key: 3,
                label: (
                    <div className="flex items-center gap-2">
                        <MoveIcon />
                        Move
                    </div>
                ),
                onSelect: () => {
                    setIsMoveMediaFileModalOpen(true);
                    setSelectedMedia(media);
                },
            },
            {
                key: 4,
                label: (
                    <div className="flex items-center gap-2 text-red-500">
                        {isDeletingFile ? <img src={dltLoader} alt="icon" width={14} height={14} /> : <LuTrash2 />}
                        Delete
                    </div>
                ),
                disabled: isDeletingFile,
                onSelect: async () => {
                    const deletedFile = await deleteFile(id);
                    if (deletedFile) {
                        onMediaFileDeleted(id);
                    }
                },
            },
        ],
        [
            deleteFile,
            download_url,
            file_name,
            id,
            isDeletingFile,
            media,
            onMediaFileDeleted,
            setIsMoveMediaFileModalOpen,
            setIsUpdateMediaFileModalOpen,
            setSelectedMedia,
        ]
    );

    return (
        <div className="card flex flex-col 2xl:w-[258px]" onDrag={handleDrag} draggable>
            <figure
                className="relative overflow-hidden w-full h-48 rounded-t-md bg-[#DEE1E3] flex justify-center items-center cursor-pointer"
                onClick={(e) => {
                    setSelectedMedia(media);
                    setIsPreviweModalOpen(true);
                }}
            >
                <img
                    src={download_url}
                    alt="graphics"
                    loading="lazy"
                    className="w-full max-w-full h-full pointer-events-none object-cover"
                />
                <div className="iframe-overlay absolute h-full w-full cursor-pointer bg-[transparent]"></div>
            </figure>
            <div className="border-[1px] flex-1 bg-white p-4 rounded-b-md z-20">
                <div className="flex justify-between gap-2">
                    <p className="font-bold text-[10px] break-all mb-2">{file_name}</p>

                    <DropdownMenu triggerProps={{ css: tw`h-fit` }} items={menuItems}>
                        <div className="cursor-pointer ml-auto min-w-[20px]">
                            <img
                                src={dots}
                                alt="Three Dots"
                                className="max-w-full mx-auto pointer-events-none object-contain"
                            />
                        </div>
                    </DropdownMenu>
                </div>
                <div>
                    <p className="text-[10px] text-[#5B6B79] mb-2">{formatted_updated_date}</p>
                </div>
                <div className="flex flex-wrap gap-1">
                    {user_tags.map((tag, index) => (
                        <TagWithColor key={index} tag={tag} />
                    ))}
                </div>
            </div>
        </div>
    );
}
