import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";

export type FileMention = {
    id: string;
    name: string;
    fileExtensionType: string;
};

export type State = {
    open: boolean;
    prompt: string;
    highlightedText: string;
    streamState: {
        blockId: string | null;
        isStreamingInProgress: boolean;
        streamCopy: string;
    };
    selectedFiles: FileMention[];
    enableInternet: boolean;
};

const initialState: State = {
    open: false,
    prompt: "",
    highlightedText: "",
    streamState: {
        blockId: null,
        isStreamingInProgress: false,
        streamCopy: "",
    },
    selectedFiles: [],
    enableInternet: false,
};

const writingAssistantReducer = createSlice({
    name: "writingAssistant",
    initialState,
    reducers: {
        resetAssistantState: () => {
            return initialState;
        },
        setAssistantPrompt: (state: State, action: PayloadAction<State["prompt"]>) => {
            state.prompt = action.payload;
        },
        setHighlightedText: (state: State, action: PayloadAction<State["highlightedText"]>) => {
            state.highlightedText = action.payload;
        },
        insertAssistantFiles: (state: State, action: PayloadAction<State["selectedFiles"]>) => {
            state.selectedFiles = uniqBy([...state.selectedFiles, ...action.payload], "id");
        },
        removeAssistantFiles: (state: State, action: PayloadAction<FileMention["id"][]>) => {
            state.selectedFiles = state.selectedFiles.filter(({ id }) => !action.payload.includes(id));
        },
        removeAllAssistantFiles: (state: State) => {
            state.selectedFiles = [];
        },
        setEnableInternet: (state: State, action: PayloadAction<boolean>) => {
            state.enableInternet = action.payload;
        },
        setStreamingState: (state: State, action: PayloadAction<Partial<State["streamState"]>>) => {
            const reset = !Object.keys(action.payload).length;
            if (reset) {
                state.streamState = initialState.streamState;
            } else {
                state.streamState = { ...state.streamState, ...action.payload };
            }
        },
    },
});

export const {
    setStreamingState,
    removeAllAssistantFiles,
    resetAssistantState,
    setAssistantPrompt,
    setHighlightedText,
    insertAssistantFiles,
    removeAssistantFiles,
    setEnableInternet,
} = writingAssistantReducer.actions;

export default writingAssistantReducer.reducer;
