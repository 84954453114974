import { shallow } from "@liveblocks/client";
import { Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { useStorage } from "liveblocks.config";
import { Outlet, useParams } from "react-router-dom";
import DraftVolumeSidebar from "./draft-volume-sidebar";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { MdDragIndicator } from "react-icons/md";
import { useAppDispatch } from "store/storeTypes";
import { resetSectionState, setSectionState } from "store/reducers/draft/sectionReducer";
import { useEffect } from "react";

const DraftVolume = () => {
    const { volumeId } = useParams();
    const volume = useStorage(
        (storage) => (storage.framework as Storage["framework"]).volumes.find((vol) => vol.id === volumeId),
        shallow
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetSectionState());
        };
    }, [dispatch]);

    return (
        <div className="flex flex-1 flex-row h-full">
            <PanelGroup direction="horizontal">
                <Panel
                    defaultSize={30}
                    minSize={18}
                    maxSize={44}
                    onResize={(size) => dispatch(setSectionState({ sidebarSize: size }))}
                >
                    <DraftVolumeSidebar volume={volume} />
                </Panel>
                <PanelResizeHandle className="z-[1] relative group flex justify-center">
                    <div className="z-[1] absolute top-14 py-0.5 -left-[6.5px] rounded bg-slate-800 hover:bg-gray-darkest">
                        <MdDragIndicator className="text-gray-50 text-sm" />
                    </div>
                    <div className="w-px h-full bg-gray-200 delay-300 duration-150 group-hover:bg-slate-800 group-hover:scale-x-[2.5]" />
                </PanelResizeHandle>
                <Panel defaultSize={70}>
                    <Outlet context={{ volume }} />
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default DraftVolume;
