import { Navigate, Outlet, useLocation } from "react-router-dom";
import logo from "../../Assets/mainlogo.svg";
import { useSelector } from "react-redux";
import Loader from "utils/Spinner";

const WelcomePage = () => {
    const { isAuthenticated, isLoading } = useSelector((store) => store.auth);
    let location = useLocation();
    const { from } = location.state || { from: { pathname: "/dashboard/select-workspace" } };

    //
    if (isLoading)
        return (
            <div className="h-screen mx-auto flex content-center items-center justify-center py-5 px-2 text-center sm:px-4">
                <Loader />
            </div>
        );
    if (isAuthenticated) return <Navigate to={from?.pathname} replace />;
    return (
        <div className="flex w-full min-h-[100vh]">
            {/* left */}
            <div className="bg-white w-3/5 flex flex-col">
                {/* logo */}
                <div className="absolute top-10 left-14 z-[2]">
                    <img width="150px" height="139px" src={logo} alt="logo" />
                </div>
                {/* content-wrapper */}
                <div className="flex flex-col items-center w-full h-full justify-center mt-9">
                    <Outlet />
                </div>
            </div>
            {/* right */}
            <div className="bg-header-img w-2/5 bg-cover"></div>
        </div>
    );
};

export default WelcomePage;
