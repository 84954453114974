/** @jsxImportSource @emotion/react */
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import ClickAwayListener from "helpers/ClickAwayListener";
import TagComponent from "./TagComponent";
import tw from "twin.macro";

interface TagDropdownProps {
    selectedTags: string[];
    setSelectedTags: (tags: string[]) => void;
    availableTags: string[];
    dropdownIcon: string;
    placeholder: string;
    suggestedTags?: string[];
}

const TagsDropdown = forwardRef<HTMLDivElement, TagDropdownProps>(
    ({ selectedTags, setSelectedTags, availableTags, dropdownIcon, placeholder, suggestedTags }, ref) => {
        const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(false);
        const [tagInput, setTagInput] = useState("");

        const handleTagSelect = useCallback(
            (tag: string) => {
                if (selectedTags.includes(tag)) {
                    setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
                } else {
                    if (tag === tagInput) setTagInput("");
                    setSelectedTags([...selectedTags, tag]);
                }
            },
            [selectedTags, setSelectedTags, tagInput]
        );

        const matchedTags = useMemo(
            () =>
                availableTags.length > 0
                    ? availableTags.filter((tag) => tag.toLowerCase().includes(tagInput.toLowerCase()))
                    : (suggestedTags ?? []).filter((tag) => tag.toLowerCase().includes(tagInput.toLowerCase())),
            [availableTags, suggestedTags, tagInput]
        );

        const filteredTags = useMemo(
            () => matchedTags.filter((tag) => !selectedTags.includes(tag)),
            [matchedTags, selectedTags]
        );

        useEffect(() => {
            if (isTagsDropdownOpen && ref && typeof ref === "object" && "current" in ref && ref.current !== null) {
                const element = ref.current;
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: "smooth",
                });
            }
        }, [isTagsDropdownOpen, ref]);

        return (
            <ClickAwayListener onClickAway={() => setIsTagsDropdownOpen(false)}>
                <div className="w-full relative">
                    <button
                        className="flex items-center cursor-text py-2 w-full pl-2.5 pr-2 min-h-[40px] text-left bg-white border rounded-md text-sm outline-none focus-within:border-action"
                        onClick={() => setIsTagsDropdownOpen(true)}
                    >
                        <div className="flex flex-wrap gap-2 items-center">
                            {selectedTags.length > 0 &&
                                selectedTags.map((tag) => (
                                    <TagComponent key={tag} tag={tag} handleTagSelect={handleTagSelect} removeTag />
                                ))}
                            <div
                                className="flex-grow"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        let tagList = selectedTags.map((element) => {
                                            return element?.toLowerCase()?.trim();
                                        });
                                        if (tagList?.includes(tagInput?.toLowerCase()?.trim())) {
                                            setTagInput("");
                                            return;
                                        }
                                        setSelectedTags([...selectedTags, tagInput]);
                                        setTagInput("");
                                    }
                                }}
                            >
                                <input
                                    type="text"
                                    placeholder={placeholder}
                                    className="outline-none text-sm text-[#5b6b79] w-full"
                                    value={tagInput}
                                    onChange={(e) => setTagInput(e.target.value)}
                                />
                            </div>
                        </div>

                        <img
                            src={dropdownIcon}
                            alt="Icon"
                            loading="lazy"
                            className={"ml-auto cursor-pointer p-2"}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsTagsDropdownOpen((v) => !v);
                            }}
                            css={[
                                isTagsDropdownOpen &&
                                    (!!filteredTags?.length || !!tagInput.trim().length) &&
                                    tw`rotate-180`,
                            ]}
                        />
                    </button>
                    {isTagsDropdownOpen && (!!filteredTags?.length || !!tagInput.trim().length) && (
                        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-[0_9px_28px_#00000014] p-3">
                            <div className="flex items-center justify-between">
                                {" "}
                                <h5 className="text-[10px] text-[#5B6B79]">
                                    {availableTags.length > 0 ? "Recommended" : "Examples"}
                                </h5>
                                {(!!filteredTags?.length ||
                                    (!!tagInput.trim() && !selectedTags.includes(tagInput))) && (
                                    <button
                                        className="text-xxs pr-2 font-medium text-action duration-100 hover:text-action-hover disabled:text-gray-500"
                                        onClick={() => {
                                            setSelectedTags([
                                                ...selectedTags,
                                                ...filteredTags,
                                                ...(!!tagInput.trim() && !selectedTags.includes(tagInput)
                                                    ? [tagInput.trim()]
                                                    : []),
                                            ]);
                                            setTagInput("");
                                        }}
                                    >
                                        Select All
                                    </button>
                                )}
                            </div>
                            <ul className="flex flex-wrap gap-2 max-h-[110px] overflow-y-auto pt-1.5">
                                {filteredTags.map((tag) => (
                                    <TagComponent key={tag} handleTagSelect={handleTagSelect} tag={tag} addTag />
                                ))}
                                {!!tagInput.length && !selectedTags.includes(tagInput) && (
                                    <TagComponent
                                        key={tagInput}
                                        handleTagSelect={handleTagSelect}
                                        tag={tagInput}
                                        addTag
                                    />
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        );
    }
);

export default TagsDropdown;
