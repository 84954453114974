import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import { Select } from "../primitives/Select";

const FONT_SIZE_OPTIONS = Array.from({ length: 68 }, (_, i) => {
    return {
        value: `${i + 5}pt`,
        title: `${i + 5}pt`,
    };
}); // 5pt to 72pt

const DEFAULT_FONT_SIZE = "12pt";

type Props = {
    editor: Editor;
};

export function ToolbarFontSize({ editor }: Props) {
    const onFontSizeChange = useCallback(
        (value: string) => {
            if (!editor) {
                return;
            }
            editor.chain().focus().setFontSize(value).run();
        },
        [editor]
    );

    return (
        <Select
            variant="subtle"
            value={getCurrentFontSize(editor)}
            initialValue={DEFAULT_FONT_SIZE}
            items={FONT_SIZE_OPTIONS}
            onChange={onFontSizeChange}
        />
    );
}

function getCurrentFontSize(editor: Editor) {
    return editor.getAttributes("textStyle").fontSize || DEFAULT_FONT_SIZE;
}
