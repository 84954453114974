import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

////////////////////// IMPORTING API FUNCTIONS //////////////////////////
import {
    createDirectory,
    updateDirectory,
    deleteDirectory,
    createFile,
    moveDirectory,
    updateFile,
    deleteFile,
    moveFile,
} from "api/api";

////////////////////// IMPORTING IMAGES AND ICONS ////////////////////////
import searchIcon from "Assets/search-normal.png";
import plusSign from "Assets/add-blue-circle.svg";
import addLink from "Assets/addlink.svg";
import folderOpenSm from "Assets/folder-open-white.svg";
import driveWhite from "Assets/drive-white.svg";
import { CloseIconBlack, Spinner } from "utils/icons";
import lod from "Assets/loader.svg";

//////////////////// IMPORINTG CUSTOM COMPONENTS ///////////////////////
import Document from "components/Drive/Document";
import Folder from "components/Drive/Folder";
import CustomModal from "components/CustomModal";
import UploadDriveDocModal from "components/Drive/UploadDriveDocModal";
import DriveDropDown from "components/Drive/DriveDropDown";
import ClickAwayListener from "helpers/ClickAwayListener";
import Avatar from "components/Avatar";

import { publishedDateOptions } from "components/Search/SearchPageSearchBar";
import { formatBytes, getPastDate, validateField } from "utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";

import { fetchFileStorage, updateAllFolders, updateAllDocs } from "store/reducers/driveReducerSlice";
import GetColorForTag from "components/Drive/TagColor";
import ExtractingDocumentToast from "components/LoadingToast";
import MoveModal from "../MoveModal";
import { useNotification } from "context/notificationContext";
import { useLocation } from "react-router-dom";

const DocumentsContent = () => {
    const dispatch = useDispatch();
    const { rootFiles: allDocs, rootSubdirectories: allFolders } = useSelector((state) => state.drive.fileStorage);

    const { setToast } = useNotification();

    const [addDoc, setAddDoc] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [folderName, setFolderName] = useState("");
    const [selectedFolderId, setSelectedFolderId] = useState(false);
    const [isfolderAddModalOpen, setIsFolderAddModalOpen] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [currentFolders, setCurrentFolders] = useState([]);
    const [currentDocs, setCurrentDocs] = useState([]);
    const [removedFolderId, setRemovedFodlerId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });

    const [draggingFolderId, setDraggingFolderId] = useState("");
    const [draggingDocId, setDraggingDocId] = useState("");
    const [selectedFolderHeading, setSelectedFolderHeading] = useState("");
    const [availableTags, setAvailableTags] = useState([]);
    const [folderDropData, setFolderDropData] = useState([]);
    const [moveModalOpen, setIsMoveModalOpen] = useState({
        item: null,
        open: false,
        type: undefined,
    });
    const [stateHistory, setStateHistory] = useState({
        type: null,
        data: [],
        itemInfo: {},
    });
    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
    const n = useLocation();

    const [selectedOptions, setSelectedOptions] = useState({
        tags: [],
        types: [],
        uploaded: "",
    });

    const drpOptions = [
        {
            name: "types",
            subName: "type",
            title: "Type",
            searchable: false,
            optionIcon: true,
            options: [
                { name: "Past Proposal" },
                { name: "Product Documentation" },
                { name: "Technical Whitepaper" },
                { name: "Resume" },
                { name: "Marketing Material" },
                { name: "Certification" },
                { name: "Case Study" },
            ],
            className: "right-0 min-w-[240px] min-h-full",
            txtClass: "max-w-max",
            multiSelect: true,
        },
        {
            name: "uploaded",
            title: "Uploaded",
            searchable: false,
            optionIcon: true,
            static: true,
            options: publishedDateOptions,
            className: "right-0 min-w-[160px]",
            txtClass: "",
            multiSelect: false,
        },
    ];

    useEffect(() => {
        // Check if allDocs and allFolders are empty before dispatching the action
        if (!allDocs.length && !allFolders.length) {
            dispatch(fetchFileStorage());
        }
    }, [dispatch, allDocs.length, allFolders.length]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const { isLoadingWorkSpaces, workspaceMembers, isLoadingMembers } = useSelector((store) => store.auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [usersIds, setUsersIds] = useState([]);
    // const [selectedUser, setSelectedUser] = useState("");

    // select tagged members
    const selectMembers = (user) => {
        let updatedList = [...usersIds];
        if (updatedList?.find((v) => v === user?.id)) {
            updatedList = updatedList?.filter((s) => s !== user?.id);
        } else {
            updatedList.push(user?.id);
        }
        setUsersIds(updatedList);
    };

    const sortedMembers = [...workspaceMembers].sort((a, b) => {
        const aSelected = usersIds?.includes(a.id);
        const bSelected = usersIds?.includes(b.id);

        if (aSelected && !bSelected) {
            return -1; // a is selected but b is not, move a to the top
        } else if (!aSelected && bSelected) {
            return 1; // b is selected but a is not, move b to the top
        } else {
            // both a and b are selected or both are not selected, sort alphabetically
            return a.username.localeCompare(b.username);
        }
    });

    const usersDropDown = [
        {
            name: "users",
            subName: "users",
            title: "People",
            optionIcon: true,
            className: "right-0 pb-1.5",
            options: [
                <ul className="py-1 px-2 min-w-[230px] max-w-[320px]">
                    {isLoadingMembers || isLoadingWorkSpaces ? (
                        <div className="rounded-md p-3 text-center">
                            <Spinner width={15} height={15} classes="!text-black" />
                        </div>
                    ) : sortedMembers.filter((v) => v?.username.toLowerCase().includes(searchQuery.toLowerCase()))
                          .length > 0 ? (
                        sortedMembers
                            .filter((v) => v?.username.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map((v, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={(e) => {
                                            selectMembers(v);
                                            // setSelectedUser(v.username);
                                        }}
                                        className={`flex items-center px-2 py-[0.32rem] text-sm font-medium my-1 cursor-pointer select-none hover:bg-[#EFEFEF]`}
                                        role="button"
                                    >
                                        <input type="checkbox" checked={usersIds.includes(v.id)} className="mr-2" />
                                        <Avatar
                                            id={v?.username}
                                            src=""
                                            alt={v?.username}
                                            width={26}
                                            height={26}
                                            className="mr-2"
                                        />
                                        <span className="line-clamp-1 ">{v?.username}</span>
                                    </li>
                                );
                            })
                    ) : (
                        <li className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">No members found.</li>
                    )}
                </ul>,
            ],
        },
    ];

    /////////// searching and filtering functionality //////////////
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = (searchText) => {
        if (searchText) {
            const filtered = [...allDocs, ...allFolders].filter((item) =>
                item.name.toLowerCase().includes(searchText.toLowerCase())
            );
            setSearchResults(filtered);
        } else {
            setSearchResults([]);
        }
    };

    // function to deal filter logic
    useEffect(() => {
        let filteredResults = [...allDocs, ...allFolders];

        // handle search filter
        if (searchResults.length > 0) {
            filteredResults = filteredResults.filter((item) => searchResults.some((result) => result.id === item.id));
        }

        // Handle tags filter (OR logic within tags)
        if (selectedOptions.tags.length > 0) {
            filteredResults = filteredResults.filter((item) =>
                selectedOptions.tags.some((tag) => item?.tags?.includes(tag))
            );
        }

        // Handle classification filter (OR logic within types)
        if (selectedOptions.types.length > 0) {
            const typeFilters = selectedOptions.types.map((option) => option.toLowerCase().split(" ").join("_"));
            filteredResults = filteredResults.filter((item) => typeFilters.includes(item.classification));
        }

        // Handle period filter (AND logic for uploaded)
        if (selectedOptions.uploaded) {
            const selectedDate = getPastDate(selectedOptions.uploaded);
            filteredResults = filteredResults.filter(
                (item) => new Date(item.created_at) >= new Date(selectedDate) && new Date(item.created_at) < new Date()
            );
        }

        // Handle users filter (AND logic for user ids)
        if (usersIds.length > 0) {
            filteredResults = filteredResults.filter((item) => usersIds.includes(item.created_by));
        }

        if (
            !searchResults.length &&
            !selectedOptions.tags.length &&
            !selectedOptions.types.length &&
            !selectedOptions.uploaded &&
            !usersIds.length
        ) {
            filteredResults = [];
        }

        if (filteredResults.length > 0) {
            let filteredDocs = [];
            filteredResults.forEach((result) => {
                const d = allDocs.find((doc) => doc.id === result.id);
                if (d) {
                    if (!filteredDocs.some((result) => result.id === d.id)) {
                        filteredDocs.push(d);
                    }
                }
            });

            let filteredFolders = [];
            filteredResults.forEach((result) => {
                const f = allFolders.find((folder) => folder.id === result.id);
                if (f) {
                    if (!filteredFolders.some((result) => result.id === f.id)) {
                        filteredFolders.push(f);
                    }
                }
            });

            setCurrentDocs(filteredDocs);
            setCurrentFolders(filteredFolders);
        } else if (
            filteredResults.length === 0 &&
            (searchValue ||
                selectedOptions.tags.length ||
                selectedOptions.types.length ||
                selectedOptions.uploaded ||
                usersIds.length)
        ) {
            setCurrentDocs([]);
            setCurrentFolders([]);
        } else {
            setCurrentDocs(allDocs);
            setCurrentFolders(allFolders);
        }

        ////////////////// set available tags ///////////////////
        const allTags = allDocs.flatMap((doc) => doc.tags);
        const uniqueTags = [...new Set(allTags)];
        setAvailableTags([...uniqueTags]);
    }, [selectedOptions, searchResults, allFolders, allDocs, usersIds]);

    /////////////////////////// APIs Integration //////////////////////////
    //////////////// function to create new folder ////////////////////////
    async function createNewDirectory() {
        if (validateField(folderName)) {
            setIsFolderAddModalOpen(false);

            try {
                const { data: newFolder } = await createDirectory({ name: folderName, parent_directory_id: null });

                dispatch(updateAllFolders([...allFolders, newFolder]));
                setFolderName("");
            } catch (err) {
                handleApiError(
                    err,
                    "We were unable to create a new folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            }
        }
    }

    ///////////////////// update directory function ////////////////////
    async function updateSelectedDirectory(folderId) {
        if (validateField(folderName)) {
            setIsFolderAddModalOpen(false);
            setIsDataLoading(true);

            try {
                await updateDirectory(folderId, { name: folderName });

                const updatedFolders = allFolders.map((folder) =>
                    folder.id === folderId ? { ...folder, name: folderName } : folder
                );

                dispatch(updateAllFolders([...updatedFolders]));

                handleApiSuccess("Folder updated");
                setFolderName("");
            } catch (err) {
                handleApiError(
                    err,
                    "We were unable to update folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            }
        }
    }

    ///////////////////// delete directory function ////////////////////
    async function deleteSelectedDirectory(folderId) {
        // setIsDataLoading(true);

        try {
            await deleteDirectory(folderId);

            const updatedFolders = allFolders.filter((folder) => folder.id !== folderId);

            // setIsDataLoading(false);
            dispatch(updateAllFolders(updatedFolders));
            setRemovedFodlerId(folderId);
        } catch (err) {
            handleApiError(
                err,
                "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    ///////////////////// move directory function ////////////////////
    async function moveSelectedDirectory(dropTargetFolderId, selectedFolderId, preventHistory) {
        setIsDataLoading(true);

        if ((draggingFolderId && dropTargetFolderId && draggingFolderId !== dropTargetFolderId) || selectedFolderId) {
            try {
                let folderId = selectedFolderId || draggingFolderId;
                await moveDirectory(folderId, { parent_directory_id: dropTargetFolderId });
                if (!preventHistory) {
                    setStateHistory({
                        type: "folder",
                        data: allFolders,
                        itemInfo: allFolders?.find((folder) => folder?.id === folderId),
                    });
                }
                const updatedFolders = allFolders.filter((folder) => folder?.id !== folderId);
                dispatch(updateAllFolders(updatedFolders));

                handleApiSuccess("Folder moved");
            } catch (err) {
                handleApiError(
                    err,
                    "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            }
        } else {
            setIsDataLoading(false);
        }
    }

    ///////////////////// create new file function ////////////////////
    async function createDocument(files, id = null) {
        if (!files.length) {
            setLoadingToast({
                loading: false,
                files: 1,
            });
            return;
        }

        setLoadingToast({
            loading: true,
            files: files?.length,
        });

        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const uploadPromises = files.map(async (file) => {
                let formData = new FormData();
                formData.append("file", file);
                formData.append(id === null ? "parent_directory" : "parent_directory_id", id);
                formData.append("tags[]", []);

                const response = await createFile(formData, "upload", headers);
                return response.data;
            });

            const receivedData = await Promise.all(uploadPromises);

            if (id === null) {
                dispatch(updateAllDocs([...allDocs, ...receivedData]));
            }
            // store data in case doc is dropped from computer to a folder which then calls api getSingleDrirctory() in folder component to update files length
            setFolderDropData(receivedData);

            handleApiSuccess("Files uploaded");
            setShowUploadModal(false);
            setLoadingToast({
                loading: false,
                files: 1,
            });
        } catch (err) {
            handleApiError(
                err,
                "We were unable to create document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
            setLoadingToast({
                loading: false,
                files: 1,
            });
        }
    }

    ///////////////////// function to update a document ////////////////////
    async function updateDocument(documentId, data) {
        setIsDataLoading(true);

        try {
            const { data: updatedDoc } = await updateFile(documentId, data);

            const updatedDocs = allDocs.map((doc) => (doc.id === documentId ? updatedDoc : doc));
            dispatch(updateAllDocs(updatedDocs));

            handleApiSuccess("File updated");
        } catch (err) {
            handleApiError(
                err,
                "We were unable to update document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    ///////////////////// function to delete document ////////////////////
    async function deleteDocument(documentId) {
        // setIsDataLoading(true);

        try {
            await deleteFile(documentId);

            const updatedDocs = allDocs.filter((doc) => doc.id !== documentId);
            dispatch(updateAllDocs(updatedDocs));
            // setIsDataLoading(false);
        } catch (err) {
            handleApiError(
                err,
                "We were unable to delete document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    ///////////////////// function to move document ////////////////////
    async function moveDocument(dropTargetFolderId, selectedDocId, preventHistory) {
        try {
            let docId = selectedDocId || draggingDocId;
            await moveFile(docId, { parent_directory_id: dropTargetFolderId });
            if (!preventHistory) {
                setStateHistory({
                    type: "doc",
                    data: allDocs,
                    itemInfo: allDocs?.find((doc) => doc?.id === docId),
                });
            }
            const updatedDocs = allDocs.filter((doc) => doc.id !== docId);
            dispatch(updateAllDocs(updatedDocs));

            // store data in case doc is dropped from computer to a folder which then calls api getSingleDirectory() in folder component to update files length
            setFolderDropData(updatedDocs);

            handleApiSuccess("File moved");
        } catch (err) {
            handleApiError(
                err,
                "We were unable to move the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    // Generic api success handling function
    function handleApiSuccess(message) {
        setToast.success({
            msg: message,
        });

        setIsLoading(false);
        setIsDataLoading(false);
    }

    // Generic api error handling function
    function handleApiError(error, errorMessage) {
        setToast.error({
            msg: errorMessage,
        });
        setIsLoading(false);
        setIsDataLoading(false);
    }

    ///////////////////////////DRAG AND DROP FROM COMPUTER FUNCTIONALITY ////////////////////////////
    const [isDragOverDrive, setIsDragOverDrive] = useState(false);

    ////////////////// function to check validity of file
    function isValidFile(file) {
        const maxFileSize = 200000000; // 200 MB in bytes

        const acceptedFormats = {
            "text/plain": [".txt"],
            "application/pdf": [".pdf"],
            "application/doc": [".doc", ".docx", ".dot"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "application/pptx": [".pptx"],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
        };

        const allowedExtensions = Object.values(acceptedFormats).flat();

        if (file.size > maxFileSize) {
            handleInvalidFileMsg(
                `Document exceeds the maximum size limit. We do not support document sizes larger than ${formatBytes(
                    maxFileSize
                )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`
            );
            return false;
        }

        const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        const fileType = file.type;

        if (!allowedExtensions.includes(fileExtension) || !acceptedFormats[fileType]?.includes(fileExtension)) {
            handleInvalidFileMsg("Document format is not supported.");
            return false;
        }

        return true;
    }

    ////////// function to handle file msg /////////////
    function handleInvalidFileMsg(errorMessage) {
        setToast.error({
            msg: errorMessage,
        });
    }

    ///// function to handle files droppped from computer into drive
    function onMainDrop(acceptedFiles) {
        if (showUploadModal) return;
        setSelectedFolderHeading("");
        setIsDragOverDrive(false);
        const validFiles = acceptedFiles.filter((file) => isValidFile(file));
        createDocument(validFiles);
    }

    ///// function to handle files droppped from computer into any folder
    function onFolderDrop(acceptedFiles, targetFolderId) {
        setSelectedFolderHeading("");
        setIsDragOverDrive(false);

        const validFiles = acceptedFiles.filter((file) => isValidFile(file));
        createDocument(validFiles, targetFolderId);
    }

    const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
        onDrop: onMainDrop,
        onDragOver: (e) => {
            e.preventDefault();
            if (showUploadModal) return;
            setIsDragOverDrive(true);
        },
        onDragLeave: (e) => {
            const relatedTarget = e.relatedTarget;
            if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
                setIsDragOverDrive(false);
            }
        },
        noClick: true,
    });

    // handle move modal closing event
    const handleCloseMoveModal = () => {
        setIsMoveModalOpen({ item: null, open: false, type: undefined });
    };

    // ////////// Move folder/document ////////////////////
    async function onMoveManually(selectedDestination) {
        let parent_id = selectedDestination === "drive" ? null : selectedDestination;
        try {
            if (moveModalOpen?.type === "folder") {
                await moveSelectedDirectory(parent_id, moveModalOpen?.item?.id);
            } else {
                await moveDocument(parent_id, moveModalOpen?.item?.id);
            }
            handleCloseMoveModal();
        } catch (err) {
            handleApiError(
                err,
                "We were unable to move due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    // ////////////// setting up ctrl + z //////////
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Control" || event.key === "Meta") {
                setCtrlKeyPressed(true);
            }
            if (event.key === "z" && ctrlKeyPressed) {
                undo();
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === "Control" || event.key === "Meta") {
                setCtrlKeyPressed(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [ctrlKeyPressed]);

    // /////// perform undo method for docs & folders ///////////////////
    const undo = async () => {
        if (!stateHistory?.type) return;
        if (stateHistory?.type === "folder") {
            await moveSelectedDirectory(
                stateHistory?.itemInfo?.parent_directory,
                stateHistory?.itemInfo?.id,
                "prevent"
            );
            dispatch(updateAllFolders(stateHistory?.data));
            setStateHistory({ type: null, data: [], itemInfo: {} });
        } else {
            await moveDocument(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
            dispatch(updateAllDocs(stateHistory?.data));
            setFolderDropData(stateHistory?.data);
            setStateHistory({ type: null, data: [], itemInfo: {} });
        }
    };

    return (
        <div className="relative flex-grow" {...getMainRootProps()}>
            <input multiple {...getMainInputProps()} />
            {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
            <div
                className={`${
                    (isDragOverDrive || selectedFolderHeading) &&
                    !showUploadModal &&
                    "border-[3px] border-dashed border-action"
                } absolute w-full h-full pointer-events-none z-50`}
            ></div>

            <div className="relative">
                {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
                <section className="p-6">
                    <div className="flex gap-3">
                        <div className="flex-1 relative">
                            <img
                                src={searchIcon}
                                alt="search icon"
                                loading="lazy"
                                className="absolute top-2.5 left-1.5 w-5"
                            />

                            <input
                                className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border-[1px]"
                                value={searchValue}
                                onChange={(e) => {
                                    handleSearch(e.target.value);
                                    setSearchValue(e.target.value);
                                }}
                            />
                        </div>

                        <div className="flex items-center gap-4 flex-wrap">
                            {drpOptions.map((optItem, index) => (
                                <DriveDropDown
                                    key={index}
                                    {...optItem}
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                />
                            ))}
                            {/* {usersDropDown?.map((optItem, i) => (
                                    <DropDown
                                        key={i}
                                        name={optItem?.name}
                                        title={optItem?.title}
                                        optionIcon={optItem?.optionIcon}
                                        className={optItem?.className || ""}
                                        customList={
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Search People"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    className="border border-action w-full block px-3 py-2 text-sm mb-1 rounded-md h-10 outline-none mt-[1px]"
                                                />

                                                {optItem?.options?.length > 0 ? (
                                                    optItem?.options?.map((v, n) => <div key={n}>{v}</div>)
                                                ) : (
                                                    <div className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">
                                                        No results found.
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        btnClass="bg-[#ffffff] min-w-[100px] -mt-1 rounded-md !text-[#9FA2AA]"
                                    />
                                ))} */}
                        </div>
                    </div>
                </section>

                {/* ----------------------------- RECENT SECTION ------------------------------- */}

                {isDataLoading ? (
                    <div className="h-screen bg-white rounded-b-lg w-full flex justify-center flex-col py-7 px-2">
                        <img className="mx-auto max-w-full mt-[200px] mb-16" src={lod} alt="Loading..." />
                        <div className="flex-grow"></div>
                    </div>
                ) : (
                    <>
                        {/* <section className="recent-section px-6">
                            <h4 className="font-semibold text-sm mb-8">Recent</h4>

                            {currentDocs.length ? (
                                <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[30px] gap-y-5 mb-14">
                                    {currentDocs
                                        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                                        .slice(0, 4)
                                        .map((doc) => (
                                            <Document
                                                key={doc.id}
                                                docData={doc}
                                                updateDocument={updateDocument}
                                                deleteDocument={deleteDocument}
                                                setDraggingFolderId={setDraggingFolderId}
                                                setDraggingDocId={setDraggingDocId}
                                                isDataLoading={isDataLoading}
                                                availableTags={availableTags}
                                            />
                                        ))}
                                </div>
                            ) : (
                                <div className="border-b border-[#DBE0E5] pb-28 pt-[76px] mb-14">
                                    <h5 className="text-base text-center font-semibold text-[#1D2630]">No Documents</h5>
                                    <p className="text-sm text-[#353535] text-center">
                                        Drop documents into Drive or add documents clicking on the “documents +” button.
                                    </p>
                                </div>
                            )}
                        </section> */}

                        {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
                        <section className="folders-section px-6 pt-1 mt-5">
                            <div className="flex justify-between items-center mb-9">
                                <h4 className="font-semibold text-lg">Folders</h4>
                                <div
                                    className="flex items-center gap-2  cursor-pointer "
                                    onClick={() => {
                                        setIsFolderAddModalOpen(true);
                                        setSelectedFolderId("");
                                    }}
                                >
                                    <span className="text-sm font-semibold">Folder</span>
                                    <button onClick={() => setFolderName("")}>
                                        <img src={plusSign} alt="icon" loading="lazy" className="cursor-pointer" />
                                    </button>
                                </div>
                            </div>

                            {currentFolders.length ? (
                                <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[30px] gap-y-5 mb-12">
                                    {currentFolders.map((folder) => (
                                        <Folder
                                            key={folder.id}
                                            {...folder}
                                            setIsFolderAddModalOpen={setIsFolderAddModalOpen}
                                            setSelectedFolderId={setSelectedFolderId}
                                            draggingFolderId={draggingFolderId}
                                            setDraggingFolderId={setDraggingFolderId}
                                            draggingDocId={draggingDocId}
                                            setDraggingDocId={setDraggingDocId}
                                            moveSelectedDirectory={moveSelectedDirectory}
                                            setFolderName={setFolderName}
                                            isLoading={isLoading}
                                            handleDelete={deleteSelectedDirectory}
                                            moveDocument={moveDocument}
                                            createDocument={createDocument}
                                            setSelectedFolderHeading={setSelectedFolderHeading}
                                            setIsDragOverDrive={setIsDragOverDrive}
                                            selectedFolderHeading={selectedFolderHeading}
                                            onFolderDrop={onFolderDrop}
                                            removedFolderId={removedFolderId}
                                            folderDropData={folderDropData}
                                            handleMoveModalOpen={() => {
                                                setIsMoveModalOpen({ item: folder, open: true, type: "folder" });
                                            }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="border-b border-[#DBE0E5] pb-20 pt-4 mb-14">
                                    <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                                    <p className="text-[#353535] text-center pt-[5px]">
                                        Create a folder by clicking on the “Folder +” button.
                                    </p>
                                </div>
                            )}

                            <CustomModal isOpen={isfolderAddModalOpen} onClose={() => setIsFolderAddModalOpen(false)}>
                                <div className="p-5 w-[444px] ">
                                    <div className="flex justify-between items-center mb-5">
                                        <h3 className="font-semibold text-xl">
                                            {selectedFolderId ? "Update Folder" : "New Folder"}
                                        </h3>
                                        <div className="w-[15.56px] h-[15.56px]">
                                            <span className="cursor-pointer">
                                                <CloseIconBlack
                                                    onClick={() => {
                                                        setIsFolderAddModalOpen(false);
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            selectedFolderId
                                                ? updateSelectedDirectory(selectedFolderId)
                                                : createNewDirectory();
                                        }}
                                    >
                                        <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
                                        <input
                                            type="text"
                                            className="w-full rounded-md py-2 px-3 h-10 border-[1px] border-[#CFD1D4] focus:ring-blue-500 focus:border-blue-500 outline-none mb-7"
                                            value={folderName}
                                            onChange={(e) => setFolderName(e.target.value)}
                                            autoFocus
                                            required
                                        />

                                        <div className="flex justify-end gap-4">
                                            <button
                                                type="button"
                                                className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                                                onClick={(e) => {
                                                    setIsFolderAddModalOpen(false);
                                                    setFolderName("");
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="border-0 bg-action rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                                            >
                                                <img src={addLink} alt="icon" loading="lazy" />
                                                {selectedFolderId ? "Update" : "Add"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </CustomModal>
                        </section>

                        {/* -------------------------- SECTION DOCUMENT -------------------------------- */}
                        <section className="document-section px-6 relative mb-9">
                            <div className="flex items-center justify-between mb-9">
                                <h4 className="font-semibold text-lg">Documents</h4>
                                <div className="flex items-center gap-2">
                                    <span className="text-sm font-semibold">Document</span>
                                    <span className="relative block">
                                        <ClickAwayListener
                                            onClickAway={() => setAddDoc(false)}
                                            className="rounded-r-lg"
                                        >
                                            <img
                                                onClick={() => setShowUploadModal(true)}
                                                src={plusSign}
                                                alt="icon"
                                                className="cursor-pointer"
                                            />

                                            {/* {addDoc && (
                                                <div className="absolute right-0 top-[100%] px-2 z-[2] min-w-max rounded-md bg-white shadow-md py-1 mt-1">
                                                    <button
                                                        onClick={() => setShowUploadModal(true)}
                                                        className="flex items-center gap-2 text-sm font-normal hover:bg-[#EFEFEF] w-full px-3 py-1"
                                                    >
                                                        <img
                                                            src={uploadIcon}
                                                            alt="icon"
                                                            loading="lazy"
                                                            className="w-3"
                                                        />
                                                        Upload Document
                                                    </button>

                                                    <Link to="/dashboard/create-document">
                                                        <button className="flex items-center gap-2 text-sm font-normal hover:bg-[#EFEFEF] w-full px-3 py-1">
                                                            <img
                                                                src={plusCircle}
                                                                alt="icon"
                                                                loading="lazy"
                                                                className="w-3"
                                                            />
                                                            Create Document
                                                        </button>
                                                    </Link>
                                                </div>
                                            )} */}
                                        </ClickAwayListener>
                                    </span>
                                </div>
                            </div>

                            {currentDocs.length ? (
                                <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[30px] gap-y-5">
                                    {currentDocs.map((doc, i) => (
                                        <Document
                                            key={i + doc?.id}
                                            docData={doc}
                                            updateDocument={updateDocument}
                                            deleteDocument={deleteDocument}
                                            setDraggingFolderId={setDraggingFolderId}
                                            setDraggingDocId={setDraggingDocId}
                                            isLoading={isLoading}
                                            availableTags={availableTags}
                                            handleMoveModalOpen={() => {
                                                setIsMoveModalOpen({ item: doc, open: true, type: "doc" });
                                            }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="mb-5">
                                    <UploadDriveDocModal
                                        isDrive={!currentDocs.length}
                                        createDocument={createDocument}
                                    />
                                </div>
                            )}

                            {/* ------------ UPLOAD files MODAL --------------- */}
                            <UploadDriveDocModal
                                showUploadModal={showUploadModal}
                                setShowUploadModal={setShowUploadModal}
                                createDocument={createDocument}
                            />
                        </section>
                    </>
                )}
            </div>

            {/* ------------- Drag and Drop Toast -------------------- */}
            {(isDragOverDrive || selectedFolderHeading) && !showUploadModal && (
                <div className="absolute bottom-24 w-full pointer-events-none z-50">
                    <div className="absolute left-1/2 transform -translate-x-1/2 bg-action py-3.5 px-16 rounded-full text-center">
                        <span className="text-lg opacity-90 text-[#ffffff] block mb-1.5">
                            Drop files to upload them to
                        </span>

                        {selectedFolderHeading && (
                            <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                                <img
                                    src={folderOpenSm}
                                    alt="icon"
                                    loading="lazy"
                                    className="w-[18px] h-[18px] bg-[#fffffff]"
                                />{" "}
                                {selectedFolderHeading}
                            </span>
                        )}

                        {isDragOverDrive && (
                            <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                                <img
                                    src={driveWhite}
                                    alt="icon"
                                    loading="lazy"
                                    className="w-[18px] h-[18px] bg-[#fffffff]"
                                />{" "}
                                Content Library
                            </span>
                        )}
                    </div>
                </div>
            )}

            {/* ---------- Loading toast new ---------- */}
            {loadingToast?.loading && (
                <ExtractingDocumentToast
                    loadingText={`Uploading ${loadingToast?.files > 1 ? "Documents" : "Document"}`}
                    handleClose={() => setLoadingToast({ loading: false, files: 1 })}
                />
            )}

            {/* ------------ Move Modal --------------- */}
            <MoveModal
                selectedItem={moveModalOpen}
                handleCloseMoveModal={handleCloseMoveModal}
                onMove={onMoveManually}
                currentFolders={currentFolders}
                isMain={true}
            />
        </div>
    );
};

export default DocumentsContent;
