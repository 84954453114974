import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import { Select } from "../primitives/Select";
import styles from "./Toolbar.module.css";

const toolbarHeadings = [
    { value: "p", title: "Paragraph" },
    { value: "h1", title: "Heading 1" },
    { value: "h2", title: "Heading 2" },
    { value: "h3", title: "Heading 3" },
    { value: "h4", title: "Heading 4" },
    { value: "h5", title: "Heading 5" },
];

type Props = {
    editor: Editor;
};

export function ToolbarHeadings({ editor }: Props) {
    const onHeadingChange = useCallback(
        (value: string) => {
            if (!editor) {
                return;
            }

            switch (value) {
                case "p":
                    editor.chain().focus().setParagraph().unsetFontSize().run();
                    break;

                case "h1":
                    editor.chain().focus().setHeading({ level: 1 }).unsetFontSize().run();
                    break;

                case "h2":
                    editor.chain().focus().setHeading({ level: 2 }).unsetFontSize().run();
                    break;

                case "h3":
                    editor.chain().focus().setHeading({ level: 3 }).unsetFontSize().run();
                    break;
                case "h4":
                    editor.chain().focus().setHeading({ level: 4 }).unsetFontSize().run();
                    break;
                case "h5":
                    editor.chain().focus().setHeading({ level: 5 }).unsetFontSize().run();
                    break;
            }
        },
        [editor]
    );

    return (
        <Select
            variant="subtle"
            className={styles.headingsSelect}
            value={getCurrentHeading(editor)}
            initialValue={toolbarHeadings[0].value}
            items={toolbarHeadings}
            onChange={onHeadingChange}
        />
    );
}

function getCurrentHeading(editor: Editor) {
    if (editor.isActive("heading", { level: 1 })) {
        return "h1";
    }

    if (editor.isActive("heading", { level: 2 })) {
        return "h2";
    }

    if (editor.isActive("heading", { level: 3 })) {
        return "h3";
    }

    if (editor.isActive("heading", { level: 4 })) {
        return "h4";
    }

    if (editor.isActive("heading", { level: 5 })) {
        return "h5";
    }

    return "p";
}
