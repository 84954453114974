import { ReactComponent as Note } from "Assets/note-2.svg";
import { FC } from "react";

export enum Source {
    ProjectAttachments = 1,
    ContentDrive,
    Search,
    Internet,
}

export const DELIMITER = "tk^-$-^tk";

export const SOURCE_TO_META: Record<Source, { copy: string; searchPlaceholder?: string; icon: FC; tooltip?: string }> =
    {
        [Source.Internet]: {
            copy: "Internet",
            icon: Note,
            tooltip:
                "Search the entire internet, a full website, or a specific link. Please indicate how to use the link, when provided, such as whether to use the full website or only the specific link.",
        },
        [Source.ProjectAttachments]: {
            searchPlaceholder: "Search relevant documents...",
            copy: "Relevant Documents",
            icon: Note,
        },
        [Source.ContentDrive]: {
            searchPlaceholder: "Search content library documents...",
            copy: "Content Library",
            icon: Note,
        },
        [Source.Search]: {
            copy: "Search",
            icon: Note,
        },
    };
