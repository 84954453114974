import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInternalContractDetails, getProgress } from "api/api";
import { ProjectProgress } from "types/InternalContract";

export const getProgressMetrics = createAsyncThunk<ProjectProgress, string>(
    "project/getProgressMetrics",
    async (internalContractId) => {
        return getProgress(internalContractId).then((res) => {
            return res.data;
        });
    }
);

export const getProject = createAsyncThunk<ProjectProgress, string>(
    "project/getProject",
    async (internalContractId) => {
        return getInternalContractDetails(internalContractId).then((res) => {
            return res.data;
        });
    }
);

type State = {
    activeProject?: Record<any, any>;
    progress?: ProjectProgress;
    isLoadingProject: boolean;
    isLoadingProgress: boolean;
};

const initialState: State = {
    activeProject: undefined,
    progress: undefined,
    isLoadingProject: false,
    isLoadingProgress: false,
};

const projectReducer = createSlice({
    name: "project",
    initialState,
    reducers: {
        clearProject: () => {
            return initialState;
        },
        setActiveProject: (state: State, action: PayloadAction<State["activeProject"]>) => {
            state.activeProject = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProgressMetrics.pending, (state) => {
                state.isLoadingProgress = true;
            })
            .addCase(getProgressMetrics.fulfilled, (state, action) => {
                state.progress = action.payload || [];
                state.isLoadingProgress = false;
            })
            .addCase(getProgressMetrics.rejected, (state) => {
                state.isLoadingProgress = false;
            })
            .addCase(getProject.pending, (state) => {
                state.isLoadingProject = true;
            })
            .addCase(getProject.fulfilled, (state, action) => {
                state.activeProject = action.payload;
                state.isLoadingProject = false;
            })
            .addCase(getProject.rejected, (state) => {
                state.isLoadingProject = false;
            });
    },
});

export const { setActiveProject, clearProject } = projectReducer.actions;

export default projectReducer.reducer;
