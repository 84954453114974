import { useSearchParams, NavLink } from "react-router-dom";
// tabs component
import Account from "./Account";
import WorkSpace from "./WorkSpace";
//
import documentGray from "../../Assets/documentGray.svg";
import document from "../../Assets/document-text.svg";
import directInboxBlue from "../../Assets/direct-ib.svg";
import directInbox from "../../Assets/directic-active.svg";

// --------------------------- Settings page main Wrapper, All 2 tabs --------------------------
const Settings = () => {
    const [searchParams] = useSearchParams();
    const tabName = searchParams.get("tab")?.toLocaleLowerCase() || "workspace";

    const tabs = [
        {
            activeImg: document,
            name: "Workspace",
            slug: "workspace",
            img: documentGray,
            component: <WorkSpace />,
        },
        {
            activeImg: directInboxBlue,
            name: "Account",
            slug: "account",
            query: "",
            img: directInbox,
            component: <Account />,
        },
    ];
    return (
        <div className="inline overflow-auto p-5">
            {/* Tabs */}
            <div className="flex border border-[#ebeced] mt-0 mb-3 rounded-lg  items-center gap-8 px-3  shadow-sm bg-white pl-6">
                {tabs.map((tab, i) => (
                    <NavLink
                        className={`${
                            tabName === tab.slug ? "border-action text-action" : "border-transparent"
                        } "items-center flex items-center gap-2 text-gray-text cursor-pointer py-4 border-b-2`}
                        key={i}
                        to={`/dashboard/settings?tab=${tab.slug}`}
                    >
                        {tabName === tab.slug ? <img src={tab.activeImg} alt="" /> : <img src={tab.img} alt="" />}
                        <span className=" items-center flex cursor-pointer">{tab.name}</span>
                    </NavLink>
                ))}
            </div>
            {/* Contract Details Content */}
            {tabs?.find((v) => v?.slug === tabName)?.component}
        </div>
    );
};

export default Settings;
