import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBoltSearch } from "api/api";

export const getAllBolts = createAsyncThunk("boltSlice/getAllBolts", async () => {
    // return getBoltSearch("&limit=25").then((res) => {
    return getBoltSearch("").then((res) => {
        let data = res.data || { bolts: [] };
        return data.bolts || [];
    });
});

const initialState = {
    bolts: [],
    isLoadingBolts: false,
};

export const boltReducerSlice = createSlice({
    name: "boltSlice",
    initialState,
    reducers: {
        setBolts: (state, action) => {
            state.bolts = action.payload.data;
            state.isLoadingBolts = action.payload.loading;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllBolts.pending, (state) => {
                state.isLoadingBolts = true;
            })
            .addCase(getAllBolts.fulfilled, (state, action) => {
                state.bolts = action.payload || [];
                state.isLoadingBolts = false;
            })
            .addCase(getAllBolts.rejected, (state) => {
                state.isLoadingBolts = false;
            });
    },
});

export const { setBolts } = boltReducerSlice.actions;

export default boltReducerSlice.reducer;
