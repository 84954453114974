// TODO: use new color palette
const newColorPalette = [
    "#4CB592",
    "#4680FF",
    "#8451C5",
    "#FF6464",
    "#4680FF",
    "#F6A121",
    "#FFE146",
    "#77CBFF",
    "#FF73E9",
    "#CD8BF4",
    "#FF7940",
    "#FF6392",
];

const colorPalette = ["#4680FF"];

// Function to generate a hash for a string
const simpleHash = (str) => {
    let hash = 5381; // a prime number as a seed
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 33) ^ str.charCodeAt(i);
    }
    return hash >>> 0; // ensures the result is an unsigned 32-bit integer
};

// Function to get a color index based on the hash
const GetColorForTag = (tag) => {
    const hash = simpleHash(tag);
    return colorPalette[Math.abs(hash) % colorPalette.length];
};

export default GetColorForTag;
