/** @jsxImportSource @emotion/react */

import { ComplianceMatrixRow, Section, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { useEffect, useMemo, useRef, useState } from "react";
import { LuDoorOpen } from "react-icons/lu";
import { DisplayOption, resetPreviewProposalState } from "store/reducers/draft/previewProposalReducer";
import { togglePreviewProposalModal } from "store/reducers/modalsSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { usePreviewProposalConfig } from "./hooks";
import parse, { HTMLReactParserOptions, Element, domToReact, DOMNode } from "html-react-parser";
import InlineSourceModal from "./InlineSourceModal";
import { Skeleton } from "components/molecules/skeleton";

const TOP_SCROLL_BUFFER = 221;

type Props = {
    volume?: Volume;
    sortedSections: FormattedSection[];
    volumeRequirements: ComplianceMatrixRow[];
};

const PreviewDocument = ({ volume, sortedSections, volumeRequirements }: Props) => {
    const [sourceModalProperties, setSourceModalProperties] = useState<
        { id: string; sourceContent: string } | undefined
    >();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const { previewProposalConfig } = usePreviewProposalConfig();
    const { activeSectionId, headingsInProgress } = useAppSelector((store) => store.previewProposalState);

    const sortedRequirements = useMemo(
        () =>
            [...volumeRequirements].sort(
                (a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0)
            ),
        [volumeRequirements]
    );

    const requirementsBySection = useMemo(
        () =>
            sortedSections.reduce<{ section: Section; requirements: ComplianceMatrixRow[]; isSubsection?: boolean }[]>(
                (acc, sec) => {
                    const { subsections, ...section } = sec;
                    const sectionReqs = sortedRequirements.filter(
                        (req) => req.proposal_reference.section_id === sec.id
                    );
                    const subsectionObjs =
                        subsections?.map((subsection) => {
                            const subsectionReqs = sortedRequirements.filter(
                                (req) => req.proposal_reference.section_id === subsection.id
                            );

                            return {
                                section: subsection,
                                isSubsection: true,
                                requirements: subsectionReqs,
                            };
                        }) || [];

                    const sectionObj = {
                        section: section,
                        requirements: sectionReqs,
                    };
                    return [...acc, sectionObj, ...subsectionObjs];
                },
                []
            ),
        [sortedRequirements, sortedSections]
    );

    useEffect(() => {
        const requirementBlock = document.getElementById(`preview-section-block-${activeSectionId}`);
        if (!requirementBlock?.offsetTop) return;
        scrollRef.current?.scrollTo({ top: requirementBlock.offsetTop - TOP_SCROLL_BUFFER, behavior: "smooth" });
    }, [activeSectionId]);

    return (
        <>
            <div ref={scrollRef} className="bg-midnight-900 h-full px-14 pb-10 flex flex-col gap-8 overflow-y-auto">
                <div className="flex flex-col pt-10 gap-8 sticky top-0 z-[1] bg-midnight-900">
                    <div className="relative flex justify-center items-center">
                        <div className="text-white text-lg font-semibold text-center px-[130px]">{volume?.title}</div>
                        <button
                            className="absolute flex items-center gap-2 right-0 -top-0.5 bg-midnight-300 rounded text-sm font-medium px-2 py-1.5 duration-150 hover:bg-slate-400"
                            onClick={() => {
                                dispatch(togglePreviewProposalModal({ open: false }));
                                dispatch(resetPreviewProposalState());
                            }}
                        >
                            <LuDoorOpen className="text-base" />
                            Exit Preview
                        </button>
                    </div>
                    <hr className="border-zinc-700" />
                </div>
                <div className="rounded-[10px] bg-white flex-1 p-8 flex flex-col gap-8">
                    {requirementsBySection.map((secObj) => {
                        const format = previewProposalConfig?.sectionFormats.find(
                            (sectionFormat) => sectionFormat.id === secObj.section.id
                        );

                        return (
                            <div
                                key={secObj.section.id}
                                id={`preview-section-block-${secObj.section.id}`}
                                className="flex flex-col gap-5 whitespace-pre-wrap"
                            >
                                <div className="font-semibold text-lg" css={[secObj.isSubsection && tw`text-base`]}>
                                    {secObj.section.title}
                                </div>
                                {!!secObj.requirements.length && (
                                    <div className="flex flex-col gap-2 text-sm">
                                        {secObj.requirements.map((req) => {
                                            const sources = req.response_sources;
                                            const includeSources = previewProposalConfig?.includeSourceDocuments;
                                            let block = req.written_content;
                                            sources?.forEach(({ used_file_contents, ...source }) => {
                                                used_file_contents.forEach(
                                                    ({ requirement_source_citations, content }) => {
                                                        const escapedSource = requirement_source_citations.replace(
                                                            /([.?*+^$[\]\\(){}|-])/g,
                                                            "\\$1"
                                                        );

                                                        if (!includeSources) {
                                                            block = block.replace(
                                                                new RegExp("\\s?" + escapedSource, "g"),
                                                                ""
                                                            );
                                                        } else {
                                                            block = block.replaceAll(
                                                                `${requirement_source_citations}`,
                                                                `<button sourcecontent='${content}' sourceid='${source.file_id}' id='sourceOpener'><span>$&</span></button>`
                                                            );
                                                        }
                                                    }
                                                );
                                            });

                                            const options: HTMLReactParserOptions = {
                                                replace(domNode) {
                                                    const node = domNode as Element;
                                                    if (node?.attribs?.id === "sourceOpener") {
                                                        return (
                                                            <button
                                                                className="text-action font-medium cursor-pointer duration-100 hover:brightness-150"
                                                                onClick={() => {
                                                                    setSourceModalProperties({
                                                                        id: node.attribs.sourceid,
                                                                        sourceContent: node.attribs.sourcecontent,
                                                                    });
                                                                }}
                                                            >
                                                                {domToReact(node.children as DOMNode[])}
                                                            </button>
                                                        );
                                                    }
                                                },
                                            };

                                            return (
                                                <div key={req.requirement.id} className="flex flex-col gap-2">
                                                    {format?.displayOption === DisplayOption.Heading &&
                                                    headingsInProgress.includes(req.requirement.id) ? (
                                                        <Skeleton
                                                            borderRadius={2}
                                                            height={18}
                                                            count={2}
                                                            containerClassName="flex flex-col gap-0.5"
                                                        />
                                                    ) : (
                                                        format?.displayOption !== DisplayOption.DontInclude && (
                                                            <div
                                                                css={[
                                                                    format?.headingStyle.bold && tw`font-semibold`,
                                                                    format?.headingStyle.italic && tw`italic`,
                                                                    format?.headingStyle.underline && tw`underline`,
                                                                ]}
                                                            >
                                                                {format?.displayOption ===
                                                                    DisplayOption.FullRequirement &&
                                                                    (req.requirement.content ||
                                                                        req.requirement.summarized_content)}
                                                                {format?.displayOption === DisplayOption.Heading &&
                                                                    (req.requirement.generated_heading ||
                                                                        req.requirement.content ||
                                                                        req.requirement.summarized_content)}
                                                            </div>
                                                        )
                                                    )}
                                                    {!!req.written_content?.trim() && (
                                                        <div>{parse(block, options)}</div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            {!!sourceModalProperties && (
                <InlineSourceModal
                    open={!!sourceModalProperties}
                    onClose={() => setSourceModalProperties(undefined)}
                    {...sourceModalProperties}
                />
            )}
        </>
    );
};

export default PreviewDocument;
