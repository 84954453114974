/** @jsxImportSource @emotion/react */

const TypingEffect = ({ ...props }) => {
    return (
        <div className="typing-container flex items-center px-2 pb-2" {...props}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
};

export default TypingEffect;
