/** @jsxImportSource @emotion/react */

import show from "../../Assets/show.svg";
import hide from "../../Assets/hide.svg";
import regenerate from "../../Assets/regenerate.svg";
import edit from "../../Assets/edit-2.png";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import tw from "twin.macro";
import classNames from "classnames";

// ----------------------- Custom Accordion component -------------------
const Accordion = ({
    selfMade,
    initialState,
    title,
    description,
    isShow,
    setIsVisible,
    icon,
    notExpandable,
    onTopRightIconClick,
    topRightIconStyle,
    wrapWhitespace,
    isEditable,
    paraStyle,
    updateDescFunc,
    required,
}) => {
    const editorRef = useRef();

    const [open, setOpen] = useState(initialState || false);
    const [desc, setDesc] = useState("");
    const [descEditable, setDescEditable] = useState(false);

    useEffect(() => {
        setDesc(description);
    }, [description]);

    const updateDesc = async (e) => {
        e.preventDefault();

        setDescEditable(false);
        setDesc((prev) => prev.trim());

        if (required && !desc?.length) {
            setDesc(description);
            return;
        }

        await updateDescFunc(desc.trim());
    };

    useLayoutEffect(() => {
        if (descEditable) {
            let textArea = editorRef.current;
            if (textArea) {
                textArea.style.height = "0px";
                textArea.style.height = textArea.scrollHeight - 0.6 + "px";
            }
        }
    }, [descEditable, desc]);

    useLayoutEffect(() => {
        if (descEditable) {
            let textArea = editorRef.current;
            if (textArea) {
                textArea.focus({
                    preventScroll: true,
                });
                textArea.selectionStart = textArea.value.length;
            }
        }
    }, [descEditable]);

    return (
        <div
            className="p-2 rounded-lg border border-[#D8DDE2] shadow-sm mb-4 bg-white"
            css={[required && !desc?.length && tw`border-red-500`]}
        >
            <div
                onClick={() => (selfMade ? setOpen(!open) : setIsVisible ? setIsVisible() : null)}
                role="button"
                className="flex items-center p-2 justify-between cursor-pointer select-none"
            >
                <div className="flex gap-1">
                    {icon && <img src={icon} alt="" width={17} height={24} />}
                    <div className={classNames(["text-base font-medium", required && "required-label"])}>{title}</div>
                </div>
                {notExpandable ? (
                    <span>
                        <img
                            src={regenerate}
                            alt=""
                            onClick={onTopRightIconClick}
                            className="regenerateIconStyle"
                            style={{ ...topRightIconStyle }}
                        />
                    </span>
                ) : (
                    <span className="">
                        <img className={selfMade && open ? "" : isShow ? "" : "hidden"} src={hide} alt="icon" />
                        <img className={selfMade && open ? "hidden" : isShow ? "hidden" : ""} src={show} alt="icon" />
                    </span>
                )}
            </div>
            {notExpandable ? (
                <p
                    className={`text-[#5B6B79] ${
                        wrapWhitespace ? "whitespace-pre-wrap" : ""
                    } px-2 text-sm transition-all duration-300 ease-linear outline-0 min-h-10 py-3 opacity-1`}
                >
                    {description}
                </p>
            ) : descEditable ? (
                <textarea
                    className="overflow-hidden resize-none pt-3 pb-1.5 w-full outline-none border-none text-sm px-2"
                    onChange={(e) => {
                        setDesc(e.target.value);
                    }}
                    onBlur={updateDesc}
                    value={desc}
                    ref={editorRef}
                >
                    {desc}
                </textarea>
            ) : (
                <p
                    className={`text-[#5B6B79] ${
                        wrapWhitespace ? "whitespace-pre-wrap" : ""
                    } px-2 text-sm transition-all duration-300 ease-linear outline-0 group 
            ${
                selfMade && open
                    ? "min-h-10 py-3  opacity-1"
                    : isShow
                    ? "min-h-10 py-3  opacity-1"
                    : "h-0 overflow-hidden opacity-0"
            }`}
                    style={{ ...paraStyle }}
                >
                    {desc}
                    {isEditable && (
                        <img
                            src={edit}
                            alt=""
                            className="opacity-0 group-hover:opacity-100 inline ml-2 cursor-pointer w-[14px]"
                            onClick={() => setDescEditable(true)}
                        />
                    )}
                </p>
            )}
        </div>
    );
};

export default Accordion;
