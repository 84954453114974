import { BubbleMenu, Editor } from "@tiptap/react";
import { useSelector } from "react-redux";
import { ToolbarAskAiButton } from "./AskAiToolbar/ToolbarAskAi";
import styles from "./TextEditor.module.css";
import { ToolbarInline } from "./ToolbarInline";

type Props = {
    editor: Editor;
};

export function SelectionMenu({ editor }: Props) {
    const { toggleAskAiInputWthDrpDown } = useSelector((state: any) => state.yjsEditor);

    return (
        <BubbleMenu editor={editor} tippyOptions={{ zIndex: 99, followCursor: true, placement: "top-end" }}>
            {shouldShowBubbleMenu(editor) ? (
                <div
                    className={`${styles.bubbleMenuWrapper} w-max`}
                    style={{ visibility: toggleAskAiInputWthDrpDown ? "hidden" : "visible" }}
                >
                    <ToolbarAskAiButton editor={editor} />
                    <div className="border-l border-gray-300 h-[20px] m-auto"></div>
                    <ToolbarInline editor={editor} />
                </div>
            ) : null}
        </BubbleMenu>
    );
}

export function shouldShowBubbleMenu(editor: Editor) {
    const canBold = editor.can().chain().focus().toggleBold().run();
    const canItalic = editor.can().chain().focus().toggleItalic().run();
    const canStrike = editor.can().chain().focus().toggleStrike().run();
    // const canCode = editor.can().chain().focus().toggleCode().run();
    return canBold || canItalic || canStrike;
}
