import { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { Link, To } from "react-router-dom";
import arrowLeft from "Assets/arrow-left.svg";
import { Skeleton } from "../skeleton";

interface Props extends HTMLAttributes<HTMLDivElement> {
    header: ReactNode;
    headerActions?: ReactNode;
    actions: ReactNode;
    back: To;
    isLoading: boolean;
}

const ProjectHeader = ({ headerActions, actions, back, header, isLoading, ...props }: PropsWithChildren<Props>) => {
    return (
        <div
            className="flex border border-gray-300 mx-3 mt-3 mb-3 rounded-lg items-center gap-7 px-3 min-h-[46px] shadow-sm bg-white pl-6 justify-between"
            {...props}
        >
            <div className="flex flex-grow gap-3 items-center overflow-hidden">
                <div className="flex items-center gap-0.5">
                    {headerActions}
                    <Link to={back} className="block min-w-[24px]">
                        <img src={arrowLeft} alt="" />
                    </Link>
                </div>

                {isLoading ? (
                    <Skeleton width={600} height={40} />
                ) : (
                    <h2 className="font-semibold text-[15px] pr-2 text-[#1D2630] truncate max-w-[10000px] w-full">
                        {header}
                    </h2>
                )}
            </div>
            {!isLoading && actions}
        </div>
    );
};

export default ProjectHeader;
