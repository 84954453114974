/** @jsxImportSource @emotion/react */

import { getFile } from "api/api";
import { getFileIcon } from "utils/getFileIcon";
import moment from "moment";
import tw from "twin.macro";
import { HiOutlineDownload } from "react-icons/hi";
import { Skeleton } from "components/molecules/skeleton";
import { useEffect, useState } from "react";
import { WorkspaceFile } from "types/FileStorage";
import { Cross2Icon } from "@radix-ui/react-icons";

type SourceModalInitialProps = {
    open: boolean;
    id?: string;
    name?: string;
    sourceContent?: string;
    extension?: string;
    onClose: () => void;
};

const InlineSourceModal = ({ id, name, extension, sourceContent, open, onClose }: SourceModalInitialProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [foundFile, setFoundFile] = useState<WorkspaceFile>();

    useEffect(() => {
        if (!id || !open) return;
        setIsLoading(true);
        getFile(id)
            .then((res) => {
                const file: WorkspaceFile = res?.data;
                setFoundFile(file);
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));

        return () => {
            setFoundFile(undefined);
        };
    }, [id, open]);

    return (
        <>
            <div
                onClick={() => onClose()}
                className="bottom-0 right-0 top-0 left-0 bg-black opacity-60 fixed w-screen h-screen"
            />
            <div className="flex flex-col z-[9999] fixed top-[50%] left-[50%] max-h-[70vh] min-w-[650px] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white pt-5 pb-4 shadow-lg focus:outline-none">
                <button
                    onClick={() => onClose()}
                    className="z-[1] absolute top-[15px] right-[15px] p-1"
                    aria-label="Close"
                >
                    <Cross2Icon className="w-5 h-5" />
                </button>
                <div className="relative flex gap-3 items-center text-stone-900 text-lg font-semibold px-6 pr-11">
                    {isLoading ? (
                        <div className="flex gap-3 items-center">
                            <Skeleton borderRadius={4} height={41} width={33} />
                            <div className="flex flex-col gap-1">
                                <Skeleton borderRadius={2} height={16} width={200} />
                                <Skeleton borderRadius={2} height={12} width={80} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <img
                                src={getFileIcon(foundFile?.file_extension_type || extension)}
                                alt=""
                                className="w-[33px] h-[41px]"
                            />
                            <div className="flex flex-col gap-0.5">
                                <div
                                    className="flex font-semibold items-baseline text-sm text-stone-900 break-all line-clamp-2 pr-4"
                                    css={[foundFile && tw`items-center`]}
                                >
                                    {foundFile?.name || name}
                                    {!foundFile && (
                                        <span className="text-xs text-gray-500 ml-1.5 font-medium whitespace-nowrap">
                                            (Deleted)
                                        </span>
                                    )}
                                    {foundFile && (
                                        <a
                                            href={foundFile.download_url}
                                            download={foundFile?.name || name}
                                            className="text-[18px] ml-1.5 pb-0.5 text-action duration-150 hover:text-action-hover"
                                        >
                                            <HiOutlineDownload />
                                        </a>
                                    )}
                                </div>
                                {foundFile?.created_at && (
                                    <div className="font-normal text-xs text-gray-500">
                                        {moment(foundFile.created_at).format("MMMM D, yyyy")}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="flex flex-col mt-4 flex-1 min-h-0">
                    <div className="text-slate-700 pl-6 pr-10 text-sm text-gray-primary font-normal break-words whitespace-pre-line overflow-y-auto">
                        {sourceContent}
                    </div>
                </div>
            </div>
        </>
    );
};

export default InlineSourceModal;
