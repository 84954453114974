import { FetchEventSourceInit, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { useCallback, useRef } from "react";

type GenerateTemplateVariables = {
    requirements: { id: string; content: string; response: string }[];
};

export const useGenerateTemplate = (options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const controllerRef = useRef(new AbortController());
    const { current: controller } = controllerRef;

    const generateTemplate = (body: GenerateTemplateVariables) => {
        fetchEventSource(`${process.env.REACT_APP_BASE_URL}/templates/extract/requirements`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Workspace: `Workspace ${workspace_id}`,
                Authorization: `Bearer ${localValue}`,
                Accept: "application/json",
            },
            body: JSON.stringify(body),
            signal: controller.signal,
            ...options,
        });
    };

    const abortConnection = useCallback(() => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
    }, []);

    return { generateTemplate, abortConnection };
};
