import { useSelector, useDispatch } from "react-redux";
import { resetAuth, setCurrentUser, setWorkSpacesMembers } from "store/reducers/authReducerSlice";
import { useEffect, useState } from "react";
import { updateUsername } from "api/api";
import { CheckMarkGreen, CloseIconSm, Spinner } from "utils/icons";
import { validateField } from "utils/helpers";
import { useNotification } from "context/notificationContext";

// ------------- Account tab --------------
const Account = () => {
    const { myWorkSpaces, isLoadingWorkSpaces, currentUser, workspaceMembers, isLoadingMembers } = useSelector(
        (store) => store.auth
    );
    const { setToast } = useNotification();
    const dispatch = useDispatch();
    const [username, setUserName] = useState("");
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");
    const [nameUpdated, setNameUpdated] = useState({
        saved: false,
        lastText: "",
    });

    // logout
    const logout = () => {
        localStorage.removeItem("vultron_workspace_id");
        localStorage.removeItem("vultron_user_token");
        localStorage.removeItem("vultron_user_sso");
        window.Intercom("shutdown");
        dispatch(resetAuth());
        setToast.success({
            msg: "Logged out",
        });
    };

    // Update username
    const handleUpdateUserName = (e) => {
        e.preventDefault();
        if (updating) return;
        if (!validateField(username)) {
            setError("Username is required");
            return;
        }
        if (username?.trim() === nameUpdated?.lastText?.trim()) {
            setNameUpdated({
                saved: true,
                lastText: username,
            });
            return;
        }
        setUpdating(true);
        setError("");
        updateUsername({ username })
            .then(() => {
                dispatch(
                    setCurrentUser({
                        ...currentUser,
                        username,
                    })
                );
                let updatedMembers = [...workspaceMembers];
                let index = updatedMembers?.findIndex((v) => v?.id === currentUser?.id);
                updatedMembers[index] = {
                    ...updatedMembers[index],
                    username: username,
                };
                dispatch(
                    setWorkSpacesMembers({
                        data: updatedMembers,
                        loading: false,
                    })
                );
                setNameUpdated({
                    saved: true,
                    lastText: username,
                });
            })
            .catch((err) => {
                if (err?.response?.status === 500) {
                    setError("Username already exists in workspace");
                    setNameUpdated({
                        updated: false,
                        lastText: "",
                    });
                }
                setToast.error({
                    title: "Unable to update username",
                    msg:
                        err?.response?.status === 500
                            ? "Username already exists in the workspace. Please use a different username."
                            : "We were unable to update the username due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    //
    useEffect(() => {
        if (isLoadingWorkSpaces || isLoadingMembers) return;
        setUserName(currentUser?.username || "");
        setNameUpdated({
            saved: true,
            lastText: currentUser?.username || "",
        });

        // eslint-disable-next-line
    }, [currentUser]);
    return (
        <div className="py-3">
            <div className="border rounded-lg mb-4 bg-white p-5 ">
                <h4 className="font-[500] text-[16px] mb-4">Account Details</h4>
                <form
                    autoComplete="off"
                    autoCorrect="off"
                    onSubmit={handleUpdateUserName}
                    className={`mt-8 mb-7 ${updating || isLoadingWorkSpaces || isLoadingMembers ? " opacity-60" : ""}`}
                >
                    <label className="text-[#5c6b7a] mb-3 block text-sm" htmlFor="usN">
                        Username
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            name="usN"
                            id="usN"
                            value={username}
                            disabled={updating || isLoadingWorkSpaces || isLoadingMembers}
                            onChange={(e) => {
                                setUserName(e.target.value);
                                setError(validateField(e.target.value) ? "" : "Username is required");
                                setNameUpdated({
                                    ...nameUpdated,
                                    saved: nameUpdated?.lastText?.trim() === e.target.value?.trim(),
                                });
                            }}
                            onBlur={handleUpdateUserName}
                            className={`w-full bg-white text-[16px] placeholder:text-gray-500 outline-0 ${
                                error ? "text-red-500 border-red-500" : "text-[#1e1e1e] focus:border-action"
                            } px-3 py-2 rounded-lg border`}
                        />
                        {updating ? (
                            <div className="absolute flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                                <Spinner classes="!text-black" />
                            </div>
                        ) : error || nameUpdated?.saved || nameUpdated?.lastText?.trim() === username?.trim() ? (
                            <div className="absolute  flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                                {error ? (
                                    <span className="bg-red-500 p-[5px] rounded-full text-white">
                                        <CloseIconSm width={10} height={10} />
                                    </span>
                                ) : (
                                    <CheckMarkGreen />
                                )}
                            </div>
                        ) : null}
                    </div>
                    {error ? <p className="text-red-500 mt-1 mb-2 block text-[14px]">{error}</p> : null}
                </form>
                <p className="text-[#5c6b7a] mb-3 block text-sm">Email</p>
                <input
                    type="email"
                    name="email"
                    readOnly
                    id="email"
                    defaultValue={myWorkSpaces?.email || ""}
                    className="border-0 w-full text-[16px] text-black outline-0 bg-white "
                />
            </div>
            <div className="border rounded-lg mb-3 bg-white p-5">
                <button
                    onClick={logout}
                    className="px-3 py-3 rounded-[50px] bg-action text-white border-0 min-w-[85px] min-h-[40px] text-sm font-medium"
                >
                    Log Out
                </button>
            </div>
        </div>
    );
};

export default Account;
