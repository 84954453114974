import lod from "Assets/loader.svg";
import searchIcon from "Assets/search-normal.png";
import previewIcon from "Assets/image-preview.svg";
import NotFoundItems from "components/NotFoundItems";
import { Checkbox } from "components/atoms/checkbox";
import { useMediaSearch } from "pages/drive/media/hooks";
import PreviewMediaModal from "pages/drive/modals/PreviewMediaModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMediaFile, setPreviewMediaFile } from "store/reducers/yjs-editor-reducer";

export const GraphicCollection = () => {
    const [searchValue, setSearchValue] = useState<string>("");
    const { isSearching, searchResults, searchMedia, reset } = useMediaSearch();
    const dispatch = useDispatch();
    const { selectedMediaFile, previewMediaFile } = useSelector((state: any) => state.yjsEditor);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const handleSearchChange = (e: any) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value) {
            searchMedia(value);
        }
    };

    useEffect(() => {
        if (!searchValue) {
            reset(true);
            dispatch(setSelectedMediaFile(null));
            dispatch(setPreviewMediaFile(null));
        }
    }, [searchValue]);

    return (
        <div className="relative y-full h-full flex flex-col overflow-y-auto">
            <div className="sticky top-0 z-[20] bg-white">
                <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />
                <input
                    className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border-[1px] "
                    value={searchValue}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="h-full w-full">
                {isSearching ? (
                    <div className="h-full bg-white rounded-b-lg w-full flex justify-center flex-col items-center">
                        <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
                    </div>
                ) : searchResults.length > 0 ? (
                    <div className="cards rounded-md grid grid-cols-3 gap-x-[30px] gap-y-5 my-4 ">
                        {searchResults.map((file) => (
                            <div key={file.id}>
                                <div className="border-[2px] rounded-md card flex flex-col w-full relative hover:border-2 hover:border-gray-400 shadow-md">
                                    <Checkbox
                                        className="absolute top-2 right-2 z-10"
                                        size="md"
                                        onCheck={() =>
                                            dispatch(
                                                file.id === selectedMediaFile?.id
                                                    ? setSelectedMediaFile(null)
                                                    : setSelectedMediaFile(file)
                                            )
                                        }
                                        checked={file.id === selectedMediaFile?.id}
                                    />
                                    <figure
                                        className="relative overflow-hidden w-full h-60 rounded-md  flex  justify-center items-center cursor-pointer"
                                        onClick={() => {
                                            dispatch(
                                                file.id === selectedMediaFile?.id
                                                    ? setSelectedMediaFile(null)
                                                    : setSelectedMediaFile(file)
                                            );
                                        }}
                                    >
                                        <img
                                            src={file.download_url}
                                            alt="graphics"
                                            loading="lazy"
                                            className="w-full max-w-full h-full pointer-events-none object-cover "
                                        />
                                    </figure>
                                    <img
                                        className="absolute bottom-2 right-2 z-10 h-4 w-4 min-h-[16px] min-w-[16px] rounded-[4px] flex justify-center items-center outline-none bg-white cursor-pointer duration-150 border border-gray-400 hover:border-gray-500 "
                                        src={previewIcon}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsPreviewModalOpen(true);
                                            dispatch(setPreviewMediaFile(file));
                                        }}
                                    />
                                </div>
                                <div className="mt-1 text-sm font-normal overflow-hidden whitespace-nowrap overflow-ellipsis ">
                                    {file.file_name}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <NotFoundItems
                        title="No Search Result"
                        subTitle="There are no graphics that match your search"
                        className={"h-full w-full flex justify-center items-center bg-inherit"}
                        img={undefined}
                    />
                )}
            </div>
            {previewMediaFile && (
                <PreviewMediaModal
                    open={isPreviewModalOpen}
                    onOpenChange={setIsPreviewModalOpen}
                    selectedMedia={previewMediaFile}
                />
            )}
        </div>
    );
};
