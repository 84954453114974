/** @jsxImportSource @emotion/react */

import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import useRequirementOperations from "hook/useRequirementOperations";
import { useMemo, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import WritingPromptRow from "./WritingPromptRow";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import SortableItem from "./SortableItem";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { useAppDispatch } from "store/storeTypes";
import { toggleWritingPromptsIdeationModal } from "store/reducers/modalsSlice";
import { openWritingPromptIdeation } from "store/reducers/copilot/WritingPromptIdeationReducer";
import { LuPlus } from "react-icons/lu";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import { HiOutlineLightBulb } from "react-icons/hi";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import { property } from "lodash";
import tw from "twin.macro";
import { useTrackUserMetric } from "utils/metrics";

type Props = {
    row: ComplianceMatrixRow;
};

const WritingPrompts = ({ row }: Props) => {
    const { requirement } = row;
    const { addNewWritingPrompt } = useRequirementOperations();
    const dispatch = useAppDispatch();
    const [editablePrompt, setEditablePrompt] = useState("");
    const prompts = row?.writing_prompts;
    const { sensors, handleDragEnd, handleDragCancel, handleDragStart, activeDragId } = useDrag();
    const handleTranslation = (text: string) => {
        addNewWritingPrompt(requirement.id, {
            content: text,
        });
    };
    const trackUserEvent = useTrackUserMetric();

    const activePrompt = useMemo(() => prompts?.find(({ id }) => id === activeDragId), [activeDragId, prompts]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={verticalSortableListCollisionDetection}
            onDragEnd={(event) => handleDragEnd(event, requirement.id)}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
        >
            <div className="flex flex-col gap-1.5">
                <div className="flex items-center justify-between">
                    <div className="text-xs font-semibold text-gray-darkest flex items-center gap-1">
                        Writing Prompts
                        <Tooltip
                            disableHoverableContent
                            content="Use writing prompts to provide guidance on what to include in the response. Vultron will automatically gathers all the relevant information and synthesize the content to address each prompt. You can also point to specific documents."
                        >
                            <Icon name="InfoCircle" className="w-3 h-3" />
                        </Tooltip>
                    </div>
                    <div className="flex gap-1.5">
                        <button
                            onClick={() => {
                                dispatch(toggleWritingPromptsIdeationModal({ open: true }));
                                dispatch(openWritingPromptIdeation(row));
                            }}
                            disabled={row.locked}
                            className="flex items-center gap-1 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                        >
                            <HiOutlineLightBulb className="text-sm -mt-0.5" />
                            Ideate
                        </button>
                        <VoiceTranslateItem
                            onComplete={handleTranslation}
                            bg="slate-200"
                            text="slate-600"
                            hoverBg="slate-300"
                            hoverText="slate-900"
                            rounded="rounded"
                            disabled={row.locked}
                        />
                        <button
                            onClick={() => {
                                const createdPrompt = addNewWritingPrompt(requirement.id);
                                setEditablePrompt(createdPrompt?.id || "");

                                trackUserEvent("Drafts: New Writing Prompt Added", {
                                    requirement_id: String(requirement.id),
                                });
                            }}
                            disabled={row.locked}
                            className="bg-slate-200 text-slate-600 rounded shadow-sm text-base w-6 h-6 flex items-center justify-center duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>
                <div
                    className="flex flex-col rounded max-h-[260px] overflow-auto"
                    css={[row.locked && tw`pointer-events-none`]}
                >
                    {!!prompts?.length ? (
                        <SortableContext
                            id="REQUIREMENT_PROMPTS"
                            items={prompts || []}
                            strategy={verticalListSortingStrategy}
                            disabled={row.locked}
                        >
                            <div className="flex flex-col gap-1">
                                {prompts?.map((prompt) => (
                                    <SortableItem
                                        key={prompt.id}
                                        reqId={requirement.id}
                                        editablePrompt={editablePrompt}
                                        setEditablePrompt={setEditablePrompt}
                                        prompt={prompt}
                                        disabled={row.locked}
                                    />
                                ))}
                            </div>
                            <DragOverlay style={{ transformOrigin: "0 0 " }}>
                                {!!activePrompt && (
                                    <WritingPromptRow
                                        reqId={requirement.id}
                                        editablePrompt={editablePrompt}
                                        setEditablePrompt={setEditablePrompt}
                                        prompt={activePrompt}
                                        isDragging
                                    />
                                )}
                            </DragOverlay>
                        </SortableContext>
                    ) : (
                        <button
                            onClick={() => {
                                const createdPrompt = addNewWritingPrompt(requirement.id);
                                setEditablePrompt(createdPrompt?.id || "");

                                trackUserEvent("Drafts: New Writing Prompt Added", {
                                    requirement_id: String(requirement.id),
                                });
                            }}
                            disabled={row.locked}
                            className="bg-slate-200 h-11 text-start flex items-center justify-between gap-2 text-slate-600 rounded text-xs px-2 py-1.5 disabled:opacity-50"
                        >
                            <div className="flex items-center gap-2">
                                <BsPlusCircleFill /> Add writing prompt
                            </div>
                        </button>
                    )}
                </div>
            </div>
        </DndContext>
    );
};

export default WritingPrompts;
