import { DocumentInputContent } from "components/molecules/documents-mention-input/DocumentsMentionInput";

export const GENERATED_CONTENT_DROPPABLE = "GENERATED_CONTENT";
export const SELECTED_CONTENT_DROPPABLE = "SELECTED_CONTENT";
export const MISSING_REQUIREMENT_WRITE_INPUT = "Write a concise, coherent response using the provided materials below.";
export const INITIAL_SCROLL_STATE = { didScroll: false, scrollY: 0 };
export const INITIAL_LOADING_MSG = "Searching for relevant content";
export const SECOND_LOADING_MSG = "Synthesizing";
export const THIRD_LOADING_MSG = "Generating ideas";
export const INITIAL_LOADING_STATE = {
    msg: INITIAL_LOADING_MSG,
    loading: false,
};
export const INITIAL_QUERY_STATE: DocumentInputContent = {
    raw: "",
    plainText: "",
    mentions: [],
};
