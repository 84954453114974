/** @jsxImportSource @emotion/react */

import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { WritingAssistantConversation } from "components/copilot/writing-assistant-drawer";
import WritingAssistantInput from "components/copilot/writing-assistant-drawer/writing-assistant-input";
import CopilotActionsOptions from "./CopilotActionsOptions";
import { ContentSearchContent } from "../Framework/content-search-drawer";
import { ScreenSpinner } from "utils/icons";
import { useStatus } from "liveblocks.config";
import RequirementDetails from "components/organisms/requirement-details";
import { toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveRequirementId } from "store/reducers/copilot/requirementsReducer";
import Icon from "components/atoms/icons/Icon";
import { RequirementsDrawerContent } from "../Framework/requirements-drawer/RequirementsDrawer";

const CopilotActionsPanel = () => {
    const dispatch = useAppDispatch();
    const { open, assistantOpen, contentSearchOpen, requirementsOpen, requirementDetailsOpen } = useAppSelector(
        (root) => root.copilotDrawer
    );
    const liveblocksStatus = useStatus();

    return (
        <div
            className="bg-[#f8f9fa] p-2 pl-0 delay-200 opacity-0 absolute right-0 top-0 bottom-0 h-full duration-100 w-[460px] 2xl:w-[525px]"
            css={[open && tw`opacity-100`, !open && tw`[animation-delay: 300ms] pointer-events-none animate-delayHide`]}
        >
            <div className="relative flex flex-col h-full border border-gray-300 rounded-lg items-center bg-white overflow-hidden">
                {liveblocksStatus !== "connected" && (
                    <div className="p-7 top-0 left-0 right-0 bottom-0 absolute bg-[#5f55550e] z-[999999999] backdrop-blur-[2px] my-auto flex items-center justify-center">
                        <ScreenSpinner />
                    </div>
                )}
                <div className="flex w-full items-center gap-2 px-3 min-h-[60px] h-[60px] bg-white">
                    <CopilotActionsOptions />
                </div>
                <div className="w-full text-base font-semibold text-gray-600 px-3 py-2 border-b border-gray-light">
                    {assistantOpen && "Writing Assistant"}
                    {contentSearchOpen && "Search"}
                    {requirementsOpen && "Requirements"}
                    {requirementDetailsOpen && (
                        <div className="flex gap-1 items-center">
                            <button
                                className="!h-5 !w-5 text-inherit hover:text-gray-darkest"
                                onClick={() => {
                                    dispatch(toggleRequirements(true));
                                    dispatch(setActiveRequirementId(null));
                                }}
                            >
                                <Icon name="ArrowLeft" className="h-5 w-5" />
                            </button>
                            <span>Requirements</span>
                        </div>
                    )}
                </div>
                {assistantOpen && (
                    <div className="h-[calc(100%-101px)] w-full flex flex-col">
                        <WritingAssistantConversation />
                        <WritingAssistantInput />
                    </div>
                )}
                {contentSearchOpen && (
                    <div className="h-[calc(100%-101px)] w-full flex flex-col pt-4">
                        <ContentSearchContent />
                    </div>
                )}
                {(requirementsOpen || requirementDetailsOpen) && (
                    <div className="relative h-[calc(100%-101px)] w-full flex flex-col pt-4">
                        <RequirementsDrawerContent />
                        {requirementDetailsOpen && <RequirementDetails />}
                    </div>
                )}
                {/* {requirementDetailsOpen && (
                    <div className="h-[calc(100%-101px)] w-full flex flex-col">
                        <RequirementDetails />
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default CopilotActionsPanel;
