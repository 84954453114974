/** @jsxImportSource @emotion/react */

import Drawer from "components/organisms/drawer/Drawer";
import { useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import CircularPercentage from "components/molecules/circular-percentage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TOP_BANNER } from "const-values/Banner";
import ProjectProgressContent from "./ProjectProgressContent";
import { useEffect, useState } from "react";

const ProjectProgressDrawer = () => {
    const { progressCompleted } = useAppSelector((root) => root.projectProgress);
    // const { progressOpen: open } = useAppSelector((root) => root.copilotDrawer);
    const open = false;
    const flags = useFlags();

    const [percent, setPercent] = useState(0);
    useEffect(() => {
        if (open)
            setTimeout(() => {
                setPercent(progressCompleted);
            }, 250);

        if (!open) setPercent(0);
    }, [open, progressCompleted, percent]);

    return (
        <Drawer
            fullScreen
            opened={open}
            overlay
            overlayStyles={tw`bg-transparent`}
            onClose={() => {}}
            drawerActions={<CircularPercentage percentage={percent} />}
            header={<div className="text-stone-900 text-base font-semibold self-center">Project Checklist</div>}
            styles={[
                tw`top-[65px] z-[101] min-w-[425px] w-[425px]`,
                flags.topBanner && { top: `calc(65px + ${TOP_BANNER.HEIGHT}px)` },
            ]}
        >
            <ProjectProgressContent />
        </Drawer>
    );
};

export default ProjectProgressDrawer;
