/** @jsxImportSource @emotion/react */

import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import ComplianceMatrix from "./ComplianceMatrix/ComplianceMatrix";
import Proposal from "./Proposal";
import { useStorage, useStatus, useUpdateMyPresence } from "../../liveblocks.config";
import { RoomProvider } from "../editor/liveblocks.config";
import { ScreenSpinner } from "utils/icons";
import { getProposalDocuments } from "api/api";
import { useEffect, useMemo, useState } from "react";
import ProposalTable from "./ProposalTable";
import { ClientSideSuspense } from "@liveblocks/react";
import { Loading } from "components/yjs-editor/components/Loading";
import { ReactComponent as Template } from "../../Assets/template.svg";
import { ReactComponent as ProposalIcon } from "../../Assets/proposal-icon.svg";
import { ReactComponent as Bookmarkicon } from "../../Assets/bookmarkicon.svg";
import { ReactComponent as ExtractIcon } from "../../Assets/document-download-v2.svg";
import { ReactComponent as Binder } from "../../Assets/binder.svg";
import tw from "twin.macro";
import { useSelector } from "react-redux";
import LiveOthers from "./LiveOthers";
import { CopilotPresencePage } from "types/Presence";
import ProposalPlan from "./ProposalPlan/ProposalPlan";
import { useBannerState, usePollAutopilotStatus, useSyncResponses } from "./hooks";
import { useAppSelector } from "store/storeTypes";
import { ProjectProgressDrawer } from "./project-progress";
import ProjectContent from "pages/project-details/ProjectContent";
import AskAi from "components/askAi/AskAi";
import WritingAssistantDrawer from "./writing-assistant-drawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import { RequirementsIcon } from "./contants";
import { Extract } from "./Extract";
import CopilotModals from "./copilot-modals";
import CopilotBanners from "./copilot-banners";
import RequirementsBulkUpdate from "components/organisms/requirements-bulk-update";
import Draft from "./Framework/draft";
import useSheetOperations from "hook/useSheetOperations";

// --------------- Copilot tab ---------------
const Copilot = ({
    setContractDetails,
    setInternalContractDetails,
    setForceRefreshContract,
    internalContractDetails,
    contractDetails,
    internalContractId,
    isProjectLoading,
}) => {
    const [searchParams] = useSearchParams();
    const tabSlug = searchParams.get("tab")?.toLocaleLowerCase() || CopilotPresencePage.Project;
    const id = searchParams.get("id")?.toLocaleLowerCase();
    // const docIdParam = searchParams.get("docId")?.toLocaleLowerCase();
    const [docId, setDocId] = useState("");
    const [totalProposals, setTotalProposals] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true);
    const proposalRoomId = `document/${id}/${docId}`;
    const { fullscreen } = useAppSelector((root) => root.copilot);
    const { state } = useLocation();
    const { abortAllAutoResponse } = useSyncResponses();
    const { validateDefaultSheet } = useSheetOperations();
    usePollAutopilotStatus();
    useBannerState();
    const flags = useFlags();

    useEffect(() => {
        validateDefaultSheet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!forceRefresh) return;
        getProposalDocuments(id).then((resp) => {
            setTotalProposals(resp.data || []);
        });
        setForceRefresh(false);
    }, [id, forceRefresh]);

    useEffect(() => {
        if (state?.docId) setDocId(state.docId);
    }, [state?.docId]);

    // TODO: Implement top level state management for all attributes
    // const patchRequirements = usePatchRequirementsId();
    // const complianceMatrixStorageState = useStorage((root) => root.compliance_matrix);

    const frameworkStorageState = useStorage((root) => root.framework);
    const liveblocksStatus = useStatus();

    // Tabs data
    const tabs = useMemo(
        () => [
            {
                name: "Project",
                slug: CopilotPresencePage.Project,
                tabIcon: Binder,
                component: (
                    <ProjectContent
                        isLoading={isProjectLoading}
                        searchPage={false}
                        // Represents public contract reference information
                        id={contractDetails?.id}
                        contractDetails={contractDetails}
                        setContractDetails={setContractDetails}
                        // Represents internal contract information
                        internalContractId={internalContractId}
                        internalContractDetails={internalContractDetails}
                        setForceRefresh={setForceRefreshContract}
                        setInternalContractDetails={setInternalContractDetails}
                    />
                ),
            },
            {
                name: "Documents",
                slug: CopilotPresencePage.ProposalPlan,
                tabIcon: Bookmarkicon,
                component: (
                    <ProposalPlan
                        internalContractId={internalContractId}
                        setForceRefresh={setForceRefresh}
                        internalContractDetails={internalContractDetails}
                    />
                ),
            },
            {
                name: "Extract",
                slug: CopilotPresencePage.Extract,
                tabIcon: ExtractIcon,
                component: <Extract />,
            },
            {
                name: "Requirements",
                slug: CopilotPresencePage.ComplianceMatrix,
                tabIcon: RequirementsIcon,
                component: (
                    <ComplianceMatrix
                        frameworkState={frameworkStorageState}
                        abortAllAutoResponse={abortAllAutoResponse}
                    />
                ),
            },
            {
                name: "Draft",
                tabIcon: Template,
                slug: CopilotPresencePage.Template,
                component: <Draft />,
            },
            {
                name: "Proposals",
                slug: CopilotPresencePage.Proposal,
                tabIcon: ProposalIcon,
                component: docId ? (
                    <RoomProvider id={proposalRoomId} initialPresence={{ isTyping: false }}>
                        <ClientSideSuspense fallback={<Loading />}>
                            {() => (
                                <Proposal
                                    fullscreen={fullscreen}
                                    setForceRefresh={setForceRefresh}
                                    setDocId={setDocId}
                                    proposalDocId={docId}
                                />
                            )}
                        </ClientSideSuspense>
                    </RoomProvider>
                ) : (
                    <ProposalTable
                        proposals={totalProposals}
                        setProposals={setTotalProposals}
                        internalContractId={internalContractId}
                        setDocId={setDocId}
                        setForceRefresh={setForceRefresh}
                    />
                ),
            },
        ],
        [
            abortAllAutoResponse,
            contractDetails,
            docId,
            frameworkStorageState,
            fullscreen,
            internalContractDetails,
            internalContractId,
            isProjectLoading,
            proposalRoomId,
            setContractDetails,
            setForceRefreshContract,
            setInternalContractDetails,
            totalProposals,
        ]
    );

    const activeTab = useMemo(() => tabs?.find((v) => v?.slug === tabSlug) || tabs[0], [tabSlug, tabs]);
    const { currentUser } = useSelector((store) => store.auth);
    const updateMyPresence = useUpdateMyPresence();

    useEffect(() => {
        updateMyPresence({
            name: currentUser.username,
            activePage: activeTab.slug,
            proposalRoomId,
        });
    }, [activeTab.slug, currentUser.id, currentUser.username, docId, proposalRoomId, updateMyPresence]);

    // TOGGLE FULLSCREEN
    // useEffect(() => {
    //     if (fullscreen) {
    //         setToast.dark({
    //             msg: (
    //                 <div className="flex items-center">
    //                     Press{" "}
    //                     <span className="font-bold mx-1.5 border border-gray-400 bg-gray-500 px-1.5 relative z-[1] rounded">
    //                         esc
    //                     </span>{" "}
    //                     to leave fullscreen
    //                 </div>
    //             ),
    //             duration: 10000,
    //         });
    //     }

    //     return clearToast;
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fullscreen]);

    // useKey(
    //     "Escape",
    //     () => {
    //         if (fullscreen) {
    //             dispatch(setFullscreen(false));
    //         }
    //     },
    //     undefined,
    //     [fullscreen]
    // );

    const isAskAi = tabSlug === CopilotPresencePage.AskAi;
    return (
        <>
            {flags.writingAssistant && !fullscreen && <WritingAssistantDrawer fullscreen={fullscreen} />}
            {!fullscreen && <ProjectProgressDrawer />}
            {isAskAi ? (
                <AskAi internalContractId={internalContractId} />
            ) : (
                <>
                    <RequirementsBulkUpdate />
                    <CopilotModals />
                    <CopilotBanners />
                    <div className="relative w-full h-full bg-white text-gray-darkest rounded-lg border-[#d1d5db] border flex flex-col flex-1 [overflow-y:unset] overflow-x-hidden">
                        <div className="px-5">
                            <div className="flex gap-5 border-b border-[#d1d5db] max-w-full justify-between items-center">
                                <div className="flex gap-4 max-w-full">
                                    {tabs.map(({ tabIcon: TabIcon, ...tab }, i) => (
                                        <NavLink
                                            className={
                                                "text-[13px] font-normal border-b items-center flex gap-2 text-gray-text cursor-pointer pb-3 pt-[18px] hover:border-action border-transparent"
                                            }
                                            css={[tabSlug === tab.slug && tw`border-action text-action`]}
                                            key={i}
                                            to={`/dashboard/contracts/details?tab=${tab.slug}&id=${id}`}
                                        >
                                            <TabIcon
                                                className="text-gray-mid h-[16px] w-[16px]"
                                                css={[
                                                    tab.slug === CopilotPresencePage.Template && tw`p-[1.5px]`,
                                                    tabSlug === tab.slug && tw`text-action`,
                                                ]}
                                            />
                                            {tab?.name}
                                        </NavLink>
                                    ))}
                                </div>
                                <div className="flex items-center gap-3">
                                    {activeTab.slug !== CopilotPresencePage.Proposal && (
                                        <LiveOthers tab={activeTab.slug} />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="pt-5 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                            {activeTab?.component}
                        </div>
                        {(liveblocksStatus !== "connected" || frameworkStorageState === null) && (
                            <div className="p-7 top-0 left-0 right-0 bottom-0 absolute bg-[#5f55550e] z-[999999999] backdrop-blur-[2px] my-auto flex items-center justify-center">
                                <ScreenSpinner />
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Copilot;
