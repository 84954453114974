import axios from "axios";
import useRequirementOperations from "hook/useRequirementOperations";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { setHeadingProgress } from "store/reducers/draft/previewProposalReducer";
import { useAppDispatch } from "store/storeTypes";

export const useGenerateRequirementHeading = () => {
    const dispatch = useAppDispatch();
    const { setGeneratedHeading } = useRequirementOperations();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id");

    const generateRequirementHeading = useCallback(
        async (body: { requirement_ids: string[] }) => {
            if (!projectId) return;
            dispatch(setHeadingProgress({ add: true, ids: body.requirement_ids }));
            try {
                const { data } = await axios.post<{
                    requirements: { requirement_id: string; title: string }[];
                }>(`proposal_generation/${projectId}/requirement/title`, body);
                data.requirements.forEach(({ requirement_id, title }) => setGeneratedHeading(requirement_id, title));
            } catch {
            } finally {
                dispatch(setHeadingProgress({ add: false, ids: body.requirement_ids }));
            }
        },
        [dispatch, projectId, setGeneratedHeading]
    );

    return { generateRequirementHeading };
};
