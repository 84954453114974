import { ReactNode } from "react";

export enum Arcade {
    Project = "project",
    WinThemes = "win-themes",
    Requirements = "requirements",
    Template = "template",
    Editor = "editor",
}

export const ARCADE_TO_EMBED: Record<Arcade, ReactNode> = {
    [Arcade.Requirements]: (
        <div
            style={{ position: "relative", paddingBottom: "calc(56.59722222222222% + 41px)", height: 0, width: "100%" }}
        >
            <iframe
                src="https://demo.arcade.software/Hy56p47xcwWAOFOOFZ2p?embed"
                title="Requirements"
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
            />
        </div>
    ),
    [Arcade.Template]: (
        <div
            style={{ position: "relative", paddingBottom: "calc(49.93055555555556% + 41px)", height: 0, width: "100%" }}
        >
            <iframe
                src="https://demo.arcade.software/oYH84sPoRfGZOm217bYs?embed"
                title="Draft"
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
            />
        </div>
    ),
    [Arcade.Project]: (
        <div
            style={{ position: "relative", paddingBottom: "calc(50.55555555555556% + 41px)", height: 0, width: "100%" }}
        >
            <iframe
                src="https://demo.arcade.software/0in55EDD5EaHiDmL9IJt?embed"
                title="Projects Take 2"
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
            />
        </div>
    ),
    [Arcade.Editor]: (
        <div
            style={{ position: "relative", paddingBottom: "calc(49.93055555555556% + 41px)", height: 0, width: "100%" }}
        >
            <iframe
                src="https://demo.arcade.software/WMCrjpV18FHF7oMT89Al?embed"
                title="Editor"
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
            />
        </div>
    ),
    [Arcade.WinThemes]: (
        <div
            style={{ position: "relative", paddingBottom: "calc(53.10846560846561% + 41px)", height: 0, width: "100%" }}
        >
            <iframe
                src="https://demo.arcade.software/ZCQzCP9DQu6RJRFkaEri?embed"
                title="Generating Win Themes"
                frameBorder="0"
                loading="lazy"
                allowFullScreen={false}
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
            />
        </div>
    ),
};
