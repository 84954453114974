import { Modal } from "components/organisms/modal";
import { ComponentProps } from "react";
import tw from "twin.macro";
import { WorkspaceMedia } from "types/FileStorage";

interface Props extends Partial<ComponentProps<typeof Modal>> {
    selectedMedia: WorkspaceMedia;
}

const PreviewMediaModal = ({ selectedMedia, ...props }: Props) => {
    return (
        <Modal
            contentProps={{ css: tw`w-[50vw] max-w-[800px]` }}
            header="Preview"
            body={
                <div className="px-5 w-full h-full pb-4 overflow-auto">
                    <img
                        alt="preview"
                        className="pointer-events-none object-contain"
                        src={selectedMedia.download_url}
                    />
                </div>
            }
            {...props}
        />
    );
};

export default PreviewMediaModal;
