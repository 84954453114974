import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { TabSlug } from "components/copilot/Framework/types";

type State = {
    activeTab: TabSlug;
    activeSheet: Sheet | null;
};

const initialState: State = {
    activeTab: TabSlug.Unassigned,
    activeSheet: null,
};

const requirementsDrawerReducer = createSlice({
    name: "requirementsDrawerReducer",
    initialState,
    reducers: {
        resetRequirementsDrawerState: () => {
            return initialState;
        },
        setRequirementsDrawerState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setRequirementsDrawerState, resetRequirementsDrawerState } = requirementsDrawerReducer.actions;

export default requirementsDrawerReducer.reducer;
