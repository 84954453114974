/** @jsxImportSource @emotion/react */

import { Link, useParams, useSearchParams } from "react-router-dom";
import tw, { theme } from "twin.macro";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HTMLAttributes, forwardRef, useMemo } from "react";
import Icon from "components/atoms/icons/Icon";
import { ControlItemSlug } from "pages/draft-section/hooks";
import { FormattedSection } from "./DraftVolumeSidebar";
import SubsectionsGroup from "./SubsectionsGroup";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { Button } from "components/editor/components";
import { useFrameworkOperations } from "components/copilot/hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setSectionState } from "store/reducers/draft/sectionReducer";
import Popover from "components/atoms/popover";
import AssigneesPopoverContent from "components/organisms/assignees-popover-content";
import { AvatarGroup } from "components/molecules/avatar-group";
import { useGetUsernameById } from "hook/user/useGetUsernameById";
import { Props as AvatarProps } from "components/molecules/avatar/Avatar";
import { SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import SectionStatusPopoverContent from "components/organisms/section-status-popover-content/SectionStatusPopoverContent";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { useDropdownItems } from "./hooks";
import ConfirmModal from "components/ConfirmModal";
import Avatar from "components/molecules/avatar";
import { SECTION_STATUS_TO_META } from "const-values/Draft";
import EditableContent from "components/molecules/editable-content";
import { useTrackUserMetric } from "utils/metrics";

interface Props extends HTMLAttributes<HTMLDivElement> {
    section: Partial<FormattedSection>;
    withOpacity?: boolean;
    isDragging?: boolean;
    dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const SectionNavRow = forwardRef<HTMLDivElement, Props>(
    ({ section, withOpacity, isDragging, style, dragProps }, ref) => {
        const { title, id, parent_id, subsections, assignees, status } = section;
        const { sectionId, volumeId } = useParams();
        const [searchParams] = useSearchParams();
        const { addNewSection, updateSectionTitle, assignToSection, updateSectionStatus, deleteSection } =
            useFrameworkOperations();
        const sectionEditable = useAppSelector((store) => store.sectionState.sectionEditable);
        const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
        const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
        const dispatch = useAppDispatch();
        const getUsernameById = useGetUsernameById();
        const directionPath = `volumes/${volumeId}/sections/${id}/requirements?${searchParams.toString()}`;
        const { items, deleteSectionModal, setDeleteSectionModal } = useDropdownItems(directionPath, id);

        const isActive = id === sectionId;
        const isSubsectionActive = useMemo(
            () => !!subsections?.some((subsection) => subsection.id === sectionId),
            [sectionId, subsections]
        );
        const isSubsection = !!parent_id;
        const isEditable = id === sectionEditable;
        const trackUserEvent = useTrackUserMetric();

        const avatars = useMemo(
            () =>
                assignees?.reduce<AvatarProps[]>((acc, userId) => {
                    const username = getUsernameById(userId);
                    if (!username) return acc;
                    return [
                        ...acc,
                        {
                            key: userId,
                            id: username,
                            name: username,
                            size: 20,
                            className: "!text-xxs",
                        },
                    ];
                }, []) || [],
            [assignees, getUsernameById]
        );

        const assignedUser = useMemo(() => {
            if (assignees?.length) return getUsernameById(assignees[0]);
        }, [assignees, getUsernameById]);

        return (
            <>
                <ConfirmModal
                    open={deleteSectionModal}
                    onClose={() => setDeleteSectionModal(false)}
                    onProceed={(proceed) => {
                        if (proceed && volumeId && id) {
                            deleteSection(volumeId, id);
                        }
                    }}
                    proceedLabel="Remove"
                    title="Are you sure you want to delete this section?"
                    subTitle={
                        <div>
                            This action is irreversible and will delete all progress associated with the section.
                            <br />
                            <br />
                            Please confirm this action.
                        </div>
                    }
                />
                <div ref={ref}>
                    <div
                        className="group flex flex-col rounded relative gap-2 p-2.5 pl-1 bg-transparent overflow-hidden hover:bg-slate-100"
                        css={[
                            (isActive || isDragging) && tw`bg-slate-100`,
                            {
                                boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
                                zIndex: isDragging ? "2" : "auto",
                                opacity: withOpacity ? "0.3" : "1",
                                pointerEvents: isEditable ? "none" : "auto",
                                ...style,
                            },
                            isSubsection && tw`pl-1 py-1.5 ml-4 mb-0.5`,
                            !!subsections?.length && (isActive || isSubsectionActive) && tw`mb-1`,
                        ]}
                        {...dragProps?.attributes}
                    >
                        <div className="flex flex-row items-center justify-between">
                            {
                                <Link
                                    to={`sections/${id}/${ControlItemSlug.requirements}?${searchParams.toString()}`}
                                    className="absolute top-0 left-0 bottom-0 right-0 select-none no-link-drag"
                                />
                            }
                            <div className="flex flex-row items-start gap-1 flex-1">
                                <div
                                    className="z-[1] bg-transparent rounded p-1 opacity-0 text-slate-500 hover:text-slate-900 hover:bg-slate-200 group-hover:opacity-100"
                                    css={[
                                        { cursor: isDragging ? "grabbing" : "grab" },
                                        isDragging && tw`opacity-100`,
                                        isActive && tw`opacity-100`,
                                    ]}
                                    {...dragProps?.listeners}
                                >
                                    <Icon name="Draggable" className="w-3 h-3" />
                                </div>
                                <EditableContent
                                    submitOnEnter
                                    content={title || ""}
                                    autoFocus={isEditable}
                                    onContentUpdate={(val) => {
                                        if (volumeId && id) updateSectionTitle(volumeId, id, val);
                                        dispatch(setSectionState({ sectionEditable: "" }));
                                    }}
                                    css={[
                                        tw`font-medium p-0 text-gray-mid text-sm flex-1`,
                                        isSubsection && tw`font-normal`,
                                        isActive && tw`text-gray-darkest`,
                                        !isEditable && tw`pointer-events-none`,
                                    ]}
                                    textareaProps={{
                                        placeholder: isSubsection ? "Add subsection title" : "Add section title",
                                        ...(sidebarSize && {
                                            forceResetProps: [sidebarSize, actionsPanelOpen],
                                            forceResetDelay: 120,
                                        }),
                                    }}
                                />
                            </div>

                            <DropdownMenu
                                modal
                                triggerProps={{
                                    className: `z-[1] flex opacity-0 self-start duration-100 group-hover:opacity-100 ${
                                        isActive ? "opacity-100" : ""
                                    }`,
                                }}
                                contentProps={{ align: "start", css: tw`min-w-[120px]` }}
                                items={items}
                            >
                                <div className="p-1 text-base text-slate-600 z-[1] bg-transparent rounded hover:bg-slate-200">
                                    <BsThreeDotsVertical />
                                </div>
                            </DropdownMenu>
                        </div>
                        {(!isSubsection || isActive) && (
                            <div className="pl-6 flex gap-1.5">
                                <Popover
                                    contentProps={{ align: "start", css: tw`mx-0` }}
                                    content={
                                        <AssigneesPopoverContent
                                            singleSelect
                                            selectedUsers={assignees || []}
                                            onUserSelect={(userId) => {
                                                if (!volumeId || !id) return;
                                                assignToSection(volumeId, id, userId ? [userId] : []);

                                                trackUserEvent("Drafts: Section Assigned", {
                                                    user: String(userId),
                                                });
                                            }}
                                            tw="p-1"
                                        />
                                    }
                                >
                                    <button
                                        className="z-[1] h-7 max-w-full flex items-center text-gray-700 py-1 px-1.5 rounded bg-slate-100 duration-150 hover:bg-slate-200"
                                        css={[isActive && tw`bg-slate-200 hover:brightness-95`]}
                                    >
                                        {!!avatars.length ? (
                                            <div className="flex text-xs items-center gap-2 text-gray-500">
                                                <AvatarGroup maxCount={4} size={20} avatars={avatars} />
                                                <span className="max-w-[125px] truncate">{assignedUser}</span>
                                            </div>
                                        ) : (
                                            <span className="text-xs text-slate-500">
                                                <Avatar size={18} empty />
                                            </span>
                                        )}
                                    </button>
                                </Popover>
                                <Popover
                                    contentProps={{ align: "start", css: tw`mx-0` }}
                                    content={
                                        <SectionStatusPopoverContent
                                            selectedStatus={status || SectionStatus.Todo}
                                            onStatusSelect={(newStatus) => {
                                                if (!volumeId || !id) return;
                                                updateSectionStatus(volumeId, id, newStatus);

                                                trackUserEvent("Drafts: Section Status Updated", {
                                                    status: SECTION_STATUS_TO_META[newStatus].label,
                                                });
                                            }}
                                            tw="p-1"
                                        />
                                    }
                                >
                                    <button
                                        className="z-[1] h-7 flex items-center max-w-full text-gray-700 py-1 px-1.5 rounded bg-slate-100 duration-150 hover:bg-slate-200"
                                        css={[isActive && tw`bg-slate-200 hover:brightness-95`]}
                                    >
                                        <div className="flex text-xs items-center gap-1.5 text-gray-500 truncate">
                                            {SECTION_STATUS_TO_META[status || SectionStatus.Todo].icon}
                                            {SECTION_STATUS_TO_META[status || SectionStatus.Todo].label}
                                        </div>
                                    </button>
                                </Popover>
                            </div>
                        )}
                    </div>
                    {!!subsections?.length && (isActive || isSubsectionActive) && !withOpacity && !isDragging && (
                        <SubsectionsGroup sections={subsections} />
                    )}
                    {(isActive || isSubsectionActive) && !isSubsection && !withOpacity && !isDragging && (
                        <Button
                            onClick={() => {
                                if (id && volumeId) {
                                    const newSubsection = addNewSection(volumeId, { parent_id: id });
                                    dispatch(
                                        setSectionState({
                                            sectionEditable: newSubsection.id,
                                        })
                                    );
                                }
                            }}
                            variant="link"
                            size="md"
                            className="ml-[44px] truncate"
                        >
                            + Subsection
                        </Button>
                    )}
                </div>
            </>
        );
    }
);

export default SectionNavRow;
