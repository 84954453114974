import { Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import SectionBatch from "./SectionBatch";
import Switch from "components/atoms/switch";
import tw from "twin.macro";
import { usePreviewProposalConfig, usePublishTrigger } from "./hooks";
import { MdPublish } from "react-icons/md";

type Props = {
    volume?: Volume;
    sortedSections: FormattedSection[];
    // totalPageCount: number;
};

const PreviewProposalSidebar = ({ volume, sortedSections }: Props) => {
    const { title } = volume || {};
    const { isPublishing, handlePublish } = usePublishTrigger();
    const { previewProposalConfig, toggleIncludeSourceDocuments } = usePreviewProposalConfig();

    return (
        <div className="h-[calc(100%-164px)]">
            <div className="bg-midnight-800 flex flex-col gap-5 pt-8 overflow-y-auto h-full">
                <div className="text-[#BCBCD6] text-sm font-medium px-7">Outline</div>
                <div className="flex justify-between items-center text-zinc-200 font-semibold pl-7 pr-3">
                    <div className="text-base truncate">{title}</div>{" "}
                    {/* <span className="text-xs whitespace-nowrap">Total {totalPageCount} pgs.</span> */}
                </div>
                <div className="flex flex-col gap-3 pb-4">
                    {sortedSections.map((section) => (
                        <SectionBatch key={section.id} section={section} />
                    ))}
                </div>
            </div>
            <div className="bg-[#31313D] shadow-[0px_-5px_9px_#16161C59] px-7 py-6 flex flex-col gap-6">
                <div className="flex flex-col gap-4 text-white">
                    <div className="text-sm font-medium">Draft Settings</div>
                    <div className="flex flex-row items-center w-full justify-between">
                        <div className="text-xs">Include Source Documents</div>
                        <Switch
                            onCheckedChange={toggleIncludeSourceDocuments}
                            checked={previewProposalConfig?.includeSourceDocuments}
                            styles={tw`bg-slate-500 data-[state=checked]:bg-[#3962ac]`}
                        />
                    </div>
                </div>
                <button
                    onClick={handlePublish}
                    disabled={isPublishing}
                    className="bg-midnight-300 flex items-center justify-center rounded text-sm font-medium px-2 py-2 duration-150 hover:bg-slate-400 disabled:bg-gray-200 disabled:text-slate-400"
                >
                    {<MdPublish className="text-base mr-1" />}
                    {isPublishing ? "Publishing" : "Publish"}
                    {isPublishing && <span className="loading-ellipsis" />}
                </button>
            </div>
        </div>
    );
};

export default PreviewProposalSidebar;
