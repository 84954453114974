import { Subdirectory, WorkspaceFile, WorkspaceMedia } from "./../../types/FileStorage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDirectories, getSingleDirectory } from "api/api";
import axios from "axios";

export const fetchFileStorage = createAsyncThunk("fileStorage", async () => {
    const rootResponse = await getDirectories();
    let root = {
        id: "",
        name: "Main",
        children: [],
    };
    const allFolderNodes: { [id: string]: FolderNode } = { "": root };
    let current: FolderNode[] = [root];
    while (current.length > 0) {
        const next: FolderNode[] = [];
        for (const currentNode of current) {
            const response = currentNode.id ? await getSingleDirectory(currentNode.id) : rootResponse;
            const { subdirectories } = response.data;
            for (const subdirectory of subdirectories) {
                const { id, name } = subdirectory;
                const newNode = {
                    id,
                    name,
                    children: [],
                    parentId: currentNode.id,
                };
                currentNode.children.push(newNode);
                allFolderNodes[id] = newNode;
            }
            next.push(...currentNode.children);
        }
        current = next;
    }

    return {
        allFolderNodes,
        rootSubdirectories: rootResponse.data.subdirectories,
        rootFiles: rootResponse.data.files,
    };
});

export const fetchMediaStorage = createAsyncThunk("mediaStorage", async () => {
    const rootResponse = await axios.get("image_search/directory");
    let root = {
        id: "",
        name: "Main Library",
        children: [],
    };

    const allFolderNodes: { [id: string]: FolderNode } = { "": root };
    const user_tags = new Set<string>();
    let current: FolderNode[] = [root];
    while (current.length > 0) {
        const next: FolderNode[] = [];
        for (const currentNode of current) {
            const response = currentNode.id
                ? await axios.get(`image_search/directory/${currentNode.id}`)
                : rootResponse;
            const { subdirectories, files } = response.data;
            for (const subdirectory of subdirectories) {
                const { id, name } = subdirectory;
                const newNode = {
                    id,
                    name,
                    children: [],
                    parentId: currentNode.id,
                };
                currentNode.children.push(newNode);
                allFolderNodes[id] = newNode;
            }
            files.flatMap((file: WorkspaceMedia) => file.user_tags).forEach((tag: string) => user_tags.add(tag));
            next.push(...currentNode.children);
        }
        current = next;
    }

    return {
        allFolderNodes,
        rootSubdirectories: rootResponse.data.subdirectories,
        rootFiles: rootResponse.data.files,
        userTags: Array.from(user_tags),
    };
});

export interface NavHistory {
    id: string;
    name: string;
}

export interface FolderNode {
    id: string;
    name: string;
    children: FolderNode[];
    parentId?: string;
}

type DriveState = {
    fileStorage: {
        rootFiles: WorkspaceFile[];
        rootSubdirectories: Subdirectory[];
        isLoading: boolean;
        navHistory: NavHistory[];
        allFolderNodes: { [id: string]: FolderNode };
    };
    media: {
        rootFiles: WorkspaceMedia[];
        rootSubdirectories: Subdirectory[];
        isLoading: boolean;
        selectedFolderHeading: string;
        draggingDocId: string;
        draggingFolderId: string;
        isDragOverDrive: boolean;
        navHistory: NavHistory[];
        allFolderNodes: { [id: string]: FolderNode };
        userTags: string[];
    };
};

const initialState: DriveState = {
    fileStorage: {
        rootFiles: [],
        rootSubdirectories: [],
        isLoading: false,
        navHistory: [],
        allFolderNodes: {},
    },
    media: {
        rootFiles: [],
        rootSubdirectories: [],
        isLoading: false,
        selectedFolderHeading: "",
        draggingDocId: "",
        draggingFolderId: "",
        isDragOverDrive: false,
        navHistory: [],
        allFolderNodes: {},
        userTags: [],
    },
};

const driveSlice = createSlice({
    name: "drive",
    initialState,
    reducers: {
        updateAllFolders: (state, action) => {
            state.fileStorage.rootSubdirectories = action.payload;
        },
        updateAllDocs: (state, action) => {
            state.fileStorage.rootFiles = action.payload;
        },
        setDocumentNavHistory: (state, action) => {
            state.fileStorage.navHistory = action.payload;
        },
        updateDocumentFolderNodes: (state, action) => {
            state.fileStorage.allFolderNodes = action.payload;
        },
        updateMediaFolderNodes: (state, action) => {
            state.media.allFolderNodes = action.payload;
        },
        updateMediaRootFolders: (state, action) => {
            state.media.rootSubdirectories = action.payload;
        },
        updateMediaRootDocs: (state, action) => {
            state.media.rootFiles = action.payload;
        },
        setIsDragOverDrive: (state, action) => {
            state.media.isDragOverDrive = action.payload;
        },
        setDraggingDocId: (state, action) => {
            state.media.draggingDocId = action.payload;
        },
        setDraggingFolderId: (state, action) => {
            state.media.draggingFolderId = action.payload;
        },
        setSelectedFolderHeading: (state, action) => {
            state.media.selectedFolderHeading = action.payload;
        },
        setMediaNavHistory: (state, action) => {
            state.media.navHistory = action.payload;
        },
        setMediaUserTags: (state, action) => {
            state.media.userTags = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFileStorage.pending, (state) => {
                state.fileStorage.isLoading = true;
            })
            .addCase(fetchFileStorage.fulfilled, (state, action) => {
                state.fileStorage.isLoading = false;
                state.fileStorage.rootSubdirectories = action.payload.rootSubdirectories;
                state.fileStorage.rootFiles = action.payload.rootFiles;
                state.fileStorage.allFolderNodes = action.payload.allFolderNodes;
            })
            .addCase(fetchFileStorage.rejected, (state) => {
                state.fileStorage.isLoading = false;
            })
            .addCase(fetchMediaStorage.pending, (state) => {
                state.media.isLoading = true;
            })
            .addCase(fetchMediaStorage.fulfilled, (state, action) => {
                state.media.isLoading = false;
                state.media.rootSubdirectories = action.payload.rootSubdirectories;
                state.media.rootFiles = action.payload.rootFiles;
                state.media.allFolderNodes = action.payload.allFolderNodes;
                state.media.userTags = action.payload.userTags;
            })
            .addCase(fetchMediaStorage.rejected, (state) => {
                state.media.isLoading = false;
            });
    },
});

export default driveSlice.reducer;
export const {
    updateAllFolders,
    updateAllDocs,
    setIsDragOverDrive,
    setDraggingDocId,
    setDraggingFolderId,
    setSelectedFolderHeading,
    setDocumentNavHistory,
    setMediaNavHistory,
    updateMediaRootDocs,
    updateMediaRootFolders,
    updateDocumentFolderNodes,
    updateMediaFolderNodes,
    setMediaUserTags,
} = driveSlice.actions;
