import { Editor } from "@tiptap/react";
import { EDITOR_ROUTES } from "Routes/editor";
import axios from "axios";
import { useHandleApiError } from "hook/useHandleApiError";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMediaUrls } from "store/reducers/yjs-editor-reducer";

export const useMediaFetch = () => {
    const [isFetchingFiles, setIsFetchingFiles] = useState(false);
    const dispatch = useDispatch();

    const fetchFiles = useCallback(async (ids: string[]) => {
        setIsFetchingFiles(true);
        try {
            const fetchPromises = ids.map((id) => axios.get(EDITOR_ROUTES.media.file.get(id)));
            const results = await Promise.all(fetchPromises);
            results.forEach(({ data }) => {
                if (data && data.download_url) {
                    dispatch(setMediaUrls({ [data.id]: data.download_url }));
                }
            });
        } catch (error) {
        } finally {
            setIsFetchingFiles(false);
        }
    }, []);

    return { isFetchingFiles, fetchFiles };
};

export const useMediaUpload = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const clearFile = () => setFile(null);
    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFile(acceptedFiles[0]);
    }, []);
    const { setError } = useHandleApiError();
    const { selectedMediaFile } = useSelector((state: any) => state.yjsEditor);

    const addMediaByUploader = useCallback(
        async (editor: Editor, saveToContentLibrary: boolean) => {
            if (!file) return;
            setIsUploadingFile(true);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("visible_in_content_library", String(saveToContentLibrary));
            try {
                const { data } = await axios.post(EDITOR_ROUTES.media.file.upload, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (data && data.download_url) {
                    setFile(file);
                    dispatch(setMediaUrls({ [data.id]: data.download_url }));
                    editor
                        .chain()
                        .focus()
                        .insertContent({
                            type: "cimg",
                            attrs: {
                                id: data.id,
                            },
                        })
                        .run();
                }
            } catch (e) {
                setError(
                    "Failed to insert graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            } finally {
                setIsUploadingFile(false);
            }
        },
        [file, setIsUploadingFile]
    );

    const addMediaByGraphicCollection = useCallback(
        async (editor: Editor) => {
            if (!selectedMediaFile || !selectedMediaFile.download_url) return;
            setIsUploadingFile(true);
            try {
                dispatch(setMediaUrls({ [selectedMediaFile.id]: selectedMediaFile.download_url }));
                editor
                    .chain()
                    .focus()
                    .insertContent({
                        type: "cimg",
                        attrs: {
                            id: selectedMediaFile.id,
                        },
                    })
                    .run();
            } catch (e) {
                setError(
                    "Failed to insert graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            } finally {
                setIsUploadingFile(false);
            }
        },
        [selectedMediaFile, setIsUploadingFile]
    );

    return {
        file,
        isUploadingFile,
        onDrop,
        addMediaByUploader,
        addMediaByGraphicCollection,
        clearFile,
    };
};
