import { TOP_BANNER } from "const-values/Banner";

const TopBanner = () => {
    return (
        <div
            className="bg-gray-darkest text-xs font-medium justify-center text-white w-screen flex items-center px-5"
            style={{ height: TOP_BANNER.HEIGHT }}
        >
            We are experiencing system performance issues affecting our services. Our team is actively investigating and
            working towards a resolution. Thank you for your patience.
        </div>
    );
};

export default TopBanner;
