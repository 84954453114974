import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "hook/useLocalStorage";
import { getWorkSpaces, resetAuth, setAuth } from "store/reducers/authReducerSlice";
import axios from "axios";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "store/storeTypes";
import Hotjar from "@hotjar/browser";
import { Workspace } from "types/Workspace";
import { useLocation } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

export const useAccount = () => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { localValue: user_sso } = useLocalStorage("vultron_user_sso", false);
    // redux dispatcher
    const dispatch = useDispatch();
    const { currentUser, myWorkSpaces } = useAppSelector((store) => store.auth);
    const ldClient = useLDClient();

    const currentWorkspace: Partial<Workspace> = useMemo(() => {
        return myWorkSpaces?.workspaces?.find((workspace) => workspace?.id === workspace_id) || {};
    }, [myWorkSpaces?.workspaces, workspace_id]);

    useEffect(() => {
        // get user token from local storage
        if (!localValue) {
            localStorage.removeItem("vultron_user_token");
            localStorage.removeItem("vultron_user_sso");
            localStorage.removeItem("vultron_workspace_id");
            dispatch(resetAuth());
            return;
        }
        axios.defaults.headers.common["Authorization"] = `Bearer ${localValue}`;
        axios.defaults.headers["X-Authorization-SSO"] = user_sso;
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = process.env.REACT_APP_BASE_URL;
        if (workspace_id) {
            axios.defaults.headers.common["Workspace"] = `Workspace ${workspace_id}`;
        }
        dispatch(
            setAuth({
                isAuthenticated: true,
                isLoading: false,
            })
        );
        // @ts-ignore
        dispatch(getWorkSpaces(localValue));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentUser?.id) {
            const { email, id, username } = currentUser;

            ldClient?.identify({
                key: id,
                name: username,
                email,
                workspace_name: currentWorkspace.name || "",
                company_name: currentWorkspace.company_name || "",
            });

            Hotjar.identify(currentUser.id, {
                email: email || "",
                id,
                username: username || "",
                workspace_name: currentWorkspace.name || "",
                company_name: currentWorkspace.company_name || "",
            });
            amplitude.setUserId(currentUser.id);
        }
    }, [currentUser, currentWorkspace, currentWorkspace.company_name, currentWorkspace.name, ldClient]);

    // THIRD-PARTY INTEGRATIONS
    useIntercom();
};

const useIntercom = () => {
    const { pathname } = useLocation();
    const currentUser = useAppSelector((store) => store.auth.currentUser);
    const isProduction = process.env["NODE_ENV"] === "production";

    useEffect(() => {
        if (!isProduction) return;
        if (currentUser?.id) {
            const { email, id, username } = currentUser;

            // @ts-ignore
            window.Intercom("boot", {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                email,
                name: username,
                user_id: id,
                custom_launcher_selector: "#launch-intercom",
            });
        }
    }, [currentUser, isProduction]);

    useEffect(() => {
        if (!isProduction) return;

        // @ts-ignore
        window.Intercom("update", { last_request_at: new Date().toISOString() });
    }, [isProduction, pathname]);
};
