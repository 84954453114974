/** @jsxImportSource @emotion/react */

import {
    ComponentProps,
    FormEvent,
    HTMLProps,
    PropsWithChildren,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import tw from "twin.macro";
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import Icon from "components/atoms/icons/Icon";
import * as Collapsible from "@radix-ui/react-collapsible";
import Popover from "components/atoms/popover/Popover";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { PopoverProps } from "@radix-ui/react-popover";
import { Button } from "components/editor/components";
import IconButton from "components/atoms/icon-button/IconButton";
import { useDebounce } from "react-use";
import { HiPlusSmall } from "react-icons/hi2";
import classNames from "classnames";
import { LuTrash2 } from "react-icons/lu";
import { createPortal } from "react-dom";
import HighlightWords from "components/organisms/HighlightWords";

export enum RowType {
    Volume = "volume",
    Section = "section",
    SubSection = "subSection",
    Requirement = "requirement",
}

interface Props extends HTMLProps<HTMLDivElement> {
    isDragDisabled?: boolean;
    isDraft?: boolean;
    withinPortal?: boolean;
    isCombineEnabled?: boolean;
    hidden?: boolean;
    isSelected?: boolean;
    editable?: boolean;
    collapsible?: boolean;
    index: number;
    draggableId: string;
    type: RowType;
    isEditActive?: boolean;
    clamp?: boolean;
    title: string;
    onOpenEdit?: () => void;
    onCloseEdit?: (e?: FormEvent<HTMLFormElement>) => void;
    onEdit?: (title: string) => void;
    onDelete?: () => void;
    footer?: ReactNode;
    actionsButtons?: ReactNode;
    rowActions?: ReactNode;
    onRowSelected?: () => void;
    tooltip?: Partial<{
        drag: ReactNode;
        props: Partial<ComponentProps<typeof Tooltip>>;
    }>;
    popover?: Partial<{
        drag: ReactNode;
        props: PopoverProps;
    }>;
    searchTerm?: string;
}

const TemplateRow = ({ ...props }: PropsWithChildren<Props>) => {
    return (
        <Draggable draggableId={props.draggableId} index={props.index} isDragDisabled={props.isDragDisabled}>
            {(provided, snapshot) => {
                return <InnerItem {...props} provided={provided} snapshot={snapshot} />;
            }}
        </Draggable>
    );
};

export default TemplateRow;

export const InnerItem = ({
    isDraft,
    isCombineEnabled,
    hidden,
    isSelected,
    editable,
    collapsible,
    index,
    draggableId,
    type,
    onEdit,
    onDelete,
    onCloseEdit,
    onOpenEdit,
    title,
    isEditActive,
    children,
    footer,
    actionsButtons,
    onRowSelected,
    tooltip,
    popover,
    clamp,
    rowActions,
    provided,
    snapshot,
    className,
    withinPortal,
    isDragDisabled,
    searchTerm,
    ...props
}: Props & { provided: DraggableProvided; snapshot: DraggableStateSnapshot }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState({
        visible: false,
        open: false,
    });
    const [rowTitle, setRowTitle] = useState(title);
    const [isOpen, setIsOpen] = useState(type === RowType.Volume);
    const isVolume = type === RowType.Volume;
    const isRequirement = type === RowType.Requirement;
    const isSection = type === RowType.Section;
    const isSubSection = type === RowType.SubSection;

    useLayoutEffect(() => {
        if (clamp && contentRef.current) {
            if ((contentRef.current?.clientHeight || 0) >= 160) setShowMore({ visible: true, open: false });
        }
    }, [clamp]);

    const firstUpdate = useRef(true);
    useDebounce(
        () => {
            if (rowTitle !== title && !firstUpdate.current && editable) {
                onEdit?.(rowTitle);
            }
            firstUpdate.current = false;
        },
        200,
        [rowTitle]
    );

    let portal = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (!withinPortal) return;
        portal.current = document.createElement("div");
        document.body.appendChild(portal.current);
        return () => {
            if (portal.current) document.body.removeChild(portal.current);
        };
    }, [withinPortal]);

    const child = (
        <Collapsible.Root
            disabled={isEditActive}
            open={isOpen}
            onOpenChange={(o) => {
                if (isEditActive) return;
                setIsOpen(o);
            }}
            css={[hidden && tw`opacity-0`]}
        >
            <div
                {...provided.dragHandleProps}
                {...provided.draggableProps}
                style={provided.draggableProps.style}
                ref={provided.innerRef}
                className={classNames([
                    "!outline-0 flex flex-col w-full pb-2 !cursor-default active:cursor-grabbing",
                    className,
                ])}
                css={[isSubSection && tw`pl-12`]}
                onMouseDown={(e) => {
                    // react-beautiful-dnd removes focus of children
                    // e.currentTarget.focus();
                }}
                {...props}
            >
                <div className="flex items-center gap-2.5 w-full cursor-default">
                    <div className="flex items-center gap-2.5 w-full cursor-default">
                        <div className="flex flex-col items-center">
                            <Popover
                                open={!popover?.drag ? false : undefined}
                                content={popover?.drag}
                                {...popover?.props}
                            >
                                <div>
                                    <Tooltip
                                        content={
                                            tooltip?.drag || (
                                                <div>
                                                    <span className="text-xs font-semibold text-gray-800">Drag</span>
                                                    <span className="text-xs font-normal text-gray-800"> to move</span>
                                                </div>
                                            )
                                        }
                                        {...tooltip?.props}
                                    >
                                        <div
                                            className="flex items-center justify-center p-1 duration-150 bg-transparent rounded-md cursor-grab hover:bg-slate-100 active:bg-slate-200 active:cursor-grabbing"
                                            css={[isDragDisabled && tw`!cursor-no-drop !bg-slate-200 opacity-50`]}
                                        >
                                            <Icon name="Draggable" className="text-gray-lightest" />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Popover>
                            {actionsButtons}
                        </div>
                        <div
                            className="cursor-pointer relative group flex flex-col justify-center flex-1 min-h-[54px] bg-slate-50 text-stone-900 text-xl font-medium rounded-md overflow-hidden"
                            css={[
                                (isSection || isSubSection) &&
                                    tw`text-base font-normal text-gray-500 bg-slate-100 outline-transparent`,
                                isRequirement &&
                                    tw`cursor-grab bg-gray-50 rounded-lg border! border-zinc-200! outline-none text-sm font-normal min-h-[46px]`,
                                isRequirement && isDragDisabled && tw`cursor-default`,
                                isRequirement && isEditActive && tw`cursor-text`,
                                isVolume && tw`outline-offset-[-1px] outline outline-1 outline-sky-100`,
                                isDraft && (!collapsible || isEditActive) && tw`cursor-default`,
                                isDraft && isVolume && tw`!text-lg`,
                                isDraft && isSection && tw`!text-sm`,
                                isDraft && isVolume && !isEditActive && tw`duration-150 hover:bg-slate-100`,
                            ]}
                            onClick={() => {
                                if (isDraft && isEditActive) return;
                                setIsOpen(!isOpen);
                            }}
                        >
                            <div
                                className="flex items-center justify-between gap-4 px-4 py-3"
                                css={[isRequirement && tw`p-2.5 pr-7`]}
                            >
                                {isEditActive && editable ? (
                                    <input
                                        type="text"
                                        className="z-[1] bg-transparent focus:outline-none border-0 rounded-0 focus:ring-0 ring-0 w-full pr-2"
                                        placeholder=""
                                        value={rowTitle}
                                        onChange={(e) => setRowTitle(e.target.value)}
                                        autoFocus={isEditActive}
                                        onBlur={() => onCloseEdit?.()}
                                        onKeyDown={(e) => e.code === "Enter" && onCloseEdit?.()}
                                    />
                                ) : (
                                    <div className="flex items-center gap-2">
                                        <div className="">
                                            <div
                                                ref={contentRef}
                                                className="flex items-center gap-2 z-[1]"
                                                css={[
                                                    clamp && !showMore.open && tw`line-clamp-8`,
                                                    { wordBreak: "break-word" },
                                                    isDraft && tw`inline leading-7`,
                                                ]}
                                            >
                                                {searchTerm?.trim() ? (
                                                    <HighlightWords text={title} highlight={searchTerm} />
                                                ) : (
                                                    title
                                                )}
                                                <div
                                                    className="flex items-center gap-0.5"
                                                    css={[isDraft && tw`inline ml-1`]}
                                                >
                                                    {editable && (
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onOpenEdit?.();
                                                            }}
                                                            className="align-middle relative z-[1] inline-flex justify-center items-center cursor-pointer select-none opacity-0 group-hover:opacity-100"
                                                            role="button"
                                                        >
                                                            <IconButton
                                                                name="Pencil"
                                                                className="!inline-flex p-1 h-6 w-6 min-w-[24px] rounded-md transition-colors duration-100 text-zinc-600 hover:bg-gray-200"
                                                            />
                                                        </div>
                                                    )}
                                                    {rowActions}
                                                </div>
                                            </div>
                                            {showMore.visible && (
                                                <>
                                                    {!showMore.open && (
                                                        <Button
                                                            variant="link"
                                                            onClick={() =>
                                                                setShowMore((prev) => ({
                                                                    ...prev,
                                                                    open: true,
                                                                }))
                                                            }
                                                            className="font-semibold"
                                                        >
                                                            Show more
                                                        </Button>
                                                    )}
                                                    {showMore.open && (
                                                        <Button
                                                            variant="link"
                                                            onClick={() =>
                                                                setShowMore((prev) => ({
                                                                    ...prev,
                                                                    open: false,
                                                                }))
                                                            }
                                                            className="font-semibold"
                                                        >
                                                            Show less
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {(onRowSelected || isCombineEnabled) && !isEditActive && (
                                    <div
                                        className="absolute top-0 bottom-0 right-0 left-0 rounded-md border-transparent border-[1.5px] border-solid duration-100"
                                        css={[
                                            snapshot.combineTargetFor && tw`!border-action`,
                                            !isCombineEnabled && tw`!border-transparent`,
                                            isSelected && tw`!border-action`,
                                        ]}
                                    />
                                )}
                                {isSection && !isOpen && (
                                    <Droppable
                                        direction="horizontal"
                                        droppableId={draggableId}
                                        type="TEMPLATE_REQUIREMENTS"
                                    >
                                        {(providedReq, snapshotReq) => (
                                            <div
                                                {...providedReq.droppableProps}
                                                ref={providedReq.innerRef}
                                                className="outline-offset-[-2px] outline-dashed outline-2 outline-transparent opacity-0 absolute flex p-4 items-center top-0 bottom-0 right-0 left-0 rounded-md duration-100"
                                                css={[
                                                    snapshotReq.isDraggingOver &&
                                                        tw`opacity-100 z-20 bg-white [outline-color: #E5EEF6]`,
                                                ]}
                                            >
                                                <div className="absolute">{title}</div>
                                                {providedReq.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                )}
                                <div className="flex items-center gap-1.5">
                                    {!!onRowSelected && (
                                        <Tooltip
                                            delayDuration={500}
                                            content={
                                                isSelected ? "Click to unselect section" : "Click to select section"
                                            }
                                        >
                                            <button
                                                className="z-[1] flex items-center justify-center h-7 w-7 min-w-[28px] rounded-md transition-colors duration-100 text-gray-500 bg-slate-200 hover:bg-slate-300"
                                                css={[isSelected && tw`bg-action !text-white hover:bg-action-hover`]}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onRowSelected?.();
                                                    if (!isOpen && !isSelected) setIsOpen(true);
                                                }}
                                            >
                                                <HiPlusSmall className="stroke-[0.8] text-md" />
                                            </button>
                                        </Tooltip>
                                    )}
                                    {collapsible && (
                                        <>
                                            {!isDraft && (
                                                <Collapsible.Trigger
                                                    asChild
                                                    onClick={(e) => {
                                                        setIsOpen((prev) => !prev);
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <div tw="cursor-pointer z-[1] px-2 py-1 rounded-md text-gray-500 bg-slate-200 duration-150 hover:bg-slate-300 text-sm font-medium flex items-center whitespace-nowrap gap-1.5">
                                                        <Icon
                                                            name="ChevronDown"
                                                            className="w-4 h-4 duration-150"
                                                            css={[
                                                                { transform: "rotate(90deg)" },
                                                                isOpen && { transform: "rotate(0deg)" },
                                                            ]}
                                                        />
                                                    </div>
                                                </Collapsible.Trigger>
                                            )}
                                            {isDraft && !isEditActive && (
                                                <Icon
                                                    name="ChevronDown"
                                                    className="w-5 h-5 duration-150"
                                                    css={[
                                                        { transform: "rotate(90deg)" },
                                                        isOpen && { transform: "rotate(0deg)" },
                                                    ]}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            {footer}
                        </div>
                    </div>
                    {(isSection || isVolume) && (
                        <div
                            className="min-w-[30px] min-h-[30px] rounded-full flex justify-center items-center cursor-pointer select-none duration-150 hover:bg-slate-100"
                            role="button"
                            onClick={() => onDelete?.()}
                        >
                            <LuTrash2 className="text-slate-500" />
                        </div>
                    )}
                </div>
                {collapsible ? (
                    <Collapsible.Content className="collapsibleContent">
                        <div className="mt-2">{children}</div>
                    </Collapsible.Content>
                ) : (
                    children
                )}
            </div>
        </Collapsible.Root>
    );

    if (snapshot.isDragging && portal.current && withinPortal) {
        return createPortal(child, portal.current);
    }

    return child;
};
