import { Editor } from "@tiptap/react";
import addLink from "Assets/addlink.svg";
import addLinkDisabled from "Assets/add-circle-disabled.svg";
import insert from "Assets/svgs/insert-image.svg";
import upload from "Assets/upload-folder 1.svg";
import { Checkbox } from "components/atoms/checkbox";
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import Tabs from "components/organisms/tabs/Tabs";
import { ACCEPT_MEDIA_TYPES } from "pages/drive/constants";
import { useDropValidator } from "pages/drive/media/hooks";
import { ComponentProps, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoImagesOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import tw from "twin.macro";
import { GraphicCollection } from "../GraphicCollection";
import { useMediaUpload } from "../hooks";

interface Props extends Partial<ComponentProps<typeof Modal>> {
    editor: Editor;
}

export enum TabSlug {
    UPLOAD = 0,
    GRAPHIC_COLLECTION = 1,
}

const InsertImageModal = ({ editor, ...props }: Props) => {
    const { onDrop, isUploadingFile, file, clearFile, addMediaByUploader, addMediaByGraphicCollection } =
        useMediaUpload();
    const { selectedMediaFile } = useSelector((state: any) => state.yjsEditor);
    const [saveContentLibrary, setSaveContentLibrary] = useState(false);
    const dropValidator = useDropValidator();
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        validator: dropValidator,
        accept: ACCEPT_MEDIA_TYPES,
        noClick: false,
        multiple: false,
    });

    const [currentTab, setCurrentTab] = useState(TabSlug.UPLOAD);

    const tabs = [
        {
            slug: TabSlug.UPLOAD,
            name: "Upload",
            icon: <img src={insert} alt="Upload Image" />,
            styles: tw`!pt-4`,
        },
        {
            slug: TabSlug.GRAPHIC_COLLECTION,
            name: "Graphic Collection",
            icon: <IoImagesOutline />,
            styles: tw`!pt-4`,
        },
    ];

    useEffect(() => {
        if (props.open) {
            clearFile();
        }
    }, [props.open]);

    return (
        <Modal
            contentProps={{ css: tw`w-[80vw] max-w-[1200px]` }}
            footerProps={{ css: tw`border-t border-gray-light mt-0 pt-4` }}
            header={
                <div>
                    Add Graphic
                    <Tabs<TabSlug>
                        className="!border-b-0 !gap-6"
                        tabs={tabs}
                        activeTab={tabs[currentTab]}
                        onTabSelect={(tab) => {
                            setCurrentTab(tab.slug);
                        }}
                    />
                </div>
            }
            body={
                <div className="flex h-[70vh] flex-col overflow-y-auto pb-3 px-5">
                    {currentTab == TabSlug.UPLOAD && (
                        <div
                            {...getRootProps()}
                            className="w-full h-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 justify-center flex flex-col items-center rounded-lg bg-[#fafafa]"
                        >
                            {!file ? (
                                <>
                                    <input className="" {...getInputProps()} />
                                    <img className="" src={upload} alt="" />
                                    <span className="font-semibold">Drop or Select File</span>
                                    <span className="text-gray-text">We accept jpeg and png file</span>
                                </>
                            ) : (
                                <img
                                    className="max-w-full max-h-full object-contain"
                                    src={URL.createObjectURL(file)}
                                    alt=""
                                />
                            )}
                        </div>
                    )}
                    {currentTab == TabSlug.GRAPHIC_COLLECTION && <GraphicCollection />}
                </div>
            }
            footer={
                <>
                    <div className="flex justify-end gap-4 mr-2">
                        {currentTab == TabSlug.UPLOAD && (
                            <div className="flex items-center justify-center gap-2 text-gray-text">
                                <Checkbox
                                    disabled={!file}
                                    size="md"
                                    onCheck={() => {
                                        setSaveContentLibrary(!saveContentLibrary);
                                    }}
                                    checked={saveContentLibrary}
                                />
                                Save to Graphic Collection
                            </div>
                        )}
                    </div>
                    <button
                        type="button"
                        className="border border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                        onClick={async () => {
                            props.onOpenChange?.(false);
                        }}
                    >
                        Cancel
                    </button>
                    <Button
                        variant="primary"
                        onClick={async () => {
                            if (currentTab === TabSlug.UPLOAD) {
                                await addMediaByUploader(editor, saveContentLibrary);
                            } else if (currentTab === TabSlug.GRAPHIC_COLLECTION) {
                                await addMediaByGraphicCollection(editor);
                            }
                            props.onOpenChange?.(false);
                        }}
                        size="md"
                        loading={isUploadingFile}
                        disabled={
                            (!file && currentTab === TabSlug.UPLOAD) ||
                            (!selectedMediaFile && currentTab === TabSlug.GRAPHIC_COLLECTION)
                        }
                        className="border-0 bg-action rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                    >
                        <img
                            src={
                                (!file && currentTab === TabSlug.UPLOAD) ||
                                (!selectedMediaFile && currentTab === TabSlug.GRAPHIC_COLLECTION)
                                    ? addLinkDisabled
                                    : addLink
                            }
                            alt="icon"
                            loading="lazy"
                        />
                        Add Graphic
                    </Button>
                </>
            }
            {...props}
        />
    );
};

export default InsertImageModal;
