import * as Y from "yjs";
import LiveblocksProvider from "@liveblocks/yjs";
import { ClientSideSuspense } from "@liveblocks/react";
import Collaboration from "@tiptap/extension-collaboration";
import { useState, useEffect } from "react";
import { Presence, UserMeta } from "components/editor/types";
import { useList, useRoom } from "components/editor/liveblocks.config";
import { Loading } from "components/yjs-editor/components/Loading";
import { EditorView } from "prosemirror-view";
import { useSelector, useDispatch } from "react-redux";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TextEditor } from "components/yjs-editor/components/TextEditor";
import Editor from "components/editor/Editor";
import { ExpandIcon } from "utils/icons";
import { isExpandedAction } from "store/reducers/aiReducerSlice";
import { Avatars } from "components/editor/components/Avatars";
import { Toolbar } from "components/yjs-editor/components/Toolbar";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import { Image } from "@tiptap/extension-image";
import { CustomTaskItem } from "components/yjs-editor/components/CustomTaskItem";
import { isSlateEditorActiveAction } from "store/reducers/yjs-editor-reducer";
import { ContentLibraryImage } from "components/yjs-editor/components/ContentLibrary/ContentLibraryImage";
import { ExtensionFontSize } from "components/yjs-editor/extensions/ExtensionFontSize";
export const EditorToggler = ({ liveCursor, fullscreen }: { liveCursor: boolean; fullscreen?: boolean }) => {
    return (
        <ClientSideSuspense fallback={<Loading />}>
            {() => <GetYJSInfoWithSpecficEditor fullscreen={fullscreen} liveCursor={liveCursor} />}
        </ClientSideSuspense>
    );
};

const GetYJSInfoWithSpecficEditor = ({ liveCursor, fullscreen }: { liveCursor: boolean; fullscreen?: boolean }) => {
    const { workspaceMembers, currentUser } = useSelector((store: any) => store.auth);
    const liveUsersInADoc = workspaceMembers.filter((obj1: { id: string }) => obj1.id === currentUser.id);
    const room = useRoom();
    const [doc, setDoc] = useState<Y.Doc>();
    const [provider, setProvider] = useState<LiveblocksProvider<Presence, Storage, UserMeta, never>>();

    useEffect(() => {
        const yDoc = new Y.Doc();
        const yProvider = new LiveblocksProvider(room, yDoc);
        setProvider(yProvider);
        yProvider.once("synced", (sync: boolean) => {
            if (sync === true) {
                // Yjs content is synchronized and ready
                setProvider(yProvider);
                setDoc(yDoc);
            }
        });

        return () => {
            yDoc?.destroy();
            yProvider?.destroy();
        };
    }, [room]);

    if (!doc || !provider || !liveUsersInADoc) {
        return null;
    }
    return (
        <>
            <ChooseSpecificEditorAfterComp
                roomId={room.id}
                liveCursor={liveCursor}
                doc={doc}
                color={liveUsersInADoc[0].color}
                fullscreen={fullscreen}
            />
        </>
    );
};

const ChooseSpecificEditorAfterComp = ({
    fullscreen,
    doc,
    color,
    liveCursor,
    roomId,
}: {
    doc: Y.Doc;
    color: string;
    fullscreen?: boolean;
    liveCursor: boolean;
    roomId: string;
}) => {
    const blocks = useList("blocks");
    const dispatch = useDispatch();
    const storeEditorOfYJSTipTap = useSelector((state: any) => state.yjsEditor.storeEditorOfYJSTipTap);
    const [isSlateEditorActive, setIsSlateEditorActive] = useState(false);

    useEditor({
        editorProps: {
            attributes: {},
        },
        extensions: [
            StarterKit.configure({
                blockquote: {
                    HTMLAttributes: {
                        class: "tiptap-blockquote",
                    },
                },
                // code: {
                //     HTMLAttributes: {
                //         class: "tiptap-code",
                //     },
                // },
                // codeBlock: {
                //     languageClassPrefix: "language-",
                //     HTMLAttributes: {
                //         class: "tiptap-code-block",
                //         spellcheck: false,
                //     },
                // },
                heading: {
                    levels: [1, 2, 3, 4, 5],
                    HTMLAttributes: {
                        class: "tiptap-heading",
                    },
                },
                // The Collaboration extension comes with its own history handling
                history: false,
                horizontalRule: {
                    HTMLAttributes: {
                        class: "tiptap-hr",
                    },
                },
                listItem: {
                    HTMLAttributes: {
                        class: "tiptap-list-item",
                    },
                },
                orderedList: {
                    HTMLAttributes: {
                        class: "tiptap-ordered-list",
                    },
                },
                paragraph: {
                    HTMLAttributes: {
                        class: "tiptap-paragraph",
                    },
                },
            }),
            Highlight.configure({
                HTMLAttributes: {
                    class: "tiptap-highlight",
                },
            }),
            Image.configure({
                HTMLAttributes: {
                    class: "tiptap-image",
                },
            }),
            ContentLibraryImage,
            //   Link.configure({
            //     HTMLAttributes: {
            //       class: "tiptap-link",
            //     },
            //   }),
            Placeholder.configure({
                placeholder: "Start writing…",
                emptyEditorClass: "tiptap-empty",
            }),
            CustomTaskItem,
            TaskList.configure({
                HTMLAttributes: {
                    class: "tiptap-task-list",
                },
            }),
            TextAlign.configure({
                types: ["heading", "paragraph"],
            }),
            Typography,
            Underline,
            // Register the document with Tiptap
            Collaboration.configure({
                document: doc,
            }),
            TextStyle,
            ExtensionFontSize,
            FontFamily.configure({
                types: ["textStyle"],
            }),
            // CollaborationCursor.configure({
            //     provider: provider,
            //     user: {
            //         name,
            //         color,
            //         picture: "",
            //     },
            // }),
        ],
    });

    const [editorToRender, setEditorToRender] = useState<React.ReactNode | null>(null);

    useEffect(() => {
        const isEmptyDefaultDoc = doc.get("default").toJSON() === "";
        const hasBlocks = blocks && blocks.length > 1;

        if (hasBlocks && isEmptyDefaultDoc) {
            // Render SlateEditor when blocks exist but default doc is empty
            setEditorToRender(<Editor liveCursor={liveCursor} activeRoomId={roomId} />);
            setIsSlateEditorActive(true);
            dispatch(isSlateEditorActiveAction(true));
        } else if (blocks && blocks.length === 1) {
            // Render TiptapEditor when blocks exist and default doc is not empty
            setEditorToRender(
                <TextEditor fullscreen={fullscreen} color={color} liveCursor={liveCursor} roomId={roomId} />
            );
            setIsSlateEditorActive(false);
            dispatch(isSlateEditorActiveAction(false));
        } else if (blocks && blocks.length > 1 && doc.get("default").toJSON() !== "") {
            // Render TiptapEditor when there is only one block and default doc is empty
            setEditorToRender(
                <TextEditor fullscreen={fullscreen} color={color} liveCursor={liveCursor} roomId={roomId} />
            );
            setIsSlateEditorActive(false);
            dispatch(isSlateEditorActiveAction(false));
        }
    }, [blocks, doc, liveCursor, fullscreen]);
    if (blocks == null) {
        return <Loading />;
    }
    return (
        <>
            <ClientSideSuspense fallback={<Loading />}>
                {() => (
                    <>
                        {isSlateEditorActive && !fullscreen && (
                            <span
                                onClick={() => dispatch(isExpandedAction())}
                                role="button"
                                aria-label="Expand"
                                className="cursor-pointer select-none flex pt-3 pl-[0.50rem] pr-[1.25rem] w-max max-w-full"
                            >
                                <ExpandIcon />
                            </span>
                        )}
                        <div className="flex  py-2">
                            <div className="flex">
                                <div className="hidden">
                                    <Avatars />
                                </div>
                            </div>
                            {storeEditorOfYJSTipTap && (
                                <div>
                                    {storeEditorOfYJSTipTap && (
                                        <Toolbar fullscreen={fullscreen} editor={storeEditorOfYJSTipTap} />
                                    )}
                                    {/* <Avatars /> */}
                                </div>
                            )}
                        </div>
                        {editorToRender}
                    </>
                )}
            </ClientSideSuspense>
        </>
    );
};

// Prevents a matchesNode error on hot reloading
EditorView.prototype.updateState = function updateState(state: { plugins: any }) {
    // @ts-ignore
    if (!this.docView) return;
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    this.updateStateInner(state, this.state.plugins != state.plugins);
};
