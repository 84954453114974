import { LiveList, LiveObject } from "@liveblocks/client";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { EMPTY_SHEET } from "const-values/Sheets";
import { useMutation } from "liveblocks.config";
import { updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import { createSheet } from "utils/sheet";

const useSheetOperations = () => {
    const dispatch = useAppDispatch();

    const insertDefaultSheet = useMutation(
        ({ storage }, createdSheetProperties: Partial<Sheet> & Pick<Sheet, "name">, destinationIdx: number) => {
            let liveSheets = storage.get("sheets") as Storage["sheets"];
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

            if (!liveSheets?.length) {
                storage.set("sheets", new LiveList([]));
                liveSheets = storage.get("sheets") as Storage["sheets"];
            }

            const createdSheet = createSheet(createdSheetProperties);

            complianceMatrix?.forEach((row) => {
                const extractionId = row.get("requirement")?.get("extraction_id");
                if (!extractionId) {
                    row.get("requirement")?.set("extraction_id", createdSheet.get("id"));
                }
            });

            liveSheets?.insert(createdSheet, destinationIdx);

            return createdSheet.toImmutable();
        },
        []
    );

    const validateDefaultSheet = useMutation(({ storage }) => {
        const hasDefaultSheet = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.some(
            (row) => !row.get("requirement")?.get("extraction_id")
        );
        if (hasDefaultSheet) insertDefaultSheet({ name: EMPTY_SHEET.name }, 0);
    }, []);

    const updateSheet = useMutation(
        ({ storage }, sheetId: Sheet["id"], sheetProperties: Omit<Partial<Sheet>, "id">) => {
            validateDefaultSheet();

            const liveSheets = storage.get("sheets") as Storage["sheets"];
            const foundSheet = liveSheets?.find((sheet) => sheet.get("id") === sheetId);

            foundSheet?.update(sheetProperties);
        },
        []
    );

    const appendNewSheets = useMutation(({ storage }, newSheets: LiveObject<Sheet>[]) => {
        validateDefaultSheet();

        let liveSheets = storage.get("sheets") as Storage["sheets"];
        if (!liveSheets?.length) {
            storage.set("sheets", new LiveList([]));
            liveSheets = storage.get("sheets") as Storage["sheets"];
        }

        newSheets?.forEach((sheet) => {
            liveSheets?.push(sheet);
        });
    }, []);

    const deleteSheet = useMutation(
        ({ storage }, sheetId: Sheet["id"]) => {
            const liveSheets = storage.get("sheets") as Storage["sheets"];
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

            if (sheetId === EMPTY_SHEET.id) {
                complianceMatrix?.forEach((row) => {
                    const extractionId = row.get("requirement")?.get("extraction_id");
                    if (!extractionId) {
                        const rowIndex = complianceMatrix?.findIndex(
                            (deleteRow) => deleteRow.get("requirement")?.get("id") === row.get("requirement")?.get("id")
                        );

                        dispatch(updateCheckedState({ [row.toImmutable().requirement.id]: false }));
                        complianceMatrix.delete(rowIndex);
                    }
                });

                return;
            }

            const sheetIdx = (liveSheets || [])?.findIndex((sheet) => sheet.get("id") === sheetId);

            if (sheetIdx < 0) return;

            complianceMatrix?.forEach((row) => {
                const extractionId = row.get("requirement")?.get("extraction_id");
                if (extractionId === sheetId) {
                    const rowIndex = complianceMatrix?.findIndex(
                        (deleteRow) => deleteRow.get("requirement")?.get("id") === row.get("requirement")?.get("id")
                    );

                    dispatch(updateCheckedState({ [row.toImmutable().requirement.id]: false }));
                    complianceMatrix.delete(rowIndex);
                }
            });

            liveSheets?.delete(sheetIdx);
        },
        [dispatch]
    );

    return {
        appendNewSheets,
        deleteSheet,
        insertDefaultSheet,
        validateDefaultSheet,
        updateSheet,
    };
};

export default useSheetOperations;
