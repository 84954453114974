import { useEffect, useMemo, useRef, useState } from "react";
import bot from "../../Assets/Gero Labs Logo 256 2.svg";
import fileBtn from "../../Assets/File button.svg";
import sendMsg from "../../Assets/sendmsg.svg";
import pdf from "../../Assets/pdf.svg";
import ClickAwayListener from "../../helpers/ClickAwayListener";
import { useSearchParams } from "react-router-dom";
import Avatar from "../Avatar";
import TypingEffect from "../TypingEffect";
import { sendProposalAssistantChatMessageAPI, getProposalAssistantChatMessagesForSessionAPI } from "api/api";
import { useSelector } from "react-redux";
import { useNotification } from "context/notificationContext";

const initialMessage = {
    role: 1, // Vultron role
    content:
        "Hi, I'm Vultron. I'm here to help you craft an exceptional proposal. I can assist you with locating, structuring, and customizing your content, among many other tasks.",
    relevant_documents: [],
};

const ChatPanel = () => {
    const { currentUser } = useSelector((store) => store.auth);

    const [searchParams] = useSearchParams();
    const chatId = useMemo(() => searchParams.get("chatId")?.toLocaleLowerCase(), [searchParams]);

    const messagesBoxRef = useRef();
    const [conversation, setConversation] = useState([]);
    const [message, setMessage] = useState("");
    const [loadingAIRes, setLoadingAIRes] = useState(false);
    const [loading, setLoading] = useState(true);

    const { setToast } = useNotification();

    const [activeRelDoc, setActiveRelDoc] = useState(null);

    const getSessionChat = () => {
        setConversation([]);
        if (!chatId || chatId.length === 0) return;
        setLoading(true);
        getProposalAssistantChatMessagesForSessionAPI(chatId)
            .then((res) => {
                const chat = res.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setConversation([initialMessage, ...chat]);
            })
            .catch((_) => {
                setToast.error({
                    title: "Oops!",
                    msg: "There was an error fetching propopsal sessions. Please refresh and try again.",
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (chatId && chatId !== "0ds") getSessionChat();
        else setConversation([]);
    }, [chatId]);

    const questionChangeHandler = (event) => setMessage(event.target.value);

    const askAI = (event) => {
        event.preventDefault();
        if (!message) return;

        const data = {
            content: message,
            session_id: chatId,
        };

        setConversation((pS) => [...pS, { content: message, role: 0 }]);
        setMessage("");

        setLoadingAIRes(true);

        sendProposalAssistantChatMessageAPI(data)
            .then((res) => {
                setConversation((pS) => [...pS, res?.data]);
            })
            .catch((err) => {
                setToast.error({
                    title: "Oops!",
                    msg: "Vultron did not respond. Please try sending another message.",
                });
            })
            .finally(() => setLoadingAIRes(false));
    };

    useEffect(() => {
        if (messagesBoxRef?.current) messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
    }, [loadingAIRes, conversation]);

    return (
        <div className="flex-grow sticky top-0 right-0 flex flex-col max-h-screen overflow-hidden px-4">
            <div className="flex-grow sticky top-0 right-0 flex flex-col max-h-screen overflow-hidden">
                {conversation?.length === 0 ? (
                    <div className="relative py-5 flex-grow overflow-auto flex justify-center items-center w-full">
                        {loading ? (
                            <div
                                className="absolute   top-0 left-0 w-full  flex items-center justify-center overflow-hidden rounded-md  rounded-xl box-border"
                                style={styles.loadingOverlay}
                            >
                                <span className="loaderr"></span>
                            </div>
                        ) : (
                            <h1 className="text-sm text-[#5B6B79]">
                                {!chatId || chatId === "0ds" ? "Please select a chat session." : "No messages"}
                            </h1>
                        )}
                    </div>
                ) : (
                    <div className="py-5 flex-grow overflow-auto" ref={messagesBoxRef}>
                        {conversation?.map((msg, i) => (
                            <div
                                className={
                                    msg.role === 1
                                        ? "flex gap-2 py-5 my-2 px-3 bg-[#ececec] rounded-lg items-start"
                                        : "flex gap-2 py-4 my-2 px-3 items-start"
                                }
                                key={i}
                            >
                                {msg.role === 0 ? (
                                    <Avatar
                                        src=""
                                        id={currentUser.username}
                                        alt={currentUser.username}
                                        width={34}
                                        height={34}
                                        colorCode={2}
                                        className={"text-[17px]"}
                                    />
                                ) : (
                                    <Avatar
                                        src={bot}
                                        alt="B"
                                        width={34}
                                        height={34}
                                        bgColor="black"
                                        textColor="white"
                                        className={"text-[17px]"}
                                    />
                                )}
                                <div className="flex flex-col gap-5 pl-4 pt-1">
                                    <pre className="whitespace-pre-wrap">
                                        <span className="text-base">{msg?.content}</span>
                                    </pre>
                                    {msg.role === 1 && msg?.relevant_documents.length > 0 && (
                                        <ClickAwayListener onClickAway={() => setActiveRelDoc(null)} className="">
                                            <div className={`relative`}>
                                                <div
                                                    className="flex gap-2 pb-1 text-action cursor-pointer font-[500]"
                                                    onClick={() => setActiveRelDoc(i)}
                                                >
                                                    <img src={fileBtn} alt="" />
                                                    Searched Documents
                                                </div>
                                                <div
                                                    className={
                                                        activeRelDoc === i
                                                            ? "absolute left-0 top-[100%] text-black bg-white shadow-md flex-col gap-2 p-2 items-center rounded z-[2]"
                                                            : "hidden"
                                                    }
                                                >
                                                    {msg?.relevant_documents.map((doc, i) => (
                                                        <div
                                                            onClick={() => setActiveRelDoc(null)}
                                                            key={i}
                                                            className="flex items-center cursor-pointer select-none p-2 hover:bg-[#fafafa]"
                                                        >
                                                            <img className="mr-2" src={pdf} alt="" width={20} />
                                                            <span className="block truncate line-clamp-1">
                                                                {doc?.name || doc?.id}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    )}
                                </div>
                            </div>
                        ))}
                        {/* typing status */}
                        {loadingAIRes && (
                            <div className={"flex gap-2 my-2 py-7 px-3 bg-[#ececec] rounded-lg items-center"}>
                                <Avatar
                                    src={bot}
                                    alt="B"
                                    width={34}
                                    height={34}
                                    bgColor="black"
                                    textColor="white"
                                    className={"text-[17px]"}
                                />
                                <TypingEffect />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="sticky flex items-center">
                <div className="flex gap-3 w-full relative min-h-[66px]">
                    <form onSubmit={askAI} className="flex gap-3 w-full relative">
                        <div className="flex border-2 w-full bg-white z-[2] rounded-lg p-2">
                            <input
                                type="text"
                                className="w-full px-2 text-gray-text focus:outline-none"
                                placeholder={"I’m here to help you write your proposal."}
                                value={message}
                                onChange={questionChangeHandler}
                            />
                            <button className="pr-2" onClick={askAI}>
                                <img src={sendMsg} alt="" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const styles = {
    loadingOverlay: {
        background: "transparent",
        height: "100%",
        zIndex: 5,
    },
};

export default ChatPanel;
