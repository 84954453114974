import { Editor } from "@tiptap/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { typewrtierDivFinishesTypingAction } from "store/reducers/yjs-editor-reducer";
type Props = {
    text: string;
    editor: Editor;
};

const Typewriter = ({ text, editor }: Props) => {
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        const typingInterval = 8; // Adjust typing speed as needed

        if (index < text?.length) {
            const timer = setTimeout(() => {
                setDisplayText(text.slice(0, index + 1));
                setIndex(index + 1);
            }, typingInterval);

            return () => clearTimeout(timer);
        }
        if (index === text?.length) {
            dispatch(typewrtierDivFinishesTypingAction(true));
        }
        return undefined;
    }, [text, index, dispatch]);

    return <div className="text-sm">{displayText}</div>;
};

export default Typewriter;
