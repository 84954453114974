import { ComponentProps } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SheetTab from "./SheetTab";

const SortableItem = (props: ComponentProps<typeof SheetTab>) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.sheet.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <SheetTab ref={setNodeRef} style={style} withOpacity={isDragging} {...props} {...attributes} {...listeners} />
    );
};

export default SortableItem;
