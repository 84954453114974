/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {
    getProposalPlanDocument,
    deleteProposalPlanDocument,
    createProposalPlanDocument,
    updateProposalPlanDocumentFilterState,
    fetchInputDetails,
} from "api/api";
import React, { useMemo } from "react";
import {
    ListItemProps,
    ProposalPlantPorps,
    TemplateRowThemesWindProps,
    Document,
    DocumentSelectionContentProps,
    Directory,
    WinThemesProps,
    DocumentType,
    InputDetails,
} from "./types";
import docselectionempty from "../../../Assets/docselectionempty.svg";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import commonFile from "../../../Assets/defaultDocument.svg";
import doc from "../../../Assets/doc.svg";
import pdf from "../../../Assets/pdf.svg";
import xls from "../../../Assets/xls.svg";
import rightArrow from "../../../Assets/chevRight.png";
import zip from "../../../Assets/zipNew.svg";
import pptx from "../../../Assets/ppt.svg";
import folderIcon from "../../../Assets/folderIcon.svg";
import addgreyicon from "../../../Assets/addgreyicon.svg";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import Icon from "components/atoms/icons";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import IconButton from "components/atoms/icon-button";
import Tooltip from "components/atoms/tooltip";
import { useDebounce } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { getSingleDirectory } from "api/api";
import { Spinner } from "utils/icons";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useGenerateWinThemes } from "./hooks";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import { useMutation, useStorage } from "liveblocks.config";
import { Storage } from "../CopilotSchemaTypes";
import { LiveList, LiveObject } from "@liveblocks/client";
import { WinTheme } from "../CopilotSchemaImmutableTypes";
import { Button } from "components/editor/components";
import { uniqBy } from "lodash";
import { useNotification } from "context/notificationContext";
import Source from "../../Contract-details/Source";
import SwitchBtn from "components/SwitchBtn";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { getProject } from "store/reducers/projectReducer";
import useDocumentPreview from "hook/useDocumentPreview";
import { HiOutlineDownload } from "react-icons/hi";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

const ProposalPlan = ({ internalContractId }: ProposalPlantPorps) => {
    const defaultInputValue: InputDetails = {
        company_name: "",
        use_default_company_name: false,
        company_description: "",
        use_default_company_description: false,
        client_name: "",
        use_default_client_name: false,
        project_description: "",
        use_default_project_description: false,
        default_company_name: "",
        default_company_description: "",
        default_client_name: "",
        default_project_description: "",
    };
    const [inputDetails, setInputDetails] = useState(defaultInputValue);
    const activeProject = useAppSelector((store) => store.project.activeProject);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [internalContractForceRefresh, setInternalContractForceRefresh] = useState(false);

    const fetchData = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            const response = await fetchInputDetails(internalContractId);
            setInputDetails(response.data);
        } catch (error) {
            console.error("Error fetching project details:", error);
        } finally {
            setIsLoading(false);
            setInternalContractForceRefresh(false);
        }
    }, [internalContractId, isLoading]);

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (internalContractForceRefresh || firstUpdate.current) {
            fetchData();
            firstUpdate.current = false;
        }
    }, [fetchData, internalContractForceRefresh]);

    return (
        <div className="flex flex-col flex-1 -mt-6 px-5 overflow-x-hidden overflow-y-auto relative pb-4">
            <div className="pt-7 -mb-1">
                <div className="font-semibold text-lg mb-1 pl-1">Relevant Documents</div>
                <div className="text-sm text-gray-text mb-3 pl-1">
                    Upload documents such as the solicitation, capture intelligence, meeting notes, and briefings to be
                    referenced in this project.
                </div>
                <Source
                    searchPage={false}
                    contractDetails={null}
                    id={null}
                    internalContractId={internalContractId}
                    internalContractDetails={activeProject}
                    setForceRefresh={() => dispatch(getProject(internalContractId))}
                />
            </div>
            <div>
                <DocumentSelectionContent
                    projectInputs={inputDetails}
                    internalContractId={internalContractId}
                    setInternalContractForceRefresh={setInternalContractForceRefresh}
                />
            </div>
        </div>
    );
};

/* ----------------------Document Selection Content -------------------- */

const DocumentSelectionContent = ({
    projectInputs,
    internalContractId,
    setInternalContractForceRefresh,
}: DocumentSelectionContentProps) => {
    const imgIcon = (type: string) => {
        let img = commonFile;
        switch (type) {
            case "pdf":
            case "application/pdf":
                img = pdf;
                break;
            case "doc":
            case "docx":
            case "application/doc":
            case "application/docx":
                img = doc;
                break;
            case "xls":
            case "xlsx":
            case "application/xls":
            case "application/xlsx":
                img = xls;
                break;
            case "pptx":
            case "potx":
            case "ppsx":
                img = pptx;
                break;
            case "zip":
                img = zip;
                break;
            default:
                img = commonFile;
        }
        return img;
    };
    const {
        rootFiles: allDocs,
        rootSubdirectories: allFolders,
        isLoading,
    } = useAppSelector((state) => state.drive.fileStorage);
    // @ts-ignore
    const { setToast } = useNotification();
    const [incomingDocs, setIncomingDocs] = useState<any[]>([]);
    const [incomingFolders, setIncomingFolders] = useState<any[]>([]);
    const dispatch = useDispatch();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [currentDirectoryName, setCurrentDirectoryName] = useState("");
    const [allSelectedDocuments, setAllSelectedDocuments] = useState<DocumentType[]>([]);
    const [isDocumentFilterOn, setIsDocumentFilterOn] = useState(projectInputs?.use_file_scope || false);

    useEffect(() => {
        if (projectInputs?.use_file_scope) {
            setIsDocumentFilterOn(projectInputs?.use_file_scope);
        }
    }, [projectInputs?.use_file_scope]);

    useEffect(() => {
        if (!allDocs.length && !allFolders.length) {
            dispatch(fetchFileStorage());
        }
    }, [allDocs.length, allFolders.length, dispatch]);

    useEffect(() => {
        if (allDocs) {
            setIncomingDocs(allDocs);
            setCurrentDirectoryName("");
        }
        if (allFolders) {
            setIncomingFolders(allFolders);
        }
    }, [allDocs, allFolders, dispatch]);

    useEffect(() => {
        if (!internalContractId) return;

        const getDocuments = async () => {
            setIsDataLoading(true);
            try {
                const { data } = await getProposalPlanDocument(internalContractId);
                if (data) {
                    setAllSelectedDocuments(data);
                } else {
                    setAllSelectedDocuments([]);
                }
            } catch (err) {
                setToast.error({
                    title: "Unable to retrieve documents",
                    msg: "We were unable to retrieve documents due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            } finally {
                setIsDataLoading(false);
            }
        };

        getDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalContractId]);

    const handleResetSelectedDocuments = () => {
        if (allSelectedDocuments) {
            allSelectedDocuments.forEach((document) => {
                deleteDocument({ id: document.file_id });
            });
        }
    };

    const handleAction = async (
        action: (internalContractId: string, data: any) => Promise<any>,
        data: any,
        onSuccess: (files: DocumentType[]) => void,
        errorMsg: string
    ) => {
        if (!internalContractId) return;
        try {
            const { data: files } = await action(internalContractId, data);
            // if (data.is_directory && !files?.length) return;
            onSuccess?.(files);
        } catch (err) {
            setToast.error({
                title: "Unable to process request",
                msg: errorMsg,
            });
        }
    };

    const deleteDocument = (document: Document) => {
        const data = {
            document_id: document.id,
        };
        handleAction(
            deleteProposalPlanDocument,
            data,
            (files) => setAllSelectedDocuments((prev) => prev.filter((doc) => doc.file_id !== document.id)),
            "There was an issue deleting the document. Please refresh and try again."
        );
    };

    const addDirectory = (directory: Directory, callback: () => void) => {
        const data = {
            is_directory: true,
            target_id: directory.id,
        };
        handleAction(
            createProposalPlanDocument,
            data,
            (files) => {
                setAllSelectedDocuments((prev) => uniqBy([...prev, ...files], "file_id"));
                callback();
            },
            "There was an issue creating the directory. Please refresh and try again."
        );
    };

    const addDocument = ({ id, file_extension_type, name }: any, callback: () => void) => {
        if (allSelectedDocuments.find((doc) => doc.file_id === id)) {
            callback();
            return;
        }

        const data = {
            is_directory: false,
            target_id: id,
        };
        handleAction(
            createProposalPlanDocument,
            data,
            (files) => {
                setAllSelectedDocuments((prev) => uniqBy([...prev, ...files], "file_id"));
                callback();
            },
            "There was an issue creating the document. Please refresh and try again."
        );
    };

    const addAllIncoming = () => {
        incomingDocs.forEach((document) => {
            addDocument(document, () => {});
        });

        incomingFolders.forEach((directory) => {
            addDirectory(directory, () => {});
        });
    };

    const handleDataFromList = (data: any) => {
        if (data) {
            setCurrentDirectoryName(data.current_directory.name);
            setIncomingDocs(data.files);
            setIncomingFolders(data.subdirectories);
        }
    };

    const handleLoadingFromList = (isLoading: boolean) => {
        setIsDataLoading(isLoading);
    };

    const toggleDocumentFilter = (checked: boolean) => {
        setIsDocumentFilterOn(checked);
        updateProposalPlanDocumentFilterState(internalContractId, checked)
            .catch(() => {
                setToast.error({
                    title: "Unable to update document filter",
                    msg: "There was an issue updating the document filter. Please refresh and try again.",
                });
                setIsDocumentFilterOn(!checked);
            })
            .finally(() => setInternalContractForceRefresh(true));
    };

    return (
        <div className="pt-6 pb-7">
            {/* header */}
            <div className="flex">
                <div className="font-semibold text-lg mb-2 pl-1">Content Library</div>
                <Tooltip
                    content={
                        <div>
                            Vultron will reference the selected documents as context for this project. You can manually
                            select and filter the documents used for this project.
                        </div>
                    }
                >
                    <Icon name="InfoCircle" className="mt-1.5 ml-1.5 w-4 h-4" />
                </Tooltip>
            </div>
            <div className="text-gray-text text-sm pb-2 flex items-center gap-[0.2rem]">
                <span className="mr-2.5 pl-1">Use Full Content Library</span>
                <SwitchBtn value={!isDocumentFilterOn} handleChange={() => toggleDocumentFilter(!isDocumentFilterOn)} />
                <span className="mr-2.5 pl-3 text-sm text-gray">
                    {isDocumentFilterOn
                        ? "Vultron will only reference the selected documents as context for this project."
                        : "Vultron will use your entire content library as context for this project."}
                </span>
            </div>
            <div
                className={`w-full flex-grow pt-2 group ${
                    isDocumentFilterOn ? "" : "opacity-50 pointer-events-none overflow-y-auto"
                }`}
            >
                <div className="flex gap-4">
                    <div className="overflow-hidden h-[350px] border border-light w-[60%] rounded-md bg-white shadow flex flex-col">
                        <div className="flex justify-between w-full py-4 px-6">
                            <div className="flex items-center gap-3 text-sm">
                                <button
                                    className="cursor-pointer text-gray-mid font-medium"
                                    onClick={() => {
                                        setIncomingDocs([...allDocs]);
                                        setCurrentDirectoryName("");
                                        setIncomingFolders([...allFolders]);
                                    }}
                                >
                                    Content Library
                                </button>
                                {!!currentDirectoryName && (
                                    <>
                                        <img
                                            src={rightArrow}
                                            alt="icon"
                                            loading="lazy"
                                            className="w-[14.5px] h-[16px]"
                                        />
                                        <div className="truncate">{!isDataLoading && currentDirectoryName}</div>
                                    </>
                                )}
                            </div>
                            <Button
                                variant="link"
                                size="sm"
                                disabled={!incomingDocs.length && !incomingFolders.length}
                                className={`text-small flex items-center gap-1 font-medium cursor-pointer ${
                                    incomingDocs.length && incomingFolders.length ? "text-action" : "text-[#9FA2AA]"
                                }`}
                                onClick={addAllIncoming}
                            >
                                Select All
                            </Button>
                        </div>
                        {isLoading ? (
                            <div className="p-3 my-auto flex items-center justify-center">
                                <Spinner classes="!text-black" />
                            </div>
                        ) : (
                            (!!incomingDocs.length || !!incomingFolders.length) && (
                                <div className="overflow-auto h-full h-[350px] bg-white w-full py-0 px-2">
                                    {incomingFolders.map((item: any, index: number) => (
                                        <>
                                            <ListItem
                                                key={item.id}
                                                item={item}
                                                index={index}
                                                onDataReceived={handleDataFromList}
                                                isDataLoading={handleLoadingFromList}
                                                type="directory"
                                                addType={addDirectory}
                                                imgIcon={imgIcon}
                                                icon={folderIcon}
                                                allSelectedDocuments={allSelectedDocuments}
                                                allNestedFiles={item.all_nested_files}
                                            />
                                            <hr className="border-lightest mx-2" />
                                        </>
                                    ))}
                                    {incomingDocs.map((item: any, index: number) => (
                                        <>
                                            <ListItem
                                                key={item.id}
                                                item={item}
                                                index={index}
                                                type="document"
                                                addType={addDocument}
                                                imgIcon={imgIcon}
                                                allSelectedDocuments={allSelectedDocuments}
                                            />
                                            <hr className="border-lightest mx-2" />
                                        </>
                                    ))}
                                </div>
                            )
                        )}
                        {!incomingDocs.length && !incomingFolders.length && !isLoading && (
                            <div className="bg-white flex-1 w-full flex justify-center items-center flex-col py-7 px-2 gap-3">
                                <img
                                    src={docselectionempty}
                                    alt=""
                                    className="select-none pointer-events-none"
                                    draggable="false"
                                />
                                <span className="text-gray-text text-sm">No documents found.</span>
                            </div>
                        )}
                    </div>
                    {/* second half */}
                    <div className="border w-[40%] flex flex-col h-[350px] border-light shadow rounded-md bg-white overflow-hidden">
                        <div className="flex justify-between p-4 items-center">
                            <div className="text-sm font-medium">
                                Selected Documents ({isDocumentFilterOn ? allSelectedDocuments.length : 0})
                            </div>
                            <Button
                                variant="link"
                                size="sm"
                                disabled={!allSelectedDocuments.length}
                                className="cursor-pointer !text-[#F54040] !p-0"
                                onClick={handleResetSelectedDocuments}
                            >
                                Reset
                            </Button>
                        </div>
                        {allSelectedDocuments.length === 0 ||
                            (!isDocumentFilterOn && (
                                <div className=" bg-white flex-1 w-full flex justify-center items-center flex-col py-7 px-2">
                                    <span className="text-gray-text text-sm">No documents selected.</span>
                                </div>
                            ))}
                        {allSelectedDocuments.length !== 0 && isDocumentFilterOn && (
                            <div className="overflow-auto bg-white max-h-[400px] w-full px-2 pb-2">
                                {allSelectedDocuments.map((item) => (
                                    <>
                                        <SelectedDocumentListItem
                                            key={item.file_id}
                                            onDeleteSelectedDocument={deleteDocument}
                                            imgIcon={imgIcon}
                                            item={item}
                                        />
                                        <hr className="border-lightest mx-2" />
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

/* ---------------- All Docs And Folder List --------------*/
const ListItem = ({
    item,
    onDataReceived,
    icon,
    type,
    addType,
    isDataLoading,
    imgIcon,
    allSelectedDocuments,
    allNestedFiles,
}: ListItemProps) => {
    const [isAddingItems, setIsAddingItems] = useState(false);
    const [docsLength, setDocsLength] = useState(0);
    const { downloadFile } = useDocumentPreview();
    const [isHovered, setIsHovered] = useState(false);

    async function fetchData() {
        if (!isDataLoading) return;
        isDataLoading(true);
        const { data } = await getSingleDirectory(item.id);
        isDataLoading(false);
        onDataReceived(data);
    }

    const isItemAdded = useMemo(() => {
        if (type === "directory") {
            return !!allNestedFiles?.every((file) => allSelectedDocuments?.find(({ file_id }) => file_id === file.id));
        }

        return allSelectedDocuments.find((doc) => doc.file_id === item.id);
    }, [allNestedFiles, allSelectedDocuments, item.id, type]);

    //////// FUNCTION TO GET LENGTH OF FILES TO EVERY FOLDER
    useEffect(() => {
        async function fetchData() {
            if (type === "document") {
                return;
            }
            const { data } = await getSingleDirectory(item.id);
            setDocsLength(data.files.length);
        }
        fetchData();
    }, [item]);

    return (
        <div
            className="group w-full pl-4 pr-6 flex gap-4 items-center justify-between rounded-lg hover:bg-action-lightest"
            onClick={() => {
                if (icon) {
                    fetchData();
                }
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="group flex flex-1 py-4 cursor-pointer overflow-hidden items-center gap-1">
                <img
                    className="h-[24px] w-[24px] object-contain"
                    src={!icon ? imgIcon(item.file_extension_type) : icon}
                    alt="file-icon"
                />

                <p className="text-sm pl-2 text-gray-700 pt-[1px] truncate max-w-full w-max text-left">{item?.name}</p>
                {type === "directory" && (
                    <p className="text-[10px] pt-[3px] text-[#5B6B79] whitespace-nowrap">
                        ({allNestedFiles?.length} Documents)
                    </p>
                )}

                {type === "document" && (
                    <button
                        css={[isHovered && tw`opacity-100`]}
                        onClick={() => downloadFile(item.download_url)}
                        className="opacity-0 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
                    >
                        <HiOutlineDownload />
                    </button>
                )}
            </div>
            <Button
                variant="link"
                size="md"
                className="flex items-center gap-2 text-[14px] font-medium rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                onClick={(event) => {
                    event?.stopPropagation();
                    setIsAddingItems(true);
                    addType(item, () => setIsAddingItems(false));
                }}
                disabled={isItemAdded}
            >
                {isAddingItems ? (
                    <Spinner classes="!text-black" />
                ) : (
                    <>
                        {isItemAdded ? (
                            <>Added</>
                        ) : (
                            <>
                                <span className="text-[18px]">+</span> Add
                            </>
                        )}
                    </>
                )}
            </Button>
        </div>
    );
};

/*----------------- All Selected Document List items*/
const SelectedDocumentListItem: React.FC<{
    item: DocumentType;
    imgIcon: (type: string) => string;
    onDeleteSelectedDocument: (id: Document) => void;
}> = ({ item, imgIcon, onDeleteSelectedDocument }) => {
    return (
        <div
            key={item.file_id}
            className="group w-full px-4 py-3 flex items-center justify-between rounded hover:bg-action-lightest"
        >
            <div className="flex overflow-hidden items-center gap-1">
                <img
                    className={`h-[24px] w-[28px] pr-2 object-contain`}
                    src={imgIcon(item.file_extension_type)}
                    alt="file-icon"
                />

                <p className="text-sm text-gray-700 truncate max-w-full w-max text-left">{item.file_name}</p>
            </div>
            <button
                onClick={() => onDeleteSelectedDocument({ id: item.file_id })}
                className="text-[14px] font-medium text-blue-900 ml-2 rounded opacity-0 p-1 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto hover:bg-action-light"
            >
                <Cross2Icon />
            </button>
        </div>
    );
};

/* ---------------------- Win Themes Content -------------------- */
const WinThemesContent = ({ internalContractId }: WinThemesProps) => {
    const winThemes = (useStorage((root) => root.win_themes) as WinTheme[]) || [];
    const [isGenerating, setIsGenerating] = useState(false);
    const { setToast } = useNotification();
    const trackUserEvent = useTrackUserMetric();

    const [templateRowEditable, setTemplateRowEditable] = useState<string | null>(null);

    // close editing
    const closeEditMode = useCallback((e: FormEvent<HTMLFormElement> | undefined) => {
        if (e) {
            e.preventDefault();
        }
        setTemplateRowEditable(null);
    }, []);

    const onDragEnd = useMutation(({ storage }, result: DropResult) => {
        if (result.type !== "PROPOSAL_PLAN_WIN_THEMES") return;
        const winThemes = storage?.get("win_themes") as Storage["win_themes"];
        const { source, destination } = result;

        if (!destination) {
            return; // The item was dropped outside of a valid drop target
        }

        winThemes?.move(source.index, destination.index);
    }, []);

    // on update win theme
    const updateWinTheme = useMutation(({ storage }, id: string, newTitle: string) => {
        const winThemes = storage?.get("win_themes") as Storage["win_themes"];
        const liveWinTheme = winThemes?.find((winTheme) => winTheme.get("id") === id);

        liveWinTheme?.set("content", newTitle);
    }, []);

    // on add win theme
    const addWinTheme = useMutation(
        ({ storage }, content: string = "") => {
            const winThemes = storage?.get("win_themes") as Storage["win_themes"];
            const id = uuidv4();
            const newWinTheme = new LiveObject({ id, content });

            if (winThemes?.length) {
                winThemes.push(newWinTheme);
            } else {
                storage?.set("win_themes", new LiveList([newWinTheme]));
            }
            if (!content) {
                setTemplateRowEditable(id);
                trackUserEvent("Projects: Empty Win Theme Added", {
                    internal_contract_id: internalContractId,
                });
            }
        },
        [internalContractId]
    );

    // on remove win theme
    const removeWinTheme = useMutation(({ storage }, id: string) => {
        const winThemes = storage?.get("win_themes") as Storage["win_themes"];
        if (!winThemes) return;
        const liveWinThemeIndex = winThemes?.findIndex((winTheme) => winTheme.get("id") === id);

        if (liveWinThemeIndex !== -1) winThemes?.delete(liveWinThemeIndex);
    }, []);

    const generateWinThemes = useGenerateWinThemes({
        onmessage(msg) {
            if (msg.event === "FatalError") {
            }

            if (!!msg.data?.length) {
                addWinTheme(msg.data);

                trackUserEvent("Projects: Win Theme Generated", {
                    word_count: getWordCount(msg.data),
                    internal_contract_id: internalContractId,
                });
            }
        },
        async onopen(response) {
            if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                return; // everything's good
            } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                setToast.error({
                    title: "Unable to generate win themes",
                    msg: "There was an issue generating win themes. Please refresh and try again.",
                });
                setIsGenerating(false);
                // client-side errors are usually non-retriable:
                // throw new FatalError();
            } else {
                // throw new RetriableError();
            }
        },
        onclose() {
            setIsGenerating(false);
            // if the server closes the connection unexpectedly, retry:
            // throw new RetriableError();
        },
        onerror(err) {
            setToast.error({
                title: "Unable to generate win themes",
                msg: "There was an issue generating win themes. Please refresh and try again.",
            });
            setIsGenerating(false);
            if (err instanceof Error) {
                throw err; // rethrow to stop the operation
            } else {
                // do nothing to automatically retry. You can also
                // return a specific retry interval here.
            }
        },
    });

    const buttonText = winThemes.length < 1 ? "Generate win themes" : "Generate more win themes.";
    const buttonSuffix = winThemes.length < 1 ? "to get started." : "";

    return (
        <div className="pt-5">
            {/* header */}
            <div className="text-gray-text text-sm pb-1 flex flex-wrap items-center gap-[0.2rem]">
                Provide the win themes for this proposal.{" "}
                <button
                    onClick={() => {
                        setIsGenerating(true);
                        generateWinThemes(internalContractId);
                    }}
                    className="font-semibold max-w-full text-action text-sm duration-100 hover:text-action-hover"
                >
                    {buttonText}
                </button>
                {buttonSuffix}
            </div>
            <div className="w-full py-2 cursor-default">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" type="PROPOSAL_PLAN_WIN_THEMES">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {winThemes.map((item, index) => (
                                    <TemplateRowThemesWind
                                        index={index}
                                        key={item.id}
                                        onCloseEdit={(e) => closeEditMode(e)}
                                        editable
                                        onEdit={(newTitle) => updateWinTheme(item.id, newTitle)}
                                        onOpenEdit={() => setTemplateRowEditable(`${item.id}`)}
                                        isEditActive={templateRowEditable === item.id}
                                        onDelete={() => {
                                            removeWinTheme(item.id);
                                        }}
                                        draggableId={item.id}
                                        data={item}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    {isGenerating ? (
                        <div className="p-3 my-auto flex items-center justify-center">
                            <Spinner classes="!text-black" />
                        </div>
                    ) : (
                        <div
                            className="w-full flex items-center select-none gap-2.5 cursor-default"
                            role="button"
                            onClick={() => addWinTheme()}
                        >
                            <div className="text-[#5B6B79] p-2 pl-4 flex flex-1 items-center text-sm gap-2 cursor-pointer h-[54px] bg-slate-50 rounded-md border border-sky-100 duration-200 hover:bg-slate-100">
                                <div className="">
                                    <img alt="Plus" className="text-[#9FA2AA]" src={addgreyicon} />
                                </div>
                                Write a new win theme
                            </div>
                        </div>
                    )}
                </DragDropContext>
            </div>
        </div>
    );
};

/*-------------------template row themes win --------------------- */

const TemplateRowThemesWind = ({
    data,
    index,
    editable,
    onOpenEdit,
    onCloseEdit,
    onDelete,
    onEdit,
    isEditActive,
    draggableId,
}: TemplateRowThemesWindProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [rowTitle, setRowTitle] = useState(data.content);

    const firstUpdate = useRef(true);
    useDebounce(
        () => {
            if (rowTitle !== data.content && !firstUpdate.current && editable) {
                onEdit?.(rowTitle);
            }
            firstUpdate.current = false;
        },
        200,
        [rowTitle]
    );
    return (
        <Draggable draggableId={draggableId} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="!outline-0 flex flex-col w-full pb-2 !cursor-default active:cursor-grabbing"
                >
                    <div
                        className="cursor-pointer relative group flex items-center pl-3 py-2 pr-4 justify-center flex-1 min-h-[54px] bg-slate-50 text-stone-900 text-xl font-medium rounded-md overflow-hidden"
                        css={[tw`text-gray-500 bg-slate-100 text-base font-normal outline-transparent`]}
                    >
                        <div className="flex gap-2 items-center">
                            <div className="flex flex-col items-center">
                                <div>
                                    <Tooltip
                                        content={
                                            <div>
                                                <span className="text-gray-800 text-xs font-semibold">Drag</span>
                                                <span className="text-gray-800 text-xs font-normal"> to move</span>
                                            </div>
                                        }
                                    >
                                        <div className="flex cursor-grab justify-center items-center p-1 rounded-md bg-transparent duration-150 hover:bg-slate-200 active:bg-slate-200 active:cursor-grabbing">
                                            <Icon name="Draggable" className="text-gray-lightest" />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full items-center justify-between gap-4 p-2.5 text-sm">
                            {isEditActive && editable ? (
                                <input
                                    type="text"
                                    className="z-[1] w-full bg-transparent focus:outline-none border-0 rounded-0 focus:ring-0 ring-0"
                                    placeholder=""
                                    value={rowTitle}
                                    onChange={(e) => setRowTitle(e.target.value)}
                                    autoFocus={isEditActive}
                                    onBlur={() => onCloseEdit?.()}
                                    onKeyDown={(e) => e.code === "Enter" && onCloseEdit?.()}
                                />
                            ) : (
                                <div
                                    ref={contentRef}
                                    className="flex items-center gap-2 z-[1]"
                                    css={[tw`line-clamp-8`]}
                                >
                                    {data.content}
                                </div>
                            )}
                        </div>

                        {isEditActive && editable ? null : (
                            <div className="flex items-center gap-0.5">
                                {editable && (
                                    <div
                                        className="align-middle relative z-[1] inline-flex justify-center items-center cursor-pointer select-none opacity-0 group-hover:opacity-100"
                                        role="button"
                                    >
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onOpenEdit?.();
                                            }}
                                            name="Pencil"
                                            className="!inline-flex p-1 h-6 w-6 min-w-[24px] rounded-md transition-colors duration-100 text-zinc-600 hover:bg-gray-200"
                                        />
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDelete?.();
                                            }}
                                            name="MinusCircle"
                                            className="!inline-flex p-1 h-6 w-6 min-w-[24px] rounded-md transition-colors duration-100 text-zinc-600 hover:bg-gray-200"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};
export default ProposalPlan;
export { WinThemesContent };
