/** @jsxImportSource @emotion/react */

import IconButton from "components/atoms/icon-button/IconButton";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import AiSmartLight from "components/organisms/ai-smart-light/AiSmartLight";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getWordCount } from "utils/getWordCount";
import { useMutation, useStorage, useUpdateMyPresence } from "liveblocks.config";
import { ComplianceMatrixRow, RequirementStatus } from "../../../components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "../../../components/copilot/CopilotSchemaTypes";
import { Storage as ImmutableStorage } from "../../../components/copilot/CopilotSchemaImmutableTypes";
import { LiveList, shallow } from "@liveblocks/client";
import { useDebounce } from "react-use";
import { AiOption } from "components/organisms/ai-smart-light/utils";
import tw, { theme } from "twin.macro";
import ProposalSectionLoading from "Assets/gifs/proposal-section-loading.gif";
import { IoArrowUp } from "react-icons/io5";
import { HighlightWithinTextarea, Selection } from "react-highlight-within-textarea";
import { HiChevronUp } from "react-icons/hi2";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { useAIRevise } from "../../../components/copilot/hooks";
import { useSearchParams } from "react-router-dom";
import Selections from "../../../components/copilot/Selections";
import { STRICTNESS_TO_META } from "const-values/Draft";
import { toggleStrictnessModal } from "store/reducers/modalsSlice";
import { ResponseTolerance } from "types/Requirement";
import ResponseSources from "pages/draft-section/draft-section-requirements/ResponseSources";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { enqueue } from "store/reducers/requirementsSmartResponseReducer";
import ConfirmModal from "components/ConfirmModal";
import { LuX } from "react-icons/lu";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import useRequirementOperations from "hook/useRequirementOperations";

const LOADING_MSGS = ["Searching for relevant content", "Analyzing data", "Processing content", "Preparing response"];
const INITIAL_LOADING_MSG = LOADING_MSGS[0];

type WrittenResponseProps = {
    complianceMatrixRow: ComplianceMatrixRow;
};

const RequirementResponse = ({ complianceMatrixRow }: WrittenResponseProps) => {
    const { setToast } = useNotification();
    const {
        written_content: responseText,
        requirement,
        requirement_status,
        is_response_generating: isGenerating,
        is_response_in_queue: isInQueue,
        locked: rowLocked,
        locked_actor: lockedActor,
    } = complianceMatrixRow;
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((store) => store.auth?.currentUser);
    const generateResponseQueue = useAppSelector((root) => root.requirementsSmartResponse.generateResponseQueue);
    const positionInQueue = useMemo(
        () => generateResponseQueue.findIndex((item) => item.requirement?.id === requirement.id),
        [generateResponseQueue, requirement.id]
    );
    const { setRequirementStatus } = useRequirementOperations();
    const responseErrorRequirementId = useAppSelector(
        (store) => store.sectionState.requirementsState.responseErrorRequirementId
    );
    const [strictness, setStrictness] = useState<ResponseTolerance>(ResponseTolerance.Strict);
    const [confirmGenerateOpen, setConfirmGenerateOpen] = useState(false);
    const [confirmClearOpen, setConfirmClearOpen] = useState(false);
    const contentRef = useRef<HTMLTextAreaElement | null>(null);
    const containerRef = useRef(null);
    const [searchParams] = useSearchParams();
    const internalContractId = searchParams.get("id");
    const [text, setText] = useState("");
    const [prevText, setPrevText] = useState("");
    const [reviseActive, setReviseActive] = useState(false);
    const [isReplacingText, setIsReplacingText] = useState(false);
    const [selectedTextRange, setSelectedTextRange] = useState<{ startIndex: number; endIndex: number } | undefined>();
    const [selectedText, setSelectedText] = useState<string>("");
    const [reviseFeedback, setReviseFeedback] = useState<string>("");
    const requirementContent = requirement?.content || requirement?.summarized_content || "";
    const winThemes = useStorage(
        (root) => (root.win_themes as ImmutableStorage["win_themes"])?.filter(({ content }) => !!content),
        shallow
    );
    const isLockedInRow = rowLocked || false;
    const [locked, setLocked] = useState<boolean>(false);
    const [loadingMsg, setLoadingMsg] = useState<string>(INITIAL_LOADING_MSG);
    const updateMyPresence = useUpdateMyPresence();
    const isResponseError = responseErrorRequirementId === requirement.id;

    const reviseRef = useRef<HTMLInputElement | null>(null);

    useAnimateLoadingMsg(!!isGenerating, 4000, LOADING_MSGS, (msg) => setLoadingMsg(msg));

    const { isLoading: isRevising, reviseText } = useAIRevise((text) => {
        if (selectedTextRange) {
            setText((prev) => {
                setPrevText(prev);
                const rawValue =
                    prev.substring(0, selectedTextRange.startIndex) +
                    text +
                    prev.substring(selectedTextRange.endIndex, prev.length);
                setWrittenContent(rawValue);
                return rawValue;
            });
        }

        setSelectedTextRange({
            startIndex: selectedTextRange?.startIndex || 0,
            endIndex: text.length + (selectedTextRange?.startIndex || 0),
        });
        setSelectedText(text);
    });

    const setWrittenContent = useMutation(
        ({ storage }, content) => {
            const requirements = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirement = requirements?.find((row) => row.get("requirement")?.get("id") === requirement?.id);
            liveRequirement?.set("written_content", content);
        },
        [requirement?.id]
    );

    const handleResponseChange = useCallback(
        (value: string) => {
            if (isGenerating || isInQueue) return;
            const newText = value;
            setText(newText);
        },
        [isGenerating, isInQueue]
    );
    const handleClearResponseSources = useMutation(
        ({ storage }) => {
            const row = storage
                .get("compliance_matrix") // @ts-ignore
                ?.find((row) => row.get("requirement")?.get("id") === complianceMatrixRow.requirement?.id);

            row?.set("response_sources", new LiveList([]));
        },
        [complianceMatrixRow.requirement?.id]
    );

    const firstUpdate = useRef(true);
    const isStatusTodo = (requirement_status || RequirementStatus.Todo) === RequirementStatus.Todo;

    useDebounce(
        () => {
            if (!isGenerating && !firstUpdate.current && !isInQueue && text !== responseText) {
                setWrittenContent(text);
            }
            firstUpdate.current = false;
        },
        200,
        [text]
    );

    const [selection, setSelection] = useState<undefined | Selection>(new Selection(0));

    const resetSelection = useCallback(() => {
        setSelectedTextRange(undefined);
        setSelectedText("");
        setSelection(new Selection(0));
    }, []);

    useEffect(() => {
        if (locked || isGenerating) setText(responseText || "");
    }, [isGenerating, responseText, locked]);

    useEffect(() => {
        if (!requirement?.id) return;
        setText(responseText || "");
        return () => {
            resetSelection();
            setPrevText("");
            setIsReplacingText(false);
            setReviseActive(false);
            setReviseFeedback("");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement?.id]);

    const wordCount = useMemo(() => getWordCount(text), [text]);
    const isLoading = isGenerating || isInQueue || isRevising || isReplacingText;
    const canGenerate = !!internalContractId && !isLoading && !!requirementContent && !locked && !isLockedInRow;
    const canRevise =
        !isGenerating &&
        !isInQueue &&
        !isReplacingText &&
        !isRevising &&
        !!requirementContent?.trim() &&
        !!reviseFeedback?.trim() &&
        !!selectedText?.trim();

    const range = useMemo(() => {
        if (
            Number.isInteger(selectedTextRange?.startIndex) &&
            Number.isInteger(selectedTextRange?.endIndex) &&
            selectedTextRange?.startIndex !== selectedTextRange?.endIndex
        ) {
            return [selectedTextRange?.startIndex, selectedTextRange?.endIndex];
        }
        return [0, 0];
    }, [selectedTextRange?.endIndex, selectedTextRange?.startIndex]);

    useEffect(() => {
        if (reviseActive) {
            setTimeout(() => reviseRef?.current?.focus(), 200);
        }
    }, [reviseActive]);

    useEffect(() => {
        if (isResponseError)
            setTimeout(() => dispatch(setRequirementsState({ responseErrorRequirementId: null })), 12000);
    }, [dispatch, isResponseError]);

    const enqueueResponse = useMutation(
        ({ storage }) => {
            const row = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.find(
                (row) => row.get("requirement")?.get("id") === complianceMatrixRow.requirement?.id
            );

            const hasEmptyRequirement = !(
                row?.get("requirement").get("content")?.trim() ||
                row?.get("requirement").get("summarized_content")?.trim()
            );

            if (hasEmptyRequirement) {
                setToast.error({ msg: "Please add requirement content to generate a response." });
                return;
            }

            if (!row || row?.get("is_response_in_queue") || row?.get("is_response_generating") || hasEmptyRequirement)
                return;
            row?.update({ is_response_in_queue: true, auto_response_actor: currentUser?.id });

            dispatch(
                enqueue([
                    {
                        ...complianceMatrixRow,
                        ...{ is_response_in_queue: true, auto_response_actor: currentUser?.id },
                        response_tolerance: strictness,
                    },
                ])
            );
        },
        [complianceMatrixRow, dispatch, currentUser, strictness]
    );

    useEffect(() => {
        if (isGenerating || document.activeElement === contentRef.current) {
            updateMyPresence({
                selectedId: `${complianceMatrixRow.requirement.id}-requirement-response`,
                name: currentUser?.username,
            });
        } else {
            updateMyPresence({
                selectedId: null,
            });
        }
    }, [isGenerating, complianceMatrixRow.requirement.id, currentUser?.username, updateMyPresence]);

    useEffect(() => {
        const onVisibileChange = () => {
            if (
                (document.activeElement === contentRef.current && document.visibilityState === "visible") ||
                isGenerating
            ) {
                contentRef.current?.focus();
                updateMyPresence({
                    selectedId: `${complianceMatrixRow.requirement.id}-requirement-response`,
                    name: currentUser?.username,
                });
            }
            if (document.visibilityState === "hidden" && !isGenerating) {
                updateMyPresence({
                    selectedId: null,
                });
            }
        };
        document.addEventListener("visibilitychange", onVisibileChange);
        return () => document.removeEventListener("visibilitychange", onVisibileChange);
    }, [isGenerating, complianceMatrixRow.requirement.id, currentUser?.username, updateMyPresence]);

    const StrictnessIcon = STRICTNESS_TO_META[strictness].icon;
    const shouldShowLoader =
        (!!isGenerating && !complianceMatrixRow.written_content?.trim()) || (!!isInQueue && !isGenerating);

    return (
        <>
            <div ref={containerRef} className="flex flex-col relative mx-1.5 pb-0.5">
                <div className="z-[2] bg-slate-50 border-light border pt-2.5 pb-2.5 px-4 rounded-t-md flex flex-row justify-between overflow-hidden">
                    <div className="text-slate-500 text-xs font-normal mt-0.5">Response</div>
                    {isLockedInRow && (
                        <span className="rounded-[4px] bg-[#DBE0E5] text-[#5B6B79] text-xs px-2 py-0.5">Locked</span>
                    )}
                </div>
                <div className="relative">
                    <div
                        className="flex relative z-[2] items-center justify-between px-4 py-1.5 gap-8 bg-white border-b border-x border-zinc-200"
                        css={[isLoading && tw`pointer-events-none`]}
                    >
                        <div
                            className="flex items-center"
                            onBlur={(e) => {
                                if (reviseActive || e.relatedTarget?.classList?.contains("ai-smartlight-menu")) return;
                                setTimeout(() => updateMyPresence({ selectedId: null }), 100);

                                resetSelection();
                            }}
                        >
                            <div className="text-slate-500 text-xs font-normal">
                                {wordCount || 0} word{(wordCount || 0) !== 1 && "s"}
                            </div>
                            <div className="w-px h-4 ml-2 mr-1 bg-slate-400" />
                            <AiSmartLight
                                dynamicItems={[
                                    {
                                        value: AiOption.Revise,
                                        onSelect: () => {
                                            setReviseActive(true);
                                        },
                                    },
                                ]}
                                container={containerRef.current}
                                isReplacingText={isReplacingText}
                                setIsReplacingText={setIsReplacingText}
                                replaceText={(text) => {
                                    if (selectedTextRange) {
                                        setText((prev) => {
                                            setPrevText(prev);
                                            const rawValue =
                                                prev.substring(0, selectedTextRange.startIndex) +
                                                text +
                                                prev.substring(selectedTextRange.endIndex, prev.length);
                                            setWrittenContent(rawValue);
                                            return rawValue;
                                        });
                                    }

                                    setSelectedTextRange({
                                        startIndex: selectedTextRange?.startIndex || 0,
                                        endIndex: text.length + (selectedTextRange?.startIndex || 0),
                                    });
                                    setSelectedText(text);
                                    setReviseActive(false);
                                    setReviseFeedback("");
                                }}
                                editableRef={contentRef.current}
                                selectedText={selectedText}
                                resetSelection={() => {
                                    contentRef?.current?.blur();
                                    contentRef?.current?.focus();
                                }}
                                isSelected={
                                    !!selectedTextRange && selectedTextRange.startIndex !== selectedTextRange.endIndex
                                }
                            />
                            {prevText && !isReplacingText && (
                                <Tooltip delayDuration={500} content="Undo" contentProps={{ className: "!py-1 !px-2" }}>
                                    <IconButton
                                        name="Undo"
                                        className="text-slate-500 p-1 ml-1 rounded-md duration-150 hover:bg-slate-200"
                                        onClick={() => {
                                            setText(prevText);
                                            setWrittenContent(prevText);
                                            setPrevText("");
                                            resetSelection();
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <div
                            className="flex items-center"
                            onMouseDown={(e) => {
                                resetSelection();
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <button
                                className="text-red-500 text-xs font-normal"
                                css={[
                                    (isLoading || locked || !text.length || isLockedInRow) &&
                                        tw`text-zinc-300 pointer-events-none`,
                                ]}
                                disabled={!text.length || isLockedInRow}
                                onClick={() => {
                                    !!text.length && setConfirmClearOpen(true);
                                }}
                            >
                                Clear
                            </button>
                            <div className="w-px h-4 ml-2 mr-1 bg-slate-400" />
                            <button
                                className="text-xs flex items-center gap-1 text-slate-600 duration-100 hover:bg-slate-100 hover:text-slate-900 rounded px-1 py-1"
                                onClick={() =>
                                    dispatch(
                                        toggleStrictnessModal({
                                            open: true,
                                            initialProps: {
                                                strictness,
                                                onStrictnessSubmit: (val) => setStrictness(val),
                                            },
                                        })
                                    )
                                }
                                disabled={isLoading || locked}
                                css={[(isLoading || locked) && tw`text-zinc-300 pointer-events-none`]}
                            >
                                {<StrictnessIcon className="w-3.5 h-3.5" />}
                                {STRICTNESS_TO_META[strictness].label}
                            </button>
                            <div className="w-px h-4 ml-1 mr-2 bg-slate-400" />

                            <button
                                className="text-action text-xs font-normal flex duration-150 items-center gap-1 hover:text-action-hover"
                                css={[
                                    isLoading && tw`text-action`,
                                    !canGenerate && tw`!text-zinc-300 cursor-not-allowed`,
                                ]}
                                disabled={!canGenerate}
                                onClick={() => {
                                    if (text.trim().length) {
                                        setConfirmGenerateOpen(true);
                                    } else enqueueResponse();
                                }}
                            >
                                <Icon
                                    name="Generate"
                                    css={[
                                        isGenerating && {
                                            animation: "rotateAnimation 0.5s infinite linear",
                                        },
                                    ]}
                                />{" "}
                                {isGenerating ? (
                                    <span>
                                        Generating
                                        <span className="loading-ellipsis" />
                                    </span>
                                ) : !text?.length ? (
                                    "Generate"
                                ) : (
                                    "Regenerate"
                                )}
                            </button>
                        </div>
                    </div>
                    <div
                        className="bg-slate-100 justify-between py-2.5 text-action z-[1] absolute text-xs gap-2 flex items-center px-4 aboslute translate-y-0 left-0 right-0 bottom-0 duration-150"
                        css={[isResponseError && tw`translate-y-full`]}
                    >
                        Vultron could not find any relevant content to generate a response. Please upload more content
                        or lower the sensitivity and try again.
                        <button onClick={() => dispatch(setRequirementsState({ responseErrorRequirementId: null }))}>
                            <LuX className="text-base" />
                        </button>
                    </div>
                </div>
                <div
                    className="pr-2 pl-4 items-center py-0 min-h-0 h-0 duration-150 border-x border-transparent flex gap-2 overflow-hidden"
                    css={[
                        reviseActive && tw`py-3 min-h-[42px] border-gray-lightest border-b`,
                        isRevising && tw`bg-slate-50`,
                    ]}
                >
                    <input
                        readOnly={isRevising}
                        disabled={isRevising}
                        ref={reviseRef}
                        value={reviseFeedback}
                        onChange={(e) => setReviseFeedback(e.target.value)}
                        className="flex-1 resize-none outline-none text-xs py-1 disabled:bg-slate-50"
                        placeholder={
                            isRevising ? "This could take up to 30 seconds..." : "Enter revisions to implement..."
                        }
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && canRevise && internalContractId) {
                                setReviseFeedback("");
                                reviseText({
                                    project_id: internalContractId,
                                    requirement: requirementContent,
                                    previous_response: selectedText || "",
                                    user_feedback: reviseFeedback,
                                    win_themes: winThemes?.map(({ content }) => content) || [],
                                });
                            }
                        }}
                    />
                    <div className="flex gap-1">
                        <button
                            onClick={() => {
                                if (!canRevise || !internalContractId) return;
                                setReviseFeedback("");
                                reviseText({
                                    project_id: internalContractId,
                                    requirement: requirementContent,
                                    previous_response: selectedText || "",
                                    user_feedback: reviseFeedback,
                                    win_themes: winThemes?.map(({ content }) => content) || [],
                                });
                            }}
                            disabled={!canRevise && !isRevising}
                            className="bg-action relative text-sm cursor-pointer flex items-center justify-center text-white w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-action-hover disabled:bg-slate-200 disabled:text-slate-400 disabled:cursor-default"
                            css={[isRevising && tw`pointer-events-none`]}
                        >
                            {isRevising ? <SpinnerCircle className="h-3.5 w-3.5" /> : <IoArrowUp />}
                        </button>
                        <button
                            onClick={() => {
                                setReviseActive(false);
                                setReviseFeedback("");
                            }}
                            disabled={isRevising}
                            className="bg-slate-300 text-sm cursor-pointer flex items-center justify-center text-[16px] text-slate-800 w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-slate-200 disabled:bg-slate-200 disabled:text-slate-400 disabled:cursor-default"
                        >
                            <HiChevronUp />
                        </button>
                    </div>
                </div>
                <div
                    id={`${complianceMatrixRow.requirement.id}-requirement-response`}
                    className="selected-ai text-xs relative border-x border-zinc-200 bg-white w-full cursor-text"
                    css={[
                        {
                            ".public-DraftEditor-content": {
                                minHeight: 284,
                                position: "relative",
                                ...tw`p-4`,
                            },
                            ".public-DraftEditorPlaceholder-root": {
                                position: "absolute",
                                color: theme`colors.slate.400`,
                                ...tw`p-4`,
                            },
                        },
                        isLoading && tw`pointer-events-none`,
                    ]}
                >
                    <Selections
                        id={`${complianceMatrixRow.requirement.id}-requirement-response`}
                        containerProps={{ css: tw`!rounded-md -left-0.5 -right-0.5 -top-0.5 -bottom-0.5` }}
                        nameProps={{ css: tw`top-0.5 left-0.5 right-auto` }}
                        hasActiveOthers={(hasOthers) => hasOthers !== locked && setLocked(hasOthers)}
                    />
                    {shouldShowLoader && (
                        <div className="z-[1] flex justify-center items-center absolute inset-x-0 inset-y-0 bg-[rgba(255,255,255,0.2)] backdrop-blur-sm">
                            <div className="flex flex-col gap-2 items-center">
                                <img className="w-[130px] h-[130px]" src={ProposalSectionLoading} alt="" />
                                <div className="text-center text-gray-darkest">
                                    <div className="text-sm font-medium">
                                        {!!isGenerating ? loadingMsg : "Response in queue"}
                                        {!!isGenerating && <span className="loading-ellipsis" />}
                                    </div>
                                    <div className="text-xs mt-1 text-gray-lightest">
                                        {!!isGenerating
                                            ? "This may take up to a minute. Please stay on this screen"
                                            : `Position in queue: ${positionInQueue + 1}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <HighlightWithinTextarea
                        ref={contentRef}
                        placeholder="Provide writing prompts and guidelines to generate a response…"
                        value={text}
                        readOnly={isLoading || isLockedInRow}
                        selection={selection}
                        onChange={(value, selection) => {
                            const start = selection?.start || 0;
                            const end = selection?.end || 0;

                            if (
                                isLoading ||
                                document.activeElement?.tagName === "BODY" ||
                                document.activeElement?.id === "ai-smart-light-button"
                            ) {
                                return;
                            }
                            setSelection(undefined);
                            handleResponseChange(value);
                            if (isStatusTodo && !!value?.trim()?.length)
                                setRequirementStatus(requirement.id, RequirementStatus.InProgress);
                            setSelectedTextRange({
                                startIndex: start,
                                endIndex: end,
                            });
                            setSelectedText(value.slice(start, end) || "");
                        }}
                        highlight={range}
                        onBlur={(e) => {
                            if (e.relatedTarget?.classList?.contains("ai-smartlight-menu")) return;
                            setTimeout(() => updateMyPresence({ selectedId: null }), 100);

                            resetSelection();
                            e.stopPropagation();
                        }}
                    />
                </div>
                <ResponseSources responseSources={complianceMatrixRow.response_sources} />
            </div>
            <ConfirmModal
                open={confirmGenerateOpen}
                onClose={() => setConfirmGenerateOpen(false)}
                onProceed={(proceed) => {
                    if (proceed) {
                        enqueueResponse();
                        setConfirmGenerateOpen(false);
                    }
                }}
                proceedLabel="Confirm"
                title={`Are you sure you want to ${
                    complianceMatrixRow.response_generated ? "regenerate" : "generate"
                }?`}
                subTitle={
                    <div className="text-stone-900 text-sm font-normal w-full pr-10">
                        This will clear all of the response text.
                    </div>
                }
            />
            <ConfirmModal
                open={confirmClearOpen}
                onClose={() => setConfirmClearOpen(false)}
                onProceed={(proceed) => {
                    if (proceed) {
                        setConfirmClearOpen(false);
                        setText("");
                        setWrittenContent("");
                        handleClearResponseSources();
                    }
                }}
                proceedLabel="Confirm"
                title="Are you sure you want to clear the text?"
                subTitle={
                    <div className="text-stone-900 text-sm font-normal w-full pr-10">
                        This will clear all of the response text.
                    </div>
                }
            />
        </>
    );
};

export default RequirementResponse;
