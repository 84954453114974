import { ComponentProps, useEffect, useState } from "react";
import { Modal } from "../modal";
import { useStorage } from "liveblocks.config";
import { Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { shallow } from "@liveblocks/client";
import { Button } from "components/editor/components";
import { LuSplit } from "react-icons/lu";
import useRequirementOperations from "hook/useRequirementOperations";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import tw from "twin.macro";
import { triggerConfirm } from "../confirm-modal/utils";

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange"> {
    splitRequirementId: string;
    retainSection?: boolean;
}

const SplitModal = ({ splitRequirementId, retainSection, ...props }: Props) => {
    const { splitRequirement } = useRequirementOperations();
    const dispatch = useAppDispatch();
    const [range, setRange] = useState<{ start: number; end: number } | null>(null);
    const complianceMatrixRow = useStorage(
        (storage) =>
            (storage.compliance_matrix as Storage["compliance_matrix"]).find(
                (req) => req.requirement.id === splitRequirementId
            ),
        shallow
    );

    const { written_content } = complianceMatrixRow || {};

    useEffect(() => {
        if (props.open) setRange(null);
    }, [props.open]);

    return (
        <>
            <Modal
                header="Split"
                description="Highlight the text to split into a new requirement."
                contentProps={{ css: tw`max-w-[600px]` }}
                body={
                    <div className="px-6">
                        <div
                            className="text-sm max-h-[50vh] overflow-auto whitespace-pre-wrap bg-stone-100 rounded-md p-2 selection:bg-[#e1d6fd]"
                            onMouseUp={() => {
                                const range = window.getSelection()?.getRangeAt(0);
                                const startPosition = range?.startOffset;
                                const endPosition = range?.endOffset;

                                if (
                                    typeof startPosition === "number" &&
                                    typeof endPosition === "number" &&
                                    startPosition !== endPosition
                                ) {
                                    setRange({ start: startPosition, end: endPosition });
                                } else setRange(null);
                            }}
                        >
                            {complianceMatrixRow?.requirement.content ||
                                complianceMatrixRow?.requirement.summarized_content}
                        </div>
                    </div>
                }
                footer={
                    <>
                        <Button
                            size="md"
                            variant="outline"
                            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                            onClick={() => props.onOpenChange?.(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="md"
                            variant="primary"
                            disabled={!range}
                            onClick={() => {
                                if (range) {
                                    if (!!written_content?.trim().length) {
                                        triggerConfirm({
                                            header: "Are you sure you want to split into a new requirement?",
                                            body: "This action will create a new requirement with empty properties.",
                                        }).then((proceed) => {
                                            if (proceed) {
                                                splitRequirement(splitRequirementId, range);
                                                dispatch(setCheckedState({}));
                                                props.onOpenChange?.(false);
                                            }
                                        });
                                    } else {
                                        splitRequirement(splitRequirementId, range, retainSection);
                                        dispatch(setCheckedState({}));
                                        props.onOpenChange?.(false);
                                    }
                                }
                            }}
                        >
                            <LuSplit className="mr-1" />
                            Split
                        </Button>
                    </>
                }
                {...props}
            />
        </>
    );
};

export default SplitModal;
