import ContractRightContent from "./ContractRightContent";
import { ScreenSpinner } from "utils/icons";
import ContractOpportunity from "./components/ContractOpportunity";
import GeneralInformation from "./components/GeneralInformation";
import ContactInformation from "./components/ContactInformation";

// ------- Detail component for contract -------
const ContractContent = ({
    contractDetails,
    id,
    setContractDetails,
    internalContractId,
    internalContractDetails,
    setInternalContractDetails,
    setForceRefresh,
}) => {
    return (
        <div className="flex px-3 pb-3">
            {((internalContractDetails && Object.keys(internalContractDetails).length === 0) ||
                (contractDetails && Object.keys(contractDetails).length === 0)) && (
                <div className="p-7 top-0 left-0 w-full h-full absolute bg-[#5f55550e] shadow-md z-[2] backdrop-blur-[2px] my-auto flex items-center justify-center">
                    <ScreenSpinner />
                </div>
            )}
            <div className="rounded-lg w-full flex-grow">
                <div className="flex gap-4 w-full">
                    {/* left */}
                    <div className="max-w-[40%] w-full block">
                        <ContractOpportunity contractDetails={contractDetails} />
                        <GeneralInformation contractDetails={contractDetails} />
                        <ContactInformation contractDetails={contractDetails} />
                    </div>
                    {/* right */}
                    <div className="max-w-[60%] w-full block">
                        <ContractRightContent
                            searchPage
                            contractDetails={contractDetails}
                            id={id}
                            setContractDetails={setContractDetails}
                            internalContractId={internalContractId}
                            internalContractDetails={internalContractDetails}
                            setForceRefresh={setForceRefresh}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractContent;
