import { useEffect, useState } from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import RecommendedCard from "./components/RecommendedCard";
import { recommendedCardsData } from "api/api";
import { NoRecommendedState, ScreenSpinner } from "utils/icons";

// tabs data
const tabs = [
    {
        name: "Leads",
        slug: "recommended",
    },
    {
        name: "Bookmarked",
        slug: "bookmarked",
    },
    {
        name: "Archived",
        slug: "archived",
    },
];

// -------------- Recommendation Page ---------------------
const CuratedOpportunities = () => {
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const tabName = searchParams.get("tab")?.toLocaleLowerCase() || "recommended";

    useEffect(() => {
        setLoading(true);
        setCardsData([]);
        recommendedCardsData(tabName)
            .then((res) => {
                setCardsData(res?.data || []);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, [tabName]);
    return (
        <section className="flex flex-col w-full h-full overflow-hidden">
            <div className="p-4 my-3 shadow-sm flex items-center gap-9 bg-white">
                <div className="flex w-max items-center select-none">
                    <h4 className="font-semibold text-[20px]">Recommended</h4>
                    <span className="rounded-[4px] bg-[#DBE0E5] text-[#5B6B79] text-xs font-medium p-1 py-0.5 ml-2">
                        Beta
                    </span>
                </div>
            </div>
            <div className="px-4 w-full">
                <div className="border-b w-full max-h-[46px] flex gap-7">
                    {tabs.map((tab, id) => (
                        <NavLink
                            className={`text-sm font-normal border-b items-center flex text-gray-text cursor-pointer pb-3.5 pt-6 hover:border-action ${
                                tabName === tab.slug ? "border-action text-action" : "border-transparent"
                            }`}
                            key={id}
                            to={`/dashboard/recommended?tab=${tab.slug}`}
                        >
                            {tab.name}
                        </NavLink>
                    ))}
                </div>
            </div>
            <div className="relative flex-grow overflow-auto pt-2">
                {loading ? (
                    <div className="w-full flex justify-center items-center h-full p-5">
                        <ScreenSpinner />
                    </div>
                ) : cardsData?.length > 0 ? (
                    <RecommendedCard tabName={tabName} cardsData={cardsData} setCardsData={setCardsData} />
                ) : (
                    <div className="h-full p-5 w-full flex justify-center items-center flex-col">
                        <NoRecommendedState />
                        <div className="max-w-[449px] mx-auto flex flex-col items-center gap-1 py-3">
                            <span className="text-[#1D2630] text-center text-2xl font-bold leading-8">
                                No{" "}
                                {tabName === "recommended"
                                    ? "recommended"
                                    : tabName === "bookmarked"
                                    ? "bookmarked"
                                    : "archived "}{" "}
                                opportunities
                            </span>
                            <span className="text-[#5B6B79] text-center text-base font-normal leading-6">
                                {tabName === "recommended"
                                    ? " Please upload past proposals and  performances to improve your recommendations."
                                    : tabName === "bookmarked"
                                    ? "Bookmark opportunities to review later."
                                    : "Archive all irrelevant opportunities."}
                            </span>
                        </div>
                        {tabName === "recommended" && (
                            <Link
                                to="/dashboard/library"
                                className="flex w-[177px] my-3 h-[36px] py-2 px-4 justify-center items-center shrink-0 bg-[#2A47AB] rounded-md text-center text-sm font-medium leading-6 text-[#fff]"
                            >
                                Go to Content Library
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default CuratedOpportunities;
