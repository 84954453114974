/** @jsxImportSource @emotion/react */

import { IoIosClose } from "react-icons/io";
import { FileMention, removeAssistantFiles } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch } from "store/storeTypes";
import { getFileIcon } from "utils/getFileIcon";
import "./styles.css";
import tw from "twin.macro";

type Props = {
    file: FileMention;
    idx: number;
    fileMenuOpen: boolean;
    isMultiple?: boolean;
};

const FileChip = ({ file, idx, fileMenuOpen, isMultiple, ...props }: Props) => {
    const { id, name, fileExtensionType } = file;
    const dispatch = useAppDispatch();

    return (
        <div
            className="group relative bg-white cursor-default overflow-hidden flex gap-1.5 items-center pl-1.5 pr-2 py-1 min-w-fit h-7 min-h-[28px] text-xs rounded border border-light shadow-expanded text-gray-600"
            css={[
                isMultiple && {
                    opacity: 0,
                    animation: "fade-in 0.25s ease-in-out forwards",
                    animationDelay: `${idx * 20}ms`,
                    minHeight: 32,
                },
            ]}
            {...props}
        >
            <img src={getFileIcon(fileExtensionType)} alt="" className="w-[14px] h-full" />
            <div className="max-w-[130px] font-medium truncate" css={[isMultiple && tw`min-w-[100px] max-w-[250px]`]}>
                {name}
            </div>
            <button
                onClick={() => dispatch(removeAssistantFiles([id]))}
                className="absolute top-0 bottom-0 px-1 rounded-r-[3px] flex items-center bg-[rgba(215,215,215,0.8)] opacity-0 duration-150 right-0 text-lg group-hover:opacity-100 hover:bg-[rgba(205,205,205,0.9)] active:bg-[rgba(185,185,185,0.9)] [backdrop-filter:blur(1px)]"
            >
                <IoIosClose />
            </button>
        </div>
    );
};

export default FileChip;
