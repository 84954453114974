import Icon from "components/atoms/icons/Icon";
import {
    RequirementCompliance,
    RequirementStatus,
    SectionStatus,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { FC, ReactNode } from "react";
import { theme } from "twin.macro";
import { ResponseTolerance } from "types/Requirement";
import { ReactComponent as Stars } from "Assets/stars.svg";
import { ReactComponent as Atom } from "Assets/atom.svg";
import { ReactComponent as Diamond } from "Assets/diamond.svg";

export const SECTION_STATUS_TO_META: Record<SectionStatus, { label: string; color: string; icon: ReactNode }> = {
    [SectionStatus.Todo]: {
        label: "To Do",
        color: theme`colors.slate.600`,
        icon: <Icon name="StatusTodo" className="text-slate-600" />,
    },
    [SectionStatus.InProgress]: {
        label: "In Progress",
        color: theme`colors.yellow.400`,
        icon: <Icon name="StatusInProgress" className="text-yellow-400" />,
    },
    [SectionStatus.InReview]: {
        label: "In Review",
        color: theme`colors.orange.500`,
        icon: <Icon name="StatusInReview" className="text-orange-500" />,
    },
    [SectionStatus.Done]: {
        label: "Done",
        color: theme`colors.green.500`,
        icon: <Icon name="StatusDone" className="text-green-500" />,
    },
};

export const REQUIREMENT_STATUS_TO_META: Record<RequirementStatus, { label: string; color: string; icon: ReactNode }> =
    {
        [RequirementStatus.Todo]: {
            label: "To Do",
            color: theme`colors.slate.600`,
            icon: <Icon name="StatusTodo" className="text-slate-600" />,
        },
        [RequirementStatus.InProgress]: {
            label: "In Progress",
            color: theme`colors.yellow.400`,
            icon: <Icon name="StatusInProgress" className="text-yellow-400" />,
        },
        [RequirementStatus.InReview]: {
            label: "In Review",
            color: theme`colors.orange.500`,
            icon: <Icon name="StatusInReview" className="text-orange-500" />,
        },
        [RequirementStatus.Done]: {
            label: "Done",
            color: theme`colors.green.500`,
            icon: <Icon name="StatusDone" className="text-green-500" />,
        },
    };

export const COMPLIANCE_TO_META: Record<RequirementCompliance, { label: string; color: string; icon: ReactNode }> = {
    [RequirementCompliance.Empty]: {
        label: "Not Set",
        color: theme`colors.slate.600`,
        icon: <Icon name="EmptyCompliant" className="text-slate-600" />,
    },
    [RequirementCompliance.NotCompliant]: {
        label: "Not Compliant",
        color: theme`colors.red.500`,
        icon: <Icon name="NotCompliant" className="text-red-500" />,
    },
    [RequirementCompliance.Partial]: {
        label: "Partial",
        color: theme`colors.orange.500`,
        icon: <Icon name="PartialCompliant" className="text-orange-500" />,
    },
    [RequirementCompliance.Full]: {
        label: "Full",
        color: theme`colors.indigo.700`,
        icon: <Icon name="FullCompliant" />,
    },
};

export const STRICTNESS_TO_META: Record<ResponseTolerance, { label: string; icon: FC<any>; text: string }> = {
    [ResponseTolerance.Flexible]: {
        label: "Flexible",
        icon: Stars,
        text: "Vultron will always generate a response even when no relevant content was found.",
    },
    [ResponseTolerance.Moderate]: {
        label: "Moderate",
        icon: Atom,
        text: "Vultron will attempt to generate a response from any relevant content found.",
    },
    [ResponseTolerance.Strict]: {
        label: "Strict",
        icon: Diamond,
        text: "Vultron will only generate a response from directly relevant content.",
    },
};
