import { DragEndEvent, DragStartEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { useMutation } from "liveblocks.config";
import { useCallback, useEffect } from "react";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch } from "store/storeTypes";
import { move } from "utils/array";

export const useDrag = () => {
    const dispatch = useAppDispatch();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = useCallback(
        (event: DragStartEvent) => {
            dispatch(setRequirementsState({ activeDragRequirementId: event.active.id }));
        },
        [dispatch]
    );

    const handleDragCancel = useCallback(() => {
        dispatch(setRequirementsState({ activeDragRequirementId: null }));
    }, [dispatch]);

    const handleDragEnd = useMutation(({ storage }, event: DragEndEvent) => {
        const { active, over } = event;

        if (over?.id && active.id !== over.id) {
            const complianceMatrixRows = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveDraggableRow = complianceMatrixRows?.find(
                (row) => row.get("requirement")?.get("id") === active.id
            );
            const sectionId = liveDraggableRow?.get("proposal_reference")?.get("section_id");
            if (!liveDraggableRow || !sectionId) return;

            const rowsInSection = complianceMatrixRows.filter(
                (row) => row?.get("proposal_reference").get("section_id") === sectionId
            );
            const doesNotHaveFullOrdering = rowsInSection.some(
                (row) => typeof row.get("requirement")?.get("section_order") !== "number"
            );
            if (doesNotHaveFullOrdering) {
                rowsInSection.forEach((row, idx) => {
                    const requirement = row?.get("requirement");
                    requirement?.set("section_order", idx);
                });
            }
            rowsInSection.sort(
                (a, b) =>
                    (a.get("requirement")?.get("section_order") || 0) -
                    (b.get("requirement")?.get("section_order") || 0)
            );
            const destinationIndex = rowsInSection?.findIndex((row) => row.get("requirement")?.get("id") === over.id);
            const sourceIndex = rowsInSection?.findIndex((row) => row.get("requirement")?.get("id") === active.id);

            const sortedRows = move([...rowsInSection], sourceIndex, destinationIndex);
            sortedRows.forEach((row, idx) => {
                row.get("requirement")?.set("section_order", idx);
            });
        }

        dispatch(setRequirementsState({ activeDragRequirementId: null }));
    }, []);

    return { sensors, handleDragStart, handleDragEnd, handleDragCancel };
};

export const useCatchResizeObserver = () => {
    useEffect(() => {
        if (process.env["NODE_ENV"] !== "development") return;

        const catchErr = (e: ErrorEvent) => {
            if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");

                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        };
        window.addEventListener("error", catchErr);

        return () => {
            window.removeEventListener("error", catchErr);
        };
    }, []);
};
