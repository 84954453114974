/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import { SOURCE_TYPE_TO_TEXT } from "./utils";
import tw from "twin.macro";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { getFileIcon } from "utils/getFileIcon";
import IconButton from "components/atoms/icon-button/IconButton";
import { useNotification } from "context/notificationContext";
import { toggleSourceModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { Spinner } from "utils/icons";
import { IoMdArrowRoundUp } from "react-icons/io";
import { IoMdArrowRoundDown } from "react-icons/io";
import { usePullSourceContent } from "./hooks";
import { SearchContent } from "store/reducers/copilot/contentSearchReducer";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

type Props = {
    contentItem: SearchContent;
};

const SearchContentCard = ({ contentItem }: Props) => {
    const { setToast } = useNotification();
    const trackUserEvent = useTrackUserMetric();
    const { source_content, content_id, source_type, source_name, source_extension_type, reference_id } = contentItem;
    const { pullContentNorth, pullContentSouth, content } = usePullSourceContent(source_content, content_id);
    const { north, south, sourceContent } = content;
    const contentRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState({ visible: false, open: false });
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        if (contentRef.current) {
            if ((contentRef.current?.clientHeight || 0) >= 63) setShowMore((prev) => ({ ...prev, visible: true }));
        }
    }, [sourceContent]);

    const copyText = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(sourceContent);
            setToast.success({
                msg: "Copied to clipboard",
            });
            trackUserEvent("Search: Copy Button Clicked", {
                word_count: getWordCount(sourceContent),
                reference_id: reference_id,
            });
        } catch (err) {}
    }, [setToast, sourceContent, trackUserEvent]);

    return (
        <div className="flex flex-col bg-gray-100 rounded-md shadow-expanded w-full p-2.5 gap-2.5 border border-gray-light">
            <div className="text-xs">
                <div
                    className="text-stone-800 break-words"
                    ref={contentRef}
                    css={[!showMore.open && tw`line-clamp-4`]}
                    style={{ whiteSpace: "pre-line" }}
                >
                    {content.sourceContent}
                </div>
                {showMore.visible && (
                    <>
                        {!showMore.open && (
                            <button
                                onClick={() =>
                                    setShowMore((prev) => ({
                                        ...prev,
                                        open: true,
                                    }))
                                }
                                className="font-semibold text-xs text-action duration-150 hover:text-action-hover"
                            >
                                Show more
                            </button>
                        )}
                        {showMore.open && (
                            <button
                                onClick={() =>
                                    setShowMore((prev) => ({
                                        ...prev,
                                        open: false,
                                    }))
                                }
                                className="font-semibold text-xs text-action duration-150 hover:text-action-hover"
                            >
                                Show less
                            </button>
                        )}
                    </>
                )}
            </div>
            <div className="flex justify-between">
                <div className="flex items-center text-xs font-medium">
                    <div className="text-action">{SOURCE_TYPE_TO_TEXT[source_type]}</div>
                    <div className="w-px h-4 bg-gray-400 ml-2 mr-1" />
                    <Tooltip
                        content={
                            <div className="flex gap-2 items-center">
                                <div className="w-[27px] min-w-[27px]">
                                    <img
                                        src={getFileIcon(source_extension_type)}
                                        alt=""
                                        className="max-w-full mx-auto"
                                    />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <span className="text-slate-500 text-xs min-w-[110px] max-w-[188px] truncate">
                                        {source_name}
                                    </span>
                                </div>
                            </div>
                        }
                        delayDuration={200}
                    >
                        <button
                            className="text-gray-mid font-medium text-xs flex gap-1 items-center cursor-pointer duration-150 hover:bg-gray-200 hover:text-slate-600 p-1 rounded-md"
                            onClick={() => {
                                dispatch(
                                    toggleSourceModal({
                                        open: true,
                                        initialProps: {
                                            id: reference_id,
                                            sourceContent: content.sourceContent,
                                            extension: source_extension_type,
                                            name: source_name,
                                        },
                                    })
                                );
                            }}
                        >
                            Source <Icon name="NoteFold" className="w-3" />
                        </button>
                    </Tooltip>
                    <div className="w-px h-4 bg-gray-400 mx-1" />
                    <Tooltip
                        content={
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col justify-center">
                                    <span className="text-slate-500 text-xs min-w-[110px] max-w-[288px] truncate">
                                        {!north.id
                                            ? "No more previous content found."
                                            : "Expand to include the previous content."}
                                    </span>
                                </div>
                            </div>
                        }
                        delayDuration={200}
                    >
                        <button
                            className="text-gray-mid font-medium text-xs flex gap-1 items-center cursor-pointer duration-150 p-1 rounded-md hover:bg-gray-200 hover:text-slate-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-slate-300"
                            onClick={async () => {
                                const hasContent = await pullContentNorth();
                                if (hasContent)
                                    setShowMore((prev) => ({
                                        ...prev,
                                        open: true,
                                    }));
                            }}
                            disabled={north.loading || !north.id}
                        >
                            {north.loading ? (
                                <Spinner classes="!text-gray-600 w-3 h-3" />
                            ) : (
                                <IoMdArrowRoundUp className="w-3" />
                            )}
                        </button>
                    </Tooltip>
                    <Tooltip
                        content={
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col justify-center">
                                    <span className="text-slate-500 text-xs min-w-[110px] max-w-[288px] truncate">
                                        {!south.id
                                            ? "No more following content found."
                                            : "Expand to include the following content."}
                                    </span>
                                </div>
                            </div>
                        }
                        delayDuration={200}
                    >
                        <button
                            className="ml-px text-gray-mid font-medium text-xs flex gap-1 items-center cursor-pointer duration-150 p-1 rounded-md hover:bg-gray-200 hover:text-slate-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-slate-300"
                            onClick={async () => {
                                const hasContent = await pullContentSouth();
                                if (hasContent)
                                    setShowMore((prev) => ({
                                        ...prev,
                                        open: true,
                                    }));
                            }}
                            disabled={south.loading || !south.id}
                        >
                            {south.loading ? (
                                <Spinner classes="!text-gray-600 w-3 h-3" />
                            ) : (
                                <IoMdArrowRoundDown className="w-3" />
                            )}
                        </button>
                    </Tooltip>
                </div>
                <IconButton
                    name="Copy"
                    iconProps={{ className: "w-[18px] h-[18px]" }}
                    className="text-slate-500 bg-transparent duration-100 h-7 w-7 rounded-md hover:text-slate-700 hover:bg-gray-200 active:bg-gray-300"
                    onClick={() => copyText()}
                />
            </div>
        </div>
    );
};

export default SearchContentCard;
