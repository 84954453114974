import {
    DragEndEvent,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { useNotification } from "context/notificationContext";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useMutation } from "liveblocks.config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { LuPencil, LuTrash2 } from "react-icons/lu";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    DisplayOption,
    PreviewConfig,
    SectionFormat,
    setPreviewProposalState,
} from "store/reducers/draft/previewProposalReducer";
import { setSectionState } from "store/reducers/draft/sectionReducer";
import { togglePreviewProposalModal } from "store/reducers/modalsSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import copyText from "utils/copyText";
import { useTrackUserMetric } from "utils/metrics";

export const useDrag = () => {
    const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveDragId(event.active.id);
    }, []);

    const handleDragCancel = useCallback(() => {
        setActiveDragId(null);
    }, []);

    const handleDragEnd = useMutation(({ storage }, event: DragEndEvent, volumeId?: string) => {
        const { active, over } = event;

        if (over?.id && active.id !== over.id) {
            const sections = (storage.get("framework") as Storage["framework"])
                .get("volumes")
                ?.find((row) => row.get("id") === volumeId)
                ?.get("sections");

            if (!sections) return;

            const sourceIndex = sections?.findIndex((row) => row.get("id") === active.id);
            const destinationIndex = sections?.findIndex((row) => row.get("id") === over.id);
            if (sourceIndex === -1 || destinationIndex === -1) return;
            sections.move(sourceIndex, destinationIndex);
        }

        setActiveDragId(null);
    }, []);

    return { sensors, handleDragStart, handleDragEnd, handleDragCancel, activeDragId };
};

export const useSectionNavigation = (volume?: Volume) => {
    const [searchParams] = useSearchParams();
    const { sections } = volume || {};
    const { volumeId, sectionId } = useParams();
    const navigate = useNavigate();

    const sectionExists = useMemo(() => !!sections?.find((sec) => sec.id === sectionId), [sectionId, sections]);

    useEffect(() => {
        if (volumeId && !volume) {
            const redirectionPath = `/dashboard/contracts/details?${searchParams.toString()}`;
            navigate(redirectionPath, { replace: true });
        } else if ((sectionId && !sectionExists) || (!!sections?.length && !sectionId)) {
            const firstParentSection = sections?.find((sec) => !sec.parent_id);
            const defaultSectionPath = firstParentSection?.id ? `sections/${firstParentSection.id}/requirements` : ".";
            const redirectionPath = `${defaultSectionPath}?${searchParams.toString()}`;
            navigate(redirectionPath, { replace: true });
        }
    }, [navigate, searchParams, sectionExists, sectionId, sections, volume, volumeId]);
};

export const useDropdownItems = (directionPath: string, sectionId?: string) => {
    const [deleteSectionModal, setDeleteSectionModal] = useState(false);
    const { setToast } = useNotification();
    const link = `${window.location.origin}/dashboard/contracts/details/${directionPath}`;
    const dispatch = useAppDispatch();
    const trackUserEvent = useTrackUserMetric();

    const items = useMemo(
        () => [
            {
                key: 0,
                label: (
                    <div className="flex items-center gap-2 text-xs">
                        <LuPencil className="w-4" /> Edit
                    </div>
                ),
                onSelect: () => dispatch(setSectionState({ sectionEditable: sectionId })),
            },
            {
                key: 1,
                label: (
                    <div className="flex items-center gap-2 text-xs">
                        <AiOutlineLink className="text-base w-4" /> Copy link
                    </div>
                ),
                onSelect: () =>
                    copyText(link, () => {
                        setToast.success({
                            msg: "Copied",
                        });
                        trackUserEvent("Drafts: Section Link Copied", {
                            section_id: String(sectionId),
                        });
                    }),
            },
            {
                key: 2,
                label: (
                    <div className="flex gap-2 text-xs items-center text-red-500">
                        <LuTrash2 className="text-sm w-4" /> Delete
                    </div>
                ),
                onSelect: () => setDeleteSectionModal(true),
            },
        ],
        [link, setToast, dispatch, sectionId]
    );

    return { items, deleteSectionModal, setDeleteSectionModal };
};

export const usePreview = (draft?: Volume) => {
    const { generateRequirementHeading } = useGenerateRequirementHeading();
    const headingsInProgress = useAppSelector((store) => store.previewProposalState.headingsInProgress);
    const initialConfig = useMemo(
        () => ({
            includeSourceDocuments: false,
            sectionFormats:
                draft?.sections?.map((section) => ({
                    id: section.id,
                    displayOption: DisplayOption.Heading,
                    headingStyle: {
                        bold: false,
                        underline: false,
                        italic: false,
                    },
                })) || [],
        }),
        [draft?.sections]
    );
    const dispatch = useAppDispatch();
    const { volumeId } = useParams();
    const [previewProposalConfig, setPreviewProposalConfig] = usePersistedStorage<PreviewConfig>(
        `previewProposalConfig-${volumeId}`,
        initialConfig
    );

    const isInSync = useMemo(
        () =>
            draft?.sections.every((section, idx) => {
                const foundIndex = previewProposalConfig?.sectionFormats.findIndex(
                    (format) => format.id === section.id
                );
                return idx === foundIndex;
            }),
        [draft?.sections, previewProposalConfig?.sectionFormats]
    );

    useEffect(() => {
        if (!isInSync) {
            const sections = draft?.sections;

            const newConfigFormats = sections?.reduce<SectionFormat[]>((acc, section) => {
                const foundFormat = previewProposalConfig?.sectionFormats.find((format) => format.id === section.id);

                if (foundFormat) {
                    return [...acc, foundFormat];
                }

                return [
                    ...acc,
                    {
                        id: section.id,
                        displayOption: DisplayOption.Heading,
                        headingStyle: {
                            bold: false,
                            underline: false,
                            italic: false,
                        },
                    },
                ];
            }, []);

            if (!!previewProposalConfig && !!newConfigFormats?.length)
                setPreviewProposalConfig({ ...previewProposalConfig, sectionFormats: newConfigFormats });
        }
    }, [draft, isInSync, previewProposalConfig, previewProposalConfig?.sectionFormats, setPreviewProposalConfig]);

    const triggerPreview = useMutation(
        ({ storage }) => {
            const immutableRequirements = (
                storage.get("compliance_matrix") as Storage["compliance_matrix"]
            )?.toImmutable();
            const validRequirementsWithoutHeading = immutableRequirements.filter(
                (row) =>
                    !!(row.written_content || row.requirement.content || row.requirement.summarized_content) &&
                    !headingsInProgress.includes(row.requirement.id) &&
                    !row.requirement.skipped &&
                    !row.requirement.generated_heading &&
                    row.proposal_reference.volume_id === volumeId
            );

            if (!!validRequirementsWithoutHeading.length) {
                generateRequirementHeading({
                    requirement_ids: validRequirementsWithoutHeading.map(({ requirement }) => requirement.id),
                });
            }

            dispatch(setPreviewProposalState({ activeDraftId: volumeId }));
            dispatch(togglePreviewProposalModal({ open: true }));
        },
        [dispatch, volumeId, generateRequirementHeading]
    );

    return {
        triggerPreview,
    };
};
