import { ResponseTolerance } from "types/Requirement";
import { SortKey } from "./hooks";
import { FilterOption } from "./utils";
import { ReactComponent as Stars } from "Assets/stars.svg";
import { ReactComponent as Atom } from "Assets/atom.svg";
import { ReactComponent as Diamond } from "Assets/diamond.svg";

export const DEFAULT_DOC = [{ id: null, file_name: "No Document" }];

export const RESPONSE_TOLERANCE_OPTIONS = [
    {
        name: "Flexible",
        icon: Stars,
        value: ResponseTolerance.Flexible,
        text: "Vultron will always generate a response even when no relevant content was found.",
    },
    {
        name: "Moderate",
        icon: Atom,
        value: ResponseTolerance.Moderate,
        text: "Vultron will attempt to generate a response from any relevant content found.",
    },
    {
        name: "Strict",
        icon: Diamond,
        value: ResponseTolerance.Strict,
        text: "Vultron will only generate a response from directly relevant content.",
    },
];

enum ColumnOption {
    "Assignees" = "Assignees",
    "Status" = "Status",
    "Compliance" = "Compliance",
    "ProposalVolume" = "Proposal Volume",
    "ProposalSection" = "Proposal Section",
    "Source" = "Source",
    "Notes" = "Notes",
}

export const COLUMN_OPTIONS = [
    ColumnOption.Assignees,
    ColumnOption.Status,
    ColumnOption.Compliance,
    ColumnOption.ProposalVolume,
    ColumnOption.ProposalSection,
    ColumnOption.Source,
];

export const COLUMN_OPTION_TO_FILTER_KEY = {
    [ColumnOption.Assignees]: FilterOption.Assignees,
    [ColumnOption.Status]: FilterOption.Status,
    [ColumnOption.Compliance]: FilterOption.Compliance,
    [ColumnOption.ProposalVolume]: FilterOption.ProposalVolume,
    [ColumnOption.ProposalSection]: FilterOption.ProposalSection,
    [ColumnOption.Source]: FilterOption.Document,
};

export const SORT_KEY_TO_TEXT = {
    [SortKey.ComplianceStatus]: "Compliance",
    [SortKey.RequirementStatus]: "Status",
    [SortKey.ProposalSection]: "Section",
    [SortKey.ProposalVolume]: "Volume",
    [SortKey.RequirementText]: "Requirement",
};
