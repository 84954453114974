import loadingImg from "../Assets/generating.gif";

// /////////////// Loading Toast ///////////////////
const ExtractingDocumentToast = ({ loadingText, handleClose }) => {
    return (
        <div className="fixed bottom-0 right-0 z-50 bg-white w-96 h-24 border shadow-lg flex mb-6 mr-6">
            <div className="w-8/24 flex items-center justify-center mr-1">
                <img
                    width="80"
                    height="80"
                    className="mx-auto max-w-full object-contain"
                    style={{ maxWidth: "calc(100vh - 15%)" }}
                    src={loadingImg}
                    alt="Loading..."
                />
            </div>
            <div className="w-16/24 flex items-center justify-center text-gray-500">
                <p>{loadingText}</p>
            </div>
            <button
                className="absolute top-1 right-1 rounded hover:bg-gray-100 p-1"
                onClick={() => (handleClose ? handleClose() : null)}
            >
                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    );
};

export default ExtractingDocumentToast;
