import { ComponentProps, memo } from "react";
import MultiDatePicker from "react-multi-date-picker";

interface Props extends ComponentProps<typeof MultiDatePicker> {}

const DatePicker = (props: Props) => {
    return <MultiDatePicker arrow={false} {...props} />;
};

export default memo(DatePicker);
