import { useCallback, useState } from "react";
import axios from "axios";
import { useNotification } from "context/notificationContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
    ExtractMode,
    clearExtractState,
    resetExtractState,
    ExtractionType,
    setExtractState,
} from "store/reducers/extract/ExtractReducer";
import { useLocalStorage } from "hook/useLocalStorage";
import { getAutopilotHealthCheck } from "store/reducers/copilot/AutopilotHealthReducer";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import { createSheet } from "utils/sheet";
import useSheetOperations from "hook/useSheetOperations";
import { useMutation } from "liveblocks.config";
import { Storage } from "../CopilotSchemaTypes";
import { EMPTY_SHEET } from "const-values/Sheets";
import { CopilotPresencePage } from "types/Presence";

type BaseExtractData = {
    ai_detection: boolean;
    keywords: string[];
    requirement_chunk_type: "paragraph" | "sentence";
    extraction_id?: string;
};

export interface TextExtractData extends BaseExtractData {
    text: string;
}

type BaseTemplateExtractData = {
    solicitation_type: string;
    generate_full_template: boolean;
    extraction_id?: string;
};

export interface TextTemplateExtractData extends BaseTemplateExtractData {
    text: string;
}

const useExtract = () => {
    const [loading, setLoading] = useState(false);
    const { setToast } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("id")?.toLocaleLowerCase();
    const dispatch = useAppDispatch();
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { appendNewSheets } = useSheetOperations();
    const {
        selectedDocument,
        keywords,
        aiDetection,
        mode,
        content,
        splitType,
        selectedSheet,
        extractionType,
        templateDocumentType,
        shouldGenerateTemplateWithRequirements,
    } = useAppSelector((state) => state.extractReducer);
    const {
        requirementExtractionModal: { open: requirementExtractionModalOpen },
    } = useAppSelector((state) => state.modals);
    const flags = useFlags();

    const uploadFile = useCallback(
        (file: File | string): Promise<string> => {
            if (typeof file === "string") return Promise.resolve(file);
            let formData = new FormData();
            formData.append("file", file);
            formData.append("internal_upload_type", "0");

            return axios<{ document_id: string }>({
                method: "POST",
                url: "/autopilot/extract/" + projectId + "/upload/document",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localValue}`,
                    Workspace: `Workspace ${workspace_id}`,
                },
            }).then((res) => res.data.document_id);
        },
        [projectId, localValue, workspace_id]
    );

    const triggerDocumentRequirementExtraction = useCallback(
        async (formData: BaseExtractData) => {
            if (loading || !projectId) return;
            setLoading(true);
            try {
                const documentId = await uploadFile(selectedDocument);
                await axios.post("autopilot/extract/requirements/document/v2", {
                    ...formData,
                    document_id: documentId,
                });

                setToast.success({
                    title: "Requirements Extraction Started",
                    msg: "All results will be populated in the requirements tab.",
                });

                dispatch(getAutopilotHealthCheck(projectId));
                if (requirementExtractionModalOpen) {
                    dispatch(toggleRequirementExtractionModal({ open: false }));
                    dispatch(clearExtractState());
                } else dispatch(resetExtractState());

                setSearchParams((prev) => {
                    prev.set("tab", CopilotPresencePage.ComplianceMatrix);
                    return prev;
                });
            } catch (error: any) {
                if (error?.response?.status === 409) {
                    setToast.error({
                        title: "Unable to start extraction",
                        msg: "A requirement extraction for this document is already in progress.",
                    });
                } else {
                    setToast.error({
                        title: "Unable to start extraction",
                        msg: "We were unable to start the extraction due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [
            dispatch,
            loading,
            projectId,
            requirementExtractionModalOpen,
            selectedDocument,
            setSearchParams,
            setToast,
            uploadFile,
        ]
    );

    const triggerTextRequirementExtraction = useCallback(
        async (formData: TextExtractData) => {
            if (loading || !projectId) return;
            setLoading(true);
            try {
                await axios.post(`autopilot/${projectId}/extract/requirements/text/v2`, formData);

                setToast.success({
                    title: "Requirements Extraction Started",
                    msg: "All results will be populated in the requirements tab.",
                });
                dispatch(getAutopilotHealthCheck(projectId));
                dispatch(resetExtractState());
                setSearchParams((prev) => {
                    prev.set("tab", CopilotPresencePage.ComplianceMatrix);
                    return prev;
                });
            } catch (error) {
                setToast.error({
                    title: "Unable to start extraction",
                    msg: "We were unable to start the extraction due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            } finally {
                setLoading(false);
            }
        },
        [dispatch, loading, projectId, setSearchParams, setToast]
    );

    const triggerDocumentTemplateExtraction = useCallback(
        async (formData: BaseTemplateExtractData) => {
            if (loading || !projectId) return;
            setLoading(true);
            try {
                const documentId = await uploadFile(selectedDocument);
                await axios.post("autopilot/extract/template/document", {
                    document_id: documentId,
                    ...formData,
                });

                setToast.success({
                    title: "Proposal Template Extraction Started",
                    msg: "All results will be populated in the draft tab.",
                });

                dispatch(getAutopilotHealthCheck(projectId));
                if (requirementExtractionModalOpen) {
                    dispatch(toggleRequirementExtractionModal({ open: false }));
                    dispatch(clearExtractState());
                } else dispatch(resetExtractState());

                setSearchParams((prev) => {
                    prev.set("tab", CopilotPresencePage.Template);
                    return prev;
                });
            } catch (error: any) {
                if (error.response.status === 409) {
                    setToast.error({
                        title: "Unable to start extraction",
                        msg: "A template extraction for this document is already in progress.",
                    });
                } else {
                    setToast.error({
                        title: "Unable to start extraction",
                        msg: "We were unable to start the extraction due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                }
            } finally {
                setLoading(false);
            }
        },
        [
            dispatch,
            loading,
            projectId,
            requirementExtractionModalOpen,
            selectedDocument,
            setSearchParams,
            setToast,
            uploadFile,
        ]
    );

    const triggerTextTemplateExtraction = useCallback(
        async (formData: TextTemplateExtractData) => {
            if (loading || !projectId) return;
            setLoading(true);
            try {
                await axios.post(`autopilot/${projectId}/extract/template/text`, {
                    ...formData,
                });
                setToast.success({
                    title: "Proposal Template Extraction Started",
                    msg: "All results will be populated in the draft tab.",
                });

                dispatch(getAutopilotHealthCheck(projectId));
                dispatch(resetExtractState());
                setSearchParams((prev) => {
                    prev.set("tab", CopilotPresencePage.Template);
                    return prev;
                });
            } catch (error) {
                setToast.error({
                    title: "Unable to start extraction",
                    msg: "We were unable to start the extraction due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            } finally {
                setLoading(false);
            }
        },
        [dispatch, loading, projectId, setSearchParams, setToast]
    );
    const handleSubmit = useMutation(
        ({ storage }) => {
            if (!extractionType) {
                return;
            }

            if (extractionType === ExtractionType.Requirements && !!selectedSheet) {
                const foundSheet = (storage.get("sheets") as Storage["sheets"])?.find(
                    (sheet) => sheet.get("id") === selectedSheet.id
                );
                dispatch(setExtractState({ selectedSheet: undefined }));
                if (!foundSheet && !!selectedSheet?.id && selectedSheet.id !== EMPTY_SHEET.id) {
                    const createdSheet = createSheet(selectedSheet);
                    appendNewSheets([createdSheet]);
                }

                const baseForm: BaseExtractData = {
                    keywords,
                    ai_detection: aiDetection,
                    requirement_chunk_type: splitType,
                    extraction_id: selectedSheet.id,
                };
                if (mode === ExtractMode.DocumentUpload) {
                    triggerDocumentRequirementExtraction(baseForm);
                }
                if (mode === ExtractMode.CopyPaste) {
                    triggerTextRequirementExtraction({
                        ...baseForm,
                        text: content,
                    });
                }
            }

            if (extractionType === ExtractionType.Template) {
                if (!templateDocumentType || (shouldGenerateTemplateWithRequirements && !selectedSheet?.id)) {
                    return;
                }

                const baseForm: BaseTemplateExtractData = {
                    solicitation_type: templateDocumentType,
                    generate_full_template: shouldGenerateTemplateWithRequirements,
                };

                if (shouldGenerateTemplateWithRequirements && !!selectedSheet) {
                    const foundSheet = (storage.get("sheets") as Storage["sheets"])?.find(
                        (sheet) => sheet.get("id") === selectedSheet.id
                    );
                    dispatch(setExtractState({ selectedSheet: undefined }));
                    if (!foundSheet && !!selectedSheet?.id && selectedSheet.id !== EMPTY_SHEET.id) {
                        const createdSheet = createSheet(selectedSheet);
                        appendNewSheets([createdSheet]);
                    }

                    baseForm.extraction_id = selectedSheet.id;
                }

                if (mode === ExtractMode.DocumentUpload) {
                    triggerDocumentTemplateExtraction(baseForm);
                }
                if (mode === ExtractMode.CopyPaste) {
                    triggerTextTemplateExtraction({
                        ...baseForm,
                        text: content,
                    });
                }
            }
        },
        [
            extractionType,
            selectedSheet,
            appendNewSheets,
            keywords,
            aiDetection,
            splitType,
            mode,
            shouldGenerateTemplateWithRequirements,
            triggerDocumentRequirementExtraction,
            triggerTextRequirementExtraction,
            content,
            templateDocumentType,
            triggerDocumentTemplateExtraction,
            triggerTextTemplateExtraction,
        ]
    );

    return { loading, uploadFile, handleSubmit };
};

export default useExtract;
