import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDropDownOptions, getSaveSearchApi } from "api/api";

export const getAllDrpOptions = createAsyncThunk("utilsSlice/getAllDrpOptions", async () => {
    return getDropDownOptions().then((res) => {
        let data = res.data || [];
        return data;
    });
});

export const getAllSavedSearches = createAsyncThunk("utilsSlice/getAllSavedSearches", async () => {
    return getSaveSearchApi().then((res) => {
        let data = res.data || [];
        return data;
    });
});

const initialState = {
    optionList: [],
    isLoadingUtils: false,
    savedSearches: [],
    isLoadingSavedSearches: false,
};

export const utilsReducerSlice = createSlice({
    name: "utilsSlice",
    initialState,
    reducers: {
        setUtils: (state, action) => {
            state.optionList = action.payload.data;
            state.isLoadingUtils = action.payload.loading;
        },
        setSavedSearches: (state, action) => {
            state.savedSearches = action.payload.data;
            state.isLoadingSavedSearches = action.payload.loading;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDrpOptions.pending, (state) => {
                state.isLoadingUtils = true;
            })
            .addCase(getAllDrpOptions.fulfilled, (state, action) => {
                state.optionList = action.payload || [];
                state.isLoadingUtils = false;
            })
            .addCase(getAllDrpOptions.rejected, (state) => {
                state.isLoadingUtils = false;
            })
            //
            .addCase(getAllSavedSearches.pending, (state) => {
                state.isLoadingSavedSearches = true;
            })
            .addCase(getAllSavedSearches.fulfilled, (state, action) => {
                state.savedSearches = action.payload || [];
                state.isLoadingSavedSearches = false;
            })
            .addCase(getAllSavedSearches.rejected, (state) => {
                state.isLoadingSavedSearches = false;
            });
    },
});

export const { setUtils, setSavedSearches } = utilsReducerSlice.actions;

export default utilsReducerSlice.reducer;
