/** @jsxImportSource @emotion/react */

import { PropsWithChildren, useState } from "react";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import Icon from "components/atoms/icons/Icon";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import "twin.macro";
import { Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";
import { useStorage } from "liveblocks.config";
import { AssigneesIcon, ComplianceBadgeIcon, StatusFlagIcon } from "utils/icons";
import AssigneesPopoverContent from "../assignees-popover-content";
import { xor } from "lodash";
import { useBulkUpdateOperation } from "./hooks";
import RequirementStatusPopoverContent from "../requirement-status-popover-content";
import { styles } from "components/molecules/dropdown-menu/styles";
import CompliancePopoverContent from "../compliance-popover-content/CompliancePopoverContent";
import { useAppDispatch } from "store/storeTypes";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useParams } from "react-router-dom";

export type RequirementsMenubarPopoverProps = {
    contentProps?: DropdownMenu.DropdownMenuContentProps;
};

const RequirementAttributesMenu = ({ children, contentProps }: PropsWithChildren<RequirementsMenubarPopoverProps>) => {
    const volumes = useStorage((storage) => (storage.framework as ImmutableStorage["framework"]).volumes);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const { setComplianceStatus, setRequirementStatus, assignToSection, setAssignees } = useBulkUpdateOperation();
    const dispatch = useAppDispatch();
    const { sectionId } = useParams();

    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className={styles.content} align="start" sideOffset={4} {...contentProps}>
                    <DropdownMenu.Sub>
                        <DropdownMenu.SubTrigger>
                            <div className={styles.itemTrigger}>
                                <div className="flex items-center gap-2">
                                    <AssigneesIcon />
                                    Assign to
                                </div>
                                <div className={styles.chevronRight}>
                                    <ChevronRightIcon />
                                </div>
                            </div>
                        </DropdownMenu.SubTrigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                                <AssigneesPopoverContent
                                    onUserSelect={(userId) => {
                                        const newAssignees = xor(selectedUsers, [userId]);
                                        setSelectedUsers(newAssignees);
                                        setAssignees(newAssignees);
                                    }}
                                    onClearAll={() => {
                                        setSelectedUsers([]);
                                        setAssignees([]);
                                    }}
                                    selectedUsers={selectedUsers}
                                />
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                    <DropdownMenu.Sub>
                        <DropdownMenu.SubTrigger>
                            <div className={styles.itemTrigger}>
                                <div className="flex items-center gap-2">
                                    <StatusFlagIcon />
                                    Status
                                </div>
                                <div className={styles.chevronRight}>
                                    <ChevronRightIcon />
                                </div>
                            </div>
                        </DropdownMenu.SubTrigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                                <RequirementStatusPopoverContent
                                    isolated
                                    onStatusSelect={(newStatus) => {
                                        setRequirementStatus(newStatus);
                                    }}
                                    tw="w-full text-sm"
                                />
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                    <DropdownMenu.Sub>
                        <DropdownMenu.SubTrigger>
                            <div className={styles.itemTrigger}>
                                <div className="flex items-center gap-2">
                                    <ComplianceBadgeIcon />
                                    Compliance
                                </div>
                                <div className={styles.chevronRight}>
                                    <ChevronRightIcon />
                                </div>
                            </div>
                        </DropdownMenu.SubTrigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                                <CompliancePopoverContent
                                    isolated
                                    onStatusSelect={(newStatus) => {
                                        setComplianceStatus(newStatus);
                                    }}
                                    tw="w-full text-sm"
                                />
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                    {!!volumes.length && (
                        <DropdownMenu.Sub>
                            <DropdownMenu.SubTrigger>
                                <div className={styles.itemTrigger}>
                                    <div className="flex items-center gap-2">
                                        <Icon name="Swap" tw="w-5" />
                                        Move to
                                    </div>
                                    <div className={styles.chevronRight}>
                                        <ChevronRightIcon />
                                    </div>
                                </div>
                            </DropdownMenu.SubTrigger>
                            <DropdownMenu.Portal>
                                <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                                    {volumes.map(
                                        (volume) =>
                                            !!volume.sections.length &&
                                            !!volume.title.trim() && (
                                                <DropdownMenu.Sub key={volume.id}>
                                                    <DropdownMenu.SubTrigger className="text-sm">
                                                        <div className={styles.itemTrigger}>
                                                            {volume.title}
                                                            <div className={styles.chevronRight}>
                                                                <ChevronRightIcon />
                                                            </div>
                                                        </div>
                                                    </DropdownMenu.SubTrigger>
                                                    <DropdownMenu.Portal>
                                                        <DropdownMenu.SubContent
                                                            className={styles.subcontent}
                                                            sideOffset={8}
                                                        >
                                                            {volume.sections.map((section) => (
                                                                <DropdownMenu.Item
                                                                    key={section.id}
                                                                    className={styles.item}
                                                                    onSelect={() => {
                                                                        assignToSection(section);
                                                                        if (!!sectionId) dispatch(setCheckedState({}));
                                                                    }}
                                                                >
                                                                    {section.title || (
                                                                        <span className="text-slate-400">
                                                                            Section name...
                                                                        </span>
                                                                    )}
                                                                </DropdownMenu.Item>
                                                            ))}
                                                        </DropdownMenu.SubContent>
                                                    </DropdownMenu.Portal>
                                                </DropdownMenu.Sub>
                                            )
                                    )}
                                </DropdownMenu.SubContent>
                            </DropdownMenu.Portal>
                        </DropdownMenu.Sub>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default RequirementAttributesMenu;
