/** @jsxImportSource @emotion/react */

import { Modal } from "../../organisms/modal";
import tw from "twin.macro";
import { ComponentProps, memo, useMemo } from "react";
import { useAppSelector } from "store/storeTypes";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import PreviewProposalSidebar from "./PreviewProposalSidebar";
import PreviewDocument from "./PreviewDocument";
import { useStorage } from "liveblocks.config";
import { shallow } from "@liveblocks/client";
import { Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { useDetectPathChange } from "./hooks";

export interface PreviewProposalModalProps extends Pick<ComponentProps<typeof Modal>, "open"> {}

const PreviewProposalModal = (props: PreviewProposalModalProps) => {
    useDetectPathChange();
    const activeDraftId = useAppSelector((store) => store.previewProposalState.activeDraftId);
    const volume = useStorage(
        (storage) => (storage.framework as Storage["framework"]).volumes.find((vol) => vol.id === activeDraftId),
        shallow
    );
    const volumeRequirements = useStorage(
        (storage) =>
            (storage.compliance_matrix as Storage["compliance_matrix"]).filter(
                (row) =>
                    !row.requirement.skipped &&
                    (!!row.written_content?.trim() ||
                        (!row.written_content?.trim() &&
                            (!!row.requirement.content ||
                                !!row.requirement.summarized_content ||
                                !!row.requirement.generated_heading))) &&
                    row.proposal_reference.volume_id === activeDraftId
            ),
        shallow
    );
    const { sections } = volume || {};

    const sortedSections = useMemo(() => {
        return (
            sections?.reduce<FormattedSection[]>((acc, section) => {
                if (!section.parent_id) {
                    const subsections = sections.filter(({ parent_id }) => parent_id === section.id);
                    return [...acc, { ...section, subsections }];
                }

                return acc;
            }, []) || []
        );
    }, [sections]);

    // const wordCount = useMemo(
    //     () => getWordCount(volumeRequirements.reduce((acc, cont) => (acc += cont), "")),
    //     [volumeRequirements]
    // );
    // const totalPageCount = Math.round((wordCount / 400 + Number.EPSILON) * 100) / 100;

    return (
        <Modal
            hideClose
            contentProps={{
                onPointerDownOutside: (e) => e.preventDefault(),
                css: tw`p-0 rounded-none !w-screen !h-screen !min-w-[100vw] !min-h-[100vh]`,
            }}
            bodyProps={tw`m-0`}
            body={
                <PanelGroup direction="horizontal">
                    <Panel defaultSize={30} minSize={22} maxSize={44}>
                        <PreviewProposalSidebar
                            // totalPageCount={totalPageCount}
                            volume={volume}
                            sortedSections={sortedSections}
                        />
                    </Panel>
                    <PanelResizeHandle className="z-[1] relative group flex justify-center">
                        <div className="w-px h-full bg-gray-800 delay-300 duration-150 group-hover:bg-slate-600 group-hover:scale-x-[2.5]" />
                    </PanelResizeHandle>
                    <Panel defaultSize={70}>
                        <PreviewDocument
                            sortedSections={sortedSections}
                            volume={volume}
                            volumeRequirements={volumeRequirements}
                        />
                    </Panel>
                </PanelGroup>
            }
            {...props}
        />
    );
};

export default memo(PreviewProposalModal);
