/** @jsxImportSource @emotion/react */

import { CSSObject, Interpolation, Theme } from "@emotion/react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { ReactNode } from "react";
import "twin.macro";

export interface Props extends Dialog.DialogProps {
    header?: ReactNode;
    description?: ReactNode;
    body: ReactNode;
    footer?: ReactNode;
    hideClose?: boolean;
    contentProps?: Dialog.DialogContentProps & { css?: CSSObject };
    footerProps?: { css?: CSSObject };
    bodyProps?: Interpolation<Theme>;
}

const Modal = ({
    hideClose,
    header,
    body,
    description,
    footer,
    children,
    contentProps,
    footerProps,
    bodyProps,
    ...props
}: Props) => (
    <Dialog.Root {...props}>
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
            <Dialog.Overlay className="z-[999] backdrop-blur-[1px] bg-[#363636c4] fixed inset-0 modalContent" />
            <Dialog.Content
                onInteractOutside={(event) => {
                    // @ts-ignore
                    const isToast = event.target?.closest("[data-toast]");
                    if (isToast) event.preventDefault();
                }}
                onOpenAutoFocus={(e) => e.preventDefault()}
                className="flex flex-col z-[999] fixed top-[50%] left-[50%] max-h-[85vh] min-w-[450px] max-w-[550px] translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white pt-5 pb-4 shadow-lg focus:outline-none modalContent"
                {...contentProps}
            >
                {header && (
                    <Dialog.Title className="text-stone-900 select-none text-lg font-semibold px-6 pr-11">
                        {header}
                    </Dialog.Title>
                )}
                {description && (
                    <Dialog.Description className="mt-2 px-6 text-[13px] select-none text-gray-500">
                        {description}
                    </Dialog.Description>
                )}

                <div className="flex flex-col mt-4 flex-1 min-h-0" css={bodyProps}>
                    {body}
                    {footer && (
                        <div
                            className="flex select-none flex-row gap-2 items-center mt-6 justify-end px-4"
                            {...footerProps}
                        >
                            {footer}
                        </div>
                    )}
                </div>
                {!hideClose && (
                    <Dialog.Close asChild>
                        <button className="absolute top-[15px] right-[15px] p-1" aria-label="Close">
                            <Cross2Icon className="w-5 h-5" />
                        </button>
                    </Dialog.Close>
                )}
            </Dialog.Content>
        </Dialog.Portal>
    </Dialog.Root>
);

export default Modal;
