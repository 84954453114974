/** @jsxImportSource @emotion/react */

import { CloseIconBlack, InfoIconWarn } from "utils/icons";
import CustomModal from "./CustomModal";
import { ReactNode } from "react";
import { Button } from "./editor/components";
import tw from "twin.macro";
import Icon from "./atoms/icons/Icon";

// TODO: Refactor onProceed for components using ConfirmModal
// onProceed should be called only on confirm.

type Variant = "error";

type Props = {
    title: ReactNode;
    subTitle: ReactNode;
    open: boolean;
    proceedDisabled?: boolean;
    onClose: (proceed?: boolean) => void;
    onProceed: (proceed: boolean) => void;
    cancelLabel?: ReactNode;
    proceedLabel?: ReactNode;
    loading?: boolean;
    variant?: Variant;
};

const ConfirmModal = ({
    proceedDisabled,
    title,
    subTitle,
    open,
    onClose,
    onProceed,
    cancelLabel,
    proceedLabel,
    loading,
    variant,
}: Props) => {
    return (
        <CustomModal
            isOpen={open}
            onClose={() => {
                onClose();
                onProceed(false);
            }}
            className={"w-[100%] max-w-[477px]"}
            fullScreen={false}
        >
            <div className="px-5 py-6">
                <div className="flex items-start">
                    <span className="mr-2">
                        <Icon
                            name="WarningCircle"
                            className="text-[#E99C26]"
                            css={[variant === "error" && tw`text-red-600`]}
                        />{" "}
                    </span>
                    <p className="text-[16px] font-semibold">{title}</p>
                    <span
                        className="ml-auto p-1 cursor-pointer select-none"
                        role="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                            onProceed(false);
                        }}
                    >
                        <CloseIconBlack />
                    </span>
                </div>
                <div className="flex items-start mt-5 flex-col">
                    <p className="pl-8 text-[15px] font-normal">{subTitle}</p>
                </div>
                <div className="flex items-center justify-end w-full gap-3 mt-6">
                    <Button
                        size="md"
                        variant="outline"
                        className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClose();
                            onProceed(false);
                        }}
                    >
                        {cancelLabel || "Cancel"}
                    </Button>
                    <Button
                        autoFocus
                        loading={loading}
                        size="md"
                        disabled={proceedDisabled}
                        variant="primary"
                        css={[
                            loading && tw`pointer-events-none`,
                            variant === "error" && tw`bg-red-600 hover:bg-red-500`,
                        ]}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClose(true);
                            onProceed(true);
                        }}
                    >
                        {proceedLabel || "Continue"}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );
};

export default ConfirmModal;
