import { HTMLProps, PropsWithChildren } from "react";

// ----------- Custom chip component ------------
const Chip = ({ children, className }: PropsWithChildren<HTMLProps<HTMLDivElement>>) => {
    return (
        <div
            className={`bg-action text-white px-3 py-[8px] rounded-[50px] mr-[3px] text-[13px] ${
                className ? className : ""
            }`}
        >
            {children}
        </div>
    );
};

export default Chip;
