import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import { DropdownMenu, DropdownMenuProps } from "components/molecules/dropdown-menu";
import { setExtractState } from "store/reducers/extract/ExtractReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

type Props<T> = {
    layout?: "dropdown" | "radio";
    dropdownProps?: Omit<DropdownMenuProps<T>, "items" | "multiselect">;
};

const SplitTypeOptions = <T,>({ layout = "radio", dropdownProps }: Props<T>) => {
    const { splitType } = useAppSelector((state) => state.extractReducer);
    const dispatch = useAppDispatch();

    if (layout === "dropdown") {
        return (
            <div className="flex gap-4 items-center">
                <label className="flex gap-1 items-center text-left text-sm font-normal text-day-subtext whitespace-nowrap">
                    Split Method{" "}
                    <Tooltip
                        content={
                            <div>
                                Sentence - Split by sentence creates one row per sentence. <br />
                                Paragraph - Split by paragraph creates one row per paragraph.
                            </div>
                        }
                    >
                        <Icon name="InfoCircle" className="w-4 h-4 text-day-subtext" />
                    </Tooltip>
                </label>
                <DropdownMenu
                    items={[
                        {
                            key: 1,
                            label: "Sentence",
                            selected: splitType === "sentence",
                            value: "sentence",
                            onSelect: () => dispatch(setExtractState({ splitType: "sentence" })),
                        },
                        {
                            key: 2,
                            selected: splitType === "paragraph",
                            label: "Paragraph",
                            value: "paragraph",
                            onSelect: () => dispatch(setExtractState({ splitType: "paragraph" })),
                        },
                    ]}
                    {...dropdownProps}
                >
                    <div className="w-40 rounded-md justify-between flex items-center gap-4 border border-gray-light px-2 py-1.5 capitalize text-sm text-gray-darkest duration-100 hover:border-gray-600">
                        {splitType}
                        <Icon name="CarrotDown" />
                    </div>
                </DropdownMenu>
            </div>
        );
    }

    return (
        <div className="mb-4 flex flex-col gap-3">
            <div className="text-sm font-normal text-day-subtext">Split requirements by:</div>
            <label className="inline-flex items-center gap-4">
                <input
                    type="radio"
                    value="sentence"
                    checked={splitType === "sentence"}
                    onChange={() => dispatch(setExtractState({ splitType: "sentence" }))}
                    className="w-4 h-4 accent-action bg-gray-100 border-gray-300 cursor-pointer rounded"
                />
                <div className="flex flex-col">
                    <span className="text-sm">Sentence</span>
                    <span className="text-xs text-day-subtext">Split by sentence creates one row per sentence</span>
                </div>
            </label>
            <label className="inline-flex items-center gap-4">
                <input
                    type="radio"
                    value="paragraph"
                    checked={splitType === "paragraph"}
                    onChange={() => dispatch(setExtractState({ splitType: "paragraph" }))}
                    className="w-4 h-4 accent-action bg-gray-100 border-gray-300 cursor-pointer rounded"
                />
                <div className="flex flex-col">
                    <span className="text-sm">Paragraph</span>
                    <span className="text-xs text-day-subtext">Split by paragraph creates one row per paragraph</span>
                </div>
            </label>
        </div>
    );
};

export default SplitTypeOptions;
