import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

////////////////////// IMPORTING API FUNCTIONS //////////////////////////
import {
    createDirectory,
    createFile,
    deleteDirectory,
    deleteFile,
    getSingleDirectory,
    moveDirectory,
    moveFile,
    updateDirectory,
    updateFile,
} from "api/api";

////////////////////// IMPORTING IMAGES AND ICONS ////////////////////////
import { CloseIconBlack, Spinner } from "utils/icons";
import plusSign from "../../Assets/add-blue-circle.svg";
import addLink from "../../Assets/addlink.svg";
import driveWhite from "../../Assets/drive-white.svg";
import folderOpenSm from "../../Assets/folder-open-white.svg";
import lod from "../../Assets/loader.svg";
import searchIcon from "../../Assets/search-normal.png";

//////////////////// IMPORINTG CUSTOM COMPONENTS ///////////////////////
import Avatar from "components/Avatar";
import CustomModal from "components/CustomModal";
import Document from "components/Drive/Document";
import DriveDropDown from "components/Drive/DriveDropDown";
import Folder from "components/Drive/Folder";
import UploadDriveDocModal from "components/Drive/UploadDriveDocModal";
import ClickAwayListener from "helpers/ClickAwayListener";

import GetColorForTag from "components/Drive/TagColor";
import ExtractingDocumentToast from "components/LoadingToast";
import { publishedDateOptions } from "components/Search/SearchPageSearchBar";
import { useNotification } from "context/notificationContext";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { fetchFileStorage, updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import { useAppSelector } from "store/storeTypes";
import { formatBytes, getPastDate, validateField } from "utils/helpers";
import MoveModal from "./MoveModal";
//////////////////////// START OF MAIN FUNCTION //////////////////////////
//////////////////////////////////////////////////////////////////////////
const FolderPage = () => {
    const dispatch = useDispatch();
    const { rootFiles: allMainDocs, rootSubdirectories: allMainFolders } = useAppSelector(
        (state) => state.drive.fileStorage
    );

    const { setToast } = useNotification();
    const { folderId } = useParams();

    const [allDocs, setAllDocs] = useState([]);
    const [allFolders, setAllFolders] = useState([]);

    const [addDoc, setAddDoc] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [folderName, setFolderName] = useState("");
    const [selectedFolderId, setSelectedFolderId] = useState(false);
    const [currentDirectoryName, setCurrentDirectoryName] = useState("");
    const [isfolderAddModalOpen, setIsFolderAddModalOpen] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [removedFolderId, setRemovedFodlerId] = useState("");
    const [currentFolders, setCurrentFolders] = useState([]);
    const [currentDocs, setCurrentDocs] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });

    const [draggingFolderId, setDraggingFolderId] = useState("");
    const [draggingDocId, setDraggingDocId] = useState("");
    const [selectedFolderHeading, setSelectedFolderHeading] = useState("");
    const [availableTags, setAvailableTags] = useState([]);
    const [folderDropData, setFolderDropData] = useState([]);
    const [moveModalOpen, setIsMoveModalOpen] = useState({
        item: null,
        open: false,
        type: undefined,
    });
    const [stateHistory, setStateHistory] = useState({
        type: null,
        data: [],
        itemInfo: {},
    });
    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

    const drpOptions = [
        {
            name: "types",
            subName: "type",
            title: "Type",
            searchable: false,
            optionIcon: true,
            options: [
                { name: "Past Proposal" },
                { name: "Product Documentation" },
                { name: "Technical Whitepaper" },
                { name: "Resume" },
                { name: "Marketing Material" },
                { name: "Certification" },
                { name: "Case Study" },
            ],
            className: "right-0 min-w-[240px]  min-h-full",
            txtClass: "max-w-max",
            multiSelect: true,
        },
        {
            name: "uploaded",
            title: "Uploaded",
            searchable: false,
            optionIcon: true,
            static: true,
            options: publishedDateOptions,
            className: "right-0 min-w-[160px]",
            txtClass: "max-w-max",
            multiSelect: false,
        },
    ];

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const { isLoadingWorkSpaces, workspaceMembers, isLoadingMembers } = useSelector((store) => store.auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [usersIds, setUsersIds] = useState([]);

    const selectMembers = (user) => {
        let updatedList = [...usersIds];
        if (updatedList?.find((v) => v === user?.id)) {
            updatedList = updatedList?.filter((s) => s !== user?.id);
        } else {
            updatedList.push(user?.id);
        }
        setUsersIds(updatedList);
    };

    const sortedMembers = [...workspaceMembers].sort((a, b) => {
        const aSelected = usersIds?.includes(a.id);
        const bSelected = usersIds?.includes(b.id);

        if (aSelected && !bSelected) {
            return -1; // a is selected but b is not, move a to the top
        } else if (!aSelected && bSelected) {
            return 1; // b is selected but a is not, move b to the top
        } else {
            // both a and b are selected or both are not selected, sort alphabetically
            return a.username.localeCompare(b.username);
        }
    });

    const usersDropDown = [
        {
            name: "users",
            subName: "users",
            title: "People",
            optionIcon: true,
            className: "right-0 pb-1.5",
            options: [
                <ul className="py-1 px-2 min-w-[230px] max-w-[320px]">
                    {isLoadingMembers || isLoadingWorkSpaces ? (
                        <div className="rounded-md p-3 text-center">
                            <Spinner width={15} height={15} classes="!text-black" />
                        </div>
                    ) : sortedMembers.filter((v) => v?.username.toLowerCase().includes(searchQuery.toLowerCase()))
                          .length > 0 ? (
                        sortedMembers
                            .filter((v) => v?.username.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map((v, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={(e) => {
                                            selectMembers(v);
                                            // setSelectedUser(v.username);
                                        }}
                                        className={`flex items-center px-2 py-[0.32rem] text-sm font-medium my-1 cursor-pointer select-none hover:bg-[#EFEFEF]`}
                                        role="button"
                                    >
                                        <input type="checkbox" checked={usersIds.includes(v.id)} className="mr-2" />
                                        <Avatar
                                            id={v?.username}
                                            src=""
                                            alt={v?.username}
                                            width={26}
                                            height={26}
                                            className="mr-2"
                                        />
                                        <span className="line-clamp-1 ">{v?.username}</span>
                                    </li>
                                );
                            })
                    ) : (
                        <li className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">No members found.</li>
                    )}
                </ul>,
            ],
        },
    ];

    useEffect(() => {
        // Check if allDocs and allFolders are empty in redux before dispatching the action
        if (!allMainDocs.length && !allMainFolders.length) {
            dispatch(fetchFileStorage());
        }
    }, [dispatch, allMainDocs.length, allMainFolders.length]);

    //////////// fetch data on initial render
    useEffect(() => {
        fetchData();
    }, [folderId]);

    async function fetchData() {
        setIsDataLoading(true);

        try {
            const { data } = await getSingleDirectory(folderId);

            setCurrentDirectoryName(data.current_directory.name);
            setAllFolders(data.subdirectories);
            setCurrentFolders(data.subdirectories);
            setAllDocs(data.files);
            setCurrentDocs(data.files);
        } catch (err) {
            console.error(err);
            handleApiError(
                err,
                "We were unable to retrieve data due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        } finally {
            setIsDataLoading(false);
        }
    }

    // Handle search functionality
    const [selectedOptions, setSelectedOptions] = useState({
        tags: [],
        types: [],
        uploaded: "",
    });

    /////////// searching and filtering functionality //////////////
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = (searchText) => {
        if (searchText) {
            const filtered = [...allDocs, ...allFolders].filter((item) =>
                item.name.toLowerCase().includes(searchText.toLowerCase())
            );
            setSearchResults(filtered);
        } else {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        let filteredResults = [...allDocs, ...allFolders];

        // handle search filter
        if (searchResults.length > 0) {
            filteredResults = filteredResults.filter((item) => searchResults.some((result) => result.id === item.id));
        }

        // Handle tags filter (OR logic within tags)
        if (selectedOptions.tags.length > 0) {
            filteredResults = filteredResults.filter((item) =>
                selectedOptions.tags.some((tag) => item?.tags?.includes(tag))
            );
        }

        // Handle classification filter (OR logic within types)
        if (selectedOptions.types.length > 0) {
            const typeFilters = selectedOptions.types.map((option) => option.toLowerCase().split(" ").join("_"));
            filteredResults = filteredResults.filter((item) => typeFilters.includes(item.classification));
        }

        // Handle period filter (AND logic for uploaded)
        if (selectedOptions.uploaded) {
            const selectedDate = getPastDate(selectedOptions.uploaded);
            filteredResults = filteredResults.filter(
                (item) => new Date(item.created_at) >= new Date(selectedDate) && new Date(item.created_at) < new Date()
            );
        }

        // Handle users filter (AND logic for user ids)
        if (usersIds.length > 0) {
            filteredResults = filteredResults.filter((item) => usersIds.includes(item.created_by));
        }

        if (
            !searchResults.length &&
            !selectedOptions.tags.length &&
            !selectedOptions.types.length &&
            !selectedOptions.uploaded &&
            !usersIds.length
        ) {
            filteredResults = [];
        }

        if (filteredResults.length > 0) {
            let filteredDocs = [];
            filteredResults.forEach((result) => {
                const d = allDocs.find((doc) => doc.id === result.id);
                if (d) {
                    if (!filteredDocs.some((result) => result.id === d.id)) {
                        filteredDocs.push(d);
                    }
                }
            });

            let filteredFolders = [];
            filteredResults.forEach((result) => {
                const f = allFolders.find((folder) => folder.id === result.id);
                if (f) {
                    if (!filteredFolders.some((result) => result.id === f.id)) {
                        filteredFolders.push(f);
                    }
                }
            });

            setCurrentDocs(filteredDocs);
            setCurrentFolders(filteredFolders);
        } else if (
            filteredResults.length === 0 &&
            (searchValue ||
                selectedOptions.tags.length ||
                selectedOptions.types.length ||
                selectedOptions.uploaded ||
                usersIds.length)
        ) {
            setCurrentDocs([]);
            setCurrentFolders([]);
        } else {
            setCurrentDocs(allDocs);
            setCurrentFolders(allFolders);
        }

        ////////////////// set available tags ///////////////////
        const allTags = allDocs.flatMap((doc) => doc.tags);
        const uniqueTags = [...new Set(allTags)];
        setAvailableTags([...uniqueTags]);
    }, [selectedOptions, searchResults, allFolders, allDocs, usersIds]);

    /////////////////////////// APIs Integration //////////////////////////
    //////////////// function to create new folder ////////////////////////
    async function createNewDirectory() {
        if (validateField(folderName)) {
            setIsFolderAddModalOpen(false);

            try {
                const { data: newFolder } = await createDirectory({ name: folderName, parent_directory_id: folderId });

                setAllFolders([...allFolders, newFolder]);
                setFolderName("");
            } catch (err) {
                handleApiError(
                    err,
                    "We were unable to create a new folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            }
        }
    }

    ///////////////////// update directory function ////////////////////
    async function updateSelectedDirectory(folderId) {
        if (validateField(folderName)) {
            setIsFolderAddModalOpen(false);
            setIsDataLoading(true);

            try {
                await updateDirectory(folderId, { name: folderName });

                const updatedFolders = allFolders.map((folder) =>
                    folder.id === folderId ? { ...folder, name: folderName } : folder
                );
                setAllFolders([...updatedFolders]);

                handleApiSuccess("Folder updated");
                setFolderName("");
            } catch (err) {
                handleApiError(
                    err,
                    "We were unable to update the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            }
        }
    }

    ///////////////////// delete directory function ////////////////////
    async function deleteSelectedDirectory(folderId) {
        // setIsDataLoading(true);

        try {
            await deleteDirectory(folderId);

            const updatedFolders = allFolders.filter((folder) => folder.id !== folderId);

            // setIsDataLoading(false);
            setAllFolders([...updatedFolders]);
            setRemovedFodlerId(folderId);
        } catch (err) {
            handleApiError(
                err,
                "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    ///////////////////// move directory function ////////////////////
    async function moveSelectedDirectory(dropTargetFolderId, selectedFolderId, preventHistory) {
        setIsDataLoading(true);

        if ((draggingFolderId && dropTargetFolderId && draggingFolderId !== dropTargetFolderId) || selectedFolderId) {
            try {
                let folderId = selectedFolderId || draggingFolderId;
                await moveDirectory(folderId, {
                    parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId,
                });
                if (!preventHistory) {
                    setStateHistory({
                        type: "folder",
                        data: allFolders,
                        itemInfo: allFolders?.find((folder) => folder?.id === folderId),
                    });
                }
                let updatedFolders = allFolders?.filter((folder) => folder?.id !== folderId);
                if (dropTargetFolderId === "drive") {
                    let selectedFolder = allFolders?.find((folder) => folder?.id === folderId);
                    let newListOfFolders = [...allMainFolders, selectedFolder];
                    dispatch(updateAllFolders(newListOfFolders));
                }
                setAllFolders([...updatedFolders]);

                handleApiSuccess("Folder moved");
            } catch (err) {
                handleApiError(
                    err,
                    "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            }
        } else {
            setIsDataLoading(false);
        }
    }

    ///////////////////// create new file function ////////////////////
    async function createDocument(files, targetId = "") {
        if (!files.length) {
            setLoadingToast({
                loading: false,
                files: 1,
            });
            return;
        }

        setLoadingToast({
            loading: true,
            files: files?.length,
        });

        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const uploadPromises = files.map(async (file) => {
                let formData = new FormData();
                formData.append("file", file);
                formData.append("parent_directory_id", targetId || folderId);
                formData.append("tags[]", []);

                const response = await createFile(formData, "upload", headers);
                return response.data;
            });

            const receivedData = await Promise.all(uploadPromises);

            if (!targetId) {
                setAllDocs([...allDocs, ...receivedData]);
            }

            // store data in case doc is dropped from computer to a folder which then calls api getSingleDrirctory() in folder component to update files length
            setFolderDropData(receivedData);

            handleApiSuccess("Files uploaded");
            setShowUploadModal(false);
            setLoadingToast({
                loading: false,
                files: 1,
            });
        } catch (err) {
            handleApiError(
                err,
                "We were unable to upload the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
            setLoadingToast({
                loading: false,
                files: 1,
            });
        }
    }

    ///////////////////// function to update a document ////////////////////
    async function updateDocument(documentId, data) {
        setIsDataLoading(true);

        try {
            const { data: updatedDoc } = await updateFile(documentId, data);

            const updatedDocs = allDocs.map((doc) => (doc.id === documentId ? updatedDoc : doc));
            setAllDocs([...updatedDocs]);

            handleApiSuccess("File updated");
        } catch (err) {
            handleApiError(
                err,
                "We were unable to update the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    ///////////////////// function to delete document ////////////////////
    async function deleteDocument(documentId) {
        try {
            await deleteFile(documentId);

            const updatedDocs = allDocs.filter((doc) => doc.id !== documentId);
            setAllDocs([...updatedDocs]);
        } catch (err) {
            handleApiError(
                err,
                "We were unable to delete the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    ///////////////////// function to move document ////////////////////
    async function moveDocument(dropTargetFolderId, selectedDocId, preventHistory) {
        try {
            let docId = selectedDocId || draggingDocId;
            await moveFile(docId, { parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId });
            if (!preventHistory) {
                setStateHistory({
                    type: "doc",
                    data: allDocs,
                    itemInfo: allDocs?.find((doc) => doc?.id === docId),
                });
            }
            const updatedDocs = allDocs.filter((doc) => doc.id !== docId);

            if (dropTargetFolderId === "drive") {
                let selectedDoc = allDocs?.find((doc) => doc?.id === docId);
                let newListOfDocs = [...allMainDocs, selectedDoc];
                dispatch(updateAllDocs(newListOfDocs));
            }

            // store data in case doc is dropped from computer to a folder which then calls api getSingleDirectory() in folder component to update files length
            setFolderDropData(updatedDocs);
            setAllDocs([...updatedDocs]);

            handleApiSuccess("File moved");
        } catch (err) {
            handleApiError(
                err,
                "We were unable to move the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    // Generic api success handling function
    function handleApiSuccess(message) {
        setToast.success({
            msg: message,
        });

        setIsDataLoading(false);
    }

    // Generic api error handling function
    function handleApiError(error, errorMessage) {
        setToast.error({
            msg: errorMessage,
        });

        setIsDataLoading(false);
    }

    ///////////////////////////DRAG AND DROP COMPUTER FUNCTIONALITY ////////////////////////////
    const [isDragOverDrive, setIsDragOverDrive] = useState(false);

    ////////////////// function to check validity of file
    function isValidFile(file) {
        const maxFileSize = 200000000; // 200 MB in bytes

        const acceptedFormats = {
            "text/plain": [".txt"],
            "application/pdf": [".pdf"],
            "application/doc": [".doc", ".docx", ".dot"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "application/pptx": [".pptx"],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
        };

        const allowedExtensions = Object.values(acceptedFormats).flat();

        if (file.size > maxFileSize) {
            handleInvalidFileMsg(
                `Document exceeds the maximum size limit. We do not support document sizes larger than ${formatBytes(
                    maxFileSize
                )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`
            );
            return false;
        }

        const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        const fileType = file.type;

        if (!allowedExtensions.includes(fileExtension) || !acceptedFormats[fileType]?.includes(fileExtension)) {
            handleInvalidFileMsg("Document format is not supported.");
            return false;
        }

        return true;
    }

    ////////// function to handle file msg /////////////
    function handleInvalidFileMsg(errorMessage) {
        setToast.error({
            msg: errorMessage,
        });
    }

    ///// function to handle files droppped from computer into drive
    function onMainDrop(acceptedFiles) {
        if (showUploadModal) return;
        setSelectedFolderHeading("");
        setIsDragOverDrive(false);
        const validFiles = acceptedFiles.filter((file) => isValidFile(file));
        createDocument(validFiles);
    }

    ///// function to handle files droppped from computer into any folder
    function onFolderDrop(acceptedFiles, targetFolderId) {
        setSelectedFolderHeading("");
        setIsDragOverDrive(false);

        const validFiles = acceptedFiles.filter((file) => isValidFile(file));
        createDocument(validFiles, targetFolderId);
    }

    const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
        onDrop: onMainDrop,
        onDragOver: (e) => {
            e.preventDefault();
            if (showUploadModal) return;
            setIsDragOverDrive(true);
        },
        onDragLeave: (e) => {
            const relatedTarget = e.relatedTarget;
            if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
                setIsDragOverDrive(false);
            }
        },
        noClick: true,
    });

    // handle move modal closing event
    const handleCloseMoveModal = () => {
        setIsMoveModalOpen({ item: null, open: false, type: undefined });
    };

    // ////////// Move folder/document ////////////////////
    async function onMoveManually(selectedDestination) {
        try {
            if (moveModalOpen?.type === "folder") {
                await moveSelectedDirectory(selectedDestination, moveModalOpen?.item?.id);
            } else {
                await moveDocument(selectedDestination, moveModalOpen?.item?.id);
            }
            handleCloseMoveModal();
        } catch (err) {
            handleApiError(
                err,
                "We were unable to move due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
            );
        }
    }

    // ////////////// setting up ctrl + z //////////
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Control") {
                setCtrlKeyPressed(true);
            }
            if (event.key === "z" && ctrlKeyPressed) {
                undo();
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === "Control") {
                setCtrlKeyPressed(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [ctrlKeyPressed]);

    // /////// perform undo method for docs & folders ///////////////////
    const undo = async () => {
        if (!stateHistory?.type) return;
        if (stateHistory?.type === "folder") {
            await moveSelectedDirectory(
                stateHistory?.itemInfo?.parent_directory,
                stateHistory?.itemInfo?.id,
                "prevent"
            );
            dispatch(updateAllFolders(allMainFolders?.filter((fl) => fl?.id !== stateHistory?.itemInfo?.id)));
            setAllFolders(stateHistory?.data);
            setStateHistory({ type: null, data: [], itemInfo: {} });
        } else {
            await moveDocument(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
            dispatch(updateAllDocs(allMainDocs?.filter((dc) => dc?.id !== stateHistory?.itemInfo?.id)));
            setAllDocs(stateHistory?.data);
            setFolderDropData(stateHistory?.data);
            setStateHistory({ type: null, data: [], itemInfo: {} });
        }
    };

    return (
        <main className="flex flex-col flex-1 overflow-auto" {...getMainRootProps()}>
            <input multiple {...getMainInputProps()} />

            <div className="relative flex-grow overflow-auto">
                {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
                <div
                    className={`${
                        (isDragOverDrive || selectedFolderHeading) &&
                        !showUploadModal &&
                        "border-[3px] border-dashed border-action"
                    } absolute w-full h-full pointer-events-none z-50`}
                ></div>

                <div className="relative overflow-auto pb-9">
                    {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
                    <section className="p-6">
                        <div className="flex gap-3">
                            <div className="flex-1 relative">
                                <img
                                    src={searchIcon}
                                    alt="search icon"
                                    loading="lazy"
                                    className="absolute top-2.5 left-1.5 w-5"
                                />

                                <input
                                    className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border-[1px]"
                                    value={searchValue}
                                    onChange={(e) => {
                                        handleSearch(e.target.value);
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="flex items-center gap-4 flex-wrap">
                                {drpOptions.map((optItem, index) => (
                                    <DriveDropDown
                                        key={index}
                                        {...optItem}
                                        selectedOptions={selectedOptions}
                                        setSelectedOptions={setSelectedOptions}
                                    />
                                ))}
                                {/* {usersDropDown?.map((optItem, i) => (
                                    <DropDown
                                        key={i}
                                        name={optItem?.name}
                                        title={optItem?.title}
                                        optionIcon={optItem?.optionIcon}
                                        className={optItem?.className || ""}
                                        selectedOptions={[]}
                                        customList={
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Search People"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    className="border border-action w-full block px-3 py-2 text-sm mb-1 rounded-md h-10 outline-none mt-[1px]"
                                                />

                                                {optItem?.options?.length > 0 ? (
                                                    optItem?.options?.map((v, n) => <div key={n}>{v}</div>)
                                                ) : (
                                                    <div className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">
                                                        No results found.
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        btnClass="bg-[#ffffff] min-w-[100px] -mt-1 rounded-md !text-[#9FA2AA]"
                                    />
                                ))} */}
                            </div>
                        </div>
                    </section>

                    {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
                    {isDataLoading ? (
                        <div className="h-screen bg-white rounded-b-lg w-full flex justify-center flex-col py-7 px-2">
                            <img className="mx-auto max-w-full mt-[200px] mb-16" src={lod} alt="Loading..." />
                            <div className="flex-grow"></div>
                        </div>
                    ) : (
                        <>
                            <section className="folders-section px-6 pt-1 mt-5">
                                <div className="flex justify-between items-center mb-9">
                                    <h4 className="font-semibold text-lg">Folders</h4>
                                    <div
                                        className="flex items-center gap-2  cursor-pointer "
                                        onClick={() => {
                                            setIsFolderAddModalOpen(true);
                                            setSelectedFolderId("");
                                        }}
                                    >
                                        <span className="text-sm font-semibold">Folder</span>
                                        <button onClick={() => setFolderName("")}>
                                            <img src={plusSign} alt="icon" loading="lazy" className="cursor-pointer" />
                                        </button>
                                    </div>
                                </div>

                                {currentFolders.length ? (
                                    <div className="cards grid grid-cols-4 2xl:grid-cols-5 gap-x-[3%] gap-y-8 mb-12">
                                        {currentFolders.map((folder) => (
                                            <Folder
                                                key={folder.id}
                                                {...folder}
                                                setIsFolderAddModalOpen={setIsFolderAddModalOpen}
                                                setSelectedFolderId={setSelectedFolderId}
                                                draggingFolderId={draggingFolderId}
                                                setDraggingFolderId={setDraggingFolderId}
                                                draggingDocId={draggingDocId}
                                                setDraggingDocId={setDraggingDocId}
                                                moveSelectedDirectory={moveSelectedDirectory}
                                                setFolderName={setFolderName}
                                                handleDelete={deleteSelectedDirectory}
                                                moveDocument={moveDocument}
                                                createDocument={createDocument}
                                                setSelectedFolderHeading={setSelectedFolderHeading}
                                                setIsDragOverDrive={setIsDragOverDrive}
                                                selectedFolderHeading={selectedFolderHeading}
                                                onFolderDrop={onFolderDrop}
                                                removedFolderId={removedFolderId}
                                                folderDropData={folderDropData}
                                                handleMoveModalOpen={() => {
                                                    setIsMoveModalOpen({ item: folder, open: true, type: "folder" });
                                                }}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="border-b border-[#DBE0E5] pb-20 pt-4 mb-14">
                                        <h5 className="text-base text-center font-semibold text-[#1D2630]">
                                            No Folders
                                        </h5>
                                        <p className="text-[#353535] text-center pt-[5px]">
                                            Create a folder by clicking on the “Folder +” button.
                                        </p>
                                    </div>
                                )}

                                <CustomModal
                                    isOpen={isfolderAddModalOpen}
                                    onClose={() => setIsFolderAddModalOpen(false)}
                                >
                                    <div className="p-5 w-[444px] ">
                                        <div className="flex justify-between items-center mb-5">
                                            <h3 className="font-semibold text-xl">
                                                {selectedFolderId ? "Update Folder" : "New Folder"}
                                            </h3>
                                            <div className="w-[15.56px] h-[15.56px]">
                                                <span className="cursor-pointer">
                                                    <CloseIconBlack
                                                        onClick={() => {
                                                            setIsFolderAddModalOpen(false);
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();

                                                selectedFolderId
                                                    ? updateSelectedDirectory(selectedFolderId)
                                                    : createNewDirectory();
                                            }}
                                        >
                                            <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
                                            <input
                                                type="text"
                                                className="w-full rounded-md py-2 px-3 h-10 border-[1px] border-[#CFD1D4] focus:ring-blue-500 focus:border-blue-500 outline-none mb-7"
                                                value={folderName}
                                                onChange={(e) => setFolderName(e.target.value)}
                                                autoFocus
                                                required
                                            />

                                            <div className="flex justify-end gap-4">
                                                <button
                                                    type="button"
                                                    className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                                                    onClick={(e) => {
                                                        setIsFolderAddModalOpen(false);
                                                        setFolderName("");
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="border-0 bg-action rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                                                >
                                                    <img src={addLink} alt="icon" loading="lazy" />
                                                    {selectedFolderId ? "Update" : "Add"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </CustomModal>
                            </section>

                            {/* -------------------------- SECTION DOCUMENT -------------------------------- */}
                            <section className="document-section px-6 relative pb-3">
                                <div className="flex items-center justify-between mb-9">
                                    <h4 className="font-semibold text-lg">Documents</h4>
                                    <div className="flex items-center gap-2">
                                        <span className="text-sm font-semibold">Document</span>
                                        <span className="relative block">
                                            <ClickAwayListener
                                                onClickAway={() => setAddDoc(false)}
                                                className="rounded-r-lg"
                                            >
                                                <img
                                                    // onClick={() => setAddDoc(!addDoc)}
                                                    onClick={() => setShowUploadModal(true)}
                                                    src={plusSign}
                                                    alt=""
                                                    className="cursor-pointer"
                                                />

                                                {/* {addDoc && (
                                                <div className="absolute right-0 top-[100%] px-2 z-[2] min-w-max rounded-md bg-white shadow-md py-1 mt-1">
                                                    <button
                                                        onClick={() => setShowUploadModal(true)}
                                                        className="flex items-center gap-2 text-sm font-normal hover:bg-[#EFEFEF] w-full px-3 py-1"
                                                    >
                                                        <img
                                                            src={uploadIcon}
                                                            alt="icon"
                                                            loading="lazy"
                                                            className="w-3"
                                                        />
                                                        Upload Document
                                                    </button>

                                                    <Link to="/dashboard/create-document">
                                                        <button className="flex items-center gap-2 text-sm font-normal hover:bg-[#EFEFEF] w-full px-3 py-1">
                                                            <img
                                                                src={plusCircle}
                                                                alt="icon"
                                                                loading="lazy"
                                                                className="w-3"
                                                            />
                                                            Create Document
                                                        </button>
                                                    </Link>
                                                </div>
                                            )} */}
                                            </ClickAwayListener>
                                        </span>
                                    </div>
                                </div>

                                {currentDocs.length ? (
                                    <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[30px] gap-y-5 mb-9">
                                        {currentDocs.map((doc, i) => (
                                            <Document
                                                key={i + doc?.id}
                                                docData={doc}
                                                updateDocument={updateDocument}
                                                deleteDocument={deleteDocument}
                                                setDraggingFolderId={setDraggingFolderId}
                                                setDraggingDocId={setDraggingDocId}
                                                availableTags={availableTags}
                                                handleMoveModalOpen={() => {
                                                    setIsMoveModalOpen({ item: doc, open: true, type: "doc" });
                                                }}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="mb-5">
                                        <UploadDriveDocModal
                                            isDrive={!currentDocs.length}
                                            createDocument={createDocument}
                                        />
                                    </div>
                                )}

                                {/* ------------ UPLOAD files MODAL --------------- */}
                                <UploadDriveDocModal
                                    showUploadModal={showUploadModal}
                                    setShowUploadModal={setShowUploadModal}
                                    createDocument={createDocument}
                                />
                            </section>
                        </>
                    )}
                </div>
            </div>

            {/* ------------- Drag and Drop Toast -------------------- */}
            {(isDragOverDrive || selectedFolderHeading) && !showUploadModal && (
                <div className="absolute inset-0 min-h-screen pointer-events-none z-50">
                    <div className="absolute bottom-3 left-[45%] bg-action py-4 px-20 rounded-full text-center">
                        <span className="text-lg opacity-90 text-[#ffffff] block mb-3">
                            Drop files to upload them to
                        </span>

                        {selectedFolderHeading && (
                            <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                                <img
                                    src={folderOpenSm}
                                    alt="icon"
                                    loading="lazy"
                                    className="w-[18px] h-[18px] bg-[#fffffff]"
                                />{" "}
                                {selectedFolderHeading}
                            </span>
                        )}

                        {isDragOverDrive && (
                            <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                                <img
                                    src={driveWhite}
                                    alt="icon"
                                    loading="lazy"
                                    className="w-[18px] h-[18px] bg-[#fffffff]"
                                />{" "}
                                {currentDirectoryName || "Content Library"}
                            </span>
                        )}
                    </div>
                </div>
            )}

            {loadingToast?.loading && (
                <ExtractingDocumentToast
                    loadingText={`Uploading ${loadingToast?.files > 1 ? "Documents" : "Document"}`}
                    handleClose={() => setLoadingToast({ loading: false, files: 1 })}
                />
            )}

            {/* ------------ Move Modal --------------- */}
            <MoveModal
                selectedItem={moveModalOpen}
                handleCloseMoveModal={handleCloseMoveModal}
                onMove={onMoveManually}
                currentFolders={allMainFolders?.filter((f) => f?.id !== folderId)}
            />
        </main>
    );
};

export default FolderPage;
