export function getWordCount(str: string) {
    return str.split(/\s+/g).filter(function (n) {
        return n !== "";
    }).length;
}
export function sliceByWordCount(str: string, count: number) {
    return str
        .split(/\s+/g)
        .filter(function (n) {
            return n !== "";
        })
        .slice(0, count)
        .join(" ");
}
