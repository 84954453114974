import { useEffect, useMemo, useRef, useState } from "react";
import SearchPageSearchBar from "../../components/Search/SearchPageSearchBar";
import SearchTable from "../../components/Search/SearchTable";
import { useSelector, useDispatch } from "react-redux";
import { useSearchDebounce } from "hook/useDebounce";
import { getAllSavedSearches } from "store/reducers/utilsRouter";
import { useLocation, useSearchParams } from "react-router-dom";
import ContractDetailsSearch from "components/Search/ContractDetailSearch";

// ---------- Search Tab main -----------------
const SearchPage = () => {
    const searchBarRef = useRef(null);
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const searchId = searchParams.get("id");
    const dispatch = useDispatch();
    const { savedSearches, isLoadingSavedSearches } = useSelector((store) => store.utils);
    const [searchVal, setSearchVal] = useState("");
    const [allChips, setAllChips] = useState({});
    const { search, setSearchQuery } = useSearchDebounce();
    const [firstReload, setFirstReload] = useState(true);
    const [offset, setOffset] = useState(0);
    const [limitQuery, setLimitQuery] = useState(`limit=50&offset=${offset}`);
    const [infiniteScroll, setInfiniteScroll] = useState(true);

    // fetch all saved searches list at once when page is load
    useEffect(() => {
        if (isLoadingSavedSearches || savedSearches?.length > 0) return;
        dispatch(getAllSavedSearches());

        // eslint-disable-next-line
    }, []);

    const isContractDetails = useMemo(() => pathname?.includes("/dashboard/search/contracts-details"), [pathname]);

    //
    return (
        <div className="inline flex-1 overflow-hidden">
            {isContractDetails && <ContractDetailsSearch />}
            {!(isContractDetails && searchId) && (
                <SearchPageSearchBar
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    setSearchQuery={setSearchQuery}
                    allChips={allChips}
                    setAllChips={setAllChips}
                    firstReload={firstReload}
                    setFirstReload={setFirstReload}
                    offset={offset}
                    setOffset={setOffset}
                    setLimitQuery={setLimitQuery}
                    setInfiniteScroll={setInfiniteScroll}
                    searchBarRef={searchBarRef}
                />
            )}
            <SearchTable
                searchVal={search}
                allChips={allChips}
                setAllChips={setAllChips}
                firstReload={firstReload}
                setFirstReload={setFirstReload}
                offset={offset}
                setOffset={setOffset}
                limitQuery={limitQuery}
                setLimitQuery={setLimitQuery}
                infiniteScroll={infiniteScroll}
                setInfiniteScroll={setInfiniteScroll}
                searchBarRef={searchBarRef}
                isContractDetails={isContractDetails}
            />
        </div>
    );
};

export default SearchPage;
