import { Node } from "@tiptap/core";
import { NodeViewProps, NodeViewWrapper, ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaFetch } from "./hooks";
import "../../../../styles/text-editor.css";
function ContentLibraryImageComponent({ node }: NodeViewProps) {
    const { fetchFiles } = useMediaFetch();
    const { mediaUrls } = useSelector((state: any) => state.yjsEditor);
    useEffect(() => {
        if (node.attrs.id) {
            fetchFiles([node.attrs.id]);
        }
    }, [node.attrs.id]);

    return (
        <NodeViewWrapper>
            {mediaUrls[node.attrs.id] ? <img className="tiptap-image" src={mediaUrls[node.attrs.id]} /> : null}
        </NodeViewWrapper>
    );
}

export const ContentLibraryImage = Node.create({
    name: "cimg",
    group: "block",
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    addAttributes() {
        return {
            id: {
                default: "",
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: "cimg",
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ["cimg", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },
    addNodeView() {
        return ReactNodeViewRenderer(ContentLibraryImageComponent);
    },
});
