import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

//////////////// IMPORTING IMAGES AND ICONS //////////////////////////
import dots from "../../Assets/3dots.svg";
import folderOpenIcon from "../../Assets/folder-open.svg";
import { getSingleDirectory } from "api/api";
import { MoveIcon } from "utils/icons";
import { useAppDispatch } from "store/storeTypes";
import { setDocumentNavHistory } from "store/reducers/driveReducerSlice";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { LuPencil, LuTrash2 } from "react-icons/lu";
//////////////////////////// START OF MAIN FUNCTION /////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
export default function Folder({
    id,
    name,
    setIsFolderAddModalOpen,
    setSelectedFolderId,
    setFolderName,
    draggingFolderId,
    setDraggingFolderId,
    draggingDocId,
    setDraggingDocId,
    moveSelectedDirectory,
    moveDocument,
    handleDelete,
    setSelectedFolderHeading,
    selectedFolderHeading,
    setIsDragOverDrive,
    onFolderDrop,
    removedFolderId,
    folderDropData,
    handleMoveModalOpen,
}) {
    const [docsLength, setDocsLength] = useState(0);
    const [folderHoverStyles, setFolderHoverStyles] = useState("");
    const dispatch = useAppDispatch();

    //////// FUNCTION TO GET LENGTH OF FILES TO EVERY FOLDER
    useEffect(() => {
        if (removedFolderId === id) return;
        async function fetchData() {
            const { data } = await getSingleDirectory(id);

            setDocsLength(data.current_directory?.all_nested_files?.length);
        }
        fetchData();
    }, [id, folderDropData, removedFolderId]);

    function handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation();

        if (draggingDocId) {
            setFolderHoverStyles("border-action bg-[#DBE0E5]");
            return;
        }

        // if (draggingFolderId || draggingDocId) return;
        if (draggingFolderId) return;

        setSelectedFolderHeading(name);
        setIsDragOverDrive(false);
    }

    function handleDragLeave(e) {
        const relatedTarget = e.relatedTarget;

        if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
            setSelectedFolderHeading("");
            setFolderHoverStyles("");
        }
    }

    function handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();

        setDraggingFolderId(id);
        setDraggingDocId("");
    }

    async function handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        /// if files are dragged directly into computer
        if (selectedFolderHeading) {
            const droppedFiles = Array.from(e.dataTransfer.files);
            onFolderDrop(droppedFiles, id);
        }

        //// if file is dragged into a folder
        if (draggingDocId) {
            moveDocument(id);
        }

        ///// if folder is dragged into another folder
        if (draggingFolderId) {
            moveSelectedDirectory(id);
        }

        setFolderHoverStyles("");
        setDraggingDocId("");
        setDraggingFolderId("");
    }

    function formatName(str) {
        if (str.length <= 12) {
            return str;
        }
        return str.substring(0, 12) + "...";
    }

    const menuItems = useMemo(
        () => [
            {
                key: 1,
                label: (
                    <div className="flex items-center gap-2">
                        <LuPencil /> Rename
                    </div>
                ),
                onSelect: () => {
                    setIsFolderAddModalOpen(true);
                    setSelectedFolderId(id);
                    setFolderName(name);
                },
            },
            {
                key: 2,
                label: (
                    <div className="flex items-center gap-2">
                        <MoveIcon />
                        Move
                    </div>
                ),
                onSelect: () => {
                    handleMoveModalOpen();
                },
            },
            {
                key: 3,
                label: (
                    <div className="flex items-center gap-2 text-red-500">
                        <LuTrash2 />
                        Delete
                    </div>
                ),
                onSelect: () => {
                    handleDelete(id);
                },
            },
        ],
        [handleDelete, handleMoveModalOpen, id, name, setFolderName, setIsFolderAddModalOpen, setSelectedFolderId]
    );

    return (
        <Link
            to={`/dashboard/library/documents/folder/${id}`}
            onClick={() =>
                dispatch(
                    setDocumentNavHistory([
                        {
                            name,
                            id,
                        },
                    ])
                )
            }
        >
            <div
                className={`flex items-center gap-4 h-full border-[2px] relative px-3 py-5 rounded-md shadow-[0_8px_24px_#1B2E5E14] 2xl:w-[258px] ${
                    folderHoverStyles || "bg-white border-[#ffffff]"
                }`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDrag={handleDrag}
                onDragLeave={handleDragLeave}
                draggable
            >
                <img
                    src={folderOpenIcon}
                    alt="Folder Open Icon"
                    loading="lazy"
                    className="w-[35px] h-[35px] pointer-events-none"
                />
                <div className="flex-1">
                    <div className="flex justify-between gap-1 mb-1">
                        <h4 className="font-semibold break-words">{formatName(name)}</h4>

                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <DropdownMenu items={menuItems}>
                                <div className="cursor-pointer ml-auto">
                                    <img
                                        src={dots}
                                        alt="Three Dots"
                                        className="max-w-full mx-auto pointer-events-none object-contain"
                                    />
                                </div>
                            </DropdownMenu>
                        </div>
                    </div>
                    <p className="text-[12px] text-[#5B6B79]">{docsLength} Files</p>
                </div>
            </div>
        </Link>
    );
}
