/** @jsxImportSource @emotion/react */

import { IconActionButton } from "components/atoms/icon-button";
import {
    removeAllAssistantFiles,
    setAssistantPrompt,
    setHighlightedText,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import WritingAssistantInputActions from "./WritingAssistantInputActions";
import { useRef, useState } from "react";
import tw from "twin.macro";
import FileChip from "./FileChip";
import InternetChip from "./InternetChip";
import TextareaAutosize from "react-textarea-autosize";
import Popover from "components/atoms/popover";
import { useAssistant, useWritingAssistantSelection } from "./hooks";
import { IoIosClose, IoIosSquare, IoIosSend } from "react-icons/io";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import { LuFiles, LuX } from "react-icons/lu";

const WritingAssistantInput = () => {
    const { activeProject } = useAppSelector((root) => root.project);
    const { prompt, selectedFiles, streamState, enableInternet, highlightedText } = useAppSelector(
        (root) => root.writingAssistant
    );
    const { isStreamingInProgress } = streamState;
    useWritingAssistantSelection();

    const dispatch = useAppDispatch();
    const { submitMessage, abortConnection } = useAssistant();
    const canSubmit = !!prompt.trim() && !isStreamingInProgress && !!activeProject?.internal_contract.id;

    const trailingSpace = (prompt: string): string => {
        if (prompt === "" || prompt[prompt.length - 1] === " ") {
            return "";
        } else {
            return " ";
        }
    };

    const handleTranslation = (message: string) => {
        const spacing = trailingSpace(prompt);
        dispatch(setAssistantPrompt(prompt + spacing + message));
    };

    return (
        <div className="relative bg-white border-t-1.5 border-gray-lightest p-3 flex flex-col gap-2">
            <HighlightedTextDisplay text={highlightedText} />
            <div className="relative flex flex-col p-2 pl-2.5 mt-[36px] gap-2 rounded-lg border border-gray-light shadow-sm">
                <WritingAssistantInputActions />
                <div className="flex">
                    <TextareaAutosize
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                submitMessage();
                            }
                        }}
                        readOnly={isStreamingInProgress}
                        autoFocus
                        minRows={!!selectedFiles.length || enableInternet ? 1.2 : 3}
                        maxRows={5}
                        value={prompt}
                        className="text-gray-600 text-sm pr-8 flex-1 outline-none resize-none w-full"
                        placeholder="Chat with Vultron..."
                        onChange={(e) => dispatch(setAssistantPrompt(e.target.value))}
                    />
                </div>
                <span className="flex items-center gap-2">
                    <VoiceTranslateItem onComplete={handleTranslation} />
                    {(!!selectedFiles.length || enableInternet) && <FileWrap enableInternet={enableInternet} />}
                </span>
                <IconActionButton
                    icon={isStreamingInProgress ? <IoIosSquare size={"14"} /> : <IoIosSend />}
                    onClick={(e) => {
                        if (isStreamingInProgress) {
                            abortConnection();
                        } else {
                            submitMessage();
                        }
                    }}
                    style={{ position: "absolute", bottom: 8, right: 8 }}
                    disabled={!isStreamingInProgress ? !canSubmit : undefined}
                />
            </div>
        </div>
    );
};

export default WritingAssistantInput;

interface FileWrapProps {
    enableInternet: boolean;
}

const FileWrap = ({ enableInternet }: FileWrapProps) => {
    const [fileMenuOpen, setFileMenuOpen] = useState(false);
    const ref = useRef(null);
    const { selectedFiles } = useAppSelector((root) => root.writingAssistant);
    const { sidebarVisible } = useAppSelector((root) => root.copilot);
    const dispatch = useAppDispatch();

    return (
        <div ref={ref} className="relative flex flex-row flex-nowrap duration-200 max-w-[61px]">
            <div className="fade-in-container flex flex-row gap-1">{enableInternet && <InternetChip />}</div>
            {selectedFiles.length < (sidebarVisible ? 2 : 2) ? (
                <div className="fade-in-container flex flex-row gap-1">
                    {selectedFiles.map((file, idx) => (
                        <FileChip key={file.id} fileMenuOpen={fileMenuOpen} idx={idx} file={file} />
                    ))}
                </div>
            ) : (
                <Popover
                    portalProps={{ container: ref.current }}
                    open={fileMenuOpen}
                    onOpenChange={setFileMenuOpen}
                    contentProps={{
                        side: "top",
                        align: "start",
                        alignOffset: -8,
                        sideOffset: 8,
                        css: [tw`bg-transparent shadow-none border-0 m-0`],
                    }}
                    content={
                        <div className="relative px-2 py-1 max-h-[60vh] overflow-y-auto flex flex-col-reverse gap-1">
                            {selectedFiles.map((file, idx) => (
                                <FileChip key={file.id} fileMenuOpen={fileMenuOpen} isMultiple idx={idx} file={file} />
                            ))}
                        </div>
                    }
                >
                    <button className="relative duration-100 flex gap-1 items-center px-2 py-[3px] min-w-fit h-[28px] text-xs rounded bg-white border border-light shadow-sm text-gray-600 hover:bg-neutral-50 hover:border-gray-silver">
                        <LuFiles className="text-sm text-action" />
                        <div className="max-w-[120px] truncate">{selectedFiles.length} Files</div>
                        <button
                            onClick={() => dispatch(removeAllAssistantFiles())}
                            className="absolute flex items-center justify-center w-5 h-5 -right-2 -top-2 rounded-full bg-[rgba(225,225,225,0.8)] duration-150 text-lg hover:bg-[rgba(215,215,215,0.8)] active:bg-[rgba(190,190,190,0.8)] [backdrop-filter:blur(1px)]"
                        >
                            <IoIosClose />
                        </button>
                    </button>
                </Popover>
            )}
        </div>
    );
};

interface HighlightedTextDisplayProps {
    text: string;
}

const HighlightedTextDisplay = ({ text }: HighlightedTextDisplayProps) => {
    const dispatch = useAppDispatch();
    const textStyle = `whitespace-pre-wrap break-words`;
    const scrollableTextStyle = `max-h-72 overflow-auto`;

    return (
        <div
            className="absolute hidden pb-3 inset-x-0 top-0 transform -translate-y-full whitespace-pre-wrap break-words z-[1]"
            css={[!!text && tw`block`]}
        >
            <div className="bg-white relative mt-2 mx-3 rounded-lg border border-black shadow-lg">
                <button
                    onClick={() => dispatch(setHighlightedText(""))}
                    className="absolute top-3 right-3 text-slate-500 duration-150 hover:text-slate-900"
                >
                    <LuX />
                </button>
                <p className="text-xs font-semibold px-4 pt-4 text-gray-600">
                    The assistant will act on the highlighted text:
                </p>
                <p className={`text-xs text-gray-700 pb-4 px-4 pt-2 ${scrollableTextStyle} ${textStyle}`} title={text}>
                    {text}
                </p>
            </div>
        </div>
    );
};
