import { createInternalContractMilestone } from "api/api";
import CInputWithLabel from "components/custom/CInputWithLabel";
import CSmButton from "components/custom/CSmButton";
import CustomModal from "components/CustomModal";
import { useState } from "react";
import { CloseIconBlack } from "utils/icons";

const AddMilestoneModal = ({ isOpen, setIsOpen, internalContractId, setInternalContractDetails }) => {
    const [milestone, setMilestone] = useState({
        name: "",
        deadline: "",
    });
    const [loading, setLoading] = useState(false);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setMilestone((pS) => ({
            ...pS,
            [name]: value,
        }));
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await createInternalContractMilestone(internalContractId, milestone);

            setInternalContractDetails((pS) => ({
                ...pS,
                milestone: pS.milestones.push({ ...response.data }),
            }));
            setIsOpen(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <CustomModal isOpen={isOpen} className={"w-full max-w-[600px] overflow-x-hidden scrollbar-none pb-3"}>
            <div className="p-1 flex flex-col">
                <div className="flex items-center justify-between border-gray-300 w-full px-5 pt-3 pb-2">
                    <h6 className="font-semibold text-[16px]">Add Milestone</h6>
                    <span
                        className="ml-auto block"
                        role="button"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        <CloseIconBlack fill="black" width={12} height={12} />
                    </span>
                </div>

                <div className="mt-[10px]">
                    <form onSubmit={submitHandler} className="w-full px-[22.5px] ">
                        <CInputWithLabel
                            label="Title"
                            onChange={changeHandler}
                            name="name"
                            type="text"
                            className="mt-[8px]"
                            required={true}
                        />
                        <CInputWithLabel
                            label="Date"
                            onChange={changeHandler}
                            name="deadline"
                            type="date"
                            className="mt-[8px]"
                            required={true}
                        />

                        <div className="w-full flex justify-end mt-8">
                            <div className="flex gap-[5px]">
                                <CSmButton
                                    title="Cancel"
                                    isTheme={false}
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                />
                                <CSmButton
                                    title="Add"
                                    isTheme={true}
                                    type="submit"
                                    loading={loading}
                                    style={styles.submitBtn}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </CustomModal>
    );
};

const styles = {
    submitBtn: { width: "66px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
    cancelBtn: { width: "80px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
    deleteBtn: { width: "146px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
};

export default AddMilestoneModal;
