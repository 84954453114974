import { useEffect, useState } from "react";
import CustomModal from "components/CustomModal";
import { CloseIconBlack, FolderIconBlue, FolderIconYellow, Spinner } from "utils/icons";

//
const MoveModal = ({ selectedItem, handleCloseMoveModal, currentFolders, onMove, isMain }) => {
    const [loading, setLoading] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState(null);

    // --- move function ------
    const handleMove = () => {
        if (loading || !selectedDestination) return;
        setLoading(true);
        onMove(selectedDestination).finally(() => {
            setLoading(false);
            setSelectedDestination(null);
        });
    };

    const availableFolders = currentFolders?.filter((folder) => folder?.id !== selectedItem?.item?.id);

    useEffect(() => {
        if (!selectedItem?.open) {
            setSelectedDestination(null);
        }
    }, [selectedItem]);

    if (!selectedItem?.open) return null;
    return (
        <CustomModal
            isOpen={selectedItem?.open}
            onClose={() => (handleCloseMoveModal ? (loading ? null : handleCloseMoveModal()) : null)}
        >
            <div className="p-6 w-[595px]">
                <div className="flex justify-between items-start">
                    <h3 className="font-semibold text-xl break-words line-clamp-3 pr-4">
                        Move “{selectedItem?.item?.name}”
                    </h3>
                    <div className="w-[15.56px] h-[15.56px] flex items-center justify-center mt-1">
                        <span className="cursor-pointer">
                            <CloseIconBlack
                                onClick={() =>
                                    handleCloseMoveModal ? (loading ? null : handleCloseMoveModal()) : null
                                }
                                className="h-3 w-3"
                            />
                        </span>
                    </div>
                </div>

                <div className="my-6 max-h-[350px] flex flex-col border rounded overflow-auto">
                    {!isMain && (
                        <div
                            role="button"
                            className={`border-b flex gap-3 p-4 cursor-pointer select-none ${
                                selectedDestination === "drive" ? " bg-[#f7f7f7]" : "hover:bg-[#f8f8f8]"
                            }`}
                            onClick={() => {
                                setSelectedDestination("drive");
                            }}
                        >
                            <div className="min-w-[38px] flex items-center justify-center">
                                <FolderIconBlue />
                            </div>
                            <p className="font-semibold flex-1">Main Library</p>
                        </div>
                    )}
                    {availableFolders?.map((v, i) => (
                        <div
                            role="button"
                            className={`${
                                availableFolders?.length === i + 1 ? "" : "border-b"
                            } flex gap-3 p-4 cursor-pointer select-none ${
                                selectedDestination === v?.id ? " bg-[#f7f7f7]" : "hover:bg-[#f8f8f8]"
                            }`}
                            key={i}
                            onClick={() => {
                                setSelectedDestination((prev) => (prev === v?.id ? null : v?.id));
                            }}
                        >
                            <div className="min-w-[38px] flex items-center justify-center">
                                <FolderIconYellow />
                            </div>
                            <p className="font-[500] flex-1">{v?.name}</p>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end gap-4 mt-2">
                    <button
                        className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                        disabled={loading}
                        onClick={() => (handleCloseMoveModal ? (loading ? null : handleCloseMoveModal()) : null)}
                    >
                        Cancel
                    </button>
                    <button
                        className="border-0 disabled:opacity-50 bg-action rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                        onClick={handleMove}
                        disabled={loading || selectedDestination === null}
                    >
                        {loading ? <Spinner width="18" height="18" /> : null}
                        {loading ? "Moving" : "Move"}
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};

export default MoveModal;
