import MobileScreen from "./components/MobileScreen";
import AppRouter from "./config/AppRouter";
import ScrollTop from "./utils/ScrollTop";
import { GlobalModals } from "components/organisms/global-modals";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { useAccount } from "hook/useAccount";
import NotificationsProvider from "context/notificationContext";

const App = () => {
    useAccount();

    return (
        <NotificationsProvider>
            <TooltipProvider>
                <div className="hidden xl:block">
                    <AppRouter />
                </div>
                <MobileScreen className="xl:hidden" />
                <ScrollTop />
                <GlobalModals />
            </TooltipProvider>
        </NotificationsProvider>
    );
};

export default App;
