import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import TagsDropdown from "components/organisms/tags-dropdown/TagsDropdown";
import { AVAILABLE_KEYWORDS } from "components/copilot/Extract/upload-documents/constants";
import dropdownIcon from "Assets/icon-wrapper.svg";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setExtractState } from "store/reducers/extract/ExtractReducer";
import { Button } from "components/editor/components";

const ExtractionKeywordsDropdown = () => {
    const { keywords } = useAppSelector((state) => state.extractReducer);
    const dispatch = useAppDispatch();

    return (
        <div className="flex flex-col gap-1">
            <div className="flex justify-between items-baseline">
                <label className="flex items-center gap-1 text-sm font-normal text-day-subtext">
                    Keywords{" "}
                    <Tooltip content={<div>Vultron will shred requirements based on the provided keywords.</div>}>
                        <Icon name="InfoCircle" className="w-4 h-4" />
                    </Tooltip>
                </label>
                <Button
                    disabled={!keywords.length}
                    onClick={() => dispatch(setExtractState({ keywords: [] }))}
                    variant="link"
                    className="!text-red-500 text-xs -mb-0.1 mr-1 hover:!text-red-400"
                >
                    Clear
                </Button>
            </div>
            <TagsDropdown
                dropdownIcon={dropdownIcon}
                selectedTags={keywords}
                setSelectedTags={(tags) => {
                    dispatch(setExtractState({ keywords: tags }));
                }}
                availableTags={AVAILABLE_KEYWORDS}
                placeholder="Add keywords..."
            />
        </div>
    );
};

export default ExtractionKeywordsDropdown;
