import { useEffect, useState } from "react";
import ContractsSearchBar from "../../components/contractsMainPage/ContractsSearchBar";
import ContractTable from "../../components/contractsMainPage/ContarctsTable";
import { getContractManagerData } from "store/reducers/contractManagerReducerSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLocalStorage } from "hook/useLocalStorage";

let obj = { opportunity_type: "", start_date: "", response_date: "" };

const ContractManagement = () => {
    const dispatch = useDispatch();
    const { data: contracts, isLoadingContractManagerData } = useSelector((store) => store.contracts);
    const [newContract, setNewContract] = useState(obj);

    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { setLocalVal: setLocalFilters, localValue: localFilters } = useLocalStorage(
        `contractFilters_${workspace_id}`,
        {}
    );

    useEffect(() => {
        if (isLoadingContractManagerData || contracts?.length > 0) return;
        dispatch(getContractManagerData());
    }, []);

    return (
        <>
            <ContractsSearchBar
                selectedFilters={localFilters}
                setSelectedFilters={setLocalFilters}
                newContract={newContract}
                setNewContract={setNewContract}
            />
            <ContractTable selectedFilters={localFilters} setSelectedFilters={setLocalFilters} />
        </>
    );
};

export default ContractManagement;
