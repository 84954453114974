/** @jsxImportSource @emotion/react */

import ToastBanner from "components/atoms/toast-banner";
import { Button } from "components/editor/components";
import { HTMLProps } from "react";
import { setBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { useAppDispatch } from "store/storeTypes";

const ExtractingTemplateBanner = (props: HTMLProps<HTMLDivElement>) => {
    const dispatch = useAppDispatch();

    return (
        <ToastBanner {...props}>
            <div className="w-full flex-1">
                <div className="flex flex-col gap-1">
                    <div className="text-sm text-white font-semibold">
                        Extracting template
                        <span className="loading-ellipsis" />
                    </div>
                    <div className="flex items-center text-xs text-gray-400">This may take up to 10 minutes</div>
                </div>
            </div>
            <div className="w-full flex gap-1 justify-end">
                <Button
                    className="!text-white hover:!bg-[rgba(200,200,200,0.1)]"
                    onClick={() => dispatch(setBannerState({ templateExtraction: { open: false, forceClose: true } }))}
                    variant="ghost"
                    size="sm"
                >
                    Close
                </Button>
            </div>
        </ToastBanner>
    );
};

export default ExtractingTemplateBanner;
