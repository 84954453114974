/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import Icon from "components/atoms/icons/Icon";
import { ReactComponent as UploadIcon } from "Assets/document-upload-v2.svg";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ExtractMode, setExtractState } from "store/reducers/extract/ExtractReducer";
import UploadDocuments from "./upload-documents/UploadDocuments";

export function UploadDocsSection() {
    const { mode, content } = useAppSelector((state) => state.extractReducer);
    const dispatch = useAppDispatch();

    const isUsingUpload = mode === ExtractMode.DocumentUpload;
    const isCopyPaste = mode === ExtractMode.CopyPaste;

    return (
        <div>
            <div className="w-full flex items-center gap-4 mb-4">
                <button
                    type="button"
                    className="flex flex-grow w-1/2 items-center rounded-lg p-5 gap-4 duration-150 hover:shadow-md"
                    css={[
                        isUsingUpload
                            ? tw`border-2 border-[#9BA9D9] bg-action-lightest shadow-md`
                            : tw`border-2 border-gray-light bg-gray-lightest`,
                    ]}
                    onClick={() => dispatch(setExtractState({ mode: ExtractMode.DocumentUpload }))}
                >
                    <div className="p-2 rounded" css={[isUsingUpload ? tw`bg-action-light` : tw`bg-gray-200`]}>
                        <UploadIcon css={[isUsingUpload ? tw`text-action` : tw`text-gray-mid`, tw`w-6 h-6`]} />
                    </div>
                    <div className="text-left">
                        <p className="font-semibold text-base">Select Document</p>
                        <div className="text-gray-lightest text-xs">
                            Use this option to extract content from a full document.
                        </div>
                    </div>
                </button>
                <button
                    type="button"
                    className="flex flex-grow w-1/2 items-center rounded-lg p-5 gap-4 duration-150 hover:shadow-md"
                    css={[
                        isCopyPaste
                            ? tw`border-[#9BA9D9] border-2 bg-action-lightest shadow-md`
                            : tw`border-2 border-gray-light bg-gray-lightest`,
                    ]}
                    onClick={() => dispatch(setExtractState({ mode: ExtractMode.CopyPaste }))}
                >
                    <div className="p-2 rounded" css={[isCopyPaste ? tw`bg-action-light` : tw`bg-gray-200`]}>
                        <Icon
                            name="CopySolid"
                            css={[isCopyPaste ? tw`text-action` : tw`text-gray-mid`]}
                            className="w-6 h-6"
                        />
                    </div>
                    <div className="text-left">
                        <div className="font-semibold text-base">Copy & Paste Text</div>
                        <div className="text-gray-lightest text-xs">
                            Use this option to focus extraction on select content.
                        </div>
                    </div>
                </button>
            </div>
            <div className="min-h-60">
                {isUsingUpload ? (
                    <UploadDocuments />
                ) : (
                    <textarea
                        placeholder="Paste selected content here..."
                        value={content}
                        onChange={(e) => {
                            dispatch(setExtractState({ content: e.target.value }));
                        }}
                        className="block h-[284px] flex-1 mt-0.5 text-xs resize-none border border-light rounded-lg outline-none p-4 pt-5 w-full"
                    />
                )}
            </div>
        </div>
    );
}
