/** @jsxImportSource @emotion/react */

import { Section, ComplianceMatrixRow as ImmutableComplianceMatrixRow } from "../CopilotSchemaImmutableTypes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TabSlug } from "./types";
import { useMutation } from "liveblocks.config";
import { LiveObject } from "@liveblocks/client";
import { FetchEventSourceInit, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { getTemplateDetails } from "api/api";
import { TemplateDetails } from "types/Templates";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { fetchTemplates } from "store/reducers/templates/templatesReducer";
import { useDebounce } from "react-use";
import { ComplianceMatrixRow, SourceType, Storage, Template } from "../CopilotSchemaTypes";
import { createComplianceMatrixRow } from "utils/complianceMatrix";
import useRequirementOperations from "hook/useRequirementOperations";
import { RequirementBlock } from "./section-proposal/SectionProposal";

export const useTabFilter = (activeTab: TabSlug, sheetRequirements: ImmutableComplianceMatrixRow[]) => {
    const results = useMemo(
        () =>
            sheetRequirements.filter((row) => {
                if (activeTab === TabSlug.Assigned) {
                    return !row?.requirement?.disregarded && !!row.proposal_reference.section_id;
                } else if (activeTab === TabSlug.Unassigned) {
                    return !row?.requirement?.disregarded && !row.proposal_reference.section_id;
                } else if (activeTab === TabSlug.Disregarded) {
                    return !!row?.requirement?.disregarded;
                }

                return row;
            }),
        [activeTab, sheetRequirements]
    );

    return { results };
};

export const useLock = () => {
    const lock = useMutation(({ storage }, volumeId: string, sectionId: string, locked: boolean) => {
        // @ts-ignore
        const volumes: any = storage.get("framework")?.get("volumes");
        volumes?.some((vol: any) => {
            if (vol.get("id") === volumeId) {
                const foundSection = vol.get("sections")?.find((sec: any) => sec.get("id") === sectionId);
                foundSection?.set("locked", locked);
                return true;
            }

            return false;
        });
    }, []);

    return lock;
};

export const useCreateAndAssignRequirement = () => {
    const { assignToSection } = useRequirementOperations();

    return useMutation(
        (
            { storage },
            selectedSection: Section,
            onCreate?: (liveRow: string) => void,
            partialRowProperties?: Partial<ComplianceMatrixRow>
        ) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

            const newRow = createComplianceMatrixRow(partialRowProperties);
            const newRowId = newRow.get("requirement").get("id");
            onCreate?.(newRowId);

            complianceMatrix.push(newRow);
            assignToSection(newRow.get("requirement")?.get("id"), selectedSection);
        },
        []
    );
};

type SectionProposalVariables = {
    requirement_blocks: RequirementBlock[];
    block_title: string;
    qa_toggle: boolean;
    win_themes?: string[];
};

export const useSseSectionProposal = (setIsGenerating: (loading: boolean) => void, options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const controllerRef = useRef(new AbortController());
    const { current: controller } = controllerRef;

    // @ts-ignore
    const { setToast } = useNotification();

    const generateSectionProposal = (body: SectionProposalVariables, id?: string) => {
        fetchEventSource(`${process.env.REACT_APP_BASE_URL}/proposal_generation/${id}/block/generate/sources/stream`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Workspace: `Workspace ${workspace_id}`,
                Authorization: `Bearer ${localValue}`,
                Accept: "application/json",
            },
            body: JSON.stringify(body),
            signal: controller.signal,
            ...options,
            onerror(err) {
                setToast.error({
                    title: "Unable to generate section",
                    msg: "We were unable to generate the section due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
                setIsGenerating(false);
                throw err;
            },
        });
    };

    const abortConnection = useCallback(() => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
    }, []);

    return { generateSectionProposal, abortConnection };
};

export const useTemplates = () => {
    const dispatch = useAppDispatch();
    const { templates } = useAppSelector((root) => root.templates);
    const { setToast } = useNotification();

    const getTemplates = useCallback(async () => {
        try {
            await dispatch(fetchTemplates());
        } catch {
            setToast.error({
                title: "Unable to retrieve templates",
                msg: "We were unable to retrieve templates due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { templates, getTemplates };
};

export const useTemplateDetails = (id?: string) => {
    const [templateDetails, setTemplateDetails] = useState<TemplateDetails>();

    const fetchTemplateDetails = useCallback((id?: string) => {
        if (!id) return;
        getTemplateDetails(id)
            .then((resp) => setTemplateDetails(resp.data))
            .catch(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id) {
            fetchTemplateDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { templateDetails, fetchTemplateDetails };
};

export const useSearchRequirements = (searchTerm: string, sheetRequirements: ImmutableComplianceMatrixRow[]) => {
    const [searchResults, setSearchResults] = useState<ImmutableComplianceMatrixRow[]>(sheetRequirements);

    const getSearchResults = useMemo(() => {
        const filteredResults = sheetRequirements.filter((row) =>
            (row.requirement.content || row.requirement.summarized_content)
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
        );
        return filteredResults;
    }, [searchTerm, sheetRequirements]);

    useDebounce(
        () => {
            if (!!searchTerm.trim()) setSearchResults(getSearchResults);
        },
        300,
        [searchTerm, getSearchResults]
    );

    return { searchResults: !searchTerm.trim() ? sheetRequirements : searchResults };
};

export const useUpdateTemplate = () =>
    useMutation(({ storage }, templateProperties: Partial<Template>) => {
        (storage.get("framework") as Storage["framework"])?.set(
            "template",
            new LiveObject({
                id: (storage.get("framework") as Storage["framework"])?.get("template")?.get("id"),
                ...templateProperties,
            })
        );
    }, []);

type GenerateRecommendedAPIContentVariables = {
    user_query: string;
    project_id: string;
    source_types: SourceType[];
    file_id_filters: string[];
    past_ideas: string[];
};

export const useGenerateRecommendedAPIContent = (options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const controllerRef = useRef(new AbortController());
    const { current: controller } = controllerRef;

    const generateRecommendedContent = (body: GenerateRecommendedAPIContentVariables) => {
        fetchEventSource(`${process.env.REACT_APP_BASE_URL}/requirements/generate/writing/ideas/v2/stream`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Workspace: `Workspace ${workspace_id}`,
                Authorization: `Bearer ${localValue}`,
                Accept: "application/json",
            },
            body: JSON.stringify(body),
            signal: controller.signal,
            ...options,
        });
    };

    const abortConnection = useCallback(() => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
    }, []);

    return { generateRecommendedContent, abortConnection };
};
