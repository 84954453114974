/** @jsxImportSource @emotion/react */
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Button } from "components/editor/components";
import Icon from "components/atoms/icons/Icon";
import { Modal } from "components/organisms/modal";
import { useMemo, useState } from "react";
import ConfirmModal from "components/ConfirmModal";
import { TemplateDetails } from "types/Templates";
import tw from "twin.macro";
import { useMutation } from "liveblocks.config";
import { LiveList, LiveObject } from "@liveblocks/client";
import { transformFrameworkToSavePayload } from "./utils";
import { createTemplate } from "api/api";
import { useHandleApiError } from "hook/useHandleApiError";
import { useNotification } from "context/notificationContext";
import { fetchTemplates } from "store/reducers/templates/templatesReducer";
import { useAppDispatch } from "store/storeTypes";
import { toggleTemplatesModal } from "store/reducers/modalsSlice";
import { Volume } from "../CopilotSchemaImmutableTypes";
import { Storage } from "../CopilotSchemaTypes";

const TemplateSelector = () => {
    const { setToast } = useNotification();
    const dispatch = useAppDispatch();
    const [clearConfirmModalOpen, setClearConfirmModalOpen] = useState<boolean>(false);
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [newTemplate, setNewTemplate] = useState<Partial<Pick<TemplateDetails, "name" | "data">>>({ name: "" });
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isClearing, setIsClearing] = useState<boolean>(false);

    const { setError } = useHandleApiError();

    const clearTemplate = useMutation(({ storage }) => {
        setIsClearing(true);
        const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

        (storage.get("framework") as Storage["framework"])?.update({
            volumes: new LiveList([]),
            template: new LiveObject({}),
        });

        matrix?.forEach((row: any) => {
            row.set(
                "proposal_reference",
                new LiveObject({
                    volume_id: "",
                    section_id: "",
                    paragraph: "",
                })
            );
        });

        setTimeout(() => {
            setIsClearing(false);
            setClearConfirmModalOpen(false);
        }, 1500);
    }, []);

    const menuItems = useMemo(
        () => [
            {
                key: 1,
                label: (
                    <div className="flex items-center gap-2">
                        <Icon name="TaskSquare" className="w-4 h-4" />
                        Import Template
                    </div>
                ),
                onSelect: () => dispatch(toggleTemplatesModal({ open: true })),
            },
            // ...(flags.templateExtraction
            //     ? [
            //           {
            //               key: 2,
            //               label: (
            //                   <div className="flex items-center gap-2">
            //                       <LuLayoutList className="text-[15px] w-4 mx-auto" />
            //                       Extract Template
            //                   </div>
            //               ),
            //               onSelect: () => {
            //                   dispatch(toggleTemplateExtractionModal({ open: true }));
            //                   dispatch(setExtractionMode(ExtractionMode.CopyPaste));
            //               },
            //           },
            //       ]
            //     : []),
            // ...(flags.templateExtractionFromRequirements
            //     ? [
            //           {
            //               key: 3,
            //               disabled: !validRequirements.length,
            //               tooltipProps: {
            //                   contentProps: { side: "left", sideOffset: 1 } as TooltipContentProps,
            //                   disabled: !!validRequirements.length,
            //                   content: "You must have at least one requirement",
            //               },
            //               label: (
            //                   <div className="flex items-center gap-2">
            //                       <VscGitPullRequestGoToChanges className="text-[16px] w-4 mx-auto" />
            //                       Generate Template
            //                       <Tooltip
            //                           contentProps={{ alignOffset: -30, align: "end" }}
            //                           disabled={!validRequirements.length}
            //                           content="Generate a template based on requirements"
            //                       >
            //                           <Icon name="InfoCircleBold" className="-ml-1 w-[15px] h-[15px]" />
            //                       </Tooltip>
            //                   </div>
            //               ),
            //               onSelect: () => {
            //                   dispatch(toggleTemplateExtractionFromRequirementsModal({ open: true }));
            //                   dispatch(setExtractionMode(ExtractionMode.Requirements));
            //               },
            //           },
            //       ]
            //     : []),
            {
                key: 3,
                label: (
                    <div className="flex items-center gap-2">
                        <Icon name="Save" className="w-4 h-4" />
                        Save New Template
                    </div>
                ),
                onSelect: () => setSaveModalOpen(true),
            },
            {
                key: 4,
                label: (
                    <div className="flex items-center gap-2">
                        <Icon name="EraserSquare" className="w-4 h-4" />
                        Clear Template
                    </div>
                ),
                onSelect: () => {
                    setClearConfirmModalOpen(true);
                },
            },
        ],
        [dispatch]
    );

    const onSave = useMutation(
        async ({ storage }) => {
            if (isSaving) return;
            setIsSaving(true);
            try {
                const framework = storage?.get("framework") as Storage["framework"];
                const immutableVolumes = (framework.get("volumes")?.map((vol) => vol.toImmutable()) || []) as Volume[];
                const data = { volumes: transformFrameworkToSavePayload(immutableVolumes) };

                const {
                    data: { id },
                } = await createTemplate({ name: newTemplate.name, data });

                framework?.get("template")?.set("id", id);

                try {
                    await dispatch(fetchTemplates());
                } catch {
                    setToast.error({
                        title: "Unable to save template",
                        msg: "We were unable to save the template due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                }
                setSaveModalOpen(false);
                setNewTemplate({ name: "" });
            } catch (err) {
                setError("Failed to create the template. Please refresh and try again.");
            } finally {
                setIsSaving(false);
            }
        },
        [newTemplate]
    );

    return (
        <>
            <DropdownMenu items={menuItems}>
                <Button variant="outline" size="md" className="!pr-3 gap-2 bg-white">
                    Templates
                    <Icon name="CarrotDown" />
                </Button>
            </DropdownMenu>
            <Modal
                open={saveModalOpen}
                onOpenChange={(open) => {
                    setSaveModalOpen(open);
                    setNewTemplate({ name: "" });
                }}
                header="New Template"
                body={
                    <div className=" w-full gap-2 flex-col justify-start items-start inline-flex px-6">
                        <div className="text-gray-500 text-sm font-normal leading-snug">Template Name</div>
                        <input
                            autoFocus
                            value={newTemplate.name}
                            onChange={(e) => setNewTemplate((prev) => ({ ...prev, name: e.target.value }))}
                            className="w-full px-3 py-2 bg-white rounded-lg border border-neutral-300 outline-0"
                        />
                    </div>
                }
                footer={
                    <>
                        <Button
                            size="md"
                            variant="outline"
                            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                            onClick={() => {
                                setSaveModalOpen(false);
                                setNewTemplate({ name: "" });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="md"
                            variant="primary"
                            loading={isSaving}
                            css={[isSaving && tw`pointer-events-none`]}
                            disabled={!newTemplate.name?.length}
                            onClick={() => onSave()}
                        >
                            Save
                        </Button>
                    </>
                }
            />
            <ConfirmModal
                open={clearConfirmModalOpen}
                onClose={(proceed) => !proceed && setClearConfirmModalOpen(false)}
                onProceed={(proceed) => {
                    if (proceed) {
                        clearTemplate();
                    }
                }}
                loading={isClearing}
                proceedLabel="Clear"
                title="Are you sure you want to clear this template?"
                subTitle={
                    <div className="text-stone-900 text-sm font-normal">
                        This is an irreversible operation and will remove all progress associated with the draft.
                    </div>
                }
            />
        </>
    );
};

export default TemplateSelector;
