import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContractManagerItems } from "api/api";

export const getContractManagerData = createAsyncThunk("contractManagerSlice/getContractManagerData", async () => {
    return getContractManagerItems().then((res) => {
        return res.data;
    });
});

const initialState = {
    data: [],
    isLoadingContractManagerData: false,
};

export const contractManagerReducerSlice = createSlice({
    name: "contractManagerSlice",
    initialState,
    reducers: {
        setContractManager: (state, action) => {
            state.data = action.payload.data;
            state.isLoadingContractManagerData = action.payload.loading;
        },
        appendContract: (state, action) => {
            state.data = [...state.data, action.payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getContractManagerData.pending, (state) => {
                state.isLoadingContractManagerData = true;
            })
            .addCase(getContractManagerData.fulfilled, (state, action) => {
                state.data = action.payload || [];
                state.isLoadingContractManagerData = false;
            })
            .addCase(getContractManagerData.rejected, (state) => {
                state.isLoadingContractManagerData = false;
            });
    },
});

export const { setContractManager, appendContract } = contractManagerReducerSlice.actions;

export default contractManagerReducerSlice.reducer;
