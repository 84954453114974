import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toggleAskAiInputWthDrpDown: false,
    prevDomSelection: {},
    isTextAreaHaveValue: false,
    colorShouldMatchOfSelectioAndUserLogo: null,
    typingFinished: false,
    toggleEditorIfYjsHasNoData: false,
    editorPrevSelection: {},
    storeEditorOfYJSTipTap: null,
    userHasExistingDocumentType: false,
    createdNewPropsalDoc: false,
    selectedText: "",
    isSlateEditorActive: false,
    mediaUrls: {},
    openMediaModal: false,
    selectedMediaFile: null,
    previewMediaFile: null,
};

export const yjsEditor = createSlice({
    name: "yjsEditor",
    initialState,
    reducers: {
        isTextAreaHaveValueAction: (state, action) => {
            state.isTextAreaHaveValue = action.payload;
        },
        colorShouldMatchOfSelectioAndUserLogoAction: (state, action) => {
            state.colorShouldMatchOfSelectioAndUserLogo = action.payload;
        },
        prevDomSelectionAction: (state, action) => {
            state.prevDomSelection = action.payload;
        },
        typewrtierDivFinishesTypingAction: (state, action) => {
            state.typingFinished = action.payload;
        },
        editorPrevSelectionAction: (state, action) => {
            state.editorPrevSelection = action.payload;
        },
        selectedTextAction: (state, action) => {
            state.selectedText = action.payload;
        },
        createdNewPropsalDocumentAction: (state, action) => {
            state.createdNewPropsalDoc = action.payload;
        },
        resetEditorStateAction: (state) => {
            state.storeEditorOfYJSTipTap = null;
        },
        storeEditorStateAction: (state, action) => {
            state.storeEditorOfYJSTipTap = action.payload;
        },
        toggleEditorIfUserHasExistingDocumentAction: (state, action) => {
            state.userHasExistingDocumentType = action.payload;
        },
        resetToggleEditorIfUserHasExistingDocumentAction: (state) => {
            state.userHasExistingDocumentType = null;
        },
        toggleAskAiInputWthDrpDownAction: (state, action) => {
            state.toggleAskAiInputWthDrpDown = action.payload;
        },
        isSlateEditorActiveAction: (state, action) => {
            state.isSlateEditorActive = action.payload;
        },
        setMediaUrls: (state, action) => {
            state.mediaUrls = {
                ...state.mediaUrls,
                ...action.payload,
            };
        },
        setOpenMediaModal: (state, action) => {
            state.openMediaModal = action.payload;
        },
        setSelectedMediaFile: (state, action) => {
            state.selectedMediaFile = action.payload;
        },
        setPreviewMediaFile: (state, action) => {
            state.previewMediaFile = action.payload;
        },
    },
});

export const {
    storeEditorStateAction,
    resetEditorStateAction,
    editorPrevSelectionAction,
    toggleAskAiInputWthDrpDownAction,
    colorShouldMatchOfSelectioAndUserLogoAction,
    isTextAreaHaveValueAction,
    prevDomSelectionAction,
    createdNewPropsalDocumentAction,
    typewrtierDivFinishesTypingAction,
    toggleEditorIfUserHasExistingDocumentAction,
    selectedTextAction,
    isSlateEditorActiveAction,
    resetToggleEditorIfUserHasExistingDocumentAction,
    setMediaUrls,
    setOpenMediaModal,
    setSelectedMediaFile,
    setPreviewMediaFile,
} = yjsEditor.actions;

export default yjsEditor.reducer;
