import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
    fullscreen: boolean;
    sidebarVisible: boolean;
};

const initialState: State = {
    fullscreen: true,
    sidebarVisible: false,
};

const copilotReducer = createSlice({
    name: "copilotReducer",
    initialState,
    reducers: {
        setFullscreen: (state: State, action: PayloadAction<boolean>) => {
            state.fullscreen = action.payload;
        },
        setSidebarVisible: (state: State, action: PayloadAction<boolean>) => {
            state.sidebarVisible = action.payload;
        },
    },
});

export const { setFullscreen, setSidebarVisible } = copilotReducer.actions;

export default copilotReducer.reducer;
