/** @jsxImportSource @emotion/react */

import { signInDomainSSO } from "api/api";
import { useNotification } from "context/notificationContext";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "./Loading";
import tw from "twin.macro";

// -------------------- Login screen ----------------------
const SSOLoginPage = () => {
    let [searchParams] = useSearchParams();
    const [domain, setDomain] = useState(searchParams.get("domain") || "");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { setToast } = useNotification();

    const handleChange = (e) => {
        setDomain(e.target.value);
        setError(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);

        signInDomainSSO({ domain: domain })
            .then((resp) => {
                const url = resp?.data?.url;
                if (!url) {
                    setError(true);
                    return;
                }
                window.location.href = url;
            })
            .catch((err) => {
                let errType = err?.response?.status;
                switch (errType) {
                    case 401:
                        setToast.warning({
                            msg: "Email not found. Please contact sales@vultron.ai to sign up.",
                        });
                        break;
                    default:
                        setToast.error({
                            title: "Authentication failed",
                            msg: "We were unable to authenticate the login due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                        });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (loading) return <Loading />;

    return (
        <div className="">
            <div className="flex flex-col items-center justify-center font-primary-font">
                <h1 className="text-4xl font-bold py-1">Welcome to Vultron</h1>
                <p className="text-gray-600 py-2">Sign up or login using SSO.</p>
            </div>
            <form
                onSubmit={handleSubmit}
                className="flex flex-col items-start justify-start pt-6 font-primary-font w-[450px]"
            >
                <label className="text-gray-500 pb-2">Enter your organization domain</label>
                <input
                    type="text"
                    placeholder="Domain"
                    autoFocus
                    className="border-[#CCCCCC] focus:border-action border-2 rounded-lg p-3 w-full focus:outline-none"
                    css={[error && tw`border-red-500 focus:border-red-500 text-red-500`]}
                    value={domain}
                    onChange={handleChange}
                />
                {error && <div className="text-red-500 mt-1 mb-2 block text-[14px]">Domain is not valid</div>}
                <button
                    type="submit"
                    className="bg-action text-white justify-center flex my-2 w-full flex-col rounded-lg p-3 items-center text-base font-medium min-h-[60px]"
                >
                    Continue
                </button>
            </form>
        </div>
    );
};

export default SSOLoginPage;
