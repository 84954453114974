import { useEffect, useState } from "react";
import Chip from "../Chip";
import DropDown from "../DropDown";
import plusCircle from "../../Assets/plus-circle.svg";
import { CloseIconSm } from "../../utils/icons";
import { useSelector } from "react-redux";
import { deadlineOptions, publishedDateOptions } from "components/Search/SearchPageSearchBar";
import { statusList } from "config/data";
import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import Icon from "components/atoms/icons/Icon";

// =================== Contract management search component =======================
const ContractPageSearchBar = ({ selectedFilters, setSelectedFilters, newContract, setNewContract }) => {
    const dispatch = useAppDispatch();
    const [drpOptions, setDrpOptions] = useState([
        {
            name: "status",
            subName: "status",
            title: "Status",
            searchable: true,
            static: true,
            optionIcon: true,
            className: "right-0 !min-w-[220px]",
            options: [
                ...statusList.map((v) => {
                    return { name: v?.label, value: v?.label };
                }),
            ],
            multiSelect: true,
        },
        {
            name: "publishedDate",
            title: "Published Date",
            searchable: false,
            optionIcon: true,
            static: true,
            options: publishedDateOptions,
            className: "!min-w-[240px]",
            txtClass: "hover:bg-gray-100",
            btnClass: "hover:!text-black hover:!border-[#dbe0e5] min-h-[40px]",
            activeBtnClass: "hover:!text-action hover:!border-action !border-action",
            multiSelect: false,
        },
        {
            name: "response_deadline",
            title: "Deadline",
            searchable: false,
            optionIcon: true,
            static: true,
            options: deadlineOptions,
            className: "!min-w-[240px]",
            txtClass: "hover:bg-gray-100",
            btnClass: "hover:!text-black hover:!border-[#dbe0e5] min-h-[40px]",
            activeBtnClass: "hover:!text-action hover:!border-action !border-action",
            multiSelect: false,
        },
    ]);
    const { optionList, isLoadingUtils } = useSelector((store) => store.utils);

    // Select filter options
    const handleSelectChip = (name, e) => {
        let value = e.target.value || "";
        let prevList = selectedFilters[name] || [];
        let prevAvail = prevList?.find((v) => v === value);
        if (prevList && prevAvail) {
            let newList = prevList?.filter((p) => p !== value);
            if (newList?.length > 0) {
                setSelectedFilters({
                    ...selectedFilters,
                    [name]: newList,
                });
            } else {
                let obj = { ...selectedFilters };
                delete obj[name];
                setSelectedFilters(obj);
            }
            return;
        }
        setSelectedFilters({
            ...selectedFilters,
            [name]: drpOptions.find((b) => b.name === name).multiSelect ? [value, ...prevList] : [value],
        });
    };

    // Unselect a filter option
    const handleUnSelectOption = (keyName, value) => {
        let prevList = selectedFilters[keyName] || [];
        prevList = prevList?.filter((p) => p !== value);
        let updatedList = [];
        let obj = { ...selectedFilters };
        if (prevList.length > 0) {
            updatedList = prevList;
            obj = {
                ...obj,
                [keyName]: updatedList,
            };
        } else {
            delete obj[keyName];
        }
        setSelectedFilters(obj);
    };

    // set our menu option when data is fetched
    useEffect(() => {
        if (isLoadingUtils) return;
        let updatedList = drpOptions?.map((v) => {
            if (v?.static) return v;
            let newOpts = optionList[v?.subName] || optionList[v?.name] || [];
            return {
                ...v,
                options: newOpts,
            };
        });
        setDrpOptions(updatedList);
    }, [isLoadingUtils]);

    return (
        <>
            <div className="p-4 my-3 shadow-sm bg-white sticky top-0 left-0 z-[2]">
                <div className="flex w-full items-center justify-between gap-1">
                    {/* title */}
                    <div className="flex w-max items-center">
                        <h4 className="font-semibold text-[20px]">Projects</h4>
                    </div>
                    {/* DropDown */}
                    <div className="min-[1550px]:max-w-[874px] max-w-[820px] ml-auto">
                        <div className="flex items-center gap-2 justify-start flex-auto flex-wrap">
                            {drpOptions?.map((optItem, index) => (
                                <DropDown
                                    key={index}
                                    name={optItem?.name}
                                    title={optItem?.title}
                                    subTitle={optItem?.subTitle}
                                    multiSelect={optItem?.multiSelect}
                                    options={optItem?.options?.map((v) => v?.name || "")}
                                    selectedOptions={selectedFilters}
                                    handleOptionSelect={handleSelectChip}
                                    optionIcon={optItem?.optionIcon}
                                    searchInput={optItem?.searchable}
                                    className={optItem?.className || ""}
                                    textClass={optItem?.txtClass || "!max-w-[610px]"}
                                    btnClass={optItem?.btnClass || "min-h-[40px]"}
                                    activeBtnClass={optItem?.activeBtnClass || ""}
                                    itemClass={"hover:bg-[#f7f7f7]"}
                                    noTruncate
                                />
                            ))}
                            <button
                                className="bg-action flex text-white h-10 gap-1 py-2 px-3 text-sm items-center rounded-lg"
                                onClick={() => dispatch(toggleCreateProjectModal({ open: true }))}
                            >
                                <Icon name="PlusCircle" className="w-4 h-4" />
                                <span>New Project</span>
                            </button>
                        </div>
                        {/* filters */}
                        {Object.values(selectedFilters)?.length > 0 && (
                            <div className="flex flex-wrap items-center justify-start w-full mt-2 mr-2">
                                {Object?.keys(selectedFilters).map((mainItem, i) => {
                                    let obj = drpOptions?.find((v) => v?.name === mainItem);
                                    return selectedFilters[mainItem]?.map((item, ind) => (
                                        <Chip key={ind} className="flex items-center py-[9px] mt-1">
                                            <div className="flex flex-wrap flex-grow pl-1">
                                                <span className="block capitalize pr-1">{obj?.title}:</span>
                                                <span className="block">{item}</span>
                                            </div>
                                            <span
                                                className="ml-2 inline-flex justify-center w-4 cursor-pointer"
                                                role="button"
                                                onClick={() => handleUnSelectOption(mainItem, item)}
                                            >
                                                <CloseIconSm />
                                            </span>
                                        </Chip>
                                    ));
                                })}
                                <button
                                    className="text-action font-[500] text-sm mr-1 mt-2 px-2"
                                    onClick={() => {
                                        setSelectedFilters({});
                                    }}
                                >
                                    Clear All
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractPageSearchBar;
