import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Section } from "components/copilot/CopilotSchemaImmutableTypes";

type State = {
    templateRowEditable: string | null;
    selectedSection?: Section;
};

const initialState: State = {
    templateRowEditable: "",
    selectedSection: undefined,
};

const frameworkReducer = createSlice({
    name: "framework",
    initialState,
    reducers: {
        resetFrameworkState: () => {
            return initialState;
        },
        setTemplateRowEditable: (state: State, action: PayloadAction<string>) => {
            state.templateRowEditable = action.payload;
        },
        setFrameworkState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setTemplateRowEditable, setFrameworkState, resetFrameworkState } = frameworkReducer.actions;

export default frameworkReducer.reducer;
