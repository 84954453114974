import { FetchEventSourceInit, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";

export const useGenerateWinThemes = (options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");

    return (id: string) => {
        fetchEventSource(`${process.env.REACT_APP_BASE_URL}/proposal_generation/${id}/win_theme/generate/stream`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Workspace: `Workspace ${workspace_id}`,
                Authorization: `Bearer ${localValue}`,
                Accept: "application/json",
            },
            body: JSON.stringify({}),
            ...options,
        });
    };
};
