import { AvatarGroup } from "components/molecules/avatar-group";
import { useOthers } from "liveblocks.config";
import { uniqBy } from "lodash";
import { memo, useMemo } from "react";

const LiveOthers = ({ tab }: { tab: string }) => {
    const others = useOthers();

    const avatars = useMemo(
        () =>
            uniqBy(
                others
                    ?.filter(({ presence }) => presence.activePage === tab)
                    ?.map(({ connectionId, presence }) => ({
                        key: connectionId,
                        id: presence.name || "",
                        name: presence.name || "",
                        src: "",
                        alt: presence.name || "",
                        size: 32,
                        tooltip: true,
                    })),
                "id"
            ),
        [others, tab]
    );
    //@ts-ignore
    return <AvatarGroup maxCount={6} avatars={avatars || []} size={32} />;
};

export default memo(LiveOthers);
