/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import Popover from "components/atoms/popover";
import { WritingPrompt } from "components/copilot/CopilotSchemaImmutableTypes";
import AttachmentsPopover from "components/molecules/attachments-popover";
import EditableContent from "components/molecules/editable-content";
import useRequirementOperations from "hook/useRequirementOperations";
import { uniq } from "lodash";
import { Dispatch, HTMLAttributes, SetStateAction, forwardRef, useCallback, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuFiles, LuTrash2 } from "react-icons/lu";
import { useAppSelector } from "store/storeTypes";
import tw, { theme } from "twin.macro";
import { BaseFile } from "types/FileStorage";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

interface Props extends HTMLAttributes<HTMLDivElement> {
    reqId: string;
    prompt: WritingPrompt;
    editablePrompt: string;
    setEditablePrompt: Dispatch<SetStateAction<string>>;
    withOpacity?: boolean;
    disabled?: boolean;
    isDragging?: boolean;
}

const WritingPromptRow = forwardRef<HTMLDivElement, Props>(
    (
        { reqId, prompt, editablePrompt, setEditablePrompt, withOpacity, disabled, isDragging, style, ...dragProps },
        ref
    ) => {
        const { saveWritingPromptProperties, deleteWritingPrompt } = useRequirementOperations();
        const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
        const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
        const [localFiles, setLocalFiles] = useState(prompt.file_id_filters || []);
        const trackUserEvent = useTrackUserMetric();

        useEffect(() => {
            setLocalFiles(prompt.file_id_filters);
        }, [prompt.file_id_filters]);

        const handleSelectedFiles = useCallback((checked: boolean, files: BaseFile[]) => {
            if (checked) {
                setLocalFiles((prev) => uniq([...prev, ...files.map(({ id }) => id)]));
            } else setLocalFiles((prev) => prev.filter((id) => !files.find((file) => file.id === id)));
        }, []);

        const isEditing = prompt.id === editablePrompt;

        const handleClear = useCallback(
            (files: BaseFile[]) =>
                setLocalFiles((prev) => prev.filter((prevFileId) => !files.find((file) => file.id === prevFileId))),
            []
        );
        const onFileSave = useCallback(
            (open: boolean) => {
                !open && saveWritingPromptProperties(reqId, prompt.id, { file_id_filters: localFiles });
            },
            [localFiles, prompt.id, reqId, saveWritingPromptProperties]
        );

        return (
            <div
                ref={ref}
                className="bg-slate-200 text-start flex items-center justify-between gap-5 text-slate-600 rounded text-xs p-2"
                css={[
                    {
                        ...(isDragging && { boxShadow: theme`boxShadow.md` }),
                        zIndex: isDragging ? "2" : "auto",
                        opacity: withOpacity ? "0.3" : disabled ? ".5" : "1",
                        ...style,
                    },
                ]}
            >
                <div className="flex items-center gap-0.5 flex-1">
                    <div
                        className="z-[1] bg-transparent rounded py-1 text-gray-lightest hover:text-gray-darkest group-hover:opacity-100"
                        css={[{ cursor: isDragging ? "grabbing" : "grab" }]}
                        {...dragProps}
                    >
                        <Icon name="Draggable" className="w-3 h-3" />
                    </div>
                    <EditableContent
                        submitOnEnter
                        autoFocus={isEditing}
                        content={prompt.content || ""}
                        onContentUpdate={(val) => {
                            if (disabled) return;
                            setEditablePrompt("");
                            saveWritingPromptProperties(reqId, prompt.id, { content: val });

                            trackUserEvent("Drafts: Writing Prompt Updated", {
                                requirement_id: String(reqId),
                                word_count: getWordCount(val),
                            });
                        }}
                        css={[tw`text-xs p-1.5 flex-1 hover:bg-[#D4DEE9]`, disabled && tw`hover:bg-transparent`]}
                        heightProps={tw`overflow-hidden`}
                        textareaProps={{
                            placeholder: "Add writing prompt",
                            forceResetProps: [sidebarSize, actionsPanelOpen],
                            forceResetDelay: 150,
                            disabled,
                        }}
                    />
                </div>
                <div className="flex items-center gap-1.5">
                    <AttachmentsPopover
                        handleSelectedFiles={handleSelectedFiles}
                        onClear={handleClear}
                        onOpenChange={onFileSave}
                        selectedFiles={localFiles}
                    >
                        <div className="relative cursor-pointer flex justify-center items-center rounded-md h-7 w-7 bg-white border border-light shadow text-gray-600 duration-100 hover:bg-neutral-50 hover:border-gray-silver">
                            <LuFiles className="text-base text-action" />
                            {!!localFiles.length && (
                                <div className="bg-action text-white font-medium shadow-sharp-full rounded-full w-3.5 h-3.5 flex justify-center items-center text-[9px] absolute -top-1.5 -right-2">
                                    {localFiles.length}
                                </div>
                            )}
                        </div>
                    </AttachmentsPopover>
                    <Popover
                        contentProps={{ align: "start", css: tw`text-xs mx-0 overflow-hidden p-1` }}
                        content={
                            <button
                                onClick={() => deleteWritingPrompt(reqId, prompt.id)}
                                className="py-1.5 px-2 flex gap-2 items-center text-red-500 bg-transparent duration-100 hover:bg-slate-100 disabled:opacity-50"
                                disabled={disabled}
                            >
                                <LuTrash2 /> Delete
                            </button>
                        }
                    >
                        <div className="cursor-pointer rounded px-1 py-1 bg-transparent duration-100 hover:bg-slate-300">
                            <BsThreeDotsVertical />
                        </div>
                    </Popover>
                </div>
            </div>
        );
    }
);

export default WritingPromptRow;
