import { TemplateExtractionModal } from "components/modals/template-extraction/template-extraction-modal";
import TemplatesModal from "../Framework/TemplatesModal";
import { TemplateExtractionFromRequirementsModal } from "components/modals/template-extraction/template-extraction-from-requirements-modal";
import { useAppSelector } from "store/storeTypes";
import { RequirementExtractionModal } from "components/organisms/requirement-extraction-modal";
import WritingPromptIdeationModal from "components/modals/writing-prompt-ideation-modal/WritingPromptIdeationModal";
import StrictnessModal from "components/modals/strictness-modal";
import PreviewProposalModal from "components/modals/preview-proposal-modal/PreviewProposalModal";

const CopilotModals = () => {
    const {
        templateExtractionModal,
        requirementExtractionModal,
        templateExtractionFromRequirementsModal,
        templatesModal,
        writingPromptIdeationModal,
        strictnessModal,
        previewProposalModal,
    } = useAppSelector((store) => store.modals);

    return (
        <>
            <WritingPromptIdeationModal {...writingPromptIdeationModal} />
            <TemplatesModal {...templatesModal} />
            <TemplateExtractionModal {...templateExtractionModal} />
            <TemplateExtractionFromRequirementsModal {...templateExtractionFromRequirementsModal} />
            <RequirementExtractionModal {...requirementExtractionModal} />
            <StrictnessModal {...strictnessModal} />
            <PreviewProposalModal {...previewProposalModal} />
        </>
    );
};

export default CopilotModals;
