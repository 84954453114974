/** @jsxImportSource @emotion/react */

import ToastBanner from "components/atoms/toast-banner";
import { Button } from "components/editor/components";
import { HTMLProps, useEffect, useMemo, useState } from "react";
import { setBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { abortAutoResponse } from "store/reducers/requirementsSmartResponseReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

const SmartResponseBanner = (props: HTMLProps<HTMLDivElement>) => {
    const [confirm, setConfirm] = useState(false);
    const dispatch = useAppDispatch();
    const { generateResponseQueue, autoResponseActive } = useAppSelector((root) => root.requirementsSmartResponse);

    const estimatedGenerationTime = useMemo(() => Math.floor(Math.random() * (60 - 45) + 45), []);
    const seconds = generateResponseQueue.length * estimatedGenerationTime;
    const minutes = Math.floor(seconds / 60);

    useEffect(() => {
        if (autoResponseActive) {
            setConfirm(false);
        }
    }, [autoResponseActive]);

    return (
        <ToastBanner {...props}>
            <div className="w-full flex-1">
                <div className="flex flex-col gap-1">
                    <div className="text-sm text-white font-semibold">
                        {!confirm && (
                            <>
                                Responding to requirements
                                <span className="loading-ellipsis" />
                            </>
                        )}
                        {confirm && "Are you sure you want to stop?"}
                    </div>
                    <div className="flex items-center text-xs text-gray-400">
                        <span>{generateResponseQueue.length} in queue</span>
                        <div className="h-[14px] w-[1px] bg-gray-mid mx-2" />
                        <span>
                            Estimated:{" "}
                            {minutes < 1 ? `${seconds} seconds` : `${minutes} minute${minutes === 1 ? "" : "s"}`}
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-full flex gap-1 justify-end">
                {!confirm && (
                    <>
                        <Button
                            className="!text-white hover:!bg-[rgba(200,200,200,0.1)]"
                            onClick={() =>
                                dispatch(setBannerState({ smartResponse: { open: false, forceClose: false } }))
                            }
                            variant="ghost"
                            size="sm"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => setConfirm(true)}
                            variant="primary"
                            size="sm"
                            className="!bg-white !text-red-500  hover:!bg-neutral-200"
                        >
                            Stop
                        </Button>
                    </>
                )}
                {confirm && (
                    <div className="flex gap-1">
                        <Button
                            onClick={() => setConfirm(false)}
                            variant="ghost"
                            size="sm"
                            className="!text-white hover:!bg-[rgba(200,200,200,0.1)]"
                        >
                            No
                        </Button>
                        <Button
                            className="!bg-white !text-gray-darkest hover:!bg-neutral-200"
                            onClick={() => dispatch(abortAutoResponse())}
                            variant="primary"
                            size="sm"
                        >
                            Yes
                        </Button>
                    </div>
                )}
            </div>
        </ToastBanner>
    );
};

export default SmartResponseBanner;
