import {
    AddProjectIcon,
    BookMarkIcon,
    CancelIcon,
    InfoIcon,
    LeftArrow,
    RightArrow,
    RocketIcon,
    SendIcon,
    SuccessThumbsUp,
    SuccessThumbsDown,
    ThumbsDownIcon,
    ThumbsUpIcon,
    ExternalLink,
    CheckMarkIcon,
    Docs,
    NotesIcons,
} from "utils/icons";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { addToContract, getUserFeedbackDetails, userFeedBackPost } from "api/api";
import moment from "moment";
import { Spinner } from "utils/icons";
// icons
import pdf from "../../../Assets/pdf.svg";
import doc from "../../../Assets/doc.svg";
import xls from "../../../Assets/xls.svg";
import zip from "../../../Assets/zip.png";
import pptx from "../../../Assets/ppt.svg";
import commonFile from "../../../Assets/commonFile.png";
import { Button } from "components/editor/components";

// ----------- New Drawer/Sidebar component for recommended projects ------------
const RecommendedSideBar = ({
    handleCloseSideBar,
    cardsData,
    selectedCardIndex,
    setSelectedCardIndex,
    updateCard,
    tabName,
}) => {
    const [showFeedbackArea, setShowFeedbackArea] = useState(false);
    const [recommendationType, setRecommendationType] = useState("");
    const [feedBackArea, setFeedBackArea] = useState("");
    const [sideBarData, setSideBarData] = useState({});
    const [addingToProject, setAddingToProject] = useState(false);
    const [sendingFeedback, setSendingFeedback] = useState(false);
    const [feedBackData, setFeedBackData] = useState({});

    // select card according to index
    useEffect(() => {
        const selectedData = cardsData[selectedCardIndex] || {};
        setSideBarData(selectedData);
    }, [selectedCardIndex, cardsData]);

    useEffect(() => {
        const selectedData = cardsData[selectedCardIndex] || {};
        setAddingToProject(false);
        setFeedBackArea("");
        setRecommendationType("");
        setShowFeedbackArea(false);
        setSendingFeedback(false);
        setFeedBackData({});
        if (!selectedData?.id) return;
        // fetch user feedback if have
        getUserFeedbackDetails(selectedData?.id)
            .then((res) => {
                setFeedBackData(res?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [selectedCardIndex]);

    // handle send feedback
    const sendFeedback = () => {
        setSendingFeedback(true);
        const obj = {
            lead_id: sideBarData?.id,
            feedback_type: recommendationType === "good" ? "positive" : "negative",
            statement: feedBackArea,
        };
        userFeedBackPost(obj)
            .then(() => {
                getUserFeedbackDetails(sideBarData?.id)
                    .then((res) => {
                        setTimeout(() => {
                            setSendingFeedback(false);
                            setFeedBackData(res?.data);
                        }, 1500);
                    })
                    .catch((err) => {});
            })
            .catch((err) => {
                setSendingFeedback(false);
            });
    };

    const writeExplanation = (type, source) => {
        const explanations = {
            "company overview": "Aligns with your company overview.",
            "file storage": (
                <>
                    Corresponds with material from <strong>{source}</strong> in your Content Library.
                </>
            ),
            "past project": (
                <>
                    Similar to <strong>{source}</strong> from your past projects.
                </>
            ),
        };
        return explanations[type];
    };

    // handle back and forward buttons
    const handleIndexBtn = (move) => {
        setFeedBackArea("");
        if (move === "back" && selectedCardIndex > 0) {
            setSelectedCardIndex(selectedCardIndex - 1);
        } else if (move === "forward" && selectedCardIndex < cardsData.length - 1) {
            setSelectedCardIndex(selectedCardIndex + 1);
        }
    };

    // render img icon based on type
    const imgIcon = (type) => {
        let img = <img width={32} src={commonFile} alt="" />;
        switch (type) {
            case "pdf":
            case "application/pdf":
                img = <img src={pdf} alt="" />;
                break;
            case "doc":
            case "docx":
            case "application/doc":
            case "application/docx":
                img = <img src={doc} alt="" />;
                break;
            case "xls":
            case "xlsx":
            case "application/xls":
            case "application/xlsx":
                img = <img src={xls} alt="" />;
                break;
            case "pptx":
            case "potx":
            case "ppsx":
                img = <img width={32} src={pptx} alt="" />;
                break;
            case "zip":
                img = <img width={32} src={zip} alt="" />;
                break;
            default:
                img = <img width={32} src={commonFile} alt="" />;
        }
        return img;
    };

    const getSourceDetails = (source_type, fileName = "") => {
        const fileExtension = fileName?.split(".")?.pop();
        let borderColor = "";
        let bgColor = "";
        let sourceTypeName = "";
        let IconComponent = imgIcon(fileExtension);
        source_type = source_type?.toString()?.toLowerCase();
        if (source_type === "capabilities statement") {
            IconComponent = <Docs width="41" />;
        } else if (source_type === "past project") {
            IconComponent = <NotesIcons width="41" />;
        }
        if (source_type === "file storage") {
            borderColor = "border-[#BDD2FF]";
            bgColor = "bg-[#EEF3FF]";
            sourceTypeName = "Content Library";
        } else if (source_type === "capabilities statement") {
            borderColor = "border-[#FFC79A]";
            bgColor = "bg-[#FFEFE2]";
            sourceTypeName = "Capabilites Statement";
        } else if (source_type === "past project") {
            borderColor = "border-[#D9BBFF]";
            bgColor = "bg-[#F5EDFF]";
            sourceTypeName = "Past Project";
        } else {
            borderColor = "border-[#D9BBFF]";
            bgColor = "bg-[#F5EDFF]";
            sourceTypeName = source_type;
        }
        return { IconComponent, borderColor, bgColor, sourceTypeName };
    };

    const formatTimeLeft = (date) => {
        const duration = moment(date).fromNow(true); // This will give us "a month", "2 months", etc.

        if (duration === "a month") return "one month";
        if (duration === "a day") return "one day";
        if (duration === "a year") return "one year";
        // ... Add any other checks you want here ...

        return duration;
    };

    // JSX
    return (
        <>
            <div className="flex flex-grow justify-between items-center sticky top-0 bg-white px-6 py-4 z-[1]">
                <div className="flex gap-2">
                    {sideBarData?.state === "bookmarked" ? (
                        <span
                            className="cursor-pointer select-none"
                            role="button"
                            onClick={() => updateCard(sideBarData?.id, "recommended")}
                        >
                            <BookMarkIcon fill="#F5C150" stroke="#F5C150" />
                        </span>
                    ) : (
                        <span
                            className="cursor-pointer select-none"
                            role="button"
                            onClick={() => updateCard(sideBarData?.id, "bookmarked")}
                        >
                            <BookMarkIcon />
                        </span>
                    )}
                    <Link to={`/dashboard/search/contracts-details/?id=${sideBarData?.reference_id}`} target="_blank">
                        <ExternalLink />
                    </Link>
                </div>
                <div className="flex items-center gap-4">
                    <div className="flex gap-2 items-center">
                        <button
                            className={`${
                                selectedCardIndex === 0 ? "opacity-50 cursor-not-allowed" : "opacity-100 cursor-pointer"
                            } w-[36px] h-[36px] flex justify-center items-center rounded-sm bg-[#F1F2F2]`}
                            onClick={() => {
                                selectedCardIndex !== 0 && handleIndexBtn("back");
                            }}
                            disabled={selectedCardIndex === 0}
                        >
                            <LeftArrow />
                        </button>
                        <button
                            className={`${
                                selectedCardIndex >= cardsData.length - 1
                                    ? "opacity-50 cursor-not-allowed"
                                    : "opacity-100 cursor-pointer"
                            } w-[36px] h-[36px] flex justify-center items-center rounded-sm bg-[#F1F2F2]`}
                            onClick={() => {
                                handleIndexBtn("forward");
                            }}
                            disabled={selectedCardIndex >= cardsData.length - 1}
                        >
                            <RightArrow />
                        </button>
                    </div>
                    <button
                        className="cursor-pointer bg-transparent"
                        onClick={() => (handleCloseSideBar ? handleCloseSideBar() : null)}
                    >
                        <CancelIcon />
                    </button>
                </div>
            </div>
            <div className="flex flex-col px-6 pb-6">
                <div className="text-[#1E1E1E] text-xl font-medium leading-7">{sideBarData?.title_name}</div>
                <div className="flex flex-wrap gap-2 items-center mt-5">
                    {sideBarData?.formatted_tags?.map((tag, ind) => (
                        <span
                            className="px-2 py-[1px] flex justify-center rounded-[4px] text-[#5B6B79] select-none items-center bg-[#F1F1F1] text-xs font-normal leading-5"
                            key={ind}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
                <div className="pt-7">
                    <span className="text-base font-medium leading-6 text-[#1E1E1E]">Key Information</span>
                    <div className="flex w-full py-4 flex-col gap-3">
                        <div className="flex  w-full ">
                            <div className="w-[35%] flex flex-col gap-3 text-[#5B6B79] text-sm font-medium leading-6">
                                Published Date
                            </div>
                            <div className="w-[65%]">
                                <span className=" flex flex-col gap-3 text-[#1E1E1E]  text-sm font-normal leading-6">
                                    {" "}
                                    {sideBarData?.publish_date
                                        ? moment(sideBarData?.publish_date).format("MMMM DD, YYYY")
                                        : "-"}
                                </span>
                            </div>
                        </div>
                        {/* ------------------- */}
                        <div className="flex  w-full">
                            <div className="w-[35%] flex flex-col gap-3 text-[#5B6B79] text-sm font-medium leading-6">
                                Deadline
                            </div>
                            <div className="w-[65%]">
                                <span className="flex gap-1 text-[#1E1E1E] text-sm font-normal leading-6">
                                    {sideBarData?.deadline ? moment(sideBarData.deadline).format("MMMM DD, YYYY") : "-"}
                                    <span className="text-[#D69D21] text-xs font-normal leading-6">
                                        {" "}
                                        {sideBarData?.deadline ? `(${formatTimeLeft(sideBarData.deadline)} left)` : ""}
                                    </span>
                                </span>
                            </div>
                        </div>
                        {/* ------------------- */}
                        <div className="flex  w-full">
                            <div className="w-[35%] flex flex-col gap-3 text-[#5B6B79] text-sm font-medium leading-6">
                                Stage
                            </div>
                            <div className="w-[65%]">
                                <span className=" flex flex-col gap-3 text-[#1E1E1E]  text-sm font-normal leading-6">
                                    {" "}
                                    {sideBarData?.stage || "-"}
                                </span>
                            </div>
                        </div>
                        {/* ------------------- */}
                        <div className="flex  w-full">
                            <div className="w-[35%] flex flex-col gap-3 text-[#5B6B79] text-sm font-medium leading-6">
                                Department
                            </div>
                            <div className="w-[65%]">
                                <span className=" flex flex-col gap-3 text-[#1E1E1E]  text-sm font-normal leading-6">
                                    {" "}
                                    {sideBarData?.client_name || "-"}
                                </span>
                            </div>
                        </div>
                        {/* ------------------- */}
                        <div className="flex  w-full">
                            <div className="w-[35%] flex flex-col gap-3 text-[#5B6B79] text-sm font-medium leading-6">
                                Place of Performance
                            </div>
                            <div className="w-[65%]">
                                <span className=" flex flex-col gap-3 text-[#1E1E1E]  text-sm font-normal leading-6">
                                    {" "}
                                    {sideBarData?.place_of_performance || "-"}
                                </span>
                            </div>
                        </div>
                        {/* ------------------- */}
                        <div className="flex  w-full">
                            <div className="w-[35%] flex flex-col gap-3 text-[#5B6B79] text-sm font-medium leading-6">
                                Point of Contact
                            </div>
                            <div className="w-[65%]">
                                <span className=" flex flex-col gap-3 text-[#1E1E1E]  text-sm font-normal leading-6">
                                    {" "}
                                    {sideBarData?.poc_email || "-"}
                                </span>
                            </div>
                        </div>
                        {/* ------------------- */}
                    </div>
                </div>

                <div className="flex flex-col gap-3 mt-6">
                    <span className="text-[#1E1E1E] text-base font-medium leading-6">Overview</span>
                    {sideBarData?.description ? (
                        <p className="text-sm font-normal leading-[22px]">{sideBarData?.description}</p>
                    ) : (
                        <div className="h-[100px] flex justify-center items-center">
                            <span className="text-[#5B6B79] text-sm font-normal leading-[18px]">
                                No overview available
                            </span>
                        </div>
                    )}
                </div>
                <div className="bg-[#F5F7FE] p-4 pl-6 rounded-lg flex items-center my-8">
                    <div className="relative max-h-[77.5px] w-[155px]">
                        <div className="overflow-hidden max-h-full top-[0] pointer-events-none">
                            <CircularProgressbar
                                value={Math.round(sideBarData?.match_score * 100) || 0}
                                circleRatio={0.5}
                                strokeWidth={9}
                                styles={{
                                    root: {
                                        width: "155",
                                        height: "155",
                                        fill: "none",
                                        transform: "rotate(0.75turn)",
                                    },
                                    path: { stroke: "#4680FF", strokeLinecap: "butt" },
                                    trail: { stroke: "#fff", strokeLinecap: "butt" },
                                    trailColor: "#fff",
                                    backgroundColor: "transparent",
                                }}
                            />
                        </div>
                        <div className="absolute pb-[1px] flex flex-col justify-end w-full px-5 left-[50%] h-full -translate-x-1/2 top-0">
                            <p className="text-[#1E1E1E] text-center text-lg font-bold">
                                {Math.round(sideBarData?.match_score * 100) || 0}%
                            </p>
                            <p className="text-[#5B6B79] text-center text-[10px] font-normal">Est. Requirements Met</p>
                        </div>
                    </div>
                    <div className="pr-14 pl-10 flex-grow">
                        <p className="text-[#5B6B79] w-full inline-block text-sm font-normal">
                            This opportunity is a {Math.round(sideBarData?.match_score * 100) <= 50 ? "good" : "strong"}{" "}
                            potential fit, with <b>{Math.round(sideBarData?.match_score * 100) || "--"}%</b> of the
                            requirements estimated to be satisfied.
                            <Tooltip.Provider delayDuration={150}>
                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <span className="ml-1 -mb-[1.3px] w-[5%] inline-block cursor-pointer">
                                            <InfoIcon width="15" height="15" />
                                        </span>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal className="">
                                        <Tooltip.Content
                                            side="bottom"
                                            align="end"
                                            className="text-xs -top-[16px] text-gray-500 z-[99999] p-3 flex justify-center items-center flex-col border-[2px] border-[#DBE0E5] bg-[#fff] shadow-xl w-[355px]  min-h-[87px] mr-[-1.5rem]  rounded-sm"
                                            sideOffset={3}
                                        >
                                            <div>
                                                <span className="flex gap-1 text-[#1E1E1E] text-[12px] font-semibold leading-6 items-center mb-1">
                                                    <span>
                                                        <RocketIcon />
                                                    </span>
                                                    <span>Improve your recommended opportunities</span>
                                                </span>
                                                <span className="text-[#5B6B79] text-[12px] font-normal mb-2">
                                                    Upload past proposals and performances to improve your
                                                    recommendations and estimated requirements satisfied.
                                                </span>
                                                <button className="bg-[#2A47AB] rounded-[4px] px-2 py-1 flex items-center justify-center gap-2 mt-3 text-#fff">
                                                    <Link
                                                        to="/dashboard/library"
                                                        className="text-[10px] font-medium leading- text-[#fff]"
                                                    >
                                                        Go to Content Library
                                                    </Link>
                                                    <span>
                                                        <SendIcon />
                                                    </span>
                                                </button>
                                            </div>
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>
                            </Tooltip.Provider>
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <span className="text-[#1E1E1E] text-base font-medium leading-6 pb-3">
                        Why this is a good fit...
                    </span>
                    {sideBarData?.explanation_sources && sideBarData?.explanation_sources.length > 0 ? (
                        <div className="my-1">
                            <ul className="text-[#1D2630] text-sm font-normal leading-5 list-disc pl-4">
                                {sideBarData?.explanation_sources?.map((source, index) => (
                                    <li key={index} className="mb-2">
                                        {writeExplanation(source.source_type, source.name)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <>
                            <div className="h-[100px] flex justify-center items-center">
                                <span className="text-[#BFBFBF] text-sm font-normal leading-[18px]">
                                    No explanations found
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div className="mt-5">
                    <span className="text-[#1E1E1E] text-base font-medium leading-6">
                        How the conclusion was drawn...
                    </span>
                    {sideBarData?.sources ? (
                        <div className="flex  w-full max-w-full gap-2 whitespace-nowrap overflow-x-auto pr-4 select-none">
                            {sideBarData?.sources?.map((source) => {
                                const { IconComponent, borderColor, bgColor, sourceTypeName } = getSourceDetails(
                                    source.source_type,
                                    source.name
                                );
                                return (
                                    <div className="py-4 cursor-default flex" key={source.reference_id}>
                                        <div
                                            className={`p-3 pr-4 flex gap-2 border ${borderColor} ${bgColor} rounded-md min-w-[223px] flex items-center w-max`}
                                        >
                                            <div>{IconComponent}</div>
                                            <div className="flex flex-col">
                                                <span className="text-[#1D2630] text-xs font-normal leading-5">
                                                    {" "}
                                                    {source.name}
                                                </span>
                                                <span className="text-[#5B6B79] text-[10px] font-normal leading-5">
                                                    {" "}
                                                    {sourceTypeName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <>
                            <div className="h-[100px] flex justify-center items-center">
                                <span className="text-[##BFBFBF] text-sm font-normal leading-[18px]">
                                    No sources found
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div className="h-[1px] w-full bg-[#F0F2F4] mt-2">
                    <span className="invisible">m,m </span>
                </div>
                <div className="mt-6">
                    <span className="text-[#1E1E1E] text-base font-medium leading-6">
                        Was this opportunity a good recommendation?
                    </span>
                    <div className="pt-4 flex gap-2 mb-4">
                        <button
                            disabled={feedBackData?.user}
                            className={`w-[179px] h-[32px] flex gap-2 rounded justify-center items-center disabled:cursor-default disabled:opacity-80  ${
                                recommendationType === "good" || feedBackData?.type === "positive"
                                    ? "bg-[#4CB592] text-white"
                                    : " bg-[#EAEAEA] text-[#000]"
                            }`}
                            onClick={() => {
                                setRecommendationType(recommendationType === "good" ? "" : "good");
                                recommendationType !== "good" ? setShowFeedbackArea(true) : setShowFeedbackArea(false);
                            }}
                        >
                            <span className="text-xs font-normal leading-6">Good Recommendation</span>
                            <span>
                                {recommendationType === "good" || feedBackData?.type === "positive" ? (
                                    <SuccessThumbsUp />
                                ) : (
                                    <ThumbsUpIcon />
                                )}
                            </span>
                        </button>
                        <button
                            disabled={feedBackData?.user}
                            className={`w-[179px] h-[32px] flex gap-2 rounded justify-center items-center disabled:cursor-default disabled:opacity-80 ${
                                recommendationType === "bad" || feedBackData?.type === "negative"
                                    ? "bg-[#E14747] text-white"
                                    : " bg-[#EAEAEA] text-[#000]"
                            }`}
                            onClick={() => {
                                setRecommendationType(recommendationType === "bad" ? "" : "bad");
                                recommendationType !== "bad" ? setShowFeedbackArea(true) : setShowFeedbackArea(false);
                            }}
                        >
                            <span className="text-xs font-normal leading-6">Bad Recommendation</span>
                            <span>
                                {recommendationType === "bad" || feedBackData?.type === "negative" ? (
                                    <SuccessThumbsDown />
                                ) : (
                                    <ThumbsDownIcon />
                                )}
                            </span>
                        </button>
                    </div>
                </div>
                <div>
                    {feedBackData?.user ? (
                        <div className="text-[#1E1E1E] text-xs flex gap-3 mt-2">
                            <span>Feedback Provided: {moment(feedBackData?.created_at).fromNow()}</span>
                            <div className="h-10px w-[1px] bg-black"></div>
                            <span>Provided by: {feedBackData?.username}</span>
                        </div>
                    ) : null}

                    {showFeedbackArea && (
                        <span className="text-[#2A47AB] text-sm font-normal leading-2">Leave Us Feedback</span>
                    )}

                    {(showFeedbackArea || feedBackData?.user) && (
                        <div className="relative">
                            <textarea
                                readOnly={feedBackData?.user}
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                placeholder={feedBackData?.user ? feedBackData?.statement : "Enter your feedback here"}
                                className="w-full h-[119px] resize-none read-only:cursor-default bg-[#F2F2F3] placeholder:text-[#5B6B79] text-sm font-normal leading-6 focus:outline-none focus:ring-0 py-2 px-3 rounded-sm mt-2"
                                value={feedBackArea}
                                onChange={(e) => setFeedBackArea(e.target.value)}
                            />
                            {!feedBackData?.user && (
                                <span
                                    className={`${
                                        feedBackArea === "" ? "opacity-50" : "opacity-100"
                                    } bg-[#2A47AB] p-[2px] rounded-full cursor-pointer w-6 h-6 absolute flex justify-center items-center right-4 bottom-4 `}
                                    onClick={sendFeedback}
                                >
                                    {sendingFeedback ? <Spinner /> : <SendIcon />}
                                </span>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex items-center justify-end gap-4 mt-6">
                    <div className="text-gray-mid text-sm font-medium duration-150 hover:text-gray-400">
                        {sideBarData?.state === "archived" || tabName?.toLowerCase() === "archived" ? (
                            <button onClick={() => updateCard(sideBarData?.id, "recommended")}>Unarchive</button>
                        ) : (
                            <button onClick={() => updateCard(sideBarData?.id, "archived")}>Archive</button>
                        )}
                    </div>
                    <Button
                        variant="primary"
                        size="md"
                        disabled={addingToProject || sideBarData?.state === "converted"}
                        onClick={() => {
                            addToContract({ contract_id: sideBarData?.reference_id });
                            updateCard(sideBarData?.id, "converted", () => setAddingToProject(false));
                            setAddingToProject(true);
                        }}
                        className="gap-2"
                    >
                        {addingToProject ? (
                            <>
                                <span>
                                    <Spinner width={12} height={12} />
                                </span>
                                <span>Adding to projects</span>
                            </>
                        ) : sideBarData?.state === "converted" ? (
                            <>
                                <span>
                                    <CheckMarkIcon />
                                </span>
                                <span>Added to projects</span>
                            </>
                        ) : (
                            <>
                                <span>
                                    <AddProjectIcon />
                                </span>
                                <span>Add to Projects</span>
                            </>
                        )}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default RecommendedSideBar;
