/** @jsxImportSource @emotion/react */

import { useSearchParams, useLocation } from "react-router-dom";
import { useState, useEffect, Suspense, ChangeEvent, FormEvent, FocusEvent, useMemo } from "react";
import { ScreenSpinner } from "utils/icons";
import { getInternalContractDetails, updateInternalContractDetails } from "api/api";
import { RoomProvider } from "liveblocks.config";
import edit from "../../Assets/edit-2.png";
import { useNotification } from "context/notificationContext";
import { ProjectHeader } from "components/molecules/project-header";
import Copilot from "components/copilot/Copilot";
import { InternalContract } from "types/InternalContract";
import { clearProject, getProgressMetrics, getProject } from "store/reducers/projectReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { CopilotPresencePage } from "types/Presence";
import tw from "twin.macro";
import { useFlags } from "launchdarkly-react-client-sdk";
import { resetAssistantState } from "store/reducers/writing-assistant/writingAssistantReducer";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import { toggleActions, toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { TOP_BANNER } from "const-values/Banner";
import { VscLayoutSidebarRight, VscLayoutSidebarRightOff } from "react-icons/vsc";
import Tooltip from "components/atoms/tooltip";
import CopilotActionsPanel, { CopilotActionsOptions } from "components/copilot/copilot-actions-panel";
import { resetRequirementsDrawerState } from "store/reducers/copilot/requirementsDrawerReducer";
import { resetContentSearchState } from "store/reducers/copilot/contentSearchReducer";
import { resetFrameworkState } from "store/reducers/templates/frameworkReducer";
import { DragDropContext } from "react-beautiful-dnd";
import { useDrag as useBeautifulDrag } from "components/copilot/hooks";
import { useSyncProgress } from "components/copilot/project-progress/hooks";
import { resetRequirementsState } from "store/reducers/copilot/requirementsReducer";
import { DndContext } from "@dnd-kit/core";
import { useCatchResizeObserver, useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { resetBannerState } from "store/reducers/copilot/copilotBannerReducer";
import HelpDropdown from "./HelpDropdown";
import { getProjectAttachments, resetProjectAttachmentsState } from "store/reducers/projectAttachmentsReducer";

const ProjectDetails = () => {
    const [searchParams] = useSearchParams();
    const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
    const roomId = `copilot/${internalContractId}`;
    const { fullscreen, sidebarVisible } = useAppSelector((root) => root.copilot);
    const flags = useFlags();

    return (
        <div
            className="bg-[#f8f9fa] flex flex-row duration-150 absolute top-[65px] right-0 left-[234px] bottom-0 overflow-hidden"
            css={[
                fullscreen && tw`duration-200 top-0 left-0 right-0`,
                flags.topBanner && { top: fullscreen ? TOP_BANNER.HEIGHT : `calc(65px + ${TOP_BANNER.HEIGHT}px)` },
                fullscreen && sidebarVisible && tw`left-[234px]`,
            ]}
        >
            <RoomProvider id={roomId} initialPresence={{ isTyping: false, cursor: null, activePage: null, name: "" }}>
                <Suspense
                    fallback={
                        <div className="p-20 w-full my-auto flex items-center justify-center">
                            <ScreenSpinner />
                        </div>
                    }
                >
                    <ProjectRoom />
                </Suspense>
            </RoomProvider>
        </div>
    );
};

export default ProjectDetails;

const ProjectRoom = () => {
    useCatchResizeObserver();
    const { setToast } = useNotification();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const tabName = searchParams.get("tab")?.toLocaleLowerCase() || CopilotPresencePage.Project;
    const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
    const dispatch = useAppDispatch();
    const { open } = useAppSelector((root) => root.copilotDrawer);
    const fullscreen = useAppSelector((root) => root.copilot.fullscreen);
    const [internalContractDetails, setInternalContractDetails] = useState<InternalContract>({});
    const [contractDetails, setContractDetails] = useState({});
    const [title, setTitle] = useState("");
    const [titleEditable, setTitleEditable] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const { sensors, handleDragCancel, handleDragEnd, handleDragStart } = useDrag();
    const flags = useFlags();
    useSyncProgress();

    useEffect(() => {
        if (!internalContractId) return;
        dispatch(resetBannerState());
        dispatch(getProject(internalContractId));
        dispatch(getProgressMetrics(internalContractId));
        dispatch(fetchFileStorage());
        dispatch(fetchFileStorage());
        dispatch(getProjectAttachments(internalContractId));
        const intervalId = setInterval(() => dispatch(getProject(internalContractId)), 20000);
        return () => {
            clearInterval(intervalId);
            dispatch(clearProject());
            dispatch(resetAssistantState());
            dispatch(resetRequirementsDrawerState());
            dispatch(resetContentSearchState());
            dispatch(resetFrameworkState());
            dispatch(resetRequirementsState());
            dispatch(resetProjectAttachmentsState());
        };
    }, [dispatch, internalContractId]);

    // Fetch contract information and mark as viewed
    useEffect(() => {
        if (!internalContractId) return;
        getInternalContractDetails(internalContractId)
            .then((res) => {
                setInternalContractDetails(res?.data || {});
                const contractDetails = { ...res?.data?.internal_contract?.contract, notes: res?.data?.notes };
                setContractDetails(contractDetails || {});
            })
            .catch((err) => {
                setToast.error({
                    title: "Unable to load project",
                    msg: "We were unable to load the project due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {});
        if (forceRefresh) dispatch(getProject(internalContractId));

        setForceRefresh(false);
    }, [internalContractId, forceRefresh]);

    useEffect(() => {
        const internalCDetails = JSON.parse(JSON.stringify(internalContractDetails));
        setTitle(internalCDetails?.internal_contract?.project_name);
    }, [internalContractDetails]);
    useEffect(() => {
        if (fullscreen) dispatch(toggleAssistant(true));
    }, [dispatch, fullscreen]);

    const titleChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setTitle(value);
    };

    const changeTitle = async (e: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>) => {
        e.preventDefault();

        setTitleEditable(false);
        await updateInternalContractDetails(internalContractId, { project_name: title });
    };

    const editTitle = () => {
        setTitleEditable(true);
    };

    const isProjectLoading = useMemo(
        () =>
            (internalContractDetails && Object.keys(internalContractDetails).length === 0) ||
            (contractDetails && Object.keys(contractDetails).length === 0),
        [contractDetails, internalContractDetails]
    );
    const dragEnd = useBeautifulDrag();

    return (
        <DragDropContext onDragEnd={dragEnd}>
            <DndContext
                sensors={sensors}
                collisionDetection={verticalSortableListCollisionDetection}
                onDragEnd={(event) => handleDragEnd(event)}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
            >
                <div
                    className="bg-[#f8f9fa] flex gap-2 p-2 h-full flex-col w-full z-[2] duration-150"
                    css={[open && fullscreen && tw`w-[calc(100vw-460px)] 2xl:w-[calc(100vw-525px)]`]}
                >
                    <ProjectHeader
                        style={{ margin: 0, paddingLeft: 16 }}
                        isLoading={isProjectLoading}
                        back={
                            tabName !== CopilotPresencePage.AskAi
                                ? `/dashboard/contracts`
                                : `/dashboard/contracts/details/?tab=project&id=${internalContractId}&chatId=0ds`
                        }
                        actions={
                            fullscreen ? (
                                <div className="flex items-center gap-2">
                                    {flags.intercom && <HelpDropdown />}
                                    <Tooltip content={open ? "Close panel" : "Open panel"}>
                                        <button
                                            className="text-[22px] h-10 w-10 text-action rounded-full flex justify-center items-center duration-150 bg-transparent hover:bg-action-lightest hover:brightness-95"
                                            onClick={() => dispatch(toggleActions(!open))}
                                        >
                                            {open ? <VscLayoutSidebarRight /> : <VscLayoutSidebarRightOff />}
                                        </button>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className="flex gap-2">
                                    <CopilotActionsOptions />
                                </div>
                            )
                        }
                        header={
                            location.pathname.includes("/dashboard/contracts/details/") ? (
                                <div className="flex items-center w-full">
                                    <form onSubmit={changeTitle} className="w-full">
                                        {titleEditable ? (
                                            <input
                                                type="text"
                                                value={title}
                                                className="outline-none border-none text-[18px] w-full"
                                                onChange={titleChangeHandler}
                                                onBlur={changeTitle}
                                                autoFocus
                                            />
                                        ) : (
                                            <div className="contract-details-title flex items-center">
                                                <span className="truncate">{title}</span>
                                                {!titleEditable && tabName === "project" && (
                                                    <img
                                                        src={edit}
                                                        alt=""
                                                        className="cursor-pointer w-[16px] h-[16px] ml-[12px]"
                                                        onClick={editTitle}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            ) : (
                                <> {internalContractDetails?.internal_contract?.project_name || "-"} </>
                            )
                        }
                    />
                    <Copilot
                        isProjectLoading={isProjectLoading}
                        setContractDetails={setContractDetails}
                        contractDetails={contractDetails}
                        internalContractId={internalContractId}
                        internalContractDetails={internalContractDetails}
                        setForceRefreshContract={setForceRefresh}
                        setInternalContractDetails={setInternalContractDetails}
                    />
                </div>
                {fullscreen && <CopilotActionsPanel />}
            </DndContext>
        </DragDropContext>
    );
};
