import SwitchBtn from "components/SwitchBtn";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import { HTMLProps } from "react";
import { setExtractState } from "store/reducers/extract/ExtractReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

const VultronExtractionToggle = (props: HTMLProps<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { aiDetection } = useAppSelector((state) => state.extractReducer);

    return (
        <div className="flex items-center gap-6 py-1" {...props}>
            <div className="flex items-center gap-1 whitespace-nowrap">
                <h3 className="text-sm font-normal text-day-subtext">AI Optimized Extraction</h3>
                <Tooltip
                    content={
                        <div>
                            Vultron will automatically extract requirements that are not captured by the keywords.
                        </div>
                    }
                >
                    <Icon name="InfoCircle" className="w-4 h-4 text-day-subtext" />
                </Tooltip>
                <span className="text-xs font-medium text-action">Recommended</span>
            </div>
            <SwitchBtn
                value={aiDetection}
                handleChange={() => dispatch(setExtractState({ aiDetection: !aiDetection }))}
            />
        </div>
    );
};

export default VultronExtractionToggle;
