/** @jsxImportSource @emotion/react */

import upload from "Assets/upload-folder 1.svg";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "utils/helpers";
import { MAX_ATTACHMENT_SIZE } from "const-values/Project";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useUploadDocuments } from "./hooks";
import { ExtractionType, setExtractState } from "store/reducers/extract/ExtractReducer";
import { getFileIcon } from "utils/getFileIcon";
import { HiCheckCircle } from "react-icons/hi2";
import { useEffect, useMemo } from "react";
import { groupBy } from "lodash";
import useDocumentPreview from "hook/useDocumentPreview";
import { HiOutlineDownload } from "react-icons/hi";

const UploadDocuments = () => {
    const { selectedDocument, extractionType } = useAppSelector((state) => state.extractReducer);
    const { requirement_text_tasks, requirement_document_tasks, template_document_tasks, template_text_tasks } =
        useAppSelector((root) => root.autopilotHealthCheck);
    const { activeProject } = useAppSelector((state) => state.project);
    const dispatch = useAppDispatch();
    const govDocs = activeProject?.government_source || [];
    const internalDocs = activeProject?.internal_documents || [];
    const { onDrop, dropValidator, files: userUploadedFiles } = useUploadDocuments();
    const files = [...userUploadedFiles, ...internalDocs, ...govDocs];
    const { downloadFile } = useDocumentPreview();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: MAX_ATTACHMENT_SIZE,
        validator: dropValidator,
        accept: {
            "text/plain": [".txt"],
            "text/pdf": [".pdf"],
            "text/doc": [".docx"],
            "text/excel": [".xlsx"],
            "text/powerpoint": [".pptx"],
        },
        noClick: false,
        maxFiles: 30,
        multiple: true,
    });

    const groupedDocumentsInTaskQueue = useMemo(() => {
        if (extractionType === ExtractionType.Requirements) {
            return groupBy([...requirement_text_tasks, ...requirement_document_tasks], "reference_id");
        }

        return groupBy([...template_document_tasks, ...template_text_tasks], "reference_id");
    }, [
        extractionType,
        requirement_document_tasks,
        requirement_text_tasks,
        template_document_tasks,
        template_text_tasks,
    ]);

    useEffect(() => {
        if (typeof selectedDocument === "string" && groupedDocumentsInTaskQueue[selectedDocument]) {
            dispatch(setExtractState({ selectedDocument: "" }));
        }
    }, [dispatch, groupedDocumentsInTaskQueue, selectedDocument]);

    return (
        <div className="flex flex-col h-full">
            <div
                {...getRootProps()}
                className="flex-shrink-0 p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 mb-2 justify-center flex flex-col items-center rounded-lg bg-[#fafafa]"
            >
                <input multiple {...getInputProps()} />
                {isDragActive && (
                    <div className="absolute bg-gray-light flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
                        {" "}
                        Drop or Select File
                    </div>
                )}
                <img src={upload} alt="" className="w-12 h-12 flex-shrink" />
                <span className="font-semibold text-base">Drop or Select File</span>
                <span className="text-gray-text text-sm">We accept pdf, docx, txt, xlsx, and pptx files</span>
            </div>
            {!!files?.length && (
                <div
                    className="flex flex-col gap-1 flex-grow flex-shrink p-2 w-full overflow-y-auto min-h-[112px] max-h-[190px] border border-gray-light bg-gray-lightest rounded-lg"
                    css={[!!files?.length && tw`min-h-0`]}
                >
                    {!!files?.length &&
                        files?.map((file, i) => (
                            <button
                                key={i}
                                className="p-3 flex justify-between items-center w-full border border-gray-lightest bg-white shadow-sm rounded-lg duration-150 hover:border-action disabled:opacity-40 disabled:pointer-events-none"
                                onClick={() =>
                                    !groupedDocumentsInTaskQueue[file.id] &&
                                    dispatch(setExtractState({ selectedDocument: file?.id || file }))
                                }
                                css={[
                                    (file.id === selectedDocument || file === selectedDocument) &&
                                        tw`border-action bg-action-lightest`,
                                ]}
                                disabled={!!groupedDocumentsInTaskQueue[file.id]}
                            >
                                <div className="group flex items-center gap-3 px-3 flex-grow max-w-[95%]">
                                    <img
                                        className="w-[33px] h-[41px]"
                                        src={getFileIcon(file.file_type || file.type)}
                                        alt=""
                                    />
                                    <div className="flex flex-col items-start max-w-[93%]">
                                        <div className="flex items-center gap-2">
                                            <span className="block text-sm font-medium break-words overflow-hidden max-w-full truncate">
                                                {file.file_name || file.name}
                                            </span>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    downloadFile(file.download_url);
                                                }}
                                                className="opacity-0 group-hover:opacity-100 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
                                            >
                                                <HiOutlineDownload />
                                            </button>
                                        </div>
                                        <span className="text-xs">
                                            {file.file_size_formatted || formatBytes(file.size)}
                                        </span>
                                    </div>
                                </div>
                                {(file.id === selectedDocument || file === selectedDocument) && (
                                    <div className="w-8 flex justify-center items-center">
                                        <HiCheckCircle className="text-[30px] text-action" />
                                    </div>
                                )}
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
};

export default UploadDocuments;
