import { useCallback, useMemo, useState } from "react";

export const useSortable = (items) => {
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "", by_date: false });

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.direction === "ascending" && sortConfig.by_date === true) {
                    return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]);
                }
                if (sortConfig.direction === "descending" && sortConfig.by_date === true) {
                    return new Date(b[sortConfig.key]) - new Date(a[sortConfig.key]);
                }
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const handleSorting = useCallback((key, direction, by_date) => {
        return setSortConfig({ key, direction: direction, by_date: by_date || false });
    }, []);

    return {
        items: sortedItems,
        sortConfig,
        handleSorting,
    };
};
