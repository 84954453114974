import { Checkbox } from "components/atoms/checkbox";
import Tooltip from "components/atoms/tooltip";
import { BaseFile, WorkspaceFile } from "types/FileStorage";
import { getFileIcon } from "utils/getFileIcon";
import { styles } from "./styles";
import { useRef } from "react";
import useDocumentPreview from "hook/useDocumentPreview";
import { HiOutlineDownload } from "react-icons/hi";

type Props = {
    file: WorkspaceFile;
    selectedFiles: string[];
    handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
};

const FileRow = ({ file, handleSelectedFiles, selectedFiles }: Props) => {
    const fileNameRef = useRef<HTMLDivElement | null>(null);
    const { downloadFile } = useDocumentPreview();
    return (
        <div className={styles.checkboxItemRow}>
            <Tooltip
                key={file.id}
                disabled={(fileNameRef.current?.clientWidth || 0) < 314}
                disableHoverableContent
                content={file.name}
                contentProps={{ side: "left", align: "end" }}
            >
                <div className="group text-stone-800 flex items-center gap-2 text-sm flex-1 truncate text-start py-1.5">
                    <img src={getFileIcon(file.file_extension_type)} alt="" className="h-[18px]" />
                    <div ref={fileNameRef} className="truncate">
                        {file.name}
                    </div>
                    <button
                        onClick={() => downloadFile(file.download_url)}
                        className="opacity-0 group-hover:opacity-100 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
                    >
                        <HiOutlineDownload />
                    </button>
                </div>
            </Tooltip>
            <Checkbox
                checked={!!selectedFiles.includes(file.id)}
                onCheck={(checkValue) => handleSelectedFiles(checkValue, [file])}
            />
        </div>
    );
};

export default FileRow;
