/** @jsxImportSource @emotion/react */

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button } from "components/editor/components";
import { styles } from "components/molecules/dropdown-menu/styles";
import { HiOutlineSparkles } from "react-icons/hi2";
import tw from "twin.macro";
import { AiOption, ShortenOption, ToneOption, dynamicMenuItems, menuItems } from "./utils";
import { useCallback, useMemo, useState } from "react";
import { writingAssistant } from "api/api";
import { useNotification } from "context/notificationContext";

const handleMenuItemClick = (value: string, selectedText: string) => {
    let obj;

    switch (value) {
        case AiOption.ImproveWriting:
            obj = { actionType: "action", text: selectedText, action: "improve_writing" };
            break;
        case AiOption.Grammer:
            obj = { actionType: "action", text: selectedText, action: "fix" };
            break;
        case AiOption.Shorter:
            obj = { actionType: "action", text: selectedText, action: "shorten" };
            break;
        case AiOption.Longer:
            obj = { actionType: "action", text: selectedText, action: "expand" };
            break;
        case ToneOption.Professional:
            obj = { actionType: "tone", text: selectedText, tone: "professional" };
            break;
        case ToneOption.Concise:
            obj = { actionType: "tone", text: selectedText, tone: "concise" };
            break;
        case ToneOption.Official:
            obj = { actionType: "tone", text: selectedText, tone: "official" };
            break;
        case ToneOption.Confident:
            obj = { actionType: "tone", text: selectedText, tone: "confident" };
            break;
        case ToneOption.Persuasive:
            obj = { actionType: "tone", text: selectedText, tone: "persuasive" };
            break;
        case ToneOption.SolutionOriented:
            obj = { actionType: "tone", text: selectedText, tone: "solution" };
            break;
        case ToneOption.CustomerCentric:
            obj = { actionType: "tone", text: selectedText, tone: "customer" };
            break;
        case ToneOption.ActiveVoice:
            obj = { actionType: "tone", text: selectedText, tone: "active_voice" };
            break;
        case ShortenOption.ExtraShort:
            obj = { actionType: "action", text: selectedText, action: "shorten", wordLimit: 50 };
            break;
        case ShortenOption.Short:
            obj = { actionType: "action", text: selectedText, action: "shorten", wordLimit: 100 };
            break;
        case ShortenOption.Medium:
            obj = { actionType: "action", text: selectedText, action: "shorten", wordLimit: 200 };
            break;
        case ShortenOption.Long:
            obj = { actionType: "action", text: selectedText, action: "shorten", wordLimit: 500 };
            break;
        default:
            return;
    }

    return obj;
};

type Props = {
    container?: HTMLElement | null;
    isSelected: boolean;
    selectedText: string;
    replaceText: (text: string) => void;
    resetSelection: () => void;
    editableRef?: HTMLTextAreaElement | null;
    isReplacingText: boolean;
    setIsReplacingText: (isLoading: boolean) => void;
    dynamicItems?: { value: AiOption; onSelect: () => void }[];
};

const AiSmartLight = ({
    container,
    isReplacingText,
    setIsReplacingText,
    replaceText,
    selectedText,
    resetSelection,
    isSelected,
    editableRef,
    dynamicItems,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [subOpen, setSubOpen] = useState("");
    const { setToast } = useNotification();

    const onMenuItemSelect = useCallback(
        (value: AiOption | ToneOption | ShortenOption) => {
            const dynamicItem = dynamicItems?.find((item) => item.value === value);
            if (dynamicItem) {
                dynamicItem?.onSelect();
                return;
            }
            const obj = handleMenuItemClick(value, selectedText);
            if (!obj) return;
            // @ts-ignore
            const { actionType, text, payloadAction, tone, wordLimit, action, user_request } = obj;
            setIsReplacingText(true);
            writingAssistant(actionType, { text, tone, wordLimit, action, user_request, payloadAction })
                .then((res) => {
                    replaceText(res.data.response);
                })
                .catch(() => {
                    setToast.error({
                        title: "Unable to revise content",
                        msg: "We were unable to revise the content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                })
                .finally(() => setIsReplacingText(false));
        },
        [dynamicItems, replaceText, selectedText, setIsReplacingText, setToast]
    );

    const options = useMemo(
        () => [
            ...menuItems,
            ...dynamicMenuItems?.filter(({ value }) => dynamicItems?.find((item) => item.value === value)),
        ],
        [dynamicItems]
    );

    return (
        <DropdownMenu.Root
            modal
            open={open}
            onOpenChange={(o: boolean) => {
                if (!o) {
                    setSubOpen("");
                }
                setOpen(o);
            }}
        >
            <DropdownMenu.Trigger asChild>
                <button
                    id="ai-smart-light-button"
                    className="relative p-1 rounded-md !outline-none duration-150 hover:bg-gray-200 disabled:pointer-events-none"
                    disabled={!isSelected || isReplacingText}
                >
                    <HiOutlineSparkles
                        size={24}
                        className="text-gray-300 transition-colors"
                        css={[(isSelected || isReplacingText) && tw`text-gray-500`]}
                    />
                    <div
                        className="absolute top-[-14px] right-[-20px] px-1.5 py-0.5 text-[0.75rem] bg-[#A782C3] text-white rounded-md font-semibold opacity-0 transition-opacity"
                        css={[
                            (isSelected || isReplacingText) && tw`opacity-100`,
                            isReplacingText && tw`right-[-62px] pointer-events-none`,
                            !isReplacingText && tw`transition-none`,
                        ]}
                    >
                        {isReplacingText ? (
                            <>
                                Writing
                                <span className="loading-ellipsis" />
                            </>
                        ) : (
                            "AI"
                        )}
                    </div>
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal container={container}>
                <DropdownMenu.Content className={`${styles.content} ai-smartlight-menu`} align="start" alignOffset={-3}>
                    {options.map(({ key, icon, label, value, subitems }) =>
                        !!subitems?.length ? (
                            <DropdownMenu.Sub open={subOpen === value} key={key}>
                                <DropdownMenu.SubTrigger
                                    onMouseEnter={() => setSubOpen(value)}
                                    className={`${styles.subtrigger} ai-smartlight-menu`}
                                >
                                    <Button
                                        variant="ghost"
                                        className="text-stone-700 cursor-default font-normal w-full justify-start py-5 pr-4 text-sm"
                                        onPointerDown={() => {
                                            if (value === AiOption.Shorter) {
                                                onMenuItemSelect(value);
                                                setOpen(false);
                                                setSubOpen("");
                                            }
                                        }}
                                    >
                                        <div className="flex items-center gap-2">
                                            {icon}
                                            {label}
                                        </div>
                                    </Button>
                                </DropdownMenu.SubTrigger>
                                <DropdownMenu.Portal container={container}>
                                    <DropdownMenu.SubContent
                                        className={`${styles.subcontent} ai-smartlight-menu`}
                                        sideOffset={8}
                                    >
                                        {subitems.map((subitem) => (
                                            <DropdownMenu.Item
                                                key={subitem.key}
                                                className="!outline-0 ai-smartlight-menu"
                                            >
                                                <Button
                                                    variant="ghost"
                                                    className="text-stone-700 font-normal w-full justify-start py-5 pr-4 text-sm"
                                                    onPointerDown={() => {
                                                        onMenuItemSelect(subitem.value);
                                                        setOpen(false);
                                                        setSubOpen("");
                                                    }}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        {subitem.icon}
                                                        {subitem.label}
                                                    </div>
                                                </Button>
                                            </DropdownMenu.Item>
                                        ))}
                                    </DropdownMenu.SubContent>
                                </DropdownMenu.Portal>
                            </DropdownMenu.Sub>
                        ) : (
                            <DropdownMenu.Item
                                onMouseEnter={() => setSubOpen("")}
                                key={key}
                                className="!outline-0 ai-smartlight-menu"
                            >
                                <Button
                                    variant="ghost"
                                    className="text-stone-700 font-normal w-full justify-start py-5 pr-4 text-sm"
                                    onPointerDown={() => {
                                        if (value !== AiOption.Tone) onMenuItemSelect(value);
                                        setOpen(false);
                                        setSubOpen("");
                                    }}
                                >
                                    <div className="flex items-center gap-2">
                                        {icon}
                                        {label}
                                    </div>
                                </Button>
                            </DropdownMenu.Item>
                        )
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default AiSmartLight;
