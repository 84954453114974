const MEDIA_BASE = "image_search";

export const DRIVE_ROUTES = {
    media: {
        default: `${MEDIA_BASE}/directory`,
        search: `${MEDIA_BASE}/search`,
        directory: {
            create: `${MEDIA_BASE}/directory/create`,
            getRoot: `${MEDIA_BASE}/directory`,
            get: (id: string) => `${MEDIA_BASE}/directory/${id}`,
            update: (id: string) => `${MEDIA_BASE}/directory/${id}`,
            delete: (id: string) => `${MEDIA_BASE}/directory/${id}`,
            move: (id: string) => `${MEDIA_BASE}/directory/move/${id}`,
        },
        file: {
            create: `${MEDIA_BASE}/file/create`,
            get: (id: string) => `${MEDIA_BASE}/file/${id}`,
            update: (id: string) => `${MEDIA_BASE}/file/${id}`,
            delete: (id: string) => `${MEDIA_BASE}/file/${id}`,
            move: (id: string) => `${MEDIA_BASE}/file/move/${id}`,
        },
    },
} as const;
