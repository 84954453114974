import { getSingleDirectory } from "api/api";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import { Subdirectory, WorkspaceFile } from "types/FileStorage";

export type NestedDriveState = {
    current_directory: Subdirectory | null;
    files: WorkspaceFile[];
    subdirectories: Subdirectory[];
};

export const useNestedDriveMeta = () => {
    const [nextNestedDriveState, setNextNestedDriveState] = useState<{ [key: string]: NestedDriveState }>({});
    const [currentNestedDriveState, setCurrentNestedDriveState] = useState<NestedDriveState>();
    const [isLoading, setIsLoading] = useState(false);
    const { rootFiles: allDocs, rootSubdirectories: allFolders } = useAppSelector((root) => root.drive.fileStorage);

    const getNextNestedDriveState = useCallback(() => {
        if (isLoading) return;
        setIsLoading(true);
        const directoryRequests = (currentNestedDriveState?.subdirectories || allFolders).map(
            ({ id }) => getSingleDirectory(id) as Promise<AxiosResponse<NestedDriveState, any>>
        );
        Promise.all(directoryRequests)
            .then((results) => {
                setNextNestedDriveState(
                    results.reduce<{ [key: string]: NestedDriveState }>((acc, { data }) => {
                        if (data.current_directory) return { [data.current_directory?.id]: data, ...acc };
                        return acc;
                    }, {})
                );
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
    }, [isLoading, currentNestedDriveState?.subdirectories, allFolders]);

    useEffect(() => {
        getNextNestedDriveState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNestedDriveState?.current_directory?.id]);

    const docs = currentNestedDriveState?.files || allDocs;
    const folders = useMemo(
        () => currentNestedDriveState?.subdirectories || allFolders,
        [allFolders, currentNestedDriveState?.subdirectories]
    );

    return { docs, folders, nextNestedDriveState, currentNestedDriveState, setCurrentNestedDriveState, isLoading };
};
