import { HTMLProps, useEffect, useLayoutEffect, useRef } from "react";

interface Props extends HTMLProps<HTMLTextAreaElement> {
    flexible?: boolean;
    forceResetProps?: any[];
    forceResetDelay?: number;
    onHeightSet?: (height: number) => void;
}

export const FlexibleTextarea = ({ flexible, onHeightSet, forceResetDelay, forceResetProps, ...props }: Props) => {
    const ref = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
        if (ref.current && flexible) {
            ref.current.style.height = "0px";
            ref.current.style.height = `${ref.current.scrollHeight}px`;

            onHeightSet?.(ref.current.scrollHeight);
        }
    }, [flexible, onHeightSet, props.value]);

    useLayoutEffect(() => {
        if (!forceResetProps?.length) return;
        if (ref.current && flexible) {
            setTimeout(() => {
                if (!ref.current) return;
                ref.current.style.height = "0px";
                ref.current.style.height = `${ref.current.scrollHeight}px`;

                onHeightSet?.(ref.current.scrollHeight);
            }, forceResetDelay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceResetDelay, ...(forceResetProps || [])]);

    useEffect(() => {
        if (props.autoFocus)
            // autofocus and set selection to end
            // used to enforce autofocus
            setTimeout(() => {
                if (document.activeElement === ref.current) return;

                ref.current?.focus();
                ref.current?.setSelectionRange(ref.current?.value.length, ref.current?.value.length);
            }, 20);
    }, [props.autoFocus]);

    return <textarea ref={ref} {...props} />;
};
