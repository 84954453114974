/** @jsxImportSource @emotion/react */

import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import CopilotTable from "./CopilotTable";
import tw from "twin.macro";
import { Spinner } from "utils/icons";
import { downloadExcel, getInternalContractDetails } from "api/api";
import "react-circular-progressbar/dist/styles.css";
import { useLocalStorage } from "hook/useLocalStorage";
import { isEqual, omit } from "lodash";
import { filterOut, getAllSections } from "./utils";
import Icon from "components/atoms/icons/Icon";
import { Direction, SortKey, useCopilotSearch, useInsertRequirementRow } from "./hooks";
import { HiArrowUp, HiArrowDown } from "react-icons/hi2";
import LiveNameCursors from "components/molecules/live-name-cursors";
import {
    RESPONSE_TOLERANCE_OPTIONS,
    COLUMN_OPTIONS,
    COLUMN_OPTION_TO_FILTER_KEY,
    DEFAULT_DOC,
    SORT_KEY_TO_TEXT,
} from "./constants";
import { useSortable } from "./hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Button } from "components/editor/components";
import { Checkbox } from "components/atoms/checkbox";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { CopilotPresencePage } from "types/Presence";
import IconButton from "components/atoms/icon-button/IconButton";
import { useMutation, useStorage } from "liveblocks.config";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { QueueSource, enqueue } from "store/reducers/requirementsSmartResponseReducer";
import ConfirmModal from "components/ConfirmModal";
import { Modal } from "components/organisms/modal";
import { ResponseTolerance } from "types/Requirement";
import { LuSearch } from "react-icons/lu";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import { setCheckedState, updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { shallow } from "@liveblocks/client";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { EMPTY_SHEET } from "const-values/Sheets";
import { useKey } from "react-use";

const ComplianceMatrix = ({ frameworkState, abortAllAutoResponse }) => {
    const { checkedState, activeSheet } = useAppSelector((root) => root.requirements);
    const complianceMatrixState = useStorage(
        (storage) =>
            storage.compliance_matrix.filter(
                (row) => activeSheet?.id === (row.requirement.extraction_id || EMPTY_SHEET.id)
            ),
        shallow
    );

    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { setLocalVal: setSelectedColumns, localValue: selectedColumns } = useLocalStorage(
        `complianceMatrixColumns_${workspace_id}`,
        COLUMN_OPTIONS
    );

    const [searchParams] = useSearchParams();
    const [showInfoTab, setShowInfoTab] = useState(false);
    const [sortActive, setSortActive] = useState(false);
    const [documents, setDocuments] = useState([{ id: null, name: "No Document" }]);
    const [exporting, setExporting] = useState(false);
    const [contractDetails, setContractDetails] = useState({});
    const [autoRespondModal, setAutoRespondModal] = useState(false);
    const [confirmAbortModal, setConfirmAbortModal] = useState(false);
    const [responseTolerance, setResponseTolerance] = useState(ResponseTolerance.Moderate);

    const [requirements, setRequirements] = useState([]);

    const [searchFilters, setSearchFilters] = useState({
        // column filters
        assignees: "",
        documentName: "",
        complianceType: "",
        requirementStatusType: "",
        proposalVolume: "",
        proposalSection: "",
        // active reqs
        requirementsOnly: true,
    });

    const id = searchParams.get("id")?.toLocaleLowerCase();
    const documentsRef = useRef();
    const searchRef = useRef();
    useEffect(() => {
        const fetchInternalContract = () => {
            getInternalContractDetails(id)
                .then((res) => {
                    const contract = res?.data?.internal_contract?.contract || {};
                    if (!isEqual(contract, contractDetails)) setContractDetails(contract);
                    const govDocs = res?.data?.government_source;
                    const internalDocs = res?.data?.internal_documents;
                    const allDocs = [...DEFAULT_DOC, ...internalDocs, ...govDocs];
                    const allDocsCopy = allDocs.map((doc) => omit(doc, ["download_url", "secure_preview_url"]));
                    const stripDocumentKeys = documentsRef.current?.map((doc) =>
                        omit(doc, ["download_url", "secure_preview_url"])
                    );

                    // causes children to rerender
                    // keys "download_url" and "secure_preview_url" always change
                    if (!isEqual(allDocsCopy, stripDocumentKeys)) {
                        documentsRef.current = allDocs;
                        setDocuments(allDocs);
                    }
                })
                .catch((err) => {
                    documentsRef.current = DEFAULT_DOC;
                    setDocuments(DEFAULT_DOC);
                });
        };
        fetchInternalContract();
        const intervalId = setInterval(() => fetchInternalContract(), 3000);
        return () => {
            clearInterval(intervalId);
            dispatch(setCheckedState({}));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectedColumn = useCallback(
        (checked, value) => {
            const prevList = [...selectedColumns];
            if (!checked) {
                const columnToFilterKey = COLUMN_OPTION_TO_FILTER_KEY[value];
                setSelectedColumns(prevList?.filter((p) => p !== value));
                setSearchFilters((prev) => ({ ...prev, [columnToFilterKey]: "" }));
            } else {
                setSelectedColumns([value, ...prevList]);
            }
        },
        [selectedColumns, setSelectedColumns]
    );

    const handleExport = useCallback(() => {
        setExporting(true);
        downloadExcel(id)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                const filename = `compliance-matrix_${new Date().toISOString().split("T")[0]}.xlsx`;
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setExporting(false);
            });
    }, [id]);

    const hasColumnFilters = useMemo(() => {
        const { requirementsOnly, ...rest } = searchFilters;
        return Object.values(rest).some((filterValue) => !!filterValue);
    }, [searchFilters]);
    const hasFilters = hasColumnFilters || !!searchFilters.requirementsOnly;

    const filteredComplianceMatrix = useMemo(() => {
        if (!hasFilters) return complianceMatrixState;
        return complianceMatrixState.filter((row) => filterOut(row, searchFilters, frameworkState));
    }, [hasFilters, complianceMatrixState, searchFilters, frameworkState]);

    const { query, setQuery, filteredResults } = useCopilotSearch(filteredComplianceMatrix);

    useEffect(() => setRequirements(filteredResults), [filteredResults]);

    const allSections = useMemo(() => getAllSections(frameworkState?.volumes), [frameworkState?.volumes]);

    const updatedRequirements = useMemo(
        () =>
            requirements?.map((v) => {
                let volId = v?.proposal_reference?.volume_id;
                let secId = v?.proposal_reference?.section_id;
                let volumeIndex = frameworkState?.volumes?.findIndex((vl) => vl?.id === volId);
                return {
                    ...v,
                    requirementText: v?.requirement?.content || v?.requirement?.summarized_content || "",
                    proposalVolume: volumeIndex,
                    proposalSection: allSections?.findIndex((sc) => sc?.id === secId) || null,
                };
            }),
        [allSections, frameworkState?.volumes, requirements]
    );

    // sorted data for table rows
    const { items: requirementItems, sortConfig, handleSorting } = useSortable(updatedRequirements, sortActive);

    const sortOptions = useMemo(
        () =>
            Object.values(SortKey).map((option) => ({
                key: option,
                label: SORT_KEY_TO_TEXT[option],
                onSelect: () => handleSorting({ key: option }),
            })),
        [handleSorting]
    );

    const cursorPanel = useRef();

    const columnItems = useMemo(
        () =>
            COLUMN_OPTIONS.map((option) => ({
                key: option,
                label: option,
                value: option,
                checked: selectedColumns.includes(option),
                onCheck: (checked, value) => handleSelectedColumn(checked, value),
            })),
        [handleSelectedColumn, selectedColumns]
    );

    const dispatch = useAppDispatch();
    const { generateResponseQueue: responseQueue } = useAppSelector((root) => root.requirementsSmartResponse);
    const currentUser = useAppSelector((root) => root.auth?.currentUser);

    const checkedReqIds = useMemo(
        () => Object.keys(checkedState).filter((reqId) => checkedState[reqId]),
        [checkedState]
    );

    const initAutoRespondQueue = useMutation(
        ({ storage }, responseTolerance) => {
            const rows = storage.get("compliance_matrix") || [];
            const queueableRows = rows?.filter(
                (row) =>
                    !row.get("is_response_in_queue") &&
                    (
                        row.get("requirement").get("content") || row.get("requirement").get("summarized_content")
                    )?.trim() &&
                    !row.get("is_response_generating") &&
                    !responseQueue?.find(
                        (queueRow) => queueRow.requirement?.id === row?.get("requirement")?.get("id")
                    ) &&
                    checkedReqIds.includes(row.get("requirement")?.get("id"))
            );
            if (!queueableRows?.length || !currentUser?.id) return;

            queueableRows.forEach((row) => {
                row.update({ is_response_in_queue: true, auto_response_actor: currentUser?.id });
            });
            dispatch(
                enqueue(
                    queueableRows.map((row) => ({
                        ...row.toImmutable(),
                        responseTolerance,
                        queueSource: QueueSource.RequirementsTable,
                    }))
                )
            );

            const uncheckedReqs = checkedReqIds.reduce((acc, reqId) => ({ ...acc, [reqId]: false }), {});
            dispatch(updateCheckedState(uncheckedReqs));
        },
        [responseQueue, currentUser, checkedReqIds]
    );

    const canAddRequirement = !sortActive && !hasColumnFilters;
    const insertRequirementRow = useInsertRequirementRow();
    const addRequirementRow = useCallback(() => {
        if (!canAddRequirement) return;
        const newReq = createComplianceMatrixRowRequirement({
            ...(activeSheet?.id !== EMPTY_SHEET.id && { extraction_id: activeSheet?.id }),
        });
        insertRequirementRow(0, { requirement: newReq });
    }, [activeSheet?.id, canAddRequirement, insertRequirementRow]);

    useKey(
        (e) => e.metaKey && e.code === "KeyF",
        (e) => {
            searchRef.current?.focus();
            e.stopPropagation();
            e.preventDefault();
        }
    );

    return (
        <div ref={cursorPanel} className="flex flex-col flex-1 overflow-x-hidden overflow-y-hidden relative">
            <LiveNameCursors cursorPanel={cursorPanel} activePage={CopilotPresencePage.ComplianceMatrix} />
            <div className="z-10 bg-white pb-3">
                <div className="px-5">
                    <div className="flex justify-between gap-4 items-center">
                        <div className="flex gap-4 flex-1 max-w-[250px]">
                            <div className="flex py-2 px-2 w-full items-center gap-2 text-gray-darkest font-normal text-sm rounded-md border border-gray-light focus-within:border-slate-600">
                                <LuSearch className="text-lg text-gray-mid" />
                                <input
                                    ref={searchRef}
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    placeholder="Type to search..."
                                    className="!outline-none w-full truncate"
                                />
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <div className="flex gap-2 items-center whitespace-nowrap">
                                <label className="text-gray-500 text-sm font-normal">Sort by:</label>
                                <div className="cursor-default overflow-hidden border h-10 border-[#dbe0e5] flex items-center rounded-lg">
                                    <Tooltip
                                        disabled={!complianceMatrixState?.length}
                                        content={`${!sortActive ? "Enable" : "Disable"} sorting`}
                                    >
                                        <div
                                            className="h-full px-2 flex items-center"
                                            css={[!complianceMatrixState?.length && tw`bg-gray-200`]}
                                        >
                                            <Checkbox
                                                onCheck={(checked) => setSortActive(checked)}
                                                checked={sortActive}
                                                disabled={!complianceMatrixState?.length}
                                                className={!complianceMatrixState?.length ? "!bg-gray-300" : undefined}
                                            />
                                        </div>
                                    </Tooltip>
                                    <button
                                        disabled={!sortActive}
                                        onClick={() =>
                                            handleSorting({
                                                direction:
                                                    sortConfig.direction === Direction.Descending
                                                        ? Direction.Ascending
                                                        : Direction.Descending,
                                            })
                                        }
                                        className="group peer border-l border-[#dbe0e5] h-full flex items-center px-2 duration-0 hover:bg-gray-100 hover:duration-100 disabled:bg-gray-200 disabled:pointer-events-none"
                                    >
                                        {sortConfig.direction === Direction.Ascending && (
                                            <HiArrowUp className="text-action stroke-1 group-disabled:text-slate-400" />
                                        )}
                                        {sortConfig.direction === Direction.Descending && (
                                            <HiArrowDown className="text-action stroke-1 group-disabled:text-slate-400" />
                                        )}
                                    </button>
                                    <DropdownMenu
                                        disabled={!sortActive}
                                        triggerProps={{ css: tw`h-full` }}
                                        items={sortOptions}
                                    >
                                        <div className="px-2 h-full border-l border-[#dbe0e5] duration-100 hover:bg-gray-100 flex items-center text-sm font-medium text-black group-disabled:bg-gray-200 group-disabled:text-slate-400 group-disabled:duration-0">
                                            {SORT_KEY_TO_TEXT[sortConfig.key]}
                                        </div>
                                    </DropdownMenu>
                                </div>
                            </div>

                            <DropdownMenu modal={false} multiselect items={columnItems}>
                                <Tooltip content="Show or hide columns">
                                    <IconButton
                                        name="Columns"
                                        className="shadow-expanded min-h-[40px] w-10 !h-10 border border-[#dbe0e5] rounded-lg duration-100 hover:bg-gray-50"
                                    />
                                </Tooltip>
                            </DropdownMenu>
                            <Tooltip content="Export requirements">
                                <button
                                    className="shadow-expanded flex justify-center text-action w-10 !h-10 items-center gap-2 text-sm border border-[#dbe0e5] rounded-lg duration-100 hover:bg-gray-50"
                                    onClick={handleExport}
                                >
                                    <>
                                        {exporting ? (
                                            <Spinner classes="!text-black" width="18" height="18" />
                                        ) : (
                                            <Icon name="Export" className="w-[18px] h-[18px]" />
                                        )}
                                    </>
                                </button>
                            </Tooltip>
                            <DropdownMenu
                                modal={false}
                                triggerProps={{ className: "rounded-lg" }}
                                items={[
                                    {
                                        key: 1,
                                        label: "New Entry",
                                        onSelect: () => addRequirementRow(),
                                    },
                                    {
                                        key: 2,
                                        label: "From Text",
                                        onSelect: () => dispatch(toggleRequirementExtractionModal({ open: true })),
                                    },
                                ]}
                                disabled={!canAddRequirement}
                            >
                                <div
                                    className="shadow-expanded min-h-[40px] min-w-[40px] px-4 !h-10 border text-md border-[#dbe0e5] rounded-lg flex items-center justify-center duration-100 hover:bg-action-hover text-white bg-action"
                                    css={[!canAddRequirement && tw`shadow-none bg-gray-200 text-slate-400`]}
                                >
                                    <Icon name="PlusCircle" className="w-[15px] h-[15px] mr-1.5" /> Add
                                </div>
                            </DropdownMenu>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-5 pb-4 flex-1">
                <CopilotTable
                    searchActive={!!query.length}
                    sortConfig={sortConfig}
                    handleSorting={handleSorting}
                    sortActive={sortActive}
                    complianceMatrixState={complianceMatrixState}
                    requirements={requirementItems}
                    setRequirements={setRequirements}
                    setSearchFilters={setSearchFilters}
                    searchFilters={searchFilters}
                    showInfoTab={showInfoTab}
                    setShowInfoTab={setShowInfoTab}
                    documents={documents}
                    frameworkState={frameworkState}
                    selectedColumns={selectedColumns}
                />
            </div>
            <ConfirmModal
                open={confirmAbortModal}
                onClose={() => setConfirmAbortModal(false)}
                onProceed={(proceed) => proceed && abortAllAutoResponse()}
                variant="error"
                proceedLabel="Stop all"
                title="Are you sure you want to stop?"
                subTitle={
                    <div>
                        This will terminate <span className="font-semibold">ALL</span> smart responses.
                    </div>
                }
            />
            <Modal
                open={autoRespondModal}
                onOpenChange={(open) => {
                    setAutoRespondModal(open);
                }}
                header="Smart Response"
                body={
                    <div className="flex flex-col gap-2.5 px-4">
                        {RESPONSE_TOLERANCE_OPTIONS.map(({ icon: ResponseIcon, value, text, name }) => (
                            <button
                                key={name}
                                className="rounded-md border border-zinc-300 px-4 py-3 w-[420px] text-left duration-100 hover:bg-action-lightest"
                                css={[
                                    value === responseTolerance &&
                                        tw`border-action bg-action-lightest [outline-style: solid] outline-[0.5px] outline-action`,
                                ]}
                                onClick={() => setResponseTolerance(value)}
                            >
                                <div className="flex flex-col gap-2">
                                    <div
                                        className="flex items-center gap-1"
                                        css={[value === responseTolerance && tw`text-action`]}
                                    >
                                        <ResponseIcon />
                                        <div className="text-md font-medium">{name}</div>
                                    </div>
                                    <div className="text-gray-mid text-xs pr-4">{text}</div>
                                </div>
                            </button>
                        ))}
                    </div>
                }
                footer={
                    <>
                        <Button
                            size="md"
                            variant="outline"
                            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                            onClick={() => {
                                setAutoRespondModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="md"
                            variant="primary"
                            css={[!responseTolerance && tw`pointer-events-none`]}
                            disabled={!responseTolerance}
                            onClick={() => {
                                setAutoRespondModal(false);
                                initAutoRespondQueue(responseTolerance);
                            }}
                        >
                            Generate
                        </Button>
                    </>
                }
            />
        </div>
    );
};

export default ComplianceMatrix;
