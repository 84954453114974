import { useEffect } from "react";

let timeout: NodeJS.Timeout;
let loc = 0;
export const useAnimateLoadingMsg = (
    isLoading: boolean,
    delay: number,
    loadingMsgs: string[],
    onSetMsg: (msg: string) => void
) => {
    useEffect(() => {
        if (isLoading) {
            const animate = () => {
                timeout = setTimeout(function () {
                    loc++;
                    onSetMsg(loadingMsgs[loc]);
                    if (loc < loadingMsgs.length - 1) {
                        animate();
                    }
                }, delay);
            };
            animate();
        }

        return () => {
            clearTimeout(timeout);
            loc = 0;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
};
