import { useState } from "react";
import exportIcon from "../../../Assets/export.svg";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { downloadProposal } from "api/api";
import { Spinner } from "utils/icons";
import { useSelector } from "react-redux";
import { EditorToggler } from "../EditorToggler";
import { CopilotPresencePage } from "types/Presence";
import { useSearchParams } from "react-router-dom";

const Proposal = ({ fullscreen, setForceRefresh, setDocId, proposalDocId }) => {
    const [searchParams] = useSearchParams();
    const nestedTab = searchParams.get("tab")?.toLocaleLowerCase();
    const fullScreen = useSelector((state) => state.aiReducer.isExpanded);
    const isSlateEditorActive = useSelector((state) => state.yjsEditor.isSlateEditorActive);
    const [exporting, setExporting] = useState(false);
    return (
        <div className="px-5 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
            <div className="flex items-center pb-2 mb-1">
                <div className="flex items-center justify-between w-full gap-2 ml-auto">
                    <button
                        onClick={() => {
                            setDocId("");
                            setForceRefresh(true);
                        }}
                        className="flex text-action items-center gap-2 text-sm ml-1 py-1"
                    >
                        <AiOutlineUnorderedList className="" />
                        All Proposals
                    </button>
                    <button
                        className="flex text-action items-center gap-2 text-sm mr-1 py-1"
                        onClick={() => {
                            setExporting(true);
                            const dataFormat = isSlateEditorActive ? "liveblock-storage" : "yjs";
                            downloadProposal(proposalDocId, dataFormat)
                                .then((response) => {
                                    console.log({ Resdata: response.data });
                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                    console.log({ url });
                                    const link = document.createElement("a");
                                    const filename = `proposal_${new Date().toISOString().split("T")[0]}.docx`;
                                    link.href = url;
                                    link.setAttribute("download", filename);
                                    document.body.appendChild(link);
                                    link.click();
                                })
                                .catch((error) => console.error(error))
                                .finally(() => setExporting(false));
                        }}
                    >
                        {exporting ? (
                            <Spinner classes="!text-black items-center" width={13} height={13} />
                        ) : (
                            <img src={exportIcon} alt="" />
                        )}
                        <span className="mr-2">{exporting ? "Exporting..." : "Export"}</span>
                    </button>
                </div>
            </div>
            <div
                className="border bg-white px-3 pb-2 overflow-hidden flex flex-col flex-1"
                style={{
                    position: fullScreen ? "fixed" : "",
                    top: fullScreen ? "64px" : "",
                    left: fullScreen ? "233px" : "",
                    width: fullScreen ? "calc(100% - 233px)" : "",
                    bottom: 0,
                    overflow: "hidden",
                    zIndex: fullScreen ? "20" : "",
                }}
            >
                <EditorToggler fullscreen={fullscreen} liveCursor={CopilotPresencePage.Proposal === nestedTab} />
                <div className="p-3 scrollbar-none overflow-auto  border-0 outline-none flex-1"></div>
            </div>
        </div>
    );
};

export default Proposal;
