import { PropsWithChildren } from "react";
import { DropdownMenu } from "../dropdown-menu";
import "./styles.css";
import { MenuItem } from "../dropdown-menu/DropdownMenu";

interface Props<T> extends Partial<ReturnType<typeof DropdownMenu>> {
    items: MenuItem<T>[];
    selectedColor?: string;
}

export const ListFilterMenu = <T,>({ items, children, selectedColor, ...props }: PropsWithChildren<Props<T>>) => {
    return (
        <DropdownMenu
            items={items}
            triggerProps={{
                className: "filter-button",
                style: {
                    // @ts-ignore
                    "--filter-button-open-background": selectedColor,
                },
            }}
            {...props}
        >
            {children}
        </DropdownMenu>
    );
};
