/** @jsxImportSource @emotion/react */
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Requirement } from "../CopilotSchemaTypes";
import useRequirementOperations from "hook/useRequirementOperations";
import { useAppDispatch } from "store/storeTypes";
import { updateCheckedState } from "store/reducers/copilot/requirementsReducer";

interface SkipButtonProps {
    requirement: Requirement;
}

const SkipButton: React.FC<SkipButtonProps> = ({ requirement }) => {
    const { setSkipped } = useRequirementOperations();
    const dispatch = useAppDispatch();

    return (
        <div
            className="z-[1] group px-4 py-4 min-w-[100px] max-w-[100px] w-[5%] cursor-pointer"
            onClick={() => {
                if (!requirement.skipped) dispatch(updateCheckedState({ [requirement.id]: false }));
                setSkipped(requirement.id, !requirement.skipped);
            }}
        >
            <div className="flex items-center justify-center gap-1  text-sm text-gray-400 group-hover:text-action">
                {requirement.skipped ? <Skip /> : <Include />}
            </div>
        </div>
    );
};

const Skip = () => {
    return (
        <div className="transition-all flex gap-1 items-center group-hover:-ml-4">
            <FaEye className="translate-x-0 group-hover:-translate-x-[18px] duration-200 opacity-0 absolute group-hover:relative group-hover:opacity-100" />
            <FaEyeSlash className="translate-x-0 group-hover:-translate-x-[18px] duration-200 opacity-100 group-hover:opacity-0 group-hover:absolute" />
            <span className="absolute opacity-0 group-hover:opacity-100 duration-100 group-hover:delay-50">
                Include
            </span>
        </div>
    );
};

const Include = () => {
    return (
        <div className="transition-all flex gap-1 items-center group-hover:-ml-4">
            <FaEyeSlash className="translate-x-0 group-hover:-translate-x-[18px] transition-transform duration-200 opacity-0 absolute group-hover:relative group-hover:opacity-100" />
            <FaEye className="translate-x-0 group-hover:-translate-x-[18px] transition-transform duration-200 opacity-100 group-hover:opacity-0 group-hover:absolute" />
            <span className="absolute opacity-0 group-hover:opacity-100 duration-100 group-hover:delay-50">
                Exclude
            </span>
        </div>
    );
};

export default SkipButton;
