import { IoDocumentTextOutline } from "react-icons/io5";
import { IoListOutline } from "react-icons/io5";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ControlItem } from "components/molecules/segmented-control/SegmentedControl";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EventStreamContentType, FetchEventSourceInit, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ProposalInProgressSection, setProposalState, setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useNotification } from "context/notificationContext";
import { ResponseSource } from "components/copilot/CopilotSchemaImmutableTypes";
import { DELIMITER, HEARTBEAT } from "const-values/Stream";
import { useMutation, useUpdateMyPresence } from "liveblocks.config";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { LiveList, LiveObject } from "@liveblocks/client";
import Tooltip from "components/atoms/tooltip";

export enum ControlItemSlug {
    requirements = "requirements",
    proposal = "proposal",
}

export const useSegmentedControl = () => {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const onControlSelect = useCallback(
        (val: ControlItem<ControlItemSlug>) => {
            navigate(`${val.value}?${searchParams.toString()}`);
        },
        [navigate, searchParams]
    );

    const controlItems = useMemo(
        () => [
            {
                label: (
                    <Tooltip
                        disableHoverableContent
                        contentProps={{ sideOffset: 8 }}
                        content="Requirements"
                        delayDuration={1500}
                    >
                        <div>
                            <IoListOutline />
                        </div>
                    </Tooltip>
                ),
                value: ControlItemSlug.requirements,
            },
            {
                label: (
                    <Tooltip
                        contentProps={{ sideOffset: 8 }}
                        disableHoverableContent
                        content="Full Section Draft"
                        delayDuration={1500}
                    >
                        <div>
                            <IoDocumentTextOutline />
                        </div>
                    </Tooltip>
                ),
                value: ControlItemSlug.proposal,
            },
        ],
        []
    );

    const [activeControlItem, setActiveControlItem] = useState<ControlItem<ControlItemSlug>>(
        () => controlItems.find((item) => pathname?.split("/").includes(item.value)) || controlItems[0]
    );

    useEffect(() => {
        setActiveControlItem(controlItems.find((item) => pathname?.split("/").includes(item.value)) || controlItems[0]);
    }, [controlItems, pathname]);

    return { controlItems, activeControlItem, onControlSelect };
};

type SectionProposalVariables = {
    sourced_requirements: {
        requirement_id: string;
        requirement: string;
        response: string;
        requirement_sources: ResponseSource[];
    }[];
    block_title: string;
    full_requirement_title: boolean;
};

export const useSseSectionProposal = (options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { volumeId: volume } = useParams();
    const [isGenerating, setIsGenerating] = useState(false);
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id");
    const { sectionInProgress } = useAppSelector((store) => store.sectionState.proposalState);
    const currentUser = useAppSelector((store) => store.auth.currentUser);
    const controllerRef = useRef(new AbortController());
    const dispatch = useAppDispatch();
    const { setToast } = useNotification();
    const updateMyPresence = useUpdateMyPresence();

    useEffect(() => {
        if (sectionInProgress?.id && projectId && volume && !isGenerating) {
            initProposalStream(sectionInProgress, projectId, volume);
            updateMyPresence({
                selectedId: `${sectionInProgress.id}-proposal-section`,
                name: currentUser?.username,
            });
        }
    }, [projectId, sectionInProgress?.id, currentUser?.username, volume, isGenerating]);

    const initProposalStream = useMutation(
        ({ storage }, section: ProposalInProgressSection, projectId: string, volumeId: string) => {
            controllerRef.current = new AbortController();

            const liveRequirements = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.filter(
                (row) =>
                    row.get("proposal_reference")?.get("section_id") === section.id &&
                    !row.get("requirement").get("skipped")
            );
            const sortedLiveRequirements = [...liveRequirements].sort(
                (a, b) =>
                    (a.toImmutable().requirement.section_order || 0) - (b.toImmutable().requirement.section_order || 0)
            );

            const sourcedRequirements = sortedLiveRequirements?.map((row) => ({
                requirement_id: row.get("requirement").get("id"),
                requirement:
                    row.get("requirement").get("content") || row.get("requirement").get("summarized_content") || "",
                response: row.get("written_content"),
                requirement_sources: row.get("response_sources")?.map((s) => s.toImmutable()) || [],
            }));

            const body: SectionProposalVariables = {
                full_requirement_title: section.full_requirement_title,
                block_title: section.title,
                sourced_requirements: sourcedRequirements,
            };
            const liveVolumes = (storage.get("framework") as Storage["framework"])?.get("volumes");
            const volumeIndex = liveVolumes?.findIndex((v) => v.get("id") === volumeId);
            const sections = liveVolumes?.get(volumeIndex)?.get("sections");
            const sectionIndex = sections?.findIndex((s) => s.get("id") === section.id);

            if (sectionIndex === -1 || sectionIndex === undefined) return;
            const liveSection = sections?.get(sectionIndex);

            liveSection?.set("proposal", "");
            liveSection?.set("proposal_sources", new LiveList([]));

            let shouldChunk = false;
            let chunkText = "";
            if (sourcedRequirements.length > 20) shouldChunk = true;

            fetchEventSource(
                `${process.env.REACT_APP_BASE_URL}/proposal_generation/${projectId}/generate/section/stream`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Workspace: `Workspace ${workspace_id}`,
                        Authorization: `Bearer ${localValue}`,
                        Accept: "application/json",
                    },
                    body: JSON.stringify(body),
                    signal: controllerRef.current?.signal,
                    onmessage(msg) {
                        if (msg.event === "FatalError") {
                            abortConnection();
                        }

                        if (!!msg.data?.length) {
                            if (msg.data === "*") return;

                            try {
                                const parsed: Record<"sources", ResponseSource[]> = JSON.parse(msg.data);
                                if (typeof parsed !== "object" || !parsed.sources) throw new Error("error");

                                const liveSources = parsed.sources?.map((source) => new LiveObject(source));
                                liveSection?.set("proposal_sources", new LiveList(liveSources));
                            } catch {
                                if (msg.data === HEARTBEAT) {
                                    return;
                                } else if (msg.data !== DELIMITER) {
                                    if (shouldChunk) {
                                        chunkText += msg.data;
                                    } else {
                                        liveSection?.set("proposal", `${liveSection?.get("proposal")}${msg.data}`);
                                    }
                                }
                            }
                        } else if (typeof msg.data === "string") {
                            if (shouldChunk) {
                                chunkText += "\n";
                            } else {
                                liveSection?.set("proposal", `${liveSection?.get("proposal")}\n`);
                            }
                        }
                    },
                    async onopen(response) {
                        chunkText = "";
                        if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                            liveSection?.set("proposal", "");
                            liveSection?.set("proposal_sources", new LiveList([]));
                            return; // everything's good
                        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                            setToast.error({
                                title: "Unable to generate content",
                                msg: "We were unable to generate content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                            });
                            abortConnection();
                            // client-side errors are usually non-retriable:
                            // throw new FatalError();
                        } else {
                            // throw new RetriableError();
                        }
                    },
                    onclose() {
                        if (shouldChunk) liveSection?.set("proposal", chunkText);

                        setTimeout(() => {
                            dispatch(setProposalState({ sectionInProgress: null }));
                            setIsGenerating(false);
                            updateMyPresence({
                                selectedId: null,
                            });
                        }, 200);

                        // if the server closes the connection unexpectedly, retry:
                        // throw new RetriableError();
                    },
                    onerror(err) {
                        setToast.error({
                            title: "Unable to generate proposal",
                            msg: "We were unable to generate the proposal due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                        });
                        abortConnection();
                        throw err;
                    },
                }
            );
        },
        [dispatch, localValue, setToast, workspace_id]
    );

    const abortConnection = useCallback(() => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
        dispatch(setProposalState({ sectionInProgress: null }));
        setIsGenerating(false);
        updateMyPresence({
            selectedId: null,
        });
    }, [dispatch, setIsGenerating]);

    return { abortConnection };
};

export const useRouteRequirement = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const reqId = searchParams.get("req");

    useLayoutEffect(() => {
        if (reqId) {
            searchParams.delete("req");
            setSearchParams(searchParams, { replace: true });
            dispatch(setRequirementsState({ requirementHighlighted: reqId }));
        }
    }, [dispatch, reqId, searchParams, setSearchParams]);
};
