/** @jsxImportSource @emotion/react */

import { Empty } from "components/molecules/empty";
import { useCallback } from "react";
import { LuFiles, LuSearch } from "react-icons/lu";
import tw from "twin.macro";
import { useSearchContent } from "./hooks";
import searching from "Assets/gifs/searching-documents.gif";
import ContentDrivePopover from "components/molecules/content-drive-popover-content/ContentDrivePopover";
import { BaseFile } from "types/FileStorage";
import { uniq } from "lodash";
import SearchContentCard from "./search-content-card";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setContentSearchState } from "store/reducers/copilot/contentSearchReducer";

const ContentSearchContent = () => {
    const { query, selectedFiles } = useAppSelector((root) => root.contentSearch);
    const dispatch = useAppDispatch();
    const { isLoading, searchContent, loadingMsg, isResponseEmpty, results } = useSearchContent();

    const handleSelectedFiles = useCallback(
        (checked: boolean, files: BaseFile[]) => {
            if (checked)
                dispatch(
                    setContentSearchState({ selectedFiles: uniq([...selectedFiles, ...files.map(({ id }) => id)]) })
                );
            if (!checked)
                dispatch(
                    setContentSearchState({
                        selectedFiles: selectedFiles.filter((id) => !files.find((file) => file.id === id)),
                    })
                );
        },
        [dispatch, selectedFiles]
    );

    return (
        <>
            <div className="flex gap-2 items-center w-full pl-4 pr-5">
                <input
                    placeholder="Search content library..."
                    className="flex-1 py-2 pl-2 outline-none text-sm bg-gray-50 rounded-md border border-gray-light"
                    value={query}
                    onChange={(e) => dispatch(setContentSearchState({ query: e.target.value }))}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && query?.trim() && !isLoading) {
                            searchContent({ text: query.trim(), file_id_filters: selectedFiles });
                        }
                    }}
                />
                <button
                    disabled={!query?.trim() || isLoading}
                    onClick={() => {
                        if (query?.trim() && !isLoading) {
                            searchContent({ text: query.trim(), file_id_filters: selectedFiles });
                        }
                    }}
                    name="Generate"
                    className="h-9 w-9 flex justify-center items-center text-white bg-action rounded-md duration-150 hover:bg-action-hover disabled:bg-gray-200 disabled:text-slate-400"
                >
                    <LuSearch className="text-lg" />
                </button>
                <ContentDrivePopover
                    handleSelectedFiles={handleSelectedFiles}
                    onClear={() => dispatch(setContentSearchState({ selectedFiles: [] }))}
                    selectedFiles={selectedFiles}
                    contentProps={{
                        align: "end",
                        side: "bottom",
                        css: [tw`backdrop-blur-sm mx-0 min-w-[300px] bg-[rgba(255,255,255,0.95)]`],
                    }}
                >
                    <div className="relative cursor-pointer flex justify-center items-center rounded-md h-9 w-9 bg-white border border-light shadow text-gray-600 duration-100 hover:bg-neutral-50 hover:border-gray-silver">
                        <>
                            <LuFiles className="text-lg text-action" />
                            {!!selectedFiles.length && (
                                <div className="bg-action text-white font-medium shadow-sharp-full rounded-full w-[18px] h-[18px] flex justify-center items-center text-[11px] absolute -top-2 -right-2">
                                    {selectedFiles.length}
                                </div>
                            )}
                        </>
                    </div>
                </ContentDrivePopover>
            </div>
            <div className="w-full pt-2 h-[calc(100%-38px)]">
                {!results.length && !isLoading && !isResponseEmpty && (
                    <Empty
                        name="DocumentSearch"
                        containerProps={{
                            className: "w-[70%] flex flex-col items-center h-full justify-center m-auto",
                        }}
                        title={
                            <div className="mt-6 text-center">
                                <span className="text-gray-700 text-lg font-semibold mt-5">
                                    What content can I find for you?
                                </span>
                                <br></br>
                                <span className="text-gray-500 text-sm mt-5">
                                    Search content library for relevant information
                                </span>
                            </div>
                        }
                    />
                )}
                {!!results.length && !isLoading && !isResponseEmpty && (
                    <div className="flex flex-col gap-2.5 overflow-y-auto pt-2 pb-4 px-4 h-full">
                        {results.map((contentItem, i) => (
                            <SearchContentCard key={`${contentItem.date}_${i}`} contentItem={contentItem} />
                        ))}
                    </div>
                )}
                {isResponseEmpty && (
                    <Empty
                        name="DocumentSearch"
                        containerProps={{
                            className: "w-[70%] flex flex-col items-center h-full justify-center m-auto",
                        }}
                        title={
                            <div className="mt-6 text-center">
                                <span className="text-gray-700 text-lg font-semibold mt-5">
                                    No matching content found
                                </span>
                                <br></br>
                                <span className="text-gray-500 text-sm mt-5">Please try a different search</span>
                            </div>
                        }
                    />
                )}
                {isLoading && (
                    <div className="h-full w-full flex flex-col items-center justify-center">
                        <img src={searching} alt="" />
                        <div className="text-gray-400 text-sm -mt-3">
                            {loadingMsg}
                            <span className="loading-ellipsis" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ContentSearchContent;
