import dropdownIcon from "Assets/icon-wrapper.svg";
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import TagsDropdown from "components/organisms/tags-dropdown/TagsDropdown";
import { ComponentProps, useEffect, useRef, useState } from "react";
import { setMediaUserTags } from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { WorkspaceMedia } from "types/FileStorage";
import { useMediaMutateOperations } from "../media/hooks";
interface Props extends Partial<ComponentProps<typeof Modal>> {
    selectedItem: WorkspaceMedia;
    onFolderSelected?: (id: string) => void;
    onMediaFileUpdated?: (file: WorkspaceMedia) => void;
}

const UpdateMediaFileModal = ({ selectedItem, ...props }: Props) => {
    const [name, setName] = useState("");
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const { updateFile, isUpdatingFile } = useMediaMutateOperations();
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLInputElement>(null);
    const { userTags } = useAppSelector((state) => state.drive.media);

    useEffect(() => {
        if (props.open) {
            setName(selectedItem.file_name);
            setSelectedTags([...selectedItem.user_tags]);
        }
    }, [props.open]);

    return (
        <Modal
            contentProps={{ onClick: (e) => e.stopPropagation() }}
            header="Edit Properties"
            body={
                <div ref={containerRef} className="flex flex-col overflow-y-auto px-5">
                    <label className="mb-2 block text-sm text-[#5B6B79]">Graphic Name</label>
                    <input
                        type="text"
                        className="w-full rounded-md py-2 px-3 border border-gray-light outline-none mb-3"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder={name}
                    />

                    <label className="mb-2 block text-sm text-[#5B6B79]">Tags</label>
                    <TagsDropdown
                        ref={containerRef}
                        dropdownIcon={dropdownIcon}
                        selectedTags={selectedTags}
                        setSelectedTags={(tags) => {
                            setSelectedTags(tags);
                            const newUserTags = new Set();
                            userTags.forEach((tag) => newUserTags.add(tag));
                            tags.forEach((tag) => newUserTags.add(tag));
                            dispatch(setMediaUserTags(Array.from(newUserTags)));
                        }}
                        availableTags={userTags}
                        placeholder="Add tags..."
                    />
                </div>
            }
            footer={
                <div className="flex justify-end gap-4">
                    <button
                        type="button"
                        className="border border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                        onClick={(e) => {
                            props.onOpenChange?.(false);
                        }}
                    >
                        Cancel
                    </button>
                    <Button
                        variant="primary"
                        size="md"
                        loading={isUpdatingFile}
                        onClick={async (e) => {
                            e.preventDefault();
                            if (!selectedItem.id) return;
                            const updatedFile = await updateFile(selectedItem.id, {
                                file_name: name,
                                user_tags: selectedTags,
                            });
                            if (updatedFile) {
                                props.onMediaFileUpdated?.(updatedFile);
                            }
                            props.onOpenChange?.(false);
                        }}
                        className="border-0 bg-action rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                    >
                        Save
                    </Button>
                </div>
            }
            {...props}
        />
    );
};

export default UpdateMediaFileModal;
