/** @jsxImportSource @emotion/react */

import { Button } from "components/editor/components";
import tw from "twin.macro";
import Lottie from "lottie-react";
import listLoading from "Assets/lotties/list-loading-lottie.json";
import loadingDots from "Assets/lotties/loading-dots.json";
import { useAppendRequirements, useSseReqExtraction } from "./hooks";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { EventStreamContentType, EventSourceMessage } from "@microsoft/fetch-event-source";
import { uniqBy } from "lodash";
import { getWordCount } from "utils/getWordCount";
import { ReactComponent as EmptyList } from "Assets/svgs/listItems.svg";
import { Checkbox } from "components/atoms/checkbox";
import Icon from "components/atoms/icons/Icon";
import { useScroll } from "react-use";
import { useNotification } from "context/notificationContext";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import { clearExtractState, setExtractState } from "store/reducers/extract/ExtractReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useObserveSseController } from "hook/useObserveSseController";
import { ExtractionKeywordsDropdown, SplitTypeOptions, VultronExtractionToggle } from "components/copilot/Extract";
import ExtractToSheetDropdown from "components/copilot/Extract/ExtractToSheetDropdown";
import { EMPTY_SHEET } from "const-values/Sheets";
import { createSheet } from "utils/sheet";
import { useMutation, useStorage } from "liveblocks.config";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import useSheetOperations from "hook/useSheetOperations";
import { Sheet, Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";

const MAX_WORDS = 5000;
const INITIAL_SCROLL_STATE = { didScroll: false, scrollY: 0 };

export type PreviewRequirement = { content: string; checked: boolean; extractionId?: string };

const SimpleRequirementExtraction = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const formRef = useRef<HTMLDivElement | null>(null);
    const refScrolling = useRef<{ didScroll: boolean; scrollY: number }>(INITIAL_SCROLL_STATE);
    const [content, setContent] = useState("");
    const [isExtracting, setIsExtracting] = useState(false);
    const [didExtract, setDidExtract] = useState(false);
    const [requirements, setRequirements] = useState<PreviewRequirement[]>([]);
    const dispatch = useAppDispatch();
    const { setToast } = useNotification();
    const appendRequirements = useAppendRequirements();
    const { appendNewSheets } = useSheetOperations();
    const { aiDetection, keywords, splitType, selectedSheet } = useAppSelector((state) => state.extractReducer);
    const localSheet = useRef<Sheet>();
    const sheets = useStorage((storage) => (storage.sheets as ImmutableStorage["sheets"]) || []);

    const options = useMemo(() => {
        return {
            onmessage(msg: EventSourceMessage) {
                if (msg.event === "FatalError") {
                }

                if (!!msg.data?.length && localSheet?.current?.id) {
                    const processedData = msg.data.replace(/\\n/g, "\n");

                    setRequirements((prev) =>
                        uniqBy(
                            [...prev, { content: processedData, checked: true, extractionId: localSheet?.current?.id }],
                            "content"
                        )
                    );
                }
            },
            async onopen(response: Response) {
                refScrolling.current = INITIAL_SCROLL_STATE;

                if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                    setIsExtracting(true);
                    setDidExtract(true);
                    return; // everything's good
                } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                    setToast.error({
                        title: "Unable to extract requirements",
                        msg: "We were unable to extract requirements due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                    setIsExtracting(false);
                    // client-side errors are usually non-retriable:
                    throw new Error();
                } else {
                    // throw new RetriableError();
                }
            },
            onclose() {
                setIsExtracting(false);
                // if the server closes the connection unexpectedly, retry:
                // throw new RetriableError();
            },
            onerror(err: Error) {
                setToast.error({
                    title: "Unable to extract requirements",
                    msg: "We were unable to extract requirements due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
                setIsExtracting(false);
                throw new Error();
            },
        };
    }, [localSheet, setToast]);

    const { extractRequirements, abortConnection } = useSseReqExtraction(setIsExtracting, options);

    useObserveSseController(abortConnection, () => {
        setIsExtracting(false);
    });

    useLayoutEffect(() => {
        if (ref.current && !refScrolling.current?.didScroll && (isExtracting || requirements.length)) {
            ref.current.scrollTo(0, ref.current.scrollHeight);
        }
    }, [isExtracting, requirements.length]);

    const wordCount = useMemo(() => getWordCount(content), [content]);
    const hasChecked = useMemo(() => requirements.some((req) => req.checked), [requirements]);

    const onSuccess = useCallback(() => {
        setContent("");
        setIsExtracting(false);
        setRequirements([]);
        dispatch(toggleRequirementExtractionModal({ open: false }));
        dispatch(clearExtractState());
    }, [dispatch]);

    const scroll = useScroll(ref);

    if (scroll.y < refScrolling.current.scrollY && !refScrolling.current.didScroll) {
        refScrolling.current = { scrollY: scroll.y, didScroll: true };
    } else if (!refScrolling.current.didScroll) refScrolling.current = { scrollY: scroll.y, didScroll: false };

    const canSubmit = useMemo(() => {
        const isNewValidSheet = !sheets.some(
            (sheet) =>
                sheet.id === selectedSheet?.id ||
                sheet.name.trim().toLowerCase() === selectedSheet?.name.trim().toLowerCase()
        );
        const isExistingValidSheet = sheets.some((sheet) => sheet.id === selectedSheet?.id);
        const isValidSheetSelection = !!selectedSheet?.name?.trim() && (isNewValidSheet || isExistingValidSheet);

        const isValidRequirementOptions = !!selectedSheet?.name && (aiDetection || (!aiDetection && !!keywords.length));
        return (
            isValidSheetSelection &&
            !!content.trim().length &&
            isValidRequirementOptions &&
            !isExtracting &&
            wordCount <= MAX_WORDS
        );
    }, [
        aiDetection,
        content,
        isExtracting,
        keywords.length,
        selectedSheet?.id,
        selectedSheet?.name,
        sheets,
        wordCount,
    ]);

    const onExtract = useMutation(
        ({ storage }) => {
            if (!!content?.length && !!selectedSheet) {
                const foundSheet = (storage.get("sheets") as Storage["sheets"])?.find(
                    (sheet) => sheet.get("id") === selectedSheet.id
                );

                if (!foundSheet && !!selectedSheet?.id && selectedSheet.id !== EMPTY_SHEET.id) {
                    const createdSheet = createSheet(selectedSheet);
                    appendNewSheets([createdSheet]);
                }

                localSheet.current = selectedSheet;

                extractRequirements({
                    text: content,
                    ai_detection: aiDetection,
                    keywords,
                    requirement_chunk_type: splitType,
                    ...(selectedSheet.id !== EMPTY_SHEET.id && {
                        extraction_id: selectedSheet.id,
                    }),
                });

                dispatch(setExtractState({ selectedSheet: undefined }));
            }
        },
        [aiDetection, content, extractRequirements, keywords, selectedSheet, splitType]
    );

    return (
        <>
            <div className="px-6 flex gap-6 flex-1 min-h-0 pb-2">
                <div className="flex flex-col flex-1">
                    <div className="flex gap-4 justify-end h-7 mx-1">
                        <Button
                            disabled={!content.length}
                            onClick={() => {
                                setContent("");
                            }}
                            variant="link"
                            className="!text-red-500 text-xs -mb-0.1 mr-1 hover:!text-red-400"
                        >
                            Clear
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2 flex-1 py-3 min-h-0 rounded-md shadow-soft relative border-1.5 border-gray-lightest">
                        <div ref={formRef} className="flex flex-col gap-3 flex-1 px-3 pb-2 overflow-y-auto">
                            <span
                                className="text-xxs font-normal text-gray-lightest absolute top-5 right-5"
                                css={wordCount > MAX_WORDS && tw`text-red-500`}
                            >
                                {wordCount}/{MAX_WORDS} words
                            </span>
                            <textarea
                                placeholder="Paste selected content here..."
                                value={content}
                                onChange={(e) => {
                                    setContent(e.target.value);
                                }}
                                className="flex-1 min-h-[250px] text-xs resize-none border border-light rounded-md bg-gray-light outline-none p-4 pt-5 w-full"
                            />
                            <div className="flex flex-col gap-4">
                                <ExtractionKeywordsDropdown />
                                <SplitTypeOptions
                                    layout="dropdown"
                                    dropdownProps={{
                                        contentProps: { css: tw`min-w-[180px]`, align: "start" },
                                        portalProps: { container: formRef.current },
                                    }}
                                />
                                <VultronExtractionToggle />
                                <ExtractToSheetDropdown
                                    dropdownMenuProps={{ portalProps: { container: formRef.current } }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-4 px-3 items-center">
                            <Button
                                disabled={!canSubmit}
                                variant="primary"
                                onClick={onExtract}
                                className="w-full"
                                size="md"
                            >
                                Extract
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-1">
                    <div className="flex items-center justify-between mx-1">
                        <label className="font-normal text-sm h-7 flex items-center">
                            Requirements ({requirements.length})
                        </label>
                        <div className="flex items-center gap-4">
                            <Button
                                disabled={!requirements.length || isExtracting}
                                onClick={() => {
                                    if (hasChecked) {
                                        setRequirements((prev) => prev.map((req) => ({ ...req, checked: false })));
                                    } else {
                                        setRequirements((prev) =>
                                            prev.map((req) => ({
                                                ...req,
                                                checked: true,
                                            }))
                                        );
                                    }
                                }}
                                variant="link"
                                className="text-xs"
                            >
                                {hasChecked ? "Deselect All" : "Select All"}
                            </Button>
                            <Button
                                disabled={!requirements.length || isExtracting}
                                onClick={() => {
                                    setRequirements([]);
                                    setDidExtract(false);
                                }}
                                variant="link"
                                className="!text-red-500 text-xs mr-1 hover:!text-red-400"
                            >
                                Clear
                            </Button>
                        </div>
                    </div>
                    <div className="min-h-0 relative flex-1 justify-between gap-2 flex flex-col p-3 border-1.5 border-gray-lightest rounded-md shadow-soft">
                        {isExtracting && !requirements.length && (
                            <div className="text-center flex flex-col gap-3 w-full h-full justify-center items-center text-xs text-[#96A4AF] p-3">
                                <Lottie
                                    animationData={listLoading}
                                    style={{
                                        height: "80%",
                                        position: "absolute",
                                        top: 31,
                                        width: "100%",
                                    }}
                                />
                                <div className="absolute top-[61%]">
                                    This may take a few minutes. Please stay on this screen
                                    <span className="loading-ellipsis" />
                                </div>
                            </div>
                        )}
                        {!requirements.length && !isExtracting && !didExtract && (
                            <div className="flex flex-col gap-6 w-full h-full justify-center items-center text-sm text-[#96A4AF] p-3">
                                <EmptyList />
                                No requirements extracted.
                            </div>
                        )}
                        {!requirements.length && !isExtracting && didExtract && (
                            <div className="flex flex-col gap-6 w-full h-full justify-center items-center text-sm text-[#96A4AF] p-3">
                                <EmptyList />
                                No requirements found in the provided text
                            </div>
                        )}
                        <div className="flex flex-col gap-2 overflow-auto" ref={ref}>
                            {requirements?.map((req, idx) => (
                                <div
                                    key={idx}
                                    role="button"
                                    onClick={() => {
                                        setRequirements((prev) => {
                                            const updatedReqs = [...prev];
                                            updatedReqs[idx] = {
                                                ...updatedReqs[idx],
                                                checked: !updatedReqs[idx].checked,
                                            };
                                            return updatedReqs;
                                        });
                                    }}
                                    className="cursor-default px-4 py-3 bg-gray-light border border-solid rounded-lg flex items-center gap-3"
                                >
                                    <Checkbox checked={req.checked} onCheck={() => {}} />
                                    <div className="text-xs font-normal whitespace-pre-line">{req.content}</div>
                                </div>
                            ))}
                            {isExtracting && !!requirements.length && (
                                <div className="my-2 relative h-6 min-h-[24px] flex justify-center w-full overflow-hidden">
                                    <Lottie
                                        animationData={loadingDots}
                                        style={{
                                            height: "100%",
                                            position: "absolute",
                                        }}
                                        css={{ transform: "scale(3.5)" }}
                                    />
                                </div>
                            )}
                        </div>
                        <Button
                            disabled={!hasChecked || isExtracting}
                            size="md"
                            variant="primary"
                            css={[!hasChecked && tw`pointer-events-none`]}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (hasChecked) {
                                    appendRequirements(
                                        requirements.filter(({ checked }) => checked),
                                        onSuccess
                                    );
                                }
                            }}
                        >
                            {isExtracting ? "Extracting" : "Add"}
                            {isExtracting && (
                                <Icon
                                    name="Generate"
                                    css={[{ animation: "rotateAnimation 0.5s infinite linear", marginLeft: "8px" }]}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SimpleRequirementExtraction;
