import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducerSlice";
import boltReducerSlice from "./reducers/boltReducerSlice";
import utilsRouter from "./reducers/utilsRouter";
import searchReducerSlice from "./reducers/searchRouterSlice";
import contractManagerReducerSlice from "./reducers/contractManagerReducerSlice";
import feedReducerSlice from "./reducers/feedReducerSlice";
import aiReducerSlice from "./reducers/aiReducerSlice";
import yjsEditorReducer from "./reducers/yjs-editor-reducer";
import driveReducerSlice from "./reducers/driveReducerSlice";
import modalsSlice from "./reducers/modalsSlice";
import ideationReducer from "./reducers/ideationReducer";
import newProjectReducer from "./reducers/newProjectReducer";
import templateExtractionReducer from "./reducers/templates/templateExtractionReducer";
import templatesReducer from "./reducers/templates/templatesReducer";
import projectProgressReducer from "./reducers/project-progress/projectProgressReducer";
import frameworkReducer from "./reducers/templates/frameworkReducer";
import projectReducer from "./reducers/projectReducer";
import writingAssistantReducer from "./reducers/writing-assistant/writingAssistantReducer";
import copilotDrawerReducer from "./reducers/copilot/copilotDrawerReducer";
import copilotReducer from "./reducers/copilot/copilotReducer";
import contentSearchReducer from "./reducers/copilot/contentSearchReducer";
import requirementsDrawerReducer from "./reducers/copilot/requirementsDrawerReducer";
import SectionIdeationReducer from "./reducers/copilot/SectionIdeationReducer";
import ExtractReducer from "./reducers/extract/ExtractReducer";
import AutopilotHealthReducer from "./reducers/copilot/AutopilotHealthReducer";
import copilotBannerReducer from "./reducers/copilot/copilotBannerReducer";
import requirementsSmartResponseReducer from "./reducers/requirementsSmartResponseReducer";
import requirementsReducer from "./reducers/copilot/requirementsReducer";
import volumeReducer from "./reducers/draft/volumeReducer";
import sectionReducer from "./reducers/draft/sectionReducer";
import WritingPromptIdeationReducer from "./reducers/copilot/WritingPromptIdeationReducer";
import projectAttachmentsReducer from "./reducers/projectAttachmentsReducer";
import previewProposalReducer from "./reducers/draft/previewProposalReducer";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        bolts: boltReducerSlice,
        utils: utilsRouter,
        ideation: ideationReducer,
        search: searchReducerSlice,
        contracts: contractManagerReducerSlice,
        feed: feedReducerSlice,
        yjsEditor: yjsEditorReducer,
        aiReducer: aiReducerSlice,
        drive: driveReducerSlice,
        modals: modalsSlice,
        requirementsSmartResponse: requirementsSmartResponseReducer,
        newProject: newProjectReducer,
        templateExtraction: templateExtractionReducer,
        templates: templatesReducer,
        projectProgress: projectProgressReducer,
        framework: frameworkReducer,
        project: projectReducer,
        writingAssistant: writingAssistantReducer,
        copilotDrawer: copilotDrawerReducer,
        copilot: copilotReducer,
        contentSearch: contentSearchReducer,
        requirementsDrawer: requirementsDrawerReducer,
        sectionIdeation: SectionIdeationReducer,
        writingPromptIdeation: WritingPromptIdeationReducer,
        extractReducer: ExtractReducer,
        autopilotHealthCheck: AutopilotHealthReducer,
        bannerState: copilotBannerReducer,
        requirements: requirementsReducer,
        volumeState: volumeReducer,
        sectionState: sectionReducer,
        projectAttachmentsState: projectAttachmentsReducer,
        previewProposalState: previewProposalReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
