/** @jsxImportSource @emotion/react */

import Popover from "components/atoms/popover";
import { ComponentProps, memo, useState } from "react";
import tw from "twin.macro";
import { ContentDrivePopoverContent } from "../content-drive-popover-content";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import ProjectAttachmentsPopoverContent from "../project-attachments-popover-content";
import { SOURCE_TO_META, Source } from "components/copilot/writing-assistant-drawer/writing-assistant-input/constants";
import { BaseFile } from "types/FileStorage";
import { getProjectAttachments } from "store/reducers/projectAttachmentsReducer";

interface Props extends Partial<ComponentProps<typeof Popover>> {
    selectedFiles: string[];
    handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
    onClear: (files: BaseFile[]) => void;
}

const AttachmentsPopover = ({ onClear, handleSelectedFiles, selectedFiles, onOpenChange, ...props }: Props) => {
    const [activeSource, setActiveSource] = useState<Source>();
    const [sourceOpen, setSourceOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const { activeProject } = useAppSelector((root) => root.project);
    const dispatch = useAppDispatch();
    const searchable = !!activeSource;

    return (
        <Popover
            content={
                <>
                    {!activeSource && (
                        <div
                            className="text-stone-800 text-sm min-w-[150px] max-w-[350px] max-h-[375px] overflow-y-auto flex flex-col p-1"
                            css={[searchable && tw`min-w-[300px]`]}
                        >
                            <button
                                onClick={() => setActiveSource(Source.ProjectAttachments)}
                                className="py-2 pr-3 pl-2 outline-none flex items-center justify-between gap-2 word-break-[break-word] hover:bg-slate-100"
                            >
                                {SOURCE_TO_META[Source.ProjectAttachments].copy}
                            </button>
                            <button
                                onClick={() => setActiveSource(Source.ContentDrive)}
                                className="py-2 pr-3 pl-2 outline-none flex items-center justify-between gap-2 word-break-[break-word] hover:bg-slate-100"
                            >
                                {SOURCE_TO_META[Source.ContentDrive].copy}
                            </button>
                        </div>
                    )}
                    {activeSource === Source.ProjectAttachments && (
                        <ProjectAttachmentsPopoverContent
                            query={query}
                            handleSelectedFiles={handleSelectedFiles}
                            selectedFiles={selectedFiles}
                        />
                    )}
                    {activeSource === Source.ContentDrive && (
                        <ContentDrivePopoverContent
                            query={query}
                            handleSelectedFiles={handleSelectedFiles}
                            onClear={onClear}
                            selectedFiles={selectedFiles}
                        />
                    )}
                </>
            }
            open={sourceOpen}
            onOpenChange={(o) => {
                if (!o) setActiveSource(undefined);
                if (o) dispatch(getProjectAttachments(activeProject?.internal_contract.id));
                setSourceOpen(o);
                onOpenChange?.(o);
            }}
            searchProps={{
                value: query,
                onChange: (e) => setQuery(e.target.value),
                placeholder: activeSource ? SOURCE_TO_META[activeSource].searchPlaceholder : undefined,
            }}
            searchable={searchable}
            contentProps={{
                align: "end",
                side: "top",
                css: [tw`mx-0 backdrop-blur-sm ml-0 bg-[rgba(255,255,255,0.95)]`, searchable && tw`min-w-[400px]`],
            }}
            {...props}
        />
    );
};

export default memo(AttachmentsPopover);
