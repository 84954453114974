import { createClient } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { Presence, UserMeta, Storage } from "./types";

export const client = createClient({
    authEndpoint: async (room) => {
        const session_token = (localStorage.getItem("vultron_user_token") ?? "").replaceAll('"', "");
        const workspace_id = (localStorage.getItem("vultron_workspace_id") ?? "").replaceAll('"', "");

        const tokens = room.split("/");
        const requestType = tokens[0];
        const internalContractId = tokens[1];
        const requestId = tokens[2];

        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/contracts/internal/${internalContractId}/proposal/${requestType}/request_edit`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${session_token}`,
                    Workspace: `Workspace ${workspace_id}`,
                },
                body: JSON.stringify({ id: requestId }),
            }
        );
        return await response.json();
    },
});

export const { RoomProvider, useOthers, useRoom, useList, useSelf, useUpdateMyPresence } = createRoomContext<
    Presence,
    Storage,
    UserMeta
>(client);
