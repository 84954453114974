/** @jsxImportSource @emotion/react */
import { HTMLAttributes, ReactNode } from "react";
import "./styles.css";
import "twin.macro";
import classNames from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
    percentage: number;
    stroke?: string;
    background?: string;
    strokeWidth?: number;
    completedNode?: ReactNode;
}

const CircularPercentage = ({
    percentage,
    stroke,
    strokeWidth,
    background,
    completedNode,
    className,
    ...props
}: Props) => {
    return (
        <div
            className={classNames([
                "justify-center relative items-center flex font-semibold w-[46px] h-[46px] text-action text-[9px]",
                className,
            ])}
            {...props}
        >
            <svg viewBox="0 0 36 36" className="circular-chart stroke-action flex justify-center items-center">
                <path
                    className="circle-bg"
                    stroke={background || "#2a47ab1a"}
                    strokeWidth="3"
                    d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                    className="circle duration-300"
                    css={[
                        {
                            strokeDashoffset: 100 - percentage,
                        },
                    ]}
                    stroke={stroke}
                    strokeWidth={strokeWidth || "3"}
                    strokeDasharray={100}
                    d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                />
            </svg>
            {completedNode && percentage === 100 ? (
                completedNode
            ) : (
                <div className="progress-count absolute stroke-[0.3]" css={{ "--num": percentage }} />
            )}
        </div>
    );
};

export default CircularPercentage;
