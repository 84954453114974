import { Link, useSearchParams } from "react-router-dom";
import error from "../../Assets/error.png";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import axios from "axios";
import { getWorkSpaces, setAuth } from "store/reducers/authReducerSlice";
import { useDispatch } from "react-redux";
import { useNotification } from "context/notificationContext";

// --------------- Auth Redirect page, When a user gets a verification email, -----------------------
const CheckLoginTokenPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [emailExpired, setEmailExpired] = useState(false);
    let [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const sso = searchParams.get("stytch_token_type") === "sso";
    // const navigate = useNavigate();
    const { setToast } = useNotification();
    const dispatch = useDispatch();

    //
    useEffect(() => {
        // check auth token
        const checkToken = () => {
            if (!token) {
                setIsLoading(false);
                return;
            }
            const synthetic_test_secret = localStorage.getItem("synthetic_test_secret");
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            if (synthetic_test_secret) {
                headers["X-Synthetic-Test-User-Token"] = synthetic_test_secret;
            }
            axios({
                method: "POST",
                url: "/users/authenticate/",
                data: { sso },
                headers: headers,
            })
                .then((res) => {
                    setEmailExpired(false);
                    setIsLoading(true);
                    let token = res?.data?.session_token;
                    let sso = res?.data?.sso;
                    localStorage.setItem("vultron_user_token", JSON.stringify(token));
                    localStorage.setItem("vultron_user_sso", JSON.stringify(sso));
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    axios.defaults.headers["X-Authorization-SSO"] = sso;
                    dispatch(getWorkSpaces(token));
                    dispatch(
                        setAuth({
                            isAuthenticated: true,
                            isLoading: false,
                        })
                    );
                })
                .catch((err) => {
                    setIsLoading(false);
                    let errType = err?.response?.status;
                    switch (errType) {
                        case 401:
                            setEmailExpired(true);
                            const msg = sso
                                ? "Authentication using the SSO provider failed."
                                : "Your link has been expired. Please resend the new link.";
                            setToast({
                                isOpen: true,
                                msg,
                                variant: "error",
                            });
                            break;
                        default:
                            setToast.error({
                                title: "Authentication failed",
                                msg: "We were unable to authenticate the login request due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                            });
                    }
                    console.log("error while verifying the user email link token. ", err);
                });
        };
        checkToken();

        // eslint-disable-next-line
    }, []);

    //
    if (isLoading) return <Loading />;
    if (sso) {
        return (
            <div className="flex flex-col items-center">
                <img src={error} alt="" className="mb-8" />
                <div className="flex flex-col items-center mb-8">
                    <h1 className="text-2xl font-semibold pt-4">Authentication Failed</h1>
                    <p className="text-[#5b6b79] py-2 text-sm w-[100%] text-center leading-relaxed">
                        Authentication using the SSO provider failed. <br /> Please contact your organization
                        administrator.{" "}
                    </p>
                </div>
            </div>
        );
    }
    if (!token || emailExpired) {
        return (
            <div className="flex flex-col items-center">
                <img src={error} alt="" className="mb-8" />
                <div className="flex flex-col items-center mb-8">
                    <h1 className="text-2xl font-semibold pt-4">Email link expired</h1>
                    <p className="text-[#5b6b79] py-2 text-sm w-[100%] text-center leading-relaxed">
                        Email links expire after 7 days and can only be used once. <br /> Resend the link to try again{" "}
                    </p>
                </div>
                <Link
                    to="/auth/login"
                    className="bg-action text-white flex  w-[450px] flex-col rounded-lg justify-center p-3 items-center text-base font-medium min-h-[60px]"
                >
                    <span>Resend Link</span>
                </Link>
            </div>
        );
    }
    return (
        <div className="flex flex-col items-center">
            <img src={error} alt="" className="mb-8" />
            <div className="flex flex-col items-center  mb-8">
                <h1 className="text-2xl font-semibold pt-4">Authentication Failed</h1>
                <p className="text-[#5b6b79] py-2 text-sm w-[100%] text-center leading-relaxed">
                    {" "}
                    The authentication failed <br /> Resend the link to try again{" "}
                </p>
            </div>
            <Link
                to="/auth/login"
                className="bg-action text-white flex  w-[450px] flex-col rounded-lg justify-center p-3 items-center text-base font-medium min-h-[60px]"
            >
                <span>Resend Link</span>
            </Link>
        </div>
    );
};

export default CheckLoginTokenPage;
