import { useState } from "react";
import { ScreenSpinner, Spinner } from "../../../utils/icons";
import { getProposalAssistantDocs } from "api/api";
import NotFoundItems from "components/NotFoundItems";
import { useEffect } from "react";
import loadingImg from "../../../Assets/generating.gif";
import pdf from "../../../Assets/pdf.svg";
import doc from "../../../Assets/doc.svg";
import xls from "../../../Assets/xls.svg";
import zip from "../../../Assets/zipNew.svg";
import pptx from "../../../Assets/pptx.png";
import commonFile from "../../../Assets/defaultDocument.svg";
import dots from "../../../Assets/3dots.svg";
import trash from "../../../Assets/trashlrg.svg";
import download from "../../../Assets/downloadlrg.svg";
import { formatBytes, getSortByTransactionDate } from "utils/helpers";
import moment from "moment/moment";
import ClickAwayListener from "helpers/ClickAwayListener";
import { deleteProposalAssistantDocs } from "api/api";
import { useNotification } from "context/notificationContext";

const ExtractingDocumentToast = ({ numDocuments, handleClose }) => {
    const documentText = `${numDocuments} ${numDocuments <= 1 ? "document" : "documents"}`;
    return (
        <div className="fixed bottom-0 right-0 z-50 bg-white w-96 h-24 border shadow-lg flex mb-6 mr-6">
            <div className="w-8/24 flex items-center justify-center mr-1">
                <img
                    width="80"
                    height="80"
                    className="mx-auto max-w-full object-contain"
                    style={{ maxWidth: "calc(100vh - 15%)" }}
                    src={loadingImg}
                    alt="Loading..."
                />
            </div>
            <div className="w-16/24 flex items-center justify-center text-gray-500">
                <p>
                    Storing {documentText}
                    <br></br>
                    into long-term memory...
                </p>
            </div>
            <button className="absolute top-1 right-1 rounded hover:bg-gray-100 p-1" onClick={(e) => handleClose(e)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-4 w-4"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    );
};

const DocumentTable = ({ searchVal, forceRefresh, setForceRefresh }) => {
    const { setToast } = useNotification();

    const [documents, setDocuments] = useState([]);
    const [forceHideExtractingToast, setforceHideExtractingToast] = useState(false);
    const [extractingDocumentNum, setExtractingDocumentNum] = useState(0);
    const [loading, setLoading] = useState(true);

    const [selectDocumentMenu, setSelectDocumentMenu] = useState(null);
    const [deletingDocs, setDeletingDocs] = useState([]);

    const handleMenuToggle = (ind) => {
        setSelectDocumentMenu((prev) => (prev === ind ? null : ind));
    };

    // delete a document
    const deleteDoc = (docID) => {
        if (deletingDocs?.includes(docID)) return;
        setSelectDocumentMenu(docID);
        setDeletingDocs([...deletingDocs, docID]);
        deleteProposalAssistantDocs({ document_id: docID })
            .then(() => {
                setSelectDocumentMenu((prev) => (prev === docID ? null : prev));
                setToast.success({
                    msg: "Document deleted",
                });
            })
            .catch((err) => {
                console.log("Error while deleting a government document ", err);
                setToast.error({
                    title: "Unable to delete document",
                    msg: "We were unable to delete the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {
                setDeletingDocs((prev) => prev?.filter((b) => b !== docID));
            });
    };

    useEffect(() => {
        setLoading(true);
        getProposalAssistantDocs()
            .then((res) => {
                setDocuments(res.data || []);
            })
            .catch((err) => {
                console.log("Error while fetching documents", err);
                setToast.error({
                    title: "Unable to retrieve documents",
                    msg: "We were unable to retrieve documents due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {
                setLoading(false);
                setForceRefresh(false);
            });
    }, [forceRefresh]);

    useEffect(() => {
        setExtractingDocumentNum(documents.filter((doc) => doc?.extract_document_task_state === "In Progress").length);
    }, [documents]);

    // Render img icon based on type
    const imgIcon = (type) => {
        let img = commonFile;
        switch (type) {
            case "pdf":
            case "application/pdf":
                img = pdf;
                break;
            case "doc":
            case "docx":
            case "application/doc":
            case "application/docx":
                img = doc;
                break;
            case "xls":
            case "xlsx":
            case "application/xls":
            case "application/xlsx":
                img = xls;
                break;
            case "pptx":
            case "potx":
            case "ppsx":
                img = pptx;
                break;
            case "zip":
                img = zip;
                break;
            default:
                img = commonFile;
        }
        return img;
    };

    return (
        <div className="p-2">
            <p className="text-base text-gray-text px-3">
                Please upload all relevant documents, such as previous proposals and product documentation, to better
                enable our AI to support the proposal crafting process.
            </p>
            <div className="border relative min-h-[80vh] max-w-full bg-[#fdfdfd] mt-5 mb-2 py-2 px-3 rounded-md">
                {loading && (
                    <div className="p-7 top-0 left-0 w-full h-full absolute bg-[#6b69690e] shadow-sm z-[2] backdrop-blur-[2px] my-auto flex items-center justify-center">
                        <ScreenSpinner />
                    </div>
                )}
                {documents?.filter((v) => v.name.toLowerCase().includes(searchVal.toLowerCase())).length > 0 ? (
                    documents
                        ?.filter((v) => v.name.toLowerCase().includes(searchVal.toLowerCase()))
                        ?.sort((a, b) => getSortByTransactionDate(a, b, "created_at"))
                        ?.map((v, i) => (
                            <div
                                key={i}
                                className="w-full max-w-full shadow-sm flex font-normal text-sm justify-between items-center rounded-lg px-4 py-2 my-2 bg-white border"
                            >
                                <div className="flex flex-grow-1 w-full items-center gap-3 max-w-[50%]">
                                    <img src={imgIcon(v?.file_type || v?.mimetype)} width={32} alt="" className="" />
                                    <p className="pr-1 line-clamp-3 break-words overflow-hidden max-w-full">
                                        {v?.name?.replaceAll("-", " ")?.replaceAll("+", " ")}
                                    </p>
                                </div>
                                <p className="min-w-[80px] px-1 text-end text-sm">{formatBytes(v?.upload_file_size)}</p>
                                <p className="min-w-[110px] pl-1 pr-2 text-end text-sm">
                                    {moment(v?.created_at).format("DD MMM YYYY")}
                                </p>
                                <ClickAwayListener
                                    onClickAway={() => setSelectDocumentMenu(null)}
                                    className="relative p-3 rounded-r-lg w-[60px]"
                                >
                                    <img
                                        src={dots}
                                        alt="menu"
                                        onClick={() => handleMenuToggle(i)}
                                        className="cursor-pointer select-none ml-auto"
                                    />
                                    <div
                                        className={
                                            selectDocumentMenu === i
                                                ? "absolute right-1 top-[100%] z-[2] bg-white  p-1 shadow-md w-[120px]"
                                                : "hidden"
                                        }
                                    >
                                        <a href={v?.download_url} download className="flex gap-2 font-normal p-1">
                                            <img src={download} alt="icon" />
                                            Download
                                        </a>
                                        <button
                                            disabled={deletingDocs?.includes(v?.id) ? true : false}
                                            onClick={() => deleteDoc(v.id)}
                                            className="flex items-center gap-2 text-red-600 p-1 disabled:opacity-60 cursor-pointer select-none w-full"
                                        >
                                            {deletingDocs?.includes(v?.id) ? (
                                                <Spinner classes="!text-black" width={13} height={13} />
                                            ) : (
                                                <img src={trash} alt="icon" />
                                            )}
                                            Delete
                                        </button>
                                    </div>
                                </ClickAwayListener>
                            </div>
                        ))
                ) : loading ? null : (
                    <div className="p-20">
                        <NotFoundItems
                            className={"min-h-[] !bg-transparent"}
                            title={"No documents found"}
                            subTitle={"There are no uploaded documents found."}
                        />
                    </div>
                )}
            </div>
            {extractingDocumentNum !== 0 && !forceHideExtractingToast && (
                <ExtractingDocumentToast
                    numDocuments={extractingDocumentNum}
                    handleClose={() => {
                        setforceHideExtractingToast(true);
                    }}
                />
            )}
        </div>
    );
};

export default DocumentTable;
