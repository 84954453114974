import { EMPTY_SHEET } from "const-values/Sheets";
import { useStorage } from "liveblocks.config";
import DropdownMenu, { DropdownMenuProps } from "../dropdown-menu";
import tw from "twin.macro";
import { PropsWithChildren, useMemo } from "react";
import { Sheet, Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";

interface Props extends Omit<DropdownMenuProps<Sheet>, "items" | "multiselect"> {
    selectedSheet?: string;
    onSelect: (value: Sheet) => void;
}

const SheetsDropdown = ({ selectedSheet, onSelect, children, ...props }: PropsWithChildren<Props>) => {
    const shouldShowDefault = useStorage((storage) =>
        (storage.compliance_matrix as ImmutableStorage["compliance_matrix"]).some(
            (row) => !row.requirement?.extraction_id
        )
    );
    const sheets = useStorage((storage) => [
        ...(shouldShowDefault ? [EMPTY_SHEET] : []),
        ...((storage.sheets as ImmutableStorage["sheets"]) || []),
    ]);
    const availableSheetOptions = useMemo(
        () =>
            (sheets || [])?.map((sheet) => ({
                key: sheet.id,
                label: sheet.name,
                value: sheet.id,
                selected: selectedSheet === sheet.id,
                onSelect: () => onSelect(sheet),
            })),
        [onSelect, selectedSheet, sheets]
    );

    return (
        <DropdownMenu label="Select Sheet" triggerProps={{ css: tw`w-fit` }} items={availableSheetOptions} {...props}>
            {children}
        </DropdownMenu>
    );
};

export default SheetsDropdown;
