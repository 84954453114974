import confetti from "canvas-confetti";
import { useCallback, useEffect } from "react";
import { theme } from "twin.macro";

export const useConfettiCannon = (config: Partial<{ rain: boolean; blasters: boolean }>) => {
    const rain = useCallback(() => {
        const duration = 5 * 1000;
        const animationEnd = Date.now() + duration;
        let skew = 1;

        function randomInRange(min: number, max: number) {
            return Math.random() * (max - min) + min;
        }

        (function frame() {
            const timeLeft = animationEnd - Date.now();
            const ticks = Math.max(200, 500 * (timeLeft / duration));
            skew = Math.max(0.8, skew - 0.001);

            confetti({
                particleCount: 1,
                startVelocity: 0,
                ticks: ticks,
                origin: {
                    x: Math.random(),
                    // since particles fall down, start skew toward top
                    y: Math.random() * skew - 0.2,
                },
                colors: [theme`colors.action.DEFAULT`, theme`colors.action.light`],
                shapes: ["circle"],
                gravity: randomInRange(0.4, 0.6),
                scalar: randomInRange(0.4, 1),
                drift: randomInRange(-0.4, 0.4),
                zIndex: 999999,
            });

            if (timeLeft > 0) {
                requestAnimationFrame(frame);
            }
        })();
    }, []);

    const sideBlasters = useCallback(() => {
        const end = Date.now() + 4.5 * 1000;
        const colors = [theme`colors.action.DEFAULT`, theme`colors.action.light`];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors,
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors,
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();
    }, []);

    useEffect(() => {
        if (config.rain && config.blasters) {
            rain();
            setTimeout(sideBlasters, 2000);
        } else if (config.rain) {
            rain();
        } else if (config.blasters) {
            sideBlasters();
        }
    }, [config.blasters, config.rain, rain, sideBlasters]);
};
