import { useEffect } from "react";

export const useObserveSseController = (abortConnection: () => void, onAbortSuccess?: () => void) => {
    // const controllerRef = useRef(new AbortController());
    // const { current: controller } = controllerRef;

    useEffect(() => {
        // const beforeUnloadCallback = (event: any) => {
        //     if (isConnectionOpen) {
        //         event.preventDefault();
        //         event.returnValue = ""; // * required for browser compatibility
        //         return ""; // * required for browser compatibility
        //     }
        //     return "";
        // };
        const unloadCallback = () => {
            abortConnection();
            onAbortSuccess?.();
        };
        const onVisibileChange = () => {
            if (document.visibilityState === "hidden") {
                abortConnection();
                onAbortSuccess?.();
            }
        };
        // if (isConnectionOpen) {
        window.addEventListener("visibilitychange", onVisibileChange);
        // window.addEventListener("beforeunload", beforeUnloadCallback);
        window.addEventListener("unload", unloadCallback);
        // }
        return () => {
            // if (!isConnectionOpen) {
            window.removeEventListener("visibilitychange", onVisibileChange);
            window.removeEventListener("unload", unloadCallback);
            // window.removeEventListener("beforeunload", beforeUnloadCallback);
            abortConnection();
            onAbortSuccess?.();
            // }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const abortConnection = useCallback(() => {
    //     if (isConnectionOpen) {
    //         controller.abort();
    //         onAbortSuccess?.();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [controller, isConnectionOpen, onAbortSuccess]);

    return { abortConnection };
};
