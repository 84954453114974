import { useEffect, useState } from "react";
import plusCircle from "../../Assets/plus-circle.svg";
import CustomModal from "../../components/CustomModal";
import { CheckMarkGreen, CloseIconBlack, CloseIconSm, InfoIconWarn, Spinner } from "../../utils/icons";
import { useSelector, useDispatch } from "react-redux";
import { formateUsersList, lowerString, validateEmail, validateField } from "utils/helpers";
import { useLocalStorage } from "hook/useLocalStorage";
import { inviteInWorkspace, removeUserWorkspace, updateUserWorkspace } from "api/api";
import { getWorkspaceUsers, setMyWorkSpaces, setWorkSpacesMembers } from "store/reducers/authReducerSlice";
import { useNotification } from "context/notificationContext";

// ------------ Workspace manage -------------
const WorkSpace = () => {
    const dispatch = useDispatch();
    const { localValue } = useLocalStorage("vultron_workspace_id", "");
    const { myWorkSpaces, isLoadingWorkSpaces, currentUser, workspaceMembers, isLoadingMembers } = useSelector(
        (store) => store.auth
    );
    const [inviteModal, setInviteModal] = useState(false);
    const [askModal, setAskModal] = useState({ open: false, id: "" });
    const { setToast } = useNotification();
    const isAdmin = lowerString(currentUser?.role) === "admin";
    const [formData, setFormData] = useState({
        name: "",
        description: "",
    });
    const [updating, setUpdating] = useState(false);
    const [updatingCompany, setUpdatingCompany] = useState(false);
    const [updatingDesc, setUpdatingDesc] = useState(false);
    const [email, setEmail] = useState("");
    const [sendingInvite, setSendingInvite] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [removingUser, setRemovingUser] = useState(false);
    const [error, setError] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [nameUpdated, setNameUpdated] = useState({
        saved: false,
        lastText: "",
    });
    const [companyUpdated, setCompanyUpdated] = useState({
        saved: false,
        lastText: "",
    });
    const [descUpdated, setDescUpdated] = useState({
        saved: false,
        lastText: "",
    });

    // current workspace
    const currentWorkspace = () => {
        return myWorkSpaces?.workspaces?.find((workspace) => workspace?.id === localValue) || {};
    };

    // handle close invite modal
    const handleCloseInviteModal = () => {
        if (sendingInvite) return;
        setInviteModal(false);
        setEmail("");
        setEmailError(false);
    };

    // remove a user
    const removeUser = () => {
        if (!askModal?.id || removingUser) return;
        setRemovingUser(true);
        removeUserWorkspace({
            member_id: askModal?.id,
        })
            .then((res) => {
                let prevList = [...workspaceMembers];
                prevList = prevList.filter((v) => v?.id !== askModal?.id);
                dispatch(
                    setWorkSpacesMembers({
                        data: prevList,
                        loading: false,
                    })
                );
                let workspacesList = [...myWorkSpaces?.workspaces];
                let index = workspacesList?.findIndex((v) => v.id === localValue);
                workspacesList[index] = {
                    ...workspacesList[index],
                    number_of_users: workspacesList[index]?.number_of_users - 1,
                };
                dispatch(
                    setMyWorkSpaces({
                        data: {
                            email: myWorkSpaces?.email,
                            workspaces: workspacesList,
                        },
                        loading: false,
                    })
                );
                setAskModal({ open: false, id: "" });
                setToast.success({
                    msg: "Member removed from the workspace",
                });
            })
            .catch((err) => {
                console.log("Error while removing member from workspace ", err);
                let errResp = err?.response?.data?.error_msg;
                console.log("Error while sending invite ", errResp || err);
                setToast.error({
                    title: "Unable to remove member",
                    msg:
                        errResp ||
                        "There was an error removing the member from the workspace. Please refresh and try again.",
                });
            })
            .finally(() => {
                setRemovingUser(false);
            });
    };

    // handle input change
    const handleChange = (e) => {
        if (!isAdmin || isLoadingWorkSpaces || updating) return;
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Update workspace details, company name only
    const handleUpdateCompanyName = (e) => {
        e?.preventDefault();
        if (!isAdmin || isLoadingWorkSpaces || updatingCompany) return;
        if (!validateField(formData.company_name)) {
            setCompanyError("Company name is required");
            return;
        }
        if (formData.company_name?.trim() === companyUpdated?.lastText?.trim()) {
            setCompanyUpdated({
                saved: true,
                lastText: formData?.company_name,
            });
            return;
        }
        setCompanyError("");
        setUpdatingCompany(true);
        setCompanyUpdated({
            saved: false,
            lastText: formData?.company_name,
        });
        updateUserWorkspace({
            company_name: formData?.company_name,
        })
            .then(() => {
                let updatedWorkspace = { ...myWorkSpaces };
                let list = [...updatedWorkspace.workspaces];
                let index = list?.findIndex((v) => v?.id === localValue);
                list[index] = {
                    ...list[index],
                    company_name: formData?.company_name,
                };
                updatedWorkspace.workspaces = list;
                dispatch(
                    setMyWorkSpaces({
                        data: updatedWorkspace,
                        loading: false,
                    })
                );
                setCompanyUpdated({
                    saved: true,
                    lastText: formData?.company_name,
                });
            })
            .catch((err) => {
                if (err?.response?.status === 500) {
                    setCompanyError(
                        "Company already exists. Please try using another name or request to join organization."
                    );
                    setCompanyUpdated({
                        updated: false,
                        lastText: "",
                    });
                }
                setToast.error({
                    title: "Unable to update workspace",
                    msg:
                        err?.response?.status === 500
                            ? "Company name already exists. Please use a different company name. If you think this is a problem on our side, please reach out to support@vultron.ai for assistance."
                            : "We were unable to update the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {
                setUpdatingCompany(false);
            });
    };

    // Update workspace details, name only
    const handleUpdateWorkSpaceName = (e) => {
        e?.preventDefault();
        if (!isAdmin || isLoadingWorkSpaces || updating) return;
        if (!validateField(formData.name)) {
            setError("Workspace name is required");
            return;
        }
        if (formData.name?.trim() === nameUpdated?.lastText?.trim()) {
            setNameUpdated({
                saved: true,
                lastText: formData?.name,
            });
            return;
        }
        setError("");
        setUpdating(true);
        setNameUpdated({
            saved: false,
            lastText: formData?.name,
        });
        updateUserWorkspace({
            name: formData?.name,
            // description: formData?.description
        })
            .then(() => {
                let updatedWorkspace = { ...myWorkSpaces };
                let list = [...updatedWorkspace.workspaces];
                let index = list?.findIndex((v) => v?.id === localValue);
                list[index] = {
                    ...list[index],
                    name: formData?.name,
                    // description: formData?.description
                };
                updatedWorkspace.workspaces = list;
                dispatch(
                    setMyWorkSpaces({
                        data: updatedWorkspace,
                        loading: false,
                    })
                );
                setNameUpdated({
                    saved: true,
                    lastText: formData?.name,
                });
            })
            .catch((err) => {
                if (err?.response?.status === 500) {
                    setError("Name already exists in workspace.");
                    setNameUpdated({
                        updated: false,
                        lastText: "",
                    });
                }
                setToast.error({
                    title: "Unable to update workspace",
                    msg:
                        err?.response?.status === 500
                            ? "Workspace name already exists. Please contract support@vultron.ai for assistance if you believe this is an issue."
                            : "We were unable to update the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
                console.log("Error while updating a particular workspace name from settings tab. ", err);
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    // Update workspace description only
    const handleUpdateWorkSpaceDesc = (e) => {
        e?.preventDefault();
        if (!isAdmin || isLoadingWorkSpaces || updatingDesc) return;
        if (formData.description?.trim() === descUpdated?.lastText?.trim()) {
            setDescUpdated({
                saved: true,
                lastText: formData?.description,
            });
            return;
        }
        setUpdatingDesc(true);
        updateUserWorkspace({
            description: formData?.description,
        })
            .then(() => {
                let updatedWorkspace = { ...myWorkSpaces };
                let list = [...updatedWorkspace.workspaces];
                let index = list?.findIndex((v) => v?.id === localValue);
                list[index] = {
                    ...list[index],
                    description: formData?.description,
                };
                updatedWorkspace.workspaces = list;
                dispatch(
                    setMyWorkSpaces({
                        data: updatedWorkspace,
                        loading: false,
                    })
                );
                setDescUpdated({
                    saved: true,
                    lastText: formData?.description,
                });
            })
            .catch((err) => {
                setToast.error({
                    title: "Unable to update workspace",
                    msg: "We were unable to update the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            })
            .finally(() => {
                setUpdatingDesc(false);
            });
    };

    // Send invite to a member to join our workspace
    const handleSendInvite = (e) => {
        e.preventDefault();
        if (sendingInvite) return;
        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }
        setSendingInvite(true);

        inviteInWorkspace({ email })
            .then(() => {
                setInviteModal(false);
                setToast.success({
                    msg: "Invite sent",
                });
                setEmail("");
                setEmailError(false);
                let workspacesList = [...myWorkSpaces?.workspaces];
                let index = workspacesList?.findIndex((v) => v.id === localValue);
                workspacesList[index] = {
                    ...workspacesList[index],
                    number_of_users: workspacesList[index]?.number_of_users + 1,
                };
                dispatch(
                    setMyWorkSpaces({
                        data: {
                            email: myWorkSpaces?.email,
                            workspaces: workspacesList,
                        },
                        loading: false,
                    })
                );
                dispatch(getWorkspaceUsers());
            })
            .catch((err) => {
                let errResp = err?.response?.data?.error_msg;
                setToast.error({
                    title: "Invite failed",
                    msg:
                        errResp ||
                        "There was an issue sending the invite. Please make sure the email is valid and try again.",
                });
            })
            .finally(() => {
                setSendingInvite(false);
            });
    };

    //
    useEffect(() => {
        setFormData(currentWorkspace());
    }, [myWorkSpaces]);

    //
    return (
        <div className="py-3">
            <div className="border rounded-lg mb-3 bg-white p-5">
                <h4 className="font-[500] text-[17px] mb-4 flex items-center">
                    Company Information
                    {isAdmin ? (
                        <span className="mx-2 flex items-center justify-end text-[13px] bg-slate-300 py-3 px-2 rounded-md text-gray-500 font-semibold leading-[0]">
                            Admin
                        </span>
                    ) : null}
                </h4>
                {isAdmin ? (
                    <div className={`mt-8 mb-4`}>
                        <form onSubmit={handleUpdateWorkSpaceName} className={`${updating ? " opacity-60" : ""}`}>
                            <label className="text-gray-500 mb-2 block" htmlFor="name">
                                Workspace Name
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={formData?.name}
                                    disabled={updating}
                                    placeholder="Acme Team"
                                    onChange={(e) => {
                                        if (e.target.value.length > 140) return;
                                        handleChange(e);
                                        setError(validateField(e.target.value) ? "" : "Workspace name is required");
                                        setNameUpdated({
                                            ...nameUpdated,
                                            saved: nameUpdated?.lastText?.trim() === e.target.value?.trim(),
                                        });
                                    }}
                                    onBlur={handleUpdateWorkSpaceName}
                                    className={`w-full text-[16px] placeholder:text-gray-500 outline-0 ${
                                        error ? "text-red-500 border-red-500" : "text-[#1e1e1e] focus:border-action"
                                    } px-3 py-2 rounded-lg border`}
                                />
                                {updating ? (
                                    <div className="absolute flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                                        <Spinner classes="!text-black" />
                                    </div>
                                ) : error ||
                                  nameUpdated?.saved ||
                                  nameUpdated?.lastText?.trim() === formData?.name?.trim() ? (
                                    <div className="absolute  flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                                        {error ? (
                                            <span className="bg-red-500 p-[5px] rounded-full text-white">
                                                <CloseIconSm width={10} height={10} />
                                            </span>
                                        ) : (
                                            <CheckMarkGreen />
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            {error ? <p className="text-red-500 mt-1 mb-2 block text-[14px]">{error}</p> : null}
                        </form>
                        <form onSubmit={handleUpdateCompanyName} className={`${updatingCompany ? " opacity-60" : ""}`}>
                            <label className="text-gray-500 mt-6 mb-2 block" htmlFor="name">
                                Company Name
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="company_name"
                                    id="company_name"
                                    value={formData?.company_name}
                                    disabled={updatingCompany}
                                    placeholder="Acme Inc."
                                    onChange={(e) => {
                                        if (e.target.value.length > 100) return;
                                        handleChange(e);
                                        setCompanyError(
                                            validateField(e.target.value) ? "" : "Company name is required"
                                        );
                                        setCompanyUpdated({
                                            ...companyUpdated,
                                            saved: companyUpdated?.lastText?.trim() === e.target.value?.trim(),
                                        });
                                    }}
                                    onBlur={handleUpdateCompanyName}
                                    className={`w-full text-[16px] placeholder:text-gray-500 outline-0 ${
                                        companyError
                                            ? "text-red-500 border-red-500"
                                            : "text-[#1e1e1e] focus:border-action"
                                    } px-3 py-2 rounded-lg border`}
                                />
                                {updating ? (
                                    <div className="absolute flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                                        <Spinner classes="!text-black" />
                                    </div>
                                ) : companyError ||
                                  companyUpdated?.saved ||
                                  companyUpdated?.lastText?.trim() === formData?.company_name?.trim() ? (
                                    <div className="absolute  flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                                        {companyError ? (
                                            <span className="bg-red-500 p-[5px] rounded-full text-white">
                                                <CloseIconSm width={10} height={10} />
                                            </span>
                                        ) : (
                                            <CheckMarkGreen />
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            {companyError ? (
                                <p className="text-red-500 mt-1 mb-2 block text-[14px]">{companyError}</p>
                            ) : null}
                        </form>
                        <form onSubmit={handleUpdateWorkSpaceDesc} className={`${updatingDesc ? " opacity-60" : ""}`}>
                            <label className="text-gray-500 mb-2 mt-6 block" htmlFor="description">
                                Company Overview
                            </label>
                            <div className="relative">
                                <textarea
                                    rows={3}
                                    onKeyDown={(e) => e.code === "Enter" && !e.shiftKey && handleUpdateWorkSpaceDesc(e)}
                                    name="description"
                                    id="description"
                                    placeholder="Acme Inc. provides a secure communication system with high-level encryption for government agencies, ensuring efficient and confidential data exchange."
                                    value={formData?.description}
                                    disabled={updatingDesc}
                                    onChange={handleChange}
                                    onBlur={handleUpdateWorkSpaceDesc}
                                    className={`w-full min-h-[100px] mb-3 text-[16px] text-[#1e1e1e] placeholder:text-gray-500 outline-0 focus:border-action px-3 py-3 rounded-lg border`}
                                />
                            </div>
                        </form>
                    </div>
                ) : (
                    <div className={`mt-8 mb-4 ${updating ? " opacity-60" : ""}`}>
                        <p className="text-gray-500 mb-2 block">Workspace Name</p>
                        <p className="w-full mb-6 text-[16px] text-[#1e1e1e] placeholder:text-gray-500">
                            {formData?.name || "-"}
                        </p>
                        <p className="text-gray-500 mb-2 block">Company Name</p>
                        <p className="w-full mb-6 text-[16px] text-[#1e1e1e] placeholder:text-gray-500">
                            {formData?.company_name || "-"}
                        </p>
                        <p className="text-gray-500 mb-2 block">Company Overview</p>
                        <p className="w-full mb-11 text-[16px] text-[#1e1e1e] placeholder:text-gray-500">
                            {formData?.description || "-"}
                        </p>
                    </div>
                )}
                <div className="flex justify-between items-center">
                    <p className="font-[500] text-[17px] pl-1">Members</p>
                    {isAdmin ? (
                        <button
                            onClick={() => {
                                setInviteModal(true);
                                setEmailError(false);
                                setEmail("");
                            }}
                            className="px-3 py-2 bg-action text-white font-primary-font text-sm rounded-lg items-center flex justify-center"
                        >
                            <img className="mr-2" src={plusCircle} alt="" />
                            Add Member
                        </button>
                    ) : null}
                </div>
                {/* members table */}
                {isLoadingMembers ? (
                    <div className="flex items-center justify-center p-6">
                        <Spinner width={29} height={29} classes="!text-black" />
                    </div>
                ) : (
                    <div className="max-w-full overflow-x-visible border bg-white rounded-lg mt-5 mb-3">
                        <table className="table-auto w-full text-[#1e1e1e]">
                            <thead className="border-b text-[#1e1e1e] text-sm">
                                <tr className="text-left bg-[#fafafa]">
                                    <th className="px-5 py-3 font-medium">Members</th>
                                    <th className="px-4 py-3 font-medium">
                                        <div className="border-l pl-4 py-2 border-gray-300">Email</div>
                                    </th>
                                    <th className="px-4 py-3 font-medium">
                                        <div className="border-l pl-4 py-2 border-gray-300">Access</div>
                                    </th>
                                    {isAdmin ? (
                                        <th className="px-4 py-3 font-medium">
                                            <div className="border-l py-2 border-gray-300 px-4">Action</div>
                                        </th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {formateUsersList(workspaceMembers, currentUser, "id")?.map((member, i) => (
                                    <tr
                                        key={i}
                                        className={`text-left text-sm bg-white ${
                                            workspaceMembers?.length === i + 1 ? "" : "border-b"
                                        }`}
                                    >
                                        <td className="px-5 py-5">{member?.username || "-"}</td>
                                        <td className="px-4 py-3">
                                            <div className="px-5">{member?.email || "-"}</div>
                                        </td>
                                        <td className="px-4 py-3 w-[210px]">
                                            <div className="px-5">{member?.role || "-"}</div>
                                        </td>
                                        {isAdmin ? (
                                            <td className="px-4 py-3 w-[150px]">
                                                {member?.id !== currentUser?.id ? (
                                                    <button
                                                        onClick={() => setAskModal({ open: true, id: member?.id })}
                                                        className="text-red-500 mx-auto py-2 px-4  border-0 outline-none cursor-pointer select-none"
                                                    >
                                                        Remove
                                                    </button>
                                                ) : null}
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {/* *************************************** */}
            {/* Invite modal */}
            <CustomModal
                isOpen={inviteModal}
                onClose={() => handleCloseInviteModal()}
                className={"w-[100%] max-w-[477px]"}
            >
                <div className="px-5 py-4 flex items-center">
                    <p className="text-[16px] font-semibold">Invite to workspace</p>
                    <span className="p-2 ml-auto cursor-pointer" role="button" onClick={handleCloseInviteModal}>
                        <CloseIconBlack width="13" height="13" />
                    </span>
                </div>
                <form onSubmit={handleSendInvite} autoComplete="off" autoCorrect="off">
                    <div className="mt-2 px-5">
                        <label className="text-gray-500 mb-2 block" htmlFor="email">
                            Enter their email
                        </label>
                        <input
                            autoFocus
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            readOnly={sendingInvite}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            onBlur={() => setEmailError(!validateEmail(email))}
                            className={`border w-full rounded-lg px-3 py-2 text-[16px]  placeholder:text-gray-500 outline-0 ${
                                emailError
                                    ? "focus:red-500 border-red-500 text-red-500"
                                    : "text-black focus:border-action"
                            }`}
                        />
                        {emailError ? (
                            <p className="text-red-500 mt-1 ml-1 block text-[14px]">Provided email is not valid</p>
                        ) : null}
                    </div>
                    <div className="w-full mt-4">
                        <div className="px-5 py-4 flex items-center justify-end w-full gap-3">
                            <button
                                disabled={sendingInvite}
                                type="button"
                                className="border-gray-300 border py-2 px-4 rounded-lg font-normal"
                                onClick={handleCloseInviteModal}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={sendingInvite}
                                type="submit"
                                className="py-2 px-4 rounded-lg font-normal bg-action text-white active:bg-[#304aa5de] disabled:opacity-60"
                            >
                                {sendingInvite ? (
                                    <>
                                        {" "}
                                        <span className="mr-1">
                                            <Spinner width={18} height={18} />
                                        </span>{" "}
                                        Sending Invite
                                    </>
                                ) : (
                                    "Send Invite"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </CustomModal>
            {/* *************************************** */}
            {/* Cancel/Close Modal */}
            <CustomModal
                isOpen={askModal?.open}
                onClose={() => (removingUser ? null : setAskModal({ open: false, id: "" }))}
                className={"w-[100%] max-w-[477px]"}
            >
                <div className="px-5 py-6">
                    <div className="flex items-start">
                        <span className="mr-2">
                            <InfoIconWarn />{" "}
                        </span>
                        <p className="text-[16px] font-semibold">Are you sure you want to remove this member?</p>
                        <span
                            className="p-2 ml-auto cursor-pointer"
                            role="button"
                            onClick={() => (removingUser ? null : setAskModal({ open: false, id: "" }))}
                        >
                            <CloseIconBlack width="13" height="13" />
                        </span>
                    </div>
                    <div className="flex items-start mt-5 flex-col">
                        <p className="pl-8 text-[15px]">
                            Confirm that you want to remove{" "}
                            <span className="font-semibold">
                                {" "}
                                {workspaceMembers?.find((v) => v?.id === askModal?.id)?.email || ""}{" "}
                            </span>{" "}
                            from this workspace.
                        </p>
                    </div>
                    <div className="flex items-center justify-end w-full gap-3 mt-8">
                        <button
                            disabled={removingUser}
                            className="border-gray-300 border py-2 px-4 rounded-lg font-normal"
                            onClick={() => {
                                setAskModal({ open: false, id: "" });
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="py-2 px-4 rounded-lg font-normal bg-action text-white active:bg-[#304aa5de] disabled:opacity-60"
                            onClick={() => {
                                removeUser(false);
                            }}
                            disabled={removingUser}
                        >
                            {removingUser ? (
                                <>
                                    {" "}
                                    <span className="mr-1">
                                        <Spinner width={18} height={18} />
                                    </span>{" "}
                                    Removing
                                </>
                            ) : (
                                "Confirm"
                            )}
                        </button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default WorkSpace;
