/** @jsxImportSource @emotion/react */

import { SIDEBAR } from "const-values/Layout";
import { memo, useState } from "react";
import { IoCloseOutline, IoListOutline } from "react-icons/io5";
import { LuMerge, LuSplit, LuTrash2 } from "react-icons/lu";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import RequirementAttributesMenu from "./RequirementAttributesMenu";
import ConfirmModal from "components/ConfirmModal";
import { useBulkUpdateOperation } from "./hooks";
import Tooltip from "components/atoms/tooltip";
import SplitModal from "./SplitModal";
import { triggerConfirm } from "../confirm-modal/utils";
import { useParams } from "react-router-dom";

const RequirementsBulkUpdate = () => {
    const [splitOpen, setSplitOpen] = useState({ open: false, reqId: "" });
    const { sidebarVisible } = useAppSelector((root) => root.copilot);
    const dispatch = useAppDispatch();
    const [confirmModal, setConfirmModal] = useState(false);
    const { sectionId } = useParams();
    const { checkedReqIds, removeRows, mergeRequirements } = useBulkUpdateOperation();

    if (!checkedReqIds?.length) return null;

    const canSplit = checkedReqIds?.length === 1;
    const canMerge = checkedReqIds?.length > 1;

    return (
        <>
            <ConfirmModal
                title="Are you sure you want to delete the selected requirements?"
                subTitle={`You have ${checkedReqIds?.length} total requirement${
                    checkedReqIds?.length !== 1 && "s"
                } selected.`}
                open={confirmModal}
                onClose={() => setConfirmModal(false)}
                onProceed={(proceed) => {
                    if (proceed) {
                        removeRows();
                        setConfirmModal(false);
                    }
                }}
                proceedLabel="Confirm"
            />
            <div
                className="fixed bottom-[84px] z-[6] hidden justify-center duration-200 left-0 right-0 pointer-events-none"
                css={[
                    { animation: "contentShow 100ms ease-in" },
                    sidebarVisible && { left: SIDEBAR.MAX_WIDTH },
                    checkedReqIds.length && tw`flex`,
                ]}
            >
                <div className="flex items-center gap-3 flex-row rounded-md p-2.5 bg-gray-darkest pointer-events-auto cursor-default">
                    <div className="flex h-7 items-center">
                        <div className="text-gray-100 h-[inherit] flex items-center px-2 text-xs border border-r-0 border-dashed border-gray-500 rounded-l">
                            {checkedReqIds.length} requirements
                        </div>
                        <button
                            onClick={() => dispatch(setCheckedState({}))}
                            className="h-[inherit] text-gray-200 text-base px-1.5 border border-dashed border-gray-500 rounded-r duration-100 hover:text-white hover:border-gray-100"
                        >
                            <IoCloseOutline />
                        </button>
                    </div>
                    <div className="bg-gray-500 w-px h-6" />
                    <div className="flex items-center gap-2">
                        <Tooltip content="Delete">
                            <button
                                onClick={() => setConfirmModal(true)}
                                className="text-gray-300 px-1.5 h-7 rounded duration-150 hover:text-white hover:bg-zinc-700"
                            >
                                <LuTrash2 />
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex items-center gap-2">
                        <button
                            disabled={!canSplit}
                            onClick={() => canSplit && setSplitOpen({ open: true, reqId: checkedReqIds?.[0] })}
                            className="flex items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed disabled:!border-gray-500"
                        >
                            <LuSplit className="text-sm" />
                            <div className="text-xs">Split</div>
                        </button>
                        <button
                            disabled={!canMerge}
                            onClick={() => {
                                if (canMerge) {
                                    triggerConfirm({
                                        header: `Are you sure you want to merge ${checkedReqIds.length} requirements?`,
                                        body: "This action will attempt to merge requirements and clear the existing properties.",
                                    }).then((proceed) => {
                                        if (proceed) {
                                            mergeRequirements(!!sectionId);
                                        }
                                    });
                                }
                            }}
                            className="flex items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed disabled:!border-gray-500"
                        >
                            <LuMerge className="text-sm" />
                            <div className="text-xs">Merge</div>
                        </button>
                        <RequirementAttributesMenu contentProps={{ sideOffset: 14 }}>
                            <div className="flex cursor-pointer items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100">
                                <IoListOutline />
                                <div className="text-xs">Properties</div>
                            </div>
                        </RequirementAttributesMenu>
                    </div>
                </div>
            </div>
            <SplitModal
                retainSection={!!sectionId}
                open={splitOpen.open}
                splitRequirementId={splitOpen.reqId}
                onOpenChange={() => setSplitOpen((prev) => ({ ...prev, open: false }))}
            />
        </>
    );
};

export default memo(RequirementsBulkUpdate);
