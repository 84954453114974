import { TabSlug } from "./types";
import { Tab } from "components/organisms/tabs/Tabs";
import { useEffect, useMemo, useRef, useState } from "react";
import { DRIVE_TAB_TO_META } from "./constants";
import { IoFolderOpenOutline, IoImagesOutline } from "react-icons/io5";
import tw from "twin.macro";
import usePageVisibility from "hook/usePageVisibility";
import { useAppDispatch } from "store/storeTypes";
import { fetchFileStorage, fetchMediaStorage } from "store/reducers/driveReducerSlice";
import { useLocation } from "react-router-dom";

export const useTabs = () => {
    const { pathname } = useLocation();

    const driveTabs: Tab<TabSlug>[] = useMemo(
        () => [
            {
                name: DRIVE_TAB_TO_META[TabSlug.Documents].name,
                slug: TabSlug.Documents,
                icon: <IoFolderOpenOutline />,
                styles: tw`!text-base pb-4 pt-5`,
            },

            {
                name: DRIVE_TAB_TO_META[TabSlug.Media].name,
                slug: TabSlug.Media,
                icon: <IoImagesOutline />,
                styles: tw`!text-base pb-4 pt-5`,
            },
        ],
        []
    );

    const activeTabState = useState<Tab<TabSlug>>(
        () => driveTabs.find(({ slug }) => pathname.includes(slug)) || driveTabs[0]
    );

    return { activeTabState, driveTabs };
};

export const usePollDrive = () => {
    const isPageVisible = usePageVisibility();
    const timerIdRef = useRef<NodeJS.Timeout>();
    const dispatch = useAppDispatch();
    const [isPollingEnabled, setIsPollingEnabled] = useState(true);

    useEffect(() => {
        const pollingCallback = async () => {
            dispatch(fetchMediaStorage());
            dispatch(fetchFileStorage());
        };

        const startPolling = () => {
            pollingCallback();
            // Polling every 30 seconds
            timerIdRef.current = setInterval(pollingCallback, 30 * 1000);
        };

        const stopPolling = () => {
            clearInterval(timerIdRef.current);
        };

        if (isPageVisible && isPollingEnabled) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [dispatch, isPageVisible, isPollingEnabled]);
};
