import { Modal } from "components/organisms/modal";
import { Button } from "components/editor/components";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { toggleWritingPromptsIdeationModal } from "store/reducers/modalsSlice";
import { useCallback } from "react";
import {
    closeWritingPromptIdeation,
    setWritingPromptIdeationState,
} from "store/reducers/copilot/WritingPromptIdeationReducer";
import WritingPromptIdeation from "pages/draft-section/draft-section-requirements/writing-prompts/writing-prompt-ideation";
import useRequirementOperations from "hook/useRequirementOperations";
import { useMutation, useStorage } from "liveblocks.config";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";
import { LiveList } from "@liveblocks/client";

export type WritingPromptIdeationModalProps = {
    open: boolean;
};

const WritingPromptIdeationModal = ({ open }: WritingPromptIdeationModalProps) => {
    const { isSubmitting, complianceMatrixRow } = useAppSelector((root) => root.writingPromptIdeation);
    const liveComplianceMatrixRow = useStorage((root) =>
        (root.compliance_matrix as ImmutableStorage["compliance_matrix"])?.find(
            (row) => row.requirement?.id === complianceMatrixRow?.requirement.id
        )
    );
    const { requirement, selected_content_v2: selectedContent } = liveComplianceMatrixRow || {};
    const dispatch = useAppDispatch();
    const canSubmit = !!selectedContent?.length;
    const { addNewWritingPrompt } = useRequirementOperations();

    const clearAllContent = useMutation(
        ({ storage }) => {
            const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            if (!matrix?.length) return;
            const liveRequirement = matrix.find((row) => row.get("requirement")?.get("id") === requirement?.id);
            liveRequirement?.update({
                generated_content_ideas_v2: new LiveList([]),
                selected_content_v2: new LiveList([]),
            });
        },
        [requirement]
    );

    const importIdeas = useCallback(() => {
        if (!requirement?.id) return;
        dispatch(setWritingPromptIdeationState({ isSubmitting: true }));
        selectedContent?.forEach(({ content }) => {
            addNewWritingPrompt(requirement.id, { content });
        });

        setTimeout(() => {
            dispatch(setWritingPromptIdeationState({ isSubmitting: false }));
            dispatch(toggleWritingPromptsIdeationModal({ open: false }));
            dispatch(closeWritingPromptIdeation());
            clearAllContent();
        }, 1000);
    }, [requirement?.id, dispatch, selectedContent, addNewWritingPrompt, clearAllContent]);

    return (
        <Modal
            contentProps={{ css: tw`h-[90vh] max-h-[1600px] w-[80vw] max-w-[1200px]` }}
            footerProps={{ css: tw`mt-4` }}
            header="Generate Writing Prompts"
            body={
                <div className="flex-1 pt-6 px-4 h-[calc(100%-56px)]">
                    <WritingPromptIdeation liveComplianceMatrixRow={liveComplianceMatrixRow} />
                </div>
            }
            footer={
                <div className="flex gap-2 justify-end">
                    <Button
                        onClick={() => {
                            dispatch(toggleWritingPromptsIdeationModal({ open: false }));
                            dispatch(closeWritingPromptIdeation());
                        }}
                        className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                        variant="outline"
                        size="md"
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={isSubmitting}
                        onClick={() => {
                            importIdeas();
                        }}
                        disabled={!canSubmit}
                        variant="primary"
                        size="md"
                    >
                        Import
                    </Button>
                </div>
            }
            open={open}
            onOpenChange={(o) => {
                if (!o) dispatch(closeWritingPromptIdeation());
                dispatch(toggleWritingPromptsIdeationModal({ open: o }));
            }}
        />
    );
};

export default WritingPromptIdeationModal;
