import axios from "axios";

// ************ Auth *************

// Sign-in
export const signIn = async (data, headers = {}) => {
    return await axios.post("/users/login/", data, { headers });
};

export const signInDomainSSO = async (data) => {
    return await axios.post("/users/login-domain-sso/", data);
};

// ************ Workspace *************

// Discover workspace
export const discoverWorkspace = async () => {
    return await axios.get("/users/workspace/discover");
};

// Create new workspace
export const createWorkspace = async (data, headers = {}) => {
    return await axios.post("/users/workspace/discover", data, { headers });
};
// Get WorkspaceName
export const getWorkspaceNames = async (name) => {
    const res = await axios.post(`/users/workspace/validation`, { workspace_name: name });
    return res.data;
};

// Update workspace
export const updateWorkspace = async (data) => {
    return await axios.post("/users/workspace/discover", data);
};

// Get workspace members
export const getWorkspaceMembers = async () => {
    return await axios.get("/users/workspace/members");
};

// Update user details, username
export const updateUsername = async (data) => {
    return await axios.put("/users/details/", data);
};

// Update particular workspace by admin of that workspace
export const updateUserWorkspace = async (data) => {
    return await axios.put("/users/workspace/", data);
};

// Send invite to a user to join our workspace
export const inviteInWorkspace = async (data) => {
    return await axios.post("/users/workspace/invite", data);
};

// Remove a user from the workspace
export const removeUserWorkspace = async (data) => {
    return await axios.post("/users/workspace/members/remove", data);
};

// ************ Ask AI ***************

export const createNewSessionAPI = (data) => axios.post("/chat/session", data);

export const getAllChatSessionsAPI = (internal_contract_id) =>
    axios.get(`/chat/session?internal_contract_id=${internal_contract_id}`);

export const editSessionNameAPI = (data) => axios.put(`/chat/session`, data);

export const deleteChatSessionAPI = (data) => axios.delete(`/chat/session`, { data });

export const getChatMessagesForSessionAPI = (session_id) => axios.get(`/chat/message?session_id=${session_id}`);

export const askAIAPI = (data) => axios.post(`/chat/message`, data);

// ************ Proposal Assistant ***************

export const createNewProposalAssistantSessionAPI = (data) => axios.post("/chat/session/corpus", data);

export const getAllProposalAssistantChatSessionsAPI = () => axios.get(`/chat/session/corpus`);

export const editProposalAssistantSessionNameAPI = (data) => axios.put(`/chat/session/corpus`, data);

export const deleteProposalAssistantChatSessionAPI = (sessionId) =>
    axios.delete(`/chat/session/corpus?session_id=${sessionId}`);

export const getProposalAssistantChatMessagesForSessionAPI = (session_id) =>
    axios.get(`/chat/message/corpus?session_id=${session_id}`);

export const sendProposalAssistantChatMessageAPI = (data) => axios.post(`/chat/message/corpus`, data);

export const getProposalAssistantDocs = async () => {
    return await axios.get("/corpus/document");
};

export const addProposalAssistantDoc = async (data, headers) => {
    return await axios.post("/corpus/document", data, { headers: headers });
};

export const deleteProposalAssistantDocs = async (data) => {
    return await axios.delete("/corpus/document", { data });
};

export const getRecommendedContentAPI = async (data) => {
    return await axios.post("/corpus/recommend", data);
};

export const searchContentAPI = async (data) => {
    return await axios.post("/corpus/search", data);
};

// ************ Bolt ***************

// Create new bolt
export const createBolt = async (data) => {
    return await axios.post("/bolt/", data);
};

// Update bolt
export const updateBolt = async (id, data) => {
    return await axios.put(`/bolt/${id}/`, data);
};

// Get bolt list & bolt search
export const getBoltSearch = async (queryParams) => {
    let query = queryParams ? `?search_query=${queryParams}` : "";
    return await axios.get(`/bolt/search${query}`);
};

// Delete a bolt
export const removeBolt = async (id) => {
    return await axios.delete(`/bolt/${id}/`);
};

// Get bolt documents
export const getBoltDocs = async () => {
    return await axios.get("/bolt/document");
};

// Add bolt document
export const addBoltDoc = async (data, headers) => {
    return await axios.post("/bolt/document/extract", data, { headers: headers });
};

// Get recommended bolts
export const getRecommendedBoltsAPI = (data) => axios.post("/bolt/recommend", data);

// ************ Contract **************

// Search contract & get contract list
export const contractSearch = async (query = "") => {
    return await axios.get(`/contracts/search${query}`);
};

// Add contract to contract manage
export const addToContract = async (data) => {
    return await axios.post("/contracts/internal", data);
};
// Delete Project
export const deleteInternalContract = async (_contract) => {
    return await axios.delete(`/contracts/internal/${_contract}/`);
};

// Mark as viewed contract
export const markAsViewedContract = async (id) => {
    return await axios.post(`/contracts/${id}/action/view`);
};

// Get dropdown options
export const getDropDownOptions = async (query = "") => {
    return await axios.get(`/contracts/search/filters${query}`);
};

// Get contract details
export const getContractDetails = async (id) => {
    return await axios.get(`/contracts/detail?id=${id}`);
};

// ************ Contract Manager **************

// Get contract manager list
export const getContractManagerItems = async () => {
    return await axios.get(`/contracts/internal`);
};

export const updateContractManagerItems = async (data) => {
    return await axios.put(`/contracts/internal`, data);
};

// Upload new manually created contract
export const createPrivateInternalContract = async (data) => {
    return await axios.post(`/contracts/internal/private`, data);
};

// Get internal contract details
export const getInternalContractDetails = async (id) => {
    return await axios.get(`/contracts/internal/${id}/`);
};

// Edit internal contract details
export const updateInternalContractDetails = async (id, data) => {
    return await axios.put(`/contracts/internal/${id}/`, data);
};

// Get internal contract details
export const uploadInternalContractLink = async (id, data) => {
    return await axios.post(`/contracts/internal/${id}/link/`, data);
};

// Get internal contract details
export const deleteInternalContractLink = async (id) => {
    return await axios.delete(`/contracts/internal/link/${id}/`);
};

// Create Milestone
export const createInternalContractMilestone = async (id, data) => {
    return await axios.post(`/contracts/internal/${id}/milestone/create`, data);
};

// Delete Milestone
export const deleteInternalContractMilestone = async (_milestone) => {
    return await axios.delete(`/contracts/internal/milestone/${_milestone}/`);
};

// Delete Milestone
export const updateInternalContractMilestone = async (_milestone, data) => {
    return await axios.put(`/contracts/internal/milestone/${_milestone}/`, data);
};

// Get Progress
export const getProgress = async (id) => {
    return await axios.get(`/contracts/internal/${id}/progress`);
};

// ************ Contract Document **************
export const uploadDocument = async (id, data) => {
    return await axios.post(`/contracts/internal/${id}/document/`, data);
};

// Delete a document from contract
export const deleteADoc = async (id) => {
    return await axios.delete(`/contracts/internal/document/${id}/`);
};

// Extract Document Requirement
export const extractDocumentRequirement = async (data) => {
    return await axios.post(`/contracts/internal/document/requirement/extract`, data);
};

// Generate Template
export const generateTemplate = async (internal_contract_id, document_id) => {
    return await axios.post(`/contracts/internal/${internal_contract_id}/generate_template/${document_id}`);
};

// Summarize Document
export const summarizeDocument = async (data) => {
    return await axios.post(`/contracts/internal/document/summarize`, data);
};

// Scan RFP Document
export const scanRFPDocument = async (data) => {
    return await axios.post(`/contracts/internal/document/scan_rfp`, data);
};

// ************ Notification **************

// Created saved search
export const saveSearchApi = async (data) => {
    return await axios.post("/notifications/filters", data);
};

// Update saved search
export const updateSearchFiltersApi = async (data) => {
    return await axios.put("/notifications/filters", data);
};

// Get saved search
export const getSaveSearchApi = async () => {
    return await axios.get("/notifications/filters");
};

// Delete Saved Search
export const deleteSavedSearchApi = (data) => axios.delete("/notifications/filters", { data });

export const getNotificationsApi = () => axios.get("/notifications/alerts");

export const getNotificationFeedsApi = () => axios.get("/notifications/feed");

// Mark Feed as Read
export const markFeedReadApi = (data) => axios.put("/notifications/feed/read", data);

// Queue an Assigned Requirement Notification
export const sendAssignedRequirementNotificationApi = (data) => axios.post("/notifications/notify/requirement", data);

// ************ Proposal Plan **************
export const getDocumentSelectionDirectories = async (project_id, directory_id = null) =>
    axios.get(
        `proposal/plan/documents/directory/view?project_id=${project_id}${
            directory_id ? `&directory_id=${directory_id}` : ""
        }`
    );
export const getProposalPlanDocument = async (id) => axios.get(`/contracts/internal/${id}/proposal/plan/documents`);
export const createProposalPlanDocument = async (id, data) =>
    axios.post(`/contracts/internal/${id}/proposal/plan/documents`, data);
export const updateProposalPlanDocumentFilterState = async (id, useDocFilter) =>
    axios.put(`/contracts/internal/${id}/proposal/plan/documents`, { use_doc_filter: useDocFilter });
export const deleteProposalPlanDocument = async (id, data) =>
    axios.delete(`/contracts/internal/${id}/proposal/plan/documents`, { data: data });
export const fetchInputDetails = async (id) => axios.get(`/contracts/internal/${id}/proposal/plan/inputs/defaults`);
export const updateInputDetails = async (id, data) => axios.put(`/contracts/internal/${id}/proposal/plan/inputs`, data);

// ************ Templates **************
export const getTemplates = () => axios.get("/templates/");
export const createTemplate = (data) => axios.post("/templates/", data);
export const getTemplateDetails = (id) => axios.get(`/templates/${id}/`);
export const updateTemplateDetails = (id, data) => axios.put(`/templates/${id}/`, data);
export const deleteTemplate = (id) => axios.delete(`/templates/${id}/`);

// ************ Copilot **************

// Generate proposal section text
export const generateRequirementResponseAPI = (data) =>
    axios.post(`/contracts/internal/proposal/row/proposal_text/generate`, data);

export const updateProposalName = (data, id) => axios.put(`/contracts/internal/proposal/document/${id}/`, data);

export const deleteProposal = (id) => axios.delete(`/contracts/internal/proposal/document/${id}/`);

// Search file content.
export const expandFileContent = async (file_content_id, previous) => {
    return await axios.post("/writing/search/full_text/expand", { file_content_id, previous });
};

// Get proposal
export const getProposal = async (internalContractId) => {
    return await axios.get(`/contracts/internal/${internalContractId}/proposal`);
};

// Get proposal status
export const getProposalStatus = async (internalContractId) => {
    return await axios.post(`/proposal_generation/${internalContractId}/status`, {});
};

// Generate a new proposal
export const generateNewProposal = async (internalContractId, data) => {
    return await axios.post(`/proposal_generation/${internalContractId}/start`, data);
};

// Get proposal documents
export const getProposalDocuments = async (internalContractId) => {
    return await axios.get(`/contracts/internal/${internalContractId}/proposal/documents`);
};

// Create new proposal document
export const createProposalDocument = async (internalContractId, data) => {
    return await axios.post(`/contracts/internal/${internalContractId}/proposal/document`, data);
};

// Get compliance matrix excel generation
export const downloadExcel = async (internalContractId) => {
    return await axios.get(`/contracts/internal/${internalContractId}/compliance_matrix/export`, {
        responseType: "blob",
    });
};

// Get proposal document download
export const downloadProposal = async (proposalDocumentId, dataFormat) => {
    return await axios.get(`/contracts/internal/proposal/${proposalDocumentId}/export?data_format=${dataFormat}`, {
        responseType: "blob",
    });
};

// Get proposal tasks status endpoint
export const getProposalTaskStatus = async (internalContractId) => {
    return await axios.get(`/contracts/internal/${internalContractId}/proposal/task_status`);
};

/* Copilot - Generate Response  */

export const writingAssistant = async (actionType, data) => {
    const session_token = (localStorage.getItem("vultron_user_token") ?? "").replaceAll('"', "");
    const existingAttemptsJsonString = localStorage.getItem("past_attempts");
    const existingAttemptsArray = existingAttemptsJsonString && JSON.parse(existingAttemptsJsonString);

    return await axios.post(`/writing/type/${actionType}`, {
        headers: {
            Authorization: `Bearer ${session_token}`,
            Workspace: `Workspace ${session_token}`,
        },
        text: data.text,
        past_attempts: data.payloadAction ? existingAttemptsArray : [data.text],
        tone: data.tone && data.tone,
        word_limit: data.wordLimit && data.wordLimit,
        user_request: data.user_request && data.user_request,
        action: data.action && data.action,
    });
};

///////////////////////////////////// Compliance Matrix API //////////////////////////////////
export const generateContentIdeas = async (internalContractId, data) => {
    return await axios.post(`/contracts/internal/${internalContractId}/generate_content_ideas`, data);
};

export const generateRequestResponse = async (data) => {
    return await axios.post(`/contracts/internal/proposal/row/proposal_text/generate`, data);
};

////////////////////////// DRIVE API ////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const getDirectories = async () => {
    return await axios.get(`file_storage/directory/view`);
};

export const getSingleDirectory = async (directoryId) => {
    return await axios.get(`file_storage/directory/view?directory_id=${directoryId}`);
};

export const createDirectory = async (data) => {
    return await axios.post("file_storage/directory/create", data);
};

export const updateDirectory = async (directoryId, data) => {
    return await axios.put(`file_storage/directory/${directoryId}/`, data);
};

export const deleteDirectory = async (directoryId, data) => {
    return await axios.delete(`file_storage/directory/${directoryId}/`, data);
};

export const moveDirectory = async (directoryId, data) => {
    return await axios.post(`file_storage/directory/move/${directoryId}/`, data);
};

export const createFile = async (data, method, headers) => {
    return await axios.post(`file_storage/file/create/${method}/`, data, { headers });
};

export const getFile = async (fileId) => {
    return await axios.get(`file_storage/file/${fileId}/`);
};

export const updateFile = async (fileId, data) => {
    return await axios.put(`file_storage/file/${fileId}/`, data);
};

export const deleteFile = async (fileId, data) => {
    return await axios.delete(`file_storage/file/${fileId}/`, data);
};

export const moveFile = async (fileId, data) => {
    return await axios.post(`file_storage/file/move/${fileId}/`, data);
};

//Recommended tab

// get all cards data
export const recommendedCardsData = async (type = "") => {
    return await axios.get(`lead_recommendation/list/${type}`);
};

// update recommended card
export const updateRecommendedCardData = async (id = "", data) => {
    return await axios.put(`lead_recommendation/${id}/update`, data);
};

// user feedback post
export const userFeedBackPost = async (data) => {
    return await axios.post(`lead_recommendation/feedback`, data);
};

//get user details for feedback
export const getUserFeedbackDetails = async (id = "") => {
    return await axios.get(`lead_recommendation/feedback?lead_id=${id}`);
};

// file storage all prompt file
export const getAllPromptFiles = async (projectId, params) => {
    return await axios.get(`/file_storage/file/all?bucket_id=${projectId}${params}`);
};
