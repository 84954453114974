import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Avatar from "../Avatar";
import { addToContract, getContractDetails, markAsViewedContract } from "api/api";
import ContractContent from "../Contract-details/ContractContent";
import plusCircle from "../../Assets/plus-circle.svg";
import { Spinner, ScreenSpinner } from "utils/icons";
import { useNotification } from "context/notificationContext";
import { ProjectHeader } from "components/molecules/project-header";
import { Button } from "components/editor/components";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

// --------------------------- Search page -> Contact Detail page main  --------------------------
const ContractDetailsSearch = () => {
    const [searchParams] = useSearchParams();
    let id = searchParams.get("id") || "";
    const { setToast } = useNotification();
    const [contractDetails, setContractDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [addingToContract, setAddingToContract] = useState(false);

    // Add  to contract
    const addToContractManager = () => {
        if (addingToContract || contractDetails?.added_to_contracts) return;
        setAddingToContract(true);

        addToContract({
            contract_id: id,
        })
            .then(() => {
                setContractDetails({
                    ...contractDetails,
                    added_to_contracts: true,
                });
            })
            .catch((err) => {
                setToast.error({
                    title: "Oops!",
                    msg: "Something went wrong. Please try again.",
                });
            })
            .finally(() => {
                setAddingToContract(null);
            });
    };

    // fetch contract information
    // Mark as viewed
    useEffect(() => {
        getContractDetails(id)
            .then((res) => {
                setContractDetails(res.data || {});
            })
            .catch((err) => {
                setToast.error({
                    title: "Oops!",
                    msg: "There was an error fetching the contract. Please refresh and try again.",
                });
            })
            .finally(() => {
                setLoading(false);
            });

        // mark as viewed
        markAsViewedContract(id)
            .then(() => {})
            .catch((err) => {});
    }, [id]);
    //

    return (
        <div className="flex flex-col h-full overflow-auto">
            <ProjectHeader
                isLoading={loading}
                back={-1}
                header={contractDetails?.title}
                actions={
                    <div className="flex items-center gap-3">
                        {contractDetails?.viewed?.length > 0 && (
                            <div className="flex items-center py-1 relative group">
                                <p className="text-gray-500 text-sm">Viewed By:</p>
                                <div className="flex items-center -space-x-[11px] ml-[9px] ">
                                    {contractDetails?.viewed?.slice(0, 3)?.map((username, i) => (
                                        <Avatar
                                            key={i}
                                            id={username?.toString()}
                                            src=""
                                            alt={username?.toString()}
                                            width={33}
                                            height={33}
                                            className="font-[500]"
                                        />
                                    ))}
                                </div>
                                <>
                                    {contractDetails?.viewed?.length > 2 && (
                                        <span className="text-sm text-[#CF5800] ml-1">+</span>
                                    )}
                                    <div className="absolute w-max top-[100%] hidden cursor-pointer rounded-[6px] right-0 bg-white shadow-[0_0_15px_rgba(0,0,0,0.1)] group-hover:block z-20">
                                        <div className="px-3 py-[0.67rem] max-h-[480px] overflow-y-auto">
                                            {contractDetails?.viewed?.map((user, i) => (
                                                <div
                                                    key={i}
                                                    className={`flex items-center min-w-[120px] max-w-[360px] ${
                                                        i + 1 === contractDetails?.viewed?.length ? "" : "mb-2"
                                                    }`}
                                                >
                                                    <Avatar
                                                        id={user?.toString()}
                                                        src=""
                                                        alt={user?.toString()}
                                                        key={i}
                                                        width={24}
                                                        height={24}
                                                        className="mr-2 !text-[13px]"
                                                    />
                                                    <p className="font-inter text-xs text-gray-primary line-clamp-1 text-ellipsis w-full">
                                                        {user?.toString()}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            </div>
                        )}
                        <Button
                            variant="primary"
                            size="md"
                            disabled={addingToContract || contractDetails?.added_to_contracts}
                            onClick={addToContractManager}
                            className="bg-action ml-[13px] flex text-white gap-1 py-2 px-3 text-sm items-center rounded-lg disabled:opacity-60"
                        >
                            {contractDetails?.added_to_contracts ? (
                                <>
                                    <IoCheckmarkCircleSharp className="bg-inherit text-lg" />
                                    <span> Added</span>
                                </>
                            ) : (
                                <>
                                    {addingToContract ? (
                                        <Spinner width={12} height={12} />
                                    ) : (
                                        <img src={plusCircle} alt="+" />
                                    )}

                                    <span> Add</span>
                                </>
                            )}
                        </Button>
                    </div>
                }
            />
            {loading ? (
                <div className="flex items-center justify-center p-6 h-full min-h-full flex-1">
                    <ScreenSpinner />
                </div>
            ) : (
                <ContractContent
                    searchPage={true}
                    id={id}
                    contractDetails={contractDetails}
                    setContractDetails={setContractDetails}
                />
            )}
        </div>
    );
};

export default ContractDetailsSearch;
