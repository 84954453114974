import { copyToClipBoard } from "utils/helpers";
import copy from "../../../Assets/copy.svg";
import { useNotification } from "context/notificationContext";

const ContactInformation = ({ contractDetails }) => {
    const { setToast } = useNotification();

    // copy text
    const copyText = (txt) => {
        copyToClipBoard(txt).then(() => {
            setToast.success({
                msg: "Copied to clipboard",
            });
        });
    };
    return (
        <div className="border border-[#D8DDE2] shadow-sm p-4 rounded-lg mb-4 bg-white">
            <h3 className="text-base font-medium pb-3">Contact Information</h3>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3 pt-2">
                    <span className="text-[#5B6B79]">Office Address</span>
                    <span className="text-sm">{contractDetails?.contact_information?.office_address || "-"}</span>
                </div>
                {(contractDetails?.contact_information?.primary_point_of_contact?.name ||
                    contractDetails?.contact_information?.primary_point_of_contact?.email) && (
                    <div className="flex flex-col gap-3 pt-2">
                        <span className="text-[#5B6B79]">Primary Point of Contact</span>
                        <div>
                            <span className="text-sm">
                                {contractDetails?.contact_information?.primary_point_of_contact?.name}
                            </span>
                            <span className="flex gap-2 text-action line-clamp-1 mt-1">
                                <span className="max-w-full line-clamp-1 text-sm">
                                    {contractDetails?.contact_information?.primary_point_of_contact?.email}
                                </span>
                                {contractDetails?.contact_information?.primary_point_of_contact?.email && (
                                    <img
                                        className="cursor-pointer select-none"
                                        onClick={() =>
                                            copyText(
                                                contractDetails?.contact_information?.primary_point_of_contact?.email
                                            )
                                        }
                                        src={copy}
                                        alt="copy"
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                )}
                {(contractDetails?.contact_information?.secondary_point_of_contact?.name ||
                    contractDetails?.contact_information?.secondary_point_of_contact?.email) && (
                    <div className="flex flex-col gap-3 pt-2">
                        <span className="text-[#5B6B79]">Secondary Point of Contact</span>
                        <div>
                            <span className="text-sm">
                                {contractDetails?.contact_information?.secondary_point_of_contact?.name}
                            </span>
                            <span className="flex gap-2 text-action line-clamp-1 text-sm mt-1">
                                <span className="max-w-full line-clamp-1">
                                    {contractDetails?.contact_information?.secondary_point_of_contact?.email}{" "}
                                </span>
                                {contractDetails?.contact_information?.secondary_point_of_contact?.email && (
                                    <div className="min-w-[30px]">
                                        <img
                                            className="cursor-pointer select-none"
                                            onClick={() =>
                                                copyText(
                                                    contractDetails?.contact_information?.secondary_point_of_contact
                                                        ?.email
                                                )
                                            }
                                            src={copy}
                                            alt="copy"
                                        />
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactInformation;
