import { createConfirmation } from "react-confirm";
import ConfirmModal from "./ConfirmModal";
import { Modal } from "../modal";
import { ComponentProps } from "react";

export const confirmModal = createConfirmation(ConfirmModal);

export function triggerConfirm({ header, description, ...options }: ComponentProps<typeof Modal>) {
    return confirmModal({ header, description, ...options });
}
