/** @jsxImportSource @emotion/react */

import { Modal } from "../modal";
import tw from "twin.macro";
import { clearExtractState } from "store/reducers/extract/ExtractReducer";
import { useAppDispatch } from "store/storeTypes";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import SimpleRequirementExtraction from "./SimpleRequirementExtraction";

export type RequirementExtractionModalProps = {
    open: boolean;
};

export const RequirementExtractionModal = ({ open }: RequirementExtractionModalProps) => {
    const dispatch = useAppDispatch();

    return (
        <Modal
            modal
            open={open}
            onOpenChange={(o) => {
                if (!o) dispatch(clearExtractState());
                dispatch(toggleRequirementExtractionModal({ open: o }));
            }}
            contentProps={{ css: tw`max-w-[1400px] w-[82vw] max-h-[90vh] h-[90vh]` }}
            header="Add Additional Requirements"
            body={
                <>
                    <div className="text-gray-lightest text-xs pl-6 -mt-2">
                        Extract, select, and add additional requirements
                    </div>
                    <SimpleRequirementExtraction />
                </>
            }
            footerProps={{ css: tw`border-t border-gray-light mt-0 pt-4` }}
        />
    );
};
