import { useAppSelector } from "store/storeTypes";
import { SourceModal } from "./SourceModal";
import { CreateProjectModal } from "components/modals/create-project-modal";

export const GlobalModals = () => {
    const { sourceModal, createProjectModal } = useAppSelector((state) => state.modals);

    return (
        <>
            <SourceModal {...sourceModal} />
            <CreateProjectModal {...createProjectModal} />
        </>
    );
};
