/** @jsxImportSource @emotion/react */

import { RequirementExtractionSettings } from "./RequirementExtractionSettings";
import { TemplateExtractionSettings } from "./TemplateExtractionSettings";
import { ExtractOptions } from "./ExtractOptions";
import { UploadDocsSection } from "./UploadDocsSection";
import { useEffect, useMemo } from "react";
import { Button } from "components/editor/components";
import useExtract from "./hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ExtractMode, clearExtractState, ExtractionType } from "store/reducers/extract/ExtractReducer";
import { useStorage } from "liveblocks.config";
import { Storage } from "../CopilotSchemaImmutableTypes";

export enum TemplateDocumentType {
    RFI = "RFI",
    RFP = "RFP",
    SBIR = "SBIR",
    Outline = "Outline",
    // RFQ = "RFQ",
    // Other = "Other",
}

export const Extract = () => {
    const dispatch = useAppDispatch();
    const {
        mode,
        selectedDocument,
        content,
        keywords,
        aiDetection,
        selectedSheet,
        extractionType,
        templateDocumentType,
        shouldGenerateTemplateWithRequirements,
    } = useAppSelector((state) => state.extractReducer);
    const sheets = useStorage((storage) => (storage.sheets as Storage["sheets"]) || []);
    const { handleSubmit, loading: isExtractLoading } = useExtract();
    const { requirement_document_tasks, requirement_text_tasks, template_document_tasks, template_text_tasks } =
        useAppSelector((state) => state.autopilotHealthCheck);

    const requirementExtractionInProgress = !!requirement_document_tasks?.length || !!requirement_text_tasks?.length;
    const templateExtractionInProgress = !!template_document_tasks?.length || !!template_text_tasks?.length;

    const isSubmitting = isExtractLoading && !requirementExtractionInProgress && !templateExtractionInProgress;

    useEffect(
        () => () => {
            dispatch(clearExtractState());
        },
        [dispatch]
    );

    const canSubmit = useMemo(() => {
        const isNewValidSheet = !sheets.some(
            (sheet) =>
                sheet.id === selectedSheet?.id ||
                sheet.name.trim().toLowerCase() === selectedSheet?.name.trim().toLowerCase()
        );
        const isExistingValidSheet = sheets.some((sheet) => sheet.id === selectedSheet?.id);
        const isValidSheetSelection = !!selectedSheet?.name?.trim() && (isNewValidSheet || isExistingValidSheet);

        const defaultValidations =
            ((!!selectedDocument && mode === ExtractMode.DocumentUpload) ||
                (!!content.trim() && mode === ExtractMode.CopyPaste)) &&
            !!extractionType;

        const isValidRequirementOptions =
            extractionType === ExtractionType.Requirements
                ? (aiDetection || (!aiDetection && !!keywords.length)) && isValidSheetSelection
                : true;

        const isValidTemplateOptions =
            extractionType === ExtractionType.Template
                ? !!templateDocumentType.trim() &&
                  (!shouldGenerateTemplateWithRequirements ||
                      (shouldGenerateTemplateWithRequirements && isValidSheetSelection))
                : true;

        return defaultValidations && isValidRequirementOptions && isValidTemplateOptions;
    }, [
        sheets,
        selectedSheet?.name,
        selectedSheet?.id,
        selectedDocument,
        mode,
        content,
        extractionType,
        aiDetection,
        keywords.length,
        templateDocumentType,
        shouldGenerateTemplateWithRequirements,
    ]);

    return (
        <div className="bg-white rounded-lg flex flex-col gap-2">
            <div className="flex flex-col gap-8 px-5 mb-16">
                <UploadDocsSection />
                <ExtractOptions />
                <div className="flex gap-x-12">
                    {extractionType === ExtractionType.Requirements && (
                        <div className="flex flex-col gap-4">
                            <h1 className="text-base font-medium">Requirement Extraction Settings</h1>
                            <RequirementExtractionSettings />
                        </div>
                    )}
                    {extractionType === ExtractionType.Template && (
                        <div className="flex flex-col gap-4">
                            <h1 className="text-base font-medium">Template Extraction Settings</h1>
                            <TemplateExtractionSettings />
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-end gap-2 sticky bottom-0 py-5 px-5 pointer-events-none">
                <Button
                    loading={isSubmitting}
                    disabled={isSubmitting || !canSubmit}
                    size="md"
                    variant="primary"
                    type="submit"
                    className="shadow-sharp pointer-events-auto"
                    onClick={() => handleSubmit()}
                >
                    {isSubmitting ? "Extracting" : "Start Extraction"}
                </Button>
            </div>
        </div>
    );
};
