import { useLayoutEffect } from "react";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

export const useRequirementHighlight = () => {
    const dispatch = useAppDispatch();
    const requirementHighlighted = useAppSelector(
        (store) => store.sectionState.requirementsState.requirementHighlighted
    );

    useLayoutEffect(() => {
        const observeClick = () => dispatch(setRequirementsState({ requirementHighlighted: "" }));
        if (requirementHighlighted) {
            const requirementCard = document.getElementById(`section-requirement-${requirementHighlighted}`);
            requirementCard?.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });

            document.addEventListener("mousedown", observeClick);
        }
        return () => {
            document.removeEventListener("mousedown", observeClick);
        };
    }, [dispatch, requirementHighlighted]);
};
