import {
    createComplianceMatrixRow,
    createComplianceMatrixRowRequirement,
    createUserInstruction,
    createWritingPrompt,
} from "./../utils/complianceMatrix";
import { LiveList, LiveObject } from "@liveblocks/client";
import {
    ComplianceMatrixRow,
    Section as ImmutableSection,
    UserInstruction,
    WritingPrompt,
    RequirementStatus,
    RequirementCompliance,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { ProposalReference, Storage } from "components/copilot/CopilotSchemaTypes";
import { useUpdateTemplate } from "components/copilot/Framework/hooks";
import { addRequirementToSection, resetSourceRequirementsOrder } from "components/copilot/Framework/utils";
import { useMutation } from "liveblocks.config";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

const useRequirementOperations = () => {
    const updateTemplate = useUpdateTemplate();
    const trackUserEvent = useTrackUserMetric();

    const assignToSection = useMutation(({ storage }, reqId: string, section: ImmutableSection | null) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const volumes = (storage.get("framework") as Storage["framework"])?.get("volumes");
        const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

        if (!liveRequirement) return;

        if (!section) {
            liveRequirement.get("proposal_reference")?.update({
                volume_id: "",
                section_id: "",
                subsection_id: "",
            });
            resetSourceRequirementsOrder(complianceMatrix, liveRequirement);
        } else {
            addRequirementToSection({
                complianceMatrix,
                activeRow: liveRequirement,
                volumeList: volumes,
                destinationSectionId: section.id,
            });
            trackUserEvent("Requirements: Requirement Added to Section", {
                requirement_id: String(reqId),
                section_id: String(section.id),
            });
        }

        updateTemplate({ isDirty: true });
    }, []);

    const setRequirementContent = useMutation(
        ({ storage }, reqId: string, content: ComplianceMatrixRow["requirement"]["content"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

            if (liveRequirement?.get("locked")) return;

            liveRequirement?.get("requirement").set("content", content);

            if (content !== liveRequirement?.get("requirement").get("content")) {
                trackUserEvent("Requirements: Requirement Updated", {
                    requirement_id: String(reqId),
                    word_count: getWordCount(content),
                });
            }
        },
        []
    );

    const setRequirementLock = useMutation(({ storage }, reqId: string, lock: boolean, lockActor: string) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

        liveRequirement?.set("locked", lock);
        liveRequirement?.set("locked_actor", lockActor);
    }, []);

    const setRequirementStatus = useMutation(
        ({ storage }, reqId: string, status: ComplianceMatrixRow["requirement_status"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

            liveRequirement?.set("requirement_status", status);

            trackUserEvent("Requirements: Requirement Status Updated", {
                requirement_id: reqId,
                new_requirement_status: status
                    ? REQUIREMENT_STATUS_TO_META[status]?.label
                    : REQUIREMENT_STATUS_TO_META[RequirementStatus.Todo].label,
            });
        },
        []
    );

    const setGeneratedHeading = useMutation(
        ({ storage }, reqId: string, heading: ComplianceMatrixRow["requirement"]["generated_heading"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

            liveRequirement?.get("requirement").set("generated_heading", heading);
        },
        []
    );

    const setComplianceStatus = useMutation(
        ({ storage }, reqId: String, status: ComplianceMatrixRow["compliance_status"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

            liveRequirement?.set("compliance_status", status);

            trackUserEvent("Requirements: Compliance Status Updated", {
                requirement_id: reqId,
                new_compliance_status: status
                    ? COMPLIANCE_TO_META[status]?.label
                    : COMPLIANCE_TO_META[RequirementCompliance.Empty].label,
            });
        },
        []
    );

    const setAssignees = useMutation(
        ({ storage }, reqId: string, assignees: ComplianceMatrixRow["assigned_user_ids"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

            liveRequirement?.set("assigned_user_ids", assignees);

            trackUserEvent("Requirements: Assignee Updated", {
                requirement_id: reqId,
                new_assignees_count: assignees?.length ?? 0,
            });
        },
        []
    );

    const addNewWritingPrompt = useMutation(({ storage }, reqId: string, properties?: Partial<WritingPrompt>) => {
        const newLiveWritingPrompt = createWritingPrompt(properties);
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));
        if (liveRequirement?.get("locked")) return;

        const liveWritingPrompts = liveRequirement?.get("writing_prompts");

        if (!liveWritingPrompts?.length) {
            liveRequirement?.set("writing_prompts", new LiveList([newLiveWritingPrompt]));
        } else liveRequirement?.get("writing_prompts")?.push(newLiveWritingPrompt);

        return newLiveWritingPrompt.toImmutable();
    }, []);

    const saveWritingPromptProperties = useMutation(
        ({ storage }, reqId: string, promptId: string, properties: Partial<WritingPrompt>) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const row = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));
            if (row?.get("locked")) return;

            const livePrompt = row?.get("writing_prompts")?.find((prompt) => prompt.get("id") === promptId);
            livePrompt?.update(properties);
        },
        []
    );

    const deleteWritingPrompt = useMutation(({ storage }, reqId: string, promptid: string) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const row = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));

        if (row?.get("locked")) return;
        const livePrompts = row?.get("writing_prompts");
        const livePromptIndex = livePrompts?.findIndex((prompt) => prompt.get("id") === promptid);

        if (typeof livePromptIndex === "number" && livePromptIndex >= 0) livePrompts?.delete(livePromptIndex);
    }, []);

    const addNewUserInstruction = useMutation(({ storage }, reqId: string, content: string) => {
        const newLiveUserInstruction = createUserInstruction(content);
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const liveRequirement = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));
        if (liveRequirement?.get("locked")) return;
        const liveUserInstructions = liveRequirement?.get("user_instructions");

        if (!liveUserInstructions?.length) {
            liveRequirement?.set("user_instructions", new LiveList([newLiveUserInstruction]));
        } else liveRequirement?.get("user_instructions")?.push(newLiveUserInstruction);

        return newLiveUserInstruction.toImmutable();
    }, []);

    const saveWritingInstructionProperties = useMutation(
        ({ storage }, reqId: string, instructionId: string, properties: Partial<UserInstruction>) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const row = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));
            if (row?.get("locked")) return;

            const liveInstruction = row
                ?.get("user_instructions")
                ?.find((instruction) => instruction.get("id") === instructionId);
            liveInstruction?.update(properties);
        },
        []
    );

    const deleteWritingInstruction = useMutation(({ storage }, reqId: string, instructionId: string) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const row = complianceMatrix?.find((row) => reqId === row.get("requirement").get("id"));
        const liveInstructions = row?.get("user_instructions");

        if (row?.get("locked")) return;

        const liveInstructionIndex = liveInstructions?.findIndex(
            (instruction) => instruction.get("id") === instructionId
        );

        if (typeof liveInstructionIndex === "number" && liveInstructionIndex >= 0)
            liveInstructions?.delete(liveInstructionIndex);
    }, []);

    const deleteRequirementRow = useMutation(({ storage }, reqId: string) => {
        const rowIndex = (storage.get("compliance_matrix") as Storage["compliance_matrix"])?.findIndex(
            (row) => row.get("requirement")?.get("id") === reqId
        );

        if (rowIndex >= 0) (storage.get("compliance_matrix") as Storage["compliance_matrix"]).delete(rowIndex);
    }, []);

    const disregardRequirement = useMutation(({ storage }, reqId: string, undoDisregard?: boolean) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const complianceMatrixRow = complianceMatrix?.find((row) => row?.get("requirement")?.get("id") === reqId);

        if (!complianceMatrixRow) return;

        if (undoDisregard) {
            complianceMatrixRow.get("requirement")?.set("disregarded", false);
        } else {
            complianceMatrixRow.get("requirement")?.set("disregarded", true);
        }

        assignToSection(reqId, null);
    }, []);

    const appendGeneratedContentIdeas = useMutation(
        ({ storage }, reqId: string, generatedContentIdeas: ComplianceMatrixRow["generated_content_ideas_v2"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const complianceMatrixRow = complianceMatrix?.find((row) => row?.get("requirement")?.get("id") === reqId);

            const liveContentIdeas = complianceMatrixRow?.get("generated_content_ideas_v2");
            if (!liveContentIdeas?.length) {
                complianceMatrixRow?.set("generated_content_ideas_v2", new LiveList(generatedContentIdeas));
            } else {
                generatedContentIdeas?.forEach((idea) => {
                    liveContentIdeas?.push(idea);
                });
            }
        },
        []
    );

    const appendSelectedContentIdeas = useMutation(
        ({ storage }, reqId: string, selectedContentIdeas: ComplianceMatrixRow["generated_content_ideas_v2"]) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const complianceMatrixRow = complianceMatrix?.find((row) => row?.get("requirement")?.get("id") === reqId);

            const liveContentIdeas = complianceMatrixRow?.get("selected_content_v2");
            if (!liveContentIdeas?.length) {
                complianceMatrixRow?.set("selected_content_v2", new LiveList(selectedContentIdeas));
            } else {
                selectedContentIdeas?.forEach((idea) => {
                    liveContentIdeas?.push(idea);
                });
            }
        },
        []
    );

    const setSkipped = useMutation(({ storage }, reqId: string, skipped: boolean) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const complianceMatrixRow = complianceMatrix?.find((row) => row?.get("requirement")?.get("id") === reqId);

        if (!complianceMatrixRow) return;

        complianceMatrixRow.get("requirement")?.set("skipped", skipped);
    }, []);

    const setNotes = useMutation(({ storage }, reqId: string, notes: string) => {
        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        const complianceMatrixRow = complianceMatrix?.find((row) => row?.get("requirement")?.get("id") === reqId);

        complianceMatrixRow?.set("notes", notes);
    }, []);

    const splitRequirement = useMutation(
        ({ storage }, reqId: string, range: { start: number; end: number }, retainSection?: boolean) => {
            const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
            const complianceMatrixRowIndex = complianceMatrix?.findIndex(
                (row) => row?.get("requirement")?.get("id") === reqId
            );
            const complianceMatrixRow = complianceMatrix.get(complianceMatrixRowIndex);
            const immutableRow = complianceMatrixRow?.toImmutable();
            const selectedText = (
                immutableRow?.requirement.content ||
                immutableRow?.requirement.summarized_content ||
                ""
            ).slice(range.start, range.end);
            const excludedText = (
                immutableRow?.requirement.content ||
                immutableRow?.requirement.summarized_content ||
                ""
            )
                .split(selectedText)
                .join("");

            if (!complianceMatrixRow) return;

            const createdRequirement = createComplianceMatrixRowRequirement({
                extraction_id: immutableRow?.requirement.extraction_id,
                content: selectedText,
            });
            const newRequirement = createComplianceMatrixRow({
                requirement: createdRequirement,
                ...(retainSection && {
                    proposal_reference: new LiveObject(complianceMatrixRow.get("proposal_reference").toImmutable()),
                }),
            });

            complianceMatrixRow?.get("requirement").set("content", excludedText);

            complianceMatrix.insert(newRequirement, complianceMatrixRowIndex + 1);
        },
        []
    );

    return {
        setRequirementStatus,
        setComplianceStatus,
        assignToSection,
        setAssignees,
        setRequirementContent,
        addNewWritingPrompt,
        saveWritingPromptProperties,
        addNewUserInstruction,
        saveWritingInstructionProperties,
        deleteWritingPrompt,
        deleteWritingInstruction,
        deleteRequirementRow,
        disregardRequirement,
        appendGeneratedContentIdeas,
        appendSelectedContentIdeas,
        setSkipped,
        setNotes,
        splitRequirement,
        setRequirementLock,
        setGeneratedHeading,
    };
};

export default useRequirementOperations;
