/** @jsxImportSource @emotion/react */

import { Link, useSearchParams } from "react-router-dom";
import msgNotif from "Assets/message-notif.svg";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SiGoogleassistant } from "react-icons/si";
import { toggleAssistant, toggleContentSearch, toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import Tooltip from "components/atoms/tooltip";
import { RequirementsIcon } from "../contants";

const styles = {
    button: "w-[46px] h-10 flex items-center justify-center rounded-lg border border-[rgb(218,220,224)] text-[rgb(95,99,104)] hover:bg-[rgb(218,220,224,0.2)] active:text-[rgb(60,64,67)]",
};

const CopilotActionsOptions = () => {
    const flags = useFlags();
    const dispatch = useAppDispatch();
    const { progressCompleted } = useAppSelector((root) => root.projectProgress);
    const { open, assistantOpen, contentSearchOpen, requirementsOpen, requirementDetailsOpen } = useAppSelector(
        (root) => root.copilotDrawer
    );
    const [searchParams] = useSearchParams();
    const internalContractId = searchParams.get("id")?.toLocaleLowerCase();

    const [percent, setPercent] = useState(0);
    useEffect(() => {
        if (open)
            setTimeout(() => {
                setPercent(progressCompleted);
            }, 250);

        if (!open) setPercent(0);
    }, [open, progressCompleted, percent]);

    return (
        <>
            {!flags.writingAssistant && (
                <Link
                    to={`/dashboard/contracts/details/?tab=ask-ai&id=${internalContractId}`}
                    className="border border-action px-4 py-2 rounded-md font-medium text-sm text-action flex items-center gap-2 whitespace-nowrap w-max"
                >
                    <img src={msgNotif} alt="" />
                    AI Assistant
                </Link>
            )}
            {flags.writingAssistant && (
                <Tooltip content="Writing Assistant">
                    <button
                        className={styles.button}
                        css={[assistantOpen && tw`!bg-action-light !text-action`]}
                        onClick={() => {
                            dispatch(toggleAssistant(true));
                        }}
                    >
                        <SiGoogleassistant className="text-[18px]" />
                    </button>
                </Tooltip>
            )}
            <Tooltip content="Requirements">
                <button
                    className={styles.button}
                    css={[(requirementsOpen || requirementDetailsOpen) && tw`!bg-action-light !text-action`]}
                    onClick={() => {
                        dispatch(toggleRequirements(true));
                    }}
                >
                    <RequirementsIcon className="text-[20px]" />
                </button>
            </Tooltip>
            <Tooltip content="Search Content">
                <button
                    className={styles.button}
                    css={[contentSearchOpen && tw`!bg-action-light !text-action`]}
                    onClick={() => {
                        dispatch(toggleContentSearch(true));
                    }}
                >
                    <LuSearch className="text-[20px] stroke-[2.5]" />
                </button>
            </Tooltip>
        </>
    );
};

export default CopilotActionsOptions;
