export const MAX_FILE_SIZE = 20000000;

export const AVAILABLE_KEYWORDS = [
    "shall",
    "will",
    "must",
    "should",
    "submit",
    "perform",
    "may",
    "can",
    "require",
    "compliant",
    "compliance",
    "comply",
    "specification",
    "criteria",
    "qualification",
    "deliverable",
    "mandatory",
    "license",
    "licensing",
    "certification",
    "certified",
    "assist",
    "acknowledge",
    "comprise",
    "contain",
    "describe",
    "designate",
    "ensure",
    "explain",
    "establish",
    "identify",
    "include",
    "necessary",
    "?",
];
