import { useState } from "react";
import CustomModal from "../../CustomModal";
import union from "../../../Assets/addlink.svg";
import cross from "../../../Assets/crosss.svg";
import { Spinner } from "../../../utils/icons";
import { uploadInternalContractLink } from "api/api";
import { useNotification } from "context/notificationContext";

// ---------------- Add new Link Modal handler --------------------------
const LinkModal = ({ showLinkModal, setShowLinkModal, internalContractId, setForceRefresh, searchPage }) => {
    const [showBtnLoading, setShowBtnLoading] = useState(false);
    const { setToast } = useNotification();

    const [name, setName] = useState("");
    const [link, setLink] = useState("https://");

    // on save link
    const onSaveBtn = () => {
        if (name.length === 0 || link.length === 0) {
            setToast.warning({
                msg: "Please provide a bookmark name and url",
            });
            return;
        }
        setShowBtnLoading(true);

        uploadInternalContractLink(internalContractId, {
            name: name,
            url: link,
        })
            .then((res) => {
                setShowLinkModal(false);
                setShowBtnLoading(false);
                setName("");
                setLink("");
                setForceRefresh(true);
            })
            .catch((err) => {
                setShowBtnLoading(false);
                setToast.error({
                    title: "Unable to create bookmark",
                    msg: "We were unable to create the bookmark due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            });
    };

    return (
        <CustomModal isOpen={showLinkModal} onClose={() => setShowLinkModal(false)} className="max-w-[556px] w-full">
            <div>
                <div className="px-4 pt-4 pb-2 flex justify-between">
                    <h3 className="font-semibold text-lg">Add {searchPage ? "Link" : "Bookmark"}</h3>
                    <img
                        src={cross}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => {
                            setShowLinkModal(false);
                        }}
                    />
                </div>
                <div className="p-4 flex flex-col gap-3">
                    <div className="flex flex-col  gap-3">
                        <label className="text-gray-text"> Name</label>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-lg p-2 focus:outline-none"
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex">
                        <span className="inline-flex py-2 px-4 items-center  text-sm text-gray-900 bg-[#fafafa] border border-gray-300 rounded-l-lg  ">
                            URL
                        </span>
                        <input
                            type="text"
                            className=" text-blue-500 rounded-none rounded-r-lg p-2 border-l-0 border border-gray-300 w-full focus:outline-none"
                            value={link}
                            onChange={(e) => {
                                setLink(e.target.value);
                            }}
                        />
                    </div>
                    <div className=" flex justify-end items-center gap-4 ">
                        <button
                            className="border-gray-300 border px-4 py-2 rounded-lg"
                            onClick={() => {
                                setShowLinkModal(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-action py-2 px-4 flex justify-centerx gap-1 items-center text-white rounded-lg"
                            onClick={onSaveBtn}
                        >
                            {showBtnLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <img src={union} alt="" /> Add
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default LinkModal;
