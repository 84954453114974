/** @jsxImportSource @emotion/react */

import { ReactComponent as ChevronsRight } from "../../../Assets/chevrons-right.svg";
import { ReactComponent as Draggable } from "../../../Assets/draggable.svg";
import { ReactComponent as MinusCircle } from "../../../Assets/minus-cirlce.svg";
import { ReactComponent as ChevronDown } from "../../../Assets/chevron-down.svg";
import { ReactComponent as Plus } from "../../../Assets/plus-v2.svg";
import { ReactComponent as PlusCircle } from "../../../Assets/plus-circle.svg";
import { ReactComponent as Eye } from "../../../Assets/eye.svg";
import { ReactComponent as EyeCrossed } from "../../../Assets/eye-crossed.svg";
import { ReactComponent as DrawerRightOpen } from "../../../Assets/drawer-right-open.svg";
import { ReactComponent as NoteRemove } from "../../../Assets/note-remove.svg";
import { ReactComponent as Swap } from "../../../Assets/swap.svg";
import { ReactComponent as Check } from "../../../Assets/check.svg";
import { ReactComponent as Generate } from "../../../Assets/generate-v2.svg";
import { ReactComponent as Pencil } from "../../../Assets/pencil.svg";
import { ReactComponent as Undo } from "../../../Assets/undo.svg";
import { ReactComponent as WarningCircle } from "../../../Assets/warning-circle.svg";
import { ReactComponent as InfoCircle } from "../../../Assets/info-circle-v2.svg";
import { ReactComponent as InfoCircleBold } from "../../../Assets/info-circle-bold.svg";
import { ReactComponent as TaskSquare } from "../../../Assets/task-square-v2.svg";
import { ReactComponent as Save } from "../../../Assets/save-v2.svg";
import { ReactComponent as EraserSquare } from "../../../Assets/eraser-square-v2.svg";
import { ReactComponent as CarrotDown } from "../../../Assets/carrot-down.svg";
import { ReactComponent as Trash } from "../../../Assets/trash-v2.svg";
import { ReactComponent as Locked } from "../../../Assets/locked.svg";
import { ReactComponent as Unlocked } from "../../../Assets/unlocked.svg";
import { ReactComponent as Search } from "../../../Assets/search-v2.svg";
import { ReactComponent as StatusFlag } from "../../../Assets/status-flag.svg";
import { ReactComponent as ApprovedCheck } from "../../../Assets/approved-check.svg";
import { ReactComponent as AttachSquare } from "../../../Assets/attach-square-v2.svg";
import { ReactComponent as NoteClipboard } from "../../../Assets/note-clipboard.svg";
import { ReactComponent as NoteFold } from "../../../Assets/note-fold.svg";
import { ReactComponent as NoteFavorite } from "../../../Assets/note-favorite.svg";
import { ReactComponent as NoteClipboardFold } from "../../../Assets/note-clipboard-fold.svg";
import { ReactComponent as NoteQueue } from "../../../Assets/note-queue.svg";
import { ReactComponent as People } from "../../../Assets/people.svg";
import { ReactComponent as Copy } from "../../../Assets/copy-v2.svg";
import { ReactComponent as CopySolid } from "../../../Assets/copy-solid-v2.svg";
import { ReactComponent as Upload } from "../../../Assets/upload.svg";
import { ReactComponent as Columns } from "../../../Assets/columns.svg";
import { ReactComponent as Export } from "../../../Assets/export-v2.svg";
import { ReactComponent as Warning } from "../../../Assets/warning.svg";
import { ReactComponent as Revise } from "../../../Assets/revise.svg";
import { ReactComponent as Binder } from "../../../Assets/binder.svg";
import { ReactComponent as StoryCircle } from "../../../Assets/story-circle.svg";
import { ReactComponent as Document } from "../../../Assets/document-edit.svg";
import { ReactComponent as DocumentFilled } from "../../../Assets/document-filled.svg";
import { ReactComponent as ImportDraft } from "../../../Assets/import-draft.svg";
import { ReactComponent as ArrowLeft } from "../../../Assets/arrow-left-v2.svg";
import { ReactComponent as StatusTodo } from "../../../Assets/statuses/sections/todo.svg";
import { ReactComponent as StatusInProgress } from "../../../Assets/statuses/sections/in-progress.svg";
import { ReactComponent as StatusInReview } from "../../../Assets/statuses/sections/in-review.svg";
import { ReactComponent as StatusDone } from "../../../Assets/statuses/sections/done.svg";
import { ReactComponent as NotCompliant } from "../../../Assets/compliance/not-compliant.svg";
import { ReactComponent as PartialCompliant } from "../../../Assets/compliance/partial-compliant.svg";
import { ReactComponent as FullCompliant } from "../../../Assets/compliance/full-compliant.svg";
import { ReactComponent as EmptyCompliant } from "../../../Assets/compliance/empty-compliant.svg";
import { HTMLProps, forwardRef } from "react";
import "twin.macro";

enum IconType {
    ChevronsRight,
    Draggable,
    MinusCircle,
    ChevronDown,
    Plus,
    PlusCircle,
    Eye,
    EyeCrossed,
    DrawerRightOpen,
    NoteRemove,
    NoteClipboard,
    Swap,
    Check,
    Generate,
    Pencil,
    Undo,
    WarningCircle,
    TaskSquare,
    Save,
    EraserSquare,
    CarrotDown,
    Trash,
    InfoCircle,
    InfoCircleBold,
    Locked,
    Unlocked,
    StatusFlag,
    ApprovedCheck,
    Search,
    AttachSquare,
    Copy,
    CopySolid,
    Upload,
    Columns,
    Export,
    People,
    NoteFold,
    NoteClipboardFold,
    NoteFavorite,
    NoteQueue,
    Warning,
    Revise,
    Binder,
    StoryCircle,
    Document,
    DocumentFilled,
    ImportDraft,
    ArrowLeft,
    StatusInProgress,
    StatusTodo,
    StatusInReview,
    StatusDone,
    NotCompliant,
    PartialCompliant,
    FullCompliant,
    EmptyCompliant,
}

const ICONS: Record<keyof typeof IconType, React.FC> = {
    ChevronsRight,
    Draggable,
    MinusCircle,
    ChevronDown,
    Plus,
    PlusCircle,
    Eye,
    EyeCrossed,
    DrawerRightOpen,
    NoteRemove,
    NoteClipboard,
    Swap,
    Check,
    Generate,
    Pencil,
    Undo,
    WarningCircle,
    TaskSquare,
    Save,
    EraserSquare,
    CarrotDown,
    Trash,
    InfoCircle,
    InfoCircleBold,
    Locked,
    Unlocked,
    StatusFlag,
    ApprovedCheck,
    Search,
    AttachSquare,
    Copy,
    CopySolid,
    Upload,
    Columns,
    Export,
    People,
    NoteFold,
    NoteClipboardFold,
    NoteFavorite,
    NoteQueue,
    Warning,
    Revise,
    Binder,
    StoryCircle,
    Document,
    DocumentFilled,
    ImportDraft,
    ArrowLeft,
    StatusInProgress,
    StatusTodo,
    StatusInReview,
    StatusDone,
    NotCompliant,
    PartialCompliant,
    FullCompliant,
    EmptyCompliant,
};

type Props = {
    name: keyof typeof IconType;
} & HTMLProps<SVGElement>;

const Icon = forwardRef<SVGElement, Props>(({ name, ...props }, ref) => {
    const Component = ICONS[name];
    return <Component ref={ref} {...props} />;
});

export default Icon;
