import { Editor } from "@tiptap/react";
import { Avatars } from "components/editor/components/Avatars";
import { useDispatch } from "react-redux";
import { isExpandedAction } from "store/reducers/aiReducerSlice";
import { ExpandIcon } from "utils/icons";
import styles from "./Toolbar.module.css";
import { ToolbarAlignment } from "./ToolbarAlignment";
import { ToolbarBlock } from "./ToolbarBlock";
import { ToolbarCommands } from "./ToolbarCommands";
import { ToolbarHeadings } from "./ToolbarHeadings";
import { ToolbarInline } from "./ToolbarInline";
import { ToolbarMedia } from "./ToolbarMedia";

type Props = {
    editor: Editor;
    fullscreen?: boolean;
};

export function Toolbar({ editor, fullscreen }: Props) {
    const dispatch = useDispatch();
    return (
        <div className={styles.toolbar}>
            {/* <div className={styles.toolbarSection}>
        <ThemeToggle />
      </div> */}
            <div className={styles.avatarAndExpand}>
                {!fullscreen && (
                    <span
                        onClick={() => dispatch(isExpandedAction())}
                        role="button"
                        aria-label="Expand"
                        className={`${styles.toolbarButton} cursor-pointer select-none w-max max-w-full}`}
                    >
                        <ExpandIcon />
                    </span>
                )}
                <Avatars style={{ marginRight: 6 }} />
            </div>
            <div className={styles.toolbarSection}>
                <ToolbarCommands editor={editor} />
            </div>
            <div className={styles.toolbarSection}>
                <ToolbarHeadings editor={editor} />
            </div>
            <div className={styles.toolbarSection}>
                <ToolbarInline editor={editor} />
            </div>
            {/* <div className={styles.toolbarSection}>
                <ToolbarInlineAdvanced editor={editor} />
            </div> */}
            <div className={styles.toolbarSection}>
                <ToolbarAlignment editor={editor} />
            </div>
            <div className={styles.toolbarSection}>
                <ToolbarBlock editor={editor} />
            </div>
            <div className={styles.toolbarSection}>
                <ToolbarMedia editor={editor} />
            </div>
        </div>
    );
}
