/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import { DropdownMenu, DropdownMenuProps } from "components/molecules/dropdown-menu";
import { useStorage } from "liveblocks.config";
import { Storage as ImmutableStorage } from "../CopilotSchemaImmutableTypes";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setExtractState } from "store/reducers/extract/ExtractReducer";
import tw from "twin.macro";
import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { LuCheck, LuPlusCircle, LuX } from "react-icons/lu";
import { nanoid } from "nanoid";
import { EMPTY_SHEET } from "const-values/Sheets";

type Props = {
    dropdownMenuProps?: Pick<DropdownMenuProps<string>, "portalProps">;
};

const ExtractToSheetDropdown = ({ dropdownMenuProps }: Props) => {
    const [isNewSheet, setIsNewSheet] = useState(false);
    const selectedSheet = useAppSelector((store) => store.extractReducer.selectedSheet);
    const shouldShowDefault = useStorage((storage) =>
        (storage.compliance_matrix as ImmutableStorage["compliance_matrix"]).some(
            (row) => !row.requirement?.extraction_id
        )
    );
    const sheets = useStorage((storage) => [
        ...(shouldShowDefault ? [EMPTY_SHEET] : []),
        ...((storage.sheets as ImmutableStorage["sheets"]) || []),
    ]);
    const dispatch = useAppDispatch();

    const isNewSheetValid = useMemo(() => {
        return (
            !!selectedSheet?.name &&
            selectedSheet?.name?.toLowerCase() !== EMPTY_SHEET.name.toLowerCase() &&
            !sheets.some((sheet) => sheet.name.trim().toLowerCase() === selectedSheet.name.trim().toLowerCase())
        );
    }, [selectedSheet?.name, sheets]);

    const availableSheetOptions: MenuItem<string>[] = useMemo(() => {
        const sheetsOptions = (sheets || [])?.map((sheet) => ({
            key: sheet.id,
            label: sheet.name,
            value: sheet.id,
            selected: selectedSheet?.id === sheet.id,
            onSelect: () => {
                setIsNewSheet(false);
                dispatch(setExtractState({ selectedSheet: sheet }));
            },
        }));

        return [
            {
                key: 1,
                label: (
                    <div className="text-action flex items-center gap-1">
                        <LuPlusCircle /> New sheet
                    </div>
                ),
                onSelect: () => {
                    dispatch(setExtractState({ selectedSheet: { id: nanoid(), name: "" } }));
                    setIsNewSheet(true);
                },
            },
            ...sheetsOptions,
        ];
    }, [dispatch, selectedSheet, sheets]);

    return (
        <div className="mb-4 flex flex-col gap-3 w-fit">
            <div className="text-sm font-normal text-day-subtext">Extract to:</div>
            <DropdownMenu
                contentProps={{ align: "start" }}
                triggerProps={{ css: tw`w-fit` }}
                items={availableSheetOptions}
                {...dropdownMenuProps}
            >
                <div className="min-w-[170px] min-h-[34px] rounded-md justify-between flex items-center gap-4 border border-gray-light px-2 py-1.5 text-sm text-gray-darkest duration-100 hover:border-gray-600">
                    <span
                        className="truncate max-w-[250px]"
                        css={[(isNewSheet || !selectedSheet) && tw`text-slate-400`]}
                    >
                        {selectedSheet?.name || "Sheet..."}
                    </span>
                    <Icon name="CarrotDown" />
                </div>
            </DropdownMenu>
            {isNewSheet && selectedSheet?.id && (
                <div className="flex gap-2 items-center">
                    <input
                        autoFocus
                        className="border border-gray-light rounded-md px-2 outline-none py-1.5 text-sm w-[250px]"
                        placeholder="New sheet name"
                        value={selectedSheet?.name || ""}
                        onChange={(e) => {
                            if (e.target.value.length <= 31)
                                dispatch(
                                    setExtractState({ selectedSheet: { ...selectedSheet, name: e.target.value } })
                                );
                        }}
                    />
                    {!!selectedSheet.name && isNewSheetValid && <LuCheck className="text-sm text-green-600" />}
                    {!!selectedSheet.name && !isNewSheetValid && (
                        <div className="flex gap-1 text-xs items-center text-red-500">
                            <LuX className="text-sm" />
                            Sheet exists
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ExtractToSheetDropdown;
