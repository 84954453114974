import { BellIcon } from "../../utils/icons";
import { useState, useEffect } from "react";
import ClickAwayListener from "../../helpers/ClickAwayListener";
import { Link } from "react-router-dom";
import setting from "../../Assets/setting-2.svg";
import logoutIcon from "../../Assets/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "store/reducers/authReducerSlice";
import { getNotificationsApi } from "api/api";
import { getTimeDifference } from "helpers/helper";
import { useFlags } from "launchdarkly-react-client-sdk";
import VoiceAssistControl from "components/voice-assist/VoiceAssistControl";
import Avatar from "../Avatar";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const DEFAULT_AVATAR = "Vultron";

// ----------- Navbar for Dashboard ------------------
const DashboardNav = () => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((store) => store.auth);
    const [notificationModal, setNotificationModal] = useState(false);
    const [showProfileDropDown, setShowProfileDropDown] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const flags = useFlags();

    useEffect(() => {
        getNotificationsApi()
            .then((res) => {
                setNotifications(res?.data || []);
            })
            .catch((err) => console.log(err));
    }, []);

    const logout = () => {
        localStorage.removeItem("vultron_workspace_id");
        localStorage.removeItem("vultron_user_token");
        window.Intercom("shutdown");
        setShowProfileDropDown(false);
        dispatch(resetAuth());
    };

    return (
        <div className="py-3 flex pr-5 pl-2 shadow-sm bg-white min-h-[64px] relative">
            <div className="flex flex-grow items-center">{flags.voiceAssist && <VoiceAssistControl />}</div>
            <div className="flex items-center gap-2">
                <ClickAwayListener onClickAway={() => setNotificationModal(false)} className="relative">
                    <span
                        onClick={() => setNotificationModal(!notificationModal)}
                        className="cursor-pointer select-none"
                        role="button"
                        title="Notifications"
                    >
                        <BellIcon read={"true"} />
                    </span>
                    {notificationModal ? (
                        <div className="absolute w-[620px] max-w-[650px] top-[135%] rounded-[12px] border px-4 py-3 right-0 bg-white shadow-[0_0_15px_rgba(0,0,0,0.1)] z-[991]">
                            <div className="flex justify-between items-center bg-white pb-3">
                                <h4 className="flex-grow pr-2 font-semibold text-[20px]">Notifications</h4>
                            </div>
                            <div
                                className={`px-1 ${
                                    notifications.length === 0
                                        ? "h-[100px] flex items-center justify-center"
                                        : "max-h-[500px] overflow-y-auto"
                                }`}
                            >
                                {/* ********** */}
                                {notifications.length > 0 ? (
                                    notifications.map((notification, idx) => {
                                        return (
                                            <Link
                                                key={`${notification.title}_${idx}`}
                                                to={(() => {
                                                    switch (notification.type) {
                                                        case 0: // contract note
                                                            // Preference is given to project vs search detail view if internal contract id is provided.
                                                            if (notification.data.internal_contract_id) {
                                                                return `/dashboard/contracts/details/?tab=project&id=${notification.data.internal_contract_id}`;
                                                            } else {
                                                                return `/dashboard/search/contracts-details/?id=${notification.data.contract_id}`;
                                                            }
                                                        case 1: // extract requirement
                                                            return `/dashboard/contracts/details/?tab=compliancematrix&id=${notification.data.internal_contract_id}`;
                                                        case 2: // generate proposal
                                                            return `/dashboard/contracts/details/?tab=proposal&id=${notification.data.internal_contract_id}`;
                                                        case 3: // bolt extraction
                                                            return "/dashboard/library";
                                                        case 4: // contract assignment
                                                            return `/dashboard/contracts/details/?tab=project&id=${notification.data.internal_contract_id}`;
                                                        case 5: // requirement assignment
                                                            return `/dashboard/contracts/details/?tab=compliancematrix&id=${notification.data.internal_contract_id}`;
                                                        case 6: // template extraction
                                                            return `/dashboard/contracts/details/?tab=framework&id=${notification.data.internal_contract_id}`;
                                                        default:
                                                            return window.location.pathname;
                                                    }
                                                })()}
                                                onClick={() => setNotificationModal(false)}
                                                className="border cursor-pointer select-none flex rounded-lg p-5 my-[0.39rem] text-sm"
                                            >
                                                <div>
                                                    <Avatar
                                                        id={(() => {
                                                            switch (notification.type) {
                                                                case 0:
                                                                    return notification.data.sent_by_username;
                                                                case 1:
                                                                    return notification.data.started_by_username;
                                                                case 2:
                                                                    return notification.data.started_by_username;
                                                                case 3:
                                                                    return notification.data.started_by_username;
                                                                case 4:
                                                                    return notification.data.sent_by_username;
                                                                case 5:
                                                                    return DEFAULT_AVATAR;
                                                                case 6:
                                                                    return notification.data.started_by_username;
                                                                default:
                                                                    return window.location.pathname;
                                                            }
                                                        })()}
                                                        width="24"
                                                        height="24"
                                                        src=""
                                                        alt={(() => {
                                                            switch (notification.type) {
                                                                case 0:
                                                                    return notification.data.sent_by_username;
                                                                case 1:
                                                                    return notification.data.started_by_username;
                                                                case 2:
                                                                    return notification.data.started_by_username;
                                                                case 3:
                                                                    return notification.data.started_by_username;
                                                                case 4:
                                                                    return notification.data.sent_by_username;
                                                                case 5:
                                                                    return DEFAULT_AVATAR;
                                                                case 6:
                                                                    return notification.data.started_by_username;
                                                                default:
                                                                    return window.location.pathname;
                                                            }
                                                        })()}
                                                    />
                                                </div>
                                                <div className="pl-3 w-full">
                                                    <div className="flex items-start">
                                                        <p className="font-[500] flex-grow text-[16px] text-black">
                                                            {notification.title}
                                                        </p>
                                                        <>
                                                            <div className="relative group">
                                                                <span className="text-gray-400 font-normal ml-auto text-[14px] whitespace-nowrap">
                                                                    {getTimeDifference(notification.created_at)}
                                                                </span>
                                                                <div className="absolute font-inter  font-semibold w-max max-w-[230px] top-[130%] left-[-140px] hidden cursor-pointer rounded border-[2px] border-[#dbe0e5]  bg-white shadow-xl  group-hover:block z-20">
                                                                    <div className="menu-arrow !bg-[#dbe0e5] !left-[170px]"></div>
                                                                    <div className="bg-white p-2">
                                                                        <p className="text-sm text-gray-primary font-normal pr-1">
                                                                            {notification.notified_at}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                    <p className="pt-2 max-w-[450px] overflow-ellipsis overflow-hidden mr-4">
                                                        {notification.message}
                                                    </p>
                                                </div>
                                            </Link>
                                        );
                                    })
                                ) : (
                                    <p className="text-center text-gray-400">
                                        {" "}
                                        You don't have any notifications at this moment.{" "}
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : null}
                </ClickAwayListener>
                <div className="relative">
                    <Avatar
                        onClick={() => setShowProfileDropDown(true)}
                        colorCode={2}
                        alt={currentUser?.username}
                        id={currentUser?.username}
                        width={34}
                        height={34}
                        className={"!text-[18px] font-semibold ml-3"}
                    />
                    {showProfileDropDown && (
                        <ClickAwayListener
                            onClickAway={(e) => {
                                e.stopPropagation();
                                setShowProfileDropDown(false);
                            }}
                            className="absolute bg-white border border-gray-100 shadow-md p-4 right-0 top-12 min-w-[120px] flex flex-col items-left justify-center gap-2 z-[999]"
                        >
                            <Link
                                onClick={() => setShowProfileDropDown(false)}
                                className="flex gap-2 font-medium text-stone-800"
                                to="/dashboard/settings?tab=workspace"
                            >
                                <img src={setting} alt="" />
                                Settings
                            </Link>
                            <Link
                                onClick={() => setShowProfileDropDown(false)}
                                className="flex gap-2 items-center font-medium text-stone-800"
                                to="https://intercom.help/vultron-ai/en/"
                                target="_blank"
                            >
                                <AiOutlineQuestionCircle className="text-lg text-stone-800" />
                                Help
                            </Link>
                            <Link onClick={logout} className="flex gap-2 font-medium text-stone-800" to="/auth">
                                <img src={logoutIcon} alt="" />
                                Logout
                            </Link>
                        </ClickAwayListener>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardNav;
