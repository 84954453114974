import { Link } from "react-router-dom";

//
const smsIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
            fill="white"
        />
        <path
            d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
            fill="white"
        />
    </svg>
);

const keyIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 18V21C2 21.6 2.4 22 3 22H7V19H10V16H12L13.4 14.6C14.7898 15.0841 16.3028 15.0823 17.6915 14.5947C19.0801 14.1072 20.2622 13.1628 21.0444 11.9161C21.8265 10.6694 22.1624 9.19415 21.9971 7.73172C21.8318 6.26928 21.1751 4.90623 20.1344 3.86555C19.0937 2.82486 17.7307 2.16816 16.2683 2.00287C14.8058 1.83757 13.3306 2.17347 12.0839 2.95562C10.8372 3.73776 9.89279 4.91985 9.40525 6.3085C8.91771 7.69714 8.91585 9.21014 9.4 10.6L2 18Z"
            stroke="#2A47AB"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.5 8C16.7761 8 17 7.77614 17 7.5C17 7.22386 16.7761 7 16.5 7C16.2239 7 16 7.22386 16 7.5C16 7.77614 16.2239 8 16.5 8Z"
            stroke="#2A47AB"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

// ---------------- Auth 1st screen -------------------
const ContinueWithEmail = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center ">
                <h1 className="text-4xl font-bold py-1">Welcome to Vultron</h1>
                <p className="text-gray-600 py-2">Sign up or login with your work email.</p>
            </div>
            <Link
                to="/auth/login"
                className="bg-action duration-150 hover:bg-action-hover text-white flex my-2 w-[450px] flex-col rounded-md mt-6"
            >
                <span className="w-full flex p-4 items-center gap-3 justify-center">
                    {" "}
                    <span>{smsIcon}</span> <span>Continue with work email</span>{" "}
                </span>
            </Link>
            <Link
                to={"/auth/sso-login"}
                className="bg-white duration-150 hover:bg-action-lightest text-action flex my-2 w-[450px] flex-col rounded-md border border-action"
            >
                <span className="w-full flex p-4 items-center gap-3 justify-center">
                    {" "}
                    <span>{keyIcon}</span> <span>Continue with SSO</span>{" "}
                </span>
            </Link>
        </>
    );
};

export default ContinueWithEmail;
