import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { TemplateDocumentType } from "components/copilot/Extract/Extract";

export enum ExtractMode {
    DocumentUpload = 1,
    CopyPaste,
}

export enum ExtractionType {
    Requirements = 1,
    Template,
}

type State = {
    keywords: string[];
    aiDetection: boolean;
    splitType: "sentence" | "paragraph";
    mode: ExtractMode;
    selectedDocument: string | File;
    content: string;
    extractionType?: ExtractionType;
    templateDocumentType: TemplateDocumentType | string;
    selectedSheet?: Sheet;
    shouldGenerateTemplateWithRequirements: boolean;
};

const initialState: State = {
    keywords: ["shall", "must", "will", "require"],
    aiDetection: true,
    splitType: "paragraph",
    mode: ExtractMode.DocumentUpload,
    selectedDocument: "",
    content: "",
    extractionType: undefined,
    templateDocumentType: "",
    shouldGenerateTemplateWithRequirements: false,
};

const extractReducer = createSlice({
    name: "extractReducer",
    initialState,
    reducers: {
        setExtractState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
        resetExtractState: (state: State) => {
            return { ...initialState, mode: state.mode };
        },
        clearExtractState: () => {
            return initialState;
        },
    },
});

export const { setExtractState, resetExtractState, clearExtractState } = extractReducer.actions;

export default extractReducer.reducer;
