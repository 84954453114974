import { ClientSideSuspense } from "@liveblocks/react";
import LiveblocksProvider from "@liveblocks/yjs";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import Highlight from "@tiptap/extension-highlight";
import { Image } from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import TaskList from "@tiptap/extension-task-list";
import { TextAlign } from "@tiptap/extension-text-align";
import { Typography } from "@tiptap/extension-typography";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { EditorView } from "prosemirror-view";
import { useEffect, useRef, useState } from "react";
import * as Y from "yjs";
import { useRoom } from "../../../components/editor/liveblocks.config";
import styles from "./TextEditor.module.css";
import classNames from "classnames";
import { PROSE_CONTAINER_ID } from "components/editor/constants";
import { Loading } from "./Loading";
import { Presence, UserMeta } from "components/editor/types";
import "../style/global.css";
import { useDispatch } from "react-redux";
import {
    editorPrevSelectionAction,
    prevDomSelectionAction,
    resetEditorStateAction,
    storeEditorStateAction,
} from "store/reducers/yjs-editor-reducer";
import { useSelector } from "react-redux";
import AskAiSearchBar, { DropDownMenu, ResponseDropDown, ToneDropDown } from "./AskAiToolbar/SearchBarInput";
import { selectedTextAction } from "store/reducers/yjs-editor-reducer";
import { SelectionMenu } from "./SelectionMenu";
import { CustomTaskItem } from "./CustomTaskItem";
import { CopilotPresencePage } from "types/Presence";
import LiveNameCursors from "components/molecules/live-name-cursors";
import { ContentLibraryImage } from "./ContentLibrary/ContentLibraryImage";
import { ExtensionFontSize } from "../extensions/ExtensionFontSize";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";

export function TextEditor({
    color,
    liveCursor,
    roomId,
    fullscreen,
}: {
    color: string;
    liveCursor: boolean;
    roomId: string;
    fullscreen?: boolean;
}) {
    return (
        <ClientSideSuspense fallback={<Loading />}>
            {() => <Editor color={color} liveCursor={liveCursor} roomId={roomId} fullscreen={fullscreen} />}
        </ClientSideSuspense>
    );
}

// Collaborative text editor with simple rich text and live cursors
export function Editor({
    color,
    liveCursor,
    roomId,
    fullscreen,
}: {
    color: string;
    liveCursor: boolean;
    roomId: string;
    fullscreen?: boolean;
}) {
    const room = useRoom();
    const [doc, setDoc] = useState<Y.Doc>();
    const [provider, setProvider] = useState<LiveblocksProvider<Presence, Storage, UserMeta, never>>();
    const [isLoading, setIsLoading] = useState(true);
    const currentUserData = useSelector((store: any) => store.auth);
    useEffect(() => {
        const yDoc = new Y.Doc();
        const yProvider = new LiveblocksProvider(room, yDoc);
        setProvider(yProvider);
        yProvider.once("synced", (sync: boolean) => {
            setIsLoading(true);
            if (sync === true) {
                // Yjs content is synchronized and ready
                setProvider(yProvider);
                setDoc(yDoc);
            }
        });
        const intervalId = setTimeout(() => {
            setIsLoading(false);
        }, 5000);
        return () => {
            yDoc?.destroy();
            yProvider?.destroy();
            clearInterval(intervalId);
        };
    }, [room]);

    if (!doc || !provider) {
        return null;
    }
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <TiptapEditor
                    fullscreen={fullscreen}
                    liveCursor={liveCursor}
                    roomId={roomId}
                    name={currentUserData.currentUser.username}
                    color={color}
                    doc={doc}
                    provider={provider}
                />
            )}
        </>
    );
}

type EditorProps = {
    doc: Y.Doc;
    name: string;
    color: string;
    provider: any;
    liveCursor: boolean;
    roomId: string;
    fullscreen?: boolean;
};

function TiptapEditor({ doc, name, color, provider, liveCursor, roomId, fullscreen }: EditorProps) {
    const dispatch = useDispatch();

    // managing state for toggling
    const { toggleAskAiInputWthDrpDown, selectedText } = useSelector((state: any) => state.yjsEditor);

    const editorRespons = useSelector((state: any) => state.aiReducer.editorResponse);
    const scrollDiv = document.getElementById("scrollDiv");
    const proseElement = document.getElementById("slateEditor");

    const editor = useEditor({
        editorProps: {
            attributes: {
                // Add styles to editor element
                class: styles.editor,
                id: "proposal-tiptap-editor",
            },
        },
        extensions: [
            StarterKit.configure({
                blockquote: {
                    HTMLAttributes: {
                        class: "tiptap-blockquote",
                    },
                },
                // code: {
                //     HTMLAttributes: {
                //         class: "tiptap-code",
                //     },
                // },
                // codeBlock: {
                //     languageClassPrefix: "language-",
                //     HTMLAttributes: {
                //         class: "tiptap-code-block",
                //         spellcheck: false,
                //     },
                // },
                heading: {
                    levels: [1, 2, 3, 4, 5],
                    HTMLAttributes: {
                        class: "tiptap-heading",
                    },
                },
                // The Collaboration extension comes with its own history handling
                history: false,
                horizontalRule: {
                    HTMLAttributes: {
                        class: "tiptap-hr",
                    },
                },
                listItem: {
                    HTMLAttributes: {
                        class: "tiptap-list-item",
                    },
                },
                orderedList: {
                    HTMLAttributes: {
                        class: "tiptap-ordered-list",
                    },
                },
                paragraph: {
                    HTMLAttributes: {
                        class: "tiptap-paragraph",
                    },
                },
            }),
            Highlight.configure({
                HTMLAttributes: {
                    class: "tiptap-highlight",
                },
            }),
            ContentLibraryImage,
            Image.configure({
                HTMLAttributes: {
                    class: "tiptap-image",
                },
            }),
            //   Link.configure({
            //     HTMLAttributes: {
            //       class: "tiptap-link",
            //     },
            //   }),
            Placeholder.configure({
                placeholder: "Start writing…",
                emptyEditorClass: "tiptap-empty",
            }),
            CustomTaskItem,
            TaskList.configure({
                HTMLAttributes: {
                    class: "tiptap-task-list",
                },
            }),
            TextAlign.configure({
                types: ["heading", "paragraph"],
            }),
            Typography,
            Underline,
            // Register the document with Tiptap
            Collaboration.configure({
                document: doc,
            }),
            // Attach provider and user info
            CollaborationCursor.configure({
                provider: provider,
                user: {
                    name,
                    color,
                    picture: "",
                },
            }),
            TextStyle,
            ExtensionFontSize,
            FontFamily.configure({
                types: ["textStyle"],
            }),
        ],
    });
    const editorRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (editor) {
            const currentSelection = editor.state.selection;
            dispatch(editorPrevSelectionAction(currentSelection));

            dispatch(storeEditorStateAction(editor));
        }

        return () => {
            dispatch(resetEditorStateAction());
        };
    }, [editor, editor?.state.selection, dispatch, color]);

    const handleSelection = () => {
        const domSelection = window.getSelection();
        const selectedTextdiv = window.getSelection()?.toString() || "";
        if (selectedTextdiv && domSelection) {
            dispatch(
                prevDomSelectionAction({
                    domSelection,
                    scrollDivLeft: scrollDiv && scrollDiv.scrollLeft,
                    scrollDivTop: scrollDiv && scrollDiv.scrollTo,
                    proseElement: proseElement,
                })
            );
            dispatch(selectedTextAction(selectedTextdiv));
        }
    };

    return (
        <div className={styles.editor} ref={editorRef} id="scrollDiv" onPointerUp={handleSelection}>
            {liveCursor && (
                <LiveNameCursors
                    activePage={CopilotPresencePage.Proposal}
                    activeRoomId={roomId}
                    cursorPanel={editorRef}
                />
            )}
            <div
                className={classNames(styles.prose, "prose")}
                id={PROSE_CONTAINER_ID}
                // onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.container} id="slateEditor">
                    {editor && <SelectionMenu editor={editor} />}
                    {editor && selectedText !== "" && toggleAskAiInputWthDrpDown && (
                        <AskAiSearchBar
                            fullscreen={fullscreen}
                            editor={editor}
                            proseElement={proseElement}
                            scrollDiv={scrollDiv}
                        />
                    )}
                    {editor &&
                        // selectedText !== "" &&
                        toggleAskAiInputWthDrpDown &&
                        (editorRespons?.response === undefined || editorRespons.response === "") && (
                            <DropDownMenu
                                fullscreen={fullscreen}
                                editor={editor}
                                proseElement={proseElement}
                                scrollDiv={scrollDiv}
                            />
                        )}

                    {editor &&
                        selectedText !== "" &&
                        toggleAskAiInputWthDrpDown &&
                        (editorRespons?.response === undefined || editorRespons.response === "") && (
                            <ToneDropDown
                                fullscreen={fullscreen}
                                editor={editor}
                                proseElement={proseElement}
                                scrollDiv={scrollDiv}
                            />
                        )}
                    {editor && selectedText !== "" && toggleAskAiInputWthDrpDown && (
                        <ResponseDropDown
                            fullscreen={fullscreen}
                            editor={editor}
                            proseElement={proseElement}
                            scrollDiv={scrollDiv}
                        />
                    )}
                    <EditorContent
                        editor={editor}
                        className="focus:outline-none"
                        readOnly={toggleAskAiInputWthDrpDown}
                    />
                </div>
            </div>
        </div>
    );
}

// Prevents a matchesNode error on hot reloading
EditorView.prototype.updateState = function updateState(state) {
    // @ts-ignore
    if (!this.docView) return;
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    this.updateStateInner(state, this.state.plugins != state.plugins);
};
