// search table data
export const tableData = [
    {
        title: "High Angular Precision perscope High Angular Precision perscope High Angular Precision perscope High Angular Precision perscope High Angular Precision perscope",
        agency: "National Aeronautics and Space Agency National Aeronautics and Space Agency National Aeronautics and Space Agency National Aeronautics and Space Agency National Aeronautics and Space Agency",
        stage: "Award Notice",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Peterson", "matthewkane"],
        colorCode: [0, 3],
    },
    {
        title: "MCAS Yuma Air Show",
        agency: "Dept of Defense",
        stage: "Canceled Sources Sought",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Kavin"],
        colorCode: [2],
    },
    {
        title: "Facilitation Services",
        agency: "National Aeronautics and Space Agency",
        stage: "Sale of Surplus Property",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
    },
    {
        title: "Market Data Services for Global Risk System",
        agency: "Commodity Futures Trading Commision",
        stage: "Solicitation",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["matthewkane", "john.jackson", "maxwang", "davidlee"],
        colorCode: [0, 4, 3, 5],
    },
    {
        title: "Audience Evaluation of engagement content and...",
        agency: "National Aeronautics and Space Agency",
        stage: "Canceled Justification",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Lura"],
        colorCode: [1],
    },
    {
        title: "Market Data Services for Global Risk System",
        agency: "Commodity Futures Trading Commission",
        stage: "Solicitation",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
    },
    {
        title: "Audience Evaluation of engagement content and...",
        agency: "National Aeronautics and Space Agency",
        stage: "Canceled Justification",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["john.jackson", "Kavin"],
        colorCode: [4, 2],
    },
    {
        title: "Market Data Services for Global Risk System",
        agency: "Commodity Futures Trading Commission",
        stage: "Solicitation",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Peterson", "maxwang"],
        colorCode: [0, 2],
    },
    {
        title: "Audience Evaluation of engagement content and...",
        agency: "National Aeronautics and Space Agency",
        stage: "Canceled Justification",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Kavin"],
        colorCode: [2],
    },
    {
        title: "High Angular Precision perscope High Angular Precision perscope High Angular Precision perscope High Angular Precision perscope High Angular Precision perscope",
        agency: "National Aeronautics and Space Agency National Aeronautics and Space Agency National Aeronautics and Space Agency National Aeronautics and Space Agency National Aeronautics and Space Agency",
        stage: "Award Notice",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Peterson", "matthewkane"],
        colorCode: [0, 3],
    },
    {
        title: "MCAS Yuma Air Show",
        agency: "Dept of Defense",
        stage: "Canceled Sources Sought",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Kavin"],
        colorCode: [2],
    },
    {
        title: "Facilitation Services",
        agency: "National Aeronautics and Space Agency",
        stage: "Sale of Surplus Property",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
    },
    {
        title: "Market Data Services for Global Risk System",
        agency: "Commodity Futures Trading Commision",
        stage: "Solicitation",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["matthewkane", "john.jackson", "maxwang", "Kavin"],
        colorCode: [0, 4, 3, 5],
    },
    {
        title: "Audience Evaluation of engagement content and...",
        agency: "National Aeronautics and Space Agency",
        stage: "Canceled Justification",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Lura"],
        colorCode: [1],
    },
    {
        title: "Market Data Services for Global Risk System",
        agency: "Commodity Futures Trading Commission",
        stage: "Solicitation",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
    },
    {
        title: "Audience Evaluation of engagement content and...",
        agency: "National Aeronautics and Space Agency",
        stage: "Canceled Justification",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["john.jackson", "Kavin"],
        colorCode: [4, 2],
    },
    {
        title: "Market Data Services for Global Risk System",
        agency: "Commodity Futures Trading Commission",
        stage: "Solicitation",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Peterson", "maxwang"],
        colorCode: [0, 2],
    },
    {
        title: "Audience Evaluation of engagement content and...",
        agency: "National Aeronautics and Space Agency",
        stage: "Canceled Justification",
        publishedDate: "Apr 06, 2023",
        deadline: "May 1, 2023",
        viewedBy: ["Kavin"],
        colorCode: [2],
    },
];

export const statusList = [
    {
        color: "#00a9b2",
        bg: "#cefcff",
        label: "Interested",
    },
    {
        color: "#df8600",
        bg: "#ffe8c5",
        label: "Work in progress",
    },
    {
        color: "#3f7cff",
        bg: "#d3e1fe",
        label: "Submitted",
    },
    {
        color: "#8202e9",
        bg: "#ead1fe",
        label: "Cancelled",
    },
    {
        color: "#00b076",
        bg: "#bef1e0",
        label: "Won Opportunity",
    },
    {
        color: "#dd0000",
        bg: "#ffd7d7",
        label: "Lost Opportunity",
    },
    {
        color: "#202020",
        bg: "#d6d6d6",
        label: "Archived",
    },
];

export const notesStatusList = [
    {
        id: 2,
        value: "Important",
        bg: "#4cb592",
    },
    {
        id: 0,
        value: "Not Important",
        bg: "#f5c150",
    },
    {
        id: 1,
        value: "Needs Review",
        bg: "#4680ff",
    },
];

export const contractsTable = [
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn.b.petersclv@gmailcom",
        status: statusList[0],
        fav: true,
    },
    {
        title: "International space station deorbit capability",
        naics: "332311",
        fOrg: "1700- International space station deorbit capability",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[1],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[3],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[2],
        fav: true,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[1],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[5],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[0],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[4],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[5],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[0],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[4],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[5],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[0],
        fav: false,
    },
    {
        title: "Paint Booth",
        naics: "332311",
        fOrg: "1700- Dept of the Navy",
        publishedDate: "May 4 , 2023",
        responseDate: "May 15, 2023",
        poc: "jenelyn b peters clv @gmail com",
        status: statusList[4],
        fav: false,
    },
];

export const complianceStatus = [
    {
        bg: "#4cb592",
        label: "Full",
    },
    {
        bg: "#e14747",
        label: "Not Compliant",
    },
    {
        bg: "#4680ff",
        label: "Partial",
    },
];

export const riskStatus = [
    {
        bg: "#6bc2a5",
        label: "Low",
    },
    {
        bg: "#f5c150",
        label: "Medium",
    },
    {
        bg: "#e14747",
        label: "High",
    },
];

export const copilotTable = [
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[0],
        risk: riskStatus[0],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[1],
        risk: riskStatus[0],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[1],
        risk: riskStatus[1],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[0],
        risk: riskStatus[2],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[0],
        risk: riskStatus[1],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[1],
        risk: riskStatus[0],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "",
        compliance: complianceStatus[2],
        risk: riskStatus[2],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[0],
        risk: riskStatus[2],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[1],
        risk: riskStatus[0],
    },
    {
        requirements:
            "Offerors shall not self-identify proposal information that they believe rises to the level of a strength or significant strength.",
        document: "DRAFT-2-of-Technical-elements-5-12-23",
        rfpSection: "Section L: Part of Technical section",
        rfpSubsection: "Element 1: MH-60 Romeo and Sierra Trainer Operating System (OS) and Subsystems",
        compliance: complianceStatus[2],
        risk: riskStatus[1],
    },
];

export const statesList = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "American Samoa", abbreviation: "AS" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "District of Columbia", abbreviation: "DC" },
    { name: "Federated States of Micronesia", abbreviation: "FM" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Guam", abbreviation: "GU" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Marshall Islands", abbreviation: "MH" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Northern Mariana Islands", abbreviation: "MP" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Palau", abbreviation: "PW" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Puerto Rico", abbreviation: "PR" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virgin Islands", abbreviation: "VI" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
];
