/** @jsxImportSource @emotion/react */

import Drawer from "components/organisms/drawer/Drawer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import WritingAssistantConversation from "./WritingAssistantConversation";
import WritingAssistantInput from "./writing-assistant-input";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TOP_BANNER } from "const-values/Banner";

const WritingAssistantDrawer = ({ fullscreen }: { fullscreen: boolean }) => {
    const { assistantOpen } = useAppSelector((root) => root.copilotDrawer);
    const dispatch = useAppDispatch();
    const flags = useFlags();

    return (
        <Drawer
            fullScreen
            opened={assistantOpen}
            onClose={() => dispatch(toggleAssistant(false))}
            header={<div className="text-stone-900 text-base font-semibold self-center">Writing Assistant</div>}
            styles={[
                tw`top-[65px] z-[101] min-w-[455px] w-[35vw] max-w-[650px]`,
                flags.topBanner && { top: `calc(65px + ${TOP_BANNER.HEIGHT}px)` },
                fullscreen && tw`top-0`,
            ]}
            headerStyles={[tw`border-b border-gray-light shadow-expanded pb-4`]}
        >
            <WritingAssistantConversation />
            <WritingAssistantInput />
        </Drawer>
    );
};

export default WritingAssistantDrawer;
