/** @jsxImportSource @emotion/react */

import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { NotificationType, useNotification } from "context/notificationContext";
import { AnimatePresence, motion } from "framer-motion";
import { PiWarning } from "react-icons/pi";
import tw from "twin.macro";

const NotificationToast = () => {
    const { clearToast, notification } = useNotification();

    return (
        <>
            <AnimatePresence>
                <Toast.Root
                    onOpenChange={(open) => {
                        if (!open) clearToast();
                    }}
                    open={!!notification}
                    // forceMount
                    asChild
                    className="pointer-events-auto flex gap-4 justify-between items-center bg-white px-4 py-3 shadow-sharp-full rounded-lg"
                    css={[
                        NotificationType.Dark === notification?.variant && tw`text-white bg-gray-dark`,
                        NotificationType.Error === notification?.variant && tw`min-w-[520px]`,
                    ]}
                    duration={notification?.duration || 8000}
                >
                    <motion.li
                        initial={{
                            y: -100,
                            opacity: 0,
                        }}
                        animate={{
                            y: 0,
                            opacity: 1,
                            transition: { duration: 0.3 },
                        }}
                        exit={{
                            opacity: 0,
                            transition: { duration: 0.15 },
                        }}
                        layout
                        data-toast
                    >
                        <div style={{ display: "flex", gap: 14, alignItems: "center" }}>
                            {NotificationType.Dark !== notification?.variant && (
                                <div
                                    aria-hidden
                                    className="text-[15px] flex justify-center items-center min-w-[24px] w-6 h-6 rounded-full"
                                    css={[
                                        NotificationType.Success === notification?.variant &&
                                            tw`text-green-600 bg-green-100`,
                                        NotificationType.Error === notification?.variant && tw`text-red-600 bg-red-100`,
                                        NotificationType.Warning === notification?.variant &&
                                            tw`text-yellow-600 bg-amber-100`,
                                    ]}
                                >
                                    {notification?.variant === NotificationType.Success && <CheckIcon />}
                                    {notification?.variant === NotificationType.Error && <Cross2Icon />}
                                    {notification?.variant === NotificationType.Warning && <PiWarning />}
                                </div>
                            )}
                            <div className="flex flex-col gap-0.5">
                                <Toast.Title
                                    className="text-sm text-stone-800"
                                    css={[NotificationType.Dark === notification?.variant && tw`text-white`]}
                                >
                                    {notification?.title}
                                </Toast.Title>
                                <Toast.Description
                                    className="text-xs text-gray-500"
                                    css={[
                                        !notification?.title && tw`text-sm`,
                                        NotificationType.Dark === notification?.variant && tw`text-white`,
                                    ]}
                                >
                                    {notification?.msg}
                                </Toast.Description>
                            </div>
                        </div>
                        <Toast.Close
                            className="flex justify-center items-center rounded-full min-w-[28px] w-7 h-7 border border-gray-light duration-150 hover:bg-gray-100"
                            css={[NotificationType.Dark === notification?.variant && tw`hover:bg-stone-800`]}
                        >
                            <Cross2Icon className="text-sm" />
                        </Toast.Close>
                    </motion.li>
                </Toast.Root>
            </AnimatePresence>
            <Toast.Viewport
                className="fixed top-6 left-1/2 transform -translate-x-1/2 flex flex-col gap-[10px] min-w-[375px] max-w-[520px] m-0 list-none z-[99999999] outline-none"
                css={[NotificationType.Dark === notification?.variant && tw`min-w-fit w-fit`]}
            />
        </>
    );
};

export default NotificationToast;
