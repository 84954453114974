import { createPortal } from "react-dom";
import { BaseSelection, Editor, Path, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import ToggleButton from "./ToggleButton";
import Select from "./Select";
import Tooltip from "./Tooltip";
import styles from "./Toolbar.module.css";
import { ReactComponent as BoldIcon } from "../icons/bold.svg";
import { ReactComponent as ItalicIcon } from "../icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "../icons/underline.svg";
import { ReactComponent as StrikethroughIcon } from "../icons/strikethrough.svg";
import { toggleMark, topLevelPath } from "../utils";
import sendArrow from "../../../Assets/sendArrow.svg";
import { BlockType, CustomElement, TextBlock } from "../types";
import { HiArrowUturnLeft } from "react-icons/hi2";
import { HiOutlineSparkles, HiOutlineMenuAlt2 } from "react-icons/hi";
import { ImMagicWand } from "react-icons/im";
import { GiMicrophone } from "react-icons/gi";
import { AiOutlineCheck } from "react-icons/ai";
import { RiMenuAddFill } from "react-icons/ri";
import { TbArrowBackUp } from "react-icons/tb";
import { MdKeyboardArrowRight } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdSpellcheck, MdShortText } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect, useState, useCallback } from "react";
import {
    askAiAction,
    changeToneToggleAction,
    sendingUserResponse,
    resetEditorResponse,
    selectedTextAction,
    isSearchBarOpenAction,
    resetTryAgainAction,
    tryAgainAction,
    resetSelectedText,
    isTypingDoneAction,
    isTextAreaHasValueAction,
} from "store/reducers/aiReducerSlice";
import Typist from "react-typist";
import { nanoid } from "nanoid";
import TypingEffectAiWriting from "./TypingEffectAiWriting";
import { debounce } from "lodash";

let controllingScrollBehaviour = true;
let controllingScrollForMainToolBar = true;

export default function Toolbar() {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement | null>(null);
    const editor = useSlate();
    const [selectedArea, setSelectedArea] = useState<DOMRect | null>(null);
    const [editorSelection, setEditorSelection] = useState<BaseSelection>();
    const loader = useSelector((state: any) => state.aiReducer.loading);
    const scrollDiv = document.getElementById("scrollDiv");
    const editorRespons = useSelector((state: any) => state.aiReducer.editorResponse);
    const selectedText = useSelector((state: any) => state.aiReducer.selectedText);
    const isSearchBarOpen = useSelector((state: any) => state.aiReducer.isSearchBarOpen);
    const isExpanded = useSelector((state: any) => state.aiReducer.isExpanded);
    const type = getSelectedElementType(editor);
    const proseElement = document.getElementById("slateEditor");
    const marks = Editor.marks(editor);
    useEffect(() => {
        const el = ref.current;
        const { selection } = editor;
        setEditorSelection(selection);
        if (!el) {
            return;
        }
        if (
            !selection ||
            Range.includes(selection, [0]) || // If the selection overlap with the title, do not show the toolbar
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ""
        ) {
            el.removeAttribute("style");
            setSelectedArea(null);
            dispatch(isTextAreaHasValueAction(false));
            dispatch(resetSelectedText());
            dispatch(isSearchBarOpenAction(false));
            dispatch(resetEditorResponse());
            dispatch(changeToneToggleAction(false));
            controllingScrollBehaviour = true;
            controllingScrollForMainToolBar = true;
            return;
        }
        if (Editor.string(editor, selection)) {
            const selectedTextFromDiv = Editor.string(editor, selection);
            dispatch(selectedTextAction(selectedTextFromDiv));
        }

        const domSelection = window.getSelection();
        if (domSelection == null || domSelection.rangeCount === 0) {
            return;
        }

        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();
        if (rect.bottom > 0 && JSON.stringify(rect) !== JSON.stringify(selectedArea)) {
            if (!rect) {
                return;
            }
            if (scrollDiv) {
                setSelectedArea({
                    bottom: rect.bottom,
                    height: rect.height,
                    left: rect.left + scrollDiv.scrollLeft,
                    right: rect.right,
                    top: rect.top + scrollDiv.scrollTop,
                    width: rect.width,
                    x: rect.x,
                    y: rect.y,
                } as DOMRect);
            }
        }

        let selectedParagraph: HTMLElement | null = null;
        const paragraphTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "li"];
        for (const tag of paragraphTags) {
            selectedParagraph = domRange.startContainer.parentElement?.closest(tag) as HTMLElement;
            if (selectedParagraph) {
                break; // Found a matching tag, no need to continue searching
            }
        }
        if (!selectedParagraph) {
            return;
        }
        const paragraphRect = selectedParagraph.getBoundingClientRect();
        if (isSearchBarOpen && scrollDiv) {
            if (rect.bottom > 0 && JSON.stringify(rect) !== JSON.stringify(selectedArea)) {
                if (!rect) {
                    return;
                }
                setSelectedArea({
                    bottom: paragraphRect.bottom,
                    height: paragraphRect.height,
                    left: paragraphRect.left + scrollDiv.scrollLeft,
                    right: paragraphRect.right,
                    top: paragraphRect.top + scrollDiv.scrollTop,
                    width: paragraphRect.width,
                    x: paragraphRect.x,
                    y: paragraphRect.y,
                } as DOMRect);
            }
        }

        if (isSearchBarOpen && selectedParagraph && proseElement) {
            if (selectedArea && scrollDiv) {
                if (isExpanded) {
                    el.style.position = "absolute";
                    el.style.opacity = "1";
                    el.id = "searchBarToScroll";
                    el.style.width = isSearchBarOpen ? `${proseElement.offsetWidth - 20}px` : "100%";
                    el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 135}px`;
                    el.style.left = `${proseElement.offsetLeft + 16}px`;
                } else {
                    el.style.position = "absolute";
                    el.style.opacity = "1";
                    el.id = "searchBarToScroll";
                    el.style.width = isSearchBarOpen ? `${proseElement.offsetWidth - 20}px` : "100%";
                    el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 415}px`;
                    el.style.left = `${proseElement.offsetLeft + 16}px`;
                }
            }
        } else {
            if (selectedArea && scrollDiv) {
                if (isExpanded) {
                    const scrollTop = scrollDiv.scrollTop || window.pageYOffset;
                    const adjustedTop = selectedArea.bottom + scrollTop - el.offsetHeight;
                    el.style.position = "absolute";
                    el.style.opacity = "1";
                    el.style.width = "auto";
                    el.style.top = `${adjustedTop - 96}px`;
                    el.style.left = `${selectedArea.left + scrollDiv.scrollLeft - 272}px`;
                    // mainToolbarScroll();
                } else {
                    const scrollTop = scrollDiv.scrollTop || window.pageYOffset;
                    const adjustedTop = selectedArea.bottom + scrollTop - el.offsetHeight;
                    el.style.position = "absolute";
                    el.style.opacity = "1";
                    el.style.width = "auto";
                    el.style.top = `${adjustedTop - 375}px`;
                    el.style.left = `${selectedArea.left + scrollDiv.scrollLeft - 310}px`;

                    // mainToolbarScroll();
                }
            }
        }
    }, [isSearchBarOpen, editor.selection, editor, selectedArea, dispatch, scrollDiv, isExpanded, proseElement, type]);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const toggleSearch = () => {
        const element = document.getElementById("searchBarToScroll");
        setTimeout(() => {
            if (controllingScrollBehaviour && element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
                controllingScrollBehaviour = false;
            }
        }, 300);
        dispatch(isSearchBarOpenAction(true));
        dispatch(resetEditorResponse());
        dispatch(askAiAction(true));
    };

    useEffect(() => {
        if (loader) {
            dispatch(isTypingDoneAction(false));
        }
    }, [loader, dispatch]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
            if (editorSelection) {
                Transforms.select(editor, editorSelection);
            }
            Editor.addMark(editor, "color", "#00000");

            const sel = editor.selection;

            Transforms.deselect(editor);

            // setTimeout(() => {
            //@ts-ignore
            Transforms.select(editor, sel);
            ReactEditor.focus(editor);
            if (isSearchBarOpen) {
                dispatch(isSearchBarOpenAction(true));
            }
        }
        dispatch(isTextAreaHasValueAction(false));
    };

    const handleClick = () => {
        handleSubmit();
        dispatch(isTextAreaHasValueAction(false));
    };

    const handleSubmit = () => {
        if (textareaRef.current) {
            const userInput = textareaRef.current.value.trim();
            if (userInput) {
                const obj = {
                    actionType: "user_request",
                    text: selectedText, // Replace this with the actual selectedText
                    user_request: userInput,
                };
                // @ts-ignore
                dispatch(sendingUserResponse(obj));
                dispatch(tryAgainAction(obj));
            }
        }
    };

    return createPortal(
        <div
            ref={ref}
            className={styles.toolbar}
            onMouseDown={(e) => {
                const target = e.target as HTMLElement;
                if (!target.classList.contains("textarea")) {
                    e.preventDefault();
                }
            }}

            // style={{ width: isSearchBarOpen ? "50%" : "auto" }}
        >
            {isSearchBarOpen && (
                <div>
                    <div className=" px-2 ">
                        {loader && (
                            <div className="flex items-center py-1 h-[52px]  gap-2 items-center font-medium">
                                <div>
                                    <HiOutlineSparkles size={18} color="#A782C3" />
                                </div>
                                <span className="text-sm  text-[#A782C3]">AI is writing...</span>
                                <div
                                    className="p-2"
                                    style={{ minWidth: `${proseElement && proseElement.offsetWidth - 205}px` }}
                                >
                                    <TypingEffectAiWriting />
                                </div>
                                <div className="">
                                    <img
                                        src={sendArrow}
                                        alt=""
                                        className=""
                                        style={{ userSelect: "none", height: "25px", width: "25px" }}
                                    />
                                </div>
                            </div>
                        )}
                        {!loader && (
                            <div className="">
                                <div id="typist">
                                    {editorRespons.response !== "" && (
                                        <div className="py-2" style={{ fontSize: "14px", color: "#37352F" }}>
                                            <Typist
                                                onTypingDone={() => {
                                                    dispatch(isTypingDoneAction(true));
                                                }}
                                                avgTypingDelay={0.001}
                                                stdTypingDelay={1}
                                            >
                                                {editorRespons?.response}
                                            </Typist>
                                        </div>
                                    )}
                                </div>

                                <div id="seachBar" className="flex items-center   py-2 justify-between">
                                    <div className={`flex items-center gap-2  py-1 w-full ${styles.textAreaContainer}`}>
                                        <HiOutlineSparkles size={18} color="#A782C3" />
                                        <textarea
                                            id="askAiToggle"
                                            ref={textareaRef}
                                            // defaultValue={isTypingDone ? editorRespons.response : ""}
                                            placeholder="Ask AI to edit or generate…"
                                            style={{ minWidth: `${proseElement && proseElement.offsetWidth - 95}px` }}
                                            onChange={(e) =>
                                                debounce(() => {
                                                    const textarea = e.target;
                                                    textarea.style.height = "auto";
                                                    const newHeight = Math.max(27, textarea.scrollHeight);
                                                    textarea.style.height = `${newHeight}px`;
                                                    if (textarea.value !== "") {
                                                        dispatch(isTextAreaHasValueAction(true));
                                                    } else {
                                                        dispatch(isTextAreaHasValueAction(false));
                                                    }
                                                }, 100)
                                            }
                                            rows={Math.max(1, editorRespons.response.split("\n").length)}
                                            onMouseLeave={() => {
                                                if (editorSelection) {
                                                    Transforms.select(editor, editorSelection);
                                                }
                                                Editor.addMark(editor, "color", "#00000");
                                                const sel = editor.selection;
                                                Transforms.deselect(editor);
                                                // setTimeout(() => {
                                                //@ts-ignore
                                                Transforms.select(editor, sel);
                                                ReactEditor.focus(editor);
                                                if (isSearchBarOpen) {
                                                    dispatch(isSearchBarOpenAction(true));
                                                }
                                                // }, 1000);
                                            }}
                                            onKeyDown={handleKeyDown}
                                            className={`${styles.aiTextArea} h-[27px] text-base border-none bg-transparent p-0  textarea`}
                                        ></textarea>
                                    </div>

                                    <div>
                                        <img
                                            src={sendArrow}
                                            alt=""
                                            className="mt-1"
                                            style={{ userSelect: "none", height: "25px", width: "25px" }}
                                            onClick={handleClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!isSearchBarOpen && (
                <>
                    <div className={styles.group}>
                        <div className="popover-container">
                            {" "}
                            <button onClick={toggleSearch} className="popover-trigger flex justify-center items-center">
                                <div className="flex items-center ">
                                    <div style={{ padding: "5px" }}>
                                        <HiOutlineSparkles color="#A782C3" />
                                    </div>
                                    <p className="text-[#A782C3]" style={{ fontSize: "0.875em" }}>
                                        Ask AI
                                    </p>
                                </div>
                            </button>
                        </div>
                        <div className={styles.separator} />
                    </div>
                    {type && (
                        <>
                            <div className={styles.tag_selector}>
                                {/* <div className={styles.separator} /> */}

                                <Select
                                    defaultValue={BlockType.Paragraph}
                                    value={type}
                                    items={[
                                        { label: "Normal text", value: BlockType.Paragraph },
                                        { label: "Heading 1", value: BlockType.H1 },
                                        { label: "Heading 2", value: BlockType.H2 },
                                        { label: "Heading 3", value: BlockType.H3 },
                                        { label: "Bulleted list", value: BlockType.BulletedList },
                                        { label: "To-do list", value: BlockType.ToDo },
                                    ]}
                                    onValueChange={(value: string) => {
                                        if (editor.selection == null) {
                                            return;
                                        }

                                        // TODO: Update Select typings to infer value type from items
                                        const type = value as TextBlock;
                                        Transforms.setNodes<CustomElement>(
                                            editor,
                                            {
                                                type,
                                            },
                                            {
                                                at: [editor.selection.anchor.path[0]],
                                            }
                                        );
                                    }}
                                />
                            </div>
                            <div className={styles.separator} />
                        </>
                    )}

                    <div className={styles.group}>
                        <Tooltip content="Toggle Bold">
                            <ToggleButton
                                ariaLabel="Toggle Bold"
                                onPointerDown={(e) => e.preventDefault()}
                                onClick={() => toggleMark(editor, "bold")}
                                isSquare
                                isActive={marks ? marks["bold"] === true : false}
                            >
                                <BoldIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip content="Toggle Italic">
                            <ToggleButton
                                ariaLabel="Toggle Italic"
                                onPointerDown={(e) => e.preventDefault()}
                                onClick={() => toggleMark(editor, "italic")}
                                isSquare
                                isActive={marks ? marks["italic"] === true : false}
                            >
                                <ItalicIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip content="Toggle Underline">
                            <ToggleButton
                                ariaLabel="Toggle Underline"
                                onPointerDown={(e) => e.preventDefault()}
                                onClick={() => toggleMark(editor, "underline")}
                                isSquare
                                isActive={marks ? marks["underline"] === true : false}
                            >
                                <UnderlineIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip content="Toggle Strikethrough">
                            <ToggleButton
                                ariaLabel="Toggle Strikethrough"
                                onPointerDown={(e) => e.preventDefault()}
                                onClick={() => toggleMark(editor, "strikeThrough")}
                                isSquare
                                isActive={marks ? marks["strikeThrough"] === true : false}
                            >
                                <StrikethroughIcon />
                            </ToggleButton>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>,
        scrollDiv ? scrollDiv : document.body
    );
}

export function AiToolbar() {
    const ref = useRef<HTMLDivElement | null>(null);
    const editor = useSlate();
    const [selectedArea, setSelectedAre] = useState<DOMRect | null>(null);
    const scrollDiv = document.getElementById("scrollDiv");
    const isExpanded = useSelector((state: any) => state.aiReducer.isExpanded);
    const [activeItem, setActiveItem] = useState(0);
    const selectedText = useSelector((state: any) => state.aiReducer.selectedText);
    const dispatch = useDispatch();
    const proseElement = document.getElementById("slateEditor");

    const handleOpnDrpDwon = () => {
        const el = ref.current;
        const { selection } = editor;
        if (!el) {
            return;
        }

        if (
            !selection ||
            Range.includes(selection, [0]) || // If the selection overlap with the title, do not show the toolbar
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ""
        ) {
            setSelectedAre(null);
            dispatch(askAiAction(false));
            el.removeAttribute("style");
            return;
        }

        const domSelection = window.getSelection();
        if (domSelection == null || domSelection.rangeCount === 0) {
            return;
        }
        const contentAbove = document.getElementById("seachBar");
        const contentAboveHeight = contentAbove && contentAbove.offsetHeight;
        const domRange = domSelection.getRangeAt(0);
        let selectedParagraph: HTMLElement | null = null;
        const paragraphTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "li"];
        for (const tag of paragraphTags) {
            selectedParagraph = domRange.startContainer.parentElement?.closest(tag) as HTMLElement;
            if (selectedParagraph) {
                break; // Found a matching tag, no need to continue searching
            }
        }
        if (!selectedParagraph) {
            return;
        }
        const paragraphRect = selectedParagraph.getBoundingClientRect();

        if (paragraphRect.bottom > 0 && JSON.stringify(paragraphRect) !== JSON.stringify(selectedArea)) {
            if (!paragraphRect) {
                return;
            }
            if (scrollDiv) {
                setSelectedAre({
                    bottom: paragraphRect.bottom,
                    height: paragraphRect.height,
                    left: paragraphRect.left + scrollDiv.scrollLeft,
                    right: paragraphRect.right,
                    top: paragraphRect.top + scrollDiv.scrollTop,
                    width: paragraphRect.width,
                    x: paragraphRect.x,
                    y: paragraphRect.y,
                } as DOMRect);
            }
        }

        if (selectedArea) {
            if (!selectedParagraph) {
                return;
            }
            if (selectedArea) {
                el.style.position = "absolute";
                el.style.opacity = "1";
            }
            if (contentAboveHeight && scrollDiv && proseElement) {
                if (isExpanded) {
                    el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 120}px`;
                    el.style.left = `${proseElement.offsetLeft + 16}px`;
                    el.style.minWidth = "300px";
                } else {
                    el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 400}px`;
                    el.style.left = `${proseElement.offsetLeft + 16}px`;
                    el.style.minWidth = "300px";
                }
            }
        }
    };

    useEffect(() => {
        handleOpnDrpDwon();
    }, [editor.selection, selectedArea, ref, dispatch, proseElement]);

    useEffect(() => {
        const handleMouseEnter = () => {
            dispatch(changeToneToggleAction(true));
        };
        const handleMouseLeave = () => {
            dispatch(changeToneToggleAction(false));
        };
        const element = document.getElementById("changetone");
        const element2 = document.getElementById("changetone");
        element && element.addEventListener("mouseenter", handleMouseEnter);
        element2 && element2.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            element && element.removeEventListener("mouseenter", handleMouseEnter);
            element2 && element2.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);
    const menuItems = [
        { id: "item1", icon: <ImMagicWand color="#A782C3" />, title: "Improve writing" },
        { id: "item2", icon: <MdSpellcheck color="#A782C3" />, title: "Fix spelling & grammar" },
        { id: "item3", icon: <MdShortText color="#A782C3" />, title: "Make shorter" },
        { id: "item4", icon: <HiOutlineMenuAlt2 color="#A782C3" />, title: "Make longer" },
        { id: "changetone", icon: <GiMicrophone color="#A782C3" />, title: "Change tone" },
        { id: "item5", icon: <HiOutlineSparkles color="#A782C3" />, title: "Explain" },
    ];

    const handleMenuItemClick = (id: string) => {
        let obj;

        switch (id) {
            case "item1":
                obj = { actionType: "action", text: selectedText, action: "improve_writing" };
                break;
            case "item2":
                obj = { actionType: "action", text: selectedText, action: "fix" };
                break;
            case "item3":
                obj = { actionType: "action", text: selectedText, action: "shorten" };
                break;
            case "item4":
                obj = { actionType: "action", text: selectedText, action: "expand" };
                break;
            case "item5":
                obj = { actionType: "explain", text: selectedText };
                break;
            default:
                return;
        }

        if (obj) {
            // @ts-ignore
            dispatch(sendingUserResponse(obj));
            dispatch(tryAgainAction(obj));
        }
    };
    return createPortal(
        <div
            ref={ref}
            className={styles.Aitoolbar}
            onMouseDown={(e) => {
                e.preventDefault();
            }}
        >
            <span className="text-xs p-2 text-[#37352FA6]">Edit or review selection</span>
            <div>
                {menuItems.map((menuItem, index) => (
                    <div
                        key={menuItem.id}
                        id={menuItem.id}
                        onMouseEnter={() => setActiveItem(index)}
                        className={`flex px-2 my-1 gap-2 items-center justify-between leading-120 w-full select-none min-h-[28px] text-base ${
                            index === activeItem ? styles.activeClass : "inactiveClass"
                        }`}
                        onClick={() => {
                            handleMenuItemClick(menuItem.id);
                        }}
                    >
                        <div className="flex my-1 gap-2 items-center">
                            <span> {menuItem.icon}</span>
                            <h5 style={{ fontSize: "0.875em" }}>{menuItem.title}</h5>
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                transform: "rotate(180deg) scaleX(-1) scaleY(1)",
                            }}
                        >
                            {activeItem === index && activeItem !== 4 && (
                                <HiArrowUturnLeft color="#37352F80" size={12} />
                            )}
                            {activeItem === index && activeItem === 4 && (
                                <MdKeyboardArrowRight color="#37352F80" size={12} />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>,
        scrollDiv ? scrollDiv : document.body
    );
}

export const ChangeToneDropDown = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const editor = useSlate();
    const [selectedArea, setSelectedAre] = useState<DOMRect | null>(null);
    const selectedText = useSelector((state: any) => state.aiReducer.selectedText);
    const isExpanded = useSelector((state: any) => state.aiReducer.isExpanded);
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(0);
    const scrollDiv = document.getElementById("scrollDiv");
    const proseElement = document.getElementById("slateEditor");
    useEffect(() => {
        const el = ref.current;
        const { selection } = editor;
        if (!el) {
            return;
        }
        if (
            !selection ||
            Range.includes(selection, [0]) || // If the selection overlap with the title, do not show the toolbar
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ""
        ) {
            setSelectedAre(null);
            dispatch(askAiAction(false));
            el.removeAttribute("style");
            return;
        }

        const domSelection = window.getSelection();
        if (domSelection == null || domSelection.rangeCount === 0) {
            return;
        }
        const domRange = domSelection.getRangeAt(0);
        let selectedParagraph: HTMLElement | null = null;
        const paragraphTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "li"];
        for (const tag of paragraphTags) {
            selectedParagraph = domRange.startContainer.parentElement?.closest(tag) as HTMLElement;
            if (selectedParagraph) {
                break; // Found a matching tag, no need to continue searching
            }
        }
        if (!selectedParagraph) {
            return;
        }
        const paragraphRect = selectedParagraph.getBoundingClientRect();
        if (paragraphRect.bottom > 0 && JSON.stringify(paragraphRect) !== JSON.stringify(selectedArea)) {
            if (!paragraphRect) {
                return;
            }
            if (scrollDiv) {
                setSelectedAre({
                    bottom: paragraphRect.bottom,
                    height: paragraphRect.height,
                    left: paragraphRect.left + scrollDiv.scrollLeft,
                    right: paragraphRect.right,
                    top: paragraphRect.top + scrollDiv.scrollTop,
                    width: paragraphRect.width,
                    x: paragraphRect.x,
                    y: paragraphRect.y,
                } as DOMRect);
            }
        }
        if (selectedArea && scrollDiv && proseElement) {
            if (!selectedParagraph) {
                return;
            }
            el.style.position = "absolute";
            el.style.opacity = "1";
            if (isExpanded) {
                el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 160}px`;
                el.style.left = `${proseElement.offsetLeft + 310}px`;
            } else {
                el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 440}px`;
                el.style.left = `${proseElement.offsetLeft + 310}px`;
            }
        }
    }, [editor.selection, scrollDiv, editor, selectedArea, ref, dispatch, isExpanded, proseElement]);

    useEffect(() => {
        const handleMouseEnter = () => {
            dispatch(changeToneToggleAction(true));
        };
        const handleMouseLeave = () => {
            dispatch(changeToneToggleAction(false));
        };
        const element = document.getElementById("changetoneClose");
        const element2 = document.getElementById("changetoneClose");
        element && element.addEventListener("mouseenter", handleMouseEnter);
        element2 && element2.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            element && element.removeEventListener("mouseenter", handleMouseEnter);
            element2 && element2.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    const changeToneDropDown = [
        "Professional",
        "Concise",
        "Official",
        "Confident",
        "Persuasive",
        "Solution-Oriented",
        "Customer-Centric",
        "Active Voice",
    ];

    const handleMenuItemClick = (item: string) => {
        let obj;

        switch (item) {
            case "Professional":
                obj = { actionType: "tone", text: selectedText, tone: "professional" };
                break;
            case "Concise":
                obj = { actionType: "tone", text: selectedText, tone: "concise" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Official":
                obj = { actionType: "tone", text: selectedText, tone: "official" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Confident":
                obj = { actionType: "tone", text: selectedText, tone: "confident" };
                break;
            case "Persuasive":
                obj = { actionType: "tone", text: selectedText, tone: "persuasive" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Solution-Oriented":
                obj = { actionType: "tone", text: selectedText, tone: "solution" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Customer-Centric":
                obj = { actionType: "tone", text: selectedText, tone: "customer" };
                dispatch(changeToneToggleAction(false));
                break;
            case "Active Voice":
                obj = { actionType: "tone", text: selectedText, tone: "active_voice" };
                dispatch(changeToneToggleAction(false));
                break;
            default:
                dispatch(changeToneToggleAction(false));
                break;
        }

        if (obj) {
            // @ts-ignore
            dispatch(sendingUserResponse(obj));
            dispatch(tryAgainAction(obj));
        }
    };

    return createPortal(
        <div
            ref={ref}
            id="changetoneClose"
            onMouseDown={(e) => {
                // prevent toolbar from taking focus away from editor
                e.preventDefault();
            }}
            className={styles.changeTonDropDown}
        >
            {changeToneDropDown.map((item, index) => (
                <div
                    onMouseEnter={() => setActiveItem(index)}
                    className={`flex px-2 mt-[0.2rem] gap-2 items-center justify-between leading-120 w-full select-none min-h-[28px] text-base ${
                        index === activeItem ? styles.activeClass : "inactiveClass"
                    }`}
                    onClick={() => {
                        handleMenuItemClick(item);
                    }}
                >
                    <div className="flex my-1 gap-1 items-center">
                        <h5 style={{ fontSize: "0.875em" }}>{item}</h5>
                    </div>
                    <div
                        style={{
                            display: "inline-block",
                            transform: "rotate(180deg) scaleX(-1) scaleY(1)",
                        }}
                    >
                        {activeItem === index && <HiArrowUturnLeft color="#37352F80" size={12} />}
                    </div>
                </div>
            ))}
        </div>,
        scrollDiv ? scrollDiv : document.body
    );
};

export function ReplaceText() {
    const ref = useRef<HTMLDivElement | null>(null);
    const editor = useSlate();
    const [selectedArea, setSelectedAre] = useState<DOMRect | null>(null);
    const editorRespons = useSelector((state: any) => state.aiReducer.editorResponse);
    const lastActionState = useSelector((state: any) => state.aiReducer.lastActionState);
    const isExpanded = useSelector((state: any) => state.aiReducer.isExpanded);
    const [activeItem, setActiveItem] = useState(0);
    const scrollDiv = document.getElementById("scrollDiv");
    const proseElement = document.getElementById("slateEditor");
    const dispatch = useDispatch();
    useEffect(() => {
        const el = ref.current;
        const { selection } = editor;

        if (!el) {
            return;
        }

        if (
            !selection ||
            Range.includes(selection, [0]) || // If the selection overlap with the title, do not show the toolbar
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ""
        ) {
            dispatch(askAiAction(false));
            el.removeAttribute("style");
            return;
        }

        const domSelection = window.getSelection();
        if (domSelection == null || domSelection.rangeCount === 0) {
            return;
        }
        const contentAbove = document.getElementById("typist");
        const contentAboveSearchBar = document.getElementById("seachBar");
        const contentAboveHeight = contentAbove && contentAbove.offsetHeight;
        const contentAboveSearchBarHeight = contentAboveSearchBar && contentAboveSearchBar.offsetHeight;
        const domRange = domSelection.getRangeAt(0);
        let selectedParagraph: HTMLElement | null = null;
        const paragraphTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "li"];
        for (const tag of paragraphTags) {
            selectedParagraph = domRange.startContainer.parentElement?.closest(tag) as HTMLElement;
            if (selectedParagraph) {
                break; // Found a matching tag, no need to continue searching
            }
        }
        if (!selectedParagraph) {
            return;
        }
        const paragraphRect = selectedParagraph.getBoundingClientRect();
        if (paragraphRect.bottom > 0 && JSON.stringify(paragraphRect) !== JSON.stringify(selectedArea)) {
            if (!paragraphRect) {
                return;
            }
            if (scrollDiv) {
                setSelectedAre({
                    bottom: paragraphRect.bottom,
                    height: paragraphRect.height,
                    left: paragraphRect.left + scrollDiv.scrollLeft,
                    right: paragraphRect.right,
                    top: paragraphRect.top + scrollDiv.scrollTop,
                    width: paragraphRect.width,
                    x: paragraphRect.x,
                    y: paragraphRect.y,
                } as DOMRect);
            }
        }
        if (selectedArea && scrollDiv && proseElement) {
            el.style.position = "absolute";
            el.style.opacity = "1";
            if (isExpanded) {
                el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 120}px`;
                el.style.left = `${proseElement.offsetLeft + 16}px`;
            } else {
                el.style.top = `${selectedArea.top + selectedParagraph.offsetHeight - 400}px`;
                el.style.left = `${proseElement.offsetLeft + 16}px`;
            }
            if (contentAboveHeight && scrollDiv) {
                const marginTop = contentAboveHeight + 80;
                const currentMarginTop = parseInt(el.style.marginTop) || 0;

                if (marginTop !== currentMarginTop) {
                    el.style.marginTop = `${marginTop}px`;

                    // scrollDiv.scrollTo(0, scrollDiv.scrollTop + (marginTop - currentMarginTop));
                }
            } else if (contentAboveSearchBarHeight && scrollDiv) {
                el.style.top = `${selectedArea.bottom + scrollDiv.scrollTop}px`;
                el.style.left = `${selectedArea.left + scrollDiv.scrollLeft}px`;

                const marginTop = contentAboveSearchBarHeight + 10;
                const currentMarginTop = parseInt(el.style.marginTop) || 0;

                if (marginTop !== currentMarginTop) {
                    el.style.marginTop = `${marginTop}px`;

                    scrollDiv.scrollTo(0, scrollDiv.scrollTop + (marginTop - currentMarginTop));
                }
            }
        }
    });

    const menuItems = [
        {
            id: "replace-selection",
            title: "Replace Selection",
            icon: <AiOutlineCheck color="#A782C3" />,
        },
        {
            id: "insert-below",
            title: "Insert Below",
            icon: <RiMenuAddFill color="#A782C3" />,
        },
        {
            id: "try-again",
            title: "Try Again",
            icon: <TbArrowBackUp color="#A782C3" />,
        },
        {
            id: "discard",
            title: "Discard",
            icon: <RiDeleteBin6Line color="#A782C3" />,
        },
    ];
    const handleInsertBelow = useCallback(() => {
        const selection = editor.selection;

        if (!selection) {
            return;
        }

        // Get the selected node
        const [start] = Range.edges(selection);
        const selectedNode = Editor.node(editor, start.path);

        if (!selectedNode) {
            return;
        }

        const activeElement = editor.children.find((x, index) => selectedNode[1][0] === index) as
            | CustomElement
            | undefined;
        if (!activeElement) {
            return;
        }
        const path = [ReactEditor.findPath(editor, activeElement)[0] + 1];
        // Insert the node at the calculated path
        const value = editorRespons?.response;
        const paragraphBlock: CustomElement = {
            type: BlockType.Paragraph,
            children: [{ text: value }],
            id: nanoid(),
        };

        Transforms.insertNodes(editor, paragraphBlock, {
            at: path,
        });

        // Defer selection to be able to focus the element we just inserted
        setTimeout(() => {
            ReactEditor.focus(editor);
            Transforms.select(editor, {
                anchor: { path: [path[0], 0], offset: 0 },
                focus: { path: [path[0], 0], offset: 0 },
            });
        }, 0);
    }, []);

    return createPortal(
        <div
            ref={ref}
            className={styles.replaceText}
            onMouseDown={(e) => {
                // prevent toolbar from taking focus away from editor
                e.preventDefault();
            }}
        >
            <div>
                {menuItems.map((menuItem, index) => (
                    <div
                        key={menuItem.id}
                        id={menuItem.id}
                        onMouseEnter={() => setActiveItem(index)}
                        className={`flex px-2 my-1 gap-2 items-center justify-between leading-120 w-full select-none min-h-[28px] text-base ${
                            index === activeItem ? styles.activeClass : "inactiveClass"
                        }`}
                        onClick={() => {
                            switch (menuItem.id) {
                                case "replace-selection": {
                                    if (editor.selection == null) {
                                        return;
                                    }
                                    const value = editorRespons?.response;
                                    const newContent = value;
                                    Transforms.delete(editor);
                                    Transforms.insertText(editor, newContent);
                                    dispatch(resetTryAgainAction());
                                    break;
                                }
                                case "insert-below": {
                                    handleInsertBelow();
                                    dispatch(resetEditorResponse());
                                    dispatch(resetTryAgainAction());
                                    break;
                                }
                                case "try-again": {
                                    if (lastActionState) {
                                        const tryAgainObj = { payloadAction: "try-again", ...lastActionState };
                                        // @ts-ignore
                                        dispatch(sendingUserResponse(tryAgainObj));
                                    }
                                    break;
                                }
                                case "discard": {
                                    // @ts-ignore
                                    dispatch(isSearchBarOpenAction(false));
                                    dispatch(changeToneToggleAction(false));
                                    dispatch(askAiAction(false));
                                    dispatch(resetEditorResponse());
                                    dispatch(resetTryAgainAction());
                                    break;
                                }
                            }
                        }}
                    >
                        <div className="flex my-1 gap-2 items-center">
                            <span> {menuItem.icon}</span>
                            <h5 style={{ fontSize: "0.875em" }}>{menuItem.title}</h5>
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                transform: "rotate(180deg) scaleX(-1) scaleY(1)",
                            }}
                        >
                            {activeItem === index && <HiArrowUturnLeft color="#37352F80" size={12} />}
                        </div>
                    </div>
                ))}
            </div>
        </div>,
        scrollDiv ? scrollDiv : document.body
    );
}

function getSelectedElementType(editor: Editor): TextBlock | null {
    if (editor.selection == null) {
        return null;
    }

    // If selection overlap on multiple top element, return null
    if (Path.compare(topLevelPath(editor.selection.anchor.path), topLevelPath(editor.selection.focus.path)) !== 0) {
        return null;
    }

    const element = editor.children[editor.selection.anchor.path[0]] as CustomElement;

    if (!isTextElementType(element.type)) {
        return null;
    }

    return element.type;
}

function isTextElementType(type: string): type is TextBlock {
    return (
        type === BlockType.H1 ||
        type === BlockType.H2 ||
        type === BlockType.H3 ||
        type === BlockType.Paragraph ||
        type === BlockType.BulletedList ||
        type === BlockType.ToDo
    );
}
