/** @jsxImportSource @emotion/react */

import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw, { theme } from "twin.macro";
import { AnimationControls, motion, useAnimation } from "framer-motion";
import { HTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import { useMutation, useStorage } from "liveblocks.config";
import { Storage as ImmutableStorage } from "../CopilotSchemaImmutableTypes";
import { DEFAULT_STEP_TO_META } from "./contants";
import { Step, StepStatus, StepType } from "types/ProjectProgress";
import { useSetStatus } from "./hooks";
import { HiArrowUturnRight, HiCheckCircle, HiOutlineCheckCircle } from "react-icons/hi2";
import { Button } from "components/editor/components";
import { Storage } from "../CopilotSchemaTypes";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useConfettiCannon } from "hook/useConfettiCannon";
import AnimatedCheck from "components/atoms/animated-check/AnimatedCheck";
import { BsPatchQuestion } from "react-icons/bs";
import { Link } from "react-router-dom";
import classNames from "classnames";

const ProjectProgressContent = ({ className }: HTMLAttributes<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const progress = useStorage((root) => root.progress as ImmutableStorage["progress"]);
    const [confettiActive, setConfettiActive] = useState(false);
    const { fullscreen } = useAppSelector((root) => root.copilot);

    const allStepsCompleted = !!progress?.steps.every(
        (step) => step.status === StepStatus.Completed || step.status === StepStatus.Skipped
    );
    useConfettiCannon({ rain: confettiActive });

    const setProjectCompleted = useMutation(({ storage }) => {
        const progress = storage.get("progress") as Storage["progress"];
        progress?.set("completed", true);
        setConfettiActive(true);
    }, []);

    useEffect(
        () => () => {
            if (!fullscreen) {
                setConfettiActive(false);
                // dispatch(toggleProgress(false));
            }
        },
        [dispatch, fullscreen]
    );

    return (
        <div
            className={classNames([
                "flex flex-col items-center flex-1 px-4 py-2 max-h-[775px] overflow-y-auto",
                className,
            ])}
        >
            {progress?.steps.map((step, idx) => (
                <ProgressStep
                    shouldExtend={!!step.status && !!progress?.steps[idx + 1]?.status}
                    completed={progress.completed}
                    key={idx}
                    step={step}
                    isLast={idx === progress.steps.length - 1}
                />
            ))}
            {(allStepsCompleted || progress?.completed) && (
                <Button
                    disabled={!!progress?.completed}
                    onClick={() => setProjectCompleted()}
                    variant="primary"
                    size="md"
                    className="w-full"
                >
                    Complete{!!progress?.completed && "d"}
                </Button>
            )}
        </div>
    );
};

const ProgressStep = ({
    isLast,
    step,
    completed,
    shouldExtend,
}: PropsWithChildren<{ isLast: boolean; step: Step; completed: boolean; shouldExtend: boolean }>) => {
    const line = useAnimation();
    const circle = useAnimation();
    const setStatus = useSetStatus(step);

    useEffect(() => {
        if (step.status === StepStatus.Completed) {
            line.start("start");
            circle.start("start");
        } else if (step.status === StepStatus.Skipped) {
            line.start("start");
            circle.start("skipped");
        } else if (step.status === StepStatus.Ready) {
            circle.start("ready");
        } else if (!step.status) {
            line.start("initial");
            circle.start("initial");
        }

        if (shouldExtend) {
            line.start("start");
        } else line.start("initial");
    }, [circle, shouldExtend, line, step.status]);

    return (
        <div className="group flex w-full flex-1 gap-2">
            <div className="flex flex-col items-center" css={[completed && tw`pointer-events-none`]}>
                <Circle
                    circle={circle}
                    status={step.status}
                    onClick={() => setStatus(step.status === StepStatus.Completed ? null : StepStatus.Completed)}
                />
                {!isLast && <Line line={line} />}
            </div>
            <div className="flex-1 pb-3">
                <div className="flex flex-row gap-2 justify-between items-center">
                    {step.type === StepType.Default && (
                        <div className="flex-1 flex flex-col gap-1 pt-0.5">
                            <div className="flex flex-row items-center gap-1">
                                <div className="text-sm font-bold">{DEFAULT_STEP_TO_META[step.step].name}</div>
                                {DEFAULT_STEP_TO_META[step.step].arcade && (
                                    <Link
                                        className="banner-link"
                                        to={{
                                            pathname: "/dashboard/help",
                                            hash: DEFAULT_STEP_TO_META[step.step].arcade,
                                        }}
                                        target="_blank"
                                    >
                                        {" "}
                                        <BsPatchQuestion className="text-action text-sm duration-100 hover:text-action-hover" />
                                    </Link>
                                )}
                            </div>
                            <div className="text-xs text-gray-500">{DEFAULT_STEP_TO_META[step.step].description}</div>
                        </div>
                    )}
                    {!completed && (
                        <div
                            className="flex flex-row items-center"
                            css={[
                                (step.status !== StepStatus.Ready || !step.status) &&
                                    tw`opacity-0 duration-100 group-hover:opacity-100`,
                            ]}
                        >
                            <Tooltip content={step.status === StepStatus.Skipped ? "Undo" : "Skip"}>
                                <button
                                    onClick={() =>
                                        setStatus(step.status === StepStatus.Skipped ? null : StepStatus.Skipped)
                                    }
                                    className="group/undo p-1.5 duration-150 rounded-md text-stone-700 hover:text-action hover:bg-zinc-100"
                                >
                                    <HiArrowUturnRight
                                        className="w-4 h-4 border border-stone-700 p-[3px] stroke-[0.9] rounded-full group-hover/undo:border-action"
                                        css={[
                                            step.status === StepStatus.Skipped &&
                                                tw`bg-action border-action text-white`,
                                        ]}
                                    />
                                </button>
                            </Tooltip>
                            <Tooltip content={step.status === StepStatus.Completed ? "Undo" : "Completed"}>
                                <button
                                    onClick={() =>
                                        setStatus(step.status === StepStatus.Completed ? null : StepStatus.Completed)
                                    }
                                    className="p-1 duration-150 rounded-md text-stone-700 hover:text-action hover:bg-zinc-100"
                                >
                                    {step.status === StepStatus.Completed ? (
                                        <HiCheckCircle className="w-5 h-5 text-action stroke-[0.5]" />
                                    ) : (
                                        <HiOutlineCheckCircle className="w-5 h-5 stroke-[1.2]" />
                                    )}
                                </button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const Line = ({ line }: { line: AnimationControls }) => {
    return (
        <div className="bg-gray-300 w-0.5 flex-1 h-full">
            <motion.div
                style={{ height: "100%" }}
                initial="initial"
                variants={{
                    start: {
                        height: "100%",
                        backgroundColor: theme`colors.action.DEFAULT`,
                        transition: { duration: 0.3 },
                    },
                    initial: { height: 0 },
                }}
                animate={line}
            />
        </div>
    );
};

const Circle = ({
    children,
    circle,
    status,
    ...props
}: PropsWithChildren<{ circle: AnimationControls; status: StepStatus | null } & HTMLAttributes<HTMLButtonElement>>) => {
    return (
        // @ts-ignore
        <motion.button
            className="relative rounded-full flex items-center justify-center w-6 h-6 border-[1.5px] border-solid"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1 }}
            initial="initial"
            variants={{
                start: {
                    borderColor: theme`colors.action.DEFAULT`,
                    borderStyle: "solid",
                },
                skipped: {
                    borderColor: theme`colors.action.DEFAULT`,
                    borderStyle: "solid",
                },
                ready: {
                    borderColor: theme`colors.action.DEFAULT`,
                    borderStyle: "dashed",
                },
                initial: {
                    backgroundColor: theme`colors.white`,
                    borderColor: theme`colors.gray.300`,
                    borderStyle: "solid",
                },
            }}
            animate={circle}
            {...props}
        >
            <motion.div
                style={{
                    height: "100%",
                    outline: `1px solid ${theme`colors.action.DEFAULT`}`,
                    outlineOffset: "-0.5px",
                }}
                initial="initial"
                variants={{
                    start: {
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                        backgroundColor: theme`colors.action.DEFAULT`,
                        transition: { duration: 0.3 },
                        opacity: 1,
                    },
                    skipped: {
                        width: "7px",
                        height: "7px",
                        borderRadius: "100%",
                        backgroundColor: theme`colors.action.DEFAULT`,
                        transition: { duration: 0.3, delay: 0.4 },
                        opacity: 1,
                    },
                    ready: {
                        width: "8px",
                        height: "8px",
                        opacity: 0,
                    },
                    initial: {
                        width: "8px",
                        height: "8px",
                        opacity: 0,
                    },
                }}
                animate={circle}
            />
            <AnimatedCheck isVisible={status === StepStatus.Completed} />
        </motion.button>
    );
};

export default ProjectProgressContent;
