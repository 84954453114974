import {
    INITIAL_LOADING_MSG,
    SECOND_LOADING_MSG,
    THIRD_LOADING_MSG,
} from "components/copilot/ideation-drawer/constants";
import { DocumentInputContent } from "components/molecules/documents-mention-input/DocumentsMentionInput";

export const GENERATED_CONTENT_DROPPABLE = "GENERATED_CONTENT";
export const SELECTED_CONTENT_DROPPABLE = "SELECTED_CONTENT";
export const INITIAL_QUERY_STATE: DocumentInputContent = {
    raw: "",
    plainText: "",
    mentions: [],
};
export const LOADING_MSGS = [INITIAL_LOADING_MSG, SECOND_LOADING_MSG, THIRD_LOADING_MSG];
