import { useOthers } from "../liveblocks.config";
import styles from "./Avatars.module.css";
import { useSelector } from "react-redux";
import CustomAvatar from "../../Avatar";
import { HTMLAttributes, useEffect, useMemo, useState } from "react";

export function Avatars(props: HTMLAttributes<HTMLDivElement>) {
    const users = useOthers();
    const { workspaceMembers } = useSelector((store: any) => store.auth);
    const [currentUsers, setCurrentUser] = useState<any>([]);

    const liveUsersInADoc = useMemo(
        () =>
            workspaceMembers.filter((obj1: { id: string }) =>
                users.some((obj2: { id: string }) => obj2.id === obj1.id)
            ),
        [users, workspaceMembers]
    );

    useEffect(() => {
        if (liveUsersInADoc) {
            setCurrentUser(liveUsersInADoc);
        }
        return () => {
            setCurrentUser([]);
        };
    }, [workspaceMembers, users]);

    return (
        <>
            {currentUsers.length !== 0 ? (
                <div className={styles.avatars} {...props}>
                    <div className="relative  first:ml-0">
                        <div
                            role="button"
                            // onClick={() => setWorkSpaceModal(!workSpaceModal)}
                            className="mt-auto w-full select-none cursor-pointer"
                        >
                            <div className="flex gap-3 items-center">
                                <span className="flex -space-x-2">
                                    {currentUsers?.map((v: any, ind: number) => (
                                        <Avatar
                                            key={ind}
                                            ind={ind}
                                            bg={v.color}
                                            textColor={"#FFFFFF"}
                                            name={v.username}
                                        />
                                    ))}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export function Avatar({ ind, name, bg, textColor }: { ind: number; name: string; bg: string; textColor: string }) {
    // scrolling into users presence
    const handleScrollIntoPresence = (name: string) => {
        const labelDivs = document.getElementsByClassName("collaboration-cursor__label");

        const cleanName = name.trim();

        Array.from(labelDivs).forEach((labelDiv) => {
            if (labelDiv.innerHTML.trim() === cleanName) {
                labelDiv.scrollIntoView({ behavior: "smooth" });
                return; // exit the loop once the first matching label is found
            }
        });
    };

    return (
        <div
            className={styles.avatar}
            data-tooltip={name ? name : "Unknown"}
            onClick={() => handleScrollIntoPresence(name)}
        >
            {/* @ts-ignore */}
            <CustomAvatar key={ind} width={34} height={34} textColor={textColor} bgColor={bg} alt={name} />
        </div>
    );
}
