/** @jsxImportSource @emotion/react */

import { useCallback, useMemo } from "react";
import { useNestedDriveMeta } from "./hooks";
import { BaseFile } from "types/FileStorage";
import { Button } from "components/editor/components";
import { BsArrowLeft } from "react-icons/bs";
import FileRow from "./FileRow";
import FolderRow from "./FolderRow";

type Props = {
    query: string;
    handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
    selectedFiles: string[];
    onClear: (files: BaseFile[]) => void;
};

const ContentDrivePopoverContent = ({ query, handleSelectedFiles, selectedFiles, onClear }: Props) => {
    const { docs, folders, nextNestedDriveState, currentNestedDriveState, setCurrentNestedDriveState, isLoading } =
        useNestedDriveMeta();

    const filteredDocs = useMemo(() => {
        if (!query.trim()) return docs;
        return docs.filter(({ name }) => name?.trim()?.toLowerCase()?.startsWith(query?.trim()?.toLowerCase()));
    }, [docs, query]);
    const filteredFolders = useMemo(() => {
        if (!query.trim()) return folders;
        return folders.filter(({ name }) => name?.trim()?.toLowerCase()?.startsWith(query?.trim()?.toLowerCase()));
    }, [folders, query]);

    const isEmpty = !filteredDocs.length && !filteredFolders.length;

    const onSelectAll = useCallback(() => {
        const folderFiles = filteredFolders.reduce((acc, folder) => {
            const files = folder.all_nested_files || [];
            return [...acc, ...files];
        }, [] as BaseFile[]);
        handleSelectedFiles(true, [...filteredDocs, ...folderFiles]);
    }, [filteredDocs, filteredFolders, handleSelectedFiles]);

    const handleClear = useCallback(() => {
        const folderFiles = filteredFolders.reduce((acc, folder) => {
            const files = folder.all_nested_files || [];
            return [...acc, ...files];
        }, [] as BaseFile[]);
        onClear([...filteredDocs, ...folderFiles]);
    }, [filteredDocs, filteredFolders, onClear]);

    return (
        <>
            <div className="flex px-3 py-1 text-xs border-b border-gray-light justify-between items-center">
                {!currentNestedDriveState?.current_directory?.id ? (
                    <div className="text-gray-mid">Content Library</div>
                ) : (
                    <Button
                        variant="link"
                        size="sm"
                        className="group flex gap-1.5 items-center"
                        onClick={() => setCurrentNestedDriveState(undefined)}
                    >
                        <BsArrowLeft className="text-base duration-150 group-hover:-translate-x-1" />
                        <div className="inline font-medium">Back to Main Library</div>
                    </Button>
                )}
                {
                    <div className="flex items-center gap-2">
                        <Button
                            onClick={() => handleClear()}
                            variant="link"
                            size="sm"
                            className="!text-xxs mr-1 !text-red-500"
                        >
                            Clear
                        </Button>
                        <Button onClick={() => onSelectAll()} variant="link" size="sm" className="!text-xxs mr-1">
                            Select All
                        </Button>
                    </div>
                }
            </div>
            <div className="text-stone-800 text-sm w-[400px] max-w-[400px] max-h-[300px] overflow-y-auto flex flex-col gap-1 p-1">
                {filteredFolders.map((folder) => (
                    <FolderRow
                        key={folder.id}
                        setCurrentNestedDriveState={setCurrentNestedDriveState}
                        folder={folder}
                        selectedFiles={selectedFiles}
                        handleSelectedFiles={handleSelectedFiles}
                        isLoading={isLoading}
                        nextNestedDriveState={nextNestedDriveState}
                    />
                ))}
                {filteredDocs.map((file) => (
                    <FileRow
                        key={file.id}
                        file={file}
                        handleSelectedFiles={handleSelectedFiles}
                        selectedFiles={selectedFiles}
                    />
                ))}
                {isEmpty && (
                    <div className="min-h-[150px] text-xs text-gray-400 w-full flex justify-center items-center">
                        No documents found
                    </div>
                )}
            </div>
        </>
    );
};

export default ContentDrivePopoverContent;
