import { Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { PropsWithChildren, useMemo } from "react";
import { DropdownMenu, DropdownMenuProps } from "../dropdown-menu";

interface Props extends Omit<DropdownMenuProps<Volume>, "items" | "multiselect"> {
    volumes?: Volume[];
    sectionsMustExist?: boolean;
    includeEmpty?: boolean;
    onSelect: (vol: Volume | null) => void;
}

export const VolumesDropdown = ({
    children,
    includeEmpty,
    onSelect,
    volumes,
    sectionsMustExist,
    ...props
}: PropsWithChildren<Props>) => {
    const items = useMemo(
        () => [
            ...(includeEmpty ? [{ key: 0, label: "-", value: null, onSelect: () => onSelect(null) }] : []),
            ...(volumes
                ?.filter((vol) => !!vol.title && (sectionsMustExist ? !!vol.sections?.length : true))
                ?.map((vol) => ({
                    key: vol.id,
                    label: vol.title,
                    value: vol.id,
                    onSelect: () => onSelect(vol),
                })) || []),
        ],
        [includeEmpty, onSelect, sectionsMustExist, volumes]
    );

    return (
        <DropdownMenu items={items} {...props}>
            {children}
        </DropdownMenu>
    );
};
