import { TabSlug } from "./types";

export const DRIVE_TAB_TO_META: Record<TabSlug, { name: string }> = {
    [TabSlug.Documents]: {
        name: "Documents",
    },
    [TabSlug.Media]: {
        name: "Graphics",
    },
};

export const ACCEPT_MEDIA_TYPES = {
    "image/jpeg": [".jpeg"],
    "image/png": [".png"],
};

export const MAX_MEDIA_FILE_SIZE = 5 * 1024 * 1024; // 5MB
