/** @jsxImportSource @emotion/react */

import { VultronBlock as ImmutableVultronBlock } from "../CopilotSchemaImmutableTypes";
import IconButton from "components/atoms/icon-button";
import { useNotification } from "context/notificationContext";
import moment from "moment";
import { LuTrash2 } from "react-icons/lu";
import { useEffect, useState, useMemo } from "react";
import { INITIAL_LOADING_MSG, INITIAL_LOADING_STATE, SECOND_LOADING_MSG, THIRD_LOADING_MSG } from "./constants";
import copyText from "utils/copyText";
import { useTrackUserMetric } from "utils/metrics";
import { useDeleteAssistantBlock } from "./hooks";
import SourceWrapper from "./SourceWrapper";
import { VultronAvatar } from "components/molecules/avatar";
import { IoRefresh } from "react-icons/io5";
import { useAppSelector } from "store/storeTypes";
import TypingEffect from "components/TypingEffect";
import InternetAnimation from "Assets/gifs/internet-loading.gif";
import { useAssistant } from "./writing-assistant-input/hooks";
import tw from "twin.macro";

const VultronBlock = ({ block }: { block: ImmutableVultronBlock }) => {
    const { setToast } = useNotification();
    const deleteBlock = useDeleteAssistantBlock();
    const { streamState } = useAppSelector((root) => root.writingAssistant);
    const { isStreamingInProgress, streamCopy, blockId } = streamState;
    const { refreshMessage } = useAssistant();
    const trackUserEvent = useTrackUserMetric();

    const isStreaming = isStreamingInProgress && blockId === block.id;
    const [internetLoadingState, setInternetLoadingState] = useState(INITIAL_LOADING_STATE);

    const determineBlockType = useMemo(() => {
        if (block.enableInternet) {
            return "internet";
        } else if (block.promptSources?.length) {
            return "content search";
        } else {
            return "other";
        }
    }, [block]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        let nextTimeout: NodeJS.Timeout;
        if (isStreaming) {
            setInternetLoadingState((prev) => ({ ...prev, msg: INITIAL_LOADING_MSG }));
            timeout = setTimeout(() => setInternetLoadingState((prev) => ({ ...prev, msg: SECOND_LOADING_MSG })), 4000);
            nextTimeout = setTimeout(
                () => setInternetLoadingState((prev) => ({ ...prev, msg: THIRD_LOADING_MSG })),
                8000
            );
        }
        return () => {
            clearTimeout(timeout);
            clearTimeout(nextTimeout);
        };
    }, [isStreaming]);

    return (
        <div
            className="flex flex-row gap-2 w-full"
            css={[isStreaming && { scrollMarginTop: 8 }]}
            id={isStreaming ? `assistant-block-${blockId}` : undefined}
        >
            <VultronAvatar size={28} />
            <div className="flex flex-col gap-3 pt-1 flex-1 min-w-0">
                <div className="text-gray-darkest text-sm whitespace-pre-wrap">
                    {isStreaming && streamCopy}
                    {isStreaming && !streamCopy && !block.enableInternet && (
                        <TypingEffect style={{ padding: 0, height: 20 }} />
                    )}
                    {isStreaming && !streamCopy && block.enableInternet && (
                        <div className="flex items-center -mt-1">
                            <img src={InternetAnimation} className="w-[33px] h-[33px]" />
                            <div className="text-action ml-1">{internetLoadingState.msg}</div>
                            <span className="loading-ellipsis" />
                        </div>
                    )}
                    {!isStreaming && !block.error && block.body}
                    {!isStreaming && block.error && (
                        <div className="text-red-500 px-1.5 py-1 bg-red-50 rounded-md border border-red-500">
                            Something went wrong. If the issue persists, please contact us at support@vultron.ai.
                        </div>
                    )}
                </div>
                {!!block.sources?.length && !isStreaming && <SourceWrapper sources={block.sources} />}
                <div
                    className="flex flex-row justify-between items-center"
                    css={[isStreaming && tw`opacity-0 pointer-events-none`]}
                >
                    <div className="flex flex-row gap-2 items-center text-gray-400">
                        <IconButton
                            name="Copy"
                            className="w-4 h-4 duration-150 hover:text-slate-600"
                            onClick={() => {
                                copyText(block.body, () => {
                                    setToast.success({
                                        msg: "Copied",
                                    });
                                });
                                trackUserEvent("Chat: Copy Button Clicked", { type: determineBlockType });
                            }}
                        />
                        <div className="h-4 w-px bg-gray-400" />
                        <button onClick={() => deleteBlock(block.id)} className="duration-150 hover:text-slate-600">
                            <LuTrash2 className="stroke-[1.6]" />
                        </button>
                        {!!block.prompt?.trim() && !isStreamingInProgress && (
                            <>
                                <div className="h-4 w-px bg-gray-400" />
                                <button
                                    onClick={() => refreshMessage(block.id)}
                                    className="duration-150 hover:text-slate-600"
                                >
                                    <IoRefresh className="stroke-[1.7]" />
                                </button>
                            </>
                        )}
                    </div>
                    <div className="text-xs text-gray-lightest ml-4">{moment(block.updated_at).fromNow()}</div>
                </div>
            </div>
        </div>
    );
};

export default VultronBlock;
