import { LiveList, LiveObject } from "@liveblocks/client";
import { DefaultStepType, StepSource, StepStatus, StepType } from "types/ProjectProgress";
import { Progress } from "../CopilotSchemaTypes";

export const buildDefaultSteps = (): Progress["steps"] =>
    new LiveList([
        new LiveObject({
            step: DefaultStepType.ProjectDescription,
            type: StepType.Default,
            status: StepStatus.Ready,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.ProjectAttachments,
            type: StepType.Default,
            status: null,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.WinThemes,
            type: StepType.Default,
            status: null,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.Requirements,
            type: StepType.Default,
            status: null,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.RequirementResponses,
            type: StepType.Default,
            status: null,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.TemplateSelection,
            type: StepType.Default,
            status: null,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.RequirementAssignment,
            type: StepType.Default,
            status: null,
            source: StepSource.Liveblocks,
        }),
        new LiveObject({
            step: DefaultStepType.ProposalSectionWriting,
            type: StepType.Default,
            status: null,
            source: StepSource.Vultron,
        }),
        new LiveObject({
            step: DefaultStepType.ProposalGeneration,
            type: StepType.Default,
            status: null,
            source: StepSource.Vultron,
        }),
        new LiveObject({
            step: DefaultStepType.Export,
            type: StepType.Default,
            status: null,
            source: StepSource.Vultron,
        }),
    ]);
