/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { EMPTY_SHEET } from "const-values/Sheets";
import useSheetOperations from "hook/useSheetOperations";
import { groupBy } from "lodash";
import { HTMLAttributes, forwardRef, useMemo, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuPencil, LuTrash2 } from "react-icons/lu";
import { setActiveSheetState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import EditSheetModal from "./EditSheetModal";
import Tooltip from "components/atoms/tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
    idx: number;
    sheets: Sheet[];
    sheet: Sheet;
    withOpacity?: boolean;
    isDragging?: boolean;
}

const SheetTab = forwardRef<HTMLDivElement, Props>(
    ({ style, idx, withOpacity, isDragging, sheet, sheets, ...props }, ref) => {
        const contentRef = useRef<HTMLButtonElement | null>(null);
        const activeSheet = useAppSelector((root) => root.requirements.activeSheet);
        const { deleteSheet } = useSheetOperations();
        const dispatch = useAppDispatch();
        const { requirement_text_tasks, requirement_document_tasks } = useAppSelector(
            (root) => root.autopilotHealthCheck
        );
        const [editSheetOpen, setEditSheetOpen] = useState(false);

        const sheetsWithExtractionsInProgress = useMemo(
            () =>
                groupBy(
                    [...requirement_text_tasks, ...requirement_document_tasks]
                        .map((task) => (!task.extraction_id ? { ...task, extraction_id: EMPTY_SHEET.id } : task))
                        .filter((task) => task.is_started),
                    "extraction_id"
                ),
            [requirement_document_tasks, requirement_text_tasks]
        );

        const sheetActions = useMemo(
            () => [
                {
                    key: 1,
                    label: (
                        <div className="flex items-center gap-2 text-xs">
                            <LuPencil className="w-4" /> Edit
                        </div>
                    ),
                    onSelect: () => setEditSheetOpen(true),
                },
                {
                    key: 2,
                    label: (
                        <div className="flex gap-2 text-xs items-center text-red-500">
                            <LuTrash2 className="text-sm w-4" /> Delete
                        </div>
                    ),
                    onSelect: () => {
                        triggerConfirm({
                            header: `Are you sure you want to delete "${sheet.name}"`,
                            body: "This action is irreversible and will delete all requirements within this sheet.",
                        }).then((proceed) => {
                            if (proceed) {
                                deleteSheet(sheet.id);
                                if (activeSheet?.id === sheet.id) {
                                    dispatch(setActiveSheetState(sheets[idx + 1] || sheets[idx - 1] || null));
                                }
                            }
                        });
                    },
                },
            ],
            [activeSheet?.id, deleteSheet, dispatch, idx, sheet.id, sheet.name, sheets]
        );

        return (
            <>
                <div
                    ref={ref}
                    className="group flex items-center relative rounded-b-md bg-slate-100 flex-1 max-w-[190px] border-x border-b border-transparent transition-colors py-2 gap-1 pl-3 pr-1 hover:bg-slate-200"
                    css={[
                        {
                            zIndex: isDragging ? "2" : "auto",
                            opacity: withOpacity ? "0.3" : "1",
                            ...style,
                        },
                        isDragging && tw`shadow !bg-slate-100`,
                        activeSheet?.id === sheet.id && tw`!bg-white text-slate-800 border-slate-300 shadow-sm`,
                    ]}
                    {...props}
                >
                    <Tooltip
                        delayDuration={800}
                        disableHoverableContent
                        disabled={isDragging || (contentRef.current?.clientWidth || 0) < 188}
                        content={sheet.name}
                    >
                        <button
                            ref={contentRef}
                            onClick={() => dispatch(setActiveSheetState(sheet))}
                            className="z-[1] outline-none absolute top-0 bottom-0 left-0 right-0"
                        />
                    </Tooltip>
                    <div
                        className="text-xs overflow-hidden whitespace-nowrap w-full pr-1"
                        style={{ WebkitMaskImage: "linear-gradient(90deg, #000 93%, transparent)" }}
                    >
                        {sheet.name}
                    </div>
                    {!!sheetsWithExtractionsInProgress[sheet.id]?.length ? (
                        <Icon
                            name="Generate"
                            className="-ml-0.5 mr-0.5 w-4 h-4"
                            css={[
                                {
                                    animation: "rotateAnimation 0.5s infinite linear",
                                },
                            ]}
                        />
                    ) : (
                        <DropdownMenu
                            contentProps={{ align: "start", css: tw`min-w-[120px]` }}
                            triggerProps={{ css: tw`z-[2]` }}
                            items={sheetActions}
                        >
                            <div
                                className="text-xs p-0.5 rounded-full text-slate-500 duration-100 hover:text-slate-800 hover:bg-slate-100 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                                css={[
                                    sheet.id === activeSheet?.id && tw`opacity-100 pointer-events-auto`,
                                    sheet.id !== activeSheet?.id && tw`hover:bg-slate-300`,
                                ]}
                            >
                                <BsThreeDotsVertical />
                            </div>
                        </DropdownMenu>
                    )}
                </div>
                <div
                    className="min-w-[1px] my-1 h-6 bg-slate-300 opacity-0"
                    css={[
                        activeSheet?.id !== sheet.id && sheets?.[idx + 1]?.id !== activeSheet?.id && tw`opacity-100`,
                        isDragging && tw`opacity-0`,
                    ]}
                />
                <EditSheetModal sheets={sheets} sheet={sheet} open={editSheetOpen} onOpenChange={setEditSheetOpen} />
            </>
        );
    }
);

export default SheetTab;
