/** @jsxImportSource @emotion/react */

import { HTMLAttributes } from "react";
import { Avatar, Props as AvatarProps } from "../avatar/Avatar";
import "twin.macro";

interface Props extends HTMLAttributes<HTMLDivElement> {
    maxCount: number;
    avatars: AvatarProps[];
    size: number;
}

export const AvatarGroup = ({ maxCount, avatars, size, ...props }: Props) => {
    const extraCount = avatars.length - maxCount;

    return (
        <div tw="flex items-center -space-x-[11px]" {...props}>
            {avatars?.slice(0, maxCount)?.map((avatarProps) => (
                <Avatar {...avatarProps} size={size} />
            ))}
            {extraCount > 0 && (
                <div
                    className="rounded-full font-medium bg-indigo-200 text-indigo-700 flex items-center justify-center cursor-pointer select-none overflow-hidden text-xs"
                    css={[
                        {
                            width: size + "px",
                            minWidth: size + "px",
                            maxWidth: size + "px",
                            height: size + "px",
                            minHeight: size + "px",
                            maxHeight: size + "px",
                        },
                    ]}
                >
                    +{extraCount}
                </div>
            )}
        </div>
    );
};
