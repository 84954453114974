import { useCallback } from "react";

const useDocumentPreview = () => {
    const downloadFile = useCallback((download_url: string): void => {
        if (!download_url) return;
        window.open(download_url, "_blank");
    }, []);

    return { downloadFile };
};

export default useDocumentPreview;
