import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
// util
import { containsAllRoles } from "../utils/helpers";
//
import { useSelector } from "react-redux";
// auth pages
import WelcomePage from "../pages/auth/WelcomePage.jsx";
import ContinueWithEmail from "../pages/auth/ContinueWithEmail.jsx";
import LoginPage from "../pages/auth/LoginPage.jsx";
import SSOLoginPage from "pages/auth/SSOLoginPage";
import ConfirmEmail from "../pages/auth/ConfirmEmailPage.jsx";
import CheckLoginTokenPage from "../pages/auth/CheckLoginTokenPage.jsx";
import SelectWorkSpacePage from "../pages/auth/SelectWorkSpacePage.jsx";
import CreateWorkSpace from "../pages/auth/CreateWorkSpace.jsx";
// dashboard pages
import Dashboard from "../pages/dashboard/Dashboard.jsx";
import SearchPage from "../pages/search-pages/SearchPage.jsx";
import ContractManagement from "../pages/contracts-management/ContractManagement.jsx";
import ProposalAssistant from "../pages/proposal-assistant/index.jsx";
import DrivePage, { MediaContent } from "pages/drive";
import FolderPage from "pages/drive/FolderPage";
import CreateDocument from "pages/drive/CreateDocument";
import CuratedOpportunities from "../pages/Notifications/index.jsx";
import Settings from "../pages/Settings";
// ****************
// loader/spinner
import ScreenSpinner from "../utils/Spinner";
import ProjectDetails from "pages/project-details";
import DraftSection, { DraftSectionEmpty, DraftSectionProposal, DraftSectionRequirements } from "pages/draft-section";
import { ControlItemSlug } from "pages/draft-section/hooks";
import DocumentsContent from "pages/drive/documents/DocumentsContent";

// --------------- main wrapper for all routing within the web app -------------------
const AppRouter = () => {
    const currentWorkspace = useSelector((store) => store.auth.currentWorkspace);

    return (
        <Routes>
            {/* UnAuthenticated routes */}
            <Route path="/" element={<Navigate to="/auth" replace />} />
            <Route path="auth" element={<WelcomePage />}>
                <Route index element={<ContinueWithEmail />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="sso-login" element={<SSOLoginPage />} />
                <Route path="confirm-email" element={<ConfirmEmail />} />
                <Route path="redirect" element={<CheckLoginTokenPage />} />
            </Route>
            {/* Authenticated routes */}
            <Route
                path="select-workspace"
                element={
                    <ProtectedRoute redirectPath="/auth">
                        <SelectWorkSpacePage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="create-workspace"
                element={
                    <ProtectedRoute redirectPath="/auth">
                        <CreateWorkSpace />
                    </ProtectedRoute>
                }
            />
            <Route
                path="dashboard"
                exact
                element={
                    <ProtectedRoute redirectPath="/auth">
                        <Dashboard />
                    </ProtectedRoute>
                }
            >
                <Route
                    index
                    element={
                        <Navigate
                            to={currentWorkspace.enable_search ? "/dashboard/search" : "/dashboard/contracts"}
                            replace
                        />
                    }
                />
                <Route path="search" element={<SearchPage />} />
                <Route path="search/contracts-details" element={<SearchPage />} />

                <Route path="recommended" element={<CuratedOpportunities />} />
                <Route path="contracts" element={<ContractManagement />} />

                <Route path="contracts/details" element={<ProjectDetails />} />
                <Route path="contracts/details/volumes/:volumeId" element={<ProjectDetails />}>
                    <Route index element={<DraftSectionEmpty />} />
                    <Route path="sections/:sectionId" element={<DraftSection />}>
                        <Route path={ControlItemSlug.requirements} element={<DraftSectionRequirements />} />
                        <Route path={ControlItemSlug.proposal} element={<DraftSectionProposal />} />
                    </Route>
                </Route>

                <Route path="proposal-assistant" element={<ProposalAssistant />} />
                <Route path="library" element={<DrivePage />}>
                    <Route index element={<Navigate to="documents" replace />} />
                    <Route path="documents" element={<DocumentsContent />} />
                    <Route path="documents/folder/:folderId" element={<FolderPage />} />
                    <Route path="media" element={<MediaContent />} />
                    <Route path="media/folder/:folderId" element={<MediaContent />} />
                </Route>
                <Route path="create-document" element={<CreateDocument />} />
                <Route path="settings" element={<Settings />} />
                <Route path="create-workspace" element={<CreateWorkSpace />} />
                <Route path="select-workspace" element={<SelectWorkSpacePage />} />
                {/* <Route path="help" element={<Help />} /> */}
            </Route>
            {/* 404 page */}
            <Route path="*" element={<h1>404 not found</h1>} />
        </Routes>
    );
};

// This is wrapper component for protected routes & it will also validate role base routing.
const ProtectedRoute = ({ redirectPath = "/", children, roles }) => {
    const { isAuthenticated, isLoading, data: user } = useSelector((store) => store.auth);
    let location = useLocation();

    // if auth loading
    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <ScreenSpinner />
            </div>
        );
    }
    // if user is not authenticated
    if (!isAuthenticated) {
        window.Intercom("shutdown");
        return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }
    // if user is authenticated but not contains provided roles
    if (roles && !containsAllRoles(roles, user.roles)) {
        return <Navigate to={"/dashboard/select-workspace"} replace state={{ from: location }} />;
    }
    return children ? children : <Outlet />;
};

export default AppRouter;
