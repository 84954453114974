import { useFrameworkOperations } from "components/copilot/hooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setSectionState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch } from "store/storeTypes";

const DraftSectionEmpty = () => {
    const [searchParams] = useSearchParams();
    const { volumeId } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { addNewSection } = useFrameworkOperations();
    return (
        <div className="px-8 mt-12 ml-2 mr-5 bg-slate-50 gap-3 h-1/3 rounded-md shadow flex flex-col items-center justify-center">
            <div className="text-slate-900 font-semibold text-base">Get Started</div>
            <div className="text-slate-500 text-xs text-center">
                <button
                    onClick={() => {
                        if (!volumeId) return;
                        const newSection = addNewSection(volumeId);
                        navigate(`sections/${newSection.id}/requirements?${searchParams.toString()}`);
                        dispatch(
                            setSectionState({
                                sectionEditable: newSection.id,
                            })
                        );
                    }}
                    className="text-action font-medium duration-100 hover:text-action-hover"
                >
                    Create a section
                </button>{" "}
                to get started.
            </div>
        </div>
    );
};

export default DraftSectionEmpty;
