import { UniqueIdentifier } from "@dnd-kit/core";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Section } from "components/copilot/CopilotSchemaImmutableTypes";

export interface ProposalInProgressSection extends Section {
    full_requirement_title: boolean;
}

type State = {
    sectionEditable: string;
    requirementsState: {
        requirementHighlighted: string;
        requirementEditable: string;
        activeDragRequirementId: UniqueIdentifier | null;
        expandedRequirementId: string | null;
        responseErrorRequirementId: string | null;
        autoScroll: boolean;
        collapseAllText: boolean;
    };
    proposalState: {
        sectionInProgress: ProposalInProgressSection | null;
    };
    sidebarSize: number;
};

const initialState: State = {
    sectionEditable: "",
    requirementsState: {
        requirementHighlighted: "",
        requirementEditable: "",
        activeDragRequirementId: null,
        expandedRequirementId: null,
        responseErrorRequirementId: null,
        autoScroll: false,
        collapseAllText: false,
    },
    proposalState: {
        sectionInProgress: null,
    },
    sidebarSize: 30,
};

const sectionReducer = createSlice({
    name: "section",
    initialState,
    reducers: {
        resetSectionState: () => {
            return initialState;
        },
        setSectionState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
        setRequirementsState: (state: State, action: PayloadAction<Partial<State["requirementsState"]>>) => {
            state.requirementsState = { ...state.requirementsState, ...action.payload };
        },
        setProposalState: (state: State, action: PayloadAction<Partial<State["proposalState"]>>) => {
            state.proposalState = { ...state.proposalState, ...action.payload };
        },
    },
});

export const { resetSectionState, setSectionState, setRequirementsState, setProposalState } = sectionReducer.actions;

export default sectionReducer.reducer;
