/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import Popover from "components/atoms/popover";
import { UserInstruction } from "components/copilot/CopilotSchemaImmutableTypes";
import EditableContent from "components/molecules/editable-content";
import useRequirementOperations from "hook/useRequirementOperations";
import { Dispatch, HTMLAttributes, SetStateAction, forwardRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuTrash2 } from "react-icons/lu";
import { useAppSelector } from "store/storeTypes";
import tw, { theme } from "twin.macro";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

interface Props extends HTMLAttributes<HTMLDivElement> {
    reqId: string;
    instruction: UserInstruction;
    editableInstruction: string;
    setEditableInstruction: Dispatch<SetStateAction<string>>;
    withOpacity?: boolean;
    disabled?: boolean;
    isDragging?: boolean;
}

const UserInstructionRow = forwardRef<HTMLDivElement, Props>(
    (
        {
            reqId,
            instruction,
            editableInstruction,
            setEditableInstruction,
            withOpacity,
            disabled,
            isDragging,
            style,
            ...dragProps
        },
        ref
    ) => {
        const { content, id } = instruction;
        const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
        const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
        const { saveWritingInstructionProperties, deleteWritingInstruction } = useRequirementOperations();
        const isEditing = id === editableInstruction;
        const trackUserEvent = useTrackUserMetric();

        return (
            <div
                ref={ref}
                className="bg-slate-200 text-start flex items-center justify-between gap-5 text-slate-600 rounded text-xs p-2"
                css={[
                    {
                        ...(isDragging && { boxShadow: theme`boxShadow.md` }),
                        zIndex: isDragging ? "2" : "auto",
                        opacity: withOpacity ? "0.3" : disabled ? ".5" : "1",
                        ...style,
                    },
                ]}
            >
                <div className="flex items-center gap-0.5 flex-1">
                    <div
                        className="z-[1] bg-transparent rounded py-1 text-gray-lightest hover:text-gray-darkest hover:bg-gray-200 group-hover:opacity-100"
                        css={[{ cursor: isDragging ? "grabbing" : "grab" }]}
                        {...dragProps}
                    >
                        <Icon name="Draggable" className="w-3 h-3" />
                    </div>
                    <EditableContent
                        submitOnEnter
                        autoFocus={isEditing}
                        content={content || ""}
                        onContentUpdate={(val) => {
                            if (disabled) return;
                            setEditableInstruction("");
                            saveWritingInstructionProperties(reqId, id, { content: val });

                            trackUserEvent("Drafts: Guideline Updated", {
                                requirement_id: String(reqId),
                                word_count: getWordCount(val),
                            });
                        }}
                        css={[tw`text-xs p-1.5 flex-1 hover:bg-[#D4DEE9]`, disabled && tw`hover:bg-transparent`]}
                        heightProps={tw`overflow-hidden`}
                        textareaProps={{
                            placeholder: "Add guideline",
                            forceResetProps: [sidebarSize, actionsPanelOpen],
                            forceResetDelay: 150,
                            disabled,
                        }}
                    />
                </div>
                <Popover
                    contentProps={{ align: "start", css: tw`mx-0 text-xs overflow-hidden p-1` }}
                    content={
                        <button
                            onClick={() => deleteWritingInstruction(reqId, id)}
                            className="py-1.5 px-2 flex gap-2 items-center text-red-500 bg-transparent duration-100 hover:bg-slate-100 disabled:opacity-50"
                            disabled={disabled}
                        >
                            <LuTrash2 /> Delete
                        </button>
                    }
                >
                    <div className="cursor-pointer rounded px-1 py-1 bg-transparent duration-100 hover:bg-slate-300">
                        <BsThreeDotsVertical />
                    </div>
                </Popover>
            </div>
        );
    }
);

export default UserInstructionRow;
