export const MobileScreen = ({ className }) => {
    return (
        <div
            className={`h-screen flex flex-col items-center justify-center bg-[#f6f6f6] text-center ${
                className ? className : ""
            }`}
        >
            <div className="mx-auto w-full max-w-xs space-y-3 rounded-lg bg-white px-4 py-6 shadow-lg">
                <h3 className="text-lg font-semibold text-brand-dark">Window size unsupported</h3>
                <p className="text-gray-medium">Please increase the window size to use the app.</p>
            </div>
        </div>
    );
};

export default MobileScreen;
