import CFWButton from "components/custom/CFWButton";
import CustomModal from "components/CustomModal";
import { CloseIconBlack } from "utils/icons";
import MemberProgress from "./MemberProgress";

const MembersModal = ({ isOpen, setIsOpen, members }) => {
    return (
        <CustomModal isOpen={isOpen} className={"w-full max-w-[500px] overflow-x-hidden scrollbar-none pb-3"}>
            <div className="w-full bg-[#ffffff] h-[65px]">
                <div className="p-1 flex flex-col">
                    <div className="flex items-center justify-between border-gray-300 w-full px-5 pt-3 pb-2">
                        <h6 className="font-semibold text-[16px]">Members Progress</h6>
                        <span
                            className="ml-auto block"
                            role="button"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            <CloseIconBlack fill="black" width={12} height={12} />
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-full px-[20px] max-h-[400px] overflow-y-scroll">
                {members?.length > 0 ? (
                    members?.map((userProgress, index) => (
                        <MemberProgress
                            name={userProgress.username}
                            totalTasks={userProgress?.total_requirements}
                            completedTasks={userProgress?.total_completed}
                            key={index}
                        />
                    ))
                ) : (
                    <p className="text-[13px] px-[8px] text-[#8D98A2]">No members assigned.</p>
                )}
            </div>

            <div className="w-full bg-[#ffffff] h-[65px] px-[20px] flex items-end">
                <CFWButton title="Done" onClick={() => setIsOpen(false)} />
            </div>
        </CustomModal>
    );
};

export default MembersModal;
