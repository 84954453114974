/** @jsxImportSource @emotion/react */

import { PropsWithChildren, useMemo } from "react";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import Icon from "components/atoms/icons/Icon";
import "twin.macro";
import { DropdownMenu } from "../dropdown-menu";
import { LuEye, LuEyeOff, LuTrash2 } from "react-icons/lu";
import useRequirementOperations from "hook/useRequirementOperations";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { styles } from "../dropdown-menu/styles";
import { useAppDispatch } from "store/storeTypes";
import { setActiveRequirementId } from "store/reducers/copilot/requirementsReducer";
import { toggleRequirementDetails } from "store/reducers/copilot/copilotDrawerReducer";
import { PiSquaresFour } from "react-icons/pi";
import { AiOutlineLink } from "react-icons/ai";
import copyText from "utils/copyText";
import { useNotification } from "context/notificationContext";
import { useBuildRequirementLink } from "hook/Requirements/useBuildRequirementLink";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import { useTrackUserMetric } from "utils/metrics";

const extendedStyles = {
    item: `${styles.item} text-xs`,
};

export type RequirementsMenubarPopoverProps = {
    sections: FormattedSection[];
    complianceMatrixRow: ComplianceMatrixRow;
    disabled?: boolean;
};

const RequirementOptionsDropdown = ({
    sections,
    children,
    complianceMatrixRow,
}: PropsWithChildren<RequirementsMenubarPopoverProps>) => {
    const { setToast } = useNotification();
    const { requirement, proposal_reference, written_content } = complianceMatrixRow;
    const { generateRequirementHeading } = useGenerateRequirementHeading();
    const { deleteRequirementRow, assignToSection, disregardRequirement } = useRequirementOperations();
    const dispatch = useAppDispatch();
    const copyLink = useBuildRequirementLink(
        proposal_reference.volume_id,
        proposal_reference.section_id,
        requirement.id
    );

    const isAssigned = !!complianceMatrixRow.proposal_reference.section_id;
    const isDisregarded = !!complianceMatrixRow.requirement.disregarded;
    const trackUserEvent = useTrackUserMetric();

    const items = useMemo(
        () => [
            {
                key: 1,
                label: (
                    <div className="flex items-center gap-2 text-xs">
                        <PiSquaresFour className="text-base" />
                        Details
                    </div>
                ),
                onSelect: () => {
                    dispatch(setActiveRequirementId(requirement.id));
                    dispatch(toggleRequirementDetails(true));
                },
            },
            ...(isAssigned
                ? [
                      {
                          key: 2,
                          label: (
                              <div className="flex items-center gap-2 text-xs">
                                  <AiOutlineLink className="text-base w-4" /> Copy link
                              </div>
                          ),
                          onSelect: () => {
                              copyText(copyLink.link, () =>
                                  setToast.success({
                                      msg: "Copied",
                                  })
                              );
                              trackUserEvent("Requirements: Requirement Link Copied", {
                                  requirement_id: String(requirement.id),
                              });
                          },
                      },
                  ]
                : []),
            ...(isAssigned
                ? [
                      {
                          key: 3,
                          label: (
                              <div className="flex items-center gap-2 text-xs">
                                  <Icon name="NoteRemove" tw="w-4" />
                                  Unassign
                              </div>
                          ),
                          onSelect: () => assignToSection(requirement.id, null),
                      },
                  ]
                : []),
            {
                key: 4,
                label: (
                    <div className="flex items-center gap-2 text-xs">
                        {!isDisregarded ? <LuEyeOff className="text-sm w-4" /> : <LuEye className="text-sm w-4" />}
                        {isDisregarded ? "Undo disregard" : "Disregard"}
                    </div>
                ),
                onSelect: () => disregardRequirement(requirement.id, isDisregarded),
            },

            ...(!!sections.length && !isDisregarded
                ? [
                      {
                          key: 5,
                          label: "",
                          subs: !!sections.length && !isDisregarded && (
                              <DropdownMenu.Sub key={5}>
                                  <DropdownMenu.SubTrigger className={extendedStyles.item}>
                                      <div className="flex items-center gap-2 text-xs">
                                          <Icon name="Swap" tw="w-4" />
                                          {isAssigned ? "Move to" : "Assign to"}
                                      </div>
                                      <div className={styles.chevronRight}>
                                          <ChevronRightIcon />
                                      </div>
                                  </DropdownMenu.SubTrigger>
                                  <DropdownMenu.Portal>
                                      <DropdownMenu.SubContent className={styles.content} sideOffset={8}>
                                          {sections.map((section) =>
                                              !!section.subsections?.length ? (
                                                  <DropdownMenu.Sub key={section.id}>
                                                      <DropdownMenu.SubTrigger className={extendedStyles.item}>
                                                          <DropdownMenu.Item
                                                              className="flex items-center w-full justify-between"
                                                              onPointerLeave={(event) => event.preventDefault()}
                                                              onPointerMove={(event) => event.preventDefault()}
                                                              onSelect={() => {
                                                                  assignToSection(requirement.id, section);
                                                                  if (
                                                                      !!(
                                                                          written_content ||
                                                                          requirement?.content ||
                                                                          requirement?.summarized_content
                                                                      )
                                                                  ) {
                                                                      generateRequirementHeading({
                                                                          requirement_ids: [requirement.id],
                                                                      });
                                                                  }
                                                              }}
                                                          >
                                                              {section.title}
                                                              <div className={styles.chevronRight}>
                                                                  <ChevronRightIcon />
                                                              </div>
                                                          </DropdownMenu.Item>
                                                      </DropdownMenu.SubTrigger>
                                                      <DropdownMenu.Portal>
                                                          <DropdownMenu.SubContent
                                                              className={styles.content}
                                                              sideOffset={8}
                                                          >
                                                              {section.subsections?.map(
                                                                  (subsection) =>
                                                                      complianceMatrixRow.proposal_reference
                                                                          .section_id !== subsection.id && (
                                                                          <DropdownMenu.Item
                                                                              key={subsection.id}
                                                                              className={extendedStyles.item}
                                                                              onSelect={() =>
                                                                                  assignToSection(
                                                                                      requirement.id,
                                                                                      subsection
                                                                                  )
                                                                              }
                                                                          >
                                                                              {subsection.title || (
                                                                                  <span className="text-slate-400">
                                                                                      Subsection name...
                                                                                  </span>
                                                                              )}
                                                                          </DropdownMenu.Item>
                                                                      )
                                                              )}
                                                          </DropdownMenu.SubContent>
                                                      </DropdownMenu.Portal>
                                                  </DropdownMenu.Sub>
                                              ) : (
                                                  <DropdownMenu.Item
                                                      key={section.id}
                                                      className={extendedStyles.item}
                                                      onSelect={() => assignToSection(requirement.id, section)}
                                                  >
                                                      {section.title || (
                                                          <span className="text-slate-400">Section name...</span>
                                                      )}
                                                  </DropdownMenu.Item>
                                              )
                                          )}
                                      </DropdownMenu.SubContent>
                                  </DropdownMenu.Portal>
                              </DropdownMenu.Sub>
                          ),
                          onSelect: () => {},
                      },
                  ]
                : []),
            {
                key: 6,
                label: (
                    <div className="flex gap-2 text-xs items-center text-red-500">
                        <LuTrash2 className="text-sm w-4" /> Delete
                    </div>
                ),
                onSelect: () => deleteRequirementRow(requirement.id),
            },
        ],
        [
            assignToSection,
            complianceMatrixRow.proposal_reference.section_id,
            copyLink.link,
            deleteRequirementRow,
            dispatch,
            disregardRequirement,
            generateRequirementHeading,
            isAssigned,
            isDisregarded,
            requirement?.content,
            requirement.id,
            requirement?.summarized_content,
            sections,
            setToast,
            written_content,
        ]
    );

    return (
        <DropdownMenu modal items={items}>
            {children}
        </DropdownMenu>
    );
};

export default RequirementOptionsDropdown;
