import React, { useState } from "react";
import voiceLine from "../../Assets/voice-line.png";
import voiceRecord from "../../Assets/voice-record.png";
import voiceStop from "../../Assets/voice-stop.png";
import voiceTalking from "../../Assets/voice-talking.png";
import axios from "axios";
import {
    VOICE_ASSIST_STOPPED,
    VOICE_ASSIST_READY,
    VOICE_ASSIST_PLAYING,
    VOICE_ASSIST_PROCESSING,
    VOICE_ASSIST_RECORDING,
    INITIAL_STATE,
    VOICE_CHAT_CONV_API,
    VOICE_CHAT_MESSAGE_API,
} from "./constants";
import { useNotification } from "context/notificationContext";

const VoiceAssistControl = () => {
    const { setToast } = useNotification();
    const [state, setState] = useState(INITIAL_STATE);
    const isStarted = state.voiceAssistState !== VOICE_ASSIST_STOPPED;

    const createMediaRecorder = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        return mediaRecorder;
    };

    const createConversation = async () => {
        try {
            const resp = await axios.post(VOICE_CHAT_CONV_API, {});
            const data = resp.data;
            return data.id;
        } catch (e) {
            setToast.error({
                title: "Unable to process request",
                msg: "We were unable to process the request due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
        }
    };

    const onRecordingComplete = async (audioBlob, conversationId) => {
        try {
            const formData = new FormData();
            formData.append("audio", audioBlob, "memo.webm");
            formData.append("conversation_id", conversationId);
            const resp = await axios.postForm(VOICE_CHAT_MESSAGE_API, formData, { responseType: "blob" });
            const blob = await resp.data;
            const audioUrl = URL.createObjectURL(blob);
            setState({ ...state, audioUrl, voiceAssistState: VOICE_ASSIST_PLAYING });
        } catch (e) {
            setToast.error({
                title: "Unable to process request",
                msg: "We were unable to process the request due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
        }
    };

    const handleStart = async () => {
        const conversationId = await createConversation();
        const mediaRecorder = await createMediaRecorder();
        mediaRecorder.ondataavailable = (e) => {
            onRecordingComplete(e.data, conversationId);
        };
        setState({
            ...state,
            mediaRecorder,
            conversationId,
            voiceAssistState: VOICE_ASSIST_READY,
        });
    };

    const handleLeave = () => {
        setState(INITIAL_STATE);
    };

    const handleStartRecording = () => {
        state.mediaRecorder.start();
        setState({ ...state, voiceAssistState: VOICE_ASSIST_RECORDING });
    };

    const handleStopRecording = () => {
        state.mediaRecorder.stop();
        setState({ ...state, voiceAssistState: VOICE_ASSIST_PROCESSING });
    };

    const handlePlaybackEnded = () => {
        setState({ ...state, audioUrl: null, voiceAssistState: VOICE_ASSIST_READY });
    };

    return (
        <div className="flex flex-row items-center gap-1">
            {state.voiceAssistState === VOICE_ASSIST_STOPPED && (
                <button onClick={handleStart} title="Start Conversation">
                    <img src={voiceLine} alt="voice start" />
                </button>
            )}
            {state.voiceAssistState === VOICE_ASSIST_READY && (
                <button onClick={handleStartRecording} title="Start Recording">
                    <img src={voiceRecord} alt="voice recording" />
                </button>
            )}
            {state.voiceAssistState === VOICE_ASSIST_RECORDING && (
                <button onClick={handleStopRecording} title="Stop Recording">
                    <img src={voiceStop} alt="voice recording" />
                </button>
            )}
            {state.voiceAssistState === VOICE_ASSIST_PROCESSING && <img src={voiceTalking} alt="voice processing" />}
            {state.voiceAssistState === VOICE_ASSIST_PLAYING && (
                <>
                    <img src={voiceTalking} alt="voice processing" />
                    <audio autoPlay src={state.audioUrl} onEnded={(e) => handlePlaybackEnded(e)}></audio>
                </>
            )}
            {isStarted && (
                <button className="ml-2 border p-2 rounded-lg hover:bg-gray-100" onClick={handleLeave}>
                    Leave
                </button>
            )}
        </div>
    );
};

export default VoiceAssistControl;
