/** @jsxImportSource @emotion/react */

import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import { styles } from "components/molecules/dropdown-menu/styles";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import Icon from "components/atoms/icons/Icon";

const HelpDropdown = () => {
    return (
        <RadixDropdownMenu.Root modal={false}>
            <RadixDropdownMenu.Trigger className={classNames([styles.trigger])}>
                <div className="bg-slate-100 border border-slate-300 shadow-sm flex gap-2 items-center text-slate-500 rounded text-sm py-[2px] px-2 duration-100 hover:bg-slate-200 hover:text-slate-700">
                    Learn
                    <Icon name="CarrotDown" />
                </div>
            </RadixDropdownMenu.Trigger>
            <RadixDropdownMenu.Portal>
                <RadixDropdownMenu.Content className={styles.content} align="end" sideOffset={4}>
                    <RadixDropdownMenu.Item
                        className={styles.item}
                        css={[{ wordBreak: "break-word" }]}
                        onSelect={() => {
                            window.open("https://intercom.help/vultron-ai/en/", "_blank");
                        }}
                        onPointerLeave={(event) => event.preventDefault()}
                        onPointerMove={(event) => event.preventDefault()}
                    >
                        Support Center
                    </RadixDropdownMenu.Item>
                    <RadixDropdownMenu.Sub>
                        <RadixDropdownMenu.SubTrigger className={styles.subtrigger}>
                            <div className={styles.itemTrigger}>
                                Guides
                                <div className={styles.chevronRight}>
                                    <ChevronRightIcon />
                                </div>
                            </div>
                        </RadixDropdownMenu.SubTrigger>
                        <RadixDropdownMenu.Portal>
                            <RadixDropdownMenu.SubContent className={styles.content} sideOffset={8}>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8893852-getting-started-projects",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Projects
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8896158-projects-documents",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Documents
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8902332-projects-extract",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Extract
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8931970-projects-drafts",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Drafts
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8902376-project-proposals",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Proposals
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8902343-projects-action-menu#h_9d0b17e77b",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Writing Assistant
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8902343-projects-action-menu#h_4d5ec096d5",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Content Search
                                </RadixDropdownMenu.Item>
                                <RadixDropdownMenu.Item
                                    onSelect={() => {
                                        window.open(
                                            "https://intercom.help/vultron-ai/en/articles/8894375-best-practices-prompting",
                                            "_blank"
                                        );
                                    }}
                                    className={styles.item}
                                >
                                    Prompting
                                </RadixDropdownMenu.Item>
                            </RadixDropdownMenu.SubContent>
                        </RadixDropdownMenu.Portal>
                    </RadixDropdownMenu.Sub>
                </RadixDropdownMenu.Content>
            </RadixDropdownMenu.Portal>
        </RadixDropdownMenu.Root>
    );
};

export default HelpDropdown;
