import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
    progressCompleted: number;
};

const initialState: State = {
    progressCompleted: 0,
};

const projectProgressReducer = createSlice({
    name: "projectProgress",
    initialState,
    reducers: {
        setProgressCompleted: (state: State, action: PayloadAction<number>) => {
            state.progressCompleted = action.payload;
        },
    },
});

export const { setProgressCompleted } = projectProgressReducer.actions;

export default projectProgressReducer.reducer;
