/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

function AnimatedCheck({
    isVisible: isChecked,
    checkStyles,
}: {
    isVisible: boolean;
    checkStyles?: Interpolation<Theme>;
}) {
    return (
        <div className="flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 text-white">
            <AnimatePresence>
                {isChecked && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-4 h-4"
                        css={[checkStyles]}
                    >
                        <motion.path
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            exit={{ pathLength: 0 }}
                            transition={{
                                type: "tween",
                                duration: isChecked ? 0.3 : 0.0,
                                delay: 0.2,
                                ease: isChecked ? "easeOut" : "easeIn",
                            }}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                        />
                    </svg>
                )}
            </AnimatePresence>
        </div>
    );
}

export default AnimatedCheck;
