import { useState } from "react";
import CustomModal from "../../CustomModal";
import cross from "../../../Assets/crosss.svg";
import upload from "../../../Assets/upload-folder 1.svg";
import pdf from "../../../Assets/pdf.svg";
import union from "../../../Assets/addlink.svg";
// import ProgressBar from '../ProgressBar'
// import circleClose from "../../Assets/close-circle.svg"
import trash from "../../../Assets/trash.svg";
import { Spinner } from "../../../utils/icons";
import docs from "../../../Assets/doc.svg";
import xls from "../../../Assets/xls.svg";
import ppt from "../../../Assets/ppt.svg";
import { useDropzone } from "react-dropzone";
import { formatBytes, pluralizeWord } from "utils/helpers";
import axios from "axios";
import { useLocalStorage } from "hook/useLocalStorage";
import { useNotification } from "context/notificationContext";
import { set } from "lodash";

// Max size 200 MB
const maxSize = 200000000;

// ------------------- Upload new Doc modal handler --------------------------------
const UploadInternalDocument = ({ internalContractId, setForceRefresh }) => {
    const [uploading, setUploadingLoading] = useState(false);
    const { setToast } = useNotification();
    const [files, setFiles] = useState([]);
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");

    // on file drop/select
    const onDrop = (acceptedFiles) => {
        if (uploading) return;
        if (acceptedFiles && acceptedFiles?.length > 0) {
            let newFiles = [];
            acceptedFiles.forEach((file) => {
                if (files.find((b) => b.name === file.name)) {
                    return;
                }
                newFiles.push(file);
            });
            autoUpload(newFiles);
        }
    };

    function formatDate(date) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return `${monthNames[monthIndex]} ${day}, ${year}`;
    }
    const currentDateFormatted = formatDate(new Date());

    // validation
    const dropValidator = (file) => {
        if (file?.size > maxSize) {
            setToast.error({
                title: "Document size not supported",
                msg:
                    file?.name +
                    ` size is too large. We do not support document sizes larger than ${formatBytes(
                        maxSize
                    )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
            });
            return {
                code: "file-too-large",
                message: `Document is larger than ${formatBytes(maxSize)}`,
            };
        }

        return null;
    };

    // drop files hook
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: maxSize,
        validator: dropValidator,
        accept: {
            "text/plain": [".txt"],
            "text/pdf": [".pdf"],
            "text/doc": [".docx"],
            "text/excel": [".xlsx"],
            "text/powerpoint": [".pptx"],
        },
        noClick: false,
        maxFiles: 50,
        multiple: true,
    });

    // remove file
    const removeFile = (file) => {
        let prevFiles = [...files];
        prevFiles = prevFiles.filter((f) => f !== file);
        setFiles(prevFiles);
    };

    const imgIcon = (type) => {
        let img = pdf;
        switch (type) {
            case "application/pdf":
                img = pdf;
                break;
            case "application/doc":
            case "application/docx":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                img = docs;
                break;
            case "application/xls":
            case "application/xlsx":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                img = xls;
                break;
            case "application/pptx":
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                img = ppt;
                break;
            default:
                img = docs;
        }
        return img;
    };

    const autoUpload = (files) => {
        if (uploading) return;

        setUploadingLoading(true);
        Promise.allSettled(
            files.map((it, i) => {
                let formData = new FormData();
                formData.append("file", it);
                formData.append("internal_upload_type", 0);

                return axios({
                    method: "POST",
                    url: "/contracts/internal/" + internalContractId + "/document/",
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localValue}`,
                        Workspace: `Workspace ${workspace_id}`,
                    },
                });
            })
        )
            .then(() => {
                setTimeout(() => {
                    setToast.success({
                        msg: `Uploaded ${files.length} ${pluralizeWord("document", "documents", files.length)}`,
                    });
                    setUploadingLoading(false);
                }, 500);
                setForceRefresh(true);
            })
            .catch(() => {
                setToast.error({
                    title: `Unable to upload ${pluralizeWord("document", "documents", files.length)}`,
                    msg: `We were unable to upload the ${pluralizeWord(
                        "document",
                        "documents",
                        files.length
                    )} due to a technical issue on our end. Please try uploading again. If the issue persists, contact support@vultron.ai for assistance.`,
                });
                setUploadingLoading(false);
            });
    };

    return (
        <div className="w-full">
            <div className="">
                <div
                    {...getRootProps()}
                    className="p-3 pt-1 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 mb-0 justify-center flex flex-col items-center rounded-lg bg-[#fafafa] min-h-[110px]"
                >
                    <input multiple {...getInputProps()} />
                    {isDragActive ? (
                        <div className="absolute bg-gray-300 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
                            {" "}
                            Drop your file here...
                        </div>
                    ) : null}
                    <img src={upload} alt="" className="h-12" />
                    <span className="font-bold text">Drop or Select File</span>
                    <span className="text-gray-text text-sm">We accept pdf, docx, txt, xlsx, and pptx files</span>
                </div>
                {/* ---------------------------------- */}
                {files?.map((fileItem, i) => (
                    <div key={i} className="p-3 flex justify-between items-center w-full">
                        <div className="flex gap-3 flex-grow">
                            <img src={imgIcon(fileItem?.type)} alt="" />
                            <div className="flex flex-col max-w-[93%]">
                                <span
                                    style={{
                                        wordWrap: "break-word",
                                        textOverflow: "clip",
                                    }}
                                    className="block text-sm font-medium break-words overflow-hidden truncate md:max-w-md lg:max-w-lg"
                                >
                                    {fileItem?.name}
                                </span>
                                <span className="text-xs">{formatBytes(fileItem?.size)}</span>
                            </div>
                        </div>
                        <button
                            className="px-2 min-w-[33px] w-[33px] border-0 b-white"
                            onClick={() => removeFile(fileItem)}
                        >
                            <img src={trash} alt="" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UploadInternalDocument;
