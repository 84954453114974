import { useCallback, useEffect, useMemo } from "react";
import {
    setAssistantPrompt,
    setHighlightedText,
    setStreamingState,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useMutation, useStorage } from "liveblocks.config";
import { useNotification } from "context/notificationContext";
import { useLocalStorage } from "hook/useLocalStorage";
import {
    AssistantBlockType,
    BlockSource,
    Storage as ImmutableStorage,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage, VultronBlock } from "components/copilot/CopilotSchemaTypes";
import { DELIMITER } from "./constants";
import { createWritingAssistantBlock } from "utils/complianceMatrix";
import { getWordCount } from "utils/getWordCount";
import { useTrackUserMetric } from "utils/metrics";
import { LiveList, LiveObject } from "@liveblocks/client";
import { useObserveSseController } from "hook/useObserveSseController";

type PastMessage = {
    content: string;
    is_assistant: boolean;
    sent_at: string;
    sources: BlockSource[];
};

type SendMessageVariables = {
    project_id: string;
    win_themes: string[];
    user_request: string;
    search_file_ids: string[];
    past_messages: PastMessage[];
    use_internet?: boolean;
};
let controller = new AbortController();
export const useAssistant = () => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { setToast } = useNotification();
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((store) => store.auth);
    const { activeProject } = useAppSelector((root) => root.project);
    const { prompt, selectedFiles, streamState, enableInternet, highlightedText } = useAppSelector(
        (root) => root.writingAssistant
    );
    const myConversation = useStorage(
        (root) =>
            (root.writing_assistant as ImmutableStorage["writing_assistant"])?.[currentUser?.id || ""]?.conversation ||
            []
    );
    const canSubmit = !!prompt.trim() && !streamState.isStreamingInProgress && !!activeProject?.internal_contract.id;
    const canRefresh = !streamState.isStreamingInProgress && !!activeProject?.internal_contract.id;
    const trackUserEvent = useTrackUserMetric();

    const sendMessage = useMutation(
        ({ storage }, messagePayload: SendMessageVariables, refreshId?: string) => {
            let text = "";
            let vultronBlock: LiveObject<VultronBlock>;
            const isNewMessage = !refreshId;
            const myConversation = (storage.get("writing_assistant") as Storage["writing_assistant"])
                ?.get(currentUser?.id || "")
                ?.get("conversation");

            const determineBlockType = () => {
                if (vultronBlock.get("enableInternet")) {
                    return "internet";
                } else if (vultronBlock.get("promptSources")?.length) {
                    return "content search";
                } else {
                    return "other";
                }
            };

            const sourceLength = () => {
                if (vultronBlock.get("sources")?.length) {
                    return vultronBlock.get("sources")?.length;
                } else {
                    return 0;
                }
            };

            if (!isNewMessage) {
                const foundBlock = myConversation?.find(
                    (block) => block.get("type") === AssistantBlockType.VultronBlock && block.get("id") === refreshId
                ) as LiveObject<VultronBlock>;
                if (!foundBlock) return;
                vultronBlock = foundBlock;
                dispatch(setStreamingState({ isStreamingInProgress: true, blockId: refreshId }));
                vultronBlock.update({ error: false, sources: new LiveList([]) });
            } else {
                const userBlock = createWritingAssistantBlock(AssistantBlockType.CoreBlock, {
                    body: messagePayload.user_request,
                });
                vultronBlock = createWritingAssistantBlock(AssistantBlockType.VultronBlock, {
                    prompt: messagePayload.user_request,
                    promptSources: messagePayload.search_file_ids,
                    error: false,
                    enableInternet: messagePayload.use_internet,
                }) as LiveObject<VultronBlock>;
                myConversation?.push(userBlock);
                myConversation?.push(vultronBlock);
                dispatch(setStreamingState({ isStreamingInProgress: true, blockId: vultronBlock.get("id") }));
                dispatch(setAssistantPrompt(""));
            }

            fetchEventSource(`${process.env.REACT_APP_BASE_URL}/chat/send_message`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Workspace: `Workspace ${workspace_id}`,
                    Authorization: `Bearer ${localValue}`,
                    Accept: "application/json",
                },
                body: JSON.stringify(messagePayload),
                signal: controller.signal,
                onmessage(msg) {
                    if (msg.event === "FatalError") {
                    }

                    if (!!msg.data?.length) {
                        try {
                            const parsed = JSON.parse(msg.data);
                            if (typeof parsed !== "object" && !parsed?.sources) throw new Error("error");
                            const sources = (parsed.sources as BlockSource[]).map((source) => new LiveObject(source));
                            vultronBlock.set("sources", new LiveList(sources));
                        } catch {
                            if (msg.data !== DELIMITER) {
                                text += msg.data;
                                dispatch(setStreamingState({ streamCopy: text }));

                                vultronBlock.set("body", text);
                            }
                        }
                    } else if (typeof msg.data === "string") {
                        text += "\n";
                        dispatch(setStreamingState({ streamCopy: text }));
                        vultronBlock.set("body", text);
                    }
                },
                async onopen(response) {
                    if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                        return; // everything's good
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                        setToast.error({
                            title: "Unable to send message",
                            msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                        });
                        vultronBlock.set("error", true);
                        dispatch(setStreamingState({}));
                    } else {
                    }
                },
                onclose() {
                    dispatch(setStreamingState({}));

                    trackUserEvent("Chat: Message Recieved", {
                        type: determineBlockType(),
                        word_count: getWordCount(vultronBlock.get("body") || ""),
                        number_sources: sourceLength(),
                    });
                },
                onerror(err) {
                    setToast.error({
                        title: "Unable to send message",
                        msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                    });
                    vultronBlock.set("error", true);
                    dispatch(setStreamingState({}));
                    if (err instanceof Error) {
                        throw err; // rethrow to stop the operation
                    } else {
                    }
                },
            });
        },
        [dispatch]
    );

    const abortConnection = useCallback(() => {
        controller.abort();
        controller = new AbortController();
        dispatch(setStreamingState({}));
    }, [dispatch]);

    useObserveSseController(abortConnection);

    const submitMessage = useMutation(
        ({ storage }) => {
            if (!canSubmit) return;
            dispatch(setHighlightedText(""));
            const userRequest = highlightedText ? `${prompt}: ${highlightedText}` : prompt;

            const winThemes =
                (storage.get("win_themes") as Storage["win_themes"])
                    ?.toImmutable()
                    ?.filter(({ content }) => !!content) || [];

            const lastTwenty = myConversation.slice(myConversation.length - 20).filter(({ body }) => !!body.trim());
            sendMessage({
                win_themes: winThemes?.map(({ content }) => content),
                project_id: activeProject.internal_contract.id,
                user_request: userRequest,
                search_file_ids: selectedFiles.map(({ id }) => id),
                past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
                    content: body,
                    is_assistant: type === AssistantBlockType.VultronBlock,
                    sent_at: updated_at,
                    sources: sources || [],
                })),
                use_internet: enableInternet,
            });
        },
        [activeProject?.internal_contract.id, canSubmit, myConversation, prompt, selectedFiles, sendMessage]
    );

    const refreshMessage = useMutation(
        ({ storage }, refreshId: string) => {
            if (!canRefresh) return;

            const winThemes =
                (storage.get("win_themes") as Storage["win_themes"])
                    ?.toImmutable()
                    ?.filter(({ content }) => !!content) || [];
            const blockIdx = myConversation?.findIndex(({ id }) => refreshId === id);
            const block = myConversation[blockIdx];
            if (blockIdx === -1) return;
            if (block?.type !== AssistantBlockType.VultronBlock || !block.prompt?.trim()) return;
            const lastTwenty = myConversation.slice(blockIdx - 20, blockIdx).filter(({ body }) => !!body.trim());
            sendMessage(
                {
                    win_themes: winThemes?.map(({ content }) => content),
                    project_id: activeProject.internal_contract.id,
                    user_request: block.prompt,
                    search_file_ids: block.promptSources || [],
                    past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
                        content: body,
                        is_assistant: type === AssistantBlockType.VultronBlock,
                        sent_at: updated_at,
                        sources: sources || [],
                    })),
                    use_internet: block.enableInternet,
                },
                refreshId
            );
        },
        [activeProject?.internal_contract.id, canRefresh, myConversation, selectedFiles, sendMessage]
    );

    return { abortConnection, refreshMessage, submitMessage };
};

export const useWritingAssistantSelection = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const captureSelectedText = (e: Event) => {
            if (
                // @ts-ignore
                e.target.activeElement.id !== "proposal-tiptap-editor" &&
                // @ts-ignore
                !e.target.activeElement.classList?.contains("public-DraftEditor-content")
            )
                return;

            const activeSelection = document.getSelection();
            const text = activeSelection?.toString();
            if (!activeSelection || !text) {
                return;
            }
            dispatch(setHighlightedText(text));
        };

        document.addEventListener("selectionchange", captureSelectedText);

        return () => {
            document.removeEventListener("selectionchange", captureSelectedText);
            dispatch(setHighlightedText(""));
        };
    }, [dispatch]);
};
