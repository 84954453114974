import { useEffect, useRef, useState } from "react";
import DropDown from "../DropDown";
import search from "../../Assets/search-normal.png";
import save from "../../Assets/save-2.svg";
import Chip from "../Chip";
import { CloseIconBlack, CloseIconSm, Spinner } from "../../utils/icons";
import CustomModal from "../CustomModal";
import { saveSearchApi, updateSearchFiltersApi } from "api/api";
import { useSelector, useDispatch } from "react-redux";
import { getExactDate } from "utils/helpers";
import { searchFilter } from "helpers/helper";
import { getAllSavedSearches, setSavedSearches } from "store/reducers/utilsRouter";
import { useNotification } from "context/notificationContext";

export let deadlineOptions = [
    { name: "Tomorrow", value: "Tomorrow" },
    { name: "Within the next week", value: "Within the next week" },
    { name: "Within the next month", value: "Within the next month" },
    { name: "Within the next 6 months", value: "Within the next 6 months" },
    { name: "Within the next year", value: "Within the next year" },
];

export let publishedDateOptions = [
    { name: "Yesterday", value: "Yesterday" },
    { name: "Last week", value: "Last week" },
    { name: "Last Month", value: "Last Month" },
    { name: "Last 6 Months", value: "Last 6 Months" },
    { name: "Last Year", value: "Last Year" },
];

// ===================  Search tab filter components ==========================
const SearchPageSearchBar = ({
    searchVal,
    setSearchVal,
    setSearchQuery,
    allChips,
    setAllChips,
    setOffset,
    setLimitQuery,
    setInfiniteScroll,
    searchBarRef,
}) => {
    const dispatch = useDispatch();
    const { setToast } = useNotification();
    const [drpOptions, setDrpOptions] = useState([
        {
            name: "departments",
            subName: "department",
            title: "Department",
            searchable: true,
            optionIcon: true,
            options: [],
            className: "!min-w-[260px] max-w-max",
            textClass: "!max-w-max",
            multiSelect: true,
        },
        {
            name: "naics_codes",
            subName: "naics_code",
            title: "NAICS",
            searchable: true,
            optionIcon: true,
            options: [],
            className: "!min-w-[260px] max-w-max",
            multiSelect: true,
        },
        {
            name: "product_codes",
            subName: "product_code",
            title: "Product Code",
            searchable: true,
            optionIcon: true,
            options: [],
            className: "!min-w-[260px] max-w-max right-0",
            multiSelect: true,
        },
        {
            name: "notice_type",
            subName: "notice_type",
            title: "Stage",
            searchable: true,
            optionIcon: true,
            options: [],
            className: "!min-w-[260px] max-w-max",
            multiSelect: true,
        },
        {
            name: "publishedDate",
            title: "Published Date",
            searchable: false,
            optionIcon: true,
            static: true,
            options: publishedDateOptions,
            className: "!min-w-[240px]",
            txtClass: "hover:bg-gray-100",
            btnClass: "hover:!text-black hover:!border-[#dbe0e5] min-h-[40px]",
            activeBtnClass: "hover:!text-action hover:!border-action !border-action",
            multiSelect: false,
        },
        {
            name: "response_deadline",
            title: "Deadline",
            searchable: false,
            optionIcon: true,
            static: true,
            options: deadlineOptions,
            className: "!min-w-[240px]",
            txtClass: "hover:bg-gray-100",
            btnClass: "hover:!text-black hover:!border-[#dbe0e5] min-h-[40px]",
            activeBtnClass: "hover:!text-action hover:!border-action !border-action",
            multiSelect: false,
        },
        {
            name: "state",
            subName: "state",
            title: "Location",
            searchable: true,
            optionIcon: true,
            className: "right-0 !min-w-[260px]",
            options: [],
            multiSelect: true,
        },
    ]);
    const { savedSearches, isLoadingSavedSearches } = useSelector((store) => store.utils);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [name, setName] = useState("");
    const [savingSearch, setSavingSearch] = useState(false);
    const { optionList, isLoadingUtils } = useSelector((store) => store.utils);
    const [saveSearchVal, setSaveSearchVal] = useState("");
    const [selectedSearch, setSelectedSearch] = useState("");
    const wrapperRef = useRef(null);

    // Select filter options
    const handleSelectChip = (name, e) => {
        let value = e.target.value || "";
        let prevList = allChips[name] || [];
        let prevAvail = prevList?.find((v) => v === value);
        if (prevList && prevAvail) {
            let newList = prevList?.filter((p) => p !== value);
            if (newList?.length > 0) {
                setAllChips((prev) => ({
                    ...prev,
                    [name]: newList,
                }));
            } else {
                let obj = { ...allChips };
                delete obj[name];
                setAllChips(obj);
            }
            return;
        }
        setAllChips((prev) => ({
            ...prev,
            [name]: drpOptions?.find((b) => b.name === name)?.multiSelect ? [value, ...prevList] : [value],
        }));
        setInfiniteScroll(true);
        setOffset(0);
        setLimitQuery(`limit=50&offset=0`);
    };

    // Unselect a filter option
    const handleUnSelectOption = (keyName, value) => {
        let prevList = allChips[keyName] || [];
        prevList = prevList?.filter((p) => p !== value);
        let updatedList = [];
        let obj = { ...allChips };
        if (prevList.length > 0) {
            updatedList = prevList;
            obj = {
                ...obj,
                [keyName]: updatedList,
            };
        } else {
            delete obj[keyName];
        }
        setAllChips(obj);
    };

    // find selected search
    const findSearchObj = (id) => {
        return savedSearches?.find((v) => v?.id === id) || {};
    };

    // save search
    const saveSearch = (e) => {
        e.preventDefault();
        if (savingSearch) return;
        setSavingSearch(true);

        let updatedDepartments = allChips?.departments?.map(
            (v) => optionList?.department?.find((s) => s?.name === v)?.value
        );
        let updatedNaicsCode = allChips?.naics_codes?.map(
            (v) => optionList?.naics_code?.find((s) => s?.name === v)?.value
        );
        let updatedProductCode = allChips?.product_codes?.map(
            (v) => optionList?.product_code?.find((s) => s?.name === v)?.value
        );
        let updatedStage = allChips?.notice_type?.map(
            (v) => optionList?.notice_type?.find((s) => s?.name === v)?.value
        );
        let updatedState = allChips?.state?.map((v) => optionList?.state?.find((s) => s?.name === v)?.value);
        //
        let reqBody = {
            name,
            filters: {
                search_query: searchVal || "",
                departments: updatedDepartments || [],
                naics_codes: updatedNaicsCode || [],
                product_codes: updatedProductCode || [],
                notice_types: updatedStage || [],
                response_deadline: getExactDate(allChips?.response_deadline, deadlineOptions)
                    ? getExactDate(allChips?.response_deadline, deadlineOptions)
                    : null,
                states: updatedState || [],
            },
        };
        let action = saveSearchApi;
        if (selectedSearch && selectedSearch?.toString()?.trim()?.length > 0) {
            action = updateSearchFiltersApi;
            reqBody.id = selectedSearch;
            reqBody.feed = findSearchObj(selectedSearch)?.feed ? "true" : "false";
        }
        action(reqBody)
            .then(() => {
                setToast.success({ msg: "Search saved" });
                setShowSearchModal(false);
                setName("");
                let updatedData = [...savedSearches];
                if (selectedSearch) {
                    let index = savedSearches?.findIndex((v) => v?.id === selectedSearch);
                    if (index >= 0) {
                        updatedData[index] = {
                            ...updatedData[index],
                            ...reqBody,
                        };
                    }
                } else {
                    dispatch(getAllSavedSearches());
                    setAllChips({});
                    setSelectedSearch("");
                }
                dispatch(
                    setSavedSearches({
                        data: updatedData,
                        loading: false,
                    })
                );
            })
            .catch((err) => {
                setToast.error({
                    title: "Oops!",
                    msg: "Something went wrong!",
                });
                console.log("Error while saving a search ", err);
            })
            .finally(() => {
                setSavingSearch(false);
            });
    };

    // close modal
    const handleCloseModal = () => {
        if (savingSearch) return;
        setShowSearchModal(false);
        setName("");
    };

    // set our menu option when data is fetched
    useEffect(() => {
        if (selectedSearch && selectedSearch?.toString()?.trim()?.length > 0) {
            let currentSearch = findSearchObj(selectedSearch)?.filters || {};
            if (currentSearch) {
                setInfiniteScroll(true);
                setOffset(0);
                setLimitQuery(`limit=50&offset=0`);
                setSearchVal(currentSearch?.search_query?.replaceAll("-", ""));
                setSearchQuery(currentSearch?.search_query?.replaceAll("-", ""));
                let updatedDepartments = currentSearch?.departments?.map(
                    (v) => optionList?.department?.find((s) => s?.value === v)?.name
                );
                let updatedNaicsCode = currentSearch?.naics_codes?.map(
                    (v) => optionList?.naics_code?.find((s) => s?.value === v)?.name
                );
                let updatedProductCode = currentSearch?.product_codes?.map(
                    (v) => optionList?.product_code?.find((s) => s?.value === v)?.name
                );
                let updatedStage = currentSearch?.notice_types?.map(
                    (v) => optionList?.notice_type?.find((s) => s?.value === v)?.name
                );
                let updatedState = currentSearch?.states?.map(
                    (v) => optionList?.state?.find((s) => s?.value === v)?.name
                );
                setAllChips({
                    departments: updatedDepartments || [],
                    naics_codes: updatedNaicsCode || [],
                    notice_type: updatedStage || [],
                    product_codes: updatedProductCode || [],
                    response_deadline: currentSearch?.response_deadline ? [currentSearch?.response_deadline] : [],
                    state: updatedState,
                });
            }
        }
        if (isLoadingUtils) return;
        let updatedList = drpOptions?.map((v) => {
            if (v?.static) return v;
            let newOpts = optionList[v?.subName] || optionList[v?.name] || [];
            return {
                ...v,
                options: newOpts,
                // options: newOpts?.slice(0, 8)
            };
        });
        setDrpOptions(updatedList);
    }, [isLoadingUtils, selectedSearch]);

    //
    return (
        <>
            {/* Save search modal  */}
            <CustomModal
                isOpen={showSearchModal}
                onClose={() => handleCloseModal()}
                className={"w-[100%] max-w-[477px]"}
            >
                <div className="container px-5 pb-4 pt-7">
                    <div className="flex items-center">
                        <p className="text-[16px] font-semibold">
                            {!selectedSearch ? "Create a New Saved Search" : "Update Saved Search"}
                        </p>
                        <span className="ml-auto block" role="button" onClick={handleCloseModal}>
                            <CloseIconBlack fill="black" />
                        </span>
                    </div>
                    <form onSubmit={saveSearch}>
                        <div className="flex items-start mt-8 flex-col">
                            <input
                                type="text"
                                placeholder="Please provide a name for the saved search?"
                                autoFocus
                                value={name}
                                required
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                className="border-gray-300 border p-2 w-full rounded-lg focus:outline-none focus:ring-1 focus:ring-action"
                            />
                        </div>
                        <div className="flex items-center justify-end w-full gap-3 mt-8">
                            <button
                                disabled={savingSearch}
                                type="button"
                                className="border-gray-300 border py-2 px-3 rounded-lg active:bg-slate-400"
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={savingSearch}
                                type="submit"
                                className="py-2 px-5 rounded-lg bg-action text-white active:bg-[#304aa5de]"
                            >
                                {savingSearch ? <Spinner /> : !selectedSearch ? "Create" : "Update"}
                            </button>
                        </div>
                    </form>
                </div>
            </CustomModal>
            {/*  */}
            <div
                ref={searchBarRef}
                className="p-4 my-3 shadow-sm  items-center gap-4 bg-white sticky top-0 left-0 z-30"
            >
                <div className="flex items-start gap-2">
                    {/* search bar */}
                    <div className="flex-grow max-w-[50%]">
                        <div className="min-w-[240px] w-full flex gap-2 px-1.5 min-h-[40px] py-[3px] rounded-lg border bg-[#f5f5f5]">
                            <img src={search} width={23} height={22} alt="icon" className="m-1 object-contain" />
                            <input
                                type="text"
                                className="bg-[#f5f5f5] focus:outline-none focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full"
                                value={searchVal}
                                onChange={(e) => {
                                    setSearchVal(e.target.value);
                                    setSearchQuery(e.target.value);
                                    setInfiniteScroll(true);
                                    setOffset(0);
                                    setLimitQuery(`limit=50&offset=0`);
                                }}
                            />
                        </div>
                    </div>
                    {/* DropDown */}
                    <div className="ml-auto max-w-[80%]">
                        <div ref={wrapperRef} className="flex items-center gap-1 justify-start flex-auto flex-wrap">
                            {drpOptions?.map((optItem, index) => (
                                <DropDown
                                    key={index}
                                    name={optItem?.name}
                                    title={optItem?.title}
                                    subTitle={optItem?.subTitle}
                                    multiSelect={optItem?.multiSelect}
                                    options={optItem?.options?.map((v) => v?.name || "")}
                                    // trimItems={8}
                                    selectedOptions={allChips}
                                    handleOptionSelect={handleSelectChip}
                                    optionIcon={optItem?.optionIcon}
                                    searchInput={optItem?.searchable}
                                    className={optItem?.className || ""}
                                    textClass={optItem?.txtClass || "!max-w-[550px]"}
                                    btnClass={optItem?.btnClass || "min-h-[40px]"}
                                    activeBtnClass={optItem?.activeBtnClass || ""}
                                    itemClass={"hover:bg-[#f7f7f7]"}
                                    noTruncate
                                />
                            ))}
                            <DropDown
                                name="savedSearches"
                                title={
                                    <div className="w-6 py-[2.2px] flex items-center justify-center">
                                        <img className="mx-auto" src={save} alt="" />
                                    </div>
                                }
                                multiSelect={false}
                                className={"right-0 !min-w-[260px]"}
                                onCloseMenu={() => setSaveSearchVal("")}
                                // btnClass={"hover:!text-black hover:!border-[#dbe0e5] focus:!border-action"}
                                btnClass="hover:!text-black hover:!border-[#dbe0e5]"
                                activeBtnClass="hover:!border-action !border-action"
                                customList={
                                    isLoadingSavedSearches ? (
                                        <div className="flex items-center justify-center p-6 bg-white">
                                            <Spinner classes="!text-black" />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="sticky top-0 left-0 bg-white z-[2] p-[0.15rem]">
                                                <input
                                                    autoFocus
                                                    onChange={(e) => {
                                                        setSaveSearchVal(e.target.value);
                                                    }}
                                                    value={saveSearchVal}
                                                    type="text"
                                                    placeholder={"Saved Searches"}
                                                    className={`w-[100%] h-[25px] py-5 px-2 rounded border border-gray-200 outline-0 focus:outline-[1px] outline-[--action] focus:ring focus:ring-[#d1e9ff]`}
                                                    style={{ outlineStyle: "solid" }}
                                                />
                                            </div>
                                            <ul className={`p-2 space-y-1 text-sm text-gray-700 max-w-[290px]`}>
                                                {searchFilter(saveSearchVal, savedSearches, "name")?.length > 0 ? (
                                                    searchFilter(saveSearchVal, savedSearches, "name")?.map(
                                                        (opt, i) => (
                                                            <li key={i}>
                                                                <div className="flex items-center">
                                                                    <span
                                                                        onClick={() => {
                                                                            setSelectedSearch(opt?.id);
                                                                            setName(findSearchObj(opt?.id)?.name);
                                                                            wrapperRef.current.click();
                                                                        }}
                                                                        className={`block hover:bg-[#f7f7f7] rounded p-2 text-sm font-medium select-none cursor-pointer w-full truncate  max-w-full ${
                                                                            selectedSearch === opt?.id
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        {opt?.name}
                                                                    </span>
                                                                </div>
                                                                {/* <div className="flex items-center px-2 py-[4px]">
                            <>
                              <input
                                id={`radio-item-${i}`}
                                type="radio"
                                value={opt?.name}
                                checked={selectedSearch === opt?.id}
                                onChange={() => { setSelectedSearch(opt?.id);  }}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mr-2"
                              />
                              <label
                                htmlFor={`radio-item-${i}`}
                                className={`text-sm font-medium select-none cursor-pointer w-full text-gray-900 truncate max-w-[260px]`}
                              >
                                {opt?.name}
                              </label>
                            </>
                          </div> */}
                                                            </li>
                                                        )
                                                    )
                                                ) : (
                                                    <li className="text-gray-400 text-center py-1 px-3">
                                                        No saved searches
                                                    </li>
                                                )}
                                            </ul>
                                        </>
                                    )
                                }
                            />
                        </div>
                        {/* Chips section start */}
                        {Object.values(allChips)?.length > 0 && (
                            <div className="flex flex-wrap items-center justify-start w-full mt-2 mr-3">
                                {Object?.keys(allChips).map((mainItem, i) => {
                                    let obj = drpOptions?.find((v) => v?.name === mainItem);
                                    return allChips[mainItem]?.map((item, ind) => (
                                        <Chip key={ind} className="flex items-center py-[9px] mt-1">
                                            <div className="flex flex-wrap flex-grow pl-1">
                                                <span className="block capitalize pr-1">{obj?.title}:</span>
                                                <span className="block">{item}</span>
                                            </div>
                                            <span
                                                className="ml-2 inline-flex justify-center w-4 cursor-pointer"
                                                role="button"
                                                onClick={() => handleUnSelectOption(mainItem, item)}
                                            >
                                                <CloseIconSm />
                                            </span>
                                        </Chip>
                                    ));
                                })}
                                <button
                                    className="text-action font-[500] text-sm mr-1 mt-2 px-2"
                                    onClick={() => {
                                        setAllChips({});
                                        setName("");
                                        setSelectedSearch();
                                        setSearchVal("");
                                    }}
                                >
                                    Clear All
                                </button>
                                <button
                                    className="text-action font-[500] text-sm mt-2 px-2"
                                    onClick={() => {
                                        setShowSearchModal(true);
                                        setName(findSearchObj(selectedSearch)?.name || "");
                                    }}
                                >
                                    Save Search
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchPageSearchBar;
