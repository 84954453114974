/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useMemo, useState } from "react";
import star from "../../Assets/star.png";
import filledStar from "../../Assets/filledstar.png";
import trash from "../../Assets/trashlrg.svg";
import dots from "../../Assets/3dots.svg";
import { statusList } from "../../config/data";
import { Link } from "react-router-dom";
import NotFound from "./NotFound";
import lod from "../../Assets/loader.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateContractManagerItems, updateInternalContractDetails } from "api/api";
import { getContractManagerData, setContractManager } from "store/reducers/contractManagerReducerSlice";
import { useSortable } from "hook/useSortable";
import { SortingActiveBottomIcon, SortingActiveIcon, SortingActiveTopIcon } from "utils/icons";
import { getExactDate } from "utils/helpers";
import moment from "moment";
import { deadlineOptions, publishedDateOptions } from "components/Search/SearchPageSearchBar";
import DeleteConfirmModal from "./DeleteConfirmModal";
import Popover from "components/atoms/popover/Popover";
import { Checkbox } from "components/atoms/checkbox";
import { AvatarGroup } from "components/molecules/avatar-group";
import { Avatar } from "components/molecules/avatar";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import tw from "twin.macro";
import { getProject } from "store/reducers/projectReducer";
import DatePicker from "components/molecules/date-picker";

// ----------------- Contract table component --------------------
const ContractTable = ({ selectedFilters }) => {
    const dispatch = useDispatch();
    const { data: contracts, isLoadingContractManagerData } = useSelector((store) => store.contracts);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const [selectedProject, setSelectedProject] = useState();

    // handle update selected memeber
    const updateSelectedMember = useCallback(
        async (projectId, assigned_user_ids) => {
            const newContracts = [...contracts];
            let rowIndex = newContracts.findIndex((v) => v.id === projectId);
            newContracts[rowIndex] = {
                ...newContracts[rowIndex],
                assigned_user_ids: assigned_user_ids,
            };

            dispatch(
                setContractManager({
                    data: newContracts,
                    loading: false,
                })
            );
            updateContractManagerItems({
                updates: newContracts
                    .map((v, i) => ({
                        internal_contract_id: v.id,
                        starred: v.starred,
                        status: v.status,
                        assigned_user_ids: v.assigned_user_ids,
                        order_key: i,
                        estimated_value: 0,
                    }))
                    .filter((_, i) => i === rowIndex),
            });
        },
        [contracts, dispatch]
    );

    // handle toggle star contract
    const handleFavoriteUpdate = async (id) => {
        const newContracts = [...contracts];
        let rowIndex = newContracts.findIndex((v) => v.id === id);
        newContracts[rowIndex] = {
            ...newContracts[rowIndex],
            starred: !newContracts[rowIndex]?.starred,
        };

        dispatch(
            setContractManager({
                data: newContracts,
                loading: false,
            })
        );
        await updateContractManagerItems({
            updates: newContracts
                .map((v, i) => ({
                    internal_contract_id: v.id,
                    starred: v.starred,
                    status: v.status,
                    assigned_user_ids: v.assigned_user_ids,
                    order_key: i,
                    estimated_value: 0,
                }))
                .filter((_, i) => i === rowIndex),
        });
    };

    // update status of a specific record
    const handleUpdateStatus = useCallback(
        async (id, statusIndex) => {
            const newContracts = [...contracts];
            let rowIndex = newContracts.findIndex((v) => v.id === id);
            newContracts[rowIndex] = {
                ...newContracts[rowIndex],
                status: statusIndex,
            };

            dispatch(
                setContractManager({
                    data: newContracts,
                    loading: false,
                })
            );

            await updateContractManagerItems({
                updates: newContracts
                    .map((v, i) => ({
                        internal_contract_id: v.id,
                        starred: v.starred,
                        status: v.status,
                        assigned_user_ids: v.assigned_user_ids,
                        order_key: i,
                        estimated_value: 0,
                    }))
                    .filter((_, i) => i === rowIndex),
            });
            dispatch(getContractManagerData());
        },
        [contracts, dispatch]
    );

    // sorted data
    let { items, sortConfig, handleSorting } = useSortable(
        contracts?.map((v, i) => {
            return {
                id: v?.id,
                starred: v?.starred,
                status: v?.status,
                assigned_user_ids: v?.assigned_user_ids || [],
                projectName: v?.project_name || "-",
                ...v?.contract,
                publishDate: v?.start_date?.length > 0 ? v?.start_date : new Date().toISOString(),
                responseDate: v?.response_date,
            };
        })
    );

    const queryPublishDate = useMemo(() => {
        let end = "";
        let start = getExactDate(selectedFilters?.publishedDate, publishedDateOptions, "past");
        if (start?.length > 0) {
            end = moment().format("YYYY-MM-DD");
        }
        return { start, end };
    }, [selectedFilters?.publishedDate]);
    const queryDeadlineDate = useMemo(() => {
        let start = "";
        let end = getExactDate(selectedFilters?.response_deadline, deadlineOptions);
        if (end?.length > 0) {
            start = moment().format("YYYY-MM-DD");
        }
        return { start, end };
    }, [selectedFilters?.response_deadline]);

    // filter list
    const filterData = useMemo(() => {
        let list = [...items];
        if (selectedFilters && Object.keys(selectedFilters)?.length > 0) {
            let departments = selectedFilters?.department || [];
            let naicsCodes = selectedFilters?.naics_codes || [];
            let states = selectedFilters?.state || [];
            let deadlineDate = selectedFilters?.response_deadline || [];
            let publishedDate = selectedFilters?.publishedDate || [];
            let statuses = selectedFilters?.status || [];
            list = items?.filter((v) =>
                departments?.length > 0
                    ? departments?.find((d) => d?.toLowerCase()?.includes(v?.department?.toLowerCase()))
                    : true && naicsCodes?.length > 0
                    ? naicsCodes?.find((n) => n?.toLowerCase()?.includes(v?.naics_codes[0]?.toLowerCase()))
                    : true && states?.length > 0
                    ? states?.find((s) =>
                          v?.contact_information?.office_address?.toLowerCase()?.includes(s?.toLowerCase())
                      )
                    : true && deadlineDate?.length > 0
                    ? new Date(v?.response_date) >= new Date(queryDeadlineDate?.start) &&
                      new Date(v?.response_date) < new Date(queryDeadlineDate?.end)
                    : true && publishedDate?.length > 0
                    ? new Date(v?.publish_date) >= new Date(queryPublishDate?.start) &&
                      new Date(v?.publish_date) <= new Date(queryPublishDate?.end)
                    : true && statuses?.length > 0
                    ? statuses?.find((st) => statusList[v?.status]?.label?.toLowerCase()?.includes(st?.toLowerCase()))
                    : true
            );
        }
        return list;
    }, [items, queryDeadlineDate, queryPublishDate, selectedFilters]);

    // handle sort click event on table headers
    const handleSortEvent = (key, by_date) => {
        if (sortConfig?.key === key) {
            if (sortConfig?.direction === "ascending") {
                handleSorting(key, "descending", by_date);
            } else {
                handleSorting("", "", false);
            }
        } else {
            handleSorting(key, "ascending", by_date);
        }
    };

    // render sort icon
    const renderSortIcon = (key, by_date) => {
        if (sortConfig?.key !== key) {
            return (
                <SortingActiveIcon
                    onclick1={() => handleSorting(key, "ascending", by_date)}
                    onclick2={() => handleSorting(key, "descending", by_date)}
                />
            );
        }
        switch (sortConfig?.direction) {
            case "ascending":
                return (
                    <SortingActiveTopIcon
                        onclick1={() => handleSorting(key, "ascending", by_date)}
                        onclick2={() => handleSorting(key, "descending", by_date)}
                    />
                );
            case "descending":
                return (
                    <SortingActiveBottomIcon
                        onclick1={() => handleSorting(key, "ascending", by_date)}
                        onclick2={() => handleSorting(key, "descending", by_date)}
                    />
                );
            default:
                return (
                    <SortingActiveIcon
                        onclick1={() => handleSorting(key, "ascending", by_date)}
                        onclick2={() => handleSorting(key, "descending", by_date)}
                    />
                );
        }
    };

    const statusItems = useCallback(
        (id) => {
            return statusList?.map((status, index) => ({
                key: index,
                style: tw`!bg-white py-0.5`,
                label: (
                    <div
                        className="text-xs p-2 rounded-md duration-150 hover:[filter:brightness(0.92)]"
                        style={{
                            color: status?.color || "",
                            background: status?.bg || "",
                        }}
                    >
                        {status?.label}
                    </div>
                ),
                onSelect: () => handleUpdateStatus(id, index),
            }));
        },
        [handleUpdateStatus]
    );

    const onDateSelect = useCallback(
        async (internalContractId, data) => {
            try {
                await updateInternalContractDetails(internalContractId, data);
                dispatch(getProject(internalContractId));
                dispatch(getContractManagerData());
            } catch {}
        },
        [dispatch]
    );

    return (
        <div className="max-w-full flex-1 overflow-auto  border bg-white rounded-lg mx-4 my-2 scrollbar-0">
            <table className="w- overflow-auto">
                <thead className="border-b text-sm sticky top-0 left-0 bg-white rounded-tl-lg rounded-tr-lg z-[1]">
                    <tr className="text-left font-thin">
                        <th className="px-2 py-2 min-w-[35px] min-[1550px]:min-w-[45px] min-[1550px]:w-[54px]"></th>
                        <th className="pr-4 w-[95%] pl-4 py-5 font-[550]">
                            <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                <span onClick={() => handleSortEvent("projectName")} className="block pr-1">
                                    Title
                                </span>
                                <span className="p-[2px] block">{renderSortIcon("title")}</span>
                            </p>
                        </th>
                        <th className="min-[1550px]:px-4 px-2 min-[1550px]:min-w-[170px] min-[1550px]:max-w-[170px] min-[1550px]:w-[170px] min-w-[142px] w-[162px] max-w-[162px] py-5 font-medium text-sm">
                            <p className="flex w-max max-w-full items-center select-none cursor-pointer py-1">
                                <span onClick={() => handleSortEvent("responseDate", true)} className="block pr-1">
                                    Deadline
                                </span>
                                <span className="p-[2px] block">{renderSortIcon("responseDate", true)}</span>
                            </p>
                        </th>
                        <th className="px-4 py-5 font-medium text-sm w-[170px] min-[1550px]:min-w-[190px] min-[1550px]:w-[210px]">
                            <p className="flex w-max max-w-full items-center select-none py-1">
                                <span className="block pr-1">Assignees</span>
                            </p>
                        </th>
                        <th className="px-4 py-5 font-medium text-sm min-w-[150px] max-w-[150px]">
                            <p className="flex w-max max-w-full items-center select-none py-1">
                                <span className="block pr-1">Status</span>
                            </p>
                        </th>
                        <th className="px-4 py-5 font-medium text-sm min-w-[50px] max-w-[150px]">
                            <p className="flex w-max max-w-full items-center select-none py-1">
                                <span className="block pr-1"></span>
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th className="h-[1px] bg-gray-200" colSpan="8"></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoadingContractManagerData && contracts?.length === 0 ? (
                        <tr>
                            <td colSpan="8">
                                <div className="min-h-[64vh] bg-white w-full flex justify-center items-center flex-col py-7 px-2">
                                    <img className="mx-auto max-w-full" src={lod} alt="" />
                                </div>
                            </td>
                        </tr>
                    ) : contracts?.length === 0 || filterData?.length === 0 ? (
                        <tr>
                            <td colSpan="8">
                                <NotFound />
                            </td>
                        </tr>
                    ) : (
                        filterData?.map((data, i) => {
                            const statusStyling = statusList[data?.status];
                            const contractDetailPath = `/dashboard/contracts/details/?tab=project&id=${data.id}`;
                            return (
                                <tr
                                    className={`${data?.starred ? " w-full border-b" : "border-b "} hover:bg-[#f5f5f5]`}
                                    key={data.id}
                                >
                                    <td className="pl-4 py-1 min-w-[45px] min-[1550px]:min-w-[45px] min-[1550px]:w-[54px] text-center">
                                        <img
                                            className="mx-auto  max-w-full object-contain cursor-pointer"
                                            src={data?.starred ? filledStar : star}
                                            alt=""
                                            onClick={() => {
                                                handleFavoriteUpdate(data?.id);
                                            }}
                                        />
                                    </td>
                                    <td className="min-w-[144px] w-[95%] text-sm font-medium">
                                        <Link className="block py-4 pr-4 pl-4" to={contractDetailPath}>
                                            <p className="text-ellipsis line-clamp-2">{data?.projectName || "-"}</p>
                                        </Link>
                                    </td>
                                    <td className="min-[1550px]:min-w-[175px] min-[1550px]:max-w-[150px] min-[1550px]:w-[150px] min-w-[142px] w-[142px] max-w-[142px] text-sm">
                                        <DateComp
                                            internalContractId={data.id}
                                            dateString={data.responseDate}
                                            onDateSelect={onDateSelect}
                                        />
                                    </td>
                                    <td className="pr-3 pl-[0.45rem] py-2 w-[160px] min-[1550px]:min-w-[170px] min-[1550px]:w-[190px]  group-hover/row:bg-[#f5f5f5]">
                                        <AssignedDropDown data={data} updateSelectedMember={updateSelectedMember} />
                                    </td>
                                    <td className="p-4 min-w-[150px] max-w-[150px]">
                                        <div className="relative w-full">
                                            <DropdownMenu items={statusItems(data?.id)} modal>
                                                <button
                                                    className="text-xs p-2 rounded-[6px]"
                                                    style={{
                                                        color: statusStyling.color || "",
                                                        background: statusStyling.bg || "",
                                                    }}
                                                >
                                                    {statusStyling.label}
                                                </button>
                                            </DropdownMenu>
                                        </div>
                                    </td>

                                    <td className="pr-3 pl-[0.45rem] py-2 w-[50px] group-hover/row:bg-[#f5f5f5] relative">
                                        <DropdownMenu
                                            modal
                                            items={[
                                                {
                                                    key: 1,
                                                    onSelect: () => {
                                                        setSelectedProject(data);
                                                        setDeleteModalActive(true);
                                                    },
                                                    label: (
                                                        <div className="flex items-center gap-2 text-red-600 text-sm disabled:opacity-60 cursor-pointer select-none w-full">
                                                            {" "}
                                                            <img
                                                                src={trash}
                                                                alt="icon"
                                                                loading="lazy"
                                                                className="mr-[2px]"
                                                            />
                                                            Delete
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        >
                                            <img src={dots} alt="" className="cursor-pointer" />
                                        </DropdownMenu>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
            <DeleteConfirmModal
                isOpen={deleteModalActive}
                setIsOpen={setDeleteModalActive}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
            />
        </div>
    );
};

export const AssignedDropDown = ({ data, updateSelectedMember }) => {
    const { workspaceMembers } = useSelector((store) => store.auth);
    const [opened, setOpened] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [assignedUsers, setAssignedUsers] = useState(data.assigned_user_ids || []);
    const isTheSame =
        data.assigned_user_ids?.length === assignedUsers.length &&
        data.assigned_user_ids?.every((id) => assignedUsers.includes(id));

    useEffect(() => {
        if (!opened && !isTheSame) setAssignedUsers(data.assigned_user_ids || []);
    }, [data.assigned_user_ids, isTheSame, opened]);

    const handleMemberSelect = (v) => {
        setAssignedUsers((prev) => {
            if (prev.includes(v)) return prev.filter((m) => m !== v);
            return [...prev, v];
        });
    };

    const getUsernameById = useCallback(
        (userId) => {
            const result = workspaceMembers?.find((member) => member?.id === userId);
            return result?.username;
        },
        [workspaceMembers]
    );

    const sortedMembers = useMemo(
        () =>
            [...workspaceMembers].sort((a, b) => {
                const aSelected = assignedUsers?.includes(a.id);
                const bSelected = assignedUsers?.includes(b.id);

                if (aSelected && !bSelected) {
                    return -1; // a is selected but b is not, move a to the top
                } else if (!aSelected && bSelected) {
                    return 1; // b is selected but a is not, move b to the top
                } else {
                    // both a and b are selected or both are not selected, sort alphabetically
                    return a.username.localeCompare(b.username);
                }
            }),
        [assignedUsers, workspaceMembers]
    );

    const filteredUsers = useMemo(() => {
        return sortedMembers?.filter((me) =>
            me?.username?.toLocaleLowerCase()?.includes(searchVal?.toLocaleLowerCase())
        );
    }, [searchVal, sortedMembers]);

    const avatars = useMemo(
        () =>
            assignedUsers?.reduce((acc, userId) => {
                const username = getUsernameById(userId);
                if (!username) return acc;
                return [
                    ...acc,
                    {
                        key: userId,
                        id: username,
                        name: username,
                        size: 32,
                        tooltip: true,
                    },
                ];
            }, []),
        [assignedUsers, getUsernameById]
    );

    return (
        <Popover
            open={opened}
            onOpenChange={(open) => {
                if (open) setAssignedUsers(data.assigned_user_ids || []);
                setOpened(open);
                if (!open) updateSelectedMember(data.id, assignedUsers);
            }}
            modal
            content={
                <div className="w-max max-w-[425px] rounded-[6px] overflow-hidden bg-white shadow-[0_0_15px_rgba(0,0,0,0.1)] z-20">
                    <div className="max-h-[250px] overflow-y-auto">
                        <div className="sticky top-0 left-0 bg-white z-[2] p-[0.15rem]">
                            <input
                                autoFocus
                                onChange={(e) => setSearchVal(e.target.value)}
                                value={searchVal}
                                type="text"
                                placeholder={"Search Members"}
                                className={`w-[100%] h-[25px] py-5 px-2 text-sm rounded border !outline-0 border-gray-200`}
                                style={{ outlineStyle: "solid" }}
                            />
                        </div>
                        <div className="p-2 pt-1">
                            {filteredUsers?.length > 0 ? (
                                filteredUsers?.map((v, i) => (
                                    <div key={i} className="flex items-center min-w-[120px] max-w-[425px] py-0.5">
                                        <label className="flex items-center gap-2 py-1 text-sm select-none cursor-pointer w-full text-gray-900 max-w-full overflow-hidden">
                                            <Checkbox
                                                onCheck={() => handleMemberSelect(v?.id)}
                                                checked={!!assignedUsers?.find((n) => n === v?.id)}
                                            />
                                            <Avatar
                                                id={v?.username}
                                                size={24}
                                                name={v?.username}
                                                className="!text-[13px]"
                                            />
                                            {v?.username}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <div className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">
                                    No members found.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
        >
            <div className="cursor-pointer py-1 w-full">
                {workspaceMembers?.length > 0 ? (
                    <div className="flex items-center w-full">
                        <div className="flex items-center -space-x-[11px] ml-[9px] min-w-[100px] min-h-[32px]">
                            {avatars?.length > 0 ? (
                                <AvatarGroup maxCount={3} avatars={avatars} size={32} />
                            ) : (
                                <p className="text-gray-400 text-sm">Add Members</p>
                            )}
                        </div>
                        {workspaceMembers?.length > 2 && <span className="text-sm text-gray-400 ml-1">+</span>}
                    </div>
                ) : null}
            </div>
        </Popover>
    );
};

export default ContractTable;

const DateComp = ({ internalContractId, dateString, onDateSelect }) => {
    const date = useMemo(() => moment.utc(dateString).toDate(), [dateString]);

    return (
        <DatePicker
            calendarPosition="top-end"
            value={date}
            onChange={(date) => {
                const dateValue = date?.valueOf();
                onDateSelect(internalContractId, {
                    response_date: new Date(dateValue)?.toISOString(),
                });
            }}
            render={(value, openCalendar, handleValueChange) => {
                return (
                    <button
                        onClick={() => openCalendar()}
                        className="text-sm p-1 rounded-md duration-100 hover:bg-gray-200"
                        css={[!dateString && tw`w-[82px]`]}
                    >
                        {dateString ? moment(dateString).format("MM/DD/YYYY") : "-"}
                    </button>
                );
            }}
        />
    );
};
