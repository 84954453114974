/** @jsxImportSource @emotion/react */

import { useRef } from "react";
import { Checkbox } from "components/atoms/checkbox";
import { styles } from "components/molecules/content-drive-popover-content/styles";
import Tooltip from "components/atoms/tooltip";
import { WorkspaceFile } from "types/FileStorage";
import { FileMention } from "store/reducers/writing-assistant/writingAssistantReducer";
import useDocumentPreview from "hook/useDocumentPreview";
import { HiOutlineDownload } from "react-icons/hi";

type Props = {
    id: string;
    name: string;
    file_extension_type: string;
    download_url: string;
    getFileIcon: (file_extension_type: string) => string;
    handleSelectedFiles: (
        checked: boolean,
        files: Pick<WorkspaceFile, "id" | "name" | "file_extension_type">[]
    ) => void;
    selectedFiles: FileMention[];
};

const AttachmentRow = ({
    id,
    name,
    file_extension_type,
    download_url,
    getFileIcon,
    handleSelectedFiles,
    selectedFiles,
}: Props) => {
    const fileNameRef = useRef<HTMLDivElement | null>(null);
    const { downloadFile } = useDocumentPreview();
    return (
        <Tooltip
            disabled={(fileNameRef.current?.clientWidth || 0) < 308}
            disableHoverableContent
            content={name}
            contentProps={{ side: "left", align: "end" }}
        >
            <div key={id} className={styles.checkboxItemRow}>
                <div className="text-stone-800 flex items-center gap-2 text-sm flex-1 truncate text-start py-1.5 group">
                    <img src={getFileIcon(file_extension_type)} alt="" className="h-[18px]" />
                    <div className="truncate" ref={fileNameRef}>
                        {name}
                    </div>
                    <button
                        onClick={() => downloadFile(download_url)}
                        className="opacity-0 group-hover:opacity-100 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
                    >
                        <HiOutlineDownload />
                    </button>
                </div>
                <Checkbox
                    checked={!!selectedFiles.find(({ id: selectedId }) => selectedId === id)}
                    onCheck={(checkValue) =>
                        handleSelectedFiles(checkValue, [{ id, name: name, file_extension_type: file_extension_type }])
                    }
                />
            </div>
        </Tooltip>
    );
};

export default AttachmentRow;
