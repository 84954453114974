import GetColorForTag from "./TagColor";

const TagWithColor = ({ tag }) => {
    const tagColor = GetColorForTag(tag);

    return (
        <div
            style={{
                backgroundColor: tagColor,
            }}
            className="py-[3px] px-[8px] text-[10px] rounded text-white"
        >
            {tag}
        </div>
    );
};

export default TagWithColor;
