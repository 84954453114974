export enum StepType {
    Default = 1,
    Custom,
}

export enum StepStatus {
    Skipped = 1,
    Ready,
    Completed,
}

export enum StepSource {
    Vultron = 1,
    Liveblocks,
}

export enum DefaultStepType {
    ProjectDescription = 1,
    ProjectAttachments,
    WinThemes,
    Requirements,
    RequirementResponses,
    TemplateSelection,
    RequirementAssignment,
    ProposalSectionWriting,
    ProposalGeneration,
    Export,
}
export enum DefaultStepName {
    ProjectDescription = "Project Description",
    ProjectAttachments = "Project Attachments",
    WinThemes = "Win Themes",
    Requirements = "Requirements",
    RequirementResponses = "Requirement Responses",
    TemplateSelection = "Template Selection",
    RequirementAssignment = "Requirement Assignment",
    ProposalSectionWriting = "Proposal Section Writing",
    ProposalGeneration = "Proposal Generation",
    Export = "Export",
}

interface BaseStep {
    status: StepStatus | null;
    source: StepSource | null;
}

export interface DefaultStep extends BaseStep {
    step: DefaultStepType;
    type: StepType.Default;
}

interface CustomStep extends BaseStep {
    name: string;
    description: string;
    type: StepType.Custom;
}

export type Step = DefaultStep | CustomStep;
