import { ReactComponent as VultronLockup } from "Assets/svgs/vultron-avatar.svg";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
    size: number;
}

const VultronAvatar = ({ size, ...props }: Props) => (
    <div
        className="flex items-center justify-center rounded-full p-[5px] bg-gray-darkest"
        style={{
            width: size + "px",
            minWidth: size + "px",
            maxWidth: size + "px",
            height: size + "px",
            minHeight: size + "px",
            maxHeight: size + "px",
        }}
        {...props}
    >
        <VultronLockup className="w-full h-full overflow-visible" />
    </div>
);

export default VultronAvatar;
