import { useState } from "react";
import EmailLogo from "../../Assets/Frame 236.png";
import { Navigate, useSearchParams } from "react-router-dom/dist";
import { signIn } from "api/api";
import { Spinner } from "utils/icons";
import { useNotification } from "context/notificationContext";

// ------------ Email Sent screen -------------------
const ConfirmEmail = () => {
    let [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const [resendingEmail, setResendingEmail] = useState(false);
    const { setToast } = useNotification();

    // resend login email
    const handleResendLink = () => {
        setResendingEmail(true);
        signIn({ email })
            .then(() => {
                setResendingEmail(false);
                setToast.success({ msg: "Link has been sent to your email" });
            })
            .catch((err) => {
                setToast.error({
                    title: "Unable to resend login link",
                    msg: "We were unable to resend the login link due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
                console.log("err while resending email =>, ", err.message);
                setResendingEmail(false);
            });
    };

    //
    if (!email) return <Navigate to="/auth/login" />;
    return (
        <>
            <div className="flex flex-col items-center  mb-8">
                <img src={EmailLogo} alt="" className="mb-8" />
                <div className="flex flex-col items-center">
                    <h1 className="text-2xl font-semibold pt-4 ">Please confirm your email</h1>
                    <p className="text-[#5b6b79] py-2 text-sm w-[100%] text-center leading-relaxed">
                        We just sent an email to <b>{email}</b>
                        <br /> Head over to your email and click the link to sign in{" "}
                    </p>
                </div>
            </div>
            <button
                className="bg-action text-white flex mt-4 w-[450px] items-center justify-center rounded-lg p-3 gap-2 text-base font-medium min-h-[60px]"
                onClick={handleResendLink}
                disabled={resendingEmail}
            >
                {resendingEmail ? (
                    <>
                        <Spinner />
                        <span>Resending Link</span>
                    </>
                ) : (
                    <span>Resend Link </span>
                )}
            </button>
        </>
    );
};

export default ConfirmEmail;
