import { Editor } from "@tiptap/react";
import { ItalicIcon } from "../icons";
import { ReactComponent as UnderlineIcon } from "../../editor/icons/underline.svg";
import { BoldIcon } from "../icons/Bold";
import { StrikethroughIcon } from "../icons/Strikethrough";
import { Button } from "../primitives/Button";
import styles from "./Toolbar.module.css";
import { useCallback } from "react";
import { ToolbarFontSize } from "./ToolbarFontSize";
import { ToolbarFontFamily } from "./ToolbarFontFamily";

type Props = {
    editor: Editor;
};

export function ToolbarInline({ editor }: Props) {
    const handleUnderline = useCallback(() => {
        editor.chain().focus().toggleUnderline().run();
    }, [editor]);
    return (
        <>
            <Button
                variant="subtle"
                className={`${styles.toolbarButton} `}
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                data-active={editor.isActive("bold") ? "is-active" : undefined}
                aria-label="Bold"
            >
                <BoldIcon />
            </Button>

            <Button
                variant="subtle"
                className={`${styles.toolbarButton} `}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                data-active={editor.isActive("italic") ? "is-active" : undefined}
                aria-label="Italic"
            >
                <ItalicIcon />
            </Button>

            <Button
                variant="subtle"
                className={styles.toolbarButton}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
                data-active={editor.isActive("strike") ? "is-active" : undefined}
                aria-label="Strikethrough"
            >
                <StrikethroughIcon />
            </Button>
            <Button
                variant="subtle"
                className={`${styles.toolbarButton} `}
                onClick={handleUnderline}
                disabled={!editor.can().chain().focus().toggleUnderline().run()}
                data-active={editor.isActive("underline") ? "is-active" : undefined}
                aria-label="underline"
            >
                <UnderlineIcon />
            </Button>
            <ToolbarFontSize editor={editor} />
            <ToolbarFontFamily editor={editor} />
        </>
    );
}
