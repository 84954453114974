import { ExtractionType, setExtractState } from "store/reducers/extract/ExtractReducer";
import Tooltip from "components/atoms/tooltip";
import Icon from "components/atoms/icons/Icon";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

export function ExtractOptions() {
    const { extractionType } = useAppSelector((state) => state.extractReducer);
    const dispatch = useAppDispatch();

    return (
        <div className="max-w-xl flex flex-col gap-3">
            <div className="flex flex-col gap-0.5">
                <h1 className="text-base font-medium">Extract Options</h1>
                <h3 className="text-sm font-normal text-day-subtext">What should be extracted?</h3>
            </div>
            <div className="flex flex-col gap-3">
                <div className="flex items-center gap-4">
                    <label className="inline-flex items-center gap-4">
                        <input
                            type="radio"
                            value="sentence"
                            checked={extractionType === ExtractionType.Requirements}
                            onChange={() => dispatch(setExtractState({ extractionType: ExtractionType.Requirements }))}
                            className="w-4 h-4 accent-action bg-gray-100 border-gray-300 cursor-pointer rounded"
                        />
                        <div className="flex items-center gap-2">
                            <p className="text-sm">Requirements</p>
                            <Tooltip content="Vultron will shred requirements and build a compliance matrix in the requirements tab.">
                                <Icon name="InfoCircle" className="w-4 h-4" />
                            </Tooltip>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-4">
                    <label className="inline-flex items-center gap-4">
                        <input
                            type="radio"
                            value="sentence"
                            checked={extractionType === ExtractionType.Template}
                            onChange={() => dispatch(setExtractState({ extractionType: ExtractionType.Template }))}
                            className="w-4 h-4 accent-action bg-gray-100 border-gray-300 cursor-pointer rounded"
                        />
                        <div className="flex items-center gap-2">
                            <p className="text-sm">Proposal Template</p>
                            <Tooltip content="Vultron will extract the defined proposal outline and build a full proposal template.">
                                <Icon name="InfoCircle" className="w-4 h-4" />
                            </Tooltip>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
}
