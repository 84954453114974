import { useMemo } from "react";
import styles from "./NameCursor.module.css";
import { motion } from "framer-motion";
import { getUserColorSet } from "utils/getUserColor";

// Custom Avatar component
const colorList = [
    { color: "#4F00B2", bg: "#D9BBFF" },
    { color: "#339E00", bg: "#B2DC9E" },
    { color: "#00809D", bg: "#90C8D4" },
    { color: "#E8AB7E", bg: "#CF5800" },
    { color: "#AA0000", bg: "#FFBBBB" },
    { color: "#339E00", bg: "#B2DC9E" },
    { color: "#AA0000", bg: "#FFBBBB" },
    { color: "#E8AB7E", bg: "#CF5800" },
    { color: "#00809D", bg: "#90C8D4" },
    { color: "#4F00B2", bg: "#D9BBFF" },
    { color: "#E8AB7E", bg: "#CF5800" },
];

// choose random color for our Avatar component
const randomColor = () => {
    let luckyNum = Math.floor(Math.random() * colorList.length);
    return colorList[luckyNum];
};

type Props = {
    x?: number;
    y?: number;
    name?: string;
};

export function NameCursor({ x, y, name }: Props) {
    let colorSet;
    if (name) colorSet = getUserColorSet(name);
    const currentState = useMemo(() => randomColor(), []);

    const background = (colorSet && colorSet.bg) || currentState?.bg;
    const color = (colorSet && colorSet?.color) || currentState?.color;

    return (
        // SKINNY CURSOR
        // <svg
        //     style={{
        //         position: "absolute",
        //         left: 0,
        //         top: 0,
        //         transform: `translateX(${x}px) translateY(${y}px)`,
        //     }}
        //     width="24"
        //     height="36"
        //     viewBox="0 0 24 36"
        //     fill="none"
        //     xmlns="http://www.w3.org/2000/svg"
        // >
        //     <path
        //         d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
        //         fill={color}
        //     />
        // </svg>
        <motion.div
            className="absolute top-0 left-0 z-[98] pointer-events-none select-none"
            initial={{ x, y }}
            animate={{ x, y }}
            transition={{ type: "tween", duration: 0.2 }}
        >
            <div className={styles.nameWrapper}>
                <svg className={styles.cursorSvg} width="32" height="44" viewBox="0 0 24 36" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="500%" y2="0%">
                            <stop offset="0%" stopColor={color} />
                            <stop offset="100%" stopColor={color} />
                        </linearGradient>
                    </defs>
                    <path
                        fill="url(#gradient)"
                        d="M0.928548 2.18278C0.619075 1.37094 1.42087 0.577818 2.2293 0.896107L14.3863 5.68247C15.2271 6.0135 15.2325 7.20148 14.3947 7.54008L9.85984 9.373C9.61167 9.47331 9.41408 9.66891 9.31127 9.91604L7.43907 14.4165C7.09186 15.2511 5.90335 15.2333 5.58136 14.3886L0.928548 2.18278Z"
                    />
                </svg>
                <div
                    className={styles.namePill}
                    style={{
                        backgroundImage: `linear-gradient(to bottom right, ${background}, ${background})`,
                        color: "#fff",
                    }}
                >
                    <div className={styles.namePillName}>{name}</div>
                </div>
            </div>
        </motion.div>
    );
}
