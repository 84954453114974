import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

type ProjectForm = {
    title: string;
    description: string;
    response_date: string;
    client_name: string;
    client_email: string;
    client_phone_number: string;
    client_poc_name: string;
};

type State = {
    isExtractLoading: boolean;
    isEnhanceLoading: boolean;
    attachments: File[];
    form: ProjectForm;
};

const initialState: State = {
    isExtractLoading: false,
    isEnhanceLoading: false,
    attachments: [],
    form: {
        title: "",
        description: "",
        response_date: new Date().toISOString(),
        client_name: "",
        client_email: "",
        client_phone_number: "",
        client_poc_name: "",
    },
};

type ExtractResponse = {
    title: string | null;
    description: string | null;
    client_information: {
        government_department: string;
        point_of_contact_name: string;
        point_of_contact_email: string;
        point_of_contact_phone_number: string;
    };
    deadline: string | null;
};

export const extractText = createAsyncThunk<ExtractResponse, { text: string }>(
    "newProject/extractText",
    async (formVariables) => {
        const response = await axios.post("contracts/internal/extract/text", formVariables);
        return response.data;
    }
);
export const extractDoc = createAsyncThunk<ExtractResponse, FormData>(
    "newProject/extractDoc",
    async (formVariables) => {
        const response = await axios.post("contracts/internal/extract/file", formVariables, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    }
);

const newProjectReducer = createSlice({
    name: "newProject",
    initialState,
    reducers: {
        editForm: (state: State, action: PayloadAction<Partial<ProjectForm>>) => {
            state.form = { ...state.form, ...action.payload };
        },
        streamEnhance: (state: State, action: PayloadAction<string>) => {
            state.form.description = `${state.form.description}${action.payload}`;
        },
        setAttachments: (state: State, action: PayloadAction<File[]>) => {
            state.attachments = [...state.attachments, ...action.payload];
        },
        removeAttachment: (state: State, action: PayloadAction<File>) => {
            state.attachments = state.attachments.filter((f) => f !== action.payload);
        },
        toggleEnhanceLoading: (state: State, action: PayloadAction<boolean>) => {
            state.isEnhanceLoading = action.payload;
        },
        clearNewProject: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extractText.pending, (state) => {
            state.isExtractLoading = true;
        });
        builder.addCase(extractText.fulfilled, (state, { payload }) => {
            state.form = {
                ...initialState.form,
                ...{
                    title: payload.title || "",
                    description: payload.description || "",
                    response_date: payload.deadline
                        ? new Date(payload.deadline).toISOString()
                        : new Date().toISOString(),
                    client_name: payload.client_information.government_department,
                    client_email: payload.client_information.point_of_contact_email,
                    client_poc_name: payload.client_information.point_of_contact_name,
                    client_phone_number: payload.client_information.point_of_contact_phone_number,
                },
            };
            state.isExtractLoading = false;
        });
        builder.addCase(extractText.rejected, (state) => {
            state.isExtractLoading = false;
        });
        builder.addCase(extractDoc.pending, (state) => {
            state.isExtractLoading = true;
        });
        builder.addCase(extractDoc.fulfilled, (state, { payload }) => {
            state.form = {
                ...initialState.form,
                ...{
                    title: payload.title || "",
                    description: payload.description || "",
                    response_date: payload.deadline
                        ? new Date(payload.deadline).toISOString()
                        : new Date().toISOString(),
                    client_name: payload.client_information.government_department,
                    client_email: payload.client_information.point_of_contact_email,
                    client_poc_name: payload.client_information.point_of_contact_name,
                    client_phone_number: payload.client_information.point_of_contact_phone_number,
                },
            };
            state.isExtractLoading = false;
        });
        builder.addCase(extractDoc.rejected, (state, action) => {
            state.isExtractLoading = false;
        });
    },
});

export const { editForm, setAttachments, removeAttachment, clearNewProject, toggleEnhanceLoading, streamEnhance } =
    newProjectReducer.actions;

export default newProjectReducer.reducer;
