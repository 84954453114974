import empty from "../../Assets/noProjects.svg";
import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import Icon from "components/atoms/icons/Icon";

const NotFound = () => {
    const dispatch = useAppDispatch();

    return (
        <div className="min-h-[64vh] bg-white w-full flex justify-center items-center flex-col py-7 px-2">
            <img src={empty} alt="" />
            <div className="text-center mt-5">
                <h1 className="font-bold text-lg">You don’t have any projects</h1>
                <span className="text-gray-text text-sm mt-3">Create a project to get started</span>
            </div>
            <button
                className="bg-action flex w-[153px] my-5 h-[36px] text-white h-10 gap-1 py-2 px-3 text-md items-center rounded-lg"
                onClick={() => dispatch(toggleCreateProjectModal({ open: true }))}
            >
                <Icon name="PlusCircle" className="w-4 h-4" />
                <span>Create project</span>
            </button>
        </div>
    );
};

export default NotFound;
