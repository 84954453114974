/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import { Volume as ImmutableVolume, SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { useFrameworkOperations } from "components/copilot/hooks";
import { AvatarGroup } from "components/molecules/avatar-group";
import { FlexibleTextarea } from "components/molecules/flexible-textarea";
import { HTMLAttributes, forwardRef, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { setVolumeState } from "store/reducers/draft/volumeReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw, { theme } from "twin.macro";
import { useDropdownItems, useVolumeMeta } from "./hooks";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import ConfirmModal from "components/ConfirmModal";
import { Avatar } from "components/molecules/avatar";
import AssigneesPopoverContent from "components/organisms/assignees-popover-content";
import Popover from "components/atoms/popover";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";

interface Props extends HTMLAttributes<HTMLDivElement> {
    volume: Partial<ImmutableVolume>;
    withOpacity?: boolean;
    isDragging?: boolean;
}

const VolumeCard = forwardRef<HTMLDivElement, Props>(
    ({ volume, withOpacity, isDragging, style, ...dragProps }, ref) => {
        const [searchParams] = useSearchParams();
        const { updateVolumeTitle, assignToVolume, deleteVolume } = useFrameworkOperations();
        const { sections, id, title, assignees } = volume;
        const { volumeEditable } = useAppSelector((store) => store.volumeState);
        const actionsMenuOpen = useAppSelector((store) => store.copilotDrawer.open);
        const sidebarVisible = useAppSelector((root) => root.copilot.sidebarVisible);
        const dispatch = useAppDispatch();
        const [localTitle, setLocalTitle] = useState(title || "");
        const { avatars, statuses, assignedUser } = useVolumeMeta(volume);
        const defaultSectionPath = sections?.[0]?.id ? `/sections/${sections[0].id}/requirements` : "";
        const directionPath = `volumes/${id}${defaultSectionPath}?${searchParams.toString()}`;
        const { items, deleteVolumeModal, setDeleteVolumeModal, isPublishing } = useDropdownItems(
            directionPath,
            volume
        );

        useEffect(() => {
            setLocalTitle(title || "");
        }, [dispatch, title]);

        const isEditable = volumeEditable === id;

        return (
            <>
                <div
                    ref={ref}
                    className="group flex flex-col justify-between relative h-[184px] bg-white border border-gray-light rounded-md p-4 hover:bg-slate-100"
                    css={[
                        {
                            boxShadow: isDragging ? theme`boxShadow.xl` : theme`boxShadow.md`,
                            zIndex: isDragging ? "2" : "auto",
                            opacity: withOpacity ? "0.3" : "1",
                            pointerEvents: isEditable ? "none" : "auto",
                            ...(isEditable && tw`!bg-white`),
                            ...style,
                        },
                    ]}
                >
                    <div
                        className="z-[1] shadow-sharp absolute -top-3 -left-3 bg-slate-100 rounded-full p-1.5 opacity-0 text-gray-lightest hover:text-gray-darkest hover:bg-slate-200 group-hover:opacity-100"
                        css={[{ cursor: isDragging ? "grabbing" : "grab" }, isDragging && tw`opacity-100`]}
                        {...dragProps}
                    >
                        <Icon name="Draggable" className="w-3.5 h-3.5" />
                    </div>
                    <Link
                        to={directionPath}
                        className="absolute top-0 left-0 bottom-0 right-0 select-none no-link-drag"
                    />
                    <div
                        onClick={() => dispatch(setVolumeState({ volumeEditable: id }))}
                        className="z-[1] rounded-md flex pointer-events-auto cursor-text p-1 w-full bg-transparent hover:bg-slate-200"
                        css={[isEditable && tw`!bg-transparent pointer-events-none`]}
                    >
                        <FlexibleTextarea
                            flexible
                            forceResetProps={[actionsMenuOpen, sidebarVisible]}
                            forceResetDelay={150}
                            readOnly={!isEditable}
                            autoFocus={isEditable}
                            value={localTitle || ""}
                            onKeyDown={(e) => {
                                if (e.code === "Enter" && !e.shiftKey) {
                                    updateVolumeTitle(id || "", localTitle);
                                    dispatch(setVolumeState({ volumeEditable: "" }));
                                    e.currentTarget.scrollTo(0, 0);
                                }
                            }}
                            className="flex-1 text-sm font-semibold text-gray-darkest bg-transparent outline-none resize-none w-full"
                            css={[
                                !isEditable && tw`max-h-10 line-clamp-2`,
                                isEditable && tw`max-h-16 pointer-events-auto`,
                            ]}
                            placeholder="Draft name..."
                            onBlur={(e) => {
                                updateVolumeTitle(id || "", localTitle);
                                dispatch(setVolumeState({ volumeEditable: "" }));
                                e.target.scrollTo(0, 0);
                            }}
                            onChange={(e) => setLocalTitle(e.currentTarget.value)}
                        />
                    </div>
                    <div className="flex flex-row gap-4 justify-between items-end">
                        <div className="flex flex-col gap-1.5 items-start">
                            <Popover
                                contentProps={{ align: "start", css: tw`mx-0` }}
                                content={
                                    <AssigneesPopoverContent
                                        singleSelect
                                        selectedUsers={assignees || []}
                                        onUserSelect={(userId) => {
                                            if (!id) return;
                                            assignToVolume(id, userId ? [userId] : []);
                                        }}
                                        tw="p-1"
                                    />
                                }
                            >
                                <button className="z-[1] h-7 max-w-full flex items-center text-gray-700 py-1 px-1.5 rounded bg-slate-100 duration-150 hover:bg-slate-200">
                                    {!!avatars.length ? (
                                        <div className="flex text-xs items-center gap-2 text-gray-500">
                                            <AvatarGroup maxCount={4} size={20} avatars={avatars} />
                                            <span className="max-w-[125px] truncate">{assignedUser}</span>
                                        </div>
                                    ) : (
                                        <span className="text-xs text-slate-500">
                                            <Avatar size={18} empty />
                                        </span>
                                    )}
                                </button>
                            </Popover>
                            <div className="text-xs text-slate-500 flex items-center gap-1.5">
                                {sections?.length} Section{sections?.length !== 1 && "s"} |
                                <div className="flex items-center gap-1">{statuses[SectionStatus.Done] || 0} Done </div>
                            </div>
                        </div>
                        <DropdownMenu
                            modal
                            triggerProps={{ className: "z-[1] flex" }}
                            contentProps={{ align: "start" }}
                            items={items}
                        >
                            <div className="p-1 text-base text-slate-600 z-[1] bg-transparent rounded hover:bg-slate-200">
                                {isPublishing ? (
                                    <SpinnerCircle className="h-4 w-4 border-slate-200 border-l-slate-800" />
                                ) : (
                                    <BsThreeDotsVertical />
                                )}
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
                <ConfirmModal
                    open={deleteVolumeModal}
                    onClose={() => setDeleteVolumeModal(false)}
                    onProceed={(proceed) => proceed && volume.id && deleteVolume(volume.id)}
                    proceedLabel="Delete"
                    title={`Are you sure you want to delete "${volume.title}"?`}
                    subTitle="This action is irreversible and will delete all progress associated with the draft."
                />
            </>
        );
    }
);

export default VolumeCard;
